.widget_count_elems_container{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    font-size: 3.8rem;
    cursor: pointer;
    padding: 25px 0;
}


.widget_count_elems_container .number_arrow_container{
    position: relative;
    width: fit-content;
    height: fit-content;
}

.widget_count_elems_container .arrow_container{
    width: fit-content;
    height: fit-content;
    position: absolute;
    top: -5px;
    right: -45px;
    display: flex;
    color: green;
}

.widget_count_elems_container .arrow_container .progress_icon{
    width: 2em;
    height: 2em;
}