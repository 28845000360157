.data_complements_container h4{
    margin-top: 5px !important;
}

.data_complements_container .bottom_buttons{
    width: 100%;
    height: fit-content;
    display: flex;
    margin: 60px 0 0 0 !important;
    justify-content: flex-end;
    align-items: flex-end;
    align-content: flex-end;
}

.data_complements_container .dialogBottom {
  margin: 0 !important;
  margin-top: 30px !important;
}

.data_complements_container .dialogBottom div {
  display: flex;
  justify-content: flex-end;
  align-items: center;
} 

.data_complements_container .discussion_but{
    background-color: rgb(52, 58, 64);
    color: white;
}

.data_complements_container .discussion_but:hover{
    background-color: rgb(52, 58, 64);
    opacity: .8;
}

.data_complements_container .send_but{
    background-color: rgb(52, 58, 64);
    color: white;
}

.data_complements_container .send_but:hover{
    background-color: rgb(52, 58, 64);
    opacity: .8;
}



  .divider_choices {
    text-align: center;
    font-size: 1.1rem;
    font-weight: 600;
    position: relative;
    margin: 0 23px;
    top: -2px;
  }
  
  .divider_choices:before {
    content: "";
    display: block;
    width: 13px;
    height: 2px;
    background-color: rgb(52, 58, 64);
    left: 29px;
    top: 50%;
    position: absolute;
  }
  
  .divider_choices:after {
    content: "";
    display: block;
    width: 13px;
    height: 2px;
    background-color: rgb(52, 58, 64);
    right: 29px;
    top: 50%;
    position: absolute;
  }


  
@media all and (max-width: 1080px){
    
  .divider_choices {
    font-size: .95rem;
  }

  .divider_choices:before {
    width: 11px;
    height: 1.5px;
  }
  
  .divider_choices:after {
    width: 11px;
    height: 1.5px;
  }
}

@media all and (max-width: 750px){

  .divider_choices {
    font-size: .9rem;
    margin: 0 19px;
  }
  

}





@media all and (max-width: 580px){
  
  .divider_choices {
    font-size: .85rem;
    margin: 0 16px;
  }

  .divider_choices:before {
    width: 8.5px;
    height: 1.5px;
    left: 23px;
  }
  
  .divider_choices:after {
    width: 8.5px;
    height: 1.5px;
    right: 23px;
  }
}    
  



@media all and (max-width: 445px){
  
  .divider_choices {
    font-size: .8rem;
    margin: 0 15px;
  }

  .divider_choices:before {
    width: 8px;
    height: 1px;
    left: 23px;
  }
  
  .divider_choices:after {
    width: 8px;
    height: 1px;
    right: 23px;
  }

}