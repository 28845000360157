
.order_off_catalogue_form_page_container{
    width: 100%;
    height: fit-content;
    display: flex;
    justify-content: space-between;
    padding: 20px 15px;
    position: relative;
    align-items: flex-start !important;
    align-content: flex-start !important;
    flex-wrap: wrap;
}


.order_off_catalogue_form_page_container .off_catalogue_form_articles_container{
    width: 44%;
    display: flex;
    flex-direction: column;
}

.order_off_catalogue_form_page_container .off_catalogue_form_container{
    width: 55%;
    display: flex;
    flex-direction: column;
}



.order_off_catalogue_form_container{
    width: 100%;
    height: fit-content;
    margin: auto;
    border: solid 1px rgb(215, 212, 212);
    background-color: #fdfdfd;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    padding: 5px;
    padding-top: 13px;
}


.order_off_catalogue_form_infos_container{
    width: 100% !important;
    margin: 0 !important;
    padding: 0 !important;
}


.order_off_catalogue_form_infos_container .row {
    margin: 5px 0 10px 0;
}


.order_off_catalogue_form_infos_container .order_off_catalogue_form_infos_title{
    padding: 0 15px;
    font-size: 1.1rem;
    font-weight: 600;
    color: #526f87;
    margin: 18px 0 7px 0;
}

.order_off_catalogue_form_infos_container .row label {
    margin-bottom: 0.25rem !important;
}

.order_off_catalogue_form_infos_container .form-control {
    border: 1px solid #c4c3c3;
    border-radius: 4px;
    color: #2c2c2c;
    height: auto;
    font-size: .9rem;
    box-shadow: none;
    margin-bottom: 12px;
    height: 39px;
    background-image: none !important;
}

.order_off_catalogue_form_page_container .order_off_catalogue_form_infos_container textarea {
    height: auto !important;
    padding: 8px 11px !important;
    box-shadow: none !important;
    border: 1px solid #c4c3c3 !important;
    color: #2c2c2c !important;
}

.order_off_catalogue_form_page_buttons_container{
    width: 100%;
    height: fit-content;
    display: flex;
    justify-content: flex-end;
    margin: 30px auto;
}



.order_off_catalogue_form_page_container .articles_view_container{
    width: 100% !important;
    height: fit-content !important;
    border: solid 1px rgb(215, 212, 212);
    background-color: #fdfdfd;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    padding: 12px 15px;
    margin-bottom: 10px;
}

.order_off_catalogue_form_page_container .articles_view_header_container{
    font-size: 1.15rem;
    font-weight: 700;
    color: rgb(228, 88, 32);
    display: flex;
    justify-content: space-between;
}

.articles_view_header_container .add_icon_container{
    border-radius: 100%;
    cursor: pointer;
    width: 25px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.articles_view_header_container .add_icon_container:hover{
    background-color: rgba(188, 188, 188, 0.2);
}

.order_off_catalogue_form_page_container .article_view_header_container{
    font-weight: 600;
    color: #526f87;
    display: flex;
    justify-content: space-between;
}

.article_view_header_container .add_icon_container{
    border-radius: 100%;
    cursor: pointer;
    width: 25px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.article_view_header_container .add_icon_container:hover{
    background-color: rgba(188, 188, 188, 0.2);
}

.order_off_catalogue_form_page_buttons_container .submit_but{
    background:rgb(228, 88, 32);
    color: white;
}

.order_off_catalogue_form_page_buttons_container .submit_but:hover{
    background: rgb(228, 88, 32);
    opacity: .9;
}


.order_off_catalogue_form_page_buttons_container .return_button{
    background:rgb(228, 32, 74);
}

.order_off_catalogue_form_page_buttons_container .return_button:hover{
    background:rgb(228, 32, 74);
    opacity: .9;
}


@media all and (max-width: 900px){

    .order_off_catalogue_form_page_container .off_catalogue_form_articles_container{
        width: 85%;
        margin: auto;
    }

    .order_off_catalogue_form_page_container .off_catalogue_form_container{
        width: 85%;
        margin: auto;
    }

    .order_off_catalogue_form_page_container .order_form_title::after{
        height: 2.5px;
        width: 38%;
    }

    .order_off_catalogue_form_page_container .articles_view_container{
        padding: 9px 15px;
    }
}


@media all and (max-width: 750px){
    .order_off_catalogue_form_page_container .order_form_title::after{
        height: 2px;
        width: 32%;
    }
}

@media all and (max-width: 580px){

    .order_off_catalogue_form_page_container{
        padding: 9px;
    }

    .order_off_catalogue_form_page_container .articles_view_container{
        padding: 9px 12px;
    }

    .order_off_catalogue_form_page_container .off_catalogue_form_articles_container{
        width: 95%;
        margin: auto;
    }

    .order_off_catalogue_form_page_container .off_catalogue_form_container{
        width: 95%;
        margin: auto;
    }

    .order_off_catalogue_form_page_container .articles_view_header_container{
        font-size: 1.05rem;
        font-weight: 700;
        color: rgb(228, 88, 32);
        display: flex;
        justify-content: space-between;
    }

    .articles_view_header_container .add_icon_container{
        width: 23px;
        height: 23px;
    }
}


@media all and (max-width: 379px){

    .order_off_catalogue_form_page_container{
        padding: 5px;
    }

    .order_off_catalogue_form_page_container .off_catalogue_form_articles_container{
        width: 99%;
        margin: auto;
    }

    .order_off_catalogue_form_page_container .off_catalogue_form_container{
        width: 99%;
        margin: auto;
    }

    .order_off_catalogue_form_page_container .order_form_title::after{
        left: 7px;
    }

    .order_off_catalogue_form_page_container .articles_view_container{
        padding: 9px 6px;
    }

    .articles_view_header_container .add_icon_container{
        width: 20px;
        height: 20px;
    }

}