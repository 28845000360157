.authentication_page_container{
    background-color: #f7f7f7;
    margin: 0;
    padding-top: 20px ;
    width: 100vw;
    height: 100vh;
}

.authentication_page_container .logo_container{
    cursor: pointer;
    width: 60vw;
    height: 90px;
    margin: 0 auto 40px auto;
    text-align: center;
    position: relative;
}


.authentication_page_container .logo_container .brand {
    cursor: pointer;
    display: block !important;
    width: -moz-fit-content !important;
    width: 50%;
    height: 100%;
    position: relative !important;
    margin: auto;
    object-fit: scale-down;
}

.authentication_page_container .authentication_page_body{
    border-color: #e8e5ef;
    border-radius: 4px;
    border-width: 1px;
    box-shadow: 0 2px 0 rgba(9, 9, 53, 0.025), 2px 4px 0 rgba(0, 0, 150, 0.015);
    margin: 0 auto;
    padding: 40px;
    padding-bottom: 30px;
    width: 52vw;
    max-width: 700px;
    max-height: 600px;
    height: fit-content;
    border-radius: 10px;
    border: solid 1px rgb(233, 233, 233);
}


.authentication_page_body .form{
    text-align: -webkit-right;
    width: 100%;
    height: 100%;
}

.authentication_page_body .form-control {
    border: 1px solid #c4c3c3;
    border-radius: 6px;
    color: #2c2c2c;
    height: auto;
    font-size: 1rem;
    box-shadow: none;
    margin-bottom: 10px !important;
    height: 39px;
    background-image: none !important;
}

.authentication_page_body h2 {
    display: flex;
    justify-content: center;
    font-weight: 600;
    margin-bottom: 5px;
    font-size: 1.9rem;
}

.authentication_page_body label{
    width: 100%;
    text-align: left;
    margin: 0;
    font-size: 1rem;
    font-weight: 600;
    padding-left: 2px;
}

.authentication_page_body .form_footer{
    width: 100%;
    display: flex;
    justify-content: center;
}


.authentication_page_body .submit_but{
    all: unset;
    background:#414e5c;
    color: white !important;
    width: 210px;
    height: 34px;
    font-size: 1.18rem;
    border-radius: 8px;
    transition: filter 300ms;
    cursor: pointer;
    margin-top: 30px;
    box-shadow: none !important;
    position: relative !important;
}

.authentication_page_body .submit_but:hover{
    background:#414e5c;
    opacity: .9;
    box-shadow: none !important;
}


@media all and (max-width: 1080px){
    
    .authentication_page_container .logo_container{
        width: 70vw;
        min-width: 680px;
        height: 90px;
    }

    .authentication_page_container .authentication_page_body{
        width: 70vw;
        max-width: 550px;
        padding-bottom: 20px;
        padding-top: 35px;
        
    }
    .authentication_page_body h2 {
        font-weight: 600;
        margin-bottom: 3px;
        font-size: 1.8rem;
    }

    .authentication_page_body label{
        font-size: 1rem;
    }

    .authentication_page_body .form-control {
        font-size: .96rem;
        margin-bottom: 9px !important;
        height: 35px;
    }

    .authentication_page_body .my_icon{
        width: 19px !important;
        height: 19px !important;
    }

    .authentication_page_body .input-group-text:last-child {
        height: 35px;
    }
    

    .authentication_page_body .submit_but{
        width: 185px;
        height: 31px;
        font-size: 1.13rem;
        margin-top: 25px !important;
    }

}


@media all and (max-width: 620px){
    
    .authentication_page_container .logo_container{
        width: 100vw;
        min-width:auto;
        height: 100px;
    }
    .authentication_page_container .logo_container .brand {
        width: 55%;
    }


    .authentication_page_body label{
        font-size: .9rem;
    }

    .authentication_page_body .form-control {
        font-size: .89rem;
        margin-bottom: 8px !important;
        height: 35px;
    }


    .authentication_page_container .authentication_page_body{
        width: 80vw;
        max-width: 550px;
        padding: 28px;
        padding-top: 35px;        
        padding-bottom: 20px;        
    }
    .authentication_page_body h2 {
        font-weight: 600;
        margin-bottom: 3px;
        font-size: 1.6rem;
    }

    .authentication_page_body .submit_but{
        width: 160px;
        height: 27px;
        font-size: 1.08rem;
        margin-top: 20px !important;
    }
}


@media all and (max-width: 460px){
    
    .authentication_page_container .logo_container .brand {
        width: 70%;
    }
    .authentication_page_container .authentication_page_body{
        width: 95vw;
        padding: 2px;
        padding-top: 25px;        
        padding-bottom: 10px;        
    }

    .authentication_page_body label{
        font-size: .85rem;
    }

    .authentication_page_body .form-control {
        font-size: .82rem;
        margin-bottom: 7px !important;
        height: 35px;
    }

    .authentication_page_body h2 {
        font-weight: 600;
        margin-bottom: 3px;
        font-size: 1.6rem;
    }

    .authentication_page_body .submit_but{
        width: 140px;
        height: 25px;
        font-size: .98rem;
        margin-top: 15px !important;
    }
    .error{
        font-size: .9em !important;
        margin-bottom: 15px !important;
      }
      
}