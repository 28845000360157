@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@400;600;700&display=swap");


:root {
  --body-font: 'Nunito', sans-serif;
}


#root{
  height: 100%;
}

html {
  margin: 0;
  min-height: 100% !important;
    height: 100vh;
}



body {
    position: relative;
    font-family: var(--body-font) !important;
    min-height: 100% !important;
    height: 100vh;
    margin: 0;
    width: 100%;
}

.form-control,select {
  font-family: var(--body-font) !important;
}

.MuiSelect-select.MuiSelect-select{
  font-family: var(--body-font) !important;
}

.MuiInputBase-input.MuiInputBase-input{
  font-family: var(--body-font) !important;
}


.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.bg-cover {
  background-size: cover !important;
}

.MuiGrid-container {
  width: -moz-fit-content !important;
  width: fit-content !important;
  align-content: center !important;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  font-family: var(--body-font) !important;
}

.MuiGrid-container {
  flex-wrap: nowrap !important;
}

.MuiGrid-align-items-xs-flex-start {
  align-items: flex-end !important;
}

.MuiGrid-spacing-xs-1  {
  margin: 0px !important;
  height: 100% !important;
}

.MuiGrid-spacing-xs-1 > .MuiGrid-item {
  position: relative;
  top: 4px !important;
  margin-right: 1px !important;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  padding-top: 0 !important;
}

.MuiInputLabel-shrink {
  transform: translate(0, 4px) scale(0.75) !important;
}

.form{
  padding: 0 20px !important;
  padding-top:15px !important;
  padding-bottom: 10px !important;
}


.error{
  width: 100%;
  font-size: 1.1em;
  color: #e13d68 !important;
  background: #dc35451f;
  padding: 10px 20px;
  border-radius: 5px;
  margin-bottom: 15px;
  text-align: start;
}

.set_refuse_raison_container .error{
  width: 100%;
  font-size: 1.1em;
  color: #dd3461;
  background: #dc35451f;
  padding: 10px 20px;
  border-radius: 5px;
  margin: 0;
  margin-bottom: 15px;
  text-align: start;
}

.is-invalid{
  border-color: #dc3545 !important;
  background-image:none !important;
}


input:focus{
  border-color: #7faad4 !important;
}

input:focus + .input-group-text{
  border-color: #7faad4 !important;
}


.input-group .form-control:last-child, .input-group-text:last-child{
  border: 1px solid #c4c3c3;
  border-left: 0 none;
  border-radius: 6px;
  height: 39px;
  padding: 0 16px !important;
}

.invalid-inputgroup{
  border-color: #dc3545 !important;
  border-left: none;
}

.form-select:invalid  {
  border-color: #dc3545 !important;
  padding-right: 4.125rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e") !important;
  background-position: right .75rem center,center right 2.25rem;
  background-size: 16px 12px,calc(.75em + .375rem) calc(.75em + .375rem);
}

.form-select:valid {
  border-color:#ced4da;
  padding-right: 4.125rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e") !important;
  background-position: right .75rem center,center right 2.25rem;
  background-size: 16px 12px,calc(.75em + .375rem) calc(.75em + .375rem);
}


.set_refuse_raison_container .MuiInputLabel-outlined.MuiInputLabel-shrink{
  transform: translate(8px, -9px) scale(0.75) !important;
  z-index: 4 !important;
    background-color: white !important;
    padding: 0 5px !important;
}

.submit_but{
    all: unset;
    background:#343a40;
    color: white;
    position: relative !important;
    box-shadow: none !important;
    font-family: var(--body-font) !important;
    padding: .3rem 1.1rem !important;
    text-transform: capitalize !important;
    margin: 6px 5px;
    font-size: .9rem !important;
    border-radius: 4px !important;
    transition: filter 300ms;
    cursor: pointer;
    align-items: center;
    text-align: center;
}


.swal-button{
    font-family: var(--body-font) !important;
    padding: .5rem 1.1rem !important;
}

.swal-button--cancel {
  background-color: #e8e7e7 !important;
}

.swal-button--danger {
  background-color: #ef635d !important;
}

.border_button{
  background: none !important;
  border: solid 1px rgb(249, 249, 249) !important;
}

.border_button:hover{
  background: rgba(147, 147, 147, .4) !important;
}


.search_filter_container {
  display: flex;
  justify-content: flex-end;
}

.page_title{
  font-size: 1.25rem;
  display: flex;
  flex-direction: column;
  justify-content: end;
  margin-right: 15px;
}

.my_icon{
  width: 22px !important;
  height: 22px !important;
}

/* .submit_but .my_icon{
  width: 21px !important;
  height: 21px !important;
}
 */

.header_form {
    width:225px;
    margin:0px 20px 0px 0px !important;
}

.status_waiting{
  background-color:#ff9b0a !important;
  font-size:.55rem !important;
  color: white !important;
  padding:.15rem .26rem !important;
  padding-top: .215rem !important;
  cursor:none !important;
  height: fit-content;
  align-self: center;
  box-shadow: none !important;
}

.status_inprogress{
  background-color:#1e73b8 !important;
  font-size:.55rem !important;
  color: white !important;
  padding:.15rem .26rem !important;
  padding-top: .215rem !important;
  cursor:none !important;
  height: fit-content;
  align-self: center;
  box-shadow: none !important;
}

.status_success{
  background-color:#1eb873 !important;
  font-size:.55rem !important;
  color: white !important;
  padding:.15rem .26rem !important;
  padding-top: .215rem !important;
  cursor:none !important;
  height: fit-content;
  align-self: center;
  box-shadow: none !important;
}

.status_deny{
  background-color:#f33640f3 !important;
  font-size:.55rem !important;
  color: white !important;
  padding:.15rem .26rem !important;
  padding-top: .215rem !important;
  cursor:none !important;
  height: fit-content;
  align-self: center;
  box-shadow: none !important;
}

.MuiButton-label {
  width: 100%;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.MuiButton-root {
  min-width: 50px !important;
}


.MuiRating-root {
  font-size: calc(100% + .25rem) !important;
}

.documentsDownload label {
  display: inline-block;
  margin-bottom: 0 !important;
}

.disabled_button{
  pointer-events: none !important;
  opacity: 0.6 !important;
}

.dialogBottom .error {
  width: 100%;
  font-size: 1.1em;
  color: #dd3461 !important;
  background: #dc35451f;
  padding: 10px 20px;
  border-radius: 5px;
  margin: 0 !important;
  text-align: start;
}

.MuiTable-root {
  height: 100% !important;
}

.MuiTableContainer-root {
  height: 100% !important;
}

.MuiTableCell-root {
  padding: 10px !important;
}

.MuiTableRow-head{
  background-color:#f6f6f6;
  padding:0 !important;
}

.MuiTableCell-root {
  padding-right: 7px !important;
  padding-left: 7px !important;
}

.MuiTableCell-head {
  font-family: var(--body-font) !important;
  font-weight: 700 !important;
}

.MuiTableCell-body{
  font-family: var(--body-font) !important;
}


.MuiTypography-h6{
  font-family: var(--body-font) !important;
}

.Mui-focused{
  background-color: white !important;
  border: none !important;
}


.apexcharts-text{
  font-family: var(--body-font) !important;
  font-size:.8rem !important;
} 

.apexcharts-yaxis-title-text{
  font-family: var(--body-font) !important;
  font-size:1rem !important;
}

/*
.MuiDialog-paperFullWidth {
  width: calc(100% - 64px) !important;
  height: calc(100% - 75px) !important;
  margin: 5px auto !important;
}*/

.round_button{
  border-radius: 50% !important;
}

.round_button:hover{
  opacity: .9;
}

.MuiFab-root{
  width: 36.5px !important;
  height: 36.5px !important;
  border-radius: 50% !important;
  background-color: rgb(231, 231, 231);
  min-height: 20px !important;
}


.closeSharpIcon{
  display:flex;
  background-color: rgb(253, 253, 253);
  border-radius: 100%;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  padding: 0.08rem !important;
  color: #ff0000 !important;
}

.closeSharpIcon:hover{
  background-color: #fcf5f5;
}

.checkIcon{
  display:flex;
  background-color: rgb(253, 253, 253);
  border-radius: 100%;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  padding: 0.08rem !important;
  color: #00b200 !important;
}

.checkIcon:hover{
  background-color: #e9fce9;
}

.editIcon{
  display:flex;
  background-color: rgb(253, 253, 253);
  border-radius: 100%;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  padding: 0.08rem !important;
  color: #3c7782 !important;
  width: 1.6rem !important;
  height: 1.6rem !important;
}

.editIcon:hover{
  background-color: #e9fce9;
}

.my_full_dialog .page_title{
  flex-direction: row;
  align-items: center;
}

.dialogBottom {
  display: flex;
  padding-top: 3px;
  justify-content: space-between;
  margin: 5px 10px !important;
  width: 100%;
}

.set_refuse_raison_container  .dialogBottom div:nth-child(1) {
  width: 100% !important;
  text-align: -webkit-right;
  padding-top: 3px;
}

.dialogBottom div:nth-child(1) {
  width: 100% !important;
  text-align: -webkit-right;
  padding-top: 3px;
  padding-right: 0 !important;
}

.dialogBottom div:nth-child(2) {
  width: fit-content;
  margin-bottom: 0px;
  display: flex;
  justify-content: flex-end;
}

.dialogBottom .error{
  margin-right: 16px !important;
}


.pagination_container{
  margin: 25px auto 40px auto;
  width: fit-content;
}

.pagination {
  height: -moz-fit-content;
  height: fit-content;
  width: fit-content;
  padding: 0;
}

.pagination li a {
    position: relative;
    display: block;
    color: #0d6efd;
    text-decoration: none;
    font-size: .92rem;
    background-color: #fff;
    border: 1px solid #dee2e6;
    padding:0.5rem 1rem;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.pagination li{
  padding: 0;
  margin: 0;
}

.pagination li a:hover {
  background-color: rgb(236, 236, 236) !important;
}

.pagination .active {
  background-color: rgb(236, 236, 236) !important;
}

.pagination  .undefined{
  background-color: rgb(236, 236, 236) !important;
}

.MuiAppBar-root {
  box-shadow: none !important;
}

input::-webkit-outer-spin-button, input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}


input[type=number] {
  opacity: 1 !important;
}

.add_new_object_fab{
  border: solid 1px #3c7782;
  background-color:#f7f7f7 !important;
  padding: 0 !important;
  color: #3c7782 !important;
  cursor:pointer;
  transition: .5s ease-in;
  margin-left: 15px !important;
  width: 30px !important;
  height: 30px !important;
}

.add_new_object_fab_big{
  width: 36px !important;
  height: 36px !important;
}

.add_new_object_fab:hover{
  background-color: #e9fce9 !important;
  transition: .5s ease-in;
}

.success_object_fab{
  border: solid 1px white;
  background-color:#18ac67 !important;
  padding: 0 !important;
  color: white !important;
  cursor:pointer;
  transition: 1s ease-in;
  margin: 6px 6px 6px 5px !important;
}

.success_object_fab:hover{
  background:#18ac67ee !important;
    opacity: 1 !important;
}

.cancel_object_fab{
  border: solid 1px white;
  background-color:#e92a44 !important;
  padding: 0 !important;
  color: white !important;
  cursor:pointer;
  transition: 1s ease-in;
  margin: 6px 3px 6px 3px !important;
}

.cancel_object_fab:hover{
  background:#e92a43ee !important;
    opacity: 1 !important;
}

.add_new_object_icon{
  width: 1.1em !important;
  height: 1.1em !important;
}
