.service_header{
    width: max-content;
    padding:0;
    z-index:3;
    position:fixed;
    top: 0;
}


.menu_admin{
    width: 100%;
    display: flex;
    justify-content: flex-start;
}

.menu_admin #service_menu_holder{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: 12px;
    margin-right: .5rem;
    cursor: pointer;
    z-index: 1;
    position: absolute;
    top: 20px;
    transform: translateX(5px);
    transition: all 400ms ease-in-out;
}

.menu_admin #service_menu_holder > div{
    width: 30px;
    height: 3.5px !important;
    border-radius: 4px;
    margin: 2.5px;
    background-color: rgb(252, 248, 248);
    transition: all 500ms ease-in-out;
    z-index: -1;
}


.menu_admin #service_menu_holder.open > div:nth-child(1){
    transform: translateY(8px) rotateZ(45deg);
}
.menu_admin #service_menu_holder.open > div:nth-child(2){
    transform: translateX(50px);
    opacity: 0;
}
.menu_admin #service_menu_holder.open > div:nth-child(3){
    transform: translateY(-9.4px) rotateZ(-45deg);
}

.menu_admin .service_content {
    background: rgb(3, 113, 119) !important;
    height: 100vh;
    display: flex;
    flex-direction: column;
    transition: all 500ms;
    z-index: 3;
}


.menu_admin .service_content > div {
    display: flex;
    flex-direction: column;
}

.menu_admin .service_content .brand {
    cursor: pointer;
    display: block !important;
    width: -moz-fit-content !important;
    width: 59px !important;
    position: relative !important;
    left: -2px !important;
    margin-top: 3px;

}

.menu_admin .service_content.show {
    z-index: 3;
    width: 60px !important;
    position: fixed;
}


.menu_admin > div > div > a:hover{
    background-color: rgba(172, 171, 171, 0.1);
}


.menu_admin #drop1.show{
    height:65px !important;
}


.menu_admin .menu_items{
    margin-top: 5px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}

.menu_admin .menu_item{
    display: flex;
    color: white;
    text-transform: uppercase;
    width: 99%;
    justify-content: space-between;
    height: 2.4rem !important;
    align-items: center;
    align-content: center;
    padding: 0 5px 0 10px;
    cursor: pointer;
    transition: all 200ms;
}

.menu_admin .menu_item_selected{
    background-color:rgba(230, 218, 218, 0.292);
    transition: all 200ms;
}

.menu_admin .menu_item:hover{
    background-color:rgba(230, 218, 218, 0.292);
    transition: all 200ms;
}


.menu_admin .menu_item div:nth-child(2){
    width: 210px !important;
    padding-top: 2px !important;
}

.menu_admin .menu_item a{
    text-decoration:none;
	color: white;
	padding: 7px 10px;
	font-size: 10.5pt;
    font-weight: 400;
}

.menu_admin .menu_item .MuiSvgIcon-root{
    width: 2.3rem !important;
    height: 1.55rem !important;
}




.service_body{
    height: calc(100vh - 72px);
    width: calc(100vw - 60px);
    margin-top: 72px ;
    margin-left: 60px;
    overflow: auto;
}