.responsive_navVertical{
    width: fit-content;
    height: 100vh;
    display: flex;
    justify-content: flex-start;
}

.responsive_navVertical .responsive_service_content {
    position: absolute;
    height: 100vh;
    width: 0;
    display: flex;
    overflow: hidden;
    flex-direction: column;
    justify-content: space-between;
    transition: all 500ms;
    z-index: 2000 !important;
}

.responsive_navVertical .responsive_service_content.show {
    transform: translateX(0px);
    z-index: 3;
    width: fit-content;
    width: 255px;
}

.responsive_navVertical .responsive_service_content .company_nav_logo_container {
    cursor: pointer;
    width: 100% !important;
    height: 68px !important;
    position: relative !important;
    left: -2px !important;
    margin-top: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
}


.responsive_navVertical .responsive_service_content .company_nav_logo_container img {
    width: 52px;
    height: 59px !important;
    object-fit: scale-down;
}

.responsive_navVertical .responsive_service_content > div {
    display: flex;
    flex-direction: column;
    min-width: 250px;
    width: 100%;
}

.responsive_navVertical #service_menu_holder{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    z-index: 3 !important;
    position: absolute;
    transition: all 500ms;
    height: 68px;
    width: 55px;
    position:fixed;
}

.responsive_navVertical #service_menu_holder > div{
    width: 25px;
    height: 3.2px !important;
    border-radius: 4px;
    margin: 2.5px;
    transition: all 500ms ease-in-out;
    z-index: 3;
}


.responsive_navVertical #service_menu_holder.open{
    transition: all 500ms;
    transform: translateX(249px);
}

.responsive_navVertical #service_menu_holder.open > div:nth-child(1){
    transform: translateY(8px) rotateZ(45deg);
}
.responsive_navVertical #service_menu_holder.open > div:nth-child(2){
    transform: translateX(50px);
    opacity: 0;
}
.responsive_navVertical #service_menu_holder.open > div:nth-child(3){
    transform: translateY(-8px) rotateZ(-45deg);
}

.responsive_navVertical .title_menu_item{
    font-size: .9rem;
    font-weight: 400;
}

.responsive_navVertical .menu_item{
    width: 100%;
}

.responsive_service_body{
    height: calc(100vh - 72px);
    width: 100vw;
    margin-top: 72px;
    overflow: auto;
}