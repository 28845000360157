.chatModal_container .MuiDialog-paperFullWidth {
    width: 60%;
    height: 100% !important;
    margin: 5px auto !important;
    padding: 0 !important;
}

.chatModal_container .MuiDialogContent-root{
    padding: 0 !important;
    overflow: hidden !important;
}



.chatModal_container #user{
    border-bottom: 1px solid; font-size: 16pt; display: flex; align-items: center;
}

.chatModal_container .modal-content .modal-body {
    padding: 0 !important;
}


.chatModal_container .chatView_container {
    height: 100%;
    flex:1;
    background-color: rgb(249 249 249);
    border: solid 1px rgb(87, 87, 87);
    display: flex;
    flex-direction: column;
}


.chatModal_container .contact-profile {
    width: 100%;
    height: fit-content;
    font-weight: 600;
    padding: 15px !important;
    background: #f5f5f5;
    font-size: 1.3rem !important;
  }

  .chatView_container .messages {
    flex: 1;
    min-height: auto !important;
    overflow-y: scroll;
    overflow-x: hidden;
    width: 100%;
    padding: 0 !important;
    margin-bottom: 0;
  }


  .chatView_container ul li:nth-last-child(1) {
    margin-bottom: 12px !important;
  }

  .chatView_container .messages::-webkit-scrollbar {
    width: 8px;
    background: transparent;
    cursor: pointer;
  }
  .chatView_container .messages::-webkit-scrollbar-thumb {
    background-color: rgba(141, 141, 141);
    cursor: pointer;
  }
  .chatView_container ul li {
    display: inline-block;
    clear: both;
    width: calc(100% - 60px);
    font-size: .95em;
    margin: 9px 0;
  }

  .chatView_container .emetteur{
    position: relative;
    display: inline-block;
    justify-content: flex-start;
    margin: 9px 3.5rem !important;
    font-family: nunito;
}
.chatView_container .emetteur > span{
    min-width: 10ch;
    width: fit-content;
    display: flex;
    align-items: center;
    max-width: 40ch;
    padding: .3rem .5rem;
    border-radius: .2rem;
    background: #2c3e50bd;
    position: relative;
    color: white !important;

}

.chatView_container .emetteur >  div:nth-child(1){
    position: relative;
    left: -2.5rem;
    font-size: 1rem;
    font-weight: 700;
}

.chatView_container .emetteur .temps{
    font-size: .8rem;
    top: 100%;
    left: 0%;
    width: max-content;
}


.destinateur{
    position: relative;
    font-family: nunito;
    text-align: -webkit-right;
}

.destinateur > span{
    border: solid 1px gray;
    min-width: 10ch;
    text-align: left !important;
    width: fit-content;
    display: flex;
    align-items: center;
    max-width: 40ch;
    padding: .3rem .5rem;
    border-radius: .2rem;
    background: whitesmoke;
    position: relative;
} 
.destinateur > div:nth-child(1){
    font-size: 1rem;
    font-weight: 700;
    position: relative;
    max-width: 8ch;
    overflow: hidden;
    text-overflow: ellipsis;
    left: 45px;
}


#messages .text > span{
    word-wrap: break-word;
    max-width: 38ch;
    display: block;
}

.input_container{
    height: fit-content;
    display: flex;
    border-top: solid 1px #777777;
    padding: 0px;
    margin: 0 !important;
    padding: 10px 14px;
}

.input_container textarea{
    max-width: 100% !important;
    max-height: none !important;
    padding: 9px 5px 7px 9px;
    resize: auto !important;
    font-size: 1rem !important;
    border: solid 0.1rem #777777 !important;
    color: #555353;
    border-radius: 7px;
    line-height: 1 !important;
    margin: 0 !important;
}

.textarea_container{
    min-width:94%;
    max-width:94%;
    height:100%;
    position: relative;
}

.sendBut_container{
    align-self: center;
    min-width: 15px;
    margin-left: 7px;
}



.chatModal_container #frame {
    width: 100%;
    min-width: 360px;
    height: 100%;
    min-height: 300px;
    max-height: 720px;
    background: #E6EAEA;
    display: flex;
  }


.chatModal_container #frame #sidepanel {
    float: left;
    min-width: 240px;
    max-width: 270px;
    width: 40%;
    background: #405e7c;
    color: #f9f9f9;
    overflow: hidden;
    position: relative;
  } 

  .chatModal_container .contact:hover {
    background: #537295;
  }
  .chatModal_container .contact.active {
    background: #537295;
  }
  .chatModal_container .contact.active span.contact-status {
    border: 2px solid #32465a !important;
  }


.chatModal_container  #frame #sidepanel #search {
    border-top: 1px solid #32465a;
    border-bottom: 1px solid #32465a;
    background: #f5f5f5;
  }
  
  
  .chatModal_container  #frame #sidepanel #search label {
    position: absolute;
    margin: 6px 0 0 8px;
    color: #32465a;
    background-color: transparent;
  }
  .chatModal_container  #frame #sidepanel #search input {
    font-family: nunito;
    padding: 10px 0 10px 32px;
    width: calc(100% - 3px);
    border: none;
    color: #32465a;
    background: #f5f5f5;
    height: 35px;
  }
  .chatModal_container  #frame #sidepanel #search input:focus {
    outline: none;
  }
  
  .chatModal_container  #frame #sidepanel #contacts {
    height: calc(100% - 177px);
    overflow-y: auto;
    overflow-x: hidden;
    text-overflow: ellipsis;
  }
  
  .chatModal_container  #frame #sidepanel #contacts::-webkit-scrollbar {
  width: 8px;
  background: #2c3e50;
  }
  .chatModal_container  #frame #sidepanel #contacts::-webkit-scrollbar-thumb {
  background-color: #8d8d8d;
  }


  .chatModal_container  #frame #sidepanel #contacts ul{
	list-style: none;
        padding-left:0;
        margin:0;
     }

  
  
  @media all and (max-width: 900px) {
    .chatModal_container  #frame #sidepanel {
      width: 58px;
      min-width: 58px;
    }
  
    .chatModal_container  #frame #sidepanel #profile {
      width: 100%;
      margin: 0 auto;
      padding: 5px 0 0 0;
      background: #32465a;
    }
    .chatModal_container  #frame #sidepanel #profile .wrap {
        height: 55px;
    }
    .chatModal_container  #frame #sidepanel #profile .wrap img {
        margin-left: 0.2rem;
    }
    .chatModal_container  #frame #sidepanel #profile .wrap p {
        display: none;
    }
    .chatModal_container  #frame #sidepanel #search {
        display: none;
    }
    .chatModal_container  #frame #sidepanel #contacts {
        height: calc(100% - 149px);
        overflow-y: scroll;
        overflow-x: hidden;
    }

    .chatModal_container  #frame #sidepanel #contacts::-webkit-scrollbar {
      display: none;
    }

    .chatModal_container .contact .wrap .meta {
        display: none !important;
    }
}
  
@media all and (max-width: 975px){
    .emetteur > span, .destinateur > span{
        max-width: 35ch !important;
    }
}

@media all and (max-width: 880px){
    .chatModal_container .MuiDialog-paperFullWidth {
        width: 75% !important;
    }
}



@media all and (max-width: 750px){
    .chatModal_container .MuiDialog-paperFullWidth {
        width: 80% !important;
    }

    .chatModal_container .contact-profile {
        padding: 12px !important;
        font-size: 1.2rem !important;
    }

    .emetteur > div:nth-child(1), .destinateur > div:nth-child(1) {
        font-size: .95rem !important;
    }

    .chatView_container .emetteur {
        margin: 7px 3rem !important;
    }

    .chatView_container .emetteur > div:nth-child(1) {
        left: -2.2rem;
    }

    .chatView_container .destinateur{
        margin: 7px 0 !important;
    }
    .chatView_container ul li {
        width: calc(100% - 55px) !important;
        font-size: .9em !important;
    }

    .chatView_container .emetteur .temps {
        font-size: .75rem;
    }

    .input_container textarea{
        padding: 7px 4px 6px 7px;
        font-size: .95rem !important;
    }

    .emetteur > span, .destinateur > span{
        max-width: 32ch !important;
    }

    .chatView_container .messages::-webkit-scrollbar {
      display: none;
    }
}



@media all and (max-width: 580px){
    .chatModal_container .MuiDialog-paperFullWidth {
        width: 89% !important;
    }

    .chatModal_container .contact-profile {
        padding: 9px !important;
        font-size: 1.1rem !important;
    }

    .emetteur > div:nth-child(1), .destinateur > div:nth-child(1) {
        font-size: .9rem !important;
    }

    .chatView_container .emetteur {
        margin: 5px 2.5rem !important;
    }

    .chatView_container .emetteur > div:nth-child(1) {
        left: -2rem !important;
    }

    .chatView_container .destinateur{
        margin: 5px 0 !important;
    }
    .chatView_container ul li {
        width: calc(100% - 55px) !important;
        font-size: .85em !important;
    }

    .chatView_container .emetteur .temps {
        font-size: .7rem;
    }

    .input_container textarea{
        padding: 7px 4px 5px 6px;
        font-size: .9rem !important;
    }

    .emetteur > span, .destinateur > span{
        max-width: 27ch !important;
    }

    .chatModal_container #frame #sidepanel {
        width: 48px;
        min-width: 48px;
    }
    .chatModal_container .contact {
      padding: 5px 0 3px 0 !important;
    }

    .chatModal_container .contact .wrap .img_cont {
      margin: auto;
  }
      .chatModal_container .contact .wrap img {
        width: 38px;
        height: 38px;
    }
}


@media all and (max-width: 450px){
    .chatModal_container .MuiDialog-paperFullWidth {
        width: 97% !important;
    }

    .emetteur > div:nth-child(1), .destinateur > div:nth-child(1) {
        font-size: .85rem !important;
    }

    .input_container textarea{
        padding: 5px 5px 4px 5px;
        font-size: .85rem !important;
    }

    .emetteur > span, .destinateur > span{
        max-width: 23ch !important;
    }

    .chatModal_container #frame #sidepanel {
        width: 45px;
        min-width: 45px;
    }

    .chatModal_container .contact {
      padding: 5px 0 3px 0 !important;
    }

    .chatModal_container .contact .wrap .img_cont {
        margin: auto;
    }
        .chatModal_container .contact .wrap img {
          width: 35px;
          height: 35px;
      }
}






.chatModal_container .contact {
    position: relative;
    padding: 10px 0;
    font-size: 0.85em;
    cursor: pointer;
  }
  
 .chatModal_container p {
    margin:0;
  }
  
  
  .chatModal_container .contact .wrap {
    width: 92%;
    margin: 0 auto;
    position: relative;
    display: flex;
  }
  .chatModal_container .contact .wrap span {
    position: absolute;
    left: 0;
    margin: -2px 0 0 -2px;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    border: 2px solid #2c3e50;
    background: #e74c3c;
  }
  .chatModal_container .contact .wrap span.online {
    background: #2ecc71;
  }
  
  .chatModal_container .contact .wrap img {
    width: 40px;
    height: 40px;
    border-radius: 100%;
    float: left;
    margin-right: 8px;
    border: 1px solid #929292;
    object-fit: cover;
    background-color: rgb(253 253 253 / 48%);
  }
  .chatModal_container .contact .wrap .meta {
    padding:0;
    font-family: nunito;
    width: 70%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .chatModal_container .contact .wrap .meta .name {
    height: 1.35rem;
    font-size: .95rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 95%;
  }
  .chatModal_container .contact .wrap .meta .preview {
    margin: 0;
    padding: 0;
    font-weight: 300;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    -moz-transition: 1s all ease;
    -o-transition: 1s all ease;
    -webkit-transition: 1s all ease;
    transition: 1s all ease;
    height:19px;
    width: 83%;
    color: rgb(206, 206, 206);
    font-size: .77rem;
  }
  .chatModal_container .contact .wrap .meta .preview span {
    position: initial;
    border-radius: initial;
    background: none;
    border: none;
    padding: 0 2px 0 0;
    margin: 0 0 0 1px;
    opacity: .5;
  }
  
 .chatModal_container #text_non_lue{
    position: absolute;
    right: 1%;
    top: 27%;
    margin: 0 !important;
    width: 18px !important;
    height: 18px !important;
    border-radius: 100%;
    background: #4BEA82;
    padding: 0 !important;
    display: flex;
    justify-content: center;
    align-items: center;
    color: black;
  }
  
  @media all and (max-width: 900px) {
    .chatModal_container .contact {
      padding: 6px 0 8px 8px;
    }
    .chatModal_container .contact .wrap {
      width: 100%;
    }
    .chatModal_container .contact .wrap img {
      margin-right: 0px;
    }
    .chatModal_container .contact .wrap .meta .preview{
      display: none;
    }
  
    .chatModal_container .contact .wrap .meta .name{
      height: 15px;
      width: 80%;
      overflow: hidden;
      font-size: 0.85rem;
      text-overflow: ellipsis;
    }
  
    .chatModal_container #text_non_lue{
      position: absolute;
      top: 1.7rem;
      right: .25rem;
      font-size: .7rem;
      width: 15px !important;
      height: 15px !important;
      color: #060606;
    }
    
  }

