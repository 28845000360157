.commissions_container{
    height: fit-content;
    width: 100%;
}

.commissions_container_header{
    font-size: 1.2rem;
    padding: 0 20px 15px 20px;
    width: 95%;
    margin: auto;
    margin-top: 30px;
    margin-bottom: 20px;
    border-bottom: solid 1px black;
    display: flex;
    justify-content: space-between;
}


.commissions_container_header .MuiGrid-container {
    width: fit-content !important;
    align-content: center !important;
    position: relative !important;
}


.commissions_container .MuiTableCell-body:nth-child(1) {
    min-width:100px !important;
    max-width:100px !important;
}

.commissions_container .MuiTableCell-body:nth-child(2) {
    min-width:85px !important;
    max-width:85px !important;
}

.commissions_container .MuiTableCell-body:nth-child(4),.commissions_container .MuiTableCell-body:nth-child(5) {
    min-width:110px !important;
    max-width:110px !important;
}

.commissions_container .MuiTableCell-body:nth-child(6) {
    min-width:220px;
}

.commissions_container .MuiTableCell-body:nth-child(7) {
    min-width:75px;
}

.commissions_container .MuiTableCell-body:nth-child(8) {
    min-width:85px;
}


.commissions_container_header .search_system{
    display: flex;
}

.commissions_table_container{
    width: 99%;
    margin: auto;
    margin-bottom: 50px;
}

.commissions_table_container .documentsContainer {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
  
.commissions_table_container .AccordCtrl_container{
    width: -moz-fit-content !important;
    width: fit-content !important;
    height: 100%;
    display: flex !important;
    flex-direction: column !important;
    justify-content: center !important;
    align-items: center !important;
}