.MuiPaper-rounded {
    padding: 0 !important;
    border-radius: 0 !important;
}

.charts_of_bills_count_byState_container{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}


.charts_of_bills_count_byState_container .MuiTable-root {
    min-width: 750px !important;
}


.charts_of_bills_count_byState_container .bills_count_byState_state{
    width: 100%;
    height: fit-content;
    display: flex;
    justify-content: space-between;
    padding-right: 15px;
    padding-left: 15px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.charts_of_bills_count_byState_container .bills_count_byState_state .MuiSelect-select.MuiSelect-select {
    color: rgb(74, 76, 74) !important;
    font-size: .9rem;
    padding: 3px 0;
    padding-top: 3.5px;
}

.charts_of_bills_count_byState_container .bills_count_byState_state .MuiInput-underline:before {
    border: none !important;
}

.charts_of_bills_count_byState_container .bills_count_byState_state .MuiSelect-icon {
    color: rgb(74, 76, 74) !important;
}

.charts_of_bills_count_byState_container .bills_count_byState_state .MuiInputBase-root {
    font-family: var(--body-font) !important;
    border: solid 1px rgb(74, 76, 74) !important;
    padding-left: 6px !important;
    border-radius: 4px !important;
}

.charts_of_bills_count_byState_container .bills_count_byState_state .MuiSelect-nativeInput {
    left: 0;
    width: 100%;
    bottom: 0;
    opacity: 0;
    position: absolute;
    pointer-events: none;
}


.charts_of_bills_count_byState_container .container{
    width: 100%;
    height: fit-content;
    flex:1;
    padding: 0 !important;
}


.bills_widget_table_container{
    padding: 0 !important;
    border-radius: 0 !important;
    margin: 0 !important;
    font-size: .5rem !important;
}


.bills_widget_table_container .MuiTableCell-body:nth-child(1) {
    min-width:125px !important;
}

.bills_widget_table_container .MuiTableCell-body:nth-child(2) {
    min-width:65px !important;
}

.bills_widget_table_container .bill_lign{
    margin-bottom: 4px;
    width: 100%;
}