
.dialogBottom div:nth-child(1){
    width: 65%;
    text-align: -webkit-right;
    padding-top: 3px;
}


.inputs_container{
    width: 100%;
    height: fit-content;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 10px;
    margin-bottom: 20px;
}


.inputs_container:last-child{
    margin-top: 40px;
    margin-bottom: 10px;
}

.input_form{
    width:250px;
    margin:0px 45px 10px 0px !important;
}

.inputs_container > .input_form:last-child{
    margin:0px 5px 10px 0px !important;
}

.inputs_container > label .input_form:last-child{
    margin:0px 5px 10px 0px !important;
    text-transform: none !important;
}


.dialogBottom .submit_but{
    background:#343a40;
    margin: 5px 6px;
    color: white;
}

.dialogBottom .submit_but:hover{
    background:#343a40;
    opacity: 0.9;
}

.dialogBottom .cancel_but{
    background:#e92a44;
}

.dialogBottom .cancel_but:hover{
    background:#e92a44;
    opacity: 0.9;
}

.dialogBottom .valide_but{
    background:#18ac67;
}

.dialogBottom .valide_but:hover{
    background:#18ac67;
    opacity: 0.9;
}




.update_porpose .documentsDownload{
    width: fit-content;
    display: flex;
    vertical-align: middle;
    align-items: center;
    margin-right: 10px;
    margin-top: 10px;
}

.update_porpose .documentsDownload:last-child{
    margin-right: 0px;
}


.update_porpose .documentsDownload .download_button{
    margin-left: 12px !important;
    color: black;
}


.update_porpose .documentsTitle{
    font-size: 1rem;
    margin-left: 9px;
  }


  
@media all and (max-width: 1080px){
    
    .inputs_container{
        margin-top: 6px;
        margin-bottom: 12px;
    }
    
    
    .inputs_container:last-child{
        margin-top: 25px;
        margin-bottom: 0px;
    }

    .input_form{
        width:250px;
        margin:0px 45px 10px 0px;
    }

}


  
@media all and (max-width: 941px){
    
    .inputs_container{
        margin-top: 6px;
        margin-bottom: 12px;
    }
    
    
    .inputs_container:last-child{
        margin-top: 25px;
        margin-bottom: 0px;
    }

    .input_form{
        width:235px !important;
        margin:0px 15px 10px 0px !important;
    }

    .update_porpose .documentsDownload .download_button{
        margin-left: 8px !important;
        color: black;
    }
    
    .update_porpose .documentsTitle{
        font-size: .95rem;
        margin-left: 8px;
    }

}


@media all and (max-width: 836px){
    
    .inputs_container{
        margin-top: 6px;
        margin-bottom: 12px;
    }
    
    
    .inputs_container:last-child{
        margin-top: 25px;
        margin-bottom: 0px;
    }

    .input_form{
        width:205px !important;
        margin:0px 10px 5px 0px !important;
    }

}



@media all and (max-width: 750px){
    
    .inputs_container{
        margin-top: 0px;
        margin-bottom: 0px;
    }
    
    
    .inputs_container:last-child{
        margin-top: 10px;
        margin-bottom: 0px;
    }

    .input_form{
        width:180px !important;
        margin:7px 25px 5px 0px !important;
        font-size: .87rem !important;
    }

    .inputs_container > .input_form:last-child{
        margin:7px 25px 5px 0px !important;
    }

    .inputs_container > label .input_form:last-child {
        font-size: .78rem !important;
    }

    .update_porpose .documentsTitle{
        font-size: .87rem;
        margin-left: 6px;
    }


}


@media all and (max-width: 620px){
    
    .input_form{
        width:175px !important;
        margin:7px 10px 5px 0px !important;
        font-size: .87rem !important;
    }

    .inputs_container > .input_form:last-child{
        margin:7px 10px 5px 0px !important;
    }

    .inputs_container > label .input_form:last-child {
        font-size: .78rem !important;
        width: 165px !important;
    }


    .update_porpose .documentsTitle{
        font-size: .82rem;
        margin-left: 6px;
    }

    .update_porpose .inputs_container:last-child{
        justify-content: flex-start !important;
    }

    .update_porpose .documentsDownload{
        margin-right: 30px;
    }

}



@media all and (max-width: 440px){
    
    .inputs_container{
        margin-top: 0px;
        margin-bottom: 0px;
    }
    
    
    .inputs_container:last-child{
        margin-top: 10px;
        margin-bottom: 0px;
    }

    .input_form{
        width:165px !important;
        margin:7px 5px 5px 0px !important;
        font-size: .8rem !important;
    }

    .inputs_container > .input_form:last-child{
        margin:7px 5px 5px 0px !important;
    }

    .inputs_container > .input_form:nth-child(2){
        margin:7px 0px 5px 0px !important;
    }

    .inputs_container > label .input_form:last-child {
        font-size: .75rem !important;
        width: 165px !important;
    }

    .update_porpose .documentsDownload{
        margin-right: 15px;
    }
}