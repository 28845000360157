@media all and (max-width: 1080px){
    
    .MuiSelect-select.MuiSelect-select{
        font-size: .9rem !important;
    }

    .MuiFormLabel-root {
        font-size: .9rem !important;
    }

    .MuiMenuItem-root, .MuiTypography-body1 {
        font-size: .9rem !important;
        padding-bottom: 4px;
        padding-top: 4px;
    }
    
    .MuiInputBase-input.MuiInputBase-input, .MuiInputBase-formControl, textarea{
        font-size: .9rem !important;
    }

    .MuiInputLabel-shrink {
        transform: translate(0, 6.5px) scale(0.7) !important;
    }

    .error{
        font-size: 1.05em !important;
        padding: 10px 20px !important;
        margin-bottom: 20px !important;
    }

    .page_title{
        font-size: 1.25rem;
    }  
    
    .header_form {
        width:205px;
        margin:0px 35px 0px 0px;
    }

    .submit_but{
        padding: .29rem 1rem !important;
        margin: 6px 5px !important;
        font-size: .88rem !important;
        border-radius: 6px;
    }

    .MuiFab-root{
        width: 35px !important;
        height: 35px !important;
    }

    .my_icon{
        width: 20px !important;
        height: 20px !important;
    }

    .MuiTableCell-root {
        padding: 8px 10px 8px 10px !important;
        font-size: 0.88rem !important;
        line-height: 1.2rem !important;
    }

    .MuiDialogContent-root {
        flex: 1 1 auto;
        padding: 6px 16px !important;
    }

    .status_success, .status_waiting, .status_deny {
        font-size: .5rem !important;
        padding: 0.13rem 0.23rem !important;
        padding-top: 0.205rem !important;
    }
      

    .pagination_container{
        margin: 23px auto 38px auto;
      }
      
      .pagination li a {
          font-size: .88rem;
          padding:0.5rem .9rem;
      }
}



@media all and (max-width: 750px){
    
    .MuiSelect-select.MuiSelect-select{
        font-size: .87rem !important;
    }

    .MuiFormHelperText-root {
        font-size: 0.6rem !important;
    }

    .MuiTypography-h6 {
        font-size: 1.2rem !important;
    }

    .MuiFormLabel-root {
        font-size: .87rem !important;
    }

    .MuiMenuItem-root, .MuiTypography-body1 {
        font-size: .87rem !important;
        padding-bottom: 4px;
        padding-top: 4px;
    }

    .MuiGrid-spacing-xs-1 {
        height: fit-content !important;
    }

    .MuiInputBase-input.MuiInputBase-input, .MuiInputBase-formControl, textarea{
        font-size: .87rem !important;
    }
    
    .MuiTableCell-root {
        padding: 8px 10px 8px 10px !important;
        font-size: 0.82rem !important;
        line-height: 1rem !important;
    }

    .page_title{
        font-size: 1.15rem;
    }  

    .header_form {
        width:185px;
        margin:0px 15px 0px 0px;
    }

    .MuiFab-root {
        width: 33px !important;
        height: 33px !important;
    }

    .status_success, .status_waiting, .status_deny {
        font-size: .46rem !important;
        padding: 0.15rem 0.2rem !important;
        padding-top: 0.15rem !important;
    }


    .MuiDialog-paper {
        margin: 20px !important;
    }

    .MuiDialog-paperFullWidth {
        width: 100% !important;
    }

    .MuiDialogTitle-root {
        padding: 7px 9px !important;
    }

    .MuiDialogContent-root {
        flex: 1 1 auto;
        padding: 6px 12px !important;
    }
      
    .dialogBottom {
        margin: 3px 7px !important;
    }

    .my_icon{
        width: 17px !important;
        height: 17px !important;
    }

    .submit_but{
        padding: .24rem 1rem !important;
        font-size: .85rem !important;
    }

    .pagination_container{
        margin: 21px auto 34px auto;
      }
      
      .pagination li a {
          font-size: .85rem;
          padding:0.45rem .85rem;
      }
    
}





@media all and (max-width: 580px){
    
    .header_form {
        width:180px;
        margin:0px 6px 0px 0px !important;
    }

    .submit_but{
        padding: .25rem .9rem !important;
        font-size: .83rem !important;
    }
}

@media all and (max-width: 549px){
    
    
    .MuiSelect-select.MuiSelect-select{
        font-size: .75rem !important;
    }

    .MuiFormLabel-root {
        font-size: .75rem !important;
    }

    .MuiMenuItem-root, .MuiTypography-body1 {
        font-size: .75rem !important;
        padding-bottom: 2px !important;
        padding-top: 2px !important;
        min-height: 28px !important;
    }

    .MuiGrid-spacing-xs-1 {
        margin: 0px !important;
    }

    .MuiGrid-spacing-xs-1 > .MuiGrid-item {
        margin-right: 2px !important;
      }

    .MuiInputBase-input.MuiInputBase-input, .MuiInputBase-formControl, textarea{
        font-size: .75rem !important;
    }

    .MuiDialogContent-root {
        flex: 1 1 auto;
        padding: 6px 7px !important;
    }

    .MuiTypography-h6 {
        font-size: 1.1rem !important;
    }
    
    .page_title{
        font-size: 1rem;
        margin-bottom: 5px;
    }  

    .three_forms_header_container .page_title , .references_header_container .page_title{
        font-size: 1.1rem !important;
        margin-bottom: 5px;
    }  

    .MuiTableCell-root {
        padding: 8px 10px 8px 10px !important;
        font-size: 0.75rem !important;
    }

    .MuiFab-root {
        width: 30px !important;
        height: 30px !important;
    }

    .MuiDialog-paper {
        margin: 8px !important;
    }

    .closeSharpIcon{
        width: 1.4rem !important;
        height: 1.4rem !important;
    }
    
    .checkIcon{
        width: 1.4rem !important;
        height: 1.4rem !important;
    }
    
    .editIcon{
      font-size: 1.3rem !important;
    }

    .dialogBottom {
        flex-direction: column;
    }

    .dialogBottom div:nth-child(1) {
        margin-bottom: 5px;
    }
    
    .dialogBottom div:nth-child(2) {
        width: 100%;
    }


    .status_success, .status_waiting, .status_deny {
        font-size: .4rem !important;
        padding: 0.12rem 0.18rem !important;
        padding-top: 0.12rem !important;
    }

    .error{
        font-size: .89em !important;
        padding: 7px 20px !important;
        margin-bottom: 15px !important;
    }

    .submit_but{
        padding: .25rem .8rem !important;
        margin: 3px 2px !important;
        font-size: .75rem !important;
    }

    .my_icon{
        width: 16.5px !important;
        height: 16.5px !important;
    }
      
    .dialogBottom {
        margin: 1px 4px !important;
    }
      
    .pagination_container{
        margin: 20px auto 30px auto;
      }
      
      .pagination li a {
          font-size: .8rem;
          padding:0.4rem .8rem;
      }
}



@media all and (max-width: 445px){
    
    .header_form {
        width:150px;
        margin:0px 10px 0px 0px;
    }

    .three_forms_header_container .search_filter_container .header_form:nth-child(2){
        margin: 0 !important;
    }

    .three_forms_header_container .page_title, .references_header_container .page_title{
        font-size: 1rem !important;
        margin-bottom: 5px;
    }  

    .page_title{
        font-size: .9rem;
        margin-bottom: 5px;
    }  

    .MuiFab-root {
        width: 29px !important;
        height: 29px !important;
    }

    .my_icon{
        width: 15.5px !important;
        height: 15.5px !important;
    }

    .MuiTableCell-root {
        padding: 8px 10px 8px 10px !important;
        font-size: 0.73rem !important;
    }

    .submit_but{
        padding: .22rem .72rem !important;
        font-size: .74rem !important;
    }

    .pagination_container{
        margin: 19px auto 28px auto;
      }
      
      .pagination li a {
          font-size: .76rem;
          padding:0.4rem .8rem;
      }
}




@media all and (max-width: 379px){
    
    .three_forms_header_container{
        margin-top: 10px !important;
        margin-bottom: 10px !important;
        padding: 0 11px 5px 8px !important;
    }

    .search_filter_container{
        width: 100% !important;
    }

    .page_title{
        height: 100% !important;
    } 

    .header_form {
        width:158px;
        margin:0 2px !important;
    }

    .MuiFab-root {
        width: 28px !important;
        height: 28px !important;
    }

    .MuiTypography-h6 {
        font-size: .95rem !important;
    }

    .MuiTableCell-root {
        padding: 8px 10px 8px 10px !important;
        font-size: 0.7rem !important;
    }
}