.save_email_awnser_container{
    background-color: #f7f7f7;
    margin: 0;
    padding-top: 20px ;
    width: 100vw;
    height: 100vh;
}

.save_email_awnser_container .logo_container{
    cursor: pointer;
    width: 60vw;
    height: 90px;
    max-height: 86px;
    margin: 0 auto 5% auto;
    text-align: center;
    position: relative;
}

.save_email_awnser_container .logo_container a {
    color: #3d4852;
    font-size: 19px;
    font-weight: bold;
    text-decoration: none;
    width: 50%;
    height: 100%;
}


.save_email_awnser_container .logo_container .brand {
    cursor: pointer;
    display: block !important;
    width: -moz-fit-content !important;
    width: 100%;
    height: 100%;
    position: relative !important;
    margin: auto;
    object-fit: scale-down;
}

.save_email_awnser_container .save_email_awnser_body{
    border-color: #e8e5ef;
    border-radius: 2px;
    border-width: 1px;
    box-shadow: 0 2px 0 rgba(0, 0, 150, 0.025), 2px 4px 0 rgba(0, 0, 150, 0.015);
    margin: 0 auto;
    padding: 0;
    width: 55%;
    height: 300px;
    border-radius: 10px;
}


.save_email_awnser_container .save_email_awnser_body .body_icon{
    width: 100%;
    height: 45%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
}

.save_email_awnser_container .save_email_awnser_body .body_icon .MuiFab-default {
    border-radius: 5px !important;
    width: 65px !important;
    height: 65px !important;
    box-shadow: none !important;
    color: rgba(0, 0, 0, 0.87);
    background-color: white !important;
}

.save_email_awnser_container .save_email_awnser_body .body_icon .done_icon {
    width: 1.9em !important;
    height: 1.9em !important;
    color: #33b66e;
}

.save_email_awnser_container .error_save .false_icon{
    width: 1.9em !important;
    height: 1.9em !important;
    color: #f33640f3 !important;
}

.save_email_awnser_container .save_email_awnser_body .body_title{
    width: 100%;
    height: 25%;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    font-size: 1.3rem;
    font-weight: 700;
    color: white;
}

.save_email_awnser_container .save_email_awnser_body .body_description{
    width: 100%;
    height: 30%;
    display: flex;
    text-align: center;
    justify-content: center;
    font-size: 1.2rem;
    color: white;
}




/*****************************************************************************/
/*****************************************************************************/
/*****************************************************************************/

.save_email_awnser_container .refuse_reason .textfield_container{
    width: 90%;
    height: 75%;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    vertical-align: middle;
}

.save_email_awnser_container .refuse_reason .error{
    width: 100%;
    font-size: 1.1em;
    color: #dd3461 !important;
    background: #dc35451f;
    padding: 10px 20px;
    border-radius: 5px;
    margin: 0 !important;
    margin-bottom: 15px !important;
    text-align: start;
  }

.save_email_awnser_container .refuse_reason .MuiOutlinedInput-multiline {
    padding: 7.5px 14px !important;
}


.save_email_awnser_container .refuse_reason .MuiInputLabel-outlined.MuiInputLabel-shrink {
    /* margin-right: 5px; */
    transform: translate(8px, -10.5px) scale(0.75) !important;
    z-index: 4 !important;
    background-color: white !important;
    padding: 0 5px !important;
}

.save_email_awnser_container .refuse_reason  .dialogBottom{
    width: 90%;
    height: 25%;
    display: flex;
    margin: 0 auto;
    justify-content: flex-end;
    align-items: center;
    align-content: center;
}

.save_email_awnser_container .refuse_reason .submit_but{
    display: flex;
    height: fit-content;
    margin: 0;
}

