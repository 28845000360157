.service_topnav .navbar{
    position: fixed;
    z-index: 2;
    display: flex;
    justify-content: flex-end;
    background-color: white !important;
    box-shadow: none;
    padding: 0;
}


.service_topnav .navbar a:not(.btn):not(.dropdown-item) {
    color: #040404;
}

.navbar_content_container{
    display: flex;
    width: calc(100% - 60px);
    background-color: #f7f7f7;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    border-bottom: solid .5px rgb(237, 237, 237);
}

.navbar_content_container .brand {
    cursor: pointer;
    display: block !important;
    width: -moz-fit-content !important;
    width: 340px;
    position: relative !important;
    height: 100%;
    margin-left: 10px;
    margin-bottom: 2px;
}


.navbar_content_container .selection {
    height: fit-content;
}