
.view_state_details_container .bill_state_byService{
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
}

.view_state_details_container .bill_state_byService div:first-child{
    font-size: .96rem;
}

.view_state_details_container .MuiOutlinedInput-multiline {
    padding: 7.5px 14px !important;
}

.view_state_details_container .first_textField_container{
    width: 100%;
    margin-top: 8px;
}

.view_state_details_container .second_textFields_container{
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin: 22px auto;
    flex-wrap: wrap;
}

.view_state_details_container .second_textFields_container .container_11{
    width: 49%;
    margin-right: 6px;
}

.view_state_details_container .second_textFields_container .container_12{
    width: 49%;
    margin-left: 6px;
}

@media all and (max-width: 662px){

    .view_state_details_container .second_textFields_container{
        margin: 15px auto !important;
    }
    
    .view_state_details_container .bill_state_byService div:first-child{
        font-size: .87rem !important;
    }    

    .view_state_details_container .second_textFields_container .container_11{
        width: 100% !important;
        margin-right: 0 !important;
        margin-bottom: 8px;
    }
    
    .view_state_details_container .second_textFields_container .container_12{
        width: 100% !important;
        margin-left: 0 !important;
    }
}