.applicant_request_view{
    width : 100% !important;
    margin: auto;
}



.applicant_request_view .applicant_request_view_header_container{
    font-size: 1.2rem;
    padding: 0 20px 15px 20px;
    width: 95%;
    margin: auto;
    margin-top: 30px;
    margin-bottom: 20px;
    border-bottom: solid 1px black;
    display: flex;
    justify-content: space-between;
}

.applicant_request_view .view_reason_button{
    width: fit-content;
    margin: auto;
    text-decoration: underline;
    margin-top: 2px;
    color: #38a1a5 !important;
    cursor: pointer !important;
}

.request_state_container{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.applicant_request_view .applicant_request_view_header_container .MuiGrid-container {
    width: -moz-fit-content !important;
    width: fit-content !important;
    align-content: center !important;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
}

.applicant_request_view .disable_button{
    opacity: 0.4;
    cursor: default !important;
}

.applicant_request_view .disable_button:hover{
    background-color: #e0e0e0 !important;
    opacity: 0.4;
    cursor: default !important;
}

.applicant_request_view label {
    margin-bottom: 0 !important;
    margin-right: 10px !important;
}

.applicant_request_view .submittions_table{
    width: 96% !important;
    margin: auto !important;
}

.applicant_request_view .MuiTableCell-body:nth-child(8) {
    min-width:235px !important;
}

.applicant_request_view .MuiTableCell-body:nth-child(8) .documentsDownload{
    width: 100%;
}