.create-supplier_form{
    margin: 35px auto 10px auto;
    max-width: 550px;
    justify-content: center;
}


.create-supplier_form .form{
    text-align: -webkit-right;
    padding-top: 0px !important;
}

.create-supplier_form h2 {
    margin-bottom: 0px !important;
    font-size:2rem !important;    
    width: 100%;
    display: flex;
    justify-content: center;
}

.create-supplier_form label{
    width: 100%;
    text-align: left;
    margin: 0;
    font-size: 1rem;
    font-weight: 600;
    padding-left: 2px;
}

.error{
    width: 100%;
    font-size: 1.1em;
    color: #e13d68 !important;
    background: #dc35451f;
    padding: 10px 20px;
    border-radius: 5px;
    margin-bottom: 20px;
    text-align: start;
}


.create-supplier_form .row {
    margin: 20px 0;
}

.create-supplier_form .form-control {
    border: 1px solid #c4c3c3;
    border-bottom-width: 1px !important;
    border-radius: 6px;
    color: #2c2c2c;
    height: auto;
    font-size: .95rem;
    box-shadow: none;
    margin-bottom: 10px;
    height: 39px;
    background-image: none !important;
    background-color: white !important;
}

.create-supplier_form .form-select-control {
    border-style: solid !important;
    border-width: 1px  !important;
    border-color: #c4c3c3 !important;
}

.create-supplier_form .MuiInput-underline:after {
    border-bottom: none !important;
}


.create-supplier_form .MuiInput-underline:before {
    border-bottom: none !important;
}

.create-supplier_form .MuiSelect-select.MuiSelect-select{
    font-family: var(--body-font) !important;
    text-align: left !important;
  }

.create-supplier_form .form-select{
    cursor: pointer;
}

.create-supplier_form .form-select .MuiInputBase-input {
    background-color: white !important;
}

.create-supplier_form  .Mui-focused {
    border: black;
}

.create-supplier_form .MuiSelect-select {
   min-width: 100% !important;
}

input,select{
    box-shadow: none !important;
}
 input[type="checkbox"]{
     cursor: pointer;
}


.create-supplier_form .submit_but{
    all: unset;
    background:#343a40;
    color: white;
    font-size: 1.08rem;
    width: 160px;
    height: 28px;
    cursor:pointer !important;
}
.create-supplier_form .submit_but:hover{
    background: #343a40;
    opacity: .9;
}

.create-supplier_form .under_submit{
    display: flex;
    justify-content: space-between;
}

.create-supplier_form .under_submit a{
    text-decoration: none;
    font-size: 12pt;
}

.create-supplier_form .disabled_button{
    pointer-events: none;
    opacity: 0.4;
  }


  @media all and (max-width: 1080px){
    
    .create-supplier_form h2 {
        margin-bottom: 0px !important;
        font-size:1.8rem !important;    
        width: 100%;
        display: flex;
        justify-content: center;    
    }

    .create-supplier_form label{
        font-size: .95rem;
    }

    .create-supplier_form .form-control {
        font-size: .9rem;
        height: 39px;
    }

    .create-supplier_form .form-select{
        font-size: .9rem;
    }

    .create-supplier_form .submit_but{
        width: 150px;
        height: 26px;
        font-size: 1.05rem;
        margin-top: 25px;
    }

    .create-supplier_form .my_icon{
        width: 19px !important;
        height: 19px !important;
    }

}



@media all and (max-width: 645px){

    .create-supplier_form{
        margin: 40px auto;
        width: 97%;
    }
    .create-supplier_form .form{
        padding: 0 10px !important;

    }

  }


@media all and (max-width: 420px){
    
    .create-supplier_form{
        max-width: 600px;
    }

    
    .create-supplier_form h2 {
        margin-bottom: 0px !important;
        font-size:1.6rem !important;  
    }

    .create-supplier_form .row {
        margin: 10px 0;
    }

    .create-supplier_form label{
        font-size: .85rem;
    }

    .create-supplier_form .form-control {
        font-size: .85rem;
    }

    .create-supplier_form .form-select{
        font-size: .85rem;
    }

    .error{
        font-size: 1em;
        padding: 7px 20px;
        margin-bottom: 15px;
    }

    .create-supplier_form .submit_but {
        width: 100px;
        height: 23px;
        font-size: 1rem;
        margin-top: 15px !important;
    }
      
}
