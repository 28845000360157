.send_email_container .form{
    text-align: -webkit-right;
    padding-top: 0px !important;
}

.send_email_container .form-control {
    border: 1px solid #c4c3c3;
    border-bottom-width: 1px !important;
    border-radius: 6px;
    color: #2c2c2c;
    height: auto;
    font-size: .95rem;
    box-shadow: none;
    margin-top: 10px;
    height: 39px;
    background-image: none !important;
    background-color: white !important;
}

.send_email_container  .Mui-focused {
    border: 1px solid #c4c3c3 !important;
}

.send_email_container  .form-control:focus {
    border: 1px solid #c4c3c3 !important;
}


.send_email_container .multiselectContainer{
    font-family: var(--body-font) !important;
}

.send_email_container .searchBox{
    border: none !important;
    font-size: .95rem !important;
    color:#2c2c2c !important;
    font-family: var(--body-font) !important;
}

.send_email_container .inputField{
    color:#2c2c2c !important;
    font-family: var(--body-font) !important;
}


.send_email_container .searchWrapper {
    border: 1px solid #c4c3c3 !important;
    border-radius: 6px !important;
    padding: 0.3rem 0.65rem !important;
}

.send_email_container .chips{
    background: #338787 !important;
}

.send_email_container .chip {
    background: #338787 !important;
    border-radius: 15px !important;
    color: #ffffff !important;
    font-size: 0.85rem !important;
    margin-bottom: 0px !important;
    margin-right: 4px !important;
    font-family: var(--body-font) !important;
}

.send_email_container .optionListContainer {
    z-index: 1000 !important;
}

.send_email_container .option{
    color: #4c4c4e !important;
}

.send_email_container .option:hover{
    background: #e9ecee !important;
}

.send_email_container .highlightOption {
    background: #e9ecee !important;
}

.send_email_container .highlightOption:hover {
    background: #e9ecee !important;
}



.text_edit_cont{
    margin:10px 0px 5px 0px;
    width: 100%;
    height: 320px;
}
.send_email_container .sun-editor .se-btn-tray {
    display: flex !important;
}

.send_email_container .sun-editor .se-wrapper {
    text-align: start !important;
}

.sun-editor .se-container {
    display: flex !important;
    flex-direction: column !important;
}


.send_email_container .files_container{
    width: 100%;
    height: fit-content;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
}

.send_email_container .file_container{
    border: solid 1px rgb(185, 185, 185);
    color: rgb(100, 99, 99) !important;
    width: fit-content;
    min-width: 150px;
    font-size: .85rem;
    display: flex;
    justify-content: space-between;
    padding: 9px 4px 9px 7px;
    border-radius: 3px;
    margin-right: 10px;
    margin-bottom: 8px;
}

.send_email_container .file_infos{
    margin-right: 8px;
}

.send_email_container .file_container .my_icon{
    color: rgb(117, 117, 117) !important;
    cursor: pointer !important;
}
