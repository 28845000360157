.products_gallery_container{
    width: 100% !important;
    height: 100%;
    display: flex;
    flex-direction: column;
}



.products_gallery_container .search_filter_container{
    display: flex;
    width: 100%;
    justify-content: flex-end;
}



.products_gallery_contents_container .view_more_button_container button{
    border: 1px solid rgb(255, 255, 255);
    background-color: rgba(0, 0, 0, .4);
    color: white;
    position: relative;
    text-transform: none !important;
}


.products_gallery_contents_container .view_more_button_container button:hover{
    border: 1px solid rgb(230, 226, 226);
    background-color: rgba(0, 0, 0, .7);
}


@media (max-width: 845px){
    .products_gallery_container .search_filter_container {
        display: flex;
        width: 100%;
        justify-content: center;
    }
}



.products_gallery_contents_container .card_title {
    margin-top: 7px;
}

.products_gallery_contents_container .card_description {
    margin-top: 10px;
}


.emptytable_msg_container{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    font-weight: 600;
}