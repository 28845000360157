.my_offers_container{
    width: 100% !important;
    overflow: hidden !important;
}


.my_offers_container_header{
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 30px 40px;
    align-items: center;
}

.addButton_container{
    width: fit-content !important;
}


.addButton_container .submit_but{
    all: unset;
    background:#3b5a7c;
    color: white;
    transition: all 150ms ease-in-out 0s;
    padding: .39rem 1.2rem ;
    margin: 6px 5px;
    font-size: .93rem;
    cursor: pointer;
    align-items: center;
    text-align: center;
    box-shadow: none;
    margin-right: 20px;
    border-radius: 4px;
}


.my_offers_container .submit_but:hover{
    background:#4b698a !important;
}

.search_filter_container{
    display: flex;
    width: fit-content;
}

.my_offers_container .gridOfService {
    width: 98% !important;
    margin: auto !important;

}

.my_offers_container .paginationBttns {
    width: 100% !important;
    height: fit-content !important;
    display: flex !important;
    justify-content: center !important;
    padding: 0 !important;
}


.my_offers_container .offers_contents_pagination_container{
    width: 98%;
    height: 100%;
    margin: auto;
    justify-content: center;
}


.my_offers_container .offers_contents_container{
    width: 100%;
    height: 100%;
    margin-bottom: 30px;
    display:flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    flex-direction: row;
    flex-wrap: wrap;
}

.my_offers_container .main_card_container {
    float: left;
    width: 42%;
    height: 270px;
    min-width: 600px;
    max-width: 700px !important;
    margin: 12px 12px !important;
    background: none;
    border: solid;
    border:solid 1px rgb(87, 87, 87);
    background-color:rgb(253, 253, 253);
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    padding: 10px 5px;
}


.search_filter_container .search_filter_form{
    width:250px;
    margin:0px 30px 0px 0px;
}



.my_offers_container .main_card_container .main_card_infos_container{
    flex: 1;
    padding: 6px 10px;
    padding-bottom: 0;
    display: flex;
    flex-direction: column;
}

.my_offers_container .main_card_container .main_card_offer_object{
    font-size: 1.5rem;
    font-weight: 700;
    color: rgb(31, 107, 132);
    height: fit-content;
    margin-bottom: 10px;
    text-transform: capitalize;
}


.my_offers_container .main_card_container .main_card_infos_container .main_card_infos_content{
    flex: 1;
    font-size: 1.05rem;
    color: #343a40;
    height: 60%;
}

.my_offers_container .main_card_container .main_card_infos_container .main_card_infos_elements{
    width: 100%;
    margin-bottom: 7px;
    display: flex;
    display:flex;
    align-items: center;
    align-content: center;
    flex-direction: row;
    flex-wrap: wrap;
}

.my_offers_container .main_card_container .main_card_infos_container .main_card_infos_element{
    width: 100%;
    margin-bottom: 7px;
}

.my_offers_container .main_card_container .main_card_infos_container .main_card_infos_elements div{
    width: 50%;
}

.my_offers_container .main_card_container .main_card_infos_container span{
    font-weight: 700;
}

.my_offers_container .main_card_container .card_buttons_container{
    width: 100%;
    height: min-content;
    display: flex;
    justify-content: flex-end;
    padding-right: 4px;
}

.main_card_container .submit_but{
    all: unset;
    background:#343a40;
    color: white;
    padding: .45rem 1.2rem;
    font-size: .9rem;
    border-radius: 4px;
    transition: filter 300ms;
    cursor: pointer;
    align-items: center;
    text-align: center;
    box-shadow: none;
    position: relative;
}

  

.main_card_container .submit_but:first-child{
    margin-right: 5px;
}

.main_card_container .cancel_but{
    background:#e92a44;
}

.main_card_container .cancel_but:hover{
    background:#e92a44 !important;
    opacity: 0.9;
}

.main_card_container .valide_but{
    background:#18ac67;
}

.main_card_container .valide_but:hover{
    background:#18ac67 !important;
    opacity: 0.9;
}



@media all and (max-width: 1135px){

    .my_offers_container_header{
        padding: 30px 15px;
    }

}


@media all and (max-width: 1080px){
    
    .search_filter_container .search_filter_form{
        width:205px;
        margin:0px 25px 0px 0px;
    }

}


@media all and (max-width: 845px){
    
    .my_offers_container_header{
        flex-direction: column;
        align-items: flex-start;
        padding: 15px 15px;
    }

    .addButton_container{
        margin-bottom: 11px;
    }

    .search_filter_container{
        display: flex;
       width: 100%;
       justify-content: center;
    }

    .my_offers_container .main_card_container .main_card_offer_object {
        font-size: 1.45rem;
        height: fit-content;
        margin-bottom: 20px;
    }

    .my_offers_container .main_card_container .main_card_infos_container .main_card_infos_content {
        font-size: .95rem;
        height: fit-content;
    }
}


@media all and (max-width: 750px){
    
    .search_filter_container .search_filter_form{
        width:185px;
        margin:0px 15px 0px 0px;
    }
}


@media all and (max-width: 645px){

    .my_offers_container .offers_contents_pagination_container {
        width: 100%;
    }

    .search_filter_container{
       flex-wrap: wrap;
    }

    .search_filter_container .search_filter_form:nth-child(2){
        margin:0;
    }

    .search_filter_container .search_filter_form:nth-child(3){
        margin-right:10px;
    }

    .my_offers_container .main_card_container {
        width: 46%;
        min-width: 280px !important;
        height: fit-content;
        margin: 6px 6px !important;
        padding: 8px 3px;
    }

    .my_offers_container .main_card_container .main_card_infos_container {
        padding: 6px 5px !important;
    }

    .my_offers_container .main_card_container .main_card_infos_container .main_card_infos_elements div {
        width: fit-content !important;
        margin: 1px !important;
    }

    .my_offers_container .main_card_container .main_card_offer_object {
        font-size: 1.35rem;
        height: fit-content;
        margin-bottom: 18px;
    }

    .my_offers_container .main_card_container .main_card_infos_container .main_card_infos_content {
        font-size: .9rem;
        height: fit-content;
    }
}


@media all and (max-width: 580px){
    
    .search_filter_container .search_filter_form{
        width:180px;
        margin:0px 15px 0px 0px;
    }
}


@media (max-width: 590px){
    .my_offers_container .main_card_container {
        min-width: 270px !important;
        margin: 5px 5px !important;
    }

    .my_offers_container .search_bar{
        margin-top: 0 !important;
    }

    .my_offers_container .main_card_container .main_card_offer_object {
        font-size: 1.2rem;
        height: fit-content;
        margin-bottom: 17px;
    }

    .my_offers_container .main_card_container .main_card_infos_container .main_card_infos_content {
        font-size: .82rem;
        height: fit-content;
    }
}


@media all and (max-width: 445px){
    
    .search_filter_container .search_filter_form{
        width:150px;
        margin:0px 10px 0px 0px;
    }

    .my_offers_container .main_card_container .main_card_offer_object {
        font-size: 1.1rem;
        height: fit-content;
        margin-bottom: 17px;
    }

    .my_offers_container .main_card_container .main_card_infos_container .main_card_infos_content {
        font-size: .78rem;
        height: fit-content;
    }
}


@media all and (max-width: 397px){
    
    .search_filter_container .search_filter_form{
        width:150px;
        margin:0px;
    }

}