

.off_catalogue_requestDetails_container{
    width: 100vw;
    height: fit-content;
    overflow: auto;
 }


.request_order_toolbar_main_container{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center !important;
    align-content: center !important;
    flex-wrap: wrap;
}


.off_catalogue_requestDetails_container .view_reason_button{
    width: fit-content;
    margin: auto;
    text-decoration: underline;
    margin-top: 2px;
    color: #38a1a5 !important;
    cursor: pointer !important;
    font-size: .95rem;
}

.off_catalogue_requestDetails_container .request_state_container{
    display: flex;
    flex-direction: column;
    align-items: center !important;
    justify-content:center !important;
    align-content: center !important;
}

 .off_catalogue_requestDetails_container .add_icon_container{
    width: fit-content;
    align-self: flex-end;
    color:#3b5a7c !important;
    cursor: pointer !important;
    margin-right: 8px;
    border-radius: 100%;
 }

 .off_catalogue_requestDetails_container .add_icon_container .MuiSvgIcon-root {
    width: 40px;
    height: 40px;
    min-width: 28px !important;
    min-height: 28px !important;
}

.off_catalogue_requestDetails_container .add_icon_container:hover{
    background:#efefef !important;
}

.off_catalogue_requestDetails_container .remove_icon_container{
    align-self: flex-end;
    color:#353535 !important;
    cursor: pointer !important;
    border-radius: 100%;
    display: flex;
    justify-content: center;
 }

 .off_catalogue_requestDetails_container .remove_icon_container:hover{
    background:#efefef !important;
 }

 .off_catalogue_requestDetails_container .remove_icon_container .MuiSvgIcon-root {
    width: 30px !important;
    height: 30px !important;
}

.off_catalogue_requestDetails_container .accordion-body {
    padding: 0;
    margin: 0;
    color: rgb(64, 64, 65);
    background-color:#f9f9f9 !important;
}


.off_catalogue_requestDetails_container div:nth-child(4) .accordion .accordion-button::before{
    background: rgb(245 128 115);
}

.off_catalogue_requestDetails_container div:nth-child(2) .accordion .accordion-button::before{
    background: rgb(245 128 115);
}


.off_catalogue_requestDetails_container .supplier_accordion .accordion-button::after {
    display: none;
}

.off_catalogue_requestDetails_container .supplier_accordion .accordion_header_content span:first-child::before{
    display: none;
}

.order_form_details_container{
    width: 80%;
    margin: 0 auto;
}

.order_form_details_container .order_form_infos_container{
    width: 100%;
    padding: 1em 2em;
    margin: 0;
}

.order_form_infos_container .row {
    margin: 5px 0 10px 0;
}


.order_form_infos_container .order_form_infos_title{
    padding: 0 15px;
    font-size: 1.1rem;
    font-weight: 600;
    color: #526f87;
    margin: 18px 0 7px 0;
}

.order_form_infos_container .row label {
    margin-bottom: 0.25rem !important;
}

.order_form_infos_container .form-control {
    border: 1px solid #c4c3c3;
    border-radius: 4px;
    color: #2c2c2c;
    height: auto;
    font-size: .9rem;
    box-shadow: none;
    margin-bottom: 12px;
    height: 39px;
    background-image: none !important;
}

.order_form_infos_container textarea {
    height: auto !important;
    padding: 8px 11px !important;
    box-shadow: none !important;
    border: 1px solid #c4c3c3 !important;
    color: #2c2c2c !important;
}

.order_form_page_buttons_container{
    width: 60%;
    height: fit-content;
    display: flex;
    justify-content: space-between;
    margin: 15px auto;
}


.off_catalogue_requestDetails_container .articles_container{
    width: 100%;
    margin-top: 15px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    font-size: .9rem;
}

.articles_container .articles_view_container{
    width: 49.5%;
    border: solid 1px rgb(215, 212, 212);
    background-color: #fdfdfd;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    padding: 13px 13px;
    margin-bottom: 10px;
}

.articles_container .article_view_header_container{
    font-size: 1.08rem;
    font-weight: 600;
    color: #526f87;
    display: flex;
    justify-content: space-between;
}


.request_order_toolbar_right .cancel_but{
    background:#e92a44 !important;
}


.request_order_toolbar_right .valide_but{
    background:#18ac67 !important;
}

.request_order_toolbar_right .cancel_but:hover{
    background:#e92a44 !important;
    opacity: .9 !important;
}

.request_order_toolbar_right .valide_but:hover{
    background:#18ac67 !important;
    opacity: .9 !important;
}


@media all and (max-width: 1080px){

    .articles_container .articles_view_container {
        padding: 11px 11px;
        margin-bottom: 8px;
        font-size: .87rem;
    }

    .articles_container .article_view_header_container{
        font-size: .98rem;
    }

}


@media all and (max-width: 620px){

    .articles_container .articles_view_container {
        padding: 11px 9px;
        margin-bottom: 8px;
        font-size: .78rem;
    }

    .articles_container .article_view_header_container{
        font-size: .9rem;
    }

}

@media all and (max-width: 490px){

    .off_catalogue_requestDetails_container .articles_container{
        justify-content: center;
    }

    .articles_container .articles_view_container {
        width: 90%;
        padding: 7px 5px;
        margin-bottom: 6px;
        font-size: .75rem;
    }

    .articles_container .article_view_header_container{
        font-size: .87rem;
    }
}