.catalogue_modal{
    width: 100%;
    height: 100%;
    margin: 30px 0 ;
}


.products_gallery_modal_container{
    width: 100% !important;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: auto !important;
}


.products_gallery_modal_header_container{
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 18px 30px 15px 30px !important;
}


.products_gallery_modal_container .search_filter_container{
    width: 100%;
}


.products_gallery_modal_contents_container{
    flex: 1;
    width: 98%;
    margin: 15px auto;
    display:flex;
    flex-direction: column;
}

.products_contents_container{
    width: 100%;
    display:flex;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
}

.products_gallery_modal_container  .main_card_container{
    width: 42%;
    height: 342px;
    min-width: 263px;
    max-width: 263px !important;
    margin: 15px 17px !important;
    border-radius: 4px;
    box-shadow: 0px 1px 2px -1px rgb(0 0 0 / 20%), 0px 4px 8px 0px rgb(0 0 0 / 14%), 0px 1px 15px 0px rgb(0 0 0 / 12%);
    display: flex;
    flex-direction: column;
    padding: 0;
    position: relative;

}

.main_card_img_container{
    width: 100%;
    height: 48.5%;
    max-height:min-content;
    position: relative;
}
.main_card_img_container img{
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    object-fit: cover !important;
    border: none;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
}

.products_gallery_modal_container .main_card_infos_container{
    flex: 1;
    width: 100%;
    background-color: rgb(252, 253, 253);
    border-top: solid 1px rgb(228, 228, 228);
    display: flex;
    flex-direction: column;
    padding: 16px 0;
    padding-top: 13px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
}

.products_gallery_modal_container .card_title{
    width: 98%;
    height: 1.55rem;
    padding: 0px 20px 0 10px;
    margin: auto;
    margin-bottom: 0;
    font-size: 1.15rem;
    font-weight: 700;
    display: flex;
    text-align: center;
    justify-content: center;
    text-transform: capitalize;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}

.main_card_infos_container .card_title:last-child{
    margin-top: 5px !important;
}   


.products_gallery_modal_container .card_description{
    height: fit-content;
    color: rgb(86, 86, 86);
    width: 98%;
    margin: auto;
    padding: 5px 10px;
    margin-bottom: 2px;
    height: 5em;
    display: flex;
    text-align: center;
    justify-content: center;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}

.products_gallery_modal_container .view_more_button_container{
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,.6);
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 0;
    opacity: 0;
    transition: all 190ms ease-in-out 0s;
    border-radius: 4px;
}

.products_gallery_modal_container .main_card_container:hover > .main_card_infos_container > .view_more_button_container{
    opacity: 1;
    transition: all 190ms ease-in-out 0s;
}



.products_gallery_modal_container .view_more_button_container button{
    all: unset;
    font-size: 1rem;
    padding: .4rem .6rem;
    border: 1px solid rgb(255, 255, 255);
    color: white;
    border-radius: 5px;
    cursor: pointer;
    position: relative;
}


.products_gallery_modal_container .view_more_button_container button:hover{
    border: 1px solid rgb(230, 226, 226);
    background-color: rgba(0, 0, 0, .7);
}


.catalogue_modal .MuiDialog-paperFullWidth {
    width: calc(100% - 64px) !important;
    height: calc(100% - 75px) !important;
    margin: 5px auto !important;
}

.catalogue_modal .MuiDialogContent-root {
    padding: 0px !important;
}

@media all and (max-width: 1160px){

    .products_gallery_modal_container  .main_card_container {
        height: 320px !important;
        min-width: 250px !important;
        max-width: 250px !important;
        margin: 14px 15px !important;
    }

    .products_gallery_modal_container .card_title{
        height: 1.55rem;
        padding: 0px 20px 0 10px;
        font-size: 1.1rem;
    }
    
    .products_gallery_modal_container  .card_description{
        padding: 5px 10px;
        font-size: .88rem !important;
    }

    .products_gallery_modal_container .main_card_infos_container {
        padding: 12px 0;
        padding-top: 10px;
    }
}

@media (max-width: 1000px){
    .products_gallery_modal_container  .main_card_container {
        height: 300px !important;
        min-width: 240px !important;
        max-width: 240px !important;
        margin: 12px 12px !important;
    }

    .main_card_img_container {
        max-height: 138px !important;
        min-height: 138px !important;
    }

    .products_gallery_modal_container  .card_title{
        padding: 0px 20px 0 10px;
        font-size: 1.05rem;
    }
    
    .products_gallery_modal_container  .card_description{
        font-size: .85rem !important;
    }
}


@media (max-width: 680px){
    .catalogue_modal .MuiDialog-paperFullWidth, .products_gallery_modal .MuiDialog-paperFullWidth {
        width: 97% !important;
        margin: 5px auto !important;
    }

    .products_gallery_modal_header_container {
        padding: 18px 15px 15px 15px !important;
    }
}


@media (max-width: 578px){
    .products_gallery_modal_container  .main_card_container {
        height: 240px !important;
        min-width: 200px !important;
        max-width: 200px !important;
        margin: 8px 5px !important;
    }

    .main_card_img_container {
        max-height: 110px !important;
        min-height: 110px !important;
    }

    .products_gallery_modal_container .main_card_infos_container {
        padding: 0px !important;
        padding-top: 0px;
    }

    .products_gallery_modal_container  .card_title {
        font-size: .9rem !important;
        margin-top: 12px !important;
    }

    .products_gallery_modal_container  .card_description {
        font-size: .7rem !important;
        margin-top: 2px !important;
    }

    .products_gallery_modal_container .main_card_infos_container .card_title:last-child {
        margin-top: 3px !important;
    }
}