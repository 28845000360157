
.all_services_identity_container{
    width: 100%;
    height: 100%;
    margin: auto;
    display: flex;
    justify-content: center;
}

.all_services_identity_infos_container{
    width: 95%;
    height: fit-content;
    margin: auto;
    padding: 15px 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.all_services_identity_infos{
    width: fit-content;
    height: fit-content;
    margin: auto;
    display:flex;
    justify-content: space-between;
    align-items: flex-start;
    align-content: flex-start;
    flex-wrap: wrap;
}

.all_services_identity_infos_container .company_infos_header{
    width: 100%;
    margin-top: 19px;
    margin-bottom: 15px;
    padding: 0 8px;
    font-size: 1.5rem;
    color: #343a40;
    font-weight: 600;
    position: relative;
}

.all_services_identity_infos_container .company_infos_header::after{
    content: '';
    position: absolute;
    padding: 0 8px;
    bottom: -2px;
    left: 8px;
    height: 2px;
    display: inline-block;
    background: rgb(86, 121, 121);
    width: 60px;
}

.srv_identity_img{
    flex: 1 1 30ch;
    height: 220px;
    max-width: 455px;
    min-width: 455px;
    margin: 0 7px 45px 7px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
}

.srv_identity_img div:first-child{
    height: 100%;
    width: 220px;
    border-radius: 100%;
    border: solid 2px rgb(153, 153, 153);
    margin-right: 25px;
    position: relative;
    overflow: hidden;
    padding: 0;
}

.srv_identity_img div:last-child{
    height: fit-content;
    width: fit-content;
}

.srv_identity_img img{
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    object-fit: cover;
    border: none;
}

.srv_identity_element{
    flex: 1 1 30ch;
    height: fit-content;
    max-width: 455px;
    min-width: 455px;
    margin: 0 7px 25px 7px;
    display: flex;
    justify-content:space-between;
    align-content: center;
    align-items: center;
    align-self: flex-end;
}

.srv_identity_element span:first-child{
    height: fit-content;
    font-size: .98rem;
    font-weight: 600;
}

.srv_identity_element:last-child{
    flex: 1;
}

.srv_identity_element .form-control {
    border: 1px solid #adacac;
    border-radius: 6px;
    background-color: white;
    color: #2c2c2c;
    height: auto;
    font-size: .93rem;
    box-shadow: none;
    width: 268px;
    height: 41px;
    background-image: none !important;
}

.srv_identity_element .form-control:disabled, .form-control[readonly] {
    border: 1px solid #bababa !important;
    background-color: #f8f8f8 !important;
    opacity: .8 !important;
}

.all_services_identity_infos_config{
    width: 100%;
    height: fit-content;
    margin-top: 15px;
    display: flex;
    justify-content: flex-end;
}

.all_services_identity_infos_config div:first-child{
    width: 50%;
}


.all_services_identity_infos_config .submit_but{
    margin: 6px 5px;
}


.all_services_identity_container .edit_but{
    background:#ffa726;
    color: white;
}

.all_services_identity_container .edit_but:hover{
    background:#ffa726;
    opacity: 0.9;
}

.all_services_identity_container .valide_but{
    background:#18ac67;
    color: white;
}

.all_services_identity_container .valide_but:hover{
    background:#18ac67;
    opacity: 0.9;
}


@media all and (max-width: 1100px){

    .all_services_identity_infos_container{
        width: 97%;
        height: fit-content;
        margin: auto;
        padding: 15px 8px;
    }

    .srv_identity_img{
        flex: 1 1 30ch !important;
        height: 195px !important;
        max-width: 415px !important;
        min-width: 415px !important;
        margin: 0 6px 30px 6px !important;
    }
    
    .srv_identity_element{
        flex: 1 1 30ch !important;
        height: fit-content;
        max-width: 415px !important;
        min-width: 415px !important;
        margin: 0 8px 22px 4px !important;
    }


    .srv_identity_img div:first-child{
        height: 100%;
        width: 200px !important;
        border-radius: 100%;
        border: solid 2px rgb(153, 153, 153);
        margin-right: 20px !important;
    }

    .srv_identity_element span:first-child{
        height: fit-content;
        font-size: .93rem !important;
        font-weight: 600;
    }

    .srv_identity_element .form-control {
        font-size: .9rem !important;
        width: 248px !important;
        height: 39px !important;
    }

    .all_services_identity_infos_container .company_infos_header{
        margin-top: 15px;
        margin-bottom: 19px;
        padding: 0 4px;
        font-size: 1.4rem;
    }
    
    .all_services_identity_infos_container .company_infos_header::after{
        padding: 0 8px;
        bottom: -2px;
        left: 6px;
        height: 2px;
        width: 60px;
    }
    
}

@media all and (max-width: 918px){

    .all_services_identity_infos_container{
        width: 100%;
        height: fit-content;
        margin: auto;
        padding: 15px 1px;
    }
}


@media all and (max-width: 874px){

    .all_services_identity_infos_container{
        height: fit-content;
        margin: auto;
    }

    .all_services_identity_infos{
        justify-content: center;
    }

    .all_services_identity_infos_container .company_infos_header{
        width: 98% !important;
        margin-bottom: 24px;
        padding: 0;
        font-size: 1.3rem;
    }
    
    .all_services_identity_infos_container .company_infos_header::after{
        padding: 0 8px;
        bottom: -2px;
        left: 2px;
        height: 2px;
        width: 60px;
    }


    .srv_identity_element{
        margin: 0 7px 17px 4px !important;
    }


}



@media all and (max-width: 450px){

    .all_services_identity_infos_container{
        height: fit-content;
        margin: auto;
        padding: 12px 0;
    }

    .all_services_identity_infos{
        justify-content: center;
    }

    .srv_identity_element{
        max-width: 96% !important;
        min-width: 96% !important;
        margin: 0 0 14px 0 !important;
    }
    
    .srv_identity_img{
        height: 145px !important;
        max-width: 97% !important;
        min-width: 97% !important;
        margin: 0 0 20px 0 !important;
    }
    
    .srv_identity_img div:first-child{
        height: 100%;
        width: 150px !important;
        border-radius: 100%;
        border: solid 2px rgb(153, 153, 153);
        margin-right: 10px !important;
    }
    

    .all_services_identity_infos_container .company_infos_header{
        width: 97% !important;
        margin-bottom: 24px;
        padding: 0;
        font-size: 1.45rem;
    }


    .srv_identity_element span:first-child{
        font-size: .8rem !important;
        font-weight: 600;
    }

    .srv_identity_element .form-control {
        font-size: .8rem !important;
        width: 218px !important;
        height: 33px !important;
    }
    
    .all_services_identity_infos_container .company_infos_header::after{
        padding: 0 8px;
        bottom: -2px;
        left: 2px;
        height: 2px;
        width: 60px;
        font-size: 1.2rem !important;
    }

    .all_services_identity_infos_config{
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .all_services_identity_infos_config div:first-child{
        width: 97%;
    }
    
}
