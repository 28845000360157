.widget_count_elements_container{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    font-size: 4rem;
    cursor: pointer;
}


.widget_count_elements_container .number_arrow_container{
    position: relative;
    width: fit-content;
    height: fit-content;
}

.widget_count_elements_container .arrow_container{
    width: fit-content;
    height: fit-content;
    position: absolute;
    top: -5px;
    right: -45px;
    display: flex;
    color: green;
}

.widget_count_elements_container .arrow_container .css-i4bv87-MuiSvgIcon-root {
    width: 2em !important;
    height: 2em !important;
}