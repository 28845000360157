
.addproductbutt{
    background:#3b5a7c !important;
    color: white !important;
}

.addproductbutt:hover{
    background:#4b698a !important;
}

.supplier_page .view_more_button_container{
    flex-direction: column;
}

.supplier_page .view_more_button_container div:first-child{
    height: 55%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.supplier_page .update_buttons{
    width: fit-content;
    height: 45%;
    display: flex;
    justify-content: space-around;
    padding: 25px 10px;
    margin: auto;
}



.supplier_page .update_buttons .cancel_but{
    background:#e92a44 !important;
}


.supplier_page .update_buttons .valide_but{
    background:#18ac67 !important;
}

.supplier_page .update_buttons .cancel_but:hover{
    opacity: .9 !important;
}

.supplier_page .update_buttons .valide_but:hover{
    opacity: .9 !important;
}


.products_gallery_container{
    width: 100% !important;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.products_gallery_container .search_filter_container{
    display: flex;
    width: 100%;
    justify-content: flex-end;
}



.products_gallery_contents_container .view_more_button_container button{
    border: 1px solid rgb(255, 255, 255);
    background-color: rgba(0, 0, 0, .4);
    color: white;
    position: relative;
    text-transform: none !important;
}


.products_gallery_contents_container .view_more_button_container button:hover{
    border: 1px solid rgb(230, 226, 226);
    background-color: rgba(0, 0, 0, .7);
}


@media (max-width: 845px){
    .products_gallery_container .search_filter_container {
        display: flex;
        width: 100%;
        justify-content: center;
    }
}



.products_gallery_contents_container .card_title {
    margin-top: 7px;
}

.products_gallery_contents_container .card_description {
    margin-top: 10px;
}

.products_gallery_contents_container .cancel_object_fab
,.products_gallery_contents_container .success_object_fab {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    padding: 0 !important;
    margin: 0 5px !important;
    border: solid 1px rgb(163, 163, 163) !important;
}