
.import_files_page_container{
    width: 100%;
    overflow: auto;
}


.drop_zone_container{
    width: 100%;
}


.all_imp_res_container{
    width: 100%;
    padding: 15px;
    display: flex;
    flex-wrap: wrap;
}

.imp_res_container{
    width: 32%;
    min-width: 310px;
    height: 245px;
    margin: 6px 5px;
    display: flex;
    flex-direction: column;
    color: rgb(84, 84, 84);
    border: solid 1px rgb(216, 216, 216);
    background-color: rgb(252, 252, 252);
    border-radius: 4px;
    cursor: pointer;
    transition: all 150ms ease-in-out 0s;
}

.imp_res_container:hover{
    border: solid 1px rgb(44, 190, 195);
    background-color: rgba(228, 248, 244, .4);
    transition: all 150ms ease-in-out 0s;
}

.imp_res_container_slected{
    border: solid 1px rgb(44, 190, 195) !important;
    background-color: rgba(228, 248, 244, .4) !important;
    transition: all 150ms ease-in-out 0s;
}


.imp_res_radio{
    height: 27%;
}

.imp_res_icon{
    height: 23%;
    text-align: center;
}


.imp_res_icon .MuiSvgIcon-root {
    width: 47px !important;
    height: 47px !important;
}

.imp_res_title{
    flex: 1;
    text-align: center;
    font-size: 1rem;
}

.files__container{
    width: 73%;
    margin: 15px auto;
    display: flex;
    flex-direction: column;
}

.import_files_page_container .submit_but{
    all: unset;
    background:rgb(231, 118, 74);
    color: white;
    width: fit-content;
    height: fit-content;
    padding: .5rem 1.5rem;
    font-size: .9rem;
    border-radius: 5px;
    transition: filter 300ms;
    cursor: pointer;
    margin-left: 20px;
}

.import_files_page_container .submit_but:hover{
    background: rgb(228, 88, 32);
    opacity: .9;
}


.zone_title{
    color: rgb(84, 84, 84);
    font-weight: 600;
    margin: 4px 0;
    display: flex;
    flex-direction: column;
    justify-content:flex-start !important;
}

@media (max-width: 1100px){

    .files__container{
        width: 75%;
    }


    .imp_res_container{
        width: 32%;
        height: 215px;
        min-width: 280px !important;
        margin: 5px 5px;
    }
    
    .imp_res_icon .MuiSvgIcon-root {
        width: 43px !important;
        height: 43px !important;
    }
    
    .imp_res_title{
        font-size: .95rem;
    }

}

@media (max-width: 870px){

    .files__container{
        width: 85%;
    }

    .all_imp_res_container{
        padding: 10px;
        justify-content: center;
    }

    .imp_res_container{
        width: 48%;
        height: 220px;
        min-width: 290px !important;
        margin: 5px 5px;
    }
    
    .imp_res_icon .MuiSvgIcon-root {
        width: 35px !important;
        height: 35px !important;
    }
    
    .imp_res_title{
        font-size: .95rem;
    }

}

@media (max-width: 635px){

    .files__container{
        width: 98%;
    }

    .all_imp_res_container{
        padding: 5px;
        justify-content: center;
    }

    .imp_res_container{
        width: 48%;
        height: 160px;
        min-width: 230px !important;
        margin: 3px 3px;
    }

    .imp_res_icon{
        height: 23%;
        text-align: center;
    }
    
    
    .imp_res_icon .MuiSvgIcon-root {
        width: 33px !important;
        height: 33px !important;
    }
    
    .imp_res_title{
        font-size: .9rem;
    }
}

