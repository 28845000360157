.radialCharts_offersState_container{
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: space-around;
    cursor: pointer;
    position: relative;
}

.radialCharts_offersState_container .radialCharts_container{
    width: 25%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    position: relative;
}


.radialCharts_offersState_container .apexcharts-text {
    font-family: var(--body-font) !important;
    font-size: 1.05rem !important;
}


@media all and (max-width: 1200px){
    .radialCharts_offersState_container .apexcharts-text {
        font-size: .97rem !important;
    }
}

@media all and (max-width: 879px){
    .radialCharts_offersState_container .apexcharts-text {
        font-size: .9rem !important;
    }
}

@media all and (max-width: 559px){
    .radialCharts_offersState_container .apexcharts-text {
        font-size: .85rem !important;
    }
}


@media all and (max-width: 450px){
    .radialCharts_offersState_container .apexcharts-text {
        font-size: .75rem !important;
    }
}
