


.supplier_reference_container {
    width: 70vw !important;
    height: fit-content !important;
    margin: 20px auto !important;
    overflow: hidden;
}


.supplier_reference_container .references_header_container .Msg_button_container{
    display: flex;
    justify-content: center;
    align-items:flex-end;
    align-content: flex-end;
    padding: 0 !important;
}


.references_header_container .submit_but{
    background:#343a40;
    color: white;
}

.references_header_container .submit_but:hover{
    background:#343a40;
    opacity: .9;
}

#text_non_lue{
    position: absolute;
    right: -7%;
    top: -16%;
    width: 20px !important;
    height: 20px !important;
    border-radius: 50%;
    background: #4BEA82;
    padding: 0 !important;
    display: flex;
    justify-content: center;
    align-items: center;
    color: black;
    font-size: .7rem;
}


.supplier_reference_container .MuiTableCell-head {
    color: rgba(26, 24, 24, 0.87) !important;
    font-weight: 600 !important;
    line-height: 1.5rem !important;
}

.supplier_reference_container .MuiTableCell-body:nth-child(1) {
    min-width:30px !important;
    max-width:40px !important;
}

.supplier_reference_container .MuiTableCell-body:nth-child(4) {
    min-width:300px !important;
}

.supplier_reference_container .documentsContainer{
    width: 100%;
    height: 100%;
    display: block;
}


.supplier_reference_container .documentsDownload{
    width: 45%;
    display: flex;
    vertical-align: middle;
    align-items: center;
}

.supplier_reference_container .documentsDownload label {
    display: inline-block;
    margin-bottom: 0;
}

.supplier_reference_container .documentsDownload .download_button{
    margin-left: 9px !important;
    color: black;
}


.supplier_reference_container .documentsTitle{
    font-size: inherit !important;
    font-weight: 700;
    color: rgb(64, 64, 65);
    margin-left: 7px;
    align-self: center;
}

.supplier_reference_container .documentsStatus_valide{
    width: fit-content;
    min-width: 70px;
    display: flex;
    justify-content: space-around;
}

.supplier_reference_container .MuiTableCell-body:nth-child(5) {
    min-width:115px;
}



.referenceStatusContainer{
    width: 100%;
    text-align: end;
    align-items: end;
    display: flex;
    justify-content: right;
}

.referenceStatusContainer2{
    width: fit-content;
    display: block;
    justify-content:center;
    text-align: center;
    align-items: center;
}


.sendBut_conatainer{
    width: 100%;
    margin: 20px 0;
    text-align: right;
}

.sendBut_conatainer button{
    background-color: rgb(52, 58, 64);
    color: white;
}

.sendBut_conatainer button:hover{
    background-color: rgb(52, 58, 64);
    opacity: .9;
}

@media all and (max-width: 1000px){

    .supplier_reference_container {
        width: 80vw !important;
    }

}


@media all and (max-width: 820px){
    .supplier_reference_container {
        width: 86vw !important;
    }
}

@media (max-width: 580px){
    .supplier_reference_container .references_header_container {
        display: flex;
        flex-direction: row !important;
    }
}

@media all and (max-width: 500px){

    .supplier_reference_container {
        width: 90vw !important;
    }

    #text_non_lue{
        width: 17px !important;
        height: 17px !important;
        font-size: .6rem;
    }
    
}


@media all and (max-width: 400px){

    .supplier_reference_container {
        width: 98vw !important;
    }

    #text_non_lue{
        width: 17px !important;
        height: 17px !important;
        font-size: .6rem;
    }
    
}