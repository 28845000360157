
.references_container {
    width: 100%;
    height: fit-content !important;
    overflow: hidden;
    margin: 0;
    margin-bottom: 30px !important;
}


.references_container .references_header_container{
    font-size: 1.2rem;
    padding: 0 20px 15px 20px;
    width: 95%;
    margin: auto;
    margin-top: 30px;
    margin-bottom: 20px;
    border-bottom: solid 1px black;
    display: flex;
    justify-content: space-between;
}


.references_container .select_container select{
    cursor: pointer;
}

.MuiTableCell-head {
    color: rgba(26, 24, 24, 0.87) !important;
    font-weight: 600 !important;
}


.references_container .reference_table{
    width: 97% !important;
    margin: auto !important;
}


.references_container .MuiTableCell-body:nth-child(4) {
    min-width:250px;
}

.documentsContainer{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.documentsDownload{
    width: 60%;
    display: flex;
    vertical-align: middle;
    align-items: center;
}

.documentsTitle{
    font-weight: 700;
    color: rgb(64, 64, 65);
    margin-left: 7px;
    align-self: center;
}

.documentsStatus_valide{
    width: fit-content;
    min-width: 70px;
    display: flex;
    justify-content: space-around;
}

.references_container .MuiTableCell-body:nth-child(5) {
    min-width:230px;
}


.references_container textarea{
    max-width: 100% !important;
    max-height: none !important;
    padding: 7px 5px 7px 5px;
    resize: auto !important;
    font-size: .9rem !important;
    border: solid 0.1rem #E3E3E3 !important;
    border-radius: 0;
    line-height: 1 !important;
}


.references_container .MuiTableCell-body:nth-last-child(1){
    min-width:70px !important;
}

.references_container .MuiTableCell-body:last-child {
    min-width:210px !important;
    padding-right: 20px !important;
}

.referenceStatusContainer{
    width: 100%;
    text-align: end;
    align-items: end;
    display: flex;
    justify-content: right;
}

.referenceStatusContainer2{
    width: fit-content;
    display: block;
    justify-content:center;
    text-align: center;
    align-items: center;
}


.refusedButton{
    pointer-events: none;
    all: unset;
    background:#d0336af3;
    color: white;
    padding: .3rem .9rem;
    padding-top: .35rem;
    font-size: .7rem !important;
    border-radius: 4px;
    text-align: center;    
}

.acceptedButton{
    pointer-events: none;
    all: unset;
    background:#33b66e;
    color: white;
    padding: .3rem .9rem;
    padding-top: .35rem;
    font-size: .7rem !important;
    border-radius: 4px;
    text-align: center;    
}




.referencecheckIcon{
    font-size: 2.9rem !important;
    background-color: rgb(253, 253, 253);
    border-radius: 100%;
    cursor: pointer;
    text-align: center;
    margin-right: 9px;
    padding: 5px;
    color:#00b200;
}

.referencecloseSharpIcon:hover{
    background-color: #fcf5f5;
}

.referencecloseSharpIcon{
    font-size: 2.9rem !important;
    background-color: rgb(253, 253, 253);
    border-radius: 100%;
    cursor: pointer;
    text-align: center;
    padding: 5px;
    color:#f33640f3;
}

.referencecheckIcon:hover{
    background-color: #e9fce9;
}



@media all and (max-width: 750px){
    

    .references_container .references_header_container {
        padding: 0 10px 12px 10px;
        width: 97%;
        margin: auto;
        margin-top: 20px;
        margin-bottom: 20px;
    }
}


@media all and (max-width: 580px){
    

    .references_container .references_header_container {
        display: flex;
        flex-direction: column;
    }

    .search_filter_container {
        width: 100%;
        display: flex;
        justify-content: center;
    }

    .referencecheckIcon {
        font-size: 2.5rem !important;
        margin-right: 6px;
        padding: 3px;
    }

    .referencecloseSharpIcon{
        font-size: 2.5rem !important;
        padding: 3px;
    }
}




@media all and (max-width: 445px){

    .references_container .references_header_container {
        padding: 0 5px 10px 5px !important;
    }

}