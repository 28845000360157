.offresAppel_SubmitView{
    width : 100% !important;
    margin: auto;
}



.three_forms_header_container{
    font-size: 1.2rem;
    padding: 0 20px 10px 20px;
    width: 95%;
    margin: auto;
    margin-top: 20px;
    margin-bottom: 17px;
    border-bottom: solid 1px black;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}


.offresAppel_SubmitView label {
    margin-bottom: 0 !important;
    margin-right: 10px !important;
}

.offresAppel_SubmitView .submittions_table{
    width: 96% !important;
    margin: auto !important;
}

.offresAppel_SubmitView .MuiTableCell-body:nth-child(7) {
    min-width:235px !important;
}

.offresAppel_SubmitView .MuiTableCell-body:nth-child(7) .documentsDownload{
    width: 100%;
}