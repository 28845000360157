.MuiPaper-rounded {
    padding: 0 !important;
    border-radius: 0 !important;
}


.charts_of_contract_container{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.charts_of_contract_container .contract_state{
    width: 100%;
    height: fit-content;
    display: flex;
    justify-content: flex-end;
    padding-right: 20px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.charts_of_contract_container .contract_state .MuiSelect-select.MuiSelect-select {
    color: rgb(74, 76, 74) !important;
    font-size: .9rem;
    padding: 3px 0;
}

.charts_of_contract_container .contract_state .MuiInput-underline:before {
    border: none !important;
}

.charts_of_contract_container .contract_state .MuiSelect-icon {
    color: rgb(74, 76, 74) !important;
}

.charts_of_contract_container .contract_state .MuiInputBase-root {
    font-family: var(--body-font) !important;
    border: solid 1px rgb(74, 76, 74) !important;
    padding-left: 6px !important;
    border-radius: 4px !important;
}

.charts_of_contract_container .contract_state .MuiSelect-nativeInput {
    left: 0;
    width: 100%;
    bottom: 0;
    opacity: 0;
    position: absolute;
    pointer-events: none;
}


.charts_of_contract_container .container{
    width: 100%;
    height: fit-content;
    flex:1;
    padding: 0 !important;
}


.contracts_table_container{
    padding: 0 !important;
    border-radius: 0 !important;
    margin: 0 !important;
    font-size: .5rem !important;
}

.contracts_table_container .MuiTableCell-body:nth-child(1) {
    min-width:125px !important;
}

.contracts_table_container .MuiTableCell-body:nth-child(2) {
    min-width:65px !important;
}
.contracts_table_container .MuiTableCell-body:nth-child(3) {
    min-width:65px !important;
}
.contracts_table_container .MuiTableCell-body:nth-child(4) {
    min-width:65px !important;
}

.contracts_table_container .MuiTableCell-body:nth-child(5) {
    min-width:105px !important;
}

.contracts_table_container .MuiTableCell-body:nth-child(6) {
    min-width:145px !important;
}