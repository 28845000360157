.product_details_modal_container{
    width: 100%;
    height: max-content;
    position: relative;
    overflow: hidden !important;
    display: flex;
    margin-top: 2px;
    padding: 20px 15px;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
}

.product_details_carousel_container{
    width: 50% !important;
    min-height: 420px;
    height: calc(84vw / 3);
    position: relative;
}

.product_details_carousel_container .carousel-item {
    width: 100%;
    min-height: 380px;
    height:calc((84vw / 3) - 40px);
    position: relative !important;
    align-items: center;
    text-align: center;
    background: rgba(0,0,0,.02);
}

.product_details_carousel_container .carousel-item img{
    max-width: 100% !important;
    height: 100% !important;
    object-fit: cover !important;
}

.carousel-control-next-icon , .carousel-control-prev-icon{
    background-color: rgba(0,0,0,0.9);
    filter: brightness(3);
    width: 40px !important;
    height: 40px !important;
    background-size: 30% 30%;
    border-radius: 100%;
}

.product_details_carousel_container .carousel-indicators{
    bottom: -50px !important;
}

.product_details_carousel_container .carousel-indicators [data-bs-target] {
    width: 10px;
    height: 10px;
    background-color: rgb(11, 79, 82) !important;
    border: solid 0.5px rgb(255, 255, 255);
    border-radius: 9px;
}


.carousel .carousel-inner {
    box-shadow: 0px 5px 15px 0px rgb(0 0 0 / 30%) !important;
    border-radius: 0.1875rem;
}

.product_details_infos_container{
    flex:1;
    height: 280px;
    margin-left: 20px;
    border: solid 1px rgb(230, 230, 230);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 19px;
    background-color: rgb(253, 253, 253);
    border-radius: 5px ;
}


.product_details_infos_container .card_title_descr{
    width: 100%;
    height: fit-content;
    max-height: 6rem;
    font-size: 1.55rem;
    font-weight: 700;
    text-transform: capitalize;
    color: rgb(31, 107, 132);
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    display: flex;
    justify-content: flex-start;
    padding: 0;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    line-height: 1.85rem;
}

.product_details_modal_container .card_price_descr{
    font-size: 1.05rem;
    font-weight: 700;
    color:rgb(228, 88, 32);
}

.product_details_modal_container .product_details_infos_container .card_categorie_descr{
    width: 100%;
    height: fit-content;
    font-size: 1rem;
    margin: 0;
    color: #343a40;
}

.product_details_modal_container .product_details_infos_container .card_categorie_descr:last-child{
    margin-bottom: 3px;
}

.product_details_modal_container .product_details_infos_container .card_categorie_descr span{
    width: fit-content;
    margin-right: 3px;
    font-weight: 700;
}


.product_details_modal_container .product_details_infos_container .card_description_details{
    width: 100%;
    font-size: 1rem;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    justify-content: flex-start;
    padding: 0;
    text-align: left;
}


.supplier_product_details_modal_container .product_details_infos_container .card_description_details {
    width: 100%;
    font-size: 1rem;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    justify-content: flex-start;
    padding: 0;
    text-align: left;
}

.product_details_modal_container .product_details_infos_container .view_more_buttons_cont{
    width: 100%;
    height: fit-content;
    display: flex;
    align-items: center;
    opacity: 1;
}


.product_details_infos_container .view_more_buttons_cont .amount_container {
    border-radius: 5px;
    width: 100px;
    height: fit-content;
    padding: 10px 9px;
    font-size: 1rem;
    margin-right: 20px;
}

.product_details_infos_container .view_more_buttons_cont .submit_but{
    background:rgb(228, 88, 32);
    color: white !important;
}

.product_details_infos_container .view_more_buttons_cont .submit_but:hover{
    background: rgb(228, 88, 32);
    opacity: .9;
}



.product_details_infos_container input[type=number]::-webkit-inner-spin-button, .product_details_infos_container
input[type=number]::-webkit-outer-spin-button {  
   opacity: 1;
}

.product_details_modal_container .details_container{
    width: 100%;
    height: fit-content;
    margin-top: 30px;
    border: solid 1px rgb(230, 230, 230);
    display: flex;
    flex-direction: column;
    padding: 20px;
    background-color: rgb(253, 253, 253);
    border-radius: 5px ;
}

.product_details_modal_container .details_container .container_title{
    width: fit-content;
    height: fit-content;
    font-size: 1.5rem;
    font-weight: 700;
    text-transform: capitalize;
    color: rgb(228, 88, 32);
    position: relative;
}

.product_details_modal_container .details_container .container_title::after{
    content: '';
    height: 2px;
    width: 70%;
    background-color: rgb(228, 88, 32);
    position: absolute;
    bottom: -2px;
    left: 1px;
}

.product_details_modal_container .details_container .description{
    width: 100%;
    height: fit-content;
    font-size: 1.07rem;
    margin: 20px 0 10px 0;
}


@media all and (max-width: 1160px){
    .product_details_modal_container{
        padding: 20px 10px;
    }

    .product_details_carousel_container{
        width: 55% !important;
    }
 
    .product_details_infos_container{
        height: 275px;
        margin-left: 18px;
        padding: 16px;
    }

    .product_details_infos_container .card_title_descr{
        font-size: 1.35rem;
        line-height: 1.8rem;
    }


    .product_details_modal_container .card_price_descr{
        font-size: 1rem;
    }

    .product_details_modal_container .product_details_infos_container .card_categorie_descr {
        font-size: .95rem;
    }

    .product_details_modal_container .product_details_infos_container .card_description_details {
        font-size: .95rem;
    }

    .product_details_infos_container .view_more_buttons_cont .amount_container {
        padding: 8px 7px;
        font-size: .95rem;
        width: 85px;
        margin-right: 18px;
    }

    .product_details_modal_container .details_container {
        margin-top: 25px;
    }

    .product_details_modal_container .details_container .container_title {
        font-size: 1.4rem;
    }

    .product_details_modal_container .details_container .description {
        font-size: .95rem;
        margin: 20px 0 10px 0;
    }

}


@media all and (max-width: 1070px){


    .product_details_carousel_container{
        width: 52% !important;
        min-height: 380px;
    }
    
    .product_details_carousel_container .carousel-item {
        min-height: 340px;
    }
 
    .product_details_infos_container{
        height: 265px;
        margin-left: 18px;
        padding: 12px 14px;
    }

    .product_details_modal_container .details_container {
        margin-top: 25px;
    }

    .product_details_modal_container .details_container .container_title {
        font-size: 1.4rem;
    }

    .product_details_modal_container .details_container .description {
        font-size: .95rem;
        margin: 20px 0 10px 0;
    }


    .carousel-control-next-icon , .carousel-control-prev-icon{
        width: 38px !important;
        height: 38px !important;
        background-size: 30% 30%;
    }
    

}



@media all and (max-width: 1000px){
 
    .product_details_infos_container {
        height: 250px;
        margin-left: 18px;
        padding: 15px;
    }

    .product_details_infos_container .view_more_buttons_cont .amount_container {
        padding: 8px 7px;
        font-size: .9rem;
        width: 80px;
        margin-right: 18px;
    }

    .product_details_infos_container .card_title_descr{
        font-size: 1.2rem;
        line-height: 1.5rem;
    }

    .product_details_modal_container .card_price_descr{
        font-size: 1rem;
    }

    .product_details_modal_container .product_details_infos_container .card_categorie_descr {
        font-size: .9rem;
    }

    .product_details_modal_container .product_details_infos_container .card_description_details {
        font-size: .9rem;
    }


    .product_details_modal_container .details_container {
        margin-top: 25px;
    }

    .product_details_modal_container .details_container .container_title {
        font-size: 1.4rem;
    }

    .product_details_modal_container .details_container .description {
        font-size: .95rem;
        margin: 20px 0 10px 0;
    }

}



@media all and (max-width: 900px){
 
    .product_details_infos_container {
        height: 240px;
        margin-left: 18px;
        padding: 14px;
    }

    .product_details_modal_container .details_container {
        padding: 15px;
    }

    .product_details_infos_container .view_more_buttons_cont .amount_container {
        padding: 8px 7px;
        font-size: .9rem;
        width: 80px;
        margin-right: 18px;
    }

    .product_details_modal_container .details_container {
        margin-top: 25px;
    }

    .product_details_modal_container .details_container .container_title {
        font-size: 1.2rem;
    }

    .product_details_modal_container .details_container .description {
        font-size: .9rem;
        margin: 20px 0 0 0;
    }

    .carousel-control-next-icon , .carousel-control-prev-icon{
        width: 36px !important;
        height: 36px !important;
        background-size: 30% 30%;
    }
    

}


@media all and (max-width: 820px){
    .product_details_carousel_container{
        width: 85% !important;
        min-height: 400px;
        margin-bottom: 25px;
    }

    .product_details_carousel_container .carousel-item {
        min-height: 360px;
    }

    .product_details_infos_container {
        margin-left: 0;
    }

    .product_details_modal_container {
        padding: 20px 20px;
    }

    .carousel-control-next-icon , .carousel-control-prev-icon{
        width: 35px !important;
        height: 35px !important;
        background-size: 30% 30%;
    }
}



@media all and (max-width: 550px){

    .product_details_carousel_container{
        width: 92% !important;
        margin-bottom: 20px;
    }

    .product_details_modal_container {
        padding: 10px;
    }

    .product_details_carousel_container .carousel-indicators [data-bs-target] {
        width: 9px;
        height: 9px;
    }

    .product_details_carousel_container .carousel-indicators {
        bottom: -45px !important;
    }

    .product_details_infos_container .view_more_buttons_cont .amount_container {
        padding: 8px 7px;
        font-size: .85rem;
        width: 70px;
        margin-right: 14px;
    }

    .product_details_infos_container {
        height: 218px;
        padding: 12px 9px;
    }

    .product_details_infos_container .card_title_descr{
        font-size: 1.1rem;
        line-height: 1.23rem;
    }

    .product_details_modal_container .card_price_descr{
        font-size: .9rem;
    }

    .product_details_modal_container .product_details_infos_container .card_categorie_descr {
        font-size: .85rem;
    }

    .product_details_modal_container .product_details_infos_container .card_description_details {
        font-size: .85rem;
    }


    .product_details_modal_container .details_container {
        margin-top: 20px;
        padding: 12px 9px;
    }

    .product_details_modal_container .details_container .container_title {
        font-size: 1.1rem;
    }

    .product_details_modal_container .details_container .description {
        font-size: .9rem;
        margin: 15px 0 0 0;
    }
}


@media (max-width: 450px){

    .product_details_carousel_container{
        width: 96% !important;
        min-height: 350px;
        margin-bottom: 15px;
    }

    .product_details_carousel_container .carousel-item {
        min-height: 310px;
    }

    .product_details_modal_container {
        padding: 10px 5px;
    }

    .carousel-control-next-icon , .carousel-control-prev-icon{
        width: 33px !important;
        height: 33px !important;
        background-size: 30% 30%;
    }
}