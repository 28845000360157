

.set_refuse_raison_container .MuiOutlinedInput-multiline {
    padding: 7.5px 14px !important;
}

/*
///////////////////////////////////////////////////////
/////////////////////////////////////////////////////*/


