.service_header{
    width: max-content;
    padding:0;
    z-index:3;
    position:fixed;
    top: 0;
}


.nav_menu_container{
    width: 100%;
    display: flex;
    justify-content: flex-start;
}



.nav_menu_container .service_content {
    height: 100vh;
    display: flex;
    flex-direction: column;
    transition: all 500ms;
    z-index: 3;
}


.nav_menu_container .service_content > div {
    display: flex;
    flex-direction: column;
}


.nav_menu_container .service_content .company_nav_logo_container {
    cursor: pointer;
    width: 100% !important;
    height: 60px !important;
    position: relative !important;
    left: -2px !important;
    margin-top: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
}


.nav_menu_container .service_content .company_nav_logo_container img {
    width: 52px;
    height: 59px !important;
    margin: 10% auto 0 auto;
    object-fit: scale-down;
}

.nav_menu_container .service_content.show {
    z-index: 3;
    width: 60px !important;
    position: fixed;
}


.nav_menu_container > div > div > a:hover{
    background-color: rgba(172, 171, 171, 0.1);
}


.nav_menu_container #drop1.show{
    height:65px !important;
}


.nav_menu_container .menu_items{
    margin-top: 5px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}

.nav_menu_container .menu_item{
    display: flex;
    color: white;
    width: 99%;
    justify-content: space-between;
    height: 2.52rem !important;
    align-items: center;
    align-content: center;
    padding: 0 5px 0 10px;
    cursor: pointer;
    transition: all 200ms;
    text-decoration: none;
}

.nav_menu_container .menu_item_selected{
    background-color:rgba(230, 218, 218, 0.292);
    transition: all 200ms;
}

.nav_menu_container .menu_item:hover{
    background-color:rgba(230, 218, 218, 0.292);
    transition: all 200ms;
}


.nav_menu_container .menu_item div:nth-child(2){
    width: 210px !important;
    padding-top: 2px !important;
}

.nav_menu_container .menu_item a{
    text-decoration:none;
	color: white;
	padding: 7px 10px;
	font-size: 10.5pt;
    font-weight: 400;
}

.nav_menu_container .menu_item .MuiSvgIcon-root{
    width: 2.3rem !important;
    height: 1.55rem !important;
}




.service_body{
    flex: 1;
    width: calc(100vw - 60px);
    margin-top: 72px ;
    margin-left: 60px;
    overflow: auto;
}