.add_product_byservice_container .cancel_but{
    background:#e92a44 !important;
    margin-right: 10px;
}


.add_product_byservice_container .valide_but{
    background:#18ac67 !important;
}

.add_product_byservice_container .cancel_but:hover{
    opacity: .9 !important;
}

.add_product_byservice_container .valide_but:hover{
    opacity: .9 !important;
}


.add_product_byservice_container textarea{
    max-width: 100% !important;
    max-height: none !important;
    padding: 5px 10px 7px 5px !important;
    resize: auto !important;
    font-size: 1rem;
    border: solid 0.1rem #b1b0b0 !important;
    border-radius: 0;
    line-height: 1 !important;
}

.add_product_byservice_container .MuiDialog-paperFullWidth {
    width: fit-content;
    padding: 10px !important;
}

.add_product_byservice_container .plus_details_dialog_container{
    padding: 0 !important;
}


.add_product_byservice_container .header_form:last-child{
    margin-right: 0 !important;
}
