.service_topnav .navbar{
    position: fixed;
    z-index: 2;
    display: flex;
    justify-content: flex-end;
    background-color: white !important;
    box-shadow: none;
    padding: 0;
}


.service_topnav .navbar a:not(.btn):not(.dropdown-item) {
    color: #040404;
}

.navbar_content_container{
    display: flex;
    width: calc(100% - 60px);
    justify-content: space-between;
    align-items: center;
    align-content: center;
    border-bottom: solid .5px rgb(237, 237, 237);
}

.navbar_content_container .company_logo_container {
    cursor: pointer;
    height: 69px;
    width: -moz-fit-content !important;
    position: relative !important;
    margin-left: 10px;
    margin-bottom: 2px;
    padding: 2px 0;
    display: flex;
    align-items: center;
}

.navbar_content_container .company_logo_container img{
    height: 65px;
    object-fit: scale-down;
}

.navbar_content_container .company_logo_container .edit_container{
    position: absolute;
    right: -10px;
    top: 8px;
    width: 21px;
    height: 21px;
    border-radius: 100%;
    color: white !important;
    background: rgba(59, 59, 59, 0.75);
    padding: 0;
    display: flex;
    justify-content: center;
    cursor: pointer;
}


.navbar_content_container .company_logo_container .edit_but{
    cursor: pointer;
    width: 0.67em !important;
    height: 0.67em !important;
}

.navbar_content_container .company_logo_container .edit_container:hover{
    background: rgba(59, 59, 59, 0.96);
}


.navbar_content_container .selection {
    height: fit-content;
}