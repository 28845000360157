

.docs_perms .form-control {
    border: 1px solid #c4c3c3 !important;
    border-radius: 6px;
    color: #2c2c2c;
    height: auto;
    font-size: .95rem;
    box-shadow: none;
    margin-bottom: 0 !important;
    height: 39px;
    background-image: none !important;
    background-color: white !important;
}

.docs_perms .form-select-control {
    border-style: solid !important;
    border-width: 1px  !important;
    border-color: #c4c3c3 !important;
}

.docs_perms .MuiInput-underline:after {
    border-bottom: none !important;
}


.docs_perms .MuiInput-underline:before {
    border-bottom: none !important;
}

.docs_perms .MuiSelect-select.MuiSelect-select{
    font-family: var(--body-font) !important;
    text-align: left !important;
  }

.docs_perms .form-select{
    cursor: pointer;
}

.docs_perms .form-select .MuiInputBase-input {
    background-color: white !important;
}

.docs_perms  .Mui-focused {
    border: black;
}

.docs_perms .MuiSelect-select {
   min-width: 100% !important;
}

input,select{
    box-shadow: none !important;
}