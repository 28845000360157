.service_topnav .navbar{
    position: fixed;
    z-index: 2;
    display: flex;
    justify-content: flex-end;
    background-color: white !important;
    box-shadow: none;
    padding: 0;
}


.service_topnav .navbar a:not(.btn):not(.dropdown-item) {
    color: #040404;
}

.navbar_content_container{
    display: flex;
    width: calc(100% - 60px);
    justify-content: space-between;
    align-items: center;
    align-content: center;
    border-bottom: solid .5px rgb(237, 237, 237);
}


.navbar_content_container .company_logo_container {
    cursor: pointer;
    width: -moz-fit-content !important;
    position: relative !important;
    height: 69px;
    margin-left: 10px;
    margin-bottom: 2px;
    padding: 2px 0;
    display: flex;
    align-items: center;
}

.navbar_content_container .company_logo_container img{
    height: 65px;
    object-fit: scale-down;
}


.navbar_content_container .selection {
    height: fit-content;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.basket_container{
    margin-right: 8px;
    position: relative;
    color: rgb(69 81 83) !important;
    cursor: pointer;
}

.basket_container #products_number{
    position: absolute;
    left: -4px;
    top: -8px;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    color: white !important;
    background: rgb(237, 101, 48);
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    vertical-align: middle;
    font-size: .6rem;
}

.navbar_content_container .user_name_container{
    width:fit-content;
    display:flex;
    margin-right:25px;
    font-size: 1rem;
}

.navbar_content_container .my_avatar{
    margin: 0;
    object-fit: scale-down;
}

@media all and (max-width: 1010px){
    .navbar_content_container{
        width: calc(100% - 50px) !important;
        height: 68px;
    }
    .navbar_content_container .company_logo_container {
        padding: 2px 0;
        height: 64px !important;
    }
    
    .navbar_content_container .company_logo_container img{
        height: 55px !important;
    }
}


@media all and (max-width: 500px){
    .navbar_content_container{
        width: calc(100% - 46px) !important;
        margin-left: 0 !important;
    }
    .navbar_content_container .company_logo_container {
        padding:0 !important;
    }

    .navbar_content_container .basket_container{
        margin-right: 0 !important;
    }

    .navbar_content_container .basket_container .my_icon{
        width: 19px !important;
        height: 19px !important;
    }

    .basket_container #products_number{
        width: 15px;
        height: 15px;
        font-size: .59rem;
    }
    .navbar_content_container .user_name_container{
        width:fit-content;
        display:flex;
        margin-right:12px;
        font-size: .8rem;
    }

    .navbar_content_container .my_avatar{
        width: 34px;
        height: 34px;
    }
    
}