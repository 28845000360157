.charts_of_the_bestSuppliers_container{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.charts_of_the_bestSuppliers_container .container{
    margin: auto;
    width: 100%;
    height: fit-content;
}


.charts_of_the_bestSuppliers_container .select_supplierCategories{
    width: 100%;
    height: fit-content;
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
    margin-top: 10px;
}

.charts_of_the_bestSuppliers_container .select_supplierCategories .MuiSelect-select.MuiSelect-select {
    color: rgb(74, 76, 74) !important;
    font-size: .9rem;
    padding: 3px 0;
}

.charts_of_the_bestSuppliers_container .select_supplierCategories .MuiInput-underline:before {
    border: none !important;
}

.charts_of_the_bestSuppliers_container .select_supplierCategories .MuiSelect-icon {
    color: rgb(74, 76, 74) !important;
}

.charts_of_the_bestSuppliers_container .select_supplierCategories .MuiInputBase-root {
    font-family: var(--body-font) !important;
    border: solid 1px rgb(74, 76, 74) !important;
    padding-left: 6px !important;
    border-radius: 4px !important;
}

.charts_of_the_bestSuppliers_container .select_supplierCategories .MuiSelect-nativeInput {
    left: 0;
    width: 100%;
    bottom: 0;
    opacity: 0;
    position: absolute;
    pointer-events: none;
}


.charts_of_the_bestSuppliers_container .categorie_container{
    width: 85%;
    height: fit-content;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    margin: auto;
    margin-bottom: 8px;
    padding-right: 15px;
    position: relative;
    font-size: 1.2rem;
    font-weight: 700;
}


.charts_of_the_bestSuppliers_container .order_of_the_bestSuppliers {
    width: 50px;
    height: 50px;
    min-width: 50px;
    min-height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    border-radius: 100%;
    position:relative;
    right: -12px;
    font-size: inherit;
}


.charts_of_the_bestSuppliers_container .name_of_the_bestSuppliers{
    height: fit-content;
    width: fit-content;
    flex: 1;
    font-size: inherit;
    padding: 5px 15px;
    text-align: center;
    color: rgb(52, 51, 51);
    background:linear-gradient(to right, #e0b663ad, #FCF6BA );
    border-radius: 3px;
    border-left: none;
    width: fit-content;
    min-width: 145px;
}


.charts_of_the_bestSuppliers_container .css-i4bv87-MuiSvgIcon-root {
    font-size: 2.2rem !important;
}


@media all and (max-width: 1200px){

    .charts_of_the_bestSuppliers_container .categorie_container{
        width: 86%;
        margin-bottom: 7px;
        font-size: 1.08rem;
    }

    .charts_of_the_bestSuppliers_container .name_of_the_bestSuppliers{
        padding: 5px 15px;
        min-width: 135px;
    }
    
    
    .charts_of_the_bestSuppliers_container .order_of_the_bestSuppliers {
        width: 46px;
        height: 46px;
        min-width: 46px;
        min-height: 46px;
        right: -12px;
    }

}


@media all and (max-width: 1080px){

    .charts_of_the_bestSuppliers_container .categorie_container{
        margin-bottom: 6px;
        font-size: 1.02rem;
    }

    .charts_of_the_bestSuppliers_container .name_of_the_bestSuppliers{
        padding: 5px 15px;
        min-width: 135px;
    }
    
    
    .charts_of_the_bestSuppliers_container .order_of_the_bestSuppliers {
        width: 43px;
        height: 43px;
        min-width: 43px;
        min-height: 43px;
        right: -11px;
    }

}



@media all and (max-width: 900px){


    .charts_of_the_bestSuppliers_container .categorie_container{
        margin-bottom: 6px;
        font-size: .92rem;
    }

    .charts_of_the_bestSuppliers_container .name_of_the_bestSuppliers{
        padding: 4px 15px;
        min-width: 135px;
    }
    
    
    .charts_of_the_bestSuppliers_container .order_of_the_bestSuppliers {
        width: 39px;
        height: 39px;
        min-width: 39px;
        min-height: 39px;
        right: -11px;
    }

}


@media all and (max-width: 500px){


    .charts_of_the_bestSuppliers_container .categorie_container{
        margin-bottom: 6px;
        font-size: .82rem;
    }

    .charts_of_the_bestSuppliers_container .name_of_the_bestSuppliers{
        padding: 4px 15px;
        min-width: 125px;
    }
    
    
    .charts_of_the_bestSuppliers_container .order_of_the_bestSuppliers {
        width: 33px;
        height: 33px;
        min-width: 33px;
        min-height: 33px;
        right: -11px;
    }

}
