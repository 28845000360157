
.view_state_details_container .bill_state_byService{
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
}

.view_state_details_container .bill_state_byService div:first-child{
    font-size: 1rem;
}

.view_state_details_container .MuiOutlinedInput-multiline {
    padding: 7.5px 14px !important;
}

.view_state_details_container .MuiInputLabel-outlined.MuiInputLabel-shrink {
    /* margin-right: 5px; */
    transform: translate(8px, -10.5px) scale(0.8) !important;
    z-index: 4 !important;
    background-color: white !important;
    padding: 0 5px !important;
}

.view_state_details_container .first_textField_container{
    width: 100%;
    margin-top: 8px;
}

.view_state_details_container .second_textFields_container{
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin: 25px auto;
}

.view_state_details_container .second_textFields_container .container_11{
    width: 49%;
    margin-right: 6px;
}

.view_state_details_container .second_textFields_container .container_12{
    width: 49%;
    margin-left: 6px;
}
