
.offersChart_categorie_container{
    width: 100%;
    height: fit-content;
    padding: 10px;
    display: flex;
    flex-direction: column;
    cursor: default;
    font-size: .8rem;
}

.offersChart_byCategorie_header_container{
    width: 100%;
    height: fit-content;
    display: flex;
    margin-bottom: 15px;
    font-weight: 600;
}

.offersChart_categorie_container .offersChart_byCategorie_header_container .offersNumber_header{
    border: solid .5px rgba(141, 141, 141, 0.2);
    background-color: rgba(242, 242, 242, 0.45);
    width: fit-content;
    padding: 8px 10px;
    margin-right: 5px;
}

.offersChart_categorie_container .offersChart_byCategorie_header_container .offersCategorie_header{
    border: solid .5px rgba(141, 141, 141, 0.2);
    background-color: rgba(242, 242, 242, 0.45);
    width: fit-content;
    padding: 8px 15px;
    margin-right: 5px;
}

.offersChart_categorie_container .offersChart_byCategorie_header_container .offersCategoriePercent_header{
    border: solid .5px rgba(141, 141, 141, 0.2);
    background-color: rgba(242, 242, 242, 0.45);
    padding: 8px 15px;
    flex: 1;
}

.offersChart_categorie_container .offersChart_byCategorie_body_container{
    width: 100%;
    flex: 1;
}


.offersChart_categorie_container .categorie_container{
    width: 100%;
    height: fit-content;
    display: flex;
    margin-bottom: 8px;
}

.offersChart_categorie_container .offersNumber_body{
    border: solid .5px rgba(141, 141, 141, 0.2);
    border-right: none;
    width: fit-content;
    width: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px 5px;
    position: relative;
}

.offersChart_categorie_container .offersNumber_body span{
    color:white !important;
}

.offersChart_categorie_container .offersNumber_body::after {
    content: "\27A4";
    position: absolute;
    right: -9px;
    bottom: auto;
    font-weight: 900;
    color:inherit;
}

.offersChart_categorie_container .offersCategorie_body{
    border: solid .5px rgba(141, 141, 141, 0.2);
    background-color: rgba(242, 242, 242, 0.45);
    border-left: none;
    width: fit-content;
    display: flex;
    align-items: center;
    padding: 8px 15px;
    min-width: 145px;
    max-width: 145px;
    margin-right: 15px;
}


.offersChart_categorie_container .MuiTypography-root{
    font-size: inherit !important;
}

.offersChart_categorie_container .offersCategoriePercent_body{
    padding: 8px 5px;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.offersChart_categorie_container .offersCategoriePercent_body .MuiLinearProgress-determinate {
    height: 22px !important;
    background-color: rgb(239, 239, 239) !important;
}

.offersChart_categorie_container .offersCategoriePercent_body .MuiLinearProgress-determinate::before {
    background-color: white !important;
}

.offersChart_categorie_container .offersCategoriePercent_body .MuiTypography-root {
    font-family: var(--body-font) !important;
    font-weight: 600 !important;
    color: rgba(0,0,0,.81) !important;
}


@media all and (max-width: 1190px){
    .offersChart_categorie_container{
        font-size: .75rem;
    }

    .offersChart_categorie_container .offersChart_byCategorie_header_container .offersNumber_header{
        padding: 5px 9px;
    }
    
    .offersChart_categorie_container .offersChart_byCategorie_header_container .offersCategorie_header{
        padding: 5px 10px;
    }
    
    .offersChart_categorie_container .offersChart_byCategorie_header_container .offersCategoriePercent_header{
        padding: 5px 10px;
    }

    .offersChart_categorie_container .offersNumber_body{
        width: 40px;
        padding: 5px 5px;
    }
    
    .offersChart_categorie_container .offersCategorie_body{
        padding: 5px 10px;
        padding-left: 15px ;
        min-width: 120px;
        max-width: 120px;
        margin-right: 10px;
    }
    
    .offersChart_categorie_container .offersCategoriePercent_body {
        padding: 5px 5px;
    }
    
}



@media all and (max-width: 857px){
    .offersChart_categorie_container{
        font-size: .7rem;
    }

    .offersChart_categorie_container .offersChart_byCategorie_header_container .offersNumber_header{
        padding: 3px 5px;
    }
    
    .offersChart_categorie_container .offersChart_byCategorie_header_container .offersCategorie_header{
        padding: 3px 6px;
    }
    
    .offersChart_categorie_container .offersChart_byCategorie_header_container .offersCategoriePercent_header{
        padding: 3px 6px;
    }

    .offersChart_categorie_container .offersNumber_body{
        width: 30px;
        padding: 0;
    }
    
    .offersChart_categorie_container .offersCategorie_body{
        padding: 3px 6px;
        padding-left: 10px;
        min-width: 100px;
        max-width: 100px;
        margin-right: 7px;
    }
    
    .offersChart_categorie_container .offersCategoriePercent_body {
        padding: 3px 5px;
    }

    .offersChart_categorie_container .offersNumber_body::after {
        right: -7px;
        bottom: auto;
    }
}