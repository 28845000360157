
.contracts_list_container{
    width: 95%;
    height: fit-content;
    margin: 0 auto;
}

.contracts_list_container_header{
    font-size: 1.2rem;
    padding: 0 20px;
    padding-bottom: 10px;
    border-bottom: solid 1px black;
    width: 100%;
    margin: auto;
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
}


.contracts_list_container .Contracts_list_table{
    width: 100% !important;
    height: fit-content;
    margin: auto !important;
}

.contracts_list_container .MuiTableCell-body:nth-child(0) {
    min-width:80px !important;
}

.contracts_list_container .MuiTableCell-body:nth-child(1) {
    min-width:80px !important;
}

.contracts_list_container .MuiTableCell-body:nth-child(2) {
    min-width:150px !important;
}


.contracts_list_container .MuiTableCell-body:nth-child(3) {
    min-width:80px !important;
}


.contracts_list_container .MuiTableCell-body:nth-child(4) {
    min-width:235px !important;
}

.contracts_list_container .MuiTableCell-body:nth-child(5) {
    min-width:80px !important;
}

.contracts_list_container .MuiTableCell-body:nth-child(6) {
    min-width:80px !important;
}



.subTypes_container{
    width:100%;
    height: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.subType_container{
    width:100%;
    height: fit-content;
    display: flex;
    margin-bottom: 5px;
    align-items: center;
}

.subType{
    width:60%;
    height: 100%;
}

.subType_state{
    width:40%;
    height: 100%;
    display: contents;
}

.subType_sign_date_container{
    margin-bottom: 5px;
}