
.set_refuse_raison_container .MuiOutlinedInput-multiline {
    padding: 7.5px 14px !important;
}

.set_refuse_raison_container  .dialogBottom div:nth-child(1) {
    width: 100% !important;
    text-align: -webkit-right;
    padding-top: 3px;
}


/*
///////////////////////////////////////////////////////
/////////////////////////////////////////////////////*/


