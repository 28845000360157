.all_news_container{
    width:100%;
    height:fit-content;
    background-color: rgb(251, 251, 251);    
    padding-top: 6px;
}


.news_container{
    width: 98%;
    height: 120px;
    display: flex;
    margin: auto;
    cursor: pointer;
    position: relative;
    margin-bottom: 6px;
    background-color: white;
    border: solid 0.01rem #dadada;
}


.news_container:hover{
    background-color: rgb(250, 250, 250);
}


.img_news_container{
    width: 30%;
    height: 98%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 0%;
    margin: auto !important;
    margin-left: 2px;
}

.title_description_container{
    width: 70%;
    height: 100%;
    position: relative;
    padding-top:8px;
    padding-left: 8px ;
    padding-right: 3px;
}

.news_title{
    width: 100%;
    height: fit-content;
    font-size: .87rem;
    font-weight: 700;
    color: #2a2a2a;
}

.news_description{
    margin-top: 4px;
    font-size: .76rem;
    font-weight: 600;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    color: #2a2a2a;
}

.news_released_time{
    position: absolute;
    bottom: 1px;
    right: 9px;
    font-size: .68rem;
    font-weight: 600;
    color: #535353;
}