
.contract_full_dialog .MuiAppBar-positionFixed {
    position: relative !important;
}

.contract_type_Container{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    margin: 10px auto 30px auto !important;
}

.contract_content_toolbar_left .search_filter_container {
   align-items: center !important;
   width: fit-content !important;
}


.contract_type{
    width: 100%;
    display: flex;
    justify-content: center;
    vertical-align: middle;
    align-items: center;
}


.contract_content_toolbar{
    background-color: #343a40;
    display: flex;
    align-items: center;
    align-content: center;
}

@media (min-width: 600px){
    .MuiToolbar-gutters {
        padding-left: 14px !important;
        padding-right: 14px !important;
    }
}

.contract_content_toolbar_main_container{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.contract_content_toolbar_left{
    width: 45%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
    flex-wrap: wrap;
}

.contract_content_toolbar_left .page_title{
    flex-direction: row;
    align-items: center;
}

.contract_content_toolbar_left .header_form{
    margin-left: 20px !important;
    margin-right: 0px !important;
}

.contract_content_toolbar_right .icon_buttons_container{
    min-width: 122px;
}


.supplier_contract_content_toolbar .contract_content_toolbar_right .icon_buttons_container {
    min-width: fit-content;
}

.contrat_type{
    font-size: 1.35rem !important;
    text-transform: uppercase;
}


.contract_content_toolbar_left .MuiInputLabel-shrink {
    transform: translate(0, 5.5px) scale(0.75) !important;
    transform-origin: top left;
    color: #f9f3f3 !important;
}

.contract_content_toolbar_left label + .MuiInput-formControl {
    margin-top: 16px;
    color: #f9f3f3 !important;
}

.contract_content_toolbar_left .MuiSelect-select.MuiSelect-select {
    padding-right: 24px;
    color: #f9f3f3 !important;
    background-color: inherit !important;
}

.contract_content_toolbar_left .MuiInput-underline:before {
    border: none !important;
}

.contract_content_toolbar_left .MuiSelect-icon {
    color: #f9f3f3 !important;
}

.contract_content_toolbar_left .MuiInputBase-root {
    font-family: var(--body-font) !important;
    border: solid 1px #f9f3f3 !important;
    padding-left: 6px !important;
    border-radius: 4px !important;
    background-color: inherit !important;
}

.contract_content_toolbar_left .MuiSelect-nativeInput {
    left: 0;
    width: 100%;
    bottom: 0;
    opacity: 0;
    position: absolute;
    pointer-events: none;
}


.contract_content_toolbar_right{
    width: 55%;
    height: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    align-content: center;
    flex-wrap: wrap;
}


.contract_content_toolbar_right .border_button{
    font-size:.85rem !important;
    padding: .28rem .9rem !important;
}

.contract_content_toolbar_right .contract_being_edited{
    background-color:#f33640f3 !important;
    font-size:.85rem !important;
    padding: .28rem .9rem !important;
    font-weight: 600;
    color: white !important;
    margin-right:3px !important;
    height: fit-content;
    align-self: center;
  }

.contract_content_toolbar_right .able_to_changeContract{
    background-color:#33b66e !important;
    font-weight: 600;
    color: white !important;
    margin-right:3px !important;
  }
  
.contract_content_toolbar_right .unable_to_changeContract{
    background-color:#ffa726 !important;
    font-weight: 600;
    color: white !important;
    margin-right:3px !important;
}
  


.text_editor_container{
    width: 100%;
    flex: 1;
    background: rgb(235, 235, 235);
    background-repeat: no-repeat;
    background-size: cover;
    padding: 15px;
    overflow: auto;
    margin-top: 0;
    overflow-x: hidden;
}

.sun-editor {
    height: 95% !important;
}

.sun-editor-editable p {
    margin: 0 0 3px !important;
}

.sun-editor .se-resizing-bar {
    display: none !important;
}

.sun-editor .se-wrapper .se-wrapper-inner {
    height: 100% !important;
}

.sun-editor .se-wrapper {
    position: relative!important;
    width: 100% !important;
    height: 100% !important;
    overflow: auto;
    z-index: 1;
}


@media (max-width: 1100px){

    .contract_content_toolbar_main_container{
        align-items: flex-end;
        padding: 5px 0;
    }

    .contract_content_toolbar_main_container .MuiIconButton-root {
        padding: 10px;
    }

    .contract_content_toolbar_left .header_form {
        margin-left: 0px !important;
        width: 185px !important;
    }

    .contract_content_toolbar_right .border_button {
        font-size: .75rem !important;
        padding: 0.22rem 0.8rem !important;
    }

    .contract_content_toolbar_right .unable_to_changeContract {
        font-size: .75rem !important;
        padding: 0.22rem 0.8rem !important;
    }

    .sun-editor .se-svg, .sun-editor button>svg {
        width: 15px !important;
        height: 15px !important;
    }

    .sun-editor .se-btn {
        width: 31px !important;
        height: 31px !important;
    }

    .sun-editor .se-btn-select.se-btn-tool-font {
        width: 92px !important;
    }

    .sun-editor .se-btn-select.se-btn-tool-size {
        width: 70px !important;
    }
}

@media (max-width: 850px){

    .contract_content_toolbar_left .header_form {
        width: 175px !important;
    }

    .contract_content_toolbar_main_container .MuiIconButton-root {
        padding: 8px;
    }

    .contract_content_toolbar_left {
        width: 240px;
    }

    .contract_content_toolbar_right {
        flex: 1;
    }

    .contract_content_toolbar_right .border_button {
        font-size: .7rem !important;
        padding: 0.2rem 0.7rem !important;
    }

    .contract_content_toolbar_right .unable_to_changeContract {
        font-size: .7rem !important;
        padding: 0.2rem 0.7rem !important;
    }

    .sun-editor .se-svg, .sun-editor button>svg {
        width: 14px !important;
        height: 14px !important;
    }

    .sun-editor .se-btn {
        width: 28px !important;
        height: 28px !important;
    }

    .sun-editor .se-btn-select.se-btn-tool-font {
        width: 100px !important;
    }

    .sun-editor .se-btn-select.se-btn-tool-font {
        width: 86px !important;
    }

    .sun-editor .se-btn-select.se-btn-tool-size {
        width: 67px !important;
    }

    .text_editor_container{
        padding: 12px;
    }
}


@media (max-width: 682px){
    .contract_content_toolbar_right .icon_buttons_container{
        margin-top: 5px;
    }

    .sun-editor .se-svg, .sun-editor button>svg {
        width: 12px !important;
        height: 12px !important;
    }

    .sun-editor .se-btn {
        width: 25px !important;
        height: 25px !important;
    }

    .sun-editor .se-btn-select.se-btn-tool-font {
        width: 80px !important;
    }

    .sun-editor .se-btn-select.se-btn-tool-size {
        width: 60px !important;
    }

    .text_editor_container{
        padding: 10px;
    }
}


@media (max-width: 550px){

    .text_editor_container{
        padding: 7px;
    }
}

