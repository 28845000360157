.my_full_dialog .MuiDialog-paper{
    margin: 0 !important;
}


.demandeAppel_container{
    width:100%;
    height: 100%;
}

.demandeAppel_container .documentsContainer {
    width: 320px;
    height: 100%;
    display: block;
}

.AccordCtrl_container{
    align-items: center !important;
    align-content: center !important;
    text-align: center !important;
    width: fit-content;
    height:100%;
}


.AccordCtrl_container .AccordCtrl_contract{
    display: flex;
    justify-content: center;
    height: 30%;
}

.AccordCtrl_container div:nth-child(2){
    width: fit-content;
    margin: auto;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
}

.AccordCtrl_container .checkIcon {
    width: 1.1em !important;
    height: 1.1em !important;
  }

  .AccordCtrl_container .closeSharpIcon {
    width: 1.1em !important;
    height: 1.1em !important;
  }


/* /////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////// ////////////////////////*/

.plus_details_dialog_container{
    padding: 18px;
    margin-bottom: 10px;
    display:flex;
    justify-content: space-between;
    align-items: flex-start;
    align-content: flex-start;
    flex-wrap: wrap;
}

.plus_details_dialog_container .header_form {
    width:205px;
    margin:0px 35px 15px 0px !important;
}


.demandeAppel_container .MuiTableCell-body:nth-child(5) {
    min-width:295px !important;
}


@media all and (max-width: 1080px){
    
    .plus_details_dialog_container .header_form {
        width:205px;
        margin:0px 35px 10px 0px !important;
    }
      
}


@media all and (max-width: 846px){

    .plus_details_dialog_container .header_form {
        width:190px;
        margin:0px 15px 10px 0px !important;
    }
}


@media all and (max-width: 750px){

    .plus_details_dialog_container .header_form {
        width:185px;
        margin:0px 15px 6px 0px !important;
    }
}





@media all and (max-width: 580px){
    
    .plus_details_dialog_container .header_form {
        width:180px;
    }
}    
    



@media all and (max-width: 445px){
    
    .plus_details_dialog_container .header_form {
        width:150px;
        margin:0px 10px 4px 0px !important;
    }

}




@media all and (max-width: 379px){

    .plus_details_dialog_container .header_form {
        width:160px;
        margin:0 !important;
    }
}

