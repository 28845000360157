.supplier_proposels_dialogBottom{
    width: 100%;
}

.supplier_proposels_dialogBottom .submit_but{
    background:#343a40;
    color: white;
}

.supplier_proposels_dialogBottom .cancel_but{
    background:#e92a44;
}

.supplier_proposels_dialogBottom .cancel_but:hover{
    background:#e92a44;
    opacity: 0.9;
}

.supplier_proposels_dialogBottom .valide_but{
    background:#18ac67;
}

.supplier_proposels_dialogBottom .valide_but:hover{
    background:#18ac67;
    opacity: 0.9;
}

.supplier_proposels_dialogBottom .return_but:hover{
    background:#343a40;
    opacity: 0.9;
}


.documentsContainer_proposelPage{
    display: flex;
    margin-bottom: 15px;
    padding-left: 15px;
    flex-wrap: wrap;
    margin: auto;
    width: fit-content;
}


.documentsContainer_proposelPage .documentsContainer{
    width: 100%;
    height: 100%;
    display: flex;
    margin-top: 45px !important;
}


.documentsContainer_proposelPage .documentsDownload{
    width: fit-content !important;
    display: flex;
    vertical-align: middle;
    align-items: center;
}

.documentsContainer_proposelPage .documentsDownload label {
    display: inline-block;
    margin-bottom: 0 !important;
}

.documentsContainer_proposelPage .documentsDownload .download_button{
    margin-left: 12px !important;
    color: black;
}


.supplier_proposels_dialogBottom .dialogBottom div:nth-child(2){
    height: 50px !important;
}

.nextStep .error{
    padding: 10px 12px !important;
}

.supplier_proposels_dialogBottom .error{
    width: 100%;
    font-size: 1.1em;
    color: #dd3461 !important;
    background: #dc35451f;
    border-radius: 5px;
    text-align: start;
    margin: 0 !important;
}

.supplier_proposels_dialogBottom .dialogBottom div:nth-child(2){
    margin-left: 20px;
    display: flex;
    justify-content: flex-end;
}