.weather_container{
    width: 100%;
    height: 95%;
    position: relative;
}


.weather_close_icon{
    position: absolute;
    border-radius: 24px;
    width: 22px;
    height: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    transition: all 150ms ease-in-out 0s;
    z-index: 3;
    right: 10px;
    top: 10px;
    color: #FFF;
}   


.weather_close_icon:hover {
    background: rgba(255,255,255,0.2);
}

.container-1-2-1{
    width: 100% !important;
    height: 95% !important;
}

.main-1-2-2{
    height: fit-content !important;
}

.daysPanel-1-2-29{
    height: fit-content !important;
}

.rw-today-hr:nth-child(5){
    display: none !important;
    opacity: 0 !important;
}

.rw-today-info:last-child {
    color: rgb(181, 222, 244);
    display: none !important;
}


.desc-1-2-32 {
    margin: 8px 0px 5px 0px !important;
    font-size: 12px !important;
}

.icon-1-2-34 {
    padding-top: 0px !important;
}

.weather_container .header-1-2-3 {
    margin: 0px 0px 8px !important;
    font-size: 2rem !important;
    font-weight: 300;
    letter-spacing: 1.5px !important;
}

.weather_container .left-1-2-4 {
    width: 60%;
    padding: 15px;
}

@media all and (max-width: 1080px){

    .weather_container .left-1-2-4 {
        width: 65% !important;
        padding: 10px 15px !important;
    }

    .weather_container .header-1-2-3 {
        margin: 0px 0px 7px !important;
        font-size: 1.9rem !important;
    }

    .weather_container .container-1-2-1 {
        font-size: 12px !important;
    }

    .weather_container .hr-1-2-15 {
        margin: 6px 0px !important;
    }

    .weather_container .current-1-2-11 {
        font-size: 37px !important;
    }

    .weather_container .right-1-2-5 {
        width: 35% !important;
        padding: 20px 30px !important;
    }

    .weather_container .right-1-2-5 svg{
       height: 64% !important;
    }


    .weather_container .day-1-2-30 {
        margin: 6px 0px !important;
    }

    .weather_container .desc-1-2-32 {
        margin: 4px 0px 3px 0px !important;
        font-size: 11px !important;
        line-height: .8rem;
    }

    .weather_container  .icon-1-2-34 svg{
        width: 37px !important;
        height: 37px !important;
    }

    .weather_container .desc-1-2-13 {
        font-size: 15px !important;
    }
}




@media all and (max-width: 850px){

    .weather_container .header-1-2-3 {
        margin: 0px 0px 7px !important;
        font-size: 1.5rem !important;
    }

    .weather_container .container-1-2-1 {
        font-size: 11px !important;
    }

    .weather_container .hr-1-2-15 {
        margin: 5px 0px !important;
    }

    .weather_container .current-1-2-11 {
        font-size: 33px !important;
    }

    .weather_container .right-1-2-5 {
        width: 35% !important;
        padding: 20px 40px !important;
    }

    .weather_container .day-1-2-30 {
        margin: 6px 0px !important;
    }

    .weather_container .desc-1-2-32 {
        margin: 4px 0px 3px 0px !important;
        font-size: 10px !important;
        line-height: .8rem;
    }

    .weather_container  .icon-1-2-34 svg{
        width: 33px !important;
        height: 33px !important;
    }

    .weather_container .desc-1-2-13 {
        font-size: 15px !important;
    }

    .weather_container .date-1-2-31 {
        font-size: 11px !important;
    }

    .weather_container .range-1-2-33 {
        font-size: 11px !important;
    }
}





@media all and (max-width: 450px){

    .weather_container .header-1-2-3 {
        margin: 0px 0px 7px !important;
        font-size: 1.4rem !important;
    }

    .weather_container .container-1-2-1 {
        font-size: 10px !important;
    }

    .weather_container .hr-1-2-15 {
        margin: 5px 0px !important;
    }

    .weather_container .current-1-2-11 {
        font-size: 30px !important;
    }

    .weather_container .right-1-2-5 {
        width: 35% !important;
        padding: 10px 25px !important;
    }

    .weather_container .right-1-2-5 svg{
        height: 65px !important;
        width: 65px !important;
     }

    .weather_container .day-1-2-30 {
        margin: 6px 0px !important;
    }

    .weather_container .desc-1-2-32 {
        margin: 4px 0px 3px 0px !important;
        font-size: 9px !important;
        line-height: .8rem;
    }

    .weather_container  .icon-1-2-34 svg{
        width: 29px !important;
        height: 29px !important;
    }

    .weather_container .desc-1-2-13 {
        font-size: 13px !important;
    }

    .weather_container .date-1-2-31 {
        font-size: 10px !important;
    }

    .weather_container .range-1-2-33 {
        font-size: 10px !important;
    }
}