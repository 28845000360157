.order_plus_details_container{
    width: 100%;
    flex:1;
    display: flex;
    flex-direction: column;
}

.order_plus_details_table_container{
    width: 96% !important;
    height: 100%;
    margin: 12px auto 0 auto;
    background-color: rgb(254, 254, 254);
    border: solid 1px rgb(228, 227, 227);
}

.order_plus_details_container .proceed_button_container{
    width: 100% !important;
    margin: auto;
    display: flex;
    justify-content: flex-end; 
}

.order_plus_details_table_container .MuiTableCell-head {
    font-weight: 600 !important;
    font-size: .95rem;
}
  


.order_plus_details_table_container .product_cell_img_container{
    width: 94px;
    height: 99px;
    display: flex;
    border: solid 1px rgb(194, 194, 194);
}

.order_plus_details_table_container .product_cell_img_container img{
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    object-fit: cover !important;
    border: none;
}

.order_plus_details_table_container .product_cell_title_container{
    flex: 1;
    margin-left: 20px;
    font-size: 1.08rem;
    font-weight: 700;
    color: rgb(57, 57, 57);
}

.order_plus_details_table_container .product_cell_title_container #title{
    text-transform: capitalize;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}

.order_plus_details_table_container .product_cell_title_container .product_cell_categorie_container{
    width: 100%;
    font-size: .85rem;
    font-weight: 500;
    text-transform: capitalize;
    color: rgb(59, 91, 91);
}

.order_plus_details_table_container .product_cell_title_container .product_cell_categorie_container span{
    font-size: inherit;
    font-weight: 700;
}

.order_plus_details_table_container .amount_container {
    border-radius: 5px;
    width: 125px !important;
    height: fit-content;
    padding: 6px 9px;
    font-size: inherit;
}

.order_plus_details_table_container input[type=number]::-webkit-inner-spin-button, .product_details_infos_container
input[type=number]::-webkit-outer-spin-button {  
   display: none !important;
}


.order_plus_details_table_container .addIcon{
    display:flex;
    background-color: rgb(253, 253, 253);
    color: #343a40;
    border-radius: 100%;
    cursor: pointer;
    text-align: center;
  }
  
  .order_plus_details_table_container .addIcon:hover{
    background-color: #e9fce9;
  }
  

.order_plus_details_table_container .MuiTableCell-body:nth-child(1) {
    min-width: 320px !important;
    width:48% !important;
    font-size: 1rem;
    font-weight: 600;
}

.order_plus_details_table_container .MuiTableCell-body:nth-child(2) {
    width:125px !important;
}

.order_plus_details_table_container .MuiTableCell-body:nth-child(3){
    width:105px !important;
}

.order_plus_details_table_container .MuiTableCell-body:nth-child(3) .amount_container {
    width: 60px !important;
    text-align: center;
}


.order_plus_details_table_container .MuiTableCell-body:nth-child(4) {
    width:105px !important;
}

.order_plus_details_table_container .MuiTableCell-body:nth-child(4) .amount_container {
    width: 60px !important;
    text-align: center;
}

.order_plus_details_table_container .MuiTableCell-body:nth-child(5) {
    width:135px !important;
}


.total_amount_container{
    width: 96% !important;
    margin: 0 auto 15px auto;
    height: fit-content;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}


.total_amount_table_container{
    width: 450px !important;
    height: fit-content;
    background-color: rgb(254, 254, 254);
    border-left: solid 1px rgb(228, 227, 227);
    border-right: solid 1px rgb(228, 227, 227);
    border-bottom: solid 1px rgb(228, 227, 227);
}

.total_amount_table_container .MuiTableCell-body{
    width:55% !important;
    font-size: .95rem;
    font-weight: 700;
    padding: 12px 18px !important;
}

.total_amount_table_container .MuiTableCell-body:nth-child(2) {
    width:50% !important;
    font-weight: 600;
    color: rgb(116, 116, 116);
}

.total_amount_table_container .amount_container {
    border-radius: 5px;
    width: 60px !important;
    height: fit-content;
    align-self: flex-end;
    padding: 6px 9px;
    font-size: inherit;
    text-align: center;
    margin-left: calc( 100% - 59px );
}


.total_amount_table_container input[type=number]::-webkit-inner-spin-button, .total_amount_table_container
input[type=number]::-webkit-outer-spin-button {  
   display: none !important;
}

.order_plus_details_container .proceed_button_container{
    width: 96% !important;
    margin: 20px auto;
    display: flex;
    justify-content: flex-end;
}


.order_plus_details_container .proceed_button_container .submit_but{
    background:rgb(228, 88, 32);
    color: white;
    font-size: .9rem;
}


.order_plus_details_container .proceed_button_container .submit_but:hover{
    opacity: .9;
}

.order_plus_details_container .cancel_but{
    background:#e92a44 !important;
    margin-right: 6px;
}


.order_plus_details_container .valide_but{
    background:#18ac67 !important;
}

.order_plus_details_container .cancel_but:hover{
    background:#e92a44 !important;
    opacity: .9 !important;
}

.order_plus_details_container .valide_but:hover{
    background:#18ac67 !important;
    opacity: .9 !important;
}


@media all and (max-width: 1100px){
    .order_plus_details_table_container .amount_container {
        width: 107px !important;
        padding: 6px 9px !important;
    }
    .order_plus_details_table_container .MuiTableCell-body:nth-child(3) .amount_container, .total_amount_table_container .amount_container {
        width: 55px !important;
    }
    .order_plus_details_table_container .MuiTableCell-body:nth-child(4) .amount_container {
        width: 55px !important;
    }
    .order_plus_details_table_container .product_cell_img_container {
        width: 92px;
        height: 96px;
    }
    .order_plus_details_table_container .product_cell_title_container {
        flex: 1;
        margin-left: 17px;
        font-size: 1rem;
    }
    .order_plus_details_table_container .product_cell_title_container .product_cell_categorie_container {
        font-size: .82rem;
    }
}


@media all and (max-width: 900px){
    .order_plus_details_table_container, .total_amount_container {
        width: 98% !important;
    }

    .order_plus_details_container .proceed_button_container {
        width: 98% !important;
        margin: 15px auto !important;
    }

    .order_plus_details_table_container .amount_container {
        width: 95px !important;
        padding: 5px 7px !important;
    }

    .total_amount_table_container .amount_container {
        padding: 5px 7px !important;
    }

    .order_plus_details_table_container .MuiTableCell-body:nth-child(3) .amount_container, .total_amount_table_container .amount_container {
        width: 50px !important;
    }
    .order_plus_details_table_container .MuiTableCell-body:nth-child(4) .amount_container {
        width: 50px !important;
    }
    .order_plus_details_table_container .product_cell_img_container {
        width: 91px;
        height: 95px;
    }
    .order_plus_details_table_container .product_cell_title_container {
        flex: 1;
        margin-left: 14px;
    }
    .total_amount_table_container {
        width: 400px !important;
    }
    .total_amount_table_container .MuiTableCell-body{
        width:55% !important;
        font-size: .95rem;
        font-weight: 700;
        padding: 11px 17px !important;
    }
}

@media all and (max-width: 700px){

    .order_plus_details_table_container .product_cell_img_container {
        width: 89px;
        height: 93px;
    }

    .total_amount_table_container {
        width: 360px !important;
    }
    .order_plus_details_table_container .product_cell_title_container {
        margin-left: 12px;
        font-size: .9rem;
    }
    .order_plus_details_table_container .product_cell_title_container .product_cell_categorie_container {
        font-size: .75rem;
    }

    .total_amount_table_container .MuiTableCell-body{
        padding: 10px 16px !important;
        font-weight: 600;
    }

    .order_plus_details_container .proceed_button_container {
        margin: 10px auto !important;
        flex-wrap: wrap;
    }
}


@media all and (max-width: 600px){

    .order_plus_details_table_container .product_cell_img_container {
        width: 79px;
        height: 83px;
    }

    .order_plus_details_table_container .MuiTableCell-body:nth-child(1) {
        min-width: 300px !important;
    }

    .total_amount_table_container {
        width: 320px !important;
    }

    .order_plus_details_table_container .product_cell_title_container {
        margin-left: 9px;
        font-size: .82rem;
    }
    .order_plus_details_table_container .product_cell_title_container .product_cell_categorie_container {
        font-size: .7rem;
    }

    .total_amount_table_container .MuiTableCell-body{
        padding: 8px 14px !important;
    }


    .order_plus_details_table_container .amount_container {
        width: 88px !important;
        padding: 4px 8px !important;
    }
    .order_plus_details_table_container .MuiTableCell-body:nth-child(3) .amount_container, .total_amount_table_container .amount_container {
        width: 47px !important;
        padding: 4px 8px !important;
    }
    .order_plus_details_table_container .MuiTableCell-body:nth-child(4) .amount_container {
        width: 47px !important;
        padding: 4px 8px !important;
    }

    .total_amount_table_container .amount_container {
        width: 47px !important;
        padding: 4px 8px !important;
    }


}



@media all and (max-width: 450px){

    .order_plus_details_table_container .product_cell_img_container {
        width: 69px;
        height: 73px;
    }

    .order_plus_details_table_container .MuiTableCell-body:nth-child(1) {
        min-width: 280px !important;
    }

    .total_amount_table_container {
        width: 300px !important;
    }
    .order_plus_details_table_container .product_cell_title_container {
        margin-left: 6px;
        font-size: .8rem;
    }
    .order_plus_details_table_container .product_cell_title_container .product_cell_categorie_container {
        font-size: .65rem;
    }

    .total_amount_table_container .MuiTableCell-body{
        padding: 6px 10px !important;
    }

    .order_plus_details_table_container .amount_container {
        width: 67px !important;
        padding: 3.5px 4px !important;
    }
    .order_plus_details_table_container .MuiTableCell-body:nth-child(3) .amount_container, .total_amount_table_container .amount_container {
        width: 45px !important;
        padding: 3.5px 4px !important;
    }
    .order_plus_details_table_container .MuiTableCell-body:nth-child(4) .amount_container {
        width: 45px !important;
        padding: 3.5px 4px !important;
    }

    .total_amount_table_container .amount_container {
        width: 45px !important;
        padding: 3.5px 4px !important;
    }


    .order_plus_details_container .proceed_button_container {
        margin: 4px auto !important;
    }

}


@media all and (max-width: 380px){

    .order_plus_details_table_container .product_cell_img_container {
        width: 65px;
        height: 69px;
    }

    .order_plus_details_table_container .MuiTableCell-body:nth-child(1) {
        min-width: 280px !important;
    }

    .total_amount_table_container {
        width: 280px !important;
    }
    .order_plus_details_table_container .product_cell_title_container {
        margin-left: 6px;
        font-size: .76rem;
    }
    .order_plus_details_table_container .product_cell_title_container .product_cell_categorie_container {
        font-size: .6rem;
    }

    .total_amount_table_container .MuiTableCell-body{
        padding: 5px 6px !important;
    }
}
