

.add_product_modal_infos .MuiOutlinedInput-multiline {
    padding: 7.5px 14px !important;
}


.add_product_modal_infos .MuiInputLabel-outlined.MuiInputLabel-shrink {
    /* margin-right: 5px; */
    transform: translate(8px, -10.5px) scale(0.75) !important;
    z-index: 4 !important;
    background-color: white !important;
    padding: 0 5px !important;
}



.add_product_modal_pics{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}


.files{
    display : block;
    width:160px;
    height:120px;
    text-align: -webkit-center;
    border: 2px dashed #57596c !important;
    margin: 0;
    padding: 0;
    cursor:pointer;
    font-size:.9rem;
    font-weight:500;
    color:black;
    padding-top:20px;
    margin-bottom:10px;
}

.invalid-fileInput{
    border: 2px dashed #dc3545!important;
}

#input_contenair_enf1{
    background-image: url("https://cdn-icons-png.flaticon.com/128/1092/1092004.png");
    background-size:30%;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    width: 100%;
    height: 60px;
    transform:scale(0.85);
    cursor: pointer;
  }


  .blocm{
    position: absolute;
    top: 13px;
    right: 6px;
    background-color:rgba(0,0,0,0);
    border: none;
    cursor: pointer;
    cursor:pointer;
    outline: none;
    color:rgba(211,57,92);
  }

  .blocm>div:nth-child(1){
        background-color:rgba(211,57,92);
        border:solid 1.75px rgba(211,57,92);
        width:20px;
        border-radius:3px;
        transition:all .5s ease;
        transform:rotateZ(45deg);

    }
    .blocm>div:nth-child(2){
        background-color:rgba(211,57,92);
        border:solid 1.5px rgba(211,57,92);
        width:20px;
        border-radius:3px;
        transition:all .5s ease;
        transform:translateY(-3.1px) rotateZ(-45deg);
    }
 
  .myClass{
    width:160px;
    height:120px;
    border: 2px dashed #57596c !important;
    display:flex;
    padding:0; 
    margin: 0;
    margin-right:15px;
    margin-bottom:15px;
    justify-content: center;
    align-items: center;
    background-color: rgba(235, 235, 235, 0.2);
    position:relative;
    z-index: 0;
    
  }
  
  .myClass img{
    width: 100%;
    height: 100%;
    z-index:-1;
    margin: auto;
    object-fit: contain;
  }

  