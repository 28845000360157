.dndflow {
    flex-direction: column !important;
    display: flex;
    flex-grow: 1;
    height: 100%;
  }
  
  .dndflow aside {
    border-bottom: 1px solid rgb(109, 109, 109);
    font-size: 12px;
    background: #4a4a4a;
    width: 100%;
    position: relative !important;
  }

  
  .dndflow .reactflow-wrapper {
    flex-grow: 1;
    height: 100%;
    background: #fbfbfb;
  }
  
  .dndflow .selectall {
    margin-top: 10px;
  }
  
  @media screen and (min-width: 768px) {
    .dndflow {
      flex-direction: row;
    }
  
  }
  

.new_node_modal label{
    color:#888888;
    margin: 0;
    padding: 0 2px 2px 0 ;
}

.new_node_modal .inputform {
    border: 1px solid #c4c3c3;
    border-bottom-width: 1px !important;
    border-radius: 6px;
    color: #2c2c2c;
    height: auto;
    font-size: .95rem;
    box-shadow: none;
    height: 36px;
    background-image: none !important;
    background-color: white !important;
}

.new_node_modal textarea{
    max-width: 100% !important;
    max-height: none !important;
    padding: 7px 10px 7px 7px !important;
    resize: auto !important;
    font-size: .95rem;
    border: 1px solid #c4c3c3 !important;
    line-height: 1 !important;
    border-radius: 6px;
}

.dndflow .MuiInputLabel-shrink {
    transform: translate(0, 5.5px) scale(0.75) !important;
    transform-origin: top left;
    color: #f9f3f3 !important;
}

.dndflow label + .MuiInput-formControl {
    margin-top: 16px;
    color: #f9f3f3 !important;
}

.dndflow .MuiSelect-select.MuiSelect-select {
    padding-right: 24px;
    color: #f9f3f3 !important;
    background-color: inherit !important;
}

.dndflow .MuiInput-underline:before {
    border: none !important;
}

.dndflow .MuiSelect-icon {
    color: #f9f3f3 !important;
}

.dndflow .MuiInputBase-root {
    font-family: var(--body-font) !important;
    border: solid 1px #f9f3f3 !important;
    padding-left: 6px !important;
    border-radius: 4px !important;
    background-color: inherit !important;
}

.dndflow .MuiSelect-nativeInput {
    left: 0;
    width: 100%;
    bottom: 0;
    opacity: 0;
    position: absolute;
    pointer-events: none;
}


.dndflow  .nodes_cont{
    width: fit-content;
    height: fit-content;
    position: absolute;
    background: #4a4a4a;
    border-top: solid 1px rgb(208, 208, 208);
    top: 100%;
    padding: 3px 6px;
    z-index: 1500 !important;
}

.dndflow aside .description {
    margin-bottom: 10px;
    padding: 15px 10px;
}

.dndflow .dndnode {
    height: fit-content;
    padding: 4px;
    border: 1px solid white;
    border-radius: 2px;
    margin: 6px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: grab;
    padding: 3px 6px;
    color:white;
    width: 45px;
    font-size: .7rem;
    text-align: center;
    cursor: pointer;
}

.dndflow .dndnode:hover {
    background-color: #656565;
}


.react-flow__node-default, .react-flow__node-group, .react-flow__node-input, .react-flow__node-output {
    padding: 6px 10px !important;
    width: fit-content !important;
    min-width: 75px !important;
    border: 1px solid rgb(112, 112, 112) !important;
    border-radius: 4px !important;
    font-size: .7rem !important;
    background-color: #fefefe !important;
}

/* .dndflow .dndnode.input {
    border-color: #0041d0;
}

.dndflow .dndnode.output {
    border-color: #ff0072;
} */