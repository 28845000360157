.add_purchase_request_mainPage{
    width: 670px;
    height: fit-content;
    margin: auto;
    margin-top: 50px;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    flex-wrap: wrap;
}

.purchase_request_type_container{
    flex:1 1 225px;
    max-width: 305px !important;
    height: 95px !important;
    box-shadow: 0px 0px 3px 1px #e0e0e0 !important;
    margin: 25px 10px;
    margin-top: 0;
    border-radius: 5px;
    display:flex;
    background-color: white;
    cursor: pointer;
    transition: all 150ms ease-in-out 0s;
    transform: scale(1);
}

.purchase_request_type_container:hover{
    transition: all 100ms ease-in-out 0s;
    background-color: rgb(253, 253, 253);
    transform: scale(1.02);
}

.add_purchase_request_mainPage .request_type_icon{
    width: 25%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    padding-left: 5px;
}

.add_purchase_request_mainPage .request_type_icon .MuiSvgIcon-root {
    width: 38px !important;
    height: 38px !important;
    color: rgb(80, 79, 79) !important;
}

.add_purchase_request_mainPage .request_type{
    width: 78%;
    height: 100%;
    font-weight: 600;
    padding: 15px 4px;
    padding-right: 5px;
    font-size: 1.17rem;
    color: rgb(80, 79, 79) !important;
    display: flex;
    align-items: center;
    align-content: center;
}
