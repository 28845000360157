@import url(https://fonts.googleapis.com/css2?family=Nunito:wght@400;600;700&display=swap);

:root {
  --body-font: 'Nunito', sans-serif;
}


#root{
  height: 100%;
}

html {
  margin: 0;
  min-height: 100% !important;
    height: 100vh;
}



body {
    position: relative;
    font-family: 'Nunito', sans-serif !important;
    font-family: var(--body-font) !important;
    min-height: 100% !important;
    height: 100vh;
    margin: 0;
    width: 100%;
}

.form-control,select {
  font-family: 'Nunito', sans-serif !important;
  font-family: var(--body-font) !important;
}

.MuiSelect-select.MuiSelect-select{
  font-family: 'Nunito', sans-serif !important;
  font-family: var(--body-font) !important;
}

.MuiInputBase-input.MuiInputBase-input{
  font-family: 'Nunito', sans-serif !important;
  font-family: var(--body-font) !important;
}


.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}


.bg-cover {
  background-size: cover !important;
}

.MuiGrid-container {
  width: -moz-fit-content !important;
  width: -webkit-fit-content !important;
  width: fit-content !important;
  align-content: center !important;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  font-family: 'Nunito', sans-serif !important;
  font-family: var(--body-font) !important;
}

.MuiGrid-container {
  flex-wrap: nowrap !important;
}

.MuiGrid-align-items-xs-flex-start {
  align-items: flex-end !important;
}

.MuiGrid-spacing-xs-1  {
  margin: 0px !important;
  height: 100% !important;
}

.MuiGrid-spacing-xs-1 > .MuiGrid-item {
  position: relative;
  top: 4px !important;
  margin-right: 1px !important;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  padding-top: 0 !important;
}

.MuiInputLabel-shrink {
  -webkit-transform: translate(0, 4px) scale(0.75) !important;
          transform: translate(0, 4px) scale(0.75) !important;
}

.form{
  padding: 0 20px !important;
  padding-top:15px !important;
  padding-bottom: 10px !important;
}


.error{
  width: 100%;
  font-size: 1.1em;
  color: #e13d68 !important;
  background: #dc35451f;
  padding: 10px 20px;
  border-radius: 5px;
  margin-bottom: 15px;
  text-align: start;
}

.set_refuse_raison_container .error{
  width: 100%;
  font-size: 1.1em;
  color: #dd3461;
  background: #dc35451f;
  padding: 10px 20px;
  border-radius: 5px;
  margin: 0;
  margin-bottom: 15px;
  text-align: start;
}

.is-invalid{
  border-color: #dc3545 !important;
  background-image:none !important;
}


input:focus{
  border-color: #7faad4 !important;
}

input:focus + .input-group-text{
  border-color: #7faad4 !important;
}


.input-group .form-control:last-child, .input-group-text:last-child{
  border: 1px solid #c4c3c3;
  border-left: 0 none;
  border-radius: 6px;
  height: 39px;
  padding: 0 16px !important;
}

.invalid-inputgroup{
  border-color: #dc3545 !important;
  border-left: none;
}

.form-select:invalid  {
  border-color: #dc3545 !important;
  padding-right: 4.125rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e") !important;
  background-position: right .75rem center,center right 2.25rem;
  background-size: 16px 12px,calc(.75em + .375rem) calc(.75em + .375rem);
}

.form-select:valid {
  border-color:#ced4da;
  padding-right: 4.125rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e") !important;
  background-position: right .75rem center,center right 2.25rem;
  background-size: 16px 12px,calc(.75em + .375rem) calc(.75em + .375rem);
}


.set_refuse_raison_container .MuiInputLabel-outlined.MuiInputLabel-shrink{
  -webkit-transform: translate(8px, -9px) scale(0.75) !important;
          transform: translate(8px, -9px) scale(0.75) !important;
  z-index: 4 !important;
    background-color: white !important;
    padding: 0 5px !important;
}

.submit_but{
    all: unset;
    background:#343a40;
    color: white;
    position: relative !important;
    box-shadow: none !important;
    font-family: 'Nunito', sans-serif !important;
    font-family: var(--body-font) !important;
    padding: .3rem 1.1rem !important;
    text-transform: capitalize !important;
    margin: 6px 5px;
    font-size: .9rem !important;
    border-radius: 4px !important;
    transition: -webkit-filter 300ms;
    transition: filter 300ms;
    transition: filter 300ms, -webkit-filter 300ms;
    cursor: pointer;
    align-items: center;
    text-align: center;
}


.swal-button{
    font-family: 'Nunito', sans-serif !important;
    font-family: var(--body-font) !important;
    padding: .5rem 1.1rem !important;
}

.swal-button--cancel {
  background-color: #e8e7e7 !important;
}

.swal-button--danger {
  background-color: #ef635d !important;
}

.border_button{
  background: none !important;
  border: solid 1px rgb(249, 249, 249) !important;
}

.border_button:hover{
  background: rgba(147, 147, 147, .4) !important;
}


.search_filter_container {
  display: flex;
  justify-content: flex-end;
}

.page_title{
  font-size: 1.25rem;
  display: flex;
  flex-direction: column;
  justify-content: end;
  margin-right: 15px;
}

.my_icon{
  width: 22px !important;
  height: 22px !important;
}

/* .submit_but .my_icon{
  width: 21px !important;
  height: 21px !important;
}
 */

.header_form {
    width:225px;
    margin:0px 20px 0px 0px !important;
}

.status_waiting{
  background-color:#ff9b0a !important;
  font-size:.55rem !important;
  color: white !important;
  padding:.15rem .26rem !important;
  padding-top: .215rem !important;
  cursor:none !important;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  align-self: center;
  box-shadow: none !important;
}

.status_inprogress{
  background-color:#1e73b8 !important;
  font-size:.55rem !important;
  color: white !important;
  padding:.15rem .26rem !important;
  padding-top: .215rem !important;
  cursor:none !important;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  align-self: center;
  box-shadow: none !important;
}

.status_success{
  background-color:#1eb873 !important;
  font-size:.55rem !important;
  color: white !important;
  padding:.15rem .26rem !important;
  padding-top: .215rem !important;
  cursor:none !important;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  align-self: center;
  box-shadow: none !important;
}

.status_deny{
  background-color:#f33640f3 !important;
  font-size:.55rem !important;
  color: white !important;
  padding:.15rem .26rem !important;
  padding-top: .215rem !important;
  cursor:none !important;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  align-self: center;
  box-shadow: none !important;
}

.MuiButton-label {
  width: 100%;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.MuiButton-root {
  min-width: 50px !important;
}


.MuiRating-root {
  font-size: calc(100% + .25rem) !important;
}

.documentsDownload label {
  display: inline-block;
  margin-bottom: 0 !important;
}

.disabled_button{
  pointer-events: none !important;
  opacity: 0.6 !important;
}

.dialogBottom .error {
  width: 100%;
  font-size: 1.1em;
  color: #dd3461 !important;
  background: #dc35451f;
  padding: 10px 20px;
  border-radius: 5px;
  margin: 0 !important;
  text-align: start;
}

.MuiTable-root {
  height: 100% !important;
}

.MuiTableContainer-root {
  height: 100% !important;
}

.MuiTableCell-root {
  padding: 10px !important;
}

.MuiTableRow-head{
  background-color:#f6f6f6;
  padding:0 !important;
}

.MuiTableCell-root {
  padding-right: 7px !important;
  padding-left: 7px !important;
}

.MuiTableCell-head {
  font-family: 'Nunito', sans-serif !important;
  font-family: var(--body-font) !important;
  font-weight: 700 !important;
}

.MuiTableCell-body{
  font-family: 'Nunito', sans-serif !important;
  font-family: var(--body-font) !important;
}


.MuiTypography-h6{
  font-family: 'Nunito', sans-serif !important;
  font-family: var(--body-font) !important;
}

.Mui-focused{
  background-color: white !important;
  border: none !important;
}


.apexcharts-text{
  font-family: 'Nunito', sans-serif !important;
  font-family: var(--body-font) !important;
  font-size:.8rem !important;
} 

.apexcharts-yaxis-title-text{
  font-family: 'Nunito', sans-serif !important;
  font-family: var(--body-font) !important;
  font-size:1rem !important;
}

/*
.MuiDialog-paperFullWidth {
  width: calc(100% - 64px) !important;
  height: calc(100% - 75px) !important;
  margin: 5px auto !important;
}*/

.round_button{
  border-radius: 50% !important;
}

.round_button:hover{
  opacity: .9;
}

.MuiFab-root{
  width: 36.5px !important;
  height: 36.5px !important;
  border-radius: 50% !important;
  background-color: rgb(231, 231, 231);
  min-height: 20px !important;
}


.closeSharpIcon{
  display:flex;
  background-color: rgb(253, 253, 253);
  border-radius: 100%;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  padding: 0.08rem !important;
  color: #ff0000 !important;
}

.closeSharpIcon:hover{
  background-color: #fcf5f5;
}

.checkIcon{
  display:flex;
  background-color: rgb(253, 253, 253);
  border-radius: 100%;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  padding: 0.08rem !important;
  color: #00b200 !important;
}

.checkIcon:hover{
  background-color: #e9fce9;
}

.editIcon{
  display:flex;
  background-color: rgb(253, 253, 253);
  border-radius: 100%;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  padding: 0.08rem !important;
  color: #3c7782 !important;
  width: 1.6rem !important;
  height: 1.6rem !important;
}

.editIcon:hover{
  background-color: #e9fce9;
}

.my_full_dialog .page_title{
  flex-direction: row;
  align-items: center;
}

.dialogBottom {
  display: flex;
  padding-top: 3px;
  justify-content: space-between;
  margin: 5px 10px !important;
  width: 100%;
}

.set_refuse_raison_container  .dialogBottom div:nth-child(1) {
  width: 100% !important;
  text-align: -webkit-right;
  padding-top: 3px;
}

.dialogBottom div:nth-child(1) {
  width: 100% !important;
  text-align: -webkit-right;
  padding-top: 3px;
  padding-right: 0 !important;
}

.dialogBottom div:nth-child(2) {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin-bottom: 0px;
  display: flex;
  justify-content: flex-end;
}

.dialogBottom .error{
  margin-right: 16px !important;
}


.pagination_container{
  margin: 25px auto 40px auto;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.pagination {
  height: -moz-fit-content;
  height: -webkit-fit-content;
  height: fit-content;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 0;
}

.pagination li a {
    position: relative;
    display: block;
    color: #0d6efd;
    text-decoration: none;
    font-size: .92rem;
    background-color: #fff;
    border: 1px solid #dee2e6;
    padding:0.5rem 1rem;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.pagination li{
  padding: 0;
  margin: 0;
}

.pagination li a:hover {
  background-color: rgb(236, 236, 236) !important;
}

.pagination .active {
  background-color: rgb(236, 236, 236) !important;
}

.pagination  .undefined{
  background-color: rgb(236, 236, 236) !important;
}

.MuiAppBar-root {
  box-shadow: none !important;
}

input::-webkit-outer-spin-button, input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}


input[type=number] {
  opacity: 1 !important;
}

.add_new_object_fab{
  border: solid 1px #3c7782;
  background-color:#f7f7f7 !important;
  padding: 0 !important;
  color: #3c7782 !important;
  cursor:pointer;
  transition: .5s ease-in;
  margin-left: 15px !important;
  width: 30px !important;
  height: 30px !important;
}

.add_new_object_fab_big{
  width: 36px !important;
  height: 36px !important;
}

.add_new_object_fab:hover{
  background-color: #e9fce9 !important;
  transition: .5s ease-in;
}

.success_object_fab{
  border: solid 1px white;
  background-color:#18ac67 !important;
  padding: 0 !important;
  color: white !important;
  cursor:pointer;
  transition: 1s ease-in;
  margin: 6px 6px 6px 5px !important;
}

.success_object_fab:hover{
  background:#18ac67ee !important;
    opacity: 1 !important;
}

.cancel_object_fab{
  border: solid 1px white;
  background-color:#e92a44 !important;
  padding: 0 !important;
  color: white !important;
  cursor:pointer;
  transition: 1s ease-in;
  margin: 6px 3px 6px 3px !important;
}

.cancel_object_fab:hover{
  background:#e92a43ee !important;
    opacity: 1 !important;
}

.add_new_object_icon{
  width: 1.1em !important;
  height: 1.1em !important;
}

/*!

 =========================================================
 * Now UI Kit - v1.3.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/now-ui-kit
 * Copyright 2019 Creative Tim (http://www.creative-tim.com)

 * Designed by www.invisionapp.com Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

 */

/*     brand Colors              */

/*      light colors         */

/* ========================================================================
 * bootstrap-switch - v3.3.2
 * http://www.bootstrap-switch.org
 * ========================================================================
 * Copyright 2012-2013 Mattia Larentis
 * http://www.apache.org/licenses/LICENSE-2.0
 */

.bootstrap-switch {
  display: inline-block;
  direction: ltr;
  cursor: pointer;
  border-radius: 30px;
  border: 0;
  position: relative;
  text-align: left;
  margin-bottom: 10px;
  line-height: 8px;
  width: 59px !important;
  height: 22px;
  outline: none;
  z-index: 0;
  -webkit-user-select: none;
  user-select: none;
  vertical-align: middle;
  transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
  margin-right: 20px;
  background: rgba(44, 44, 44, 0.2);
}

.bootstrap-switch .bootstrap-switch-container {
  display: inline-flex;
  top: 0;
  height: 22px;
  border-radius: 4px;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  width: 100px !important;
}

.bootstrap-switch .bootstrap-switch-handle-on,
.bootstrap-switch .bootstrap-switch-handle-off,
.bootstrap-switch .bootstrap-switch-label {
  box-sizing: border-box;
  cursor: pointer;
  display: inline-block !important;
  height: 100%;
  color: #fff;
  padding: 6px 12px;
  font-size: 11px;
  text-indent: -5px;
  line-height: 15px;
  transition: 0.25s ease-out;
}

.bootstrap-switch .bootstrap-switch-handle-on,
.bootstrap-switch .bootstrap-switch-handle-off {
  text-align: center;
  z-index: 1;
  float: left;
  line-height: 11px;
  width: 50% !important;
}

.bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-brown,
.bootstrap-switch .bootstrap-switch-handle-off.bootstrap-switch-brown {
  color: #fff;
  background: #f96332;
}

.bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-blue,
.bootstrap-switch .bootstrap-switch-handle-off.bootstrap-switch-blue {
  color: #fff;
  background: #2CA8FF;
}

.bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-green,
.bootstrap-switch .bootstrap-switch-handle-off.bootstrap-switch-green {
  color: #fff;
  background: #18ce0f;
}

.bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-orange,
.bootstrap-switch .bootstrap-switch-handle-off.bootstrap-switch-orange {
  background: #FFB236;
  color: #fff;
}

.bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-red,
.bootstrap-switch .bootstrap-switch-handle-off.bootstrap-switch-red {
  color: #fff;
  background: #FF3636;
}

.bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-default,
.bootstrap-switch .bootstrap-switch-handle-off.bootstrap-switch-default {
  color: #fff;
}

.bootstrap-switch .bootstrap-switch-label {
  text-align: center;
  z-index: 100;
  color: #333333;
  background: #ffffff;
  width: 22px !important;
  height: 22px !important;
  margin: 0px -11px;
  border-radius: 20px;
  position: absolute;
  float: left;
  top: 0;
  left: 50%;
  padding: 0;
  box-shadow: 0 1px 11px rgba(0, 0, 0, 0.25);
}

.bootstrap-switch.bootstrap-switch-off .bootstrap-switch-label {
  background-color: rgba(23, 23, 23, 0.4);
}

.bootstrap-switch.bootstrap-switch-on:hover .bootstrap-switch-label {
  width: 27px !important;
  margin-left: -16px;
}

.bootstrap-switch.bootstrap-switch-off:hover .bootstrap-switch-label {
  width: 27px !important;
  margin-left: -11px;
}

.bootstrap-switch .bootstrap-switch-handle-on {
  border-bottom-left-radius: 3px;
  border-top-left-radius: 3px;
}

.bootstrap-switch .bootstrap-switch-handle-off {
  text-indent: 6px;
}

.bootstrap-switch input[type='radio'],
.bootstrap-switch input[type='checkbox'] {
  position: absolute !important;
  top: 0;
  left: 0;
  opacity: 0;
  filter: alpha(opacity=0);
  z-index: -1;
}

.bootstrap-switch input[type='radio'].form-control,
.bootstrap-switch input[type='checkbox'].form-control {
  height: auto;
}

.bootstrap-switch.bootstrap-switch-mini .bootstrap-switch-handle-on,
.bootstrap-switch.bootstrap-switch-mini .bootstrap-switch-handle-off,
.bootstrap-switch.bootstrap-switch-mini .bootstrap-switch-label {
  padding: 1px 5px;
  font-size: 12px;
  line-height: 1.5;
}

.bootstrap-switch.bootstrap-switch-small .bootstrap-switch-handle-on,
.bootstrap-switch.bootstrap-switch-small .bootstrap-switch-handle-off,
.bootstrap-switch.bootstrap-switch-small .bootstrap-switch-label {
  padding: 5px 10px;
  font-size: 12px;
  line-height: 1.5;
}

.bootstrap-switch.bootstrap-switch-large .bootstrap-switch-handle-on,
.bootstrap-switch.bootstrap-switch-large .bootstrap-switch-handle-off,
.bootstrap-switch.bootstrap-switch-large .bootstrap-switch-label {
  padding: 6px 16px;
  font-size: 18px;
  line-height: 1.33;
}

.bootstrap-switch.bootstrap-switch-disabled,
.bootstrap-switch.bootstrap-switch-readonly,
.bootstrap-switch.bootstrap-switch-indeterminate {
  cursor: default !important;
}

.bootstrap-switch.bootstrap-switch-disabled .bootstrap-switch-handle-on,
.bootstrap-switch.bootstrap-switch-readonly .bootstrap-switch-handle-on,
.bootstrap-switch.bootstrap-switch-indeterminate .bootstrap-switch-handle-on,
.bootstrap-switch.bootstrap-switch-disabled .bootstrap-switch-handle-off,
.bootstrap-switch.bootstrap-switch-readonly .bootstrap-switch-handle-off,
.bootstrap-switch.bootstrap-switch-indeterminate .bootstrap-switch-handle-off,
.bootstrap-switch.bootstrap-switch-disabled .bootstrap-switch-label,
.bootstrap-switch.bootstrap-switch-readonly .bootstrap-switch-label,
.bootstrap-switch.bootstrap-switch-indeterminate .bootstrap-switch-label {
  opacity: 0.5;
  filter: alpha(opacity=50);
  cursor: default !important;
}

.bootstrap-switch.bootstrap-switch-animate .bootstrap-switch-container {
  transition: margin-left 0.5s;
}

.bootstrap-switch.bootstrap-switch-inverse .bootstrap-switch-handle-on {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  border-bottom-right-radius: 3px;
  border-top-right-radius: 3px;
}

.bootstrap-switch.bootstrap-switch-inverse .bootstrap-switch-handle-off {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  border-bottom-left-radius: 3px;
  border-top-left-radius: 3px;
}

.bootstrap-switch.bootstrap-switch-on .bootstrap-switch-container {
  margin-left: -2px !important;
}

.bootstrap-switch.bootstrap-switch-off .bootstrap-switch-container {
  margin-left: -39px !important;
}

.bootstrap-switch.bootstrap-switch-on .bootstrap-switch-label:before {
  background-color: #FFFFFF;
}

.bootstrap-switch.bootstrap-switch-on .bootstrap-switch-red~.bootstrap-switch-default {
  background-color: #FF3636;
}

.bootstrap-switch.bootstrap-switch-on .bootstrap-switch-orange~.bootstrap-switch-default {
  background-color: #FFB236;
}

.bootstrap-switch.bootstrap-switch-on .bootstrap-switch-green~.bootstrap-switch-default {
  background-color: #18ce0f;
}

.bootstrap-switch.bootstrap-switch-on .bootstrap-switch-brown~.bootstrap-switch-default {
  background-color: #f96332;
}

.bootstrap-switch.bootstrap-switch-on .bootstrap-switch-blue~.bootstrap-switch-default {
  background-color: #2CA8FF;
}

.bootstrap-switch.bootstrap-switch-off .bootstrap-switch-red,
.bootstrap-switch.bootstrap-switch-off .bootstrap-switch-brown,
.bootstrap-switch.bootstrap-switch-off .bootstrap-switch-blue,
.bootstrap-switch.bootstrap-switch-off .bootstrap-switch-orange,
.bootstrap-switch.bootstrap-switch-off .bootstrap-switch-green {
  background-color: #E3E3E3;
}

.bootstrap-switch-off .bootstrap-switch-handle-on {
  opacity: 0;
}

.bootstrap-switch-on .bootstrap-switch-handle-off {
  opacity: 0;
}

/*! nouislider - 13.1.4 */

/* Functional styling;
 * These styles are required for noUiSlider to function.
 * You don't need to change these rules to apply your design.
 */

.noUi-target,
.noUi-target * {
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-user-select: none;
  touch-action: none;
  user-select: none;
  box-sizing: border-box;
}

.noUi-target {
  position: relative;
  direction: ltr;
}

.noUi-base,
.noUi-connects {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 1;
}

/* Wrapper for all connect elements.
 */

.noUi-connects {
  overflow: hidden;
  z-index: 0;
}

.noUi-connect,
.noUi-origin {
  will-change: transform;
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
}

/* Offset direction
 */

html:not([dir="rtl"]) .noUi-horizontal .noUi-origin {
  left: auto;
  right: 0;
}

/* Give origins 0 height/width so they don't interfere with clicking the
 * connect elements.
 */

.noUi-vertical .noUi-origin {
  width: 0;
}

.noUi-horizontal .noUi-origin {
  height: 0;
}

.noUi-handle {
  position: absolute;
}

.noUi-state-tap .noUi-connect,
.noUi-state-tap .noUi-origin {
  transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  transition: transform 0.3s, -webkit-transform 0.3s;
}

.noUi-state-drag * {
  cursor: inherit !important;
}

/* Slider size and handle placement;
 */

.noUi-horizontal {
  height: 1px;
}

.noUi-horizontal .noUi-handle {
  border-radius: 50%;
  background-color: #FFFFFF;
  box-shadow: 0 1px 13px 0 rgba(0, 0, 0, 0.2);
  height: 15px;
  width: 15px;
  cursor: pointer;
  margin-top: -7px;
  outline: none;
}

.noUi-vertical {
  width: 18px;
}

.noUi-vertical .noUi-handle {
  width: 28px;
  height: 34px;
  left: -6px;
  top: -17px;
}

html:not([dir="rtl"]) .noUi-horizontal .noUi-handle {
  right: -15px;
  left: auto;
}

/* Styling;
 * Giving the connect element a border radius causes issues with using transform: scale
 */

.noUi-target {
  background-color: rgba(182, 182, 182, 0.3);
  border-radius: 3px;
}

.noUi-connects {
  border-radius: 3px;
}

.noUi-connect {
  background: #888888;
  border-radius: 3px;
  transition: background 450ms;
}

/* Handles and cursors;
 */

.noUi-draggable {
  cursor: ew-resize;
}

.noUi-vertical .noUi-draggable {
  cursor: ns-resize;
}

.noUi-handle {
  border-radius: 3px;
  background: #FFF;
  cursor: default;
  box-shadow: inset 0 0 1px #FFF, inset 0 1px 7px #EBEBEB, 0 3px 6px -3px #BBB;
  -webkit-transition: 300ms ease 0s;
  -moz-transition: 300ms ease 0s;
  -ms-transition: 300ms ease 0s;
  -o-transform: 300ms ease 0s;
  transition: 300ms ease 0s;
}

.noUi-active {
  -webkit-transform: scale3d(1.5, 1.5, 1);
  transform: scale3d(1.5, 1.5, 1);
}

/* Handle stripes;
 */

.noUi-handle:before,
.noUi-handle:after {
  display: none;
}

.noUi-handle:after {
  left: 17px;
}

.noUi-vertical .noUi-handle:before,
.noUi-vertical .noUi-handle:after {
  width: 14px;
  height: 1px;
  left: 6px;
  top: 14px;
}

.noUi-vertical .noUi-handle:after {
  top: 17px;
}

/* Disabled state;
 */

[disabled] .noUi-connect {
  background: #B8B8B8;
}

[disabled].noUi-target,
[disabled].noUi-handle,
[disabled] .noUi-handle {
  cursor: not-allowed;
}

/* Base;
 *
 */

.noUi-pips,
.noUi-pips * {
  box-sizing: border-box;
}

.noUi-pips {
  position: absolute;
  color: #999;
}

/* Values;
 *
 */

.noUi-value {
  position: absolute;
  white-space: nowrap;
  text-align: center;
}

.noUi-value-sub {
  color: #ccc;
  font-size: 10px;
}

/* Markings;
 *
 */

.noUi-marker {
  position: absolute;
  background: #CCC;
}

.noUi-marker-sub {
  background: #AAA;
}

.noUi-marker-large {
  background: #AAA;
}

/* Horizontal layout;
 *
 */

.noUi-pips-horizontal {
  padding: 10px 0;
  height: 80px;
  top: 100%;
  left: 0;
  width: 100%;
}

.noUi-value-horizontal {
  -webkit-transform: translate(-50%, 50%);
  transform: translate(-50%, 50%);
}

.noUi-rtl .noUi-value-horizontal {
  -webkit-transform: translate(50%, 50%);
  transform: translate(50%, 50%);
}

.noUi-marker-horizontal.noUi-marker {
  margin-left: -1px;
  width: 2px;
  height: 5px;
}

.noUi-marker-horizontal.noUi-marker-sub {
  height: 10px;
}

.noUi-marker-horizontal.noUi-marker-large {
  height: 15px;
}

/* Vertical layout;
 *
 */

.noUi-pips-vertical {
  padding: 0 10px;
  height: 100%;
  top: 0;
  left: 100%;
}

.noUi-value-vertical {
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%, 0);
  padding-left: 25px;
}

.noUi-rtl .noUi-value-vertical {
  -webkit-transform: translate(0, 50%);
  transform: translate(0, 50%);
}

.noUi-marker-vertical.noUi-marker {
  width: 5px;
  height: 2px;
  margin-top: -1px;
}

.noUi-marker-vertical.noUi-marker-sub {
  width: 10px;
}

.noUi-marker-vertical.noUi-marker-large {
  width: 15px;
}

.noUi-tooltip {
  display: block;
  position: absolute;
  border: 1px solid #D9D9D9;
  border-radius: 3px;
  background: #fff;
  color: #000;
  padding: 5px;
  text-align: center;
  white-space: nowrap;
}

.noUi-horizontal .noUi-tooltip {
  -webkit-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
  left: 50%;
  bottom: 120%;
}

.noUi-vertical .noUi-tooltip {
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
  top: 50%;
  right: 120%;
}

.slider.slider-neutral .noUi-connect,
.slider.slider-neutral.noUi-connect {
  background-color: #FFFFFF;
}

.slider.slider-neutral.noUi-target {
  background-color: rgba(255, 255, 255, 0.3);
}

.slider.slider-neutral .noUi-handle {
  background-color: #FFFFFF;
}

.slider.slider-primary .noUi-connect,
.slider.slider-primary.noUi-connect {
  background-color: #f96332;
}

.slider.slider-primary.noUi-target {
  background-color: rgba(249, 99, 50, 0.3);
}

.slider.slider-primary .noUi-handle {
  background-color: #f96332;
}

.slider.slider-info .noUi-connect,
.slider.slider-info.noUi-connect {
  background-color: #2CA8FF;
}

.slider.slider-info.noUi-target {
  background-color: rgba(44, 168, 255, 0.3);
}

.slider.slider-info .noUi-handle {
  background-color: #2CA8FF;
}

.slider.slider-success .noUi-connect,
.slider.slider-success.noUi-connect {
  background-color: #18ce0f;
}

.slider.slider-success.noUi-target {
  background-color: rgba(24, 206, 15, 0.3);
}

.slider.slider-success .noUi-handle {
  background-color: #18ce0f;
}

.slider.slider-warning .noUi-connect,
.slider.slider-warning.noUi-connect {
  background-color: #FFB236;
}

.slider.slider-warning.noUi-target {
  background-color: rgba(255, 178, 54, 0.3);
}

.slider.slider-warning .noUi-handle {
  background-color: #FFB236;
}

.slider.slider-danger .noUi-connect,
.slider.slider-danger.noUi-connect {
  background-color: #FF3636;
}

.slider.slider-danger.noUi-target {
  background-color: rgba(255, 54, 54, 0.3);
}

.slider.slider-danger .noUi-handle {
  background-color: #FF3636;
}

/*!
 * Datepicker for Bootstrap v1.7.0-dev (https://github.com/uxsolutions/bootstrap-datepicker)
 *
 * Licensed under the Apache License v2.0 (http://www.apache.org/licenses/LICENSE-2.0)
 */

.datepicker {
  padding: 8px 6px;
  border-radius: 4px;
  direction: ltr;
  -webkit-transform: translate3d(0, -40px, 0);
  transform: translate3d(0, -40px, 0);
  transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1) 0s, opacity 0.3s ease 0s, height 0s linear 0.35s;
  opacity: 0;
  filter: alpha(opacity=0);
  visibility: hidden;
  display: block;
  width: 254px;
  max-width: 254px;
}

.datepicker.dropdown-menu:before {
  display: none;
}

.datepicker.datepicker-primary {
  background-color: #f96332;
}

.datepicker.datepicker-primary th,
.datepicker.datepicker-primary .day div,
.datepicker.datepicker-primary table tr td span {
  color: #FFFFFF;
}

.datepicker.datepicker-primary:after {
  border-bottom-color: #f96332;
}

.datepicker.datepicker-primary.datepicker-orient-top:after {
  border-top-color: #f96332;
}

.datepicker.datepicker-primary .dow {
  color: rgba(255, 255, 255, 0.8);
}

.datepicker.datepicker-primary table tr td.old div,
.datepicker.datepicker-primary table tr td.new div,
.datepicker.datepicker-primary table tr td span.old,
.datepicker.datepicker-primary table tr td span.new {
  color: rgba(255, 255, 255, 0.4);
}

.datepicker.datepicker-primary table tr td span:hover,
.datepicker.datepicker-primary table tr td span.focused {
  background: rgba(255, 255, 255, 0.1);
}

.datepicker.datepicker-primary .datepicker-switch:hover,
.datepicker.datepicker-primary .prev:hover,
.datepicker.datepicker-primary .next:hover,
.datepicker.datepicker-primary tfoot tr th:hover {
  background: rgba(255, 255, 255, 0.2);
}

.datepicker.datepicker-primary table tr td.active div,
.datepicker.datepicker-primary table tr td.active:hover div,
.datepicker.datepicker-primary table tr td.active.disabled div,
.datepicker.datepicker-primary table tr td.active.disabled:hover div {
  background-color: #FFFFFF;
  color: #f96332;
}

.datepicker.datepicker-primary table tr td.day:hover div,
.datepicker.datepicker-primary table tr td.day.focused div {
  background: rgba(255, 255, 255, 0.2);
}

.datepicker.datepicker-primary table tr td.active:hover div,
.datepicker.datepicker-primary table tr td.active:hover:hover div,
.datepicker.datepicker-primary table tr td.active.disabled:hover div,
.datepicker.datepicker-primary table tr td.active.disabled:hover:hover div,
.datepicker.datepicker-primary table tr td.active:active div,
.datepicker.datepicker-primary table tr td.active:hover:active div,
.datepicker.datepicker-primary table tr td.active.disabled:active div,
.datepicker.datepicker-primary table tr td.active.disabled:hover:active div,
.datepicker.datepicker-primary table tr td.active.active div,
.datepicker.datepicker-primary table tr td.active:hover.active div,
.datepicker.datepicker-primary table tr td.active.disabled.active div,
.datepicker.datepicker-primary table tr td.active.disabled:hover.active div,
.datepicker.datepicker-primary table tr td.active.disabled div,
.datepicker.datepicker-primary table tr td.active:hover.disabled div,
.datepicker.datepicker-primary table tr td.active.disabled.disabled div,
.datepicker.datepicker-primary table tr td.active.disabled:hover.disabled div,
.datepicker.datepicker-primary table tr td.active[disabled] div,
.datepicker.datepicker-primary table tr td.active:hover[disabled] div,
.datepicker.datepicker-primary table tr td.active.disabled[disabled] div,
.datepicker.datepicker-primary table tr td.active.disabled:hover[disabled] div,
.datepicker.datepicker-primary table tr td span.active:hover,
.datepicker.datepicker-primary table tr td span.active:hover:hover,
.datepicker.datepicker-primary table tr td span.active.disabled:hover,
.datepicker.datepicker-primary table tr td span.active.disabled:hover:hover,
.datepicker.datepicker-primary table tr td span.active:active,
.datepicker.datepicker-primary table tr td span.active:hover:active,
.datepicker.datepicker-primary table tr td span.active.disabled:active,
.datepicker.datepicker-primary table tr td span.active.disabled:hover:active,
.datepicker.datepicker-primary table tr td span.active.active,
.datepicker.datepicker-primary table tr td span.active:hover.active,
.datepicker.datepicker-primary table tr td span.active.disabled.active,
.datepicker.datepicker-primary table tr td span.active.disabled:hover.active,
.datepicker.datepicker-primary table tr td span.active.disabled,
.datepicker.datepicker-primary table tr td span.active:hover.disabled,
.datepicker.datepicker-primary table tr td span.active.disabled.disabled,
.datepicker.datepicker-primary table tr td span.active.disabled:hover.disabled,
.datepicker.datepicker-primary table tr td span.active[disabled],
.datepicker.datepicker-primary table tr td span.active:hover[disabled],
.datepicker.datepicker-primary table tr td span.active.disabled[disabled],
.datepicker.datepicker-primary table tr td span.active.disabled:hover[disabled] {
  background-color: #FFFFFF;
}

.datepicker.datepicker-primary table tr td span.active:hover,
.datepicker.datepicker-primary table tr td span.active:hover:hover,
.datepicker.datepicker-primary table tr td span.active.disabled:hover,
.datepicker.datepicker-primary table tr td span.active.disabled:hover:hover,
.datepicker.datepicker-primary table tr td span.active:active,
.datepicker.datepicker-primary table tr td span.active:hover:active,
.datepicker.datepicker-primary table tr td span.active.disabled:active,
.datepicker.datepicker-primary table tr td span.active.disabled:hover:active,
.datepicker.datepicker-primary table tr td span.active.active,
.datepicker.datepicker-primary table tr td span.active:hover.active,
.datepicker.datepicker-primary table tr td span.active.disabled.active,
.datepicker.datepicker-primary table tr td span.active.disabled:hover.active,
.datepicker.datepicker-primary table tr td span.active.disabled,
.datepicker.datepicker-primary table tr td span.active:hover.disabled,
.datepicker.datepicker-primary table tr td span.active.disabled.disabled,
.datepicker.datepicker-primary table tr td span.active.disabled:hover.disabled,
.datepicker.datepicker-primary table tr td span.active[disabled],
.datepicker.datepicker-primary table tr td span.active:hover[disabled],
.datepicker.datepicker-primary table tr td span.active.disabled[disabled],
.datepicker.datepicker-primary table tr td span.active.disabled:hover[disabled] {
  color: #f96332;
}

.datepicker-inline {
  width: 220px;
}

.datepicker.datepicker-rtl {
  direction: rtl;
}

.datepicker.datepicker-rtl.dropdown-menu {
  left: auto;
}

.datepicker.datepicker-rtl table tr td span {
  float: right;
}

.datepicker-dropdown {
  top: 0;
  left: 0;
}

.datepicker-dropdown:before {
  content: '';
  display: inline-block;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 7px solid transparent;
  border-top: 0;
  border-bottom-color: rgba(0, 0, 0, 0.2);
  position: absolute;
}

.datepicker-dropdown:after {
  content: '';
  display: inline-block;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 6px solid #fff;
  border-top: 0;
  position: absolute;
}

.datepicker-dropdown.datepicker-orient-left:before {
  left: 6px;
}

.datepicker-dropdown.datepicker-orient-left:after {
  left: 7px;
}

.datepicker-dropdown.datepicker-orient-right:before {
  right: 6px;
}

.datepicker-dropdown.datepicker-orient-right:after {
  right: 7px;
}

.datepicker-dropdown.datepicker-orient-bottom:before {
  top: -7px;
}

.datepicker-dropdown.datepicker-orient-bottom:after {
  top: -6px;
}

.datepicker-dropdown.datepicker-orient-top:before {
  bottom: -7px;
  border-bottom: 0;
  border-top: 7px solid transparent;
}

.datepicker-dropdown.datepicker-orient-top:after {
  bottom: -6px;
  border-bottom: 0;
  border-top: 6px solid #fff;
}

.datepicker table {
  margin: 0;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
  width: 241px;
  max-width: 241px;
}

.datepicker .day div,
.datepicker th {
  transition: all 300ms ease 0s;
  text-align: center;
  width: 30px;
  height: 30px;
  line-height: 2.2;
  border-radius: 50%;
  font-weight: 300;
  font-size: 14px;
  border: none;
  z-index: 1;
  position: relative;
  cursor: pointer;
}

.datepicker th {
  color: #f96332;
}

.table-condensed>tbody>tr>td,
.table-condensed>tbody>tr>th,
.table-condensed>tfoot>tr>td,
.table-condensed>tfoot>tr>th,
.table-condensed>thead>tr>td,
.table-condensed>thead>tr>th {
  padding: 2px;
  text-align: center;
  cursor: pointer;
}

.table-striped .datepicker table tr td,
.table-striped .datepicker table tr th {
  background-color: transparent;
}

.datepicker table tr td.day:hover div,
.datepicker table tr td.day.focused div {
  background: #eee;
  cursor: pointer;
}

.datepicker table tr td.old,
.datepicker table tr td.new {
  color: #888888;
}

.datepicker table tr td.disabled,
.datepicker table tr td.disabled:hover {
  background: none;
  color: #888888;
  cursor: default;
}

.datepicker table tr td.highlighted {
  background: #d9edf7;
  border-radius: 0;
}

.datepicker table tr td.today,
.datepicker table tr td.today:hover,
.datepicker table tr td.today.disabled,
.datepicker table tr td.today.disabled:hover {
  background-color: #fde19a;
  background-image: linear-gradient(to bottom, #fdd49a, #fdf59a);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fdd49a', endColorstr='#fdf59a', GradientType=0);
  border-color: #fdf59a #fdf59a #fbed50;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
  filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
  color: #000;
}

.datepicker table tr td.today:hover,
.datepicker table tr td.today:hover:hover,
.datepicker table tr td.today.disabled:hover,
.datepicker table tr td.today.disabled:hover:hover,
.datepicker table tr td.today:active,
.datepicker table tr td.today:hover:active,
.datepicker table tr td.today.disabled:active,
.datepicker table tr td.today.disabled:hover:active,
.datepicker table tr td.today.active,
.datepicker table tr td.today:hover.active,
.datepicker table tr td.today.disabled.active,
.datepicker table tr td.today.disabled:hover.active,
.datepicker table tr td.today.disabled,
.datepicker table tr td.today:hover.disabled,
.datepicker table tr td.today.disabled.disabled,
.datepicker table tr td.today.disabled:hover.disabled,
.datepicker table tr td.today[disabled],
.datepicker table tr td.today:hover[disabled],
.datepicker table tr td.today.disabled[disabled],
.datepicker table tr td.today.disabled:hover[disabled] {
  background-color: #fdf59a;
}

.datepicker table tr td.today:active,
.datepicker table tr td.today:hover:active,
.datepicker table tr td.today.disabled:active,
.datepicker table tr td.today.disabled:hover:active,
.datepicker table tr td.today.active,
.datepicker table tr td.today:hover.active,
.datepicker table tr td.today.disabled.active,
.datepicker table tr td.today.disabled:hover.active {
  background-color: #fbf069 \9;
}

.datepicker table tr td.today:hover:hover {
  color: #000;
}

.datepicker table tr td.today.active:hover {
  color: #fff;
}

.datepicker table tr td.range,
.datepicker table tr td.range:hover,
.datepicker table tr td.range.disabled,
.datepicker table tr td.range.disabled:hover {
  background: #eee;
  border-radius: 0;
}

.datepicker table tr td.range.today,
.datepicker table tr td.range.today:hover,
.datepicker table tr td.range.today.disabled,
.datepicker table tr td.range.today.disabled:hover {
  background-color: #f3d17a;
  background-image: linear-gradient(to bottom, #f3c17a, #f3e97a);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f3c17a', endColorstr='#f3e97a', GradientType=0);
  border-color: #f3e97a #f3e97a #edde34;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
  filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
  border-radius: 0;
}

.datepicker table tr td.range.today:hover,
.datepicker table tr td.range.today:hover:hover,
.datepicker table tr td.range.today.disabled:hover,
.datepicker table tr td.range.today.disabled:hover:hover,
.datepicker table tr td.range.today:active,
.datepicker table tr td.range.today:hover:active,
.datepicker table tr td.range.today.disabled:active,
.datepicker table tr td.range.today.disabled:hover:active,
.datepicker table tr td.range.today.active,
.datepicker table tr td.range.today:hover.active,
.datepicker table tr td.range.today.disabled.active,
.datepicker table tr td.range.today.disabled:hover.active,
.datepicker table tr td.range.today.disabled,
.datepicker table tr td.range.today:hover.disabled,
.datepicker table tr td.range.today.disabled.disabled,
.datepicker table tr td.range.today.disabled:hover.disabled,
.datepicker table tr td.range.today[disabled],
.datepicker table tr td.range.today:hover[disabled],
.datepicker table tr td.range.today.disabled[disabled],
.datepicker table tr td.range.today.disabled:hover[disabled] {
  background-color: #f3e97a;
}

.datepicker table tr td.range.today:active,
.datepicker table tr td.range.today:hover:active,
.datepicker table tr td.range.today.disabled:active,
.datepicker table tr td.range.today.disabled:hover:active,
.datepicker table tr td.range.today.active,
.datepicker table tr td.range.today:hover.active,
.datepicker table tr td.range.today.disabled.active,
.datepicker table tr td.range.today.disabled:hover.active {
  background-color: #efe24b \9;
}

.datepicker table tr td.selected,
.datepicker table tr td.selected:hover,
.datepicker table tr td.selected.disabled,
.datepicker table tr td.selected.disabled:hover {
  background-color: #9e9e9e;
  background-image: linear-gradient(to bottom, #b3b3b3, #808080);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#b3b3b3', endColorstr='#808080', GradientType=0);
  border-color: #808080 #808080 #595959;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
  filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
  color: #fff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
}

.datepicker table tr td.selected:hover,
.datepicker table tr td.selected:hover:hover,
.datepicker table tr td.selected.disabled:hover,
.datepicker table tr td.selected.disabled:hover:hover,
.datepicker table tr td.selected:active,
.datepicker table tr td.selected:hover:active,
.datepicker table tr td.selected.disabled:active,
.datepicker table tr td.selected.disabled:hover:active,
.datepicker table tr td.selected.active,
.datepicker table tr td.selected:hover.active,
.datepicker table tr td.selected.disabled.active,
.datepicker table tr td.selected.disabled:hover.active,
.datepicker table tr td.selected.disabled,
.datepicker table tr td.selected:hover.disabled,
.datepicker table tr td.selected.disabled.disabled,
.datepicker table tr td.selected.disabled:hover.disabled,
.datepicker table tr td.selected[disabled],
.datepicker table tr td.selected:hover[disabled],
.datepicker table tr td.selected.disabled[disabled],
.datepicker table tr td.selected.disabled:hover[disabled] {
  background-color: #808080;
}

.datepicker table tr td.selected:active,
.datepicker table tr td.selected:hover:active,
.datepicker table tr td.selected.disabled:active,
.datepicker table tr td.selected.disabled:hover:active,
.datepicker table tr td.selected.active,
.datepicker table tr td.selected:hover.active,
.datepicker table tr td.selected.disabled.active,
.datepicker table tr td.selected.disabled:hover.active {
  background-color: #666666 \9;
}

.datepicker table tr td.active div,
.datepicker table tr td.active:hover div,
.datepicker table tr td.active.disabled div,
.datepicker table tr td.active.disabled:hover div {
  background-color: #f96332;
  color: #FFFFFF;
  box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.2);
}

.datepicker table tr td.active:hover div,
.datepicker table tr td.active:hover:hover div,
.datepicker table tr td.active.disabled:hover div,
.datepicker table tr td.active.disabled:hover:hover div,
.datepicker table tr td.active:active div,
.datepicker table tr td.active:hover:active div,
.datepicker table tr td.active.disabled:active div,
.datepicker table tr td.active.disabled:hover:active div,
.datepicker table tr td.active.active div,
.datepicker table tr td.active:hover.active div,
.datepicker table tr td.active.disabled.active div,
.datepicker table tr td.active.disabled:hover.active div,
.datepicker table tr td.active.disabled div,
.datepicker table tr td.active:hover.disabled div,
.datepicker table tr td.active.disabled.disabled div,
.datepicker table tr td.active.disabled:hover.disabled div,
.datepicker table tr td.active[disabled] div,
.datepicker table tr td.active:hover[disabled] div,
.datepicker table tr td.active.disabled[disabled] div,
.datepicker table tr td.active.disabled:hover[disabled] div {
  background-color: #f96332;
}

.datepicker table tr td.active:active,
.datepicker table tr td.active:hover:active,
.datepicker table tr td.active.disabled:active,
.datepicker table tr td.active.disabled:hover:active,
.datepicker table tr td.active.active,
.datepicker table tr td.active:hover.active,
.datepicker table tr td.active.disabled.active,
.datepicker table tr td.active.disabled:hover.active {
  background-color: #003399 \9;
}

.datepicker table tr td span {
  display: block;
  width: 41px;
  height: 41px;
  line-height: 41px;
  float: left;
  margin: 1%;
  font-size: 14px;
  cursor: pointer;
  border-radius: 50%;
}

.datepicker table tr td span:hover,
.datepicker table tr td span.focused {
  background: #eee;
}

.datepicker table tr td span.disabled,
.datepicker table tr td span.disabled:hover {
  background: none;
  color: #888888;
  cursor: default;
}

.datepicker table tr td span.active,
.datepicker table tr td span.active:hover,
.datepicker table tr td span.active.disabled,
.datepicker table tr td span.active.disabled:hover {
  color: #fff;
  background-color: #f96332;
}

.datepicker table tr td span.active:hover,
.datepicker table tr td span.active:hover:hover,
.datepicker table tr td span.active.disabled:hover,
.datepicker table tr td span.active.disabled:hover:hover,
.datepicker table tr td span.active:active,
.datepicker table tr td span.active:hover:active,
.datepicker table tr td span.active.disabled:active,
.datepicker table tr td span.active.disabled:hover:active,
.datepicker table tr td span.active.active,
.datepicker table tr td span.active:hover.active,
.datepicker table tr td span.active.disabled.active,
.datepicker table tr td span.active.disabled:hover.active,
.datepicker table tr td span.active.disabled,
.datepicker table tr td span.active:hover.disabled,
.datepicker table tr td span.active.disabled.disabled,
.datepicker table tr td span.active.disabled:hover.disabled,
.datepicker table tr td span.active[disabled],
.datepicker table tr td span.active:hover[disabled],
.datepicker table tr td span.active.disabled[disabled],
.datepicker table tr td span.active.disabled:hover[disabled] {
  background-color: #f96332;
  box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.2);
}

.datepicker table tr td span.active:active,
.datepicker table tr td span.active:hover:active,
.datepicker table tr td span.active.disabled:active,
.datepicker table tr td span.active.disabled:hover:active,
.datepicker table tr td span.active.active,
.datepicker table tr td span.active:hover.active,
.datepicker table tr td span.active.disabled.active,
.datepicker table tr td span.active.disabled:hover.active {
  background-color: #003399 \9;
}

.datepicker table tr td span.old,
.datepicker table tr td span.new {
  color: #888888;
}

.datepicker .datepicker-switch {
  width: auto;
  border-radius: 0.1875rem;
}

.datepicker .datepicker-switch,
.datepicker .prev,
.datepicker .next,
.datepicker tfoot tr th {
  cursor: pointer;
}

.datepicker .prev,
.datepicker .next {
  width: 35px;
  height: 35px;
}

.datepicker i {
  position: relative;
  top: 2px;
}

.datepicker .prev i {
  left: -1px;
}

.datepicker .next i {
  right: -1px;
}

.datepicker .datepicker-switch:hover,
.datepicker .prev:hover,
.datepicker .next:hover,
.datepicker tfoot tr th:hover {
  background: #eee;
}

.datepicker .prev.disabled,
.datepicker .next.disabled {
  visibility: hidden;
}

.datepicker .cw {
  font-size: 10px;
  width: 12px;
  padding: 0 2px 0 5px;
  vertical-align: middle;
}

.input-append.date .add-on,
.input-prepend.date .add-on {
  cursor: pointer;
}

.input-append.date .add-on i,
.input-prepend.date .add-on i {
  margin-top: 3px;
}

.input-daterange input {
  text-align: center;
}

.input-daterange input:first-child {
  border-radius: 3px 0 0 3px;
}

.input-daterange input:last-child {
  border-radius: 0 3px 3px 0;
}

.input-daterange .add-on {
  display: inline-block;
  width: auto;
  min-width: 16px;
  height: 18px;
  padding: 4px 5px;
  font-weight: normal;
  line-height: 18px;
  text-align: center;
  text-shadow: 0 1px 0 #fff;
  vertical-align: middle;
  background-color: #eee;
  border: 1px solid #ccc;
  margin-left: -5px;
  margin-right: -5px;
}

.table-condensed>tbody>tr>td,
.table-condensed>tbody>tr>th,
.table-condensed>tfoot>tr>td,
.table-condensed>tfoot>tr>th,
.table-condensed>thead>tr>td,
.table-condensed>thead>tr>th {
  position: relative;
  z-index: -1;
}

.btn,
.navbar .navbar-nav>a.btn {
  border-width: 2px;
  font-weight: 400;
  font-size: 0.8571em;
  line-height: 1.35em;
  margin: 10px 1px;
  border: none;
  border-radius: 0.1875rem;
  padding: 11px 22px;
  cursor: pointer;
  background-color: #888888;
  color: #FFFFFF;
}

.btn:hover,
.btn:focus,
.btn:not(:disabled):not(.disabled):active,
.btn:not(:disabled):not(.disabled).active,
.btn:not(:disabled):not(.disabled):active:focus,
.btn:not(:disabled):not(.disabled).active:focus,
.btn:active:hover,
.btn.active:hover,
.show>.btn.dropdown-toggle,
.show>.btn.dropdown-toggle:focus,
.show>.btn.dropdown-toggle:hover,
.navbar .navbar-nav>a.btn:hover,
.navbar .navbar-nav>a.btn:focus,
.navbar .navbar-nav>a.btn:not(:disabled):not(.disabled):active,
.navbar .navbar-nav>a.btn:not(:disabled):not(.disabled).active,
.navbar .navbar-nav>a.btn:not(:disabled):not(.disabled):active:focus,
.navbar .navbar-nav>a.btn:not(:disabled):not(.disabled).active:focus,
.navbar .navbar-nav>a.btn:active:hover,
.navbar .navbar-nav>a.btn.active:hover,
.show>.navbar .navbar-nav>a.btn.dropdown-toggle,
.show>.navbar .navbar-nav>a.btn.dropdown-toggle:focus,
.show>.navbar .navbar-nav>a.btn.dropdown-toggle:hover {
  background-color: #979797;
  color: #FFFFFF;
  box-shadow: none;
}

.btn:hover,
.navbar .navbar-nav>a.btn:hover {
  box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.17);
}

.btn.disabled,
.btn.disabled:hover,
.btn.disabled:focus,
.btn.disabled.focus,
.btn.disabled:active,
.btn.disabled.active,
.btn:disabled,
.btn:disabled:hover,
.btn:disabled:focus,
.btn:disabled.focus,
.btn:disabled:active,
.btn:disabled.active,
.btn[disabled],
.btn[disabled]:hover,
.btn[disabled]:focus,
.btn[disabled].focus,
.btn[disabled]:active,
.btn[disabled].active,
fieldset[disabled] .btn,
fieldset[disabled] .btn:hover,
fieldset[disabled] .btn:focus,
fieldset[disabled] .btn.focus,
fieldset[disabled] .btn:active,
fieldset[disabled] .btn.active,
.navbar .navbar-nav>a.btn.disabled,
.navbar .navbar-nav>a.btn.disabled:hover,
.navbar .navbar-nav>a.btn.disabled:focus,
.navbar .navbar-nav>a.btn.disabled.focus,
.navbar .navbar-nav>a.btn.disabled:active,
.navbar .navbar-nav>a.btn.disabled.active,
.navbar .navbar-nav>a.btn:disabled,
.navbar .navbar-nav>a.btn:disabled:hover,
.navbar .navbar-nav>a.btn:disabled:focus,
.navbar .navbar-nav>a.btn:disabled.focus,
.navbar .navbar-nav>a.btn:disabled:active,
.navbar .navbar-nav>a.btn:disabled.active,
.navbar .navbar-nav>a.btn[disabled],
.navbar .navbar-nav>a.btn[disabled]:hover,
.navbar .navbar-nav>a.btn[disabled]:focus,
.navbar .navbar-nav>a.btn[disabled].focus,
.navbar .navbar-nav>a.btn[disabled]:active,
.navbar .navbar-nav>a.btn[disabled].active,
fieldset[disabled] .navbar .navbar-nav>a.btn,
fieldset[disabled] .navbar .navbar-nav>a.btn:hover,
fieldset[disabled] .navbar .navbar-nav>a.btn:focus,
fieldset[disabled] .navbar .navbar-nav>a.btn.focus,
fieldset[disabled] .navbar .navbar-nav>a.btn:active,
fieldset[disabled] .navbar .navbar-nav>a.btn.active {
  background-color: #888888;
  border-color: #888888;
}

.btn.btn-simple,
.navbar .navbar-nav>a.btn.btn-simple {
  color: #888888;
  border-color: #888888;
}

.btn.btn-simple:hover,
.btn.btn-simple:focus,
.btn.btn-simple:not(:disabled):not(.disabled):active,
.btn.btn-simple:not(:disabled):not(.disabled).active,
.btn.btn-simple:not(:disabled):not(.disabled):active:focus,
.btn.btn-simple:not(:disabled):not(.disabled).active:focus,
.btn.btn-simple:active:hover,
.btn.btn-simple.active:hover,
.show>.btn.btn-simple.dropdown-toggle,
.show>.btn.btn-simple.dropdown-toggle:focus,
.show>.btn.btn-simple.dropdown-toggle:hover,
.navbar .navbar-nav>a.btn.btn-simple:hover,
.navbar .navbar-nav>a.btn.btn-simple:focus,
.navbar .navbar-nav>a.btn.btn-simple:not(:disabled):not(.disabled):active,
.navbar .navbar-nav>a.btn.btn-simple:not(:disabled):not(.disabled).active,
.navbar .navbar-nav>a.btn.btn-simple:not(:disabled):not(.disabled):active:focus,
.navbar .navbar-nav>a.btn.btn-simple:not(:disabled):not(.disabled).active:focus,
.navbar .navbar-nav>a.btn.btn-simple:active:hover,
.navbar .navbar-nav>a.btn.btn-simple.active:hover,
.show>.navbar .navbar-nav>a.btn.btn-simple.dropdown-toggle,
.show>.navbar .navbar-nav>a.btn.btn-simple.dropdown-toggle:focus,
.show>.navbar .navbar-nav>a.btn.btn-simple.dropdown-toggle:hover {
  background-color: transparent;
  color: #979797;
  border-color: #979797;
  box-shadow: none;
}

.btn.btn-link,
.navbar .navbar-nav>a.btn.btn-link {
  color: #888888;
}

.btn.btn-link:hover,
.btn.btn-link:focus,
.btn.btn-link:not(:disabled):not(.disabled):active,
.btn.btn-link:not(:disabled):not(.disabled).active,
.btn.btn-link:not(:disabled):not(.disabled):active:focus,
.btn.btn-link:not(:disabled):not(.disabled).active:focus,
.btn.btn-link:active:hover,
.btn.btn-link.active:hover,
.show>.btn.btn-link.dropdown-toggle,
.show>.btn.btn-link.dropdown-toggle:focus,
.show>.btn.btn-link.dropdown-toggle:hover,
.navbar .navbar-nav>a.btn.btn-link:hover,
.navbar .navbar-nav>a.btn.btn-link:focus,
.navbar .navbar-nav>a.btn.btn-link:not(:disabled):not(.disabled):active,
.navbar .navbar-nav>a.btn.btn-link:not(:disabled):not(.disabled).active,
.navbar .navbar-nav>a.btn.btn-link:not(:disabled):not(.disabled):active:focus,
.navbar .navbar-nav>a.btn.btn-link:not(:disabled):not(.disabled).active:focus,
.navbar .navbar-nav>a.btn.btn-link:active:hover,
.navbar .navbar-nav>a.btn.btn-link.active:hover,
.show>.navbar .navbar-nav>a.btn.btn-link.dropdown-toggle,
.show>.navbar .navbar-nav>a.btn.btn-link.dropdown-toggle:focus,
.show>.navbar .navbar-nav>a.btn.btn-link.dropdown-toggle:hover {
  background-color: transparent;
  color: #979797;
  text-decoration: none;
  box-shadow: none;
}

.btn:hover,
.btn:focus,
.navbar .navbar-nav>a.btn:hover,
.navbar .navbar-nav>a.btn:focus {
  opacity: 1;
  filter: alpha(opacity=100);
  outline: 0 !important;
}

.btn:active,
.btn.active,
.open>.btn.dropdown-toggle,
.navbar .navbar-nav>a.btn:active,
.navbar .navbar-nav>a.btn.active,
.open>.navbar .navbar-nav>a.btn.dropdown-toggle {
  box-shadow: none;
  outline: 0 !important;
}

.btn.btn-icon,
.navbar .navbar-nav>a.btn.btn-icon {
  height: 2.375rem;
  min-width: 2.375rem;
  width: 2.375rem;
  padding: 0;
  font-size: 0.9375rem;
  overflow: hidden;
  position: relative;
  line-height: normal;
}

.btn.btn-icon[class*="btn-outline-"],
.navbar .navbar-nav>a.btn.btn-icon[class*="btn-outline-"] {
  padding: 0;
}

.btn.btn-icon.btn-sm,
.navbar .navbar-nav>a.btn.btn-icon.btn-sm {
  height: 1.875rem;
  min-width: 1.875rem;
  width: 1.875rem;
}

.btn.btn-icon.btn-sm i.fab,
.btn.btn-icon.btn-sm i.now-ui-icons,
.navbar .navbar-nav>a.btn.btn-icon.btn-sm i.fab,
.navbar .navbar-nav>a.btn.btn-icon.btn-sm i.now-ui-icons {
  font-size: 0.6875rem;
}

.btn.btn-icon.btn-lg,
.navbar .navbar-nav>a.btn.btn-icon.btn-lg {
  height: 3.6rem;
  min-width: 3.6rem;
  width: 3.6rem;
}

.btn.btn-icon.btn-lg i.now-ui-icons,
.btn.btn-icon.btn-lg i.fab,
.navbar .navbar-nav>a.btn.btn-icon.btn-lg i.now-ui-icons,
.navbar .navbar-nav>a.btn.btn-icon.btn-lg i.fab {
  font-size: 1.325rem;
}

.btn.btn-icon:not(.btn-footer) i.now-ui-icons,
.btn.btn-icon:not(.btn-footer) i.fab,
.navbar .navbar-nav>a.btn.btn-icon:not(.btn-footer) i.now-ui-icons,
.navbar .navbar-nav>a.btn.btn-icon:not(.btn-footer) i.fab {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-12px, -12px);
          transform: translate(-12px, -12px);
  line-height: 1.5626rem;
  width: 23px;
}

.btn:not(.btn-icon) .now-ui-icons,
.navbar .navbar-nav>a.btn:not(.btn-icon) .now-ui-icons {
  position: relative;
  top: 1px;
}

.btn-primary {
  background-color: #f96332;
  color: #FFFFFF;
}

.btn-primary:hover,
.btn-primary:focus,
.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active:focus,
.btn-primary:not(:disabled):not(.disabled).active:focus,
.btn-primary:active:hover,
.btn-primary.active:hover,
.show>.btn-primary.dropdown-toggle,
.show>.btn-primary.dropdown-toggle:focus,
.show>.btn-primary.dropdown-toggle:hover {
  background-color: #fa7a50;
  color: #FFFFFF;
  box-shadow: none;
}

.btn-primary:hover {
  box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.17);
}

.btn-primary.disabled,
.btn-primary.disabled:hover,
.btn-primary.disabled:focus,
.btn-primary.disabled.focus,
.btn-primary.disabled:active,
.btn-primary.disabled.active,
.btn-primary:disabled,
.btn-primary:disabled:hover,
.btn-primary:disabled:focus,
.btn-primary:disabled.focus,
.btn-primary:disabled:active,
.btn-primary:disabled.active,
.btn-primary[disabled],
.btn-primary[disabled]:hover,
.btn-primary[disabled]:focus,
.btn-primary[disabled].focus,
.btn-primary[disabled]:active,
.btn-primary[disabled].active,
fieldset[disabled] .btn-primary,
fieldset[disabled] .btn-primary:hover,
fieldset[disabled] .btn-primary:focus,
fieldset[disabled] .btn-primary.focus,
fieldset[disabled] .btn-primary:active,
fieldset[disabled] .btn-primary.active {
  background-color: #f96332;
  border-color: #f96332;
}

.btn-primary.btn-simple {
  color: #f96332;
  border-color: #f96332;
}

.btn-primary.btn-simple:hover,
.btn-primary.btn-simple:focus,
.btn-primary.btn-simple:not(:disabled):not(.disabled):active,
.btn-primary.btn-simple:not(:disabled):not(.disabled).active,
.btn-primary.btn-simple:not(:disabled):not(.disabled):active:focus,
.btn-primary.btn-simple:not(:disabled):not(.disabled).active:focus,
.btn-primary.btn-simple:active:hover,
.btn-primary.btn-simple.active:hover,
.show>.btn-primary.btn-simple.dropdown-toggle,
.show>.btn-primary.btn-simple.dropdown-toggle:focus,
.show>.btn-primary.btn-simple.dropdown-toggle:hover {
  background-color: transparent;
  color: #fa7a50;
  border-color: #fa7a50;
  box-shadow: none;
}

.btn-primary.btn-link {
  color: #f96332;
}

.btn-primary.btn-link:hover,
.btn-primary.btn-link:focus,
.btn-primary.btn-link:not(:disabled):not(.disabled):active,
.btn-primary.btn-link:not(:disabled):not(.disabled).active,
.btn-primary.btn-link:not(:disabled):not(.disabled):active:focus,
.btn-primary.btn-link:not(:disabled):not(.disabled).active:focus,
.btn-primary.btn-link:active:hover,
.btn-primary.btn-link.active:hover,
.show>.btn-primary.btn-link.dropdown-toggle,
.show>.btn-primary.btn-link.dropdown-toggle:focus,
.show>.btn-primary.btn-link.dropdown-toggle:hover {
  background-color: transparent;
  color: #fa7a50;
  text-decoration: none;
  box-shadow: none;
}

.btn-success {
  background-color: #18ce0f;
  color: #FFFFFF;
}

.btn-success:hover,
.btn-success:focus,
.btn-success:not(:disabled):not(.disabled):active,
.btn-success:not(:disabled):not(.disabled).active,
.btn-success:not(:disabled):not(.disabled):active:focus,
.btn-success:not(:disabled):not(.disabled).active:focus,
.btn-success:active:hover,
.btn-success.active:hover,
.show>.btn-success.dropdown-toggle,
.show>.btn-success.dropdown-toggle:focus,
.show>.btn-success.dropdown-toggle:hover {
  background-color: #1beb11;
  color: #FFFFFF;
  box-shadow: none;
}

.btn-success:hover {
  box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.17);
}

.btn-success.disabled,
.btn-success.disabled:hover,
.btn-success.disabled:focus,
.btn-success.disabled.focus,
.btn-success.disabled:active,
.btn-success.disabled.active,
.btn-success:disabled,
.btn-success:disabled:hover,
.btn-success:disabled:focus,
.btn-success:disabled.focus,
.btn-success:disabled:active,
.btn-success:disabled.active,
.btn-success[disabled],
.btn-success[disabled]:hover,
.btn-success[disabled]:focus,
.btn-success[disabled].focus,
.btn-success[disabled]:active,
.btn-success[disabled].active,
fieldset[disabled] .btn-success,
fieldset[disabled] .btn-success:hover,
fieldset[disabled] .btn-success:focus,
fieldset[disabled] .btn-success.focus,
fieldset[disabled] .btn-success:active,
fieldset[disabled] .btn-success.active {
  background-color: #18ce0f;
  border-color: #18ce0f;
}

.btn-success.btn-simple {
  color: #18ce0f;
  border-color: #18ce0f;
}

.btn-success.btn-simple:hover,
.btn-success.btn-simple:focus,
.btn-success.btn-simple:not(:disabled):not(.disabled):active,
.btn-success.btn-simple:not(:disabled):not(.disabled).active,
.btn-success.btn-simple:not(:disabled):not(.disabled):active:focus,
.btn-success.btn-simple:not(:disabled):not(.disabled).active:focus,
.btn-success.btn-simple:active:hover,
.btn-success.btn-simple.active:hover,
.show>.btn-success.btn-simple.dropdown-toggle,
.show>.btn-success.btn-simple.dropdown-toggle:focus,
.show>.btn-success.btn-simple.dropdown-toggle:hover {
  background-color: transparent;
  color: #1beb11;
  border-color: #1beb11;
  box-shadow: none;
}

.btn-success.btn-link {
  color: #18ce0f;
}

.btn-success.btn-link:hover,
.btn-success.btn-link:focus,
.btn-success.btn-link:not(:disabled):not(.disabled):active,
.btn-success.btn-link:not(:disabled):not(.disabled).active,
.btn-success.btn-link:not(:disabled):not(.disabled):active:focus,
.btn-success.btn-link:not(:disabled):not(.disabled).active:focus,
.btn-success.btn-link:active:hover,
.btn-success.btn-link.active:hover,
.show>.btn-success.btn-link.dropdown-toggle,
.show>.btn-success.btn-link.dropdown-toggle:focus,
.show>.btn-success.btn-link.dropdown-toggle:hover {
  background-color: transparent;
  color: #1beb11;
  text-decoration: none;
  box-shadow: none;
}

.btn-info {
  background-color: #2CA8FF;
  color: #FFFFFF;
}

.btn-info:hover,
.btn-info:focus,
.btn-info:not(:disabled):not(.disabled):active,
.btn-info:not(:disabled):not(.disabled).active,
.btn-info:not(:disabled):not(.disabled):active:focus,
.btn-info:not(:disabled):not(.disabled).active:focus,
.btn-info:active:hover,
.btn-info.active:hover,
.show>.btn-info.dropdown-toggle,
.show>.btn-info.dropdown-toggle:focus,
.show>.btn-info.dropdown-toggle:hover {
  background-color: #4bb5ff;
  color: #FFFFFF;
  box-shadow: none;
}

.btn-info:hover {
  box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.17);
}

.btn-info.disabled,
.btn-info.disabled:hover,
.btn-info.disabled:focus,
.btn-info.disabled.focus,
.btn-info.disabled:active,
.btn-info.disabled.active,
.btn-info:disabled,
.btn-info:disabled:hover,
.btn-info:disabled:focus,
.btn-info:disabled.focus,
.btn-info:disabled:active,
.btn-info:disabled.active,
.btn-info[disabled],
.btn-info[disabled]:hover,
.btn-info[disabled]:focus,
.btn-info[disabled].focus,
.btn-info[disabled]:active,
.btn-info[disabled].active,
fieldset[disabled] .btn-info,
fieldset[disabled] .btn-info:hover,
fieldset[disabled] .btn-info:focus,
fieldset[disabled] .btn-info.focus,
fieldset[disabled] .btn-info:active,
fieldset[disabled] .btn-info.active {
  background-color: #2CA8FF;
  border-color: #2CA8FF;
}

.btn-info.btn-simple {
  color: #2CA8FF;
  border-color: #2CA8FF;
}

.btn-info.btn-simple:hover,
.btn-info.btn-simple:focus,
.btn-info.btn-simple:not(:disabled):not(.disabled):active,
.btn-info.btn-simple:not(:disabled):not(.disabled).active,
.btn-info.btn-simple:not(:disabled):not(.disabled):active:focus,
.btn-info.btn-simple:not(:disabled):not(.disabled).active:focus,
.btn-info.btn-simple:active:hover,
.btn-info.btn-simple.active:hover,
.show>.btn-info.btn-simple.dropdown-toggle,
.show>.btn-info.btn-simple.dropdown-toggle:focus,
.show>.btn-info.btn-simple.dropdown-toggle:hover {
  background-color: transparent;
  color: #4bb5ff;
  border-color: #4bb5ff;
  box-shadow: none;
}

.btn-info.btn-link {
  color: #2CA8FF;
}

.btn-info.btn-link:hover,
.btn-info.btn-link:focus,
.btn-info.btn-link:not(:disabled):not(.disabled):active,
.btn-info.btn-link:not(:disabled):not(.disabled).active,
.btn-info.btn-link:not(:disabled):not(.disabled):active:focus,
.btn-info.btn-link:not(:disabled):not(.disabled).active:focus,
.btn-info.btn-link:active:hover,
.btn-info.btn-link.active:hover,
.show>.btn-info.btn-link.dropdown-toggle,
.show>.btn-info.btn-link.dropdown-toggle:focus,
.show>.btn-info.btn-link.dropdown-toggle:hover {
  background-color: transparent;
  color: #4bb5ff;
  text-decoration: none;
  box-shadow: none;
}

.btn-warning {
  background-color: #FFB236;
  color: #FFFFFF;
}

.btn-warning:hover,
.btn-warning:focus,
.btn-warning:not(:disabled):not(.disabled):active,
.btn-warning:not(:disabled):not(.disabled).active,
.btn-warning:not(:disabled):not(.disabled):active:focus,
.btn-warning:not(:disabled):not(.disabled).active:focus,
.btn-warning:active:hover,
.btn-warning.active:hover,
.show>.btn-warning.dropdown-toggle,
.show>.btn-warning.dropdown-toggle:focus,
.show>.btn-warning.dropdown-toggle:hover {
  background-color: #ffbe55;
  color: #FFFFFF;
  box-shadow: none;
}

.btn-warning:hover {
  box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.17);
}

.btn-warning.disabled,
.btn-warning.disabled:hover,
.btn-warning.disabled:focus,
.btn-warning.disabled.focus,
.btn-warning.disabled:active,
.btn-warning.disabled.active,
.btn-warning:disabled,
.btn-warning:disabled:hover,
.btn-warning:disabled:focus,
.btn-warning:disabled.focus,
.btn-warning:disabled:active,
.btn-warning:disabled.active,
.btn-warning[disabled],
.btn-warning[disabled]:hover,
.btn-warning[disabled]:focus,
.btn-warning[disabled].focus,
.btn-warning[disabled]:active,
.btn-warning[disabled].active,
fieldset[disabled] .btn-warning,
fieldset[disabled] .btn-warning:hover,
fieldset[disabled] .btn-warning:focus,
fieldset[disabled] .btn-warning.focus,
fieldset[disabled] .btn-warning:active,
fieldset[disabled] .btn-warning.active {
  background-color: #FFB236;
  border-color: #FFB236;
}

.btn-warning.btn-simple {
  color: #FFB236;
  border-color: #FFB236;
}

.btn-warning.btn-simple:hover,
.btn-warning.btn-simple:focus,
.btn-warning.btn-simple:not(:disabled):not(.disabled):active,
.btn-warning.btn-simple:not(:disabled):not(.disabled).active,
.btn-warning.btn-simple:not(:disabled):not(.disabled):active:focus,
.btn-warning.btn-simple:not(:disabled):not(.disabled).active:focus,
.btn-warning.btn-simple:active:hover,
.btn-warning.btn-simple.active:hover,
.show>.btn-warning.btn-simple.dropdown-toggle,
.show>.btn-warning.btn-simple.dropdown-toggle:focus,
.show>.btn-warning.btn-simple.dropdown-toggle:hover {
  background-color: transparent;
  color: #ffbe55;
  border-color: #ffbe55;
  box-shadow: none;
}

.btn-warning.btn-link {
  color: #FFB236;
}

.btn-warning.btn-link:hover,
.btn-warning.btn-link:focus,
.btn-warning.btn-link:not(:disabled):not(.disabled):active,
.btn-warning.btn-link:not(:disabled):not(.disabled).active,
.btn-warning.btn-link:not(:disabled):not(.disabled):active:focus,
.btn-warning.btn-link:not(:disabled):not(.disabled).active:focus,
.btn-warning.btn-link:active:hover,
.btn-warning.btn-link.active:hover,
.show>.btn-warning.btn-link.dropdown-toggle,
.show>.btn-warning.btn-link.dropdown-toggle:focus,
.show>.btn-warning.btn-link.dropdown-toggle:hover {
  background-color: transparent;
  color: #ffbe55;
  text-decoration: none;
  box-shadow: none;
}

.btn-danger {
  background-color: #FF3636;
  color: #FFFFFF;
}

.btn-danger:hover,
.btn-danger:focus,
.btn-danger:not(:disabled):not(.disabled):active,
.btn-danger:not(:disabled):not(.disabled).active,
.btn-danger:not(:disabled):not(.disabled):active:focus,
.btn-danger:not(:disabled):not(.disabled).active:focus,
.btn-danger:active:hover,
.btn-danger.active:hover,
.show>.btn-danger.dropdown-toggle,
.show>.btn-danger.dropdown-toggle:focus,
.show>.btn-danger.dropdown-toggle:hover {
  background-color: #ff5555;
  color: #FFFFFF;
  box-shadow: none;
}

.btn-danger:hover {
  box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.17);
}

.btn-danger.disabled,
.btn-danger.disabled:hover,
.btn-danger.disabled:focus,
.btn-danger.disabled.focus,
.btn-danger.disabled:active,
.btn-danger.disabled.active,
.btn-danger:disabled,
.btn-danger:disabled:hover,
.btn-danger:disabled:focus,
.btn-danger:disabled.focus,
.btn-danger:disabled:active,
.btn-danger:disabled.active,
.btn-danger[disabled],
.btn-danger[disabled]:hover,
.btn-danger[disabled]:focus,
.btn-danger[disabled].focus,
.btn-danger[disabled]:active,
.btn-danger[disabled].active,
fieldset[disabled] .btn-danger,
fieldset[disabled] .btn-danger:hover,
fieldset[disabled] .btn-danger:focus,
fieldset[disabled] .btn-danger.focus,
fieldset[disabled] .btn-danger:active,
fieldset[disabled] .btn-danger.active {
  background-color: #FF3636;
  border-color: #FF3636;
}

.btn-danger.btn-simple {
  color: #FF3636;
  border-color: #FF3636;
}

.btn-danger.btn-simple:hover,
.btn-danger.btn-simple:focus,
.btn-danger.btn-simple:not(:disabled):not(.disabled):active,
.btn-danger.btn-simple:not(:disabled):not(.disabled).active,
.btn-danger.btn-simple:not(:disabled):not(.disabled):active:focus,
.btn-danger.btn-simple:not(:disabled):not(.disabled).active:focus,
.btn-danger.btn-simple:active:hover,
.btn-danger.btn-simple.active:hover,
.show>.btn-danger.btn-simple.dropdown-toggle,
.show>.btn-danger.btn-simple.dropdown-toggle:focus,
.show>.btn-danger.btn-simple.dropdown-toggle:hover {
  background-color: transparent;
  color: #ff5555;
  border-color: #ff5555;
  box-shadow: none;
}

.btn-danger.btn-link {
  color: #FF3636;
}

.btn-danger.btn-link:hover,
.btn-danger.btn-link:focus,
.btn-danger.btn-link:not(:disabled):not(.disabled):active,
.btn-danger.btn-link:not(:disabled):not(.disabled).active,
.btn-danger.btn-link:not(:disabled):not(.disabled):active:focus,
.btn-danger.btn-link:not(:disabled):not(.disabled).active:focus,
.btn-danger.btn-link:active:hover,
.btn-danger.btn-link.active:hover,
.show>.btn-danger.btn-link.dropdown-toggle,
.show>.btn-danger.btn-link.dropdown-toggle:focus,
.show>.btn-danger.btn-link.dropdown-toggle:hover {
  background-color: transparent;
  color: #ff5555;
  text-decoration: none;
  box-shadow: none;
}

.btn-neutral {
  background-color: #FFFFFF;
  color: #f96332;
}

.btn-neutral:hover,
.btn-neutral:focus,
.btn-neutral:not(:disabled):not(.disabled):active,
.btn-neutral:not(:disabled):not(.disabled).active,
.btn-neutral:not(:disabled):not(.disabled):active:focus,
.btn-neutral:not(:disabled):not(.disabled).active:focus,
.btn-neutral:active:hover,
.btn-neutral.active:hover,
.show>.btn-neutral.dropdown-toggle,
.show>.btn-neutral.dropdown-toggle:focus,
.show>.btn-neutral.dropdown-toggle:hover {
  background-color: #FFFFFF;
  color: #FFFFFF;
  box-shadow: none;
}

.btn-neutral:hover {
  box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.17);
}

.btn-neutral.disabled,
.btn-neutral.disabled:hover,
.btn-neutral.disabled:focus,
.btn-neutral.disabled.focus,
.btn-neutral.disabled:active,
.btn-neutral.disabled.active,
.btn-neutral:disabled,
.btn-neutral:disabled:hover,
.btn-neutral:disabled:focus,
.btn-neutral:disabled.focus,
.btn-neutral:disabled:active,
.btn-neutral:disabled.active,
.btn-neutral[disabled],
.btn-neutral[disabled]:hover,
.btn-neutral[disabled]:focus,
.btn-neutral[disabled].focus,
.btn-neutral[disabled]:active,
.btn-neutral[disabled].active,
fieldset[disabled] .btn-neutral,
fieldset[disabled] .btn-neutral:hover,
fieldset[disabled] .btn-neutral:focus,
fieldset[disabled] .btn-neutral.focus,
fieldset[disabled] .btn-neutral:active,
fieldset[disabled] .btn-neutral.active {
  background-color: #FFFFFF;
  border-color: #FFFFFF;
}

.btn-neutral.btn-danger {
  color: #FF3636;
}

.btn-neutral.btn-danger:hover,
.btn-neutral.btn-danger:focus,
.btn-neutral.btn-danger:active {
  color: #ff5555 !important;
}

.btn-neutral.btn-primary {
  color: #f96332;
}

.btn-neutral.btn-primary:hover,
.btn-neutral.btn-primary:focus,
.btn-neutral.btn-primary:active {
  color: #fa7a50 !important;
}

.btn-neutral.btn-info {
  color: #2CA8FF;
}

.btn-neutral.btn-info:hover,
.btn-neutral.btn-info:focus,
.btn-neutral.btn-info:active {
  color: #4bb5ff !important;
}

.btn-neutral.btn-warning {
  color: #FFB236;
}

.btn-neutral.btn-warning:hover,
.btn-neutral.btn-warning:focus,
.btn-neutral.btn-warning:active {
  color: #ffbe55 !important;
}

.btn-neutral.btn-success {
  color: #18ce0f;
}

.btn-neutral.btn-success:hover,
.btn-neutral.btn-success:focus,
.btn-neutral.btn-success:active {
  color: #1beb11 !important;
}

.btn-neutral.btn-default {
  color: #888888;
}

.btn-neutral.btn-default:hover,
.btn-neutral.btn-default:focus,
.btn-neutral.btn-default:active {
  color: #979797 !important;
}

.btn-neutral:hover,
.btn-neutral:focus,
.btn-neutral:not(:disabled):not(.disabled):active,
.btn-neutral:not(:disabled):not(.disabled).active,
.btn-neutral:not(:disabled):not(.disabled):active:focus,
.btn-neutral:not(:disabled):not(.disabled).active:focus,
.btn-neutral:active:hover,
.btn-neutral.active:hover,
.show>.btn-neutral.dropdown-toggle,
.show>.btn-neutral.dropdown-toggle:focus,
.show>.btn-neutral.dropdown-toggle:hover {
  background-color: #FFFFFF;
  color: #fa7a50;
  box-shadow: none;
}

.btn-neutral:hover,
.btn-neutral:focus {
  color: #fa7a50 !important;
}

.btn-neutral:hover:not(.nav-link),
.btn-neutral:focus:not(.nav-link) {
  box-shadow: none;
}

.btn-neutral.btn-simple {
  color: #FFFFFF;
  border-color: #FFFFFF;
}

.btn-neutral.btn-simple:hover,
.btn-neutral.btn-simple:focus,
.btn-neutral.btn-simple:not(:disabled):not(.disabled):active,
.btn-neutral.btn-simple:not(:disabled):not(.disabled).active,
.btn-neutral.btn-simple:not(:disabled):not(.disabled):active:focus,
.btn-neutral.btn-simple:not(:disabled):not(.disabled).active:focus,
.btn-neutral.btn-simple:active:hover,
.btn-neutral.btn-simple.active:hover,
.show>.btn-neutral.btn-simple.dropdown-toggle,
.show>.btn-neutral.btn-simple.dropdown-toggle:focus,
.show>.btn-neutral.btn-simple.dropdown-toggle:hover {
  background-color: transparent;
  color: #FFFFFF;
  border-color: #FFFFFF;
  box-shadow: none;
}

.btn-neutral.btn-link {
  color: #FFFFFF;
}

.btn-neutral.btn-link:hover,
.btn-neutral.btn-link:focus,
.btn-neutral.btn-link:not(:disabled):not(.disabled):active,
.btn-neutral.btn-link:not(:disabled):not(.disabled).active,
.btn-neutral.btn-link:not(:disabled):not(.disabled):active:focus,
.btn-neutral.btn-link:not(:disabled):not(.disabled).active:focus,
.btn-neutral.btn-link:active:hover,
.btn-neutral.btn-link.active:hover,
.show>.btn-neutral.btn-link.dropdown-toggle,
.show>.btn-neutral.btn-link.dropdown-toggle:focus,
.show>.btn-neutral.btn-link.dropdown-toggle:hover {
  background-color: transparent;
  color: #FFFFFF;
  text-decoration: none;
  box-shadow: none;
}

.btn-outline-primary {
  color: #f96332;
  border-color: #f96332;
}

.btn-outline-primary:hover,
.btn-outline-primary:focus,
.btn-outline-primary:not(:disabled):not(.disabled):active,
.btn-outline-primary:not(:disabled):not(.disabled).active,
.btn-outline-primary:not(:disabled):not(.disabled):active:focus,
.btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.btn-outline-primary:active:hover,
.btn-outline-primary.active:hover,
.show>.btn-outline-primary.dropdown-toggle,
.show>.btn-outline-primary.dropdown-toggle:focus,
.show>.btn-outline-primary.dropdown-toggle:hover {
  background-color: transparent;
  color: #fa7a50;
  border-color: #fa7a50;
  box-shadow: none;
}

.btn-outline-success {
  color: #18ce0f;
  border-color: #18ce0f;
}

.btn-outline-success:hover,
.btn-outline-success:focus,
.btn-outline-success:not(:disabled):not(.disabled):active,
.btn-outline-success:not(:disabled):not(.disabled).active,
.btn-outline-success:not(:disabled):not(.disabled):active:focus,
.btn-outline-success:not(:disabled):not(.disabled).active:focus,
.btn-outline-success:active:hover,
.btn-outline-success.active:hover,
.show>.btn-outline-success.dropdown-toggle,
.show>.btn-outline-success.dropdown-toggle:focus,
.show>.btn-outline-success.dropdown-toggle:hover {
  background-color: transparent;
  color: #1beb11;
  border-color: #1beb11;
  box-shadow: none;
}

.btn-outline-info {
  color: #2CA8FF;
  border-color: #2CA8FF;
}

.btn-outline-info:hover,
.btn-outline-info:focus,
.btn-outline-info:not(:disabled):not(.disabled):active,
.btn-outline-info:not(:disabled):not(.disabled).active,
.btn-outline-info:not(:disabled):not(.disabled):active:focus,
.btn-outline-info:not(:disabled):not(.disabled).active:focus,
.btn-outline-info:active:hover,
.btn-outline-info.active:hover,
.show>.btn-outline-info.dropdown-toggle,
.show>.btn-outline-info.dropdown-toggle:focus,
.show>.btn-outline-info.dropdown-toggle:hover {
  background-color: transparent;
  color: #4bb5ff;
  border-color: #4bb5ff;
  box-shadow: none;
}

.btn-outline-warning {
  color: #FFB236;
  border-color: #FFB236;
}

.btn-outline-warning:hover,
.btn-outline-warning:focus,
.btn-outline-warning:not(:disabled):not(.disabled):active,
.btn-outline-warning:not(:disabled):not(.disabled).active,
.btn-outline-warning:not(:disabled):not(.disabled):active:focus,
.btn-outline-warning:not(:disabled):not(.disabled).active:focus,
.btn-outline-warning:active:hover,
.btn-outline-warning.active:hover,
.show>.btn-outline-warning.dropdown-toggle,
.show>.btn-outline-warning.dropdown-toggle:focus,
.show>.btn-outline-warning.dropdown-toggle:hover {
  background-color: transparent;
  color: #ffbe55;
  border-color: #ffbe55;
  box-shadow: none;
}

.btn-outline-danger {
  color: #FF3636;
  border-color: #FF3636;
}

.btn-outline-danger:hover,
.btn-outline-danger:focus,
.btn-outline-danger:not(:disabled):not(.disabled):active,
.btn-outline-danger:not(:disabled):not(.disabled).active,
.btn-outline-danger:not(:disabled):not(.disabled):active:focus,
.btn-outline-danger:not(:disabled):not(.disabled).active:focus,
.btn-outline-danger:active:hover,
.btn-outline-danger.active:hover,
.show>.btn-outline-danger.dropdown-toggle,
.show>.btn-outline-danger.dropdown-toggle:focus,
.show>.btn-outline-danger.dropdown-toggle:hover {
  background-color: transparent;
  color: #ff5555;
  border-color: #ff5555;
  box-shadow: none;
}

.btn-outline-default {
  color: #888888;
  border-color: #888888;
}

.btn-outline-default:hover,
.btn-outline-default:focus,
.btn-outline-default:not(:disabled):not(.disabled):active,
.btn-outline-default:not(:disabled):not(.disabled).active,
.btn-outline-default:not(:disabled):not(.disabled):active:focus,
.btn-outline-default:not(:disabled):not(.disabled).active:focus,
.btn-outline-default:active:hover,
.btn-outline-default.active:hover,
.show>.btn-outline-default.dropdown-toggle,
.show>.btn-outline-default.dropdown-toggle:focus,
.show>.btn-outline-default.dropdown-toggle:hover {
  background-color: transparent;
  color: #979797;
  border-color: #979797;
  box-shadow: none;
}

.btn-round {
  border-width: 1px;
  border-radius: 30px !important;
  padding: 11px 23px;
}

.btn-round[class*="btn-outline-"] {
  padding: 10px 22px;
}

[class*="btn-outline-"] {
  border: 1px solid;
  padding: 10px 22px;
  background-color: transparent;
}

[class*="btn-outline-"].disabled,
[class*="btn-outline-"].disabled:hover,
[class*="btn-outline-"].disabled:focus,
[class*="btn-outline-"].disabled.focus,
[class*="btn-outline-"].disabled:active,
[class*="btn-outline-"].disabled.active,
[class*="btn-outline-"]:disabled,
[class*="btn-outline-"]:disabled:hover,
[class*="btn-outline-"]:disabled:focus,
[class*="btn-outline-"]:disabled.focus,
[class*="btn-outline-"]:disabled:active,
[class*="btn-outline-"]:disabled.active,
[class*="btn-outline-"][disabled],
[class*="btn-outline-"][disabled]:hover,
[class*="btn-outline-"][disabled]:focus,
[class*="btn-outline-"][disabled].focus,
[class*="btn-outline-"][disabled]:active,
[class*="btn-outline-"][disabled].active,
fieldset[disabled] [class*="btn-outline-"],
fieldset[disabled] [class*="btn-outline-"]:hover,
fieldset[disabled] [class*="btn-outline-"]:focus,
fieldset[disabled] [class*="btn-outline-"].focus,
fieldset[disabled] [class*="btn-outline-"]:active,
fieldset[disabled] [class*="btn-outline-"].active,
.btn-link.disabled,
.btn-link.disabled:hover,
.btn-link.disabled:focus,
.btn-link.disabled.focus,
.btn-link.disabled:active,
.btn-link.disabled.active,
.btn-link:disabled,
.btn-link:disabled:hover,
.btn-link:disabled:focus,
.btn-link:disabled.focus,
.btn-link:disabled:active,
.btn-link:disabled.active,
.btn-link[disabled],
.btn-link[disabled]:hover,
.btn-link[disabled]:focus,
.btn-link[disabled].focus,
.btn-link[disabled]:active,
.btn-link[disabled].active,
fieldset[disabled] .btn-link,
fieldset[disabled] .btn-link:hover,
fieldset[disabled] .btn-link:focus,
fieldset[disabled] .btn-link.focus,
fieldset[disabled] .btn-link:active,
fieldset[disabled] .btn-link.active {
  background-color: transparent;
}

.btn-lg {
  font-size: 1em;
  border-radius: 0.25rem;
  padding: 15px 48px;
}

.btn-lg[class*="btn-outline-"] {
  padding: 14px 47px;
}

.btn-sm {
  font-size: 14px;
  border-radius: 0.1875rem;
  padding: 5px 15px;
}

.btn-sm[class*="btn-outline-"] {
  padding: 4px 14px;
}

.btn-link {
  border: 0;
  padding: 0.5rem 0.7rem;
  background-color: transparent;
}

.btn-wd {
  min-width: 140px;
}

.btn-group.select {
  width: 100%;
}

.btn-group.select .btn {
  text-align: left;
}

.btn-group.select .caret {
  position: absolute;
  top: 50%;
  margin-top: -1px;
  right: 8px;
}

.btn-facebook,
.btn-facebook:hover,
.btn-facebookfocus,
.btn-facebook:active,
.btn-facebook:active:focus {
  color: #3b5998 !important;
}

.btn-twitter,
.btn-twitter:hover,
.btn-twitterfocus,
.btn-twitter:active,
.btn-twitter:active:focus {
  color: #55acee !important;
}

.btn-google,
.btn-google:hover,
.btn-googlefocus,
.btn-google:active,
.btn-google:active:focus {
  color: #dd4b39 !important;
}

.btn-github,
.btn-github:hover,
.btn-githubfocus,
.btn-github:active,
.btn-github:active:focus {
  color: #333333 !important;
}

.btn-linkedin,
.btn-linkedin:hover,
.btn-linkedinfocus,
.btn-linkedin:active,
.btn-linkedin:active:focus {
  color: #0077B5 !important;
}

.form-control::-moz-placeholder {
  color: #888888;
  opacity: 0.8;
  filter: alpha(opacity=80);
}

.form-control:-moz-placeholder {
  color: #888888;
  opacity: 0.8;
  filter: alpha(opacity=80);
}

.form-control::-webkit-input-placeholder {
  color: #888888;
  opacity: 0.8;
  filter: alpha(opacity=80);
}

.form-control:-ms-input-placeholder {
  color: #888888;
  opacity: 0.8;
  filter: alpha(opacity=80);
}

.form-control {
  background-color: transparent;
  border: 1px solid #E3E3E3;
  border-radius: 30px;
  color: #2c2c2c;
  height: auto;
  line-height: normal;
  font-size: 0.8571em;
  transition: color 0.3s ease-in-out, border-color 0.3s ease-in-out, background-color 0.3s ease-in-out;
  box-shadow: none;
}

.has-success .form-control {
  border-color: #E3E3E3;
}

.form-control:focus {
  border: 1px solid #f96332;
  box-shadow: none;
  outline: 0 !important;
  color: #2c2c2c;
}

.form-control:focus+.input-group-text,
.form-control:focus~.input-group-text {
  border: 1px solid #f96332;
  border-left: none;
  background-color: transparent;
}

.has-success .form-control,
.has-error .form-control,
.has-success .form-control:focus,
.has-error .form-control:focus {
  box-shadow: none;
}

.has-success .form-control:focus {
  border-color: #1be611;
}

.has-danger .form-control.form-control-success,
.has-danger .form-control.form-control-danger,
.has-success .form-control.form-control-success,
.has-success .form-control.form-control-danger {
  background-image: none;
}

.has-danger .form-control {
  border-color: #ffcfcf;
  color: #FF3636;
  background-color: rgba(222, 222, 222, 0.1);
}

.has-danger .form-control:focus {
  background-color: #FFFFFF;
}

.form-control+.form-control-feedback {
  border-radius: 0.25rem;
  font-size: 14px;
  margin-top: -7px;
  position: absolute;
  right: 10px;
  top: 50%;
  vertical-align: middle;
}

.open .form-control {
  border-radius: 0.25rem 0.25rem 0 0;
  border-bottom-color: transparent;
}

.has-success:after,
.has-danger:after {
  font-family: 'Nucleo Outline';
  content: "\ea22";
  display: inline-block;
  position: absolute;
  right: 20px;
  top: 12px;
  color: #18ce0f;
  font-size: 11px;
}

.has-success.input-lg:after,
.has-danger.input-lg:after {
  font-size: 13px;
  top: 13px;
}

.has-danger:after {
  content: "\ea53";
  color: #FF3636;
}

.form-group.no-border.input-lg .input-group-text,
.input-group.no-border.input-lg .input-group-text {
  padding: 15px 0 15px 19px;
}

.form-group.no-border.input-lg .form-control,
.input-group.no-border.input-lg .form-control {
  padding: 15px 19px;
}

.form-group.no-border.input-lg .form-control+.input-group-text,
.input-group.no-border.input-lg .form-control+.input-group-text {
  padding: 15px 19px 15px 0;
}

.form-group.input-lg .form-control,
.input-group.input-lg .form-control {
  padding: 14px 18px;
}

.form-group.input-lg .form-control+.input-group-text,
.input-group.input-lg .form-control+.input-group-text {
  padding: 14px 18px 14px 0;
}

.form-group.input-lg .input-group-text,
.input-group.input-lg .input-group-text {
  padding: 14px 0 15px 18px;
}

.form-group.input-lg .input-group-text+.form-control,
.input-group.input-lg .input-group-text+.form-control {
  padding: 15px 18px 15px 16px;
}

.form-group.no-border .form-control,
.input-group.no-border .form-control {
  padding: 11px 19px;
}

.form-group.no-border .form-control+.input-group-text,
.input-group.no-border .form-control+.input-group-text {
  padding: 11px 19px 11px 0;
}

.form-group.no-border .input-group-text,
.input-group.no-border .input-group-text {
  padding: 11px 0 11px 19px;
}

.form-group .form-control,
.input-group .form-control {
  padding: 10px 18px 10px 18px;
}

.form-group .form-control+.input-group-text,
.input-group .form-control+.input-group-text {
  padding: 10px 18px 10px 0;
}

.form-group .input-group-text,
.input-group .input-group-text {
  padding: 10px 0 10px 18px;
}

.form-group .input-group-text+.form-control,
.form-group .input-group-text~.form-control,
.input-group .input-group-text+.form-control,
.input-group .input-group-text~.form-control {
  padding: 10px 19px 11px 16px;
}

.form-group.no-border .form-control,
.form-group.no-border .form-control+.input-group-text,
.input-group.no-border .form-control,
.input-group.no-border .form-control+.input-group-text {
  background-color: rgba(222, 222, 222, 0.3);
  border: medium none;
}

.form-group.no-border .form-control:focus,
.form-group.no-border .form-control:active,
.form-group.no-border .form-control:active,
.form-group.no-border .form-control+.input-group-text:focus,
.form-group.no-border .form-control+.input-group-text:active,
.form-group.no-border .form-control+.input-group-text:active,
.input-group.no-border .form-control:focus,
.input-group.no-border .form-control:active,
.input-group.no-border .form-control:active,
.input-group.no-border .form-control+.input-group-text:focus,
.input-group.no-border .form-control+.input-group-text:active,
.input-group.no-border .form-control+.input-group-text:active {
  border: medium none;
  background-color: rgba(222, 222, 222, 0.5);
}

.form-group.no-border .form-control:focus+.input-group-text,
.input-group.no-border .form-control:focus+.input-group-text {
  background-color: rgba(222, 222, 222, 0.5);
}

.form-group.no-border .input-group-prepend .input-group-text,
.input-group.no-border .input-group-prepend .input-group-text {
  background-color: rgba(222, 222, 222, 0.3);
  border: none;
  border-left: transparent !important;
}

.form-group.no-border.input-group-focus.no-border .input-group-text,
.input-group.no-border.input-group-focus.no-border .input-group-text {
  background-color: rgba(222, 222, 222, 0.5);
}

.has-error .form-control-feedback,
.has-error .control-label {
  color: #FF3636;
}

.has-success .form-control-feedback,
.has-success .control-label {
  color: #18ce0f;
}

.input-group-text {
  background-color: #FFFFFF;
  border: 1px solid #E3E3E3;
  border-radius: 30px;
  color: #555555;
  transition: color 0.3s ease-in-out, border-color 0.3s ease-in-out, background-color 0.3s ease-in-out;
}

.input-group-focus .input-group-text {
  background-color: #FFFFFF;
  border-color: #f96332;
}

.has-success .input-group-text,
.has-danger .input-group-text {
  background-color: #FFFFFF;
}

.has-danger .form-control:focus+.input-group-text {
  color: #FF3636;
}

.has-success .form-control:focus+.input-group-text {
  color: #18ce0f;
}

.input-group-text+.form-control,
.input-group-text~.form-control {
  padding: -0.5rem 0.7rem;
  padding-left: 18px;
}

.input-group-text i {
  width: 17px;
}

.input-group,
.form-group {
  margin-bottom: 10px;
  position: relative;
}

.input-group[disabled] .input-group-text {
  background-color: #E3E3E3;
}

.input-group .input-group-prepend,
.form-group .input-group-prepend {
  margin-right: 0;
}

.input-group .input-group-prepend .input-group-text,
.form-group .input-group-prepend .input-group-text {
  border-left: 1px solid #E3E3E3;
}

.input-group .input-group-append .input-group-text,
.form-group .input-group-append .input-group-text {
  border-right: 1px solid #E3E3E3;
  padding: 10px 18px 10px 0;
}

.input-group.input-group-focus .input-group-prepend .input-group-text,
.form-group.input-group-focus .input-group-prepend .input-group-text {
  border-left: 1px solid #f96332;
}

.input-group.input-group-focus .input-group-append .input-group-text,
.form-group.input-group-focus .input-group-append .input-group-text {
  border-right: 1px solid #f96332;
}

.input-group.input-group-focus.no-border .input-group-prepend .input-group-text,
.form-group.input-group-focus.no-border .input-group-prepend .input-group-text {
  border-left: transparent;
}

.input-group .form-control:first-child,
.input-group-text:first-child,
.input-group-btn:first-child>.dropdown-toggle,
.input-group-btn:last-child>.btn:not(:last-child):not(.dropdown-toggle) {
  border-right: 0 none;
}

.input-group .form-control:last-child,
.input-group-text:last-child,
.input-group-btn:last-child>.dropdown-toggle,
.input-group-btn:first-child>.btn:not(:first-child) {
  border-left: 0 none;
}

.form-control[disabled],
.form-control[readonly],
fieldset[disabled] .form-control {
  background-color: #E3E3E3;
  color: #888888;
  cursor: not-allowed;
}

.input-group-btn .btn {
  border-width: 1px;
  padding: 11px 0.7rem;
}

.input-group-btn .btn-default:not(.btn-fill) {
  border-color: #DDDDDD;
}

.input-group-btn:last-child>.btn {
  margin-left: 0;
}

textarea.form-control {
  max-width: 100%;
  max-height: 80px;
  padding: 10px 10px 0 0;
  resize: none;
  border: none;
  border-bottom: 1px solid #E3E3E3;
  border-radius: 0;
  line-height: 2;
}

textarea.form-control:focus,
textarea.form-control:active {
  border-left: none;
  border-top: none;
  border-right: none;
}

.has-success.form-group .form-control,
.has-success.form-group.no-border .form-control,
.has-danger.form-group .form-control,
.has-danger.form-group.no-border .form-control {
  padding-right: 40px;
}

.form.form-newsletter .form-group {
  float: left;
  width: 78%;
  margin-right: 2%;
  margin-top: 9px;
}

.input-group .input-group-btn {
  padding: 0 12px;
}

.form-group input[type=file] {
  opacity: 0;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
}

.form-check {
  margin-top: .65rem;
  padding-left: 0;
}

.form-check .form-check-label {
  display: inline-block;
  position: relative;
  cursor: pointer;
  padding-left: 35px;
  line-height: 26px;
  margin-bottom: 0;
  transition: color 0.3s linear;
}

.radio .form-check-sign {
  padding-left: 28px;
}

.form-check .form-check-sign::before,
.form-check .form-check-sign::after {
  content: " ";
  display: inline-block;
  position: absolute;
  width: 26px;
  height: 26px;
  left: 0;
  cursor: pointer;
  border-radius: 3px;
  top: 0;
  background-color: transparent;
  border: 1px solid #E3E3E3;
  transition: opacity 0.3s linear;
}

.form-check .form-check-sign::after {
  font-family: 'Nucleo Outline';
  content: "\ea22";
  top: 0px;
  text-align: center;
  font-size: 14px;
  opacity: 0;
  color: #555555;
  border: 0;
  background-color: inherit;
}

.form-check.disabled .form-check-label,
.form-check.disabled .form-check-label {
  color: #9A9A9A;
  opacity: .5;
  cursor: not-allowed;
}

.form-check input[type="checkbox"],
.radio input[type="radio"] {
  opacity: 0;
  position: absolute;
  visibility: hidden;
}

.form-check input[type="checkbox"]:checked+.form-check-sign::after {
  opacity: 1;
}

.form-control input[type="checkbox"]:disabled+.form-check-sign::before,
.checkbox input[type="checkbox"]:disabled+.form-check-sign::after {
  cursor: not-allowed;
}

.form-check input[type="checkbox"]:disabled+.form-check-sign,
.form-check input[type="radio"]:disabled+.form-check-sign {
  pointer-events: none;
}

.form-check-radio .form-check-sign::before,
.form-check-radio .form-check-sign::after {
  content: " ";
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 1px solid #E3E3E3;
  display: inline-block;
  position: absolute;
  left: 3px;
  top: 3px;
  padding: 1px;
  transition: opacity 0.3s linear;
}

.form-check-radio input[type="radio"]+.form-check-sign:after,
.form-check-radio input[type="radio"] {
  opacity: 0;
}

.form-check-radio input[type="radio"]:checked+.form-check-sign::after {
  width: 4px;
  height: 4px;
  background-color: #555555;
  border-color: #555555;
  top: 11px;
  left: 11px;
  opacity: 1;
}

.form-check-radio input[type="radio"]:checked+.form-check-sign::after {
  opacity: 1;
}

.form-check-radio input[type="radio"]:disabled+.form-check-sign {
  color: #9A9A9A;
}

.form-check-radio input[type="radio"]:disabled+.form-check-sign::before,
.form-check-radio input[type="radio"]:disabled+.form-check-sign::after {
  color: #9A9A9A;
}

.progress-container {
  position: relative;
}

.progress-container+.progress-container,
.progress-container~.progress-container {
  margin-top: 15px;
}

.progress-container .progress-badge {
  color: #888888;
  font-size: 0.8571em;
  text-transform: uppercase;
}

.progress-container .progress {
  height: 1px;
  border-radius: 0;
  box-shadow: none;
  background: rgba(222, 222, 222, 0.8);
  margin-top: 14px;
}

.progress-container .progress .progress-bar {
  box-shadow: none;
  background-color: #888888;
}

.progress-container .progress .progress-value {
  position: absolute;
  top: 2px;
  right: 0;
  color: #888888;
  font-size: 0.8571em;
}

.progress-container.progress-neutral .progress {
  background: rgba(255, 255, 255, 0.3);
}

.progress-container.progress-neutral .progress-bar {
  background: #FFFFFF;
}

.progress-container.progress-neutral .progress-value,
.progress-container.progress-neutral .progress-badge {
  color: #FFFFFF;
}

.progress-container.progress-primary .progress {
  background: rgba(249, 99, 50, 0.3);
}

.progress-container.progress-primary .progress-bar {
  background: #f96332;
}

.progress-container.progress-primary .progress-value,
.progress-container.progress-primary .progress-badge {
  color: #f96332;
}

.progress-container.progress-info .progress {
  background: rgba(44, 168, 255, 0.3);
}

.progress-container.progress-info .progress-bar {
  background: #2CA8FF;
}

.progress-container.progress-info .progress-value,
.progress-container.progress-info .progress-badge {
  color: #2CA8FF;
}

.progress-container.progress-success .progress {
  background: rgba(24, 206, 15, 0.3);
}

.progress-container.progress-success .progress-bar {
  background: #18ce0f;
}

.progress-container.progress-success .progress-value,
.progress-container.progress-success .progress-badge {
  color: #18ce0f;
}

.progress-container.progress-warning .progress {
  background: rgba(255, 178, 54, 0.3);
}

.progress-container.progress-warning .progress-bar {
  background: #FFB236;
}

.progress-container.progress-warning .progress-value,
.progress-container.progress-warning .progress-badge {
  color: #FFB236;
}

.progress-container.progress-danger .progress {
  background: rgba(255, 54, 54, 0.3);
}

.progress-container.progress-danger .progress-bar {
  background: #FF3636;
}

.progress-container.progress-danger .progress-value,
.progress-container.progress-danger .progress-badge {
  color: #FF3636;
}

/*           badges             */

.badge {
  border-radius: 8px;
  padding: 4px 8px;
  text-transform: uppercase;
  font-size: 0.7142em;
  line-height: 12px;
  background-color: transparent;
  border: 1px solid;
  margin-bottom: 5px;
  color: #FFFFFF;
  border-radius: 0.875rem;
}

.badge:hover,
.badge:focus {
  text-decoration: none;
}

.badge-icon {
  padding: 0.4em 0.55em;
}

.badge-icon i {
  font-size: 0.8em;
}

.badge-default,
.badge-default[href]:focus,
.badge-default[href]:hover {
  border-color: #888888;
  background-color: #888888;
  color: #FFFFFF;
}

.badge-primary,
.badge-primary[href]:focus,
.badge-primary[href]:hover {
  border-color: #f96332;
  background-color: #f96332;
  color: #FFFFFF;
}

.badge-info,
.badge-info[href]:focus,
.badge-info[href]:hover {
  border-color: #2CA8FF;
  background-color: #2CA8FF;
  color: #FFFFFF;
}

.badge-success,
.badge-success[href]:focus,
.badge-success[href]:hover {
  border-color: #18ce0f;
  background-color: #18ce0f;
  color: #FFFFFF;
}

.badge-warning,
.badge-warning[href]:focus,
.badge-warning[href]:hover {
  border-color: #FFB236;
  background-color: #FFB236;
  color: #FFFFFF;
}

.badge-danger,
.badge-danger[href]:focus,
.badge-danger[href]:hover {
  border-color: #FF3636;
  background-color: #FF3636;
  color: #FFFFFF;
}

.badge-neutral {
  color: inherit !important;
}

.badge-neutral,
.badge-neutral[href]:focus,
.badge-neutral[href]:hover {
  border-color: #FFFFFF;
  background-color: #FFFFFF;
  color: #FFFFFF;
}

.pagination .page-item .page-link {
  border: 0;
  border-radius: 30px !important;
  transition: all .3s;
  padding: 0px 11px;
  margin: 0 3px;
  min-width: 30px;
  text-align: center;
  box-shadow: none;
  height: 30px;
  line-height: 30px;
  color: #2c2c2c;
  cursor: pointer;
  font-size: 14px;
  text-transform: uppercase;
  background: transparent;
}

.pagination .page-item .page-link:hover,
.pagination .page-item .page-link:focus {
  color: #2c2c2c;
  background-color: rgba(222, 222, 222, 0.3);
  border: none;
}

.pagination .arrow-margin-left,
.pagination .arrow-margin-right {
  position: absolute;
}

.pagination .arrow-margin-right {
  right: 0;
}

.pagination .arrow-margin-left {
  left: 0;
}

.pagination .page-item.active>.page-link {
  color: #E3E3E3;
  box-shadow: 0px 5px 25px 0px rgba(0, 0, 0, 0.2);
}

.pagination .page-item.active>.page-link,
.pagination .page-item.active>.page-link:focus,
.pagination .page-item.active>.page-link:hover {
  background-color: #888888;
  border-color: #888888;
  color: #FFFFFF;
}

.pagination .page-item.disabled>.page-link {
  opacity: .5;
  background-color: rgba(255, 255, 255, 0.2);
  color: #FFFFFF;
}

.pagination.pagination-info .page-item.active>.page-link,
.pagination.pagination-info .page-item.active>.page-link:focus,
.pagination.pagination-info .page-item.active>.page-link:hover {
  background-color: #2CA8FF;
  border-color: #2CA8FF;
}

.pagination.pagination-success .page-item.active>.page-link,
.pagination.pagination-success .page-item.active>.page-link:focus,
.pagination.pagination-success .page-item.active>.page-link:hover {
  background-color: #18ce0f;
  border-color: #18ce0f;
}

.pagination.pagination-primary .page-item.active>.page-link,
.pagination.pagination-primary .page-item.active>.page-link:focus,
.pagination.pagination-primary .page-item.active>.page-link:hover {
  background-color: #f96332;
  border-color: #f96332;
}

.pagination.pagination-warning .page-item.active>.page-link,
.pagination.pagination-warning .page-item.active>.page-link:focus,
.pagination.pagination-warning .page-item.active>.page-link:hover {
  background-color: #FFB236;
  border-color: #FFB236;
}

.pagination.pagination-danger .page-item.active>.page-link,
.pagination.pagination-danger .page-item.active>.page-link:focus,
.pagination.pagination-danger .page-item.active>.page-link:hover {
  background-color: #FF3636;
  border-color: #FF3636;
}

.pagination.pagination-neutral .page-item>.page-link {
  color: #FFFFFF;
}

.pagination.pagination-neutral .page-item>.page-link:focus,
.pagination.pagination-neutral .page-item>.page-link:hover {
  background-color: rgba(255, 255, 255, 0.2);
  color: #FFFFFF;
}

.pagination.pagination-neutral .page-item.active>.page-link,
.pagination.pagination-neutral .page-item.active>.page-link:focus,
.pagination.pagination-neutral .page-item.active>.page-link:hover {
  background-color: #FFFFFF;
  border-color: #FFFFFF;
  color: #f96332;
}

.pagination-container {
  display: flex;
  align-items: center;
}

button,
input,
optgroup,
select,
textarea {
  font-family: "Montserrat", "Helvetica Neue", Arial, sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 400;
}

small {
  font-size: 60%;
}

a {
  color: #f96332;
}

a:hover,
a:focus {
  color: #f96332;
}

h1,
.h1 {
  font-size: 3.5em;
  line-height: 1.15;
  margin-bottom: 30px;
}

h1 small,
.h1 small {
  font-weight: 700;
  text-transform: uppercase;
  opacity: .8;
}

h2,
.h2 {
  font-size: 2.5em;
  margin-bottom: 30px;
}

h3,
.h3 {
  font-size: 1.825em;
  margin-bottom: 30px;
  line-height: 1.4em;
}

h4,
.h4 {
  font-size: 1.5em;
  line-height: 1.45em;
  margin-top: 30px;
  margin-bottom: 15px;
}

h4+.category,
h4.title+.category,
.h4+.category,
.h4.title+.category {
  margin-top: -5px;
}

h5,
.h5 {
  font-size: 1.3em;
  line-height: 1.4em;
  margin-bottom: 15px;
}

h5.category,
.h5.category {
  font-weight: 400;
}

h6,
.h6 {
  font-size: 0.9em;
  font-weight: 700;
  text-transform: uppercase;
}

p {
  line-height: 1.61em;
  font-weight: 300;
  font-size: 1.2em;
}

.title {
  font-weight: 700;
  padding-top: 30px;
}

.title.title-up {
  text-transform: uppercase;
}

.title.title-up a {
  color: #2c2c2c;
  text-decoration: none;
}

.title+.category {
  margin-top: -25px;
}

.description,
.card-description,
.footer-big p {
  color: #9A9A9A;
  font-weight: 300;
}

.category {
  text-transform: capitalize;
  font-weight: 700;
  color: #9A9A9A;
}

.text-primary {
  color: #f96332 !important;
}

.text-info {
  color: #2CA8FF !important;
}

.text-success {
  color: #18ce0f !important;
}

.text-warning {
  color: #FFB236 !important;
}

.text-danger {
  color: #FF3636 !important;
}

.text-black {
  color: #444;
}

.blockquote {
  border-left: none;
  border: 1px solid #888888;
  padding: 20px;
  font-size: 1.1em;
  line-height: 1.8;
}

.blockquote small {
  color: #888888;
  font-size: 0.8571em;
  text-transform: uppercase;
}

.blockquote.blockquote-primary {
  border-color: #f96332;
  color: #f96332;
}

.blockquote.blockquote-primary small {
  color: #f96332;
}

.blockquote.blockquote-danger {
  border-color: #FF3636;
  color: #FF3636;
}

.blockquote.blockquote-danger small {
  color: #FF3636;
}

.blockquote.blockquote-white {
  border-color: rgba(255, 255, 255, 0.8);
  color: #FFFFFF;
}

.blockquote.blockquote-white small {
  color: rgba(255, 255, 255, 0.8);
}

body {
  color: #2c2c2c;
  font-size: 14px;
  font-family: "Montserrat", "Helvetica Neue", Arial, sans-serif;
  overflow-x: hidden;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

.main {
  position: relative;
  background: #FFFFFF;
}

/* Animations */

.nav-pills .nav-link,
.nav-item .nav-link,
.navbar,
.nav-tabs .nav-link,
.tag,
.tag [data-role="remove"] {
  transition: all 300ms ease 0s;
}

.card a,
.bootstrap-switch-label:before {
  transition: all 150ms ease 0s;
}

.dropdown-toggle:after,
[data-toggle="collapse"][data-parent="#accordion"] i {
  transition: -webkit-transform 150ms ease 0s;
  transition: transform 150ms ease 0s;
  transition: transform 150ms ease 0s, -webkit-transform 150ms ease 0s;
}

.dropdown-toggle[aria-expanded="true"]:after,
[data-toggle="collapse"][data-parent="#accordion"][aria-expanded="true"] i {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2);
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.button-bar {
  display: block;
  position: relative;
  width: 22px;
  height: 1px;
  border-radius: 1px;
  background: #FFFFFF;
}

.button-bar+.button-bar {
  margin-top: 7px;
}

.button-bar:nth-child(2) {
  width: 17px;
}

.separator-line {
  height: 2px;
  width: 44px;
  background-color: #888888;
  margin: 20px auto;
}

.separator-line.separator-primary {
  background-color: #f96332;
}

.section-space {
  height: 62px;
  display: block;
}

.pull-left {
  float: left;
}

.pull-right {
  float: right;
}

.title-up {
  text-transform: uppercase;
}

.nav-pills.nav-pills-just-icons .nav-item .nav-link {
  text-align: center;
  border-radius: 50%;
  height: 80px;
  width: 80px;
  padding: 0;
  max-width: 80px;
  min-width: auto;
  margin-bottom: 4px;
}

.nav-pills.nav-pills-just-icons .nav-item .nav-link i {
  line-height: 80px;
}

.nav-pills:not(.flex-column) .nav-item:not(:last-child) .nav-link {
  margin-right: 19px;
}

.nav-pills .nav-item .nav-link {
  padding: 10px 23px;
  background-color: rgba(222, 222, 222, 0.3);
  min-width: 100px;
  font-weight: 400;
  text-align: center;
  color: #444;
}

.nav-pills .nav-item .nav-link:hover {
  background-color: rgba(222, 222, 222, 0.3);
}

.nav-pills .nav-item .nav-link.active,
.nav-pills .nav-item .nav-link.active:focus,
.nav-pills .nav-item .nav-link.active:hover {
  background-color: #9A9A9A;
  color: #FFFFFF;
  box-shadow: 0px 5px 35px 0px rgba(0, 0, 0, 0.3);
}

.nav-pills .nav-item .nav-link.disabled,
.nav-pills .nav-item .nav-link:disabled,
.nav-pills .nav-item .nav-link[disabled] {
  opacity: .5;
}

.nav-pills .nav-item i {
  display: block;
  line-height: 60px;
  font-size: 24px;
}

.nav-pills.nav-pills-neutral .nav-item .nav-link {
  background-color: rgba(255, 255, 255, 0.2);
  color: #FFFFFF;
}

.nav-pills.nav-pills-neutral .nav-item .nav-link.active,
.nav-pills.nav-pills-neutral .nav-item .nav-link.active:focus,
.nav-pills.nav-pills-neutral .nav-item .nav-link.active:hover {
  background-color: #FFFFFF;
  color: #f96332;
}

.nav-pills.nav-pills-primary .nav-item .nav-link.active,
.nav-pills.nav-pills-primary .nav-item .nav-link.active:focus,
.nav-pills.nav-pills-primary .nav-item .nav-link.active:hover {
  background-color: #f96332;
}

.nav-pills.nav-pills-info .nav-item .nav-link.active,
.nav-pills.nav-pills-info .nav-item .nav-link.active:focus,
.nav-pills.nav-pills-info .nav-item .nav-link.active:hover {
  background-color: #2CA8FF;
}

.nav-pills.nav-pills-success .nav-item .nav-link.active,
.nav-pills.nav-pills-success .nav-item .nav-link.active:focus,
.nav-pills.nav-pills-success .nav-item .nav-link.active:hover {
  background-color: #18ce0f;
}

.nav-pills.nav-pills-warning .nav-item .nav-link.active,
.nav-pills.nav-pills-warning .nav-item .nav-link.active:focus,
.nav-pills.nav-pills-warning .nav-item .nav-link.active:hover {
  background-color: #FFB236;
}

.nav-pills.nav-pills-danger .nav-item .nav-link.active,
.nav-pills.nav-pills-danger .nav-item .nav-link.active:focus,
.nav-pills.nav-pills-danger .nav-item .nav-link.active:hover {
  background-color: #FF3636;
}

.tab-space {
  padding: 20px 0 50px 0px;
}

.nav-align-center {
  text-align: center;
}

.nav-align-center .nav-pills {
  display: inline-flex;
}

/* --------------------------------

Nucleo Outline Web Font - nucleoapp.com/
License - nucleoapp.com/license/
Created using IcoMoon - icomoon.io

-------------------------------- */

@font-face {
  font-family: 'Nucleo Outline';
  src: url(/static/media/nucleo-outline.56814a6b.eot);
  src: url(/static/media/nucleo-outline.56814a6b.eot) format("embedded-opentype"), url(/static/media/nucleo-outline.802141dd.woff2) format("woff2"), url(/static/media/nucleo-outline.1a1036e6.woff) format("woff"), url(/static/media/nucleo-outline.86a09ad2.ttf) format("truetype");
  font-weight: normal;
  font-style: normal;
}

/*------------------------
	base class definition
-------------------------*/

.now-ui-icons {
  display: inline-block;
  font: normal normal normal 14px/1 'Nucleo Outline';
  font-size: inherit;
  speak: none;
  text-transform: none;
  /* Better Font Rendering */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/*------------------------
  change icon size
-------------------------*/

/*----------------------------------
  add a square/circle background
-----------------------------------*/

.now-ui-icons.circle {
  padding: 0.33333333em;
  vertical-align: -16%;
  background-color: #eee;
}

.now-ui-icons.circle {
  border-radius: 50%;
}

/*------------------------
  list icons
-------------------------*/

.nc-icon-ul {
  padding-left: 0;
  margin-left: 2.14285714em;
  list-style-type: none;
}

.nc-icon-ul>li {
  position: relative;
}

.nc-icon-ul>li>.now-ui-icons {
  position: absolute;
  left: -1.57142857em;
  top: 0.14285714em;
  text-align: center;
}

.nc-icon-ul>li>.now-ui-icons.circle {
  top: -0.19047619em;
  left: -1.9047619em;
}

/*------------------------
  spinning icons
-------------------------*/

.now-ui-icons.spin {
  -webkit-animation: nc-icon-spin 2s infinite linear;
  animation: nc-icon-spin 2s infinite linear;
}

@-webkit-keyframes nc-icon-spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes nc-icon-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

/*------------------------
  rotated/flipped icons
-------------------------*/

/*------------------------
	font icons
-------------------------*/

.now-ui-icons.ui-1_check:before {
  content: "\ea22";
}

.now-ui-icons.ui-1_email-85:before {
  content: "\ea2a";
}

.now-ui-icons.arrows-1_cloud-download-93:before {
  content: "\ea21";
}

.now-ui-icons.arrows-1_cloud-upload-94:before {
  content: "\ea24";
}

.now-ui-icons.arrows-1_minimal-down:before {
  content: "\ea39";
}

.now-ui-icons.arrows-1_minimal-left:before {
  content: "\ea3a";
}

.now-ui-icons.arrows-1_minimal-right:before {
  content: "\ea3b";
}

.now-ui-icons.arrows-1_minimal-up:before {
  content: "\ea3c";
}

.now-ui-icons.arrows-1_refresh-69:before {
  content: "\ea44";
}

.now-ui-icons.arrows-1_share-66:before {
  content: "\ea4c";
}

.now-ui-icons.business_badge:before {
  content: "\ea09";
}

.now-ui-icons.business_bank:before {
  content: "\ea0a";
}

.now-ui-icons.business_briefcase-24:before {
  content: "\ea13";
}

.now-ui-icons.business_bulb-63:before {
  content: "\ea15";
}

.now-ui-icons.business_chart-bar-32:before {
  content: "\ea1e";
}

.now-ui-icons.business_chart-pie-36:before {
  content: "\ea1f";
}

.now-ui-icons.business_globe:before {
  content: "\ea2f";
}

.now-ui-icons.business_money-coins:before {
  content: "\ea40";
}

.now-ui-icons.clothes_tie-bow:before {
  content: "\ea5b";
}

.now-ui-icons.design_vector:before {
  content: "\ea61";
}

.now-ui-icons.design_app:before {
  content: "\ea08";
}

.now-ui-icons.design_bullet-list-67:before {
  content: "\ea14";
}

.now-ui-icons.design_image:before {
  content: "\ea33";
}

.now-ui-icons.design_palette:before {
  content: "\ea41";
}

.now-ui-icons.design_scissors:before {
  content: "\ea4a";
}

.now-ui-icons.design-2_html5:before {
  content: "\ea32";
}

.now-ui-icons.design-2_ruler-pencil:before {
  content: "\ea48";
}

.now-ui-icons.emoticons_satisfied:before {
  content: "\ea49";
}

.now-ui-icons.files_box:before {
  content: "\ea12";
}

.now-ui-icons.files_paper:before {
  content: "\ea43";
}

.now-ui-icons.files_single-copy-04:before {
  content: "\ea52";
}

.now-ui-icons.health_ambulance:before {
  content: "\ea07";
}

.now-ui-icons.loader_gear:before {
  content: "\ea4e";
}

.now-ui-icons.loader_refresh:before {
  content: "\ea44";
}

.now-ui-icons.location_bookmark:before {
  content: "\ea10";
}

.now-ui-icons.location_compass-05:before {
  content: "\ea25";
}

.now-ui-icons.location_map-big:before {
  content: "\ea3d";
}

.now-ui-icons.location_pin:before {
  content: "\ea47";
}

.now-ui-icons.location_world:before {
  content: "\ea63";
}

.now-ui-icons.media-1_album:before {
  content: "\ea02";
}

.now-ui-icons.media-1_button-pause:before {
  content: "\ea16";
}

.now-ui-icons.media-1_button-play:before {
  content: "\ea18";
}

.now-ui-icons.media-1_button-power:before {
  content: "\ea19";
}

.now-ui-icons.media-1_camera-compact:before {
  content: "\ea1c";
}

.now-ui-icons.media-2_note-03:before {
  content: "\ea3f";
}

.now-ui-icons.media-2_sound-wave:before {
  content: "\ea57";
}

.now-ui-icons.objects_diamond:before {
  content: "\ea29";
}

.now-ui-icons.objects_globe:before {
  content: "\ea2f";
}

.now-ui-icons.objects_key-25:before {
  content: "\ea38";
}

.now-ui-icons.objects_planet:before {
  content: "\ea46";
}

.now-ui-icons.objects_spaceship:before {
  content: "\ea55";
}

.now-ui-icons.objects_support-17:before {
  content: "\ea56";
}

.now-ui-icons.objects_umbrella-13:before {
  content: "\ea5f";
}

.now-ui-icons.education_agenda-bookmark:before {
  content: "\ea01";
}

.now-ui-icons.education_atom:before {
  content: "\ea0c";
}

.now-ui-icons.education_glasses:before {
  content: "\ea2d";
}

.now-ui-icons.education_hat:before {
  content: "\ea30";
}

.now-ui-icons.education_paper:before {
  content: "\ea42";
}

.now-ui-icons.shopping_bag-16:before {
  content: "\ea0d";
}

.now-ui-icons.shopping_basket:before {
  content: "\ea0b";
}

.now-ui-icons.shopping_box:before {
  content: "\ea11";
}

.now-ui-icons.shopping_cart-simple:before {
  content: "\ea1d";
}

.now-ui-icons.shopping_credit-card:before {
  content: "\ea28";
}

.now-ui-icons.shopping_delivery-fast:before {
  content: "\ea27";
}

.now-ui-icons.shopping_shop:before {
  content: "\ea50";
}

.now-ui-icons.shopping_tag-content:before {
  content: "\ea59";
}

.now-ui-icons.sport_trophy:before {
  content: "\ea5d";
}

.now-ui-icons.sport_user-run:before {
  content: "\ea60";
}

.now-ui-icons.tech_controller-modern:before {
  content: "\ea26";
}

.now-ui-icons.tech_headphones:before {
  content: "\ea31";
}

.now-ui-icons.tech_laptop:before {
  content: "\ea36";
}

.now-ui-icons.tech_mobile:before {
  content: "\ea3e";
}

.now-ui-icons.tech_tablet:before {
  content: "\ea58";
}

.now-ui-icons.tech_tv:before {
  content: "\ea5e";
}

.now-ui-icons.tech_watch-time:before {
  content: "\ea62";
}

.now-ui-icons.text_align-center:before {
  content: "\ea05";
}

.now-ui-icons.text_align-left:before {
  content: "\ea06";
}

.now-ui-icons.text_bold:before {
  content: "\ea0e";
}

.now-ui-icons.text_caps-small:before {
  content: "\ea1b";
}

.now-ui-icons.gestures_tap-01:before {
  content: "\ea5a";
}

.now-ui-icons.transportation_air-baloon:before {
  content: "\ea03";
}

.now-ui-icons.transportation_bus-front-12:before {
  content: "\ea17";
}

.now-ui-icons.travel_info:before {
  content: "\ea04";
}

.now-ui-icons.travel_istanbul:before {
  content: "\ea34";
}

.now-ui-icons.ui-1_bell-53:before {
  content: "\ea0f";
}

.now-ui-icons.ui-1_calendar-60:before {
  content: "\ea1a";
}

.now-ui-icons.ui-1_lock-circle-open:before {
  content: "\ea35";
}

.now-ui-icons.ui-1_send:before {
  content: "\ea4d";
}

.now-ui-icons.ui-1_settings-gear-63:before {
  content: "\ea4e";
}

.now-ui-icons.ui-1_simple-add:before {
  content: "\ea4f";
}

.now-ui-icons.ui-1_simple-delete:before {
  content: "\ea54";
}

.now-ui-icons.ui-1_simple-remove:before {
  content: "\ea53";
}

.now-ui-icons.ui-1_zoom-bold:before {
  content: "\ea64";
}

.now-ui-icons.ui-2_chat-round:before {
  content: "\ea20";
}

.now-ui-icons.ui-2_favourite-28:before {
  content: "\ea2b";
}

.now-ui-icons.ui-2_like:before {
  content: "\ea37";
}

.now-ui-icons.ui-2_settings-90:before {
  content: "\ea4b";
}

.now-ui-icons.ui-2_time-alarm:before {
  content: "\ea5c";
}

.now-ui-icons.users_circle-08:before {
  content: "\ea23";
}

.now-ui-icons.users_single-02:before {
  content: "\ea51";
}

.all-icons .font-icon-detail {
  text-align: center;
  padding: 45px 0px 30px;
  border: 1px solid #e5e5e5;
  border-radius: 0.1875rem;
  margin: 15px 0;
  min-height: 168px;
}

.all-icons [class*="now-ui-icons"] {
  font-size: 32px;
}

.all-icons .font-icon-detail p {
  margin: 25px auto 0;
  width: 100%;
  text-align: center;
  display: block;
  color: #B8B8B8;
  padding: 0 10px;
  font-size: 0.7142em;
}

.nav-tabs {
  border: 0;
  padding: 15px 0.7rem;
}

.nav-tabs:not(.nav-tabs-neutral)>.nav-item>.nav-link.active {
  box-shadow: 0px 5px 35px 0px rgba(0, 0, 0, 0.3);
}

.card .nav-tabs {
  border-top-right-radius: 0.1875rem;
  border-top-left-radius: 0.1875rem;
}

.nav-tabs>.nav-item>.nav-link {
  color: #888888;
  margin: 0;
  margin-right: 5px;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 30px;
  font-size: 14px;
  padding: 11px 23px;
  line-height: 1.5;
}

.nav-tabs>.nav-item>.nav-link:hover {
  background-color: transparent;
}

.nav-tabs>.nav-item>.nav-link.active {
  background-color: #444;
  border-radius: 30px;
  color: #FFFFFF;
}

.nav-tabs>.nav-item>.nav-link i.now-ui-icons {
  font-size: 14px;
  position: relative;
  top: 1px;
  margin-right: 3px;
}

.nav-tabs>.nav-item.disabled>.nav-link,
.nav-tabs>.nav-item.disabled>.nav-link:hover {
  color: rgba(255, 255, 255, 0.5);
}

.nav-tabs.nav-tabs-neutral>.nav-item>.nav-link {
  color: #FFFFFF;
}

.nav-tabs.nav-tabs-neutral>.nav-item>.nav-link.active {
  background-color: rgba(255, 255, 255, 0.2);
  color: #FFFFFF;
}

.nav-tabs.nav-tabs-primary>.nav-item>.nav-link.active {
  background-color: #f96332;
}

.nav-tabs.nav-tabs-info>.nav-item>.nav-link.active {
  background-color: #2CA8FF;
}

.nav-tabs.nav-tabs-danger>.nav-item>.nav-link.active {
  background-color: #FF3636;
}

.nav-tabs.nav-tabs-warning>.nav-item>.nav-link.active {
  background-color: #FFB236;
}

.nav-tabs.nav-tabs-success>.nav-item>.nav-link.active {
  background-color: #18ce0f;
}

.navbar {
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;
  min-height: 53px;
  margin-bottom: 20px;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.15);
}

.navbar a {
  vertical-align: middle;
}

.navbar a:not(.btn):not(.dropdown-item) {
  color: #FFFFFF;
}

.navbar p {
  display: inline-block;
  margin: 0;
  line-height: 21px;
  font-weight: inherit;
  font-size: inherit;
}

.navbar .navbar-nav.navbar-logo {
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 49px;
  top: -4px;
}

.navbar .navbar-nav .nav-link.btn {
  padding: 11px 22px;
}

.navbar .navbar-nav .nav-link.btn.btn-lg {
  padding: 15px 48px;
}

.navbar .navbar-nav .nav-link.btn.btn-sm {
  padding: 5px 15px;
}

.navbar .navbar-nav .nav-link:not(.btn) {
  text-transform: uppercase;
  font-size: 0.7142em;
  padding: 0.5rem 0.7rem;
  line-height: 1.625rem;
}

.navbar .navbar-nav .nav-link:not(.btn) i.fab+p,
.navbar .navbar-nav .nav-link:not(.btn) i.now-ui-icons+p {
  margin-left: 3px;
}

.navbar .navbar-nav .nav-link:not(.btn) i.fab,
.navbar .navbar-nav .nav-link:not(.btn) i.now-ui-icons {
  font-size: 18px;
  position: relative;
  top: 3px;
  text-align: center;
  width: 21px;
}

.navbar .navbar-nav .nav-link:not(.btn) i.now-ui-icons {
  top: 4px;
  font-size: 16px;
}

.navbar .navbar-nav .nav-link:not(.btn).profile-photo .profile-photo-small {
  width: 27px;
  height: 27px;
}

.navbar .navbar-nav .nav-link:not(.btn).disabled {
  opacity: .5;
  color: #FFFFFF;
}

.navbar .navbar-nav .nav-item.active .nav-link:not(.btn),
.navbar .navbar-nav .nav-item .nav-link:not(.btn):focus,
.navbar .navbar-nav .nav-item .nav-link:not(.btn):hover,
.navbar .navbar-nav .nav-item .nav-link:not(.btn):active {
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 0.1875rem;
}

.navbar .logo-container {
  width: 27px;
  height: 27px;
  overflow: hidden;
  margin: 0 auto;
  border-radius: 50%;
  border: 1px solid transparent;
}

.navbar .navbar-brand {
  text-transform: uppercase;
  font-size: 0.8571em;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  line-height: 1.625rem;
}

.navbar .navbar-toggler {
  width: 37px;
  height: 27px;
  outline: 0;
  cursor: pointer;
}

.navbar .navbar-toggler.navbar-toggler-left {
  position: relative;
  left: 0;
  padding-left: 0;
}

.navbar .navbar-toggler .navbar-toggler-bar.middle-bar {
  width: 17px;
  transition: width .2s linear;
}

.navbar .navbar-toggler:hover .navbar-toggler-bar.middle-bar {
  width: 22px;
}

.navbar .button-dropdown .navbar-toggler-bar:nth-child(2) {
  width: 17px;
}

.navbar.navbar-transparent {
  background-color: transparent !important;
  box-shadow: none;
  color: #FFFFFF;
  padding-top: 20px !important;
}

.navbar.bg-white:not(.navbar-transparent) a:not(.dropdown-item):not(.btn) {
  color: #888888;
}

.navbar.bg-white:not(.navbar-transparent) a:not(.dropdown-item):not(.btn).disabled {
  opacity: .5;
  color: #888888;
}

.navbar.bg-white:not(.navbar-transparent) .button-bar {
  background: #888888;
}

.navbar.bg-white:not(.navbar-transparent) .nav-item.active .nav-link:not(.btn),
.navbar.bg-white:not(.navbar-transparent) .nav-item .nav-link:not(.btn):focus,
.navbar.bg-white:not(.navbar-transparent) .nav-item .nav-link:not(.btn):hover,
.navbar.bg-white:not(.navbar-transparent) .nav-item .nav-link:not(.btn):active {
  background-color: rgba(222, 222, 222, 0.3);
}

.navbar.bg-white:not(.navbar-transparent) .logo-container {
  border: 1px solid #888888;
}

.bg-default {
  background-color: #888888 !important;
}

.bg-primary {
  background-color: #f96332 !important;
}

.bg-info {
  background-color: #2CA8FF !important;
}

.bg-success {
  background-color: #18ce0f !important;
}

.bg-danger {
  background-color: #FF3636 !important;
}

.bg-warning {
  background-color: #FFB236 !important;
}

.bg-white {
  background-color: #FFFFFF !important;
}

.dropdown-menu {
  border: 0;
  box-shadow: 0px 10px 50px 0px rgba(0, 0, 0, 0.2);
  border-radius: 0.125rem;
  transition: all 150ms linear;
  font-size: 14px;
}

.dropdown-menu.dropdown-menu-right:before {
  left: auto;
  right: 10px;
}

.dropdown .dropdown-menu {
  -webkit-transform: translate3d(0, -25px, 0) !important;
  transform: translate3d(0, -25px, 0) !important;
  visibility: hidden;
  display: block;
  opacity: 0;
  filter: alpha(opacity=0);
  top: 100% !important;
}

.dropdown.show .dropdown-menu,
.dropdown-menu.open {
  opacity: 1;
  filter: alpha(opacity=100);
  visibility: visible;
  -webkit-transform: translate3d(0, 0px, 0) !important;
  transform: translate3d(0, 0px, 0) !important;
}

.dropdown-menu .dropdown-item,
.bootstrap-select .dropdown-menu.inner li a {
  font-size: 0.8571em;
  padding-top: .6rem;
  padding-bottom: .6rem;
  margin-top: 5px;
  transition: all 150ms linear;
  outline: none;
}

.dropdown-menu .dropdown-item:hover,
.dropdown-menu .dropdown-item:focus,
.bootstrap-select .dropdown-menu.inner li a:hover,
.bootstrap-select .dropdown-menu.inner li a:focus {
  background-color: rgba(222, 222, 222, 0.3);
}

.dropdown-menu .dropdown-item.disabled,
.dropdown-menu .dropdown-item:disabled,
.bootstrap-select .dropdown-menu.inner li a.disabled,
.bootstrap-select .dropdown-menu.inner li a:disabled {
  color: rgba(182, 182, 182, 0.6);
}

.dropdown-menu .dropdown-item.disabled:hover,
.dropdown-menu .dropdown-item.disabled:focus,
.dropdown-menu .dropdown-item:disabled:hover,
.dropdown-menu .dropdown-item:disabled:focus,
.bootstrap-select .dropdown-menu.inner li a.disabled:hover,
.bootstrap-select .dropdown-menu.inner li a.disabled:focus,
.bootstrap-select .dropdown-menu.inner li a:disabled:hover,
.bootstrap-select .dropdown-menu.inner li a:disabled:focus {
  background-color: transparent;
}

.dropdown-menu:before {
  display: inline-block;
  position: absolute;
  width: 0;
  height: 0;
  vertical-align: middle;
  content: "";
  top: -5px;
  left: 10px;
  right: auto;
  color: #FFFFFF;
  border-bottom: .4em solid;
  border-right: .4em solid transparent;
  border-left: .4em solid transparent;
}

.dropdown-menu .dropdown-divider {
  background-color: rgba(222, 222, 222, 0.5);
}

.dropdown-menu .dropdown-header:not([href]):not([tabindex]) {
  color: rgba(182, 182, 182, 0.6);
  font-size: 0.7142em;
  text-transform: uppercase;
  font-weight: 700;
}

.dropdown-menu.dropdown-primary {
  background-color: #f95823;
}

.dropdown-menu.dropdown-primary:before {
  color: #f95823;
}

.dropdown-menu.dropdown-primary .dropdown-header:not([href]):not([tabindex]) {
  color: rgba(255, 255, 255, 0.8);
}

.dropdown-menu.dropdown-primary .dropdown-item {
  color: #FFFFFF;
}

.dropdown-menu.dropdown-primary .dropdown-item:hover,
.dropdown-menu.dropdown-primary .dropdown-item:focus {
  background-color: rgba(255, 255, 255, 0.2);
}

.dropdown-menu.dropdown-primary .dropdown-divider {
  background-color: rgba(255, 255, 255, 0.2);
}

.dropdown-menu.dropdown-info {
  background-color: #1da2ff;
}

.dropdown-menu.dropdown-info:before {
  color: #1da2ff;
}

.dropdown-menu.dropdown-info .dropdown-header:not([href]):not([tabindex]) {
  color: rgba(255, 255, 255, 0.8);
}

.dropdown-menu.dropdown-info .dropdown-item {
  color: #FFFFFF;
}

.dropdown-menu.dropdown-info .dropdown-item:hover,
.dropdown-menu.dropdown-info .dropdown-item:focus {
  background-color: rgba(255, 255, 255, 0.2);
}

.dropdown-menu.dropdown-info .dropdown-divider {
  background-color: rgba(255, 255, 255, 0.2);
}

.dropdown-menu.dropdown-danger {
  background-color: #ff2727;
}

.dropdown-menu.dropdown-danger:before {
  color: #ff2727;
}

.dropdown-menu.dropdown-danger .dropdown-header:not([href]):not([tabindex]) {
  color: rgba(255, 255, 255, 0.8);
}

.dropdown-menu.dropdown-danger .dropdown-item {
  color: #FFFFFF;
}

.dropdown-menu.dropdown-danger .dropdown-item:hover,
.dropdown-menu.dropdown-danger .dropdown-item:focus {
  background-color: rgba(255, 255, 255, 0.2);
}

.dropdown-menu.dropdown-danger .dropdown-divider {
  background-color: rgba(255, 255, 255, 0.2);
}

.dropdown-menu.dropdown-success {
  background-color: #16c00e;
}

.dropdown-menu.dropdown-success:before {
  color: #16c00e;
}

.dropdown-menu.dropdown-success .dropdown-header:not([href]):not([tabindex]) {
  color: rgba(255, 255, 255, 0.8);
}

.dropdown-menu.dropdown-success .dropdown-item {
  color: #FFFFFF;
}

.dropdown-menu.dropdown-success .dropdown-item:hover,
.dropdown-menu.dropdown-success .dropdown-item:focus {
  background-color: rgba(255, 255, 255, 0.2);
}

.dropdown-menu.dropdown-success .dropdown-divider {
  background-color: rgba(255, 255, 255, 0.2);
}

.dropdown-menu.dropdown-warning {
  background-color: #ffac27;
}

.dropdown-menu.dropdown-warning:before {
  color: #ffac27;
}

.dropdown-menu.dropdown-warning .dropdown-header:not([href]):not([tabindex]) {
  color: rgba(255, 255, 255, 0.8);
}

.dropdown-menu.dropdown-warning .dropdown-item {
  color: #FFFFFF;
}

.dropdown-menu.dropdown-warning .dropdown-item:hover,
.dropdown-menu.dropdown-warning .dropdown-item:focus {
  background-color: rgba(255, 255, 255, 0.2);
}

.dropdown-menu.dropdown-warning .dropdown-divider {
  background-color: rgba(255, 255, 255, 0.2);
}

.button-dropdown {
  padding-right: 0.7rem;
  cursor: pointer;
}

.button-dropdown .dropdown-toggle {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  display: block;
}

.button-dropdown .dropdown-toggle:after {
  display: none;
}

.alert {
  border: 0;
  border-radius: 0;
  color: #FFFFFF;
  padding-top: .9rem;
  padding-bottom: .9rem;
}

.alert .alert-link {
  color: #FFFFFF;
  opacity: .8;
}

.alert.alert-success {
  background-color: rgba(24, 206, 15, 0.8);
}

.alert.alert-danger {
  background-color: rgba(255, 54, 54, 0.8);
}

.alert.alert-warning {
  background-color: rgba(255, 178, 54, 0.8);
}

.alert.alert-info {
  background-color: rgba(44, 168, 255, 0.8);
}

.alert.alert-primary {
  background-color: rgba(249, 99, 50, 0.8);
}

.alert .alert-icon {
  display: block;
  float: left;
  margin-right: 15px;
  margin-top: -1px;
}

.alert strong {
  text-transform: uppercase;
  font-size: 12px;
}

.alert i.fa,
.alert i.now-ui-icons {
  font-size: 20px;
}

.alert .close {
  color: #FFFFFF;
  opacity: .9;
  text-shadow: none;
  line-height: 0;
  outline: 0;
  display: inline-block;
  cursor: pointer;
}

img {
  max-width: 100%;
  border-radius: 1px;
}

.img-raised {
  box-shadow: 0px 10px 25px 0px rgba(0, 0, 0, 0.3);
}

.popover {
  font-size: 14px;
  box-shadow: 0px 10px 50px 0px rgba(0, 0, 0, 0.2);
  border: none;
  line-height: 1.7;
  max-width: 240px;
}

.popover.bs-popover-top .arrow:before,
.popover.bs-popover-left .arrow:before,
.popover.bs-popover-right .arrow:before,
.popover.bs-popover-bottom .arrow:before {
  border-top-color: transparent;
  border-left-color: transparent;
  border-right-color: transparent;
  border-bottom-color: transparent;
}

.popover .popover-header {
  color: rgba(182, 182, 182, 0.6);
  font-size: 14px;
  text-transform: capitalize;
  font-weight: 600;
  margin: 0;
  margin-top: 5px;
  border: none;
  background-color: transparent;
}

.popover:before {
  display: none;
}

.popover.bs-tether-element-attached-top:after {
  border-bottom-color: #FFFFFF;
  top: -9px;
}

.popover.popover-primary {
  background-color: #f96332;
}

.popover.popover-primary .popover-body {
  color: #FFFFFF;
}

.popover.popover-primary.bs-popover-right .arrow:after {
  border-right-color: #f96332;
}

.popover.popover-primary.bs-popover-top .arrow:after {
  border-top-color: #f96332;
}

.popover.popover-primary.bs-popover-bottom .arrow:after {
  border-bottom-color: #f96332;
}

.popover.popover-primary.bs-popover-left .arrow:after {
  border-left-color: #f96332;
}

.popover.popover-primary .popover-header {
  color: #FFFFFF;
  opacity: .6;
}

.popover.popover-info {
  background-color: #2CA8FF;
}

.popover.popover-info .popover-body {
  color: #FFFFFF;
}

.popover.popover-info.bs-popover-right .arrow:after {
  border-right-color: #2CA8FF;
}

.popover.popover-info.bs-popover-top .arrow:after {
  border-top-color: #2CA8FF;
}

.popover.popover-info.bs-popover-bottom .arrow:after {
  border-bottom-color: #2CA8FF;
}

.popover.popover-info.bs-popover-left .arrow:after {
  border-left-color: #2CA8FF;
}

.popover.popover-info .popover-header {
  color: #FFFFFF;
  opacity: .6;
}

.popover.popover-warning {
  background-color: #FFB236;
}

.popover.popover-warning .popover-body {
  color: #FFFFFF;
}

.popover.popover-warning.bs-popover-right .arrow:after {
  border-right-color: #FFB236;
}

.popover.popover-warning.bs-popover-top .arrow:after {
  border-top-color: #FFB236;
}

.popover.popover-warning.bs-popover-bottom .arrow:after {
  border-bottom-color: #FFB236;
}

.popover.popover-warning.bs-popover-left .arrow:after {
  border-left-color: #FFB236;
}

.popover.popover-warning .popover-header {
  color: #FFFFFF;
  opacity: .6;
}

.popover.popover-danger {
  background-color: #FF3636;
}

.popover.popover-danger .popover-body {
  color: #FFFFFF;
}

.popover.popover-danger.bs-popover-right .arrow:after {
  border-right-color: #FF3636;
}

.popover.popover-danger.bs-popover-top .arrow:after {
  border-top-color: #FF3636;
}

.popover.popover-danger.bs-popover-bottom .arrow:after {
  border-bottom-color: #FF3636;
}

.popover.popover-danger.bs-popover-left .arrow:after {
  border-left-color: #FF3636;
}

.popover.popover-danger .popover-header {
  color: #FFFFFF;
  opacity: .6;
}

.popover.popover-success {
  background-color: #18ce0f;
}

.popover.popover-success .popover-body {
  color: #FFFFFF;
}

.popover.popover-success.bs-popover-right .arrow:after {
  border-right-color: #18ce0f;
}

.popover.popover-success.bs-popover-top .arrow:after {
  border-top-color: #18ce0f;
}

.popover.popover-success.bs-popover-bottom .arrow:after {
  border-bottom-color: #18ce0f;
}

.popover.popover-success.bs-popover-left .arrow:after {
  border-left-color: #18ce0f;
}

.popover.popover-success .popover-header {
  color: #FFFFFF;
  opacity: .6;
}

.tooltip.bs-tooltip-right .arrow:before {
  border-right-color: #FFFFFF;
}

.tooltip.bs-tooltip-top .arrow:before {
  border-top-color: #FFFFFF;
}

.tooltip.bs-tooltip-bottom .arrow:before {
  border-bottom-color: #FFFFFF;
}

.tooltip.bs-tooltip-left .arrow:before {
  border-left-color: #FFFFFF;
}

.tooltip-inner {
  padding: 0.5rem 0.7rem;
  min-width: 130px;
  background-color: #FFFFFF;
  font-size: 14px;
  color: inherit;
  box-shadow: 0px 5px 25px 0px rgba(0, 0, 0, 0.2);
}

.modal-content {
  border-radius: 0.1875rem;
  border: none;
  box-shadow: 0px 10px 50px 0px rgba(0, 0, 0, 0.5);
}

.modal-content .modal-header {
  border-bottom: none;
  padding-top: 24px;
  padding-right: 24px;
  padding-bottom: 0;
  padding-left: 24px;
}

.modal-content .modal-header button {
  position: absolute;
  right: 27px;
  top: 30px;
  outline: 0;
}

.modal-content .modal-header .title {
  margin-top: 5px;
  margin-bottom: 0;
}

.modal-content .modal-body {
  padding-top: 24px;
  padding-right: 24px;
  padding-bottom: 16px;
  padding-left: 24px;
  line-height: 1.9;
}

.modal-content .modal-footer {
  border-top: none;
  padding-right: 24px;
  padding-bottom: 16px;
  padding-left: 24px;
  /* Safari 6.1+ */
  justify-content: space-between;
}

.modal-content .modal-footer button {
  margin: 0;
  padding-left: 16px;
  padding-right: 16px;
  width: auto;
}

.modal-content .modal-footer button.pull-left {
  padding-left: 5px;
  padding-right: 5px;
  position: relative;
  left: -5px;
}

.modal-content .modal-body+.modal-footer {
  padding-top: 0;
}

.modal-backdrop {
  background: rgba(0, 0, 0, 0.3);
}

.modal.modal-mini p {
  text-align: center;
}

.modal.modal-mini .modal-dialog {
  max-width: 255px;
  margin: 0 auto;
}

.modal.modal-mini .modal-profile {
  width: 70px;
  height: 70px;
  background-color: #FFFFFF;
  border-radius: 50%;
  text-align: center;
  line-height: 5.7;
  box-shadow: 0px 5px 50px 0px rgba(0, 0, 0, 0.3);
}

.modal.modal-mini .modal-profile i {
  color: #f96332;
  font-size: 21px;
}

.modal.modal-mini .modal-profile[class*="modal-profile-"] i {
  color: #FFFFFF;
}

.modal.modal-mini .modal-profile.modal-profile-primary {
  background-color: #f96332;
}

.modal.modal-mini .modal-profile.modal-profile-danger {
  background-color: #FF3636;
}

.modal.modal-mini .modal-profile.modal-profile-warning {
  background-color: #FFB236;
}

.modal.modal-mini .modal-profile.modal-profile-success {
  background-color: #18ce0f;
}

.modal.modal-mini .modal-profile.modal-profile-info {
  background-color: #2CA8FF;
}

.modal.modal-mini .modal-footer button {
  text-transform: uppercase;
}

.modal.modal-mini .modal-footer button:first-child {
  opacity: .5;
}

.modal.modal-default .modal-content {
  background-color: #FFFFFF;
  color: #2c2c2c;
}

.modal.modal-default .modal-header .close {
  color: #2c2c2c;
}

.modal.modal-primary .modal-content {
  background-color: #f96332;
  color: #FFFFFF;
}

.modal.modal-primary .modal-header .close {
  color: #FFFFFF;
}

.modal.modal-danger .modal-content {
  background-color: #FF3636;
  color: #FFFFFF;
}

.modal.modal-danger .modal-header .close {
  color: #FFFFFF;
}

.modal.modal-warning .modal-content {
  background-color: #FFB236;
  color: #FFFFFF;
}

.modal.modal-warning .modal-header .close {
  color: #FFFFFF;
}

.modal.modal-success .modal-content {
  background-color: #18ce0f;
  color: #FFFFFF;
}

.modal.modal-success .modal-header .close {
  color: #FFFFFF;
}

.modal.modal-info .modal-content {
  background-color: #2CA8FF;
  color: #FFFFFF;
}

.modal.modal-info .modal-header .close {
  color: #FFFFFF;
}

.modal.show.modal-mini .modal-dialog {
  -webkit-transform: translate(0, 30%);
  transform: translate(0, 30%);
}

.modal .modal-header .close {
  color: #FF3636;
  text-shadow: none;
}

.modal .modal-header .close:hover,
.modal .modal-header .close:focus {
  opacity: 1;
}

.carousel-item-next,
.carousel-item-prev,
.carousel-item.active {
  display: block;
}

.carousel .carousel-inner {
  box-shadow: 0px 10px 25px 0px rgba(0, 0, 0, 0.3);
  border-radius: 0.1875rem;
}

.carousel .card {
  max-width: 650px;
  margin: 60px auto;
  display: block;
}

.footer {
  padding: 24px 0;
}

.footer.footer-default {
  background-color: #f2f2f2;
}

.footer nav {
  display: inline-block;
  float: left;
}

.footer ul {
  margin-bottom: 0;
  padding: 0;
  list-style: none;
}

.footer ul li {
  display: inline-block;
}

.footer ul li a {
  color: inherit;
  padding: 0.5rem;
  font-size: 0.8571em;
  text-transform: uppercase;
  text-decoration: none;
}

.footer ul li a:hover {
  text-decoration: none;
}

.footer .copyright {
  font-size: 0.8571em;
}

.footer:after {
  display: table;
  clear: both;
  content: " ";
}

.card {
  border: 0;
  border-radius: 0.1875rem;
  display: inline-block;
  position: relative;
  width: 100%;
  margin-bottom: 30px;
  box-shadow: 0px 5px 25px 0px rgba(0, 0, 0, 0.2);
}

.card .card-header {
  background-color: transparent;
  border-bottom: 0;
  background-color: transparent;
  border-radius: 0;
  padding: 0;
}

.card .card-footer {
  padding: 0;
  margin-top: 15px;
  background-color: transparent;
  border: 0;
}

.card[data-background-color="orange"] {
  background-color: #f96332;
}

.card[data-background-color="red"] {
  background-color: #FF3636;
}

.card[data-background-color="yellow"] {
  background-color: #FFB236;
}

.card[data-background-color="blue"] {
  background-color: #2CA8FF;
}

.card[data-background-color="green"] {
  background-color: #15b60d;
}

.card-plain {
  background: transparent;
  box-shadow: none;
}

.card-plain .card-body,
.card-plain .card-header {
  padding-left: 5px;
  padding-right: 5px;
}

.card-plain .card-header:after {
  width: 100%;
  left: 0;
}

.card-signup {
  max-width: 350px;
  margin: 0 auto;
}

.card-signup .card-header {
  margin: 0 20px;
  padding: 30px 0;
}

.card-signup .card-title {
  margin-top: 15px;
  margin-bottom: 15px;
}

.card-signup .card-footer {
  margin-bottom: 10px;
  margin-top: 24px;
  padding: 24px 0;
}

.card-signup .card-body {
  padding-top: 0px;
  padding-bottom: 0px;
  min-height: auto;
}

.card-signup .form-check,
.card-signup .form-check.form-check-radio {
  margin-top: 20px;
}

.card-signup .form-check label,
.card-signup .form-check.form-check-radio label {
  margin-left: 11px;
  color: initial;
  padding-left: 40px;
}

.card-signup .card-description {
  margin-top: 15px;
  margin-bottom: 20px;
}

.card-signup .social-line {
  margin-top: 20px;
  text-align: center;
}

.card-signup .social-line .btn.btn-icon,
.card-signup .social-line .btn.btn-icon .btn-icon {
  margin-left: 5px;
  margin-right: 5px;
  margin-top: 0;
  margin-bottom: 0;
  box-shadow: 0px 5px 50px 0px rgba(0, 0, 0, 0.2);
}

.index-page .page-header {
  height: 125vh;
}

.index-page .page-header .container>.content-center {
  top: 37%;
}

.index-page .page-header .category-absolute {
  position: absolute;
  top: 100vh;
  margin-top: -60px;
  padding: 0 15px;
  width: 100%;
  color: rgba(255, 255, 255, 0.5);
}

.landing-page .header {
  height: 100vh;
  position: relative;
}

.landing-page .header .container {
  padding-top: 26vh;
  color: #FFFFFF;
  z-index: 2;
  position: relative;
}

.landing-page .header .share {
  margin-top: 150px;
}

.landing-page .header h1 {
  font-weight: 600;
}

.landing-page .header .title {
  color: #FFFFFF;
}

.landing-page .section-team .team .team-player img {
  max-width: 100px;
}

.landing-page .section-team .team-player {
  margin-bottom: 15px;
}

.landing-page .section-contact-us .title {
  margin-bottom: 15px;
}

.landing-page .section-contact-us .description {
  margin-bottom: 30px;
}

.landing-page .section-contact-us .input-group,
.landing-page .section-contact-us .send-button,
.landing-page .section-contact-us .textarea-container {
  padding: 0 40px;
}

.landing-page .section-contact-us .textarea-container {
  margin: 40px 0;
}

.landing-page .section-contact-us a.btn {
  margin-top: 35px;
}

.profile-page .page-header {
  min-height: 550px;
}

.profile-page .profile-container {
  color: #FFFFFF;
}

.profile-page .photo-container {
  width: 123px;
  height: 123px;
  border-radius: 50%;
  overflow: hidden;
  margin: 0 auto;
  box-shadow: 0px 10px 25px 0px rgba(0, 0, 0, 0.3);
}

.profile-page .title {
  text-align: center;
  margin-top: 30px;
}

.profile-page .description,
.profile-page .category {
  text-align: center;
}

.profile-page h5.description {
  max-width: 700px;
  margin: 20px auto 75px;
}

.profile-page .nav-align-center {
  margin-top: 30px;
}

.profile-page .content {
  max-width: 450px;
  margin: 0 auto;
}

.profile-page .content .social-description {
  display: inline-block;
  max-width: 150px;
  width: 145px;
  text-align: center;
  margin: 15px 0 0px;
}

.profile-page .content .social-description h2 {
  margin-bottom: 15px;
}

.profile-page .button-container {
  text-align: center;
  margin-top: -106px;
}

.profile-page .collections img {
  margin-bottom: 30px;
}

.profile-page .gallery {
  margin-top: 45px;
  padding-bottom: 50px;
}

.section-full-page:after,
.section-full-page:before {
  display: block;
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 2;
}

.section-full-page:before {
  background-color: rgba(0, 0, 0, 0.5);
}

.section-full-page[filter-color="purple"]:after,
.section-full-page[filter-color="primary"]:after {
  background: rgba(227, 227, 227, 0.26);
  /* For browsers that do not support gradients */
  /* For Safari 5.1 to 6.0 */
  /* For Opera 11.1 to 12.0 */
  /* For Firefox 3.6 to 15 */
  background: linear-gradient(0deg, rgba(227, 227, 227, 0.26), rgba(249, 99, 50, 0.95));
  /* Standard syntax */
}

.section-full-page[data-image]:after {
  opacity: .5;
}

.section-full-page>.content,
.section-full-page>.footer {
  position: relative;
  z-index: 4;
}

.section-full-page>.content {
  min-height: calc(100vh - 80px);
}

.section-full-page .full-page-background {
  position: absolute;
  z-index: 1;
  height: 100%;
  width: 100%;
  display: block;
  top: 0;
  left: 0;
  background-size: cover;
  background-position: center center;
}

.section-full-page .footer nav>ul a:not(.btn),
.section-full-page .footer,
.section-full-page .footer .copyright a {
  color: #FFFFFF;
}

.login-page .page-header:before,
.profile-page .page-header:before {
  position: absolute;
  z-index: 0;
  width: 100%;
  height: 100%;
  display: block;
  left: 0;
  top: 0;
  content: "";
  background-color: rgba(0, 0, 0, 0.5);
}

.login-page .card-login {
  border-radius: 0.25rem;
  padding-bottom: 0.7rem;
  max-width: 320px;
}

.login-page .card-login .btn-wd {
  min-width: 180px;
}

.login-page .card-login .logo-container {
  width: 65px;
  margin: 0 auto;
  margin-bottom: 55px;
}

.login-page .card-login .logo-container img {
  width: 100%;
}

.login-page .card-login .input-group:last-child {
  margin-bottom: 40px;
}

.login-page .card-login.card-plain .form-control::-moz-placeholder {
  color: #ebebeb;
  opacity: 1;
  filter: alpha(opacity=100);
}

.login-page .card-login.card-plain .form-control:-moz-placeholder {
  color: #ebebeb;
  opacity: 1;
  filter: alpha(opacity=100);
}

.login-page .card-login.card-plain .form-control::-webkit-input-placeholder {
  color: #ebebeb;
  opacity: 1;
  filter: alpha(opacity=100);
}

.login-page .card-login.card-plain .form-control:-ms-input-placeholder {
  color: #ebebeb;
  opacity: 1;
  filter: alpha(opacity=100);
}

.login-page .card-login.card-plain .form-control {
  border-color: rgba(255, 255, 255, 0.5);
  color: #FFFFFF;
}

.login-page .card-login.card-plain .form-control:focus {
  border-color: #FFFFFF;
  background-color: transparent;
  color: #FFFFFF;
}

.login-page .card-login.card-plain .has-success:after,
.login-page .card-login.card-plain .has-danger:after {
  color: #FFFFFF;
}

.login-page .card-login.card-plain .has-danger .form-control {
  background-color: transparent;
}

.login-page .card-login.card-plain .input-group-text {
  background-color: transparent;
  border-color: rgba(255, 255, 255, 0.5);
  color: #FFFFFF;
}

.login-page .card-login.card-plain .input-group-focus .input-group-text {
  background-color: transparent;
  border-color: #FFFFFF;
  color: #FFFFFF;
}

.login-page .card-login.card-plain .form-group.no-border .form-control,
.login-page .card-login.card-plain .input-group.no-border .form-control {
  background-color: rgba(255, 255, 255, 0.1);
  color: #FFFFFF;
}

.login-page .card-login.card-plain .form-group.no-border .form-control:focus,
.login-page .card-login.card-plain .form-group.no-border .form-control:active,
.login-page .card-login.card-plain .form-group.no-border .form-control:active,
.login-page .card-login.card-plain .input-group.no-border .form-control:focus,
.login-page .card-login.card-plain .input-group.no-border .form-control:active,
.login-page .card-login.card-plain .input-group.no-border .form-control:active {
  background-color: rgba(255, 255, 255, 0.2);
  color: #FFFFFF;
}

.login-page .card-login.card-plain .form-group.no-border .form-control+.input-group-text,
.login-page .card-login.card-plain .input-group.no-border .form-control+.input-group-text {
  background-color: rgba(255, 255, 255, 0.1);
}

.login-page .card-login.card-plain .form-group.no-border .form-control+.input-group-text:focus,
.login-page .card-login.card-plain .form-group.no-border .form-control+.input-group-text:active,
.login-page .card-login.card-plain .form-group.no-border .form-control+.input-group-text:active,
.login-page .card-login.card-plain .input-group.no-border .form-control+.input-group-text:focus,
.login-page .card-login.card-plain .input-group.no-border .form-control+.input-group-text:active,
.login-page .card-login.card-plain .input-group.no-border .form-control+.input-group-text:active {
  background-color: rgba(255, 255, 255, 0.2);
  color: #FFFFFF;
}

.login-page .card-login.card-plain .form-group.no-border .form-control:focus+.input-group-text,
.login-page .card-login.card-plain .input-group.no-border .form-control:focus+.input-group-text {
  background-color: rgba(255, 255, 255, 0.2);
  color: #FFFFFF;
}

.login-page .card-login.card-plain .form-group.no-border .input-group-text,
.login-page .card-login.card-plain .input-group.no-border .input-group-text {
  background-color: rgba(255, 255, 255, 0.1);
  border: none;
  color: #FFFFFF;
}

.login-page .card-login.card-plain .form-group.no-border.input-group-focus .input-group-text,
.login-page .card-login.card-plain .input-group.no-border.input-group-focus .input-group-text {
  background-color: rgba(255, 255, 255, 0.2);
  color: #FFFFFF;
}

.login-page .card-login.card-plain .input-group-text,
.login-page .card-login.card-plain .form-group.no-border .input-group-text,
.login-page .card-login.card-plain .input-group.no-border .input-group-text {
  color: rgba(255, 255, 255, 0.8);
}

.login-page .link {
  font-size: 10px;
  color: #FFFFFF;
  text-decoration: none;
}

.section {
  padding: 70px 0;
  position: relative;
  background: #FFFFFF;
}

.section .row+.category {
  margin-top: 15px;
}

.section-navbars {
  padding-bottom: 0;
}

.section-full-screen {
  height: 100vh;
}

.section-signup {
  padding-top: 20vh;
}

.page-header {
  min-height: 100vh;
  max-height: 999px;
  padding: 0;
  color: #FFFFFF;
  position: relative;
  overflow: hidden;
}

.page-header>.content {
  margin-top: 12%;
  text-align: center;
  margin-bottom: 50px;
}

.page-header.page-header-small {
  min-height: 60vh;
  max-height: 440px;
}

.page-header:before {
  background-color: rgba(0, 0, 0, 0.3);
}

.page-header>.container {
  z-index: 2;
  padding-top: 12vh;
  padding-bottom: 40px;
}

.page-header .page-header-image {
  position: absolute;
  background-size: cover;
  background-position: center center;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.page-header .content-center {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 2;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
  color: #FFFFFF;
  padding: 0 15px;
  width: 100%;
  max-width: 880px;
}

.page-header footer {
  position: absolute;
  bottom: 0;
  width: 100%;
}

.page-header .container {
  height: 100%;
  z-index: 1;
  text-align: center;
  position: relative;
}

.page-header .category,
.page-header .description {
  color: rgba(255, 255, 255, 0.8);
}

.page-header:after,
.page-header:before {
  position: absolute;
  z-index: 0;
  width: 100%;
  height: 100%;
  display: block;
  left: 0;
  top: 0;
  content: "";
}

.clear-filter:after,
.clear-filter:before {
  display: none;
}

.clear-filter[filter-color="orange"] {
  background: rgba(44, 44, 44, 0.2);
  /* For browsers that do not support gradients */
  /* For Safari 5.1 to 6.0 */
  /* For Opera 11.1 to 12.0 */
  /* For Firefox 3.6 to 15 */
  background: linear-gradient(0deg, rgba(44, 44, 44, 0.2), rgb(11 0 104));
  /* Standard syntax */
}

.section-story-overview {
  padding: 50px 0;
}

.section-story-overview .image-container {
  height: 335px;
  position: relative;
  background-position: center center;
  background-size: cover;
  box-shadow: 0px 10px 25px 0px rgba(0, 0, 0, 0.3);
  border-radius: .25rem;
}

.section-story-overview .image-container+.category {
  padding-top: 15px;
}

.section-story-overview .image-container.image-right {
  z-index: 2;
}

.section-story-overview .image-container.image-right+h3.title {
  margin-top: 120px;
}

.section-story-overview .image-container.image-left {
  z-index: 1;
}

.section-story-overview .image-container:nth-child(2) {
  margin-top: 420px;
  margin-left: -105px;
}

.section-story-overview p.blockquote {
  width: 220px;
  min-height: 180px;
  text-align: left;
  position: absolute;
  top: 376px;
  right: 155px;
  z-index: 0;
}

.section-nucleo-icons .nucleo-container img {
  width: auto;
  left: 0;
  top: 0;
  height: 100%;
  position: absolute;
}

.section-nucleo-icons .nucleo-container {
  height: 335px;
  position: relative;
}

.section-nucleo-icons h5 {
  margin-bottom: 35px;
}

.section-nucleo-icons .icons-container {
  position: relative;
  max-width: 450px;
  height: 300px;
  max-height: 300px;
  margin: 0 auto;
}

.section-nucleo-icons .icons-container i {
  font-size: 34px;
  position: absolute;
  left: 0;
  top: 0;
}

.section-nucleo-icons .icons-container i:nth-child(1) {
  top: 5%;
  left: 7%;
}

.section-nucleo-icons .icons-container i:nth-child(2) {
  top: 28%;
  left: 24%;
}

.section-nucleo-icons .icons-container i:nth-child(3) {
  top: 40%;
}

.section-nucleo-icons .icons-container i:nth-child(4) {
  top: 18%;
  left: 62%;
}

.section-nucleo-icons .icons-container i:nth-child(5) {
  top: 74%;
  left: 3%;
}

.section-nucleo-icons .icons-container i:nth-child(6) {
  top: 36%;
  left: 44%;
  font-size: 65px;
  color: #f96332;
  padding: 1px;
}

.section-nucleo-icons .icons-container i:nth-child(7) {
  top: 59%;
  left: 26%;
}

.section-nucleo-icons .icons-container i:nth-child(8) {
  top: 60%;
  left: 69%;
}

.section-nucleo-icons .icons-container i:nth-child(9) {
  top: 72%;
  left: 47%;
}

.section-nucleo-icons .icons-container i:nth-child(10) {
  top: 88%;
  left: 27%;
}

.section-nucleo-icons .icons-container i:nth-child(11) {
  top: 31%;
  left: 80%;
}

.section-nucleo-icons .icons-container i:nth-child(12) {
  top: 88%;
  left: 68%;
}

.section-nucleo-icons .icons-container i:nth-child(13) {
  top: 5%;
  left: 81%;
}

.section-nucleo-icons .icons-container i:nth-child(14) {
  top: 58%;
  left: 90%;
}

.section-nucleo-icons .icons-container i:nth-child(15) {
  top: 6%;
  left: 40%;
}

.section-images {
  max-height: 670px;
  height: 670px;
}

.section-images .hero-images-container,
.section-images .hero-images-container-1,
.section-images .hero-images-container-2 {
  margin-top: -38vh;
}

.section-images .hero-images-container {
  max-width: 670px;
}

.section-images .hero-images-container-1 {
  max-width: 390px;
  position: absolute;
  top: 55%;
  right: 18%;
}

.section-images .hero-images-container-2 {
  max-width: 225px;
  position: absolute;
  top: 68%;
  right: 12%;
}

[data-background-color="orange"] {
  background-color: #e95e38;
}

[data-background-color="black"] {
  background-color: #2c2c2c;
}

[data-background-color]:not([data-background-color="gray"]) {
  color: #FFFFFF;
}

[data-background-color]:not([data-background-color="gray"]) .title,
[data-background-color]:not([data-background-color="gray"]) .social-description h2,
[data-background-color]:not([data-background-color="gray"]) p,
[data-background-color]:not([data-background-color="gray"]) p.blockquote,
[data-background-color]:not([data-background-color="gray"]) p.blockquote small {
  color: #FFFFFF;
}

[data-background-color]:not([data-background-color="gray"]) .separator {
  background-color: #FFFFFF;
}

[data-background-color]:not([data-background-color="gray"]) .navbar.bg-white p {
  color: #888888;
}

[data-background-color]:not([data-background-color="gray"]) h1,
[data-background-color]:not([data-background-color="gray"]) h2,
[data-background-color]:not([data-background-color="gray"]) h3,
[data-background-color]:not([data-background-color="gray"]) h4,
[data-background-color]:not([data-background-color="gray"]) h5,
[data-background-color]:not([data-background-color="gray"]) h6,
[data-background-color]:not([data-background-color="gray"]) a:not(.btn):not(.dropdown-item),
[data-background-color]:not([data-background-color="gray"]) .icons-container {
  color: #FFFFFF;
}

[data-background-color]:not([data-background-color="gray"]) .input-group-text,
[data-background-color]:not([data-background-color="gray"]) .form-group.no-border .input-group-text,
[data-background-color]:not([data-background-color="gray"]) .input-group.no-border .input-group-text {
  color: rgba(255, 255, 255, 0.8);
}

[data-background-color]:not([data-background-color="gray"]) .description,
[data-background-color]:not([data-background-color="gray"]) .social-description p {
  color: rgba(255, 255, 255, 0.8);
}

[data-background-color]:not([data-background-color="gray"]) p.blockquote {
  border-color: rgba(255, 255, 255, 0.2);
}

[data-background-color]:not([data-background-color="gray"]) .checkbox label::before,
[data-background-color]:not([data-background-color="gray"]) .checkbox label::after,
[data-background-color]:not([data-background-color="gray"]) .radio label::before,
[data-background-color]:not([data-background-color="gray"]) .radio label::after {
  border-color: rgba(255, 255, 255, 0.2);
}

[data-background-color]:not([data-background-color="gray"]) .checkbox label::after,
[data-background-color]:not([data-background-color="gray"]) .checkbox label,
[data-background-color]:not([data-background-color="gray"]) .radio label {
  color: #FFFFFF;
}

[data-background-color]:not([data-background-color="gray"]) .checkbox input[type="checkbox"]:disabled+label,
[data-background-color]:not([data-background-color="gray"]) .radio input[type="radio"]:disabled+label {
  color: #FFFFFF;
}

[data-background-color]:not([data-background-color="gray"]) .radio input[type="radio"]:not(:disabled):hover+label::after,
[data-background-color]:not([data-background-color="gray"]) .radio input[type="radio"]:checked+label::after {
  background-color: #FFFFFF;
  border-color: #FFFFFF;
}

[data-background-color]:not([data-background-color="gray"]) .form-control::-moz-placeholder {
  color: #ebebeb;
  opacity: 1;
  filter: alpha(opacity=100);
}

[data-background-color]:not([data-background-color="gray"]) .form-control:-moz-placeholder {
  color: #ebebeb;
  opacity: 1;
  filter: alpha(opacity=100);
}

[data-background-color]:not([data-background-color="gray"]) .form-control::-webkit-input-placeholder {
  color: #ebebeb;
  opacity: 1;
  filter: alpha(opacity=100);
}

[data-background-color]:not([data-background-color="gray"]) .form-control:-ms-input-placeholder {
  color: #ebebeb;
  opacity: 1;
  filter: alpha(opacity=100);
}

[data-background-color]:not([data-background-color="gray"]) .form-control {
  border-color: rgba(255, 255, 255, 0.5);
  color: #FFFFFF;
}

[data-background-color]:not([data-background-color="gray"]) .form-control:focus {
  border-color: #FFFFFF;
  background-color: transparent;
  color: #FFFFFF;
}

[data-background-color]:not([data-background-color="gray"]) .has-success:after,
[data-background-color]:not([data-background-color="gray"]) .has-danger:after {
  color: #FFFFFF;
}

[data-background-color]:not([data-background-color="gray"]) .has-danger .form-control {
  background-color: transparent;
}

[data-background-color]:not([data-background-color="gray"]) .input-group-text {
  background-color: transparent;
  border-color: rgba(255, 255, 255, 0.5);
  color: #FFFFFF;
}

[data-background-color]:not([data-background-color="gray"]) .input-group-focus .input-group-text {
  background-color: transparent;
  border-color: #FFFFFF;
  color: #FFFFFF;
}

[data-background-color]:not([data-background-color="gray"]) .form-group.no-border .form-control,
[data-background-color]:not([data-background-color="gray"]) .input-group.no-border .form-control {
  background-color: rgba(255, 255, 255, 0.1);
  color: #FFFFFF;
}

[data-background-color]:not([data-background-color="gray"]) .form-group.no-border .form-control:focus,
[data-background-color]:not([data-background-color="gray"]) .form-group.no-border .form-control:active,
[data-background-color]:not([data-background-color="gray"]) .form-group.no-border .form-control:active,
[data-background-color]:not([data-background-color="gray"]) .input-group.no-border .form-control:focus,
[data-background-color]:not([data-background-color="gray"]) .input-group.no-border .form-control:active,
[data-background-color]:not([data-background-color="gray"]) .input-group.no-border .form-control:active {
  background-color: rgba(255, 255, 255, 0.2);
  color: #FFFFFF;
}

[data-background-color]:not([data-background-color="gray"]) .form-group.no-border .form-control+.input-group-text,
[data-background-color]:not([data-background-color="gray"]) .input-group.no-border .form-control+.input-group-text {
  background-color: rgba(255, 255, 255, 0.1);
}

[data-background-color]:not([data-background-color="gray"]) .form-group.no-border .form-control+.input-group-text:focus,
[data-background-color]:not([data-background-color="gray"]) .form-group.no-border .form-control+.input-group-text:active,
[data-background-color]:not([data-background-color="gray"]) .form-group.no-border .form-control+.input-group-text:active,
[data-background-color]:not([data-background-color="gray"]) .input-group.no-border .form-control+.input-group-text:focus,
[data-background-color]:not([data-background-color="gray"]) .input-group.no-border .form-control+.input-group-text:active,
[data-background-color]:not([data-background-color="gray"]) .input-group.no-border .form-control+.input-group-text:active {
  background-color: rgba(255, 255, 255, 0.2);
  color: #FFFFFF;
}

[data-background-color]:not([data-background-color="gray"]) .form-group.no-border .form-control:focus+.input-group-text,
[data-background-color]:not([data-background-color="gray"]) .input-group.no-border .form-control:focus+.input-group-text {
  background-color: rgba(255, 255, 255, 0.2);
  color: #FFFFFF;
}

[data-background-color]:not([data-background-color="gray"]) .form-group.no-border .input-group-text,
[data-background-color]:not([data-background-color="gray"]) .input-group.no-border .input-group-text {
  background-color: rgba(255, 255, 255, 0.1);
  border: none;
  color: #FFFFFF;
}

[data-background-color]:not([data-background-color="gray"]) .form-group.no-border.input-group-focus .input-group-text,
[data-background-color]:not([data-background-color="gray"]) .input-group.no-border.input-group-focus .input-group-text {
  background-color: rgba(255, 255, 255, 0.2);
  color: #FFFFFF;
}

[data-background-color]:not([data-background-color="gray"]) .btn[class*="btn-outline-"] {
  background-color: transparent;
  border-color: rgba(255, 255, 255, 0.5);
  color: #FFFFFF;
}

[data-background-color]:not([data-background-color="gray"]) .btn[class*="btn-outline-"]:hover,
[data-background-color]:not([data-background-color="gray"]) .btn[class*="btn-outline-"]:focus,
[data-background-color]:not([data-background-color="gray"]) .btn[class*="btn-outline-"]:not(:disabled):not(.disabled):active,
[data-background-color]:not([data-background-color="gray"]) .btn[class*="btn-outline-"]:not(:disabled):not(.disabled).active,
[data-background-color]:not([data-background-color="gray"]) .btn[class*="btn-outline-"]:not(:disabled):not(.disabled):active:focus,
[data-background-color]:not([data-background-color="gray"]) .btn[class*="btn-outline-"]:not(:disabled):not(.disabled).active:focus,
[data-background-color]:not([data-background-color="gray"]) .btn[class*="btn-outline-"]:active:hover,
[data-background-color]:not([data-background-color="gray"]) .btn[class*="btn-outline-"].active:hover,
.show>[data-background-color]:not([data-background-color="gray"]) .btn[class*="btn-outline-"].dropdown-toggle,
.show>[data-background-color]:not([data-background-color="gray"]) .btn[class*="btn-outline-"].dropdown-toggle:focus,
.show>[data-background-color]:not([data-background-color="gray"]) .btn[class*="btn-outline-"].dropdown-toggle:hover {
  background-color: transparent;
  border-color: #FFFFFF;
  color: #FFFFFF;
}

[data-background-color]:not([data-background-color="gray"]) .nav-tabs>.nav-item>.nav-link i.now-ui-icons {
  color: #FFFFFF;
}

[data-background-color]:not([data-background-color="gray"]).section-nucleo-icons .icons-container i:nth-child(6) {
  color: #FFFFFF;
}

@media screen and (max-width: 991px) {
  .sidebar-collapse .navbar-collapse {
    position: fixed;
    display: block !important;
    top: 0;
    height: 100% !important;
    width: 300px;
    right: 0;
    z-index: 1032;
    visibility: visible;
    background-color: #999;
    overflow-y: visible;
    border-top: none;
    text-align: left;
    max-height: none !important;
    -webkit-transform: translate3d(300px, 0, 0);
    transform: translate3d(300px, 0, 0);
    transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
  }
  .sidebar-collapse .navbar-collapse:before {
    background: #f96332;
    /* For browsers that do not support gradients */
    /* For Safari 5.1 to 6.0 */
    /* For Opera 11.1 to 12.0 */
    /* For Firefox 3.6 to 15 */
    background: linear-gradient(#f96332 0%, #000 80%);
    /* Standard syntax (must be last) */
    opacity: 0.76;
    filter: alpha(opacity=76);
    display: block;
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: -1;
  }
  .sidebar-collapse .navbar-collapse .navbar-nav:not(.navbar-logo) .nav-link {
    margin: 0 1rem;
    margin-top: 0.3125rem;
  }
  .sidebar-collapse .navbar-collapse .navbar-nav:not(.navbar-logo) .nav-link:not(.btn) {
    color: #FFFFFF !important;
  }
  .sidebar-collapse .navbar-collapse .dropdown-menu .dropdown-item {
    color: #FFFFFF;
  }
  .sidebar-collapse.menu-on-left .navbar-translate {
    flex-direction: row-reverse;
  }
  .sidebar-collapse .navbar .navbar-nav {
    margin-top: 53px;
    position: relative;
    max-height: calc(100vh - 75px);
    min-height: 100%;
    overflow: auto;
  }
  .sidebar-collapse .navbar .navbar-nav.navbar-logo {
    top: 0;
    height: 53px;
  }
  .sidebar-collapse .navbar .dropdown.show .dropdown-menu,
  .sidebar-collapse .navbar .dropdown .dropdown-menu {
    background-color: transparent;
    border: 0;
    transition: none;
    box-shadow: none;
    width: auto;
    margin: 0 1rem;
    margin-bottom: 15px;
    padding-top: 0;
    height: 125px;
    overflow-y: scroll;
  }
  .sidebar-collapse .navbar .dropdown.show .dropdown-menu:before,
  .sidebar-collapse .navbar .dropdown .dropdown-menu:before {
    display: none;
  }
  .sidebar-collapse .navbar .dropdown .dropdown-item {
    padding-left: 2.5rem;
  }
  .sidebar-collapse .navbar .dropdown .dropdown-menu {
    display: none;
  }
  .sidebar-collapse .navbar .dropdown.show .dropdown-menu {
    display: block;
  }
  .sidebar-collapse .navbar .dropdown-menu .dropdown-item:focus,
  .sidebar-collapse .navbar .dropdown-menu .dropdown-item:hover {
    color: #FFFFFF;
    border-radius: 0.1875rem;
  }
  .sidebar-collapse .navbar .navbar-translate {
    width: 100%;
    position: relative;
    display: flex;
    justify-content: space-between !important;
    align-items: center;
    -webkit-transform: translate3d(0px, 0, 0);
    transform: translate3d(0px, 0, 0);
    transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
  }
  .sidebar-collapse .navbar .navbar-toggler-bar {
    display: block;
    position: relative;
    width: 22px;
    height: 1px;
    border-radius: 1px;
    background: #FFFFFF;
  }
  .sidebar-collapse .navbar .navbar-toggler-bar+.navbar-toggler-bar {
    margin-top: 7px;
  }
  .sidebar-collapse .navbar .navbar-toggler-bar.bar2 {
    width: 17px;
    transition: width .2s linear;
  }
  .sidebar-collapse .navbar.bg-white:not(.navbar-transparent) .navbar-toggler-bar {
    background: #888888;
  }
  .sidebar-collapse .top-bar {
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
  .sidebar-collapse .middle-bar {
    opacity: 1;
  }
  .sidebar-collapse .bottom-bar {
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
  .sidebar-collapse .top-bar,
  .sidebar-collapse .middle-bar,
  .sidebar-collapse .bottom-bar {
    transition: all 0.2s;
  }
  .sidebar-collapse .toggled .top-bar {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
    -webkit-transform-origin: 0%;
            transform-origin: 0%;
  }
  .sidebar-collapse .toggled .bottom-bar {
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
    -webkit-transform-origin: 0%;
            transform-origin: 0%;
  }
  .sidebar-collapse .toggled .middle-bar {
    opacity: 0;
  }
  .sidebar-collapse [class*="navbar-expand-"] .navbar-collapse {
    width: 300px;
  }
  .sidebar-collapse .wrapper {
    transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
  }
  .sidebar-collapse #bodyClick {
    height: 100%;
    width: 100%;
    position: fixed;
    opacity: 1;
    top: 0;
    left: auto;
    right: 300px;
    content: "";
    z-index: 9999;
    overflow-x: hidden;
    background-color: transparent;
    transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
  }
  .sidebar-collapse.menu-on-left .navbar-collapse {
    right: auto;
    left: 0;
    -webkit-transform: translate3d(-300px, 0, 0);
    transform: translate3d(-300px, 0, 0);
  }
  .nav-open .sidebar-collapse .navbar-collapse {
    -webkit-transform: translate3d(0px, 0, 0);
    transform: translate3d(0px, 0, 0);
  }
  .nav-open .sidebar-collapse .wrapper {
    -webkit-transform: translate3d(-150px, 0, 0);
    transform: translate3d(-150px, 0, 0);
  }
  .nav-open .sidebar-collapse .navbar-translate {
    -webkit-transform: translate3d(-300px, 0, 0);
    transform: translate3d(-300px, 0, 0);
  }
  .nav-open .sidebar-collapse.menu-on-left .navbar-collapse {
    -webkit-transform: translate3d(0px, 0, 0);
    transform: translate3d(0px, 0, 0);
  }
  .nav-open .sidebar-collapse.menu-on-left .navbar-translate {
    -webkit-transform: translate3d(300px, 0, 0);
    transform: translate3d(300px, 0, 0);
  }
  .nav-open .sidebar-collapse.menu-on-left .wrapper {
    -webkit-transform: translate3d(150px, 0, 0);
    transform: translate3d(150px, 0, 0);
  }
  .nav-open .sidebar-collapse.menu-on-left #bodyClick {
    right: auto;
    left: 300px;
  }
  .navbar-nav .nav-link i.fab,
  .navbar-nav .nav-link i.now-ui-icons {
    opacity: .5;
  }
  .bootstrap-collapse .navbar .navbar-collapse {
    background: none !important;
  }
  .bootstrap-collapse.menu-on-left .navbar-translate {
    flex-direction: row-reverse;
  }
  .bootstrap-collapse .navbar .navbar-nav {
    margin-top: 53px;
    position: relative;
    max-height: calc(100vh - 75px);
    min-height: 100%;
    overflow: auto;
  }
  .bootstrap-collapse .navbar .navbar-nav.navbar-logo {
    top: 0;
    height: 53px;
  }
  .bootstrap-collapse .navbar .dropdown.show .dropdown-menu,
  .bootstrap-collapse .navbar .dropdown .dropdown-menu {
    background-color: transparent;
    border: 0;
    transition: none;
    box-shadow: none;
    width: auto;
    margin: 0 1rem;
    margin-bottom: 15px;
    padding-top: 0;
    height: 125px;
    overflow-y: scroll;
  }
  .bootstrap-collapse .navbar .dropdown.show .dropdown-menu:before,
  .bootstrap-collapse .navbar .dropdown .dropdown-menu:before {
    display: none;
  }
  .bootstrap-collapse .navbar .dropdown .dropdown-item {
    padding-left: 2.5rem;
  }
  .bootstrap-collapse .navbar .dropdown .dropdown-menu {
    display: none;
  }
  .bootstrap-collapse .navbar .dropdown.show .dropdown-menu {
    display: block;
  }
  .bootstrap-collapse .navbar .dropdown-menu .dropdown-item:focus,
  .bootstrap-collapse .navbar .dropdown-menu .dropdown-item:hover {
    color: #FFFFFF;
    border-radius: 0.1875rem;
  }
  .bootstrap-collapse .navbar .navbar-translate {
    width: 100%;
    position: relative;
    display: flex;
    justify-content: space-between !important;
    align-items: center;
    -webkit-transform: translate3d(0px, 0, 0);
    transform: translate3d(0px, 0, 0);
    transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
  }
  .bootstrap-collapse .navbar .navbar-toggler-bar {
    display: block;
    position: relative;
    width: 22px;
    height: 1px;
    border-radius: 1px;
    background: #FFFFFF;
  }
  .bootstrap-collapse .navbar .navbar-toggler-bar+.navbar-toggler-bar {
    margin-top: 7px;
  }
  .bootstrap-collapse .navbar .navbar-toggler-bar.bar2 {
    width: 17px;
    transition: width .2s linear;
  }
  .bootstrap-collapse .navbar.bg-white:not(.navbar-transparent) .navbar-toggler-bar {
    background: #888888;
  }
  .bootstrap-collapse .top-bar {
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
  .bootstrap-collapse .middle-bar {
    opacity: 1;
  }
  .bootstrap-collapse .bottom-bar {
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
  .bootstrap-collapse .top-bar,
  .bootstrap-collapse .middle-bar,
  .bootstrap-collapse .bottom-bar {
    transition: all 0.2s;
  }
  .bootstrap-collapse .toggled .top-bar {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
    -webkit-transform-origin: 0%;
            transform-origin: 0%;
  }
  .bootstrap-collapse .toggled .bottom-bar {
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
    -webkit-transform-origin: 0%;
            transform-origin: 0%;
  }
  .bootstrap-collapse .toggled .middle-bar {
    opacity: 0;
  }
  .profile-photo .profile-photo-small {
    margin-left: -2px;
  }
  .button-dropdown {
    display: none;
  }
  .section-nucleo-icons .container .row>[class*="col-"]:first-child {
    text-align: center;
  }
  .footer .copyright {
    text-align: right;
  }
  .section-nucleo-icons .icons-container {
    margin-top: 65px;
  }
  .navbar-nav .nav-link i.fa,
  .navbar-nav .nav-link i.now-ui-icons {
    opacity: .5;
  }
  .section-images {
    height: 500px;
    max-height: 500px;
  }
  .section-images .hero-images-container {
    max-width: 500px;
  }
  .section-images .hero-images-container-1 {
    right: 10%;
    top: 68%;
    max-width: 269px;
  }
  .section-images .hero-images-container-2 {
    right: 5%;
    max-width: 135px;
    top: 93%;
  }
}

@media screen and (min-width: 992px) {
  .navbar-collapse:not(.has-image) {
    background: transparent !important;
  }
  .burger-menu .navbar-collapse {
    position: fixed;
    display: block !important;
    top: 0;
    height: 100% !important;
    width: 300px;
    right: 0;
    z-index: 1032;
    visibility: visible;
    background-color: #999;
    overflow-y: visible;
    border-top: none;
    text-align: left;
    max-height: none !important;
    -webkit-transform: translate3d(300px, 0, 0);
    transform: translate3d(300px, 0, 0);
    transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
  }
  .burger-menu .navbar-collapse:before {
    background: #f96332;
    /* For browsers that do not support gradients */
    /* For Safari 5.1 to 6.0 */
    /* For Opera 11.1 to 12.0 */
    /* For Firefox 3.6 to 15 */
    background: linear-gradient(#f96332 0%, #000 80%);
    /* Standard syntax (must be last) */
    opacity: 0.76;
    filter: alpha(opacity=76);
    display: block;
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: -1;
  }
  .burger-menu .navbar-collapse .navbar-nav:not(.navbar-logo) .nav-link {
    margin: 0 1rem;
    margin-top: 0.3125rem;
  }
  .burger-menu .navbar-collapse .navbar-nav:not(.navbar-logo) .nav-link:not(.btn) {
    color: #FFFFFF !important;
  }
  .burger-menu .navbar-collapse .dropdown-menu .dropdown-item {
    color: #FFFFFF;
  }
  .burger-menu.menu-on-left .navbar-translate {
    flex-direction: row-reverse;
  }
  .burger-menu .navbar .navbar-nav {
    margin-top: 53px;
    position: relative;
    max-height: calc(100vh - 75px);
    min-height: 100%;
    overflow: auto;
  }
  .burger-menu .navbar .navbar-nav.navbar-logo {
    top: 0;
    height: 53px;
  }
  .burger-menu .navbar .dropdown.show .dropdown-menu,
  .burger-menu .navbar .dropdown .dropdown-menu {
    background-color: transparent;
    border: 0;
    transition: none;
    box-shadow: none;
    width: auto;
    margin: 0 1rem;
    margin-bottom: 15px;
    padding-top: 0;
    height: 125px;
    overflow-y: scroll;
  }
  .burger-menu .navbar .dropdown.show .dropdown-menu:before,
  .burger-menu .navbar .dropdown .dropdown-menu:before {
    display: none;
  }
  .burger-menu .navbar .dropdown .dropdown-item {
    padding-left: 2.5rem;
  }
  .burger-menu .navbar .dropdown .dropdown-menu {
    display: none;
  }
  .burger-menu .navbar .dropdown.show .dropdown-menu {
    display: block;
  }
  .burger-menu .navbar .dropdown-menu .dropdown-item:focus,
  .burger-menu .navbar .dropdown-menu .dropdown-item:hover {
    color: #FFFFFF;
    border-radius: 0.1875rem;
  }
  .burger-menu .navbar .navbar-translate {
    width: 100%;
    position: relative;
    display: flex;
    justify-content: space-between !important;
    align-items: center;
    -webkit-transform: translate3d(0px, 0, 0);
    transform: translate3d(0px, 0, 0);
    transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
  }
  .burger-menu .navbar .navbar-toggler-bar {
    display: block;
    position: relative;
    width: 22px;
    height: 1px;
    border-radius: 1px;
    background: #FFFFFF;
  }
  .burger-menu .navbar .navbar-toggler-bar+.navbar-toggler-bar {
    margin-top: 7px;
  }
  .burger-menu .navbar .navbar-toggler-bar.bar2 {
    width: 17px;
    transition: width .2s linear;
  }
  .burger-menu .navbar.bg-white:not(.navbar-transparent) .navbar-toggler-bar {
    background: #888888;
  }
  .burger-menu .top-bar {
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
  .burger-menu .middle-bar {
    opacity: 1;
  }
  .burger-menu .bottom-bar {
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
  .burger-menu .top-bar,
  .burger-menu .middle-bar,
  .burger-menu .bottom-bar {
    transition: all 0.2s;
  }
  .burger-menu .toggled .top-bar {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
    -webkit-transform-origin: 0%;
            transform-origin: 0%;
  }
  .burger-menu .toggled .bottom-bar {
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
    -webkit-transform-origin: 0%;
            transform-origin: 0%;
  }
  .burger-menu .toggled .middle-bar {
    opacity: 0;
  }
  .burger-menu [class*="navbar-expand-"] .navbar-collapse {
    width: 300px;
  }
  .burger-menu .wrapper {
    transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
  }
  .burger-menu #bodyClick {
    height: 100%;
    width: 100%;
    position: fixed;
    opacity: 1;
    top: 0;
    left: auto;
    right: 300px;
    content: "";
    z-index: 9999;
    overflow-x: hidden;
    background-color: transparent;
    transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
  }
  .nav-open .burger-menu .navbar-collapse {
    -webkit-transform: translate3d(0px, 0, 0);
    transform: translate3d(0px, 0, 0);
  }
  .burger-menu .navbar-collapse {
    display: block !important;
  }
  .burger-menu .navbar-collapse .navbar-nav {
    margin-top: 53px;
    height: 100%;
    z-index: 2;
    position: relative;
    display: flex;
    flex-direction: column;
  }
  .burger-menu .navbar-collapse .navbar-nav .nav-item {
    margin: 0;
  }
  .burger-menu.menu-on-left .navbar-collapse {
    right: auto;
    left: 0;
    -webkit-transform: translate3d(-300px, 0, 0);
    transform: translate3d(-300px, 0, 0);
  }
  .burger-menu [class*="navbar-expand-"] .navbar-nav .dropdown-menu {
    position: static;
    float: none;
  }
  .burger-menu [class*="navbar-expand-"] .navbar-toggler {
    display: inline-block;
  }
  .burger-menu .section-navbars .navbar-collapse {
    display: none !important;
  }
  .nav-open .burger-menu.menu-on-left .navbar .navbar-translate {
    -webkit-transform: translate3d(300px, 0, 0);
    transform: translate3d(300px, 0, 0);
  }
  .nav-open .burger-menu .navbar .navbar-translate {
    -webkit-transform: translate3d(-300px, 0, 0);
    transform: translate3d(-300px, 0, 0);
  }
  .nav-open .burger-menu.menu-on-left .navbar-collapse {
    -webkit-transform: translate3d(0px, 0, 0);
    transform: translate3d(0px, 0, 0);
  }
  .nav-open .burger-menu.menu-on-left #bodyClick {
    right: auto;
    left: 300px;
  }
  .burger-menu.menu-on-left .navbar-brand {
    float: right;
    margin-right: 0;
    margin-left: 1rem;
  }
  .navbar-nav .nav-link.profile-photo {
    padding: 0;
    margin: 7px 0.7rem;
  }
  .navbar-nav .nav-link.btn:not(.btn-sm) {
    margin: 0;
  }
  .navbar-nav .nav-item:not(:last-child) {
    margin-right: 5px;
  }
  .section-nucleo-icons .icons-container {
    margin: 0 0 0 auto;
  }
  .dropdown-menu .dropdown-item {
    color: inherit;
    display: flex;
    align-items: center;
  }
  .dropdown-menu .dropdown-item i {
    margin-right: 5px;
    position: relative;
    top: 1px;
  }
  .footer .copyright {
    float: right;
  }
}

@media screen and (min-width: 768px) {
  .image-container.image-right {
    top: 80px;
    margin-left: -100px;
    margin-bottom: 130px;
  }
  .image-container.image-left {
    margin-right: -100px;
  }
}

@media screen and (max-width: 768px) {
  .image-container.image-left {
    margin-bottom: 220px;
  }
  .image-container.image-left p.blockquote {
    margin: 0 auto;
    position: relative;
    right: 0;
  }
  .nav-tabs {
    display: inline-block;
    width: 100%;
    padding-left: 100px;
    padding-right: 100px;
    text-align: center;
  }
  .nav-tabs .nav-item>.nav-link {
    margin-bottom: 5px;
  }
  .landing-page .section-story-overview .image-container:nth-child(2) {
    margin-left: 0;
    margin-bottom: 30px;
  }
}

@media screen and (max-width: 576px) {
  .navbar[class*='navbar-expand-'] .container {
    margin-left: 0;
    margin-right: 0;
  }
  .footer .copyright {
    text-align: center;
  }
  .section-nucleo-icons .icons-container i {
    font-size: 30px;
  }
  .section-nucleo-icons .icons-container i:nth-child(6) {
    font-size: 48px;
  }
  .page-header .container h6.category-absolute {
    width: 90%;
  }
}

@media screen and (min-width: 991px) and (max-width: 1200px) {
  .section-images .hero-images-container-1 {
    right: 9%;
    max-width: 370px;
  }
  .section-images .hero-images-container-2 {
    right: 2%;
    max-width: 216px;
  }
}

@media screen and (max-width: 768px) {
  .section-images {
    height: 300px;
    max-height: 300px;
  }
  .section-images .hero-images-container {
    max-width: 380px;
  }
  .section-images .hero-images-container-1 {
    right: 7%;
    top: 87%;
    max-width: 210px;
  }
  .section-images .hero-images-container-2 {
    right: 1%;
    max-width: 133px;
    top: 99%;
  }
}

@media screen and (max-width: 517px) {
  .alert .alert-icon {
    margin-top: 10px;
  }
}

@media screen and (min-width: 1200px) {
  .section-images .hero-images-container-1 {
    top: 51%;
    right: 21%;
  }
  .section-images .hero-images-container-2 {
    top: 66%;
    right: 14%;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  .section-images .hero-images-container,
  .section-images .hero-images-container-1,
  .section-images .hero-images-container-2 {
    margin-top: -15vh;
    margin-left: 80px;
  }
  .section-images .hero-images-container {
    max-width: 300px;
  }
  .section-images .hero-images-container-1 {
    right: 28%;
    top: 40%;
  }
  .section-images .hero-images-container-2 {
    right: 21%;
    top: 55%;
  }
  .index-page .category-absolute {
    top: 90vh;
  }
}

@media screen and (max-width: 580px) {
  .alert button.close {
    position: absolute;
    right: 11px;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
  }
}

@media only screen and (min-height: 950px) {
  .index-page .page-header .category-absolute {
    margin-top: -330px;
  }
}

/*# sourceMappingURL=kit-free.css.map */
@media all and (max-width: 1080px){
    
    .MuiSelect-select.MuiSelect-select{
        font-size: .9rem !important;
    }

    .MuiFormLabel-root {
        font-size: .9rem !important;
    }

    .MuiMenuItem-root, .MuiTypography-body1 {
        font-size: .9rem !important;
        padding-bottom: 4px;
        padding-top: 4px;
    }
    
    .MuiInputBase-input.MuiInputBase-input, .MuiInputBase-formControl, textarea{
        font-size: .9rem !important;
    }

    .MuiInputLabel-shrink {
        -webkit-transform: translate(0, 6.5px) scale(0.7) !important;
                transform: translate(0, 6.5px) scale(0.7) !important;
    }

    .error{
        font-size: 1.05em !important;
        padding: 10px 20px !important;
        margin-bottom: 20px !important;
    }

    .page_title{
        font-size: 1.25rem;
    }  
    
    .header_form {
        width:205px;
        margin:0px 35px 0px 0px;
    }

    .submit_but{
        padding: .29rem 1rem !important;
        margin: 6px 5px !important;
        font-size: .88rem !important;
        border-radius: 6px;
    }

    .MuiFab-root{
        width: 35px !important;
        height: 35px !important;
    }

    .my_icon{
        width: 20px !important;
        height: 20px !important;
    }

    .MuiTableCell-root {
        padding: 8px 10px 8px 10px !important;
        font-size: 0.88rem !important;
        line-height: 1.2rem !important;
    }

    .MuiDialogContent-root {
        flex: 1 1 auto;
        padding: 6px 16px !important;
    }

    .status_success, .status_waiting, .status_deny {
        font-size: .5rem !important;
        padding: 0.13rem 0.23rem !important;
        padding-top: 0.205rem !important;
    }
      

    .pagination_container{
        margin: 23px auto 38px auto;
      }
      
      .pagination li a {
          font-size: .88rem;
          padding:0.5rem .9rem;
      }
}



@media all and (max-width: 750px){
    
    .MuiSelect-select.MuiSelect-select{
        font-size: .87rem !important;
    }

    .MuiFormHelperText-root {
        font-size: 0.6rem !important;
    }

    .MuiTypography-h6 {
        font-size: 1.2rem !important;
    }

    .MuiFormLabel-root {
        font-size: .87rem !important;
    }

    .MuiMenuItem-root, .MuiTypography-body1 {
        font-size: .87rem !important;
        padding-bottom: 4px;
        padding-top: 4px;
    }

    .MuiGrid-spacing-xs-1 {
        height: -webkit-fit-content !important;
        height: -moz-fit-content !important;
        height: fit-content !important;
    }

    .MuiInputBase-input.MuiInputBase-input, .MuiInputBase-formControl, textarea{
        font-size: .87rem !important;
    }
    
    .MuiTableCell-root {
        padding: 8px 10px 8px 10px !important;
        font-size: 0.82rem !important;
        line-height: 1rem !important;
    }

    .page_title{
        font-size: 1.15rem;
    }  

    .header_form {
        width:185px;
        margin:0px 15px 0px 0px;
    }

    .MuiFab-root {
        width: 33px !important;
        height: 33px !important;
    }

    .status_success, .status_waiting, .status_deny {
        font-size: .46rem !important;
        padding: 0.15rem 0.2rem !important;
        padding-top: 0.15rem !important;
    }


    .MuiDialog-paper {
        margin: 20px !important;
    }

    .MuiDialog-paperFullWidth {
        width: 100% !important;
    }

    .MuiDialogTitle-root {
        padding: 7px 9px !important;
    }

    .MuiDialogContent-root {
        flex: 1 1 auto;
        padding: 6px 12px !important;
    }
      
    .dialogBottom {
        margin: 3px 7px !important;
    }

    .my_icon{
        width: 17px !important;
        height: 17px !important;
    }

    .submit_but{
        padding: .24rem 1rem !important;
        font-size: .85rem !important;
    }

    .pagination_container{
        margin: 21px auto 34px auto;
      }
      
      .pagination li a {
          font-size: .85rem;
          padding:0.45rem .85rem;
      }
    
}





@media all and (max-width: 580px){
    
    .header_form {
        width:180px;
        margin:0px 6px 0px 0px !important;
    }

    .submit_but{
        padding: .25rem .9rem !important;
        font-size: .83rem !important;
    }
}

@media all and (max-width: 549px){
    
    
    .MuiSelect-select.MuiSelect-select{
        font-size: .75rem !important;
    }

    .MuiFormLabel-root {
        font-size: .75rem !important;
    }

    .MuiMenuItem-root, .MuiTypography-body1 {
        font-size: .75rem !important;
        padding-bottom: 2px !important;
        padding-top: 2px !important;
        min-height: 28px !important;
    }

    .MuiGrid-spacing-xs-1 {
        margin: 0px !important;
    }

    .MuiGrid-spacing-xs-1 > .MuiGrid-item {
        margin-right: 2px !important;
      }

    .MuiInputBase-input.MuiInputBase-input, .MuiInputBase-formControl, textarea{
        font-size: .75rem !important;
    }

    .MuiDialogContent-root {
        flex: 1 1 auto;
        padding: 6px 7px !important;
    }

    .MuiTypography-h6 {
        font-size: 1.1rem !important;
    }
    
    .page_title{
        font-size: 1rem;
        margin-bottom: 5px;
    }  

    .three_forms_header_container .page_title , .references_header_container .page_title{
        font-size: 1.1rem !important;
        margin-bottom: 5px;
    }  

    .MuiTableCell-root {
        padding: 8px 10px 8px 10px !important;
        font-size: 0.75rem !important;
    }

    .MuiFab-root {
        width: 30px !important;
        height: 30px !important;
    }

    .MuiDialog-paper {
        margin: 8px !important;
    }

    .closeSharpIcon{
        width: 1.4rem !important;
        height: 1.4rem !important;
    }
    
    .checkIcon{
        width: 1.4rem !important;
        height: 1.4rem !important;
    }
    
    .editIcon{
      font-size: 1.3rem !important;
    }

    .dialogBottom {
        flex-direction: column;
    }

    .dialogBottom div:nth-child(1) {
        margin-bottom: 5px;
    }
    
    .dialogBottom div:nth-child(2) {
        width: 100%;
    }


    .status_success, .status_waiting, .status_deny {
        font-size: .4rem !important;
        padding: 0.12rem 0.18rem !important;
        padding-top: 0.12rem !important;
    }

    .error{
        font-size: .89em !important;
        padding: 7px 20px !important;
        margin-bottom: 15px !important;
    }

    .submit_but{
        padding: .25rem .8rem !important;
        margin: 3px 2px !important;
        font-size: .75rem !important;
    }

    .my_icon{
        width: 16.5px !important;
        height: 16.5px !important;
    }
      
    .dialogBottom {
        margin: 1px 4px !important;
    }
      
    .pagination_container{
        margin: 20px auto 30px auto;
      }
      
      .pagination li a {
          font-size: .8rem;
          padding:0.4rem .8rem;
      }
}



@media all and (max-width: 445px){
    
    .header_form {
        width:150px;
        margin:0px 10px 0px 0px;
    }

    .three_forms_header_container .search_filter_container .header_form:nth-child(2){
        margin: 0 !important;
    }

    .three_forms_header_container .page_title, .references_header_container .page_title{
        font-size: 1rem !important;
        margin-bottom: 5px;
    }  

    .page_title{
        font-size: .9rem;
        margin-bottom: 5px;
    }  

    .MuiFab-root {
        width: 29px !important;
        height: 29px !important;
    }

    .my_icon{
        width: 15.5px !important;
        height: 15.5px !important;
    }

    .MuiTableCell-root {
        padding: 8px 10px 8px 10px !important;
        font-size: 0.73rem !important;
    }

    .submit_but{
        padding: .22rem .72rem !important;
        font-size: .74rem !important;
    }

    .pagination_container{
        margin: 19px auto 28px auto;
      }
      
      .pagination li a {
          font-size: .76rem;
          padding:0.4rem .8rem;
      }
}




@media all and (max-width: 379px){
    
    .three_forms_header_container{
        margin-top: 10px !important;
        margin-bottom: 10px !important;
        padding: 0 11px 5px 8px !important;
    }

    .search_filter_container{
        width: 100% !important;
    }

    .page_title{
        height: 100% !important;
    } 

    .header_form {
        width:158px;
        margin:0 2px !important;
    }

    .MuiFab-root {
        width: 28px !important;
        height: 28px !important;
    }

    .MuiTypography-h6 {
        font-size: .95rem !important;
    }

    .MuiTableCell-root {
        padding: 8px 10px 8px 10px !important;
        font-size: 0.7rem !important;
    }
}
.authentication_page_container{
    background-color: #f7f7f7;
    margin: 0;
    padding-top: 20px ;
    width: 100vw;
    height: 100vh;
}

.authentication_page_container .logo_container{
    cursor: pointer;
    width: 60vw;
    height: 90px;
    margin: 0 auto 40px auto;
    text-align: center;
    position: relative;
}


.authentication_page_container .logo_container .brand {
    cursor: pointer;
    display: block !important;
    width: -moz-fit-content !important;
    width: 50%;
    height: 100%;
    position: relative !important;
    margin: auto;
    object-fit: scale-down;
}

.authentication_page_container .authentication_page_body{
    border-color: #e8e5ef;
    border-radius: 4px;
    border-width: 1px;
    box-shadow: 0 2px 0 rgba(9, 9, 53, 0.025), 2px 4px 0 rgba(0, 0, 150, 0.015);
    margin: 0 auto;
    padding: 40px;
    padding-bottom: 30px;
    width: 52vw;
    max-width: 700px;
    max-height: 600px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    border-radius: 10px;
    border: solid 1px rgb(233, 233, 233);
}


.authentication_page_body .form{
    text-align: -webkit-right;
    width: 100%;
    height: 100%;
}

.authentication_page_body .form-control {
    border: 1px solid #c4c3c3;
    border-radius: 6px;
    color: #2c2c2c;
    height: auto;
    font-size: 1rem;
    box-shadow: none;
    margin-bottom: 10px !important;
    height: 39px;
    background-image: none !important;
}

.authentication_page_body h2 {
    display: flex;
    justify-content: center;
    font-weight: 600;
    margin-bottom: 5px;
    font-size: 1.9rem;
}

.authentication_page_body label{
    width: 100%;
    text-align: left;
    margin: 0;
    font-size: 1rem;
    font-weight: 600;
    padding-left: 2px;
}

.authentication_page_body .form_footer{
    width: 100%;
    display: flex;
    justify-content: center;
}


.authentication_page_body .submit_but{
    all: unset;
    background:#414e5c;
    color: white !important;
    width: 210px;
    height: 34px;
    font-size: 1.18rem;
    border-radius: 8px;
    transition: -webkit-filter 300ms;
    transition: filter 300ms;
    transition: filter 300ms, -webkit-filter 300ms;
    cursor: pointer;
    margin-top: 30px;
    box-shadow: none !important;
    position: relative !important;
}

.authentication_page_body .submit_but:hover{
    background:#414e5c;
    opacity: .9;
    box-shadow: none !important;
}


@media all and (max-width: 1080px){
    
    .authentication_page_container .logo_container{
        width: 70vw;
        min-width: 680px;
        height: 90px;
    }

    .authentication_page_container .authentication_page_body{
        width: 70vw;
        max-width: 550px;
        padding-bottom: 20px;
        padding-top: 35px;
        
    }
    .authentication_page_body h2 {
        font-weight: 600;
        margin-bottom: 3px;
        font-size: 1.8rem;
    }

    .authentication_page_body label{
        font-size: 1rem;
    }

    .authentication_page_body .form-control {
        font-size: .96rem;
        margin-bottom: 9px !important;
        height: 35px;
    }

    .authentication_page_body .my_icon{
        width: 19px !important;
        height: 19px !important;
    }

    .authentication_page_body .input-group-text:last-child {
        height: 35px;
    }
    

    .authentication_page_body .submit_but{
        width: 185px;
        height: 31px;
        font-size: 1.13rem;
        margin-top: 25px !important;
    }

}


@media all and (max-width: 620px){
    
    .authentication_page_container .logo_container{
        width: 100vw;
        min-width:auto;
        height: 100px;
    }
    .authentication_page_container .logo_container .brand {
        width: 55%;
    }


    .authentication_page_body label{
        font-size: .9rem;
    }

    .authentication_page_body .form-control {
        font-size: .89rem;
        margin-bottom: 8px !important;
        height: 35px;
    }


    .authentication_page_container .authentication_page_body{
        width: 80vw;
        max-width: 550px;
        padding: 28px;
        padding-top: 35px;        
        padding-bottom: 20px;        
    }
    .authentication_page_body h2 {
        font-weight: 600;
        margin-bottom: 3px;
        font-size: 1.6rem;
    }

    .authentication_page_body .submit_but{
        width: 160px;
        height: 27px;
        font-size: 1.08rem;
        margin-top: 20px !important;
    }
}


@media all and (max-width: 460px){
    
    .authentication_page_container .logo_container .brand {
        width: 70%;
    }
    .authentication_page_container .authentication_page_body{
        width: 95vw;
        padding: 2px;
        padding-top: 25px;        
        padding-bottom: 10px;        
    }

    .authentication_page_body label{
        font-size: .85rem;
    }

    .authentication_page_body .form-control {
        font-size: .82rem;
        margin-bottom: 7px !important;
        height: 35px;
    }

    .authentication_page_body h2 {
        font-weight: 600;
        margin-bottom: 3px;
        font-size: 1.6rem;
    }

    .authentication_page_body .submit_but{
        width: 140px;
        height: 25px;
        font-size: .98rem;
        margin-top: 15px !important;
    }
    .error{
        font-size: .9em !important;
        margin-bottom: 15px !important;
      }
      
}
.save_email_awnser_container{
    background-color: #f7f7f7;
    margin: 0;
    padding-top: 20px ;
    width: 100vw;
    height: 100vh;
}

.save_email_awnser_container .logo_container{
    cursor: pointer;
    width: 60vw;
    height: 90px;
    max-height: 86px;
    margin: 0 auto 5% auto;
    text-align: center;
    position: relative;
}

.save_email_awnser_container .logo_container a {
    color: #3d4852;
    font-size: 19px;
    font-weight: bold;
    text-decoration: none;
    width: 50%;
    height: 100%;
}


.save_email_awnser_container .logo_container .brand {
    cursor: pointer;
    display: block !important;
    width: -moz-fit-content !important;
    width: 100%;
    height: 100%;
    position: relative !important;
    margin: auto;
    object-fit: scale-down;
}

.save_email_awnser_container .save_email_awnser_body{
    border-color: #e8e5ef;
    border-radius: 2px;
    border-width: 1px;
    box-shadow: 0 2px 0 rgba(0, 0, 150, 0.025), 2px 4px 0 rgba(0, 0, 150, 0.015);
    margin: 0 auto;
    padding: 0;
    width: 55%;
    height: 300px;
    border-radius: 10px;
}


.save_email_awnser_container .save_email_awnser_body .body_icon{
    width: 100%;
    height: 45%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
}

.save_email_awnser_container .save_email_awnser_body .body_icon .MuiFab-default {
    border-radius: 5px !important;
    width: 65px !important;
    height: 65px !important;
    box-shadow: none !important;
    color: rgba(0, 0, 0, 0.87);
    background-color: white !important;
}

.save_email_awnser_container .save_email_awnser_body .body_icon .done_icon {
    width: 1.9em !important;
    height: 1.9em !important;
    color: #33b66e;
}

.save_email_awnser_container .error_save .false_icon{
    width: 1.9em !important;
    height: 1.9em !important;
    color: #f33640f3 !important;
}

.save_email_awnser_container .save_email_awnser_body .body_title{
    width: 100%;
    height: 25%;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    font-size: 1.3rem;
    font-weight: 700;
    color: white;
}

.save_email_awnser_container .save_email_awnser_body .body_description{
    width: 100%;
    height: 30%;
    display: flex;
    text-align: center;
    justify-content: center;
    font-size: 1.2rem;
    color: white;
}




/*****************************************************************************/
/*****************************************************************************/
/*****************************************************************************/

.save_email_awnser_container .refuse_reason .textfield_container{
    width: 90%;
    height: 75%;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    vertical-align: middle;
}

.save_email_awnser_container .refuse_reason .error{
    width: 100%;
    font-size: 1.1em;
    color: #dd3461 !important;
    background: #dc35451f;
    padding: 10px 20px;
    border-radius: 5px;
    margin: 0 !important;
    margin-bottom: 15px !important;
    text-align: start;
  }

.save_email_awnser_container .refuse_reason .MuiOutlinedInput-multiline {
    padding: 7.5px 14px !important;
}


.save_email_awnser_container .refuse_reason .MuiInputLabel-outlined.MuiInputLabel-shrink {
    /* margin-right: 5px; */
    -webkit-transform: translate(8px, -10.5px) scale(0.75) !important;
            transform: translate(8px, -10.5px) scale(0.75) !important;
    z-index: 4 !important;
    background-color: white !important;
    padding: 0 5px !important;
}

.save_email_awnser_container .refuse_reason  .dialogBottom{
    width: 90%;
    height: 25%;
    display: flex;
    margin: 0 auto;
    justify-content: flex-end;
    align-items: center;
    align-content: center;
}

.save_email_awnser_container .refuse_reason .submit_but{
    display: flex;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    margin: 0;
}


.loading_animation_container{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
}
.service_header{
    width: -webkit-max-content;
    width: max-content;
    padding:0;
    z-index:3;
    position:fixed;
    top: 0;
}


.nav_menu_container{
    width: 100%;
    display: flex;
    justify-content: flex-start;
}



.nav_menu_container .service_content {
    height: 100vh;
    display: flex;
    flex-direction: column;
    transition: all 500ms;
    z-index: 3;
}


.nav_menu_container .service_content > div {
    display: flex;
    flex-direction: column;
}


.nav_menu_container .service_content .company_nav_logo_container {
    cursor: pointer;
    width: 100% !important;
    height: 60px !important;
    position: relative !important;
    left: -2px !important;
    margin-top: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
}


.nav_menu_container .service_content .company_nav_logo_container img {
    width: 52px;
    height: 59px !important;
    margin: 10% auto 0 auto;
    object-fit: scale-down;
}

.nav_menu_container .service_content.show {
    z-index: 3;
    width: 60px !important;
    position: fixed;
}


.nav_menu_container > div > div > a:hover{
    background-color: rgba(172, 171, 171, 0.1);
}


.nav_menu_container #drop1.show{
    height:65px !important;
}


.nav_menu_container .menu_items{
    margin-top: 5px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}

.nav_menu_container .menu_item{
    display: flex;
    color: white;
    width: 99%;
    justify-content: space-between;
    height: 2.52rem !important;
    align-items: center;
    align-content: center;
    padding: 0 5px 0 10px;
    cursor: pointer;
    transition: all 200ms;
    text-decoration: none;
}

.nav_menu_container .menu_item_selected{
    background-color:rgba(230, 218, 218, 0.292);
    transition: all 200ms;
}

.nav_menu_container .menu_item:hover{
    background-color:rgba(230, 218, 218, 0.292);
    transition: all 200ms;
}


.nav_menu_container .menu_item div:nth-child(2){
    width: 210px !important;
    padding-top: 2px !important;
}

.nav_menu_container .menu_item a{
    text-decoration:none;
	color: white;
	padding: 7px 10px;
	font-size: 10.5pt;
    font-weight: 400;
}

.nav_menu_container .menu_item .MuiSvgIcon-root{
    width: 2.3rem !important;
    height: 1.55rem !important;
}




.service_body{
    flex: 1 1;
    width: calc(100vw - 60px);
    margin-top: 72px ;
    margin-left: 60px;
    overflow: auto;
}
.responsive_navVertical{
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: 100vh;
    display: flex;
    justify-content: flex-start;
}

.responsive_navVertical .responsive_service_content {
    position: absolute;
    height: 100vh;
    width: 0;
    display: flex;
    overflow: hidden;
    flex-direction: column;
    justify-content: space-between;
    transition: all 500ms;
    z-index: 2000 !important;
}

.responsive_navVertical .responsive_service_content.show {
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
    z-index: 3;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    width: 255px;
}

.responsive_navVertical .responsive_service_content .company_nav_logo_container {
    cursor: pointer;
    width: 100% !important;
    height: 68px !important;
    position: relative !important;
    left: -2px !important;
    margin-top: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
}


.responsive_navVertical .responsive_service_content .company_nav_logo_container img {
    width: 52px;
    height: 59px !important;
    object-fit: scale-down;
}

.responsive_navVertical .responsive_service_content > div {
    display: flex;
    flex-direction: column;
    min-width: 250px;
    width: 100%;
}

.responsive_navVertical #service_menu_holder{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    z-index: 3 !important;
    position: absolute;
    transition: all 500ms;
    height: 68px;
    width: 55px;
    position:fixed;
}

.responsive_navVertical #service_menu_holder > div{
    width: 25px;
    height: 3.2px !important;
    border-radius: 4px;
    margin: 2.5px;
    transition: all 500ms ease-in-out;
    z-index: 3;
}


.responsive_navVertical #service_menu_holder.open{
    transition: all 500ms;
    -webkit-transform: translateX(249px);
            transform: translateX(249px);
}

.responsive_navVertical #service_menu_holder.open > div:nth-child(1){
    -webkit-transform: translateY(8px) rotateZ(45deg);
            transform: translateY(8px) rotateZ(45deg);
}
.responsive_navVertical #service_menu_holder.open > div:nth-child(2){
    -webkit-transform: translateX(50px);
            transform: translateX(50px);
    opacity: 0;
}
.responsive_navVertical #service_menu_holder.open > div:nth-child(3){
    -webkit-transform: translateY(-8px) rotateZ(-45deg);
            transform: translateY(-8px) rotateZ(-45deg);
}

.responsive_navVertical .title_menu_item{
    font-size: .9rem;
    font-weight: 400;
}

.responsive_navVertical .menu_item{
    width: 100%;
}

.responsive_service_body{
    height: calc(100vh - 72px);
    width: 100vw;
    margin-top: 72px;
    overflow: auto;
}
.service_topnav .navbar{
    position: fixed;
    z-index: 2;
    display: flex;
    justify-content: flex-end;
    background-color: white !important;
    box-shadow: none;
    padding: 0;
}


.service_topnav .navbar a:not(.btn):not(.dropdown-item) {
    color: #040404;
}

.navbar_content_container{
    display: flex;
    width: calc(100% - 60px);
    justify-content: space-between;
    align-items: center;
    align-content: center;
    border-bottom: solid .5px rgb(237, 237, 237);
}


.navbar_content_container .company_logo_container {
    cursor: pointer;
    width: -moz-fit-content !important;
    position: relative !important;
    height: 69px;
    margin-left: 10px;
    margin-bottom: 2px;
    padding: 2px 0;
    display: flex;
    align-items: center;
}

.navbar_content_container .company_logo_container img{
    height: 65px;
    object-fit: scale-down;
}


.navbar_content_container .selection {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.basket_container{
    margin-right: 8px;
    position: relative;
    color: rgb(69 81 83) !important;
    cursor: pointer;
}

.basket_container #products_number{
    position: absolute;
    left: -4px;
    top: -8px;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    color: white !important;
    background: rgb(237, 101, 48);
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    vertical-align: middle;
    font-size: .6rem;
}

.navbar_content_container .user_name_container{
    width:-webkit-fit-content;
    width:-moz-fit-content;
    width:fit-content;
    display:flex;
    margin-right:25px;
    font-size: 1rem;
}

.navbar_content_container .my_avatar{
    margin: 0;
    object-fit: scale-down;
}

@media all and (max-width: 1010px){
    .navbar_content_container{
        width: calc(100% - 50px) !important;
        height: 68px;
    }
    .navbar_content_container .company_logo_container {
        padding: 2px 0;
        height: 64px !important;
    }
    
    .navbar_content_container .company_logo_container img{
        height: 55px !important;
    }
}


@media all and (max-width: 500px){
    .navbar_content_container{
        width: calc(100% - 46px) !important;
        margin-left: 0 !important;
    }
    .navbar_content_container .company_logo_container {
        padding:0 !important;
    }

    .navbar_content_container .basket_container{
        margin-right: 0 !important;
    }

    .navbar_content_container .basket_container .my_icon{
        width: 19px !important;
        height: 19px !important;
    }

    .basket_container #products_number{
        width: 15px;
        height: 15px;
        font-size: .59rem;
    }
    .navbar_content_container .user_name_container{
        width:-webkit-fit-content;
        width:-moz-fit-content;
        width:fit-content;
        display:flex;
        margin-right:12px;
        font-size: .8rem;
    }

    .navbar_content_container .my_avatar{
        width: 34px;
        height: 34px;
    }
    
}
@media all and (max-width: 1200px){
    .widgets_contents_container .item_title {
        padding: 8px 15px 7px 15px !important;
        font-size: .95rem !important;
    }

    .widget_count_elems_container {
        font-size: 3.2rem !important;
    }


    .widget_count_elems_container .arrow_container {
        top: -5px !important;
        right: -30px !important;
    }

    .widget_count_elems_container .arrow_container .progress_icon {
        width: 1.5em !important;
        height: 1.5em !important;
    }

    .widgets_contents_container .MuiInputBase-input.MuiInputBase-input,.widgets_main_container .MuiInputBase-formControl {
        font-size: .87rem !important;
    }
}


@media all and (max-width: 1080px){
    .widgets_contents_container .item_title {
        padding: 6px 10px 5px 10px !important;
        font-size: .9rem !important;
    }

    .widgets_main_container  .MuiTableCell-root {
        padding: 10px 8px !important;
        font-size: 0.75rem !important;
        
    }

    .widgets_contents_container .header_form {
        width:155px !important;
    }
    
    .widgets_contents_container .MuiInputBase-input.MuiInputBase-input,.widgets_main_container .MuiInputBase-formControl {
        font-size: .82rem !important;
    }

}


@media all and (max-width: 990px) and (min-width: 765px){

    .main_title{
        width: -webkit-fit-content !important;
        width: -moz-fit-content !important;
        width: fit-content !important;
        font-size: 1.85rem !important;
        padding-left: 26px !important;
    }
    
    .widgets_configButton_container{
        flex: 1 1 !important;
        padding-right: 13px !important;
        justify-content: flex-end;
    }
    
    
    .month_year_switchercontainer{
        height: 33px !important;
        margin-right: 20px !important;
    }


    .month_year_switchercontainer .selected_button{
        all: unset !important;
        background-color: #484f57 !important;
        color: white !important;
        border-radius: 40px !important;
        font-size: .85rem !important;
        padding: .36rem 1.05rem !important;
        transition: all 150ms ease-in-out 0s;
        box-shadow: none !important;
        position: relative !important;
    }
    
    
    .month_year_switchercontainer .unselected_button{
        all: unset !important;
        color: #484f57 !important;
        border-radius: 40px !important;
        font-size: .85rem !important;
        padding: .36rem 1.05rem !important;
        cursor: pointer !important;
        transition: all 150ms ease-in-out 0s;
        pointer-events: fill !important;
        box-shadow: none !important;
        position: relative !important;
    }
    
    .month_year_switchercontainer .year_slct_form{
        width: 85px !important;
        height: 32px !important;
    }


    .month_year_switchercontainer .MuiSelect-select.MuiSelect-select {
        color: inherit !important;
        font-size: .85rem !important;
        padding-top: 0.3rem !important;
        padding-bottom: 0.31rem !important;
        background-color: inherit !important;
    }
    
    .month_year_switchercontainer .MuiInputBase-root {
        font-family: var(--body-font) !important;
        border: none !important;
        padding: .35rem .5rem !important;
        border-radius: 40px !important;
        height: 31px !important;
    }

    .widgets_configButton{
        border: solid 1px #484f57;
        background:#484f57 !important;
        color: white !important;
        transition: all 150ms ease-in-out 0s;
        cursor: pointer !important;
        align-items: center !important;
        text-align: center !important;
        box-shadow: none !important;
    }


    .widgets_configButton:hover{
        background:#5b636b !important;
    }

    .widgets_saveButton{
        border: solid 1px #3b5a7c;
        background:#3b5a7c !important;
        color: white !important;
        transition: all 150ms ease-in-out 0s;
        cursor: pointer !important;
        align-items: center !important;
        text-align: center !important;
        box-shadow: none !important;
        margin-right: 9px !important;
    }


    .widgets_saveButton:hover{
        background:#4b698a !important;
    }


    .widgets_configButton_container .disabled_button{
        pointer-events: none !important;
        opacity: 0.6 !important;
    }

    .widgets_contents_container::-webkit-scrollbar {
        width: 8px !important;
        height: 8px !important;
        background: white;
    }

    .widgets_contents_container::-webkit-scrollbar-thumb {
        background-color: rgb(106, 102, 102);
        border-radius: 8px !important;
    }
    
    .widget_component_container::-webkit-scrollbar {
        width: 6px !important;
        height: 6px !important;
        background: white;
    }

    .widget_component_container::-webkit-scrollbar-thumb {
        border-radius: 6px !important;
    }

    .widget_count_elems_container {
        font-size: 2.75rem !important;
    }

    .widgets_contents_container .header_form {
        width:150px !important;
    }


    .widgets_contents_container .MuiInputBase-input.MuiInputBase-input,.widgets_main_container .MuiInputBase-formControl {
        font-size: .78rem !important;
    }

    .apexcharts-text {
        font-size: .75rem !important;
    }
}




@media all and (max-width: 765px) and (min-width: 660px){

    .main_title{
        width: -webkit-fit-content !important;
        width: -moz-fit-content !important;
        width: fit-content !important;
        font-size: 1.6rem !important;
        padding-left: 15px !important;
    }
    
    .widgets_configButton_container{
        padding-right: 7px !important;
    }
    
    
    .month_year_switchercontainer{
        height: 28px !important;
        margin-right: 15px !important;
    }


    .month_year_switchercontainer .selected_button{
        all: unset !important;
        background-color: #484f57 !important;
        color: white !important;
        border-radius: 40px !important;
        font-size: .76rem !important;
        padding: .25rem .8rem !important;
        transition: all 150ms ease-in-out 0s;
        box-shadow: none !important;
        position: relative !important;
    }
    
    
    .month_year_switchercontainer .unselected_button{
        all: unset !important;
        color: #484f57 !important;
        border-radius: 40px !important;
        font-size: .76rem !important;
        padding: .25rem .8rem !important;
        cursor: pointer !important;
        transition: all 150ms ease-in-out 0s;
        pointer-events: fill !important;
        box-shadow: none !important;
        position: relative !important;
    }
    
    .MuiSelect-select.MuiSelect-select {
        padding-right: 20px !important;
    }

    .MuiMenuItem-root {
        font-size: .76rem !important;
    }

    .month_year_switchercontainer .year_slct_form{
        width: 75px !important;
    }


    .month_year_switchercontainer .MuiSelect-select.MuiSelect-select {
        color: inherit !important;
        font-size: .76rem !important;
        padding-top: 0.22rem !important;
        padding-bottom: 0.23rem !important;
        background-color: inherit !important;
    }

    .month_year_switchercontainer .MuiSelect-icon {
        right: 0px !important;
        width: 20px !important;
        height: 20px !important;
    }
    
    .month_year_switchercontainer .MuiInputBase-root {
        font-family: var(--body-font) !important;
        border: none !important;
        padding: .3rem .5rem !important;
        border-radius: 40px !important;
        height: 26px !important;
    }

    .widgets_configButton{
        border: solid 1px #484f57;
        background:#484f57 !important;
        color: white !important;
        transition: all 150ms ease-in-out 0s;
        cursor: pointer !important;
        align-items: center !important;
        text-align: center !important;
        box-shadow: none !important;
    }


    .widgets_configButton:hover{
        background:#5b636b !important;
    }

    .widgets_saveButton{
        border: solid 1px #3b5a7c;
        background:#3b5a7c !important;
        color: white !important;
        transition: all 150ms ease-in-out 0s;
        cursor: pointer !important;
        align-items: center !important;
        text-align: center !important;
        box-shadow: none !important;
        margin-right: 6px !important;
    }


    .widgets_saveButton:hover{
        background:#4b698a !important;
    }


    .widgets_configButton_container .disabled_button{
        pointer-events: none !important;
        opacity: 0.6 !important;
    }


    .widgets_contents_container .item_title {
        padding: 6px 10px 5px 10px !important;
        font-size: .85rem !important;
    }

    .widget_count_elems_container {
        font-size: 2.5rem !important;
    }


    .widget_count_elems_container .arrow_container {
        top: -5px !important;
        right: -26px !important;
    }

    .widget_count_elems_container .arrow_container .progress_icon {
        width: 1.4em !important;
        height: 1.4em !important;
    }

    .widgets_main_container  .MuiTableCell-root {
        padding: 9px 7px !important;
        font-size: 0.72rem !important;
        
    }

    .widgets_contents_container::-webkit-scrollbar {
        width: 8px !important;
        height: 8px !important;
        background: white;
    }

    .widgets_contents_container::-webkit-scrollbar-thumb {
        background-color: rgb(106, 102, 102);
        border-radius: 8px !important;
    }
    
    .widget_component_container::-webkit-scrollbar {
        width: 6px !important;
        height: 6px !important;
        background: white;
    }

    .widget_component_container::-webkit-scrollbar-thumb {
        border-radius: 6px !important;
    }

    .widgets_contents_container .header_form {
        width:150px !important;
    }

    .widgets_contents_container .MuiInputBase-input.MuiInputBase-input,.widgets_main_container .MuiInputBase-formControl {
        font-size: .78rem !important;
    }

    .apexcharts-text {
        font-size: .7rem !important;
    }
}



@media all and (max-width: 660px) and (min-width: 549px){

    .main_title{
        width: -webkit-fit-content !important;
        width: -moz-fit-content !important;
        width: fit-content !important;
        font-size: 1.35rem !important;
        padding-left: 15px !important;
        padding-right: 5px !important;
    }
    
    .widgets_configButton_container{
        padding-right: 7px !important;
    }
    
    
    .month_year_switchercontainer{
        height: 25px !important;
        margin-right: 15px !important;
    }


    .month_year_switchercontainer .selected_button{
        all: unset !important;
        background-color: #484f57 !important;
        color: white !important;
        border-radius: 40px !important;
        font-size: .7rem !important;
        padding: .2rem .75rem !important;
        transition: all 150ms ease-in-out 0s;
        box-shadow: none !important;
        position: relative !important;
    }
    
    
    .month_year_switchercontainer .unselected_button{
        all: unset !important;
        color: #484f57 !important;
        border-radius: 40px !important;
        font-size: .7rem !important;
        padding: .2rem .75rem !important;
        cursor: pointer !important;
        transition: all 150ms ease-in-out 0s;
        pointer-events: fill !important;
        box-shadow: none !important;
        position: relative !important;
    }
    
    .MuiSelect-select.MuiSelect-select {
        padding-right: 14px !important;
    }

    .MuiMenuItem-root {
        font-size: .7rem !important;
    }

    .month_year_switchercontainer .year_slct_form{
        width: 65px !important;
    }


    .month_year_switchercontainer .MuiSelect-select.MuiSelect-select {
        color: inherit !important;
        font-size: .7rem !important;
        padding-top: 0.11rem !important;
        padding-bottom: 0.12rem !important;
        background-color: inherit !important;
    }

    .month_year_switchercontainer .MuiSelect-icon {
        top: 4px !important;
        right: 0px !important;
        width: 15px !important;
        height: 15px !important;
    }
    
    
    .month_year_switchercontainer .MuiInputBase-root {
        font-family: var(--body-font) !important;
        border: none !important;
        padding: .25rem .5rem !important;
        border-radius: 40px !important;
        height: 23px !important;
    }


    .widgets_configButtons .my_icon{
        width: 14.5px !important;
        height: 14.5px !important;
    }


    .widgets_configButton{
        background:#484f57 !important;
        color: white !important;
        padding: .35rem .75rem !important;
        font-size: .7rem !important;
        transition: all 150ms ease-in-out 0s;
        cursor: pointer !important;
        align-items: center !important;
        text-align: center !important;
        box-shadow: none !important;
    }

    .widgets_configButton:hover{
        background:#5b636b !important;
    }

    .widgets_saveButton{
        border: solid 1px #3b5a7c;
        background:#3b5a7c !important;
        color: white !important;
        transition: all 150ms ease-in-out 0s;
        cursor: pointer !important;
        align-items: center !important;
        text-align: center !important;
        box-shadow: none !important;
        margin-right: 5px !important;
    }

    .widgets_saveButton:hover{
        background:#4b698a !important;
    }

    .widgets_configButton_container .disabled_button{
        pointer-events: none !important;
        opacity: 0.6 !important;
    }


    .widgets_contents_container::-webkit-scrollbar {
        width: 8px !important;
        height: 8px !important;
        background: white;
    }

    .widgets_contents_container::-webkit-scrollbar-thumb {
        background-color: rgb(106, 102, 102);
        border-radius: 8px !important;
    }
    
    .widget_component_container::-webkit-scrollbar {
        width: 6px !important;
        height: 6px !important;
        background: white;
    }

    .widget_component_container::-webkit-scrollbar-thumb {
        border-radius: 6px !important;
    }
    
    .widgets_contents_container .item_title {
        padding: 6px 10px 5px 10px !important;
        font-size: .82rem !important;
    }

    .widget_count_elems_container {
        font-size: 2.6rem !important;
    }


    .widget_count_elems_container .arrow_container {
        top: -5px !important;
        right: -26px !important;
    }

    .widget_count_elems_container .arrow_container .progress_icon {
        width: 1.4em !important;
        height: 1.4em !important;
    }

    .widgets_main_container  .MuiTableCell-root {
        padding: 9px 7px !important;
        font-size: 0.72rem !important;
        
    }

    .widgets_contents_container .header_form {
        width:150px !important;
    }

    .widgets_contents_container .MuiInputBase-input.MuiInputBase-input,.widgets_main_container .MuiInputBase-formControl {
        font-size: .77rem !important;
    }

    .apexcharts-text {
        font-size: .7rem !important;
    }
}





@media all and (max-width: 549px){

    .main_title{
        width: -webkit-fit-content !important;
        width: -moz-fit-content !important;
        width: fit-content !important;
        font-size: 1.1rem !important;
        padding-left: 6px !important;
        padding-right: 4px !important;
    }
    
    .widgets_configButton_container{
        padding-right: 5px !important;
    }
    
    .widgets_configButtons{
        width: -webkit-fit-content !important;
        width: -moz-fit-content !important;
        width: fit-content !important;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    
    .month_year_switchercontainer{
        height: 25px !important;
        margin-right: 10px !important;
    }


    .month_year_switchercontainer .selected_button{
        all: unset !important;
        background-color: #484f57 !important;
        color: white !important;
        display: flex;
        border-radius: 40px !important;
        font-size: .75rem !important;
        padding: 0 0.65rem !important;
        height: 100% !important;
        transition: all 150ms ease-in-out 0s;
        box-shadow: none !important;
        position: relative !important;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    
    
    .month_year_switchercontainer .unselected_button{
        all: unset !important;
        color: #484f57 !important;
        border-radius: 40px !important;
        font-size: .72rem !important;
        padding: .2rem .65rem !important;
        cursor: pointer !important;
        transition: all 150ms ease-in-out 0s;
        pointer-events: fill !important;
        box-shadow: none !important;
        position: relative !important;
    }
    
    .MuiSelect-select.MuiSelect-select {
        padding-right: 12px !important;
    }

    .MuiMenuItem-root {
        font-size: .7rem !important;
    }

    .month_year_switchercontainer .year_slct_form{
        width: 60px !important;
    }


    .month_year_switchercontainer .MuiSelect-select.MuiSelect-select {
        color: inherit !important;
        font-size: .7rem !important;
        padding-top: 0.11rem !important;
        padding-bottom: 0.12rem !important;
        background-color: inherit !important;
    }

    .month_year_switchercontainer .MuiSelect-icon {
        top: 4px !important;
        right: 0px !important;
        width: 15px !important;
        height: 15px !important;
    }
    
    
    .month_year_switchercontainer .MuiInputBase-root {
        font-family: var(--body-font) !important;
        border: none !important;
        padding: .25rem .4rem !important;
        border-radius: 40px !important;
        height: 23px !important;
    }


    .widgets_configButtons .my_icon{
        width: 14.5px !important;
        height: 14.5px !important;
    }


    .widgets_configButton{
        background:#484f57 !important;
        color: white !important;
        transition: all 150ms ease-in-out 0s;
        cursor: pointer !important;
        align-items: center !important;
        text-align: center !important;
        box-shadow: none !important;
        margin-bottom: 5px !important;
    }

    .widgets_configButton:hover{
        background:#5b636b !important;
    }

    .widgets_saveButton{
        border: solid 1px #3b5a7c;
        background:#3b5a7c !important;
        color: white !important;
        transition: all 150ms ease-in-out 0s;
        cursor: pointer !important;
        align-items: center !important;
        text-align: center !important;
        box-shadow: none !important;
        margin-bottom: 5px !important;
    }

    .widgets_saveButton:hover{
        background:#4b698a !important;
    }

    .widgets_configButton_container .disabled_button{
        pointer-events: none !important;
        opacity: 0.6 !important;
    }


    .widgets_contents_container::-webkit-scrollbar {
        width: 7px !important;
        height: 7px !important;
        background: white;
    }

    .widgets_contents_container::-webkit-scrollbar-thumb {
        background-color: rgb(106, 102, 102);
        border-radius: 7px !important;
    }
    
    .widget_component_container::-webkit-scrollbar {
        width: 5px !important;
        height: 5px !important;
        background: white;
    }

    .widget_component_container::-webkit-scrollbar-thumb {
        border-radius: 5px !important;
    }

    .widgets_contents_container .item_title {
        padding: 6px 9px 5px 9px !important;
        font-size: .77rem !important;
    }

    .widget_count_elems_container {
        font-size: 2.2rem !important;
    }


    .widget_count_elems_container .arrow_container {
        top: -5px !important;
        right: -24px !important;
    }

    .widget_count_elems_container .arrow_container .progress_icon {
        width: 1.1em !important;
        height: 1.1em !important;
    }

    .widgets_main_container  .MuiTableCell-root {
        padding: 8px 7px !important;
        font-size: 0.72rem !important;
        
    }
    .widgets_main_container .MuiTableCell-head {
        line-height: .9rem !important;
    }

    .widgets_contents_container .header_form {
        width:145px !important;
    }

    .widgets_contents_container .MuiInputBase-input.MuiInputBase-input,.widgets_main_container .MuiInputBase-formControl {
        font-size: .75rem !important;
    }

    .apexcharts-text {
        font-size: .68rem !important;
    }

    .config_widget_container .MuiDialog-paperWidthLg {
        max-width: 100vw !important;
    }
    
}

@media all and (max-width: 379px){

    .widgets_main_container  .MuiTableCell-root {
        padding: 8px 7px !important;
        font-size: 0.7rem !important;
        
    }

    .widgets_contents_container .item_title {
        padding: 6px 9px 5px 9px !important;
        font-size: .75rem !important;
    }

    .widgets_contents_container .MuiInputBase-input.MuiInputBase-input,.widgets_main_container .MuiInputBase-formControl {
        font-size: .72rem !important;
    }

    .apexcharts-text {
        font-size: .65rem !important;
    }
}

.widgets_main_container{
    width: 100%;
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
    margin: 0; 
}


.parent {
    display: flex;
    flex-direction: column;
}

.childThatGrows{
    flex-grow: 1;        
    min-height: 0;       
    overflow: scroll;
 }


 .widgets_view_title{
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 12%;
    max-height: 100px;
    align-content: center;
    align-items: center;
}

.main_title{
    width: 50%;
    font-size: 2rem;
    font-weight: 600;
    color: #535353;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    padding: 20px;
    padding-left: 30px;
}

.widgets_configButton_container{
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    text-align: end;
    padding-right: 15px;
    display: flex;
    align-items: center;
    align-content: center;
}


.month_year_switchercontainer{
    height: 50px;
    margin-right: 25px;
    border: solid 1px #484f57;
    border-radius: 40px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    pointer-events: none !important;
    position: relative !important;
}



.month_year_switchercontainer:hover{
    background-color: #484f5714 !important;
    color: white !important;
    transition: all 150ms ease-in-out 0s;
}

.month_year_switchercontainer .MuiInput-underline:before {
    border: none !important;
}

.month_year_switchercontainer .MuiSelect-icon {
    color: inherit !important;
    right: 4px !important;
}

.month_year_switchercontainer .active_select{
    background-color: #484f57 !important;
    color: white !important;
    pointer-events: fill !important;
}

.month_year_switchercontainer .inactive_select{
    pointer-events: fill !important;
}

.month_year_switchercontainer .MuiSelect-nativeInput {
    left: 0;
    width: 100%;
    bottom: 0;
    opacity: 0;
    position: absolute;
    pointer-events: none;
}

.month_year_switchercontainer .year_slct_form{
    width: 95px;
}

@media all and (min-width: 990px){


    .month_year_switchercontainer .MuiInputBase-root {
        font-family: var(--body-font) !important;
        border: none !important;
        padding: .35rem .5rem !important;
        border-radius: 40px !important;
        height: 35px !important;
    }


    .month_year_switchercontainer .MuiSelect-select.MuiSelect-select {
        color: inherit !important;
        font-size: .92rem !important;
        padding-top: 0.37rem !important;
        padding-bottom: 0.35rem !important;
        background-color: inherit !important;
    }
    
        
    .month_year_switchercontainer .selected_button{
        all: unset !important;
        background-color: #484f57 !important;
        color: white !important;
        border-radius: 40px !important;
        font-size: .95rem !important;
        padding: .38rem 1.25rem !important;
        transition: all 150ms ease-in-out 0s;
        box-shadow: none !important;
        position: relative !important;
    }
    
    
    .month_year_switchercontainer .unselected_button{
        all: unset !important;
        color: #484f57 !important;
        font-size: .95rem !important;
        border-radius: 40px !important;
        padding: .38rem 1.25rem !important;
        cursor: pointer !important;
        transition: all 150ms ease-in-out 0s;
        pointer-events: fill !important;
        box-shadow: none !important;
        position: relative !important;
    }


    .widgets_configButton{
        border: solid 1px #484f57;
        background:#484f57 !important;
        color: white !important;
        padding: 0 !important;
        transition: all 150ms ease-in-out 0s;
        cursor: pointer !important;
        align-items: center !important;
        text-align: center !important;
        margin-right: 10px !important;
    }


    .widgets_configButton:hover{
        background:#484f57ee !important;
    }

    .widgets_saveButton{
        border: solid 1px #3c7782;
        background:#3b5a7c !important;
        color: white !important;
        padding: 0 !important;
        transition: all 150ms ease-in-out 0s;
        cursor: pointer !important;
        align-items: center !important;
        text-align: center !important;
        margin-right: 10px !important;
    }


    .widgets_saveButton:hover{
        background:#3b5a7cee !important;
    }


    .widgets_configButton_container .disabled_button{
        pointer-events: none !important;
        opacity: 0.6 !important;
    }


}



.config_widget_container .MuiDialog-paperWidthLg {
    max-width: 85vw;
}

.MuiDialog-paperFullWidth {
    width: calc(100% - 64px);
    border-radius: 5px !important;
    padding-top: 5px !important;
}


.react-grid-item.cssTransforms {
    transition-property: -webkit-transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform;
    transition-property: none !important;
    /* transition-property: transform, -webkit-transform; */
}

.widgets_contents_container{
    width: 100%;
    height:90%;
    transition: .2s linear;
    display:flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    flex-direction: row;
    flex-wrap: wrap;
    background-color: transparent;
    box-shadow: none !important;
    display: flex !important;
    flex-direction: column !important;
    background-color: rgb(250, 250, 250);
    overflow: auto;
    border-top: solid 3px rgb(237, 237, 237);
    border-bottom: solid .5px rgb(243, 243, 243);
 }


  .widget_container:after {
    content: "";
    display: block;
    position: absolute;
    bottom: -2rem;
    width: 100%;
    height: 3px;
    background-color: none;
  }

.react-grid-item.react-grid-placeholder {
    background:rgba(0, 0, 0, 0.3) !important;
  }


.widgets_contents_container .widget_container{
    margin: 0;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    background: rgb(253, 253, 253) !important;
    box-shadow: none !important;
    box-shadow: 0px 0px 6px .8px #d5d5d5 !important;
    border-radius: 5px !important;
    display: flex !important;
    flex-direction: column !important;
    color: black !important;
}


.widgets_contents_container .item_title{
    padding: 9px 15px 8px 20px;
    font-size: 1rem;
    font-weight: 800;
    text-align: left;
    width: 100%;
    font-family: var(--body-font) !important;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: rgba(26, 26, 26, .8);
    border-bottom: solid 1px rgba(196, 196, 196,.5);
}

.widgets_contents_container .item_title div:nth-child(2){
    transition: all 150ms ease-in-out 0s;
    border-radius: 24px;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    transition: all 150ms ease-in-out 0s;
}

.widgets_contents_container .item_title div:nth-child(2):hover {
    background: rgba(0,0,0,0.04);
    transition: all 150ms ease-in-out 0s;
}

/* 
.react-grid-item > .react-resizable-handle.react-resizable-handle-se {
    opacity: 0 !important;
}

.react-grid-item > .react-resizable-handle.react-resizable-handle-sw {
    opacity: 0 !important;
} */

.widget_component_container{
    height:100% !important;
    overflow: auto;
    cursor: default !important;
}

.react-grid-item > .react-resizable-handle{
    background-image: none !important;
}
.react-grid-item > .react-resizable-handle::after {
    right: 8px !important;
    bottom: 8px !important;
    width: 9px !important;
    height: 9px !important;
    border-right: 3px solid rgb(83, 97, 110) !important;
    border-bottom: 3px solid rgb(83, 97, 110) !important;
}

/* .MuiPaper-root::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    background: transparent;
}
.MuiPaper-root::-webkit-scrollbar-thumb {
    background-color: rgb(123, 122, 122);
    border-radius: 3px;
}

.MuiPaper-root::-webkit-scrollbar-thumb:hover {
    background-color: rgba(141, 141, 141);
    cursor: pointer !important;
} */


.widgets_contents_container::-webkit-scrollbar {
    width: 9px;
    height: 9px;
    background: white;
    cursor: pointer !important;
}
.widgets_contents_container::-webkit-scrollbar-thumb {
    background-color: rgb(106, 102, 102);
    border-radius: 9px;
    cursor: pointer !important;
}

.widgets_contents_container::-webkit-scrollbar-thumb:hover {
    background-color: rgb(163, 159, 159);
    cursor: pointer !important;
}

.widget_component_container::-webkit-scrollbar {
    width: 7px;
    height: 7px;
    background: white;
    cursor: pointer !important;
}
.widget_component_container::-webkit-scrollbar-thumb {
    background-color: rgb(106, 102, 102);
    border-radius: 7px;
    cursor: pointer !important;
}

.widget_component_container::-webkit-scrollbar-thumb:hover {
    background-color: rgb(163, 159, 159);
    cursor: pointer !important;
}


.widgets_main_container .MuiTable-root {
    background: rgb(252, 252, 252) !important;
    color: white;
}

.widgets_main_container .MuiTableContainer-root {
    width: 100%;
    height: 100% !important;
    overflow-x: visible !important;
    border-radius: 0 0 6px 6px !important;
}

.widgets_main_container .MuiTableBody-root {
    display: table-row-group;
    background: rgb(252, 252, 252) !important;
    color: white !important;
}


.widgets_main_container .MuiTableCell-head {
    background: rgba(96, 119, 135, 0.85) !important;
    color: white !important;
    font-weight: 600 !important;
    line-height: .95rem !important;
    font-size: .8rem;
    background-color: rgb(248, 248, 248);
    padding: 12px 8px;
}
  
.widgets_main_container .MuiTableCell-body{
    background: rgb(252, 252, 252) !important;
    color: black !important;
    padding: 12px 8px;
    font-size: .8rem;
}


.widgets_main_container .header_form {
    width:160px;
    margin:0px !important;
}


.widgets_configButtons .MuiFab-root{
    width: 39px !important;
    height: 39px !important;
  }
.weather_container{
    width: 100%;
    height: 95%;
    position: relative;
}


.weather_close_icon{
    position: absolute;
    border-radius: 24px;
    width: 22px;
    height: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    transition: all 150ms ease-in-out 0s;
    z-index: 3;
    right: 10px;
    top: 10px;
    color: #FFF;
}   


.weather_close_icon:hover {
    background: rgba(255,255,255,0.2);
}

.container-1-2-1{
    width: 100% !important;
    height: 95% !important;
}

.main-1-2-2{
    height: -webkit-fit-content !important;
    height: -moz-fit-content !important;
    height: fit-content !important;
}

.daysPanel-1-2-29{
    height: -webkit-fit-content !important;
    height: -moz-fit-content !important;
    height: fit-content !important;
}

.rw-today-hr:nth-child(5){
    display: none !important;
    opacity: 0 !important;
}

.rw-today-info:last-child {
    color: rgb(181, 222, 244);
    display: none !important;
}


.desc-1-2-32 {
    margin: 8px 0px 5px 0px !important;
    font-size: 12px !important;
}

.icon-1-2-34 {
    padding-top: 0px !important;
}

.weather_container .header-1-2-3 {
    margin: 0px 0px 8px !important;
    font-size: 2rem !important;
    font-weight: 300;
    letter-spacing: 1.5px !important;
}

.weather_container .left-1-2-4 {
    width: 60%;
    padding: 15px;
}

@media all and (max-width: 1080px){

    .weather_container .left-1-2-4 {
        width: 65% !important;
        padding: 10px 15px !important;
    }

    .weather_container .header-1-2-3 {
        margin: 0px 0px 7px !important;
        font-size: 1.9rem !important;
    }

    .weather_container .container-1-2-1 {
        font-size: 12px !important;
    }

    .weather_container .hr-1-2-15 {
        margin: 6px 0px !important;
    }

    .weather_container .current-1-2-11 {
        font-size: 37px !important;
    }

    .weather_container .right-1-2-5 {
        width: 35% !important;
        padding: 20px 30px !important;
    }

    .weather_container .right-1-2-5 svg{
       height: 64% !important;
    }


    .weather_container .day-1-2-30 {
        margin: 6px 0px !important;
    }

    .weather_container .desc-1-2-32 {
        margin: 4px 0px 3px 0px !important;
        font-size: 11px !important;
        line-height: .8rem;
    }

    .weather_container  .icon-1-2-34 svg{
        width: 37px !important;
        height: 37px !important;
    }

    .weather_container .desc-1-2-13 {
        font-size: 15px !important;
    }
}




@media all and (max-width: 850px){

    .weather_container .header-1-2-3 {
        margin: 0px 0px 7px !important;
        font-size: 1.5rem !important;
    }

    .weather_container .container-1-2-1 {
        font-size: 11px !important;
    }

    .weather_container .hr-1-2-15 {
        margin: 5px 0px !important;
    }

    .weather_container .current-1-2-11 {
        font-size: 33px !important;
    }

    .weather_container .right-1-2-5 {
        width: 35% !important;
        padding: 20px 40px !important;
    }

    .weather_container .day-1-2-30 {
        margin: 6px 0px !important;
    }

    .weather_container .desc-1-2-32 {
        margin: 4px 0px 3px 0px !important;
        font-size: 10px !important;
        line-height: .8rem;
    }

    .weather_container  .icon-1-2-34 svg{
        width: 33px !important;
        height: 33px !important;
    }

    .weather_container .desc-1-2-13 {
        font-size: 15px !important;
    }

    .weather_container .date-1-2-31 {
        font-size: 11px !important;
    }

    .weather_container .range-1-2-33 {
        font-size: 11px !important;
    }
}





@media all and (max-width: 450px){

    .weather_container .header-1-2-3 {
        margin: 0px 0px 7px !important;
        font-size: 1.4rem !important;
    }

    .weather_container .container-1-2-1 {
        font-size: 10px !important;
    }

    .weather_container .hr-1-2-15 {
        margin: 5px 0px !important;
    }

    .weather_container .current-1-2-11 {
        font-size: 30px !important;
    }

    .weather_container .right-1-2-5 {
        width: 35% !important;
        padding: 10px 25px !important;
    }

    .weather_container .right-1-2-5 svg{
        height: 65px !important;
        width: 65px !important;
     }

    .weather_container .day-1-2-30 {
        margin: 6px 0px !important;
    }

    .weather_container .desc-1-2-32 {
        margin: 4px 0px 3px 0px !important;
        font-size: 9px !important;
        line-height: .8rem;
    }

    .weather_container  .icon-1-2-34 svg{
        width: 29px !important;
        height: 29px !important;
    }

    .weather_container .desc-1-2-13 {
        font-size: 13px !important;
    }

    .weather_container .date-1-2-31 {
        font-size: 10px !important;
    }

    .weather_container .range-1-2-33 {
        font-size: 10px !important;
    }
}
.config_widget_container{
    min-width: 70vw !important;
}


.config_widget_container .dialog_title_container{
    width: 100%;
    display: flex !important;
    justify-content: space-between !important;
    padding: 5px 10px !important;
    font-size: inherit !important;
}

.config_widget_container .dialog_title_container .dialog_title{
    width: -webkit-fit-content !important;
    width: -moz-fit-content !important;
    width: fit-content !important;
    align-content: center;
    align-items: center;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    color: rgb(80, 79, 79) !important;
    text-transform: uppercase;
    font-weight: 600;
    font-size: inherit !important;
}

.config_widget_container .dialog_title_container .dialog_icon{
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    align-content: center;
    align-items: center;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    color: rgb(80, 79, 79) !important;
}

.remains_widgets_container{
    width: 100%;
    display:flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 7px;
    justify-content: center;
}

.remains_widgets_container .widget_container{
    flex: 1 1 35ch;
    max-width: 290px;
    max-height: none !important;
    box-shadow: 0px 0px 3px 1px #e0e0e0 !important;
    margin: 20px 10px;
    margin-top: 0;
    border-radius: 5px;
    display: flex;
    background-color: white;
    cursor: pointer;
    transition: all 150ms ease-in-out 0s;
    -webkit-transform: scale(1);
            transform: scale(1);
}

.remains_widgets_container .widget_container:hover{
    transition: all 150ms ease-in-out 0s;
    background-color: rgb(248, 248, 248);
    -webkit-transform: scale(1.05);
            transform: scale(1.05);
}

.remains_widgets_container .widget_icon{
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    padding: 10px 10px 10px 15px;
}

.remains_widgets_container .widget_icon .MuiSvgIcon-root {
    width: 38px !important;
    height: 38px !important;
    color: rgb(80, 79, 79) !important;
}

.remains_widgets_container .widget_title{
    flex: 1 1;
    height: 100%;
    font-weight: 600;
    padding: 15px 4px;
    padding-right: 10px;
    font-size: 1.08rem;
    color: rgb(80, 79, 79) !important;
    display: flex;
    align-items: center;
    align-content: center;
}


@media all and (max-width: 1100px){

    .remains_widgets_container .widget_container{
        flex: 1 1 28ch;
        margin: 13px 9px;
    }

    .remains_widgets_container .widget_icon{
        padding: 10px 10px 10px 15px;
    }
    
    .remains_widgets_container .widget_icon .MuiSvgIcon-root {
        width: 35px !important;
        height: 35px !important;
    }
    
    .remains_widgets_container .widget_title{
        padding: 15px 4px;
        padding-right: 10px;
        font-size: 1rem;
    }

}


@media all and (max-width: 800px){

    .remains_widgets_container .widget_container{
        flex: 1 1 22ch;
        margin: 10px 7px;
    }

    .remains_widgets_container .widget_icon{
        padding: 7px;
    }
    
    .remains_widgets_container .widget_icon .MuiSvgIcon-root {
        width: 32px !important;
        height: 32px !important;
    }
    
    .remains_widgets_container .widget_title{
        padding: 10px 3px;
        padding-right: 6px;
        font-size: .95rem;
    }

}


@media all and (max-width: 600px){

    .remains_widgets_container .widget_container{
        flex: 1 1 20ch;
        margin: 7px 5px;
        max-width: 235px !important;
    }

    .remains_widgets_container .widget_icon{
        padding: 7px;
    }
    
    .remains_widgets_container .widget_icon .MuiSvgIcon-root {
        width: 27px !important;
        height: 27px !important;
    }
    
    .remains_widgets_container .widget_title{
        padding: 7px 3px;
        padding-right: 6px;
        font-size: .9rem;
    }

}



@media all and (max-width: 430px){

    .remains_widgets_container .widget_container{
        flex: 1 1 18ch;
        margin: 5px 3px;
    }

    .remains_widgets_container .widget_icon{
        padding: 5px;
    }
    
    .remains_widgets_container .widget_icon .MuiSvgIcon-root {
        width: 22px !important;
        height: 22px !important;
    }
    
    .remains_widgets_container .widget_title{
        padding: 7px 3px;
        padding-right: 6px;
        font-size: .8rem;
    }

}
.myAgenda_container{
    width: 100%;
    height: 100%;
    margin-top: 12px;
}

.rbc-toolbar .rbc-btn-group:nth-child(1) button:nth-child(1){
    display: none !important;
}


.rbc-btn-group:first-child{
    margin-left: 15px;
}

.rbc-btn-group:first-child > button:last-child:not(:first-child) {
    border-radius: 100% !important;
    width: 24px;
    height: 24px;
    padding: 0 !important;
    border-color:rgb(137, 137, 137) !important ;
    color: rgb(137, 137, 137) !important;
    font-weight: 600;
    margin-left: 5px;
}

.rbc-btn-group:first-child > button:not(:first-child):not(:last-child) {
    border-radius: 100% !important;
    width: 24px;
    height: 24px;
    padding: 0 !important;
    border-color:rgb(137, 137, 137) !important ;
    color: rgb(137, 137, 137) !important;
    font-weight: 600;
}


.rbc-label {
    padding: 0 6.5px !important;
    color: #535353 !important;
    font-size: .8rem !important;
}

.rbc-toolbar .rbc-toolbar-label {
    flex-grow: 1;
    padding: 0 0 0 15px !important;
    text-align: left !important;
    font-size: .9rem !important;
}


.rbc-btn-group:last-child{
    margin-right: 15px;
    font-family: var(--body-font) !important;
    font-size: .8rem !important;
}


.rbc-btn-group:last-child > button:last-child:not(:first-child) {
    border-radius: 30px !important;
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
    border-color:rgb(137, 137, 137) !important ;
    padding: 5px 15px !important;
    padding-bottom: 5.5px !important;
    transition: all 100ms ease-in-out 0s;
}

.rbc-btn-group:last-child > button:first-child:not(:last-child) {
    border-radius: 30px !important;
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    border-color:rgb(137, 137, 137) !important ;
    padding: 5px 15px !important;
    padding-bottom: 5.5px !important;
    transition: all 100ms ease-in-out 0s;
}

.rbc-toolbar button:active, .rbc-toolbar button.rbc-active {
    background-image: none;
    box-shadow: none !important;
    background-color: #3b5a7c !important;
    color: white !important;
    border-color:rgb(137, 137, 137) !important ;
    transition: all 100ms ease-in-out 0s;
}


.rbc-header {
    padding: 5px 3px !important;
    font-size: .8rem !important;
    color: #535353 !important;
    border-bottom: none !important;
}

.rbc-header button {
    font-weight: 600 !important;
}


.rbc-allday-cell {
    display: none !important;
}


.rbc-day-slot .rbc-events-container {
    margin: 0 !important;
}


.rbc-row-content {
    background: white !important;
    font-size: .9rem !important;
}


.rbc-day-slot .rbc-event-label {
    margin-top: 3px !important;
}

.rbc-day-slot .rbc-event-content {
    font-size: .8rem !important;
    margin-top: 3px !important;
}


.rbc-time-column .rbc-timeslot-group {
    background: white !important;
}

.rbc-day-slot {
    max-height: 550px !important;
}

.rbc-events-container {
    max-height: 550px !important;
}

.rbc-timeslot-group {
    flex: 1 1;
    max-height: 50px !important;
}

.rbc-time-slot {
    border-top: 1px solid #f7f7f7;
    max-height: 25px !important;
}

.rbc-event::after{
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(0,0,0,.2);
    z-index: -1;
}

.rbc-time-content{
    overflow: hidden !important;
}

@media all and (max-width: 800px){

    .rbc-btn-group:first-child > button:last-child:not(:first-child) {
        width: 22px;
        height: 22px;
        font-size: .9rem;
        margin-left: 3px;
    }


    .rbc-btn-group:first-child > button:not(:first-child):not(:last-child) {
        width: 22px;
        height: 22px;
        font-size: .9rem;
    }

    .rbc-btn-group:first-child {
        margin-left: 12px;
    }

    .rbc-btn-group:last-child {
        margin-right: 12px;
        font-size: .77rem !important;
    }

    .rbc-btn-group:last-child > button:last-child:not(:first-child) {
        padding: 4.5px 12px !important;
        padding-bottom: 5.5px !important;
    }

    .rbc-btn-group:last-child > button:first-child:not(:last-child) {
        padding: 4.5px 12px !important;
        padding-bottom: 5.5px !important;
    }

    .rbc-toolbar .rbc-toolbar-label {
        padding: 0 0 0 12px !important;
        font-size: .85rem !important;
    }

    .rbc-header {
        padding: 4px 3px !important;
        font-size: .75rem !important;
    }
    .rbc-label {
        padding: 0 5.5px !important;
        font-size: .75rem !important;
    }
}




@media all and (max-width: 450px){

    .rbc-btn-group:first-child > button:last-child:not(:first-child) {
        width: 20px;
        height: 20px;
        font-size: .78rem;
        margin-left: 3px;
    }

    .rbc-btn-group:first-child > button:not(:first-child):not(:last-child) {
        width: 20px;
        height: 20px;
        font-size: .78rem;
    }

    .rbc-btn-group:first-child {
        margin-left: 10px;
    }

    .rbc-btn-group:last-child {
        margin-right: 10px;
        font-size: .72rem !important;
    }

    .rbc-btn-group:last-child > button:last-child:not(:first-child) {
        padding: 4px 10px !important;
        padding-bottom: 4.5px !important;
    }

    .rbc-btn-group:last-child > button:first-child:not(:last-child) {
        padding: 4px 12px !important;
        padding-bottom: 4.5px !important;
    }

    .rbc-toolbar .rbc-toolbar-label {
        padding: 0 0 0 10px !important;
        font-size: .76rem !important;
    }

    .rbc-header {
        padding: 4px 3px !important;
        font-size: .7rem !important;
    }
    .rbc-label {
        padding: 0 5.5px !important;
        font-size: .7rem !important;
    }
}
.charts_ofBillsAmount_payed_unpayed{
    width: 96%;
    height: 100%;
    margin: auto;
    padding: 0;
    padding-top: 10px;
}

.charts_ofBillsAmount_payed_unpayed .pexcharts-legend-text{
    font-size: .9rem !important;
    font-weight: 500 !important;
    font-style: var(--body-font) !important;
}
.MuiPaper-rounded {
    padding: 0 !important;
    border-radius: 0 !important;
}


.charts_of_bills_count_byState_container{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.charts_of_bills_count_byState_container .MuiTable-root {
    min-width: 750px !important;
}


.charts_of_bills_count_byState_container .bills_count_byState_state{
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    display: flex;
    justify-content: flex-end;
    padding-right: 15px;
    padding-left: 15px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.charts_of_bills_count_byState_container .bills_count_byState_state .MuiSelect-select.MuiSelect-select {
    color: rgb(74, 76, 74) !important;
    font-size: .9rem;
    padding: 3px 0;
    padding-top: 3.5px;
}

.charts_of_bills_count_byState_container .bills_count_byState_state .MuiInput-underline:before {
    border: none !important;
}

.charts_of_bills_count_byState_container .bills_count_byState_state .MuiSelect-icon {
    color: rgb(74, 76, 74) !important;
}

.charts_of_bills_count_byState_container .bills_count_byState_state .MuiInputBase-root {
    font-family: var(--body-font) !important;
    border: solid 1px rgb(74, 76, 74) !important;
    padding-left: 6px !important;
    border-radius: 4px !important;
}

.charts_of_bills_count_byState_container .bills_count_byState_state .MuiSelect-nativeInput {
    left: 0;
    width: 100%;
    bottom: 0;
    opacity: 0;
    position: absolute;
    pointer-events: none;
}


.charts_of_bills_count_byState_container .container{
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    flex:1 1;
    padding: 0 !important;
}


.bills_widget_table_container{
    padding: 0 !important;
    border-radius: 0 !important;
    margin: 0 !important;
    font-size: .5rem !important;
}


.bills_widget_table_container .MuiTableCell-body:nth-child(1) {
    min-width:125px !important;
}

.bills_widget_table_container .MuiTableCell-body:nth-child(2) {
    min-width:65px !important;
}

.bills_widget_table_container .bill_lign{
    margin-bottom: 4px;
    width: 100%;
}
.all_news_container{
    width:100%;
    height:100%;
    background-color: rgb(251, 251, 251);    
    padding-top: 6px;
}


.news_container{
    width: 98%;
    height: 120px;
    display: flex;
    margin: auto;
    cursor: pointer;
    position: relative;
    margin-bottom: 6px;
    background-color: white;
    border: solid 0.01rem #dadada;
}


.news_container:hover{
    background-color: rgb(250, 250, 250);
}


.img_news_container{
    width: 30%;
    height: 98%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 0%;
    margin: auto !important;
    margin-left: 2px;
}

.title_description_container{
    width: 70%;
    height: 100%;
    position: relative;
    padding-top:8px;
    padding-left: 8px ;
    padding-right: 3px;
}

.news_title{
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    font-size: .87rem;
    font-weight: 700;
    color: #2a2a2a;
}

.news_description{
    margin-top: 4px;
    font-size: .76rem;
    font-weight: 600;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    color: #2a2a2a;
}

.news_released_time{
    position: absolute;
    bottom: 1px;
    right: 9px;
    font-size: .68rem;
    font-weight: 600;
    color: #535353;
}
.MuiPaper-rounded {
    padding: 0 !important;
    border-radius: 0 !important;
}

.bills_widget_table_container{
    padding: 0 !important;
    border-radius: 0 !important;
    margin: 0 !important;
    font-size: .5rem !important;
}

.bills_widget_table_container .MuiTable-root {
    min-width: 700px !important;
}


.bills_widget_table_container .MuiTableCell-body:nth-child(1) {
    min-width:125px !important;
}

.bills_widget_table_container .MuiTableCell-body:nth-child(2) {
    min-width:105px !important;
}

.bills_widget_table_container .MuiTableCell-body:nth-child(3) {
    position: relative;
}

.bills_widget_table_container .bill_container_titles{
    position: absolute;
    top: 0px;
    left: 0px;
    margin-bottom: 0 !important;
    padding: 0 10px;
    padding-top: 15px;
}

.bills_widget_table_container .bill_container{
    display: flex;
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
}

.bills_widget_table_container .bill_infos{
    width: 20% !important;
    min-width: 80px !important;
    margin-right: 4px;
}

.bills_widget_table_container .bill_amount{
    width: 20% !important;
    min-width: 80px !important;
    margin-right: 5px;
}

.bills_widget_table_container .bill_treatedDate{
    width: 20% !important;
    min-width: 80px !important;
    margin-right: 6px;
}


.widget_count_elems_container{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    font-size: 3.8rem;
    cursor: pointer;
    padding: 25px 0;
}


.widget_count_elems_container .number_arrow_container{
    position: relative;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
}

.widget_count_elems_container .arrow_container{
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    position: absolute;
    top: -5px;
    right: -45px;
    display: flex;
    color: green;
}

.widget_count_elems_container .arrow_container .progress_icon{
    width: 2em;
    height: 2em;
}
.charts_of_the_bestSuppliers_container{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.charts_of_the_bestSuppliers_container .container{
    margin: auto;
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
}


.charts_of_the_bestSuppliers_container .select_supplierCategories{
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
    margin-top: 10px;
}

.charts_of_the_bestSuppliers_container .select_supplierCategories .MuiSelect-select.MuiSelect-select {
    color: rgb(74, 76, 74) !important;
    font-size: .9rem;
    padding: 3px 0;
}

.charts_of_the_bestSuppliers_container .select_supplierCategories .MuiInput-underline:before {
    border: none !important;
}

.charts_of_the_bestSuppliers_container .select_supplierCategories .MuiSelect-icon {
    color: rgb(74, 76, 74) !important;
}

.charts_of_the_bestSuppliers_container .select_supplierCategories .MuiInputBase-root {
    font-family: var(--body-font) !important;
    border: solid 1px rgb(74, 76, 74) !important;
    padding-left: 6px !important;
    border-radius: 4px !important;
}

.charts_of_the_bestSuppliers_container .select_supplierCategories .MuiSelect-nativeInput {
    left: 0;
    width: 100%;
    bottom: 0;
    opacity: 0;
    position: absolute;
    pointer-events: none;
}


.charts_of_the_bestSuppliers_container .categorie_container{
    width: 85%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    margin: auto;
    margin-bottom: 8px;
    padding-right: 15px;
    position: relative;
    font-size: 1.2rem;
    font-weight: 700;
}


.charts_of_the_bestSuppliers_container .order_of_the_bestSuppliers {
    width: 50px;
    height: 50px;
    min-width: 50px;
    min-height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    border-radius: 100%;
    position:relative;
    right: -12px;
    font-size: inherit;
}


.charts_of_the_bestSuppliers_container .name_of_the_bestSuppliers{
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    flex: 1 1;
    font-size: inherit;
    padding: 5px 15px;
    text-align: center;
    color: rgb(52, 51, 51);
    background:linear-gradient(to right, #e0b663ad, #FCF6BA );
    border-radius: 3px;
    border-left: none;
    width: fit-content;
    min-width: 145px;
}


.charts_of_the_bestSuppliers_container .css-i4bv87-MuiSvgIcon-root {
    font-size: 2.2rem !important;
}


@media all and (max-width: 1200px){

    .charts_of_the_bestSuppliers_container .categorie_container{
        width: 86%;
        margin-bottom: 7px;
        font-size: 1.08rem;
    }

    .charts_of_the_bestSuppliers_container .name_of_the_bestSuppliers{
        padding: 5px 15px;
        min-width: 135px;
    }
    
    
    .charts_of_the_bestSuppliers_container .order_of_the_bestSuppliers {
        width: 46px;
        height: 46px;
        min-width: 46px;
        min-height: 46px;
        right: -12px;
    }

}


@media all and (max-width: 1080px){

    .charts_of_the_bestSuppliers_container .categorie_container{
        margin-bottom: 6px;
        font-size: 1.02rem;
    }

    .charts_of_the_bestSuppliers_container .name_of_the_bestSuppliers{
        padding: 5px 15px;
        min-width: 135px;
    }
    
    
    .charts_of_the_bestSuppliers_container .order_of_the_bestSuppliers {
        width: 43px;
        height: 43px;
        min-width: 43px;
        min-height: 43px;
        right: -11px;
    }

}



@media all and (max-width: 900px){


    .charts_of_the_bestSuppliers_container .categorie_container{
        margin-bottom: 6px;
        font-size: .92rem;
    }

    .charts_of_the_bestSuppliers_container .name_of_the_bestSuppliers{
        padding: 4px 15px;
        min-width: 135px;
    }
    
    
    .charts_of_the_bestSuppliers_container .order_of_the_bestSuppliers {
        width: 39px;
        height: 39px;
        min-width: 39px;
        min-height: 39px;
        right: -11px;
    }

}


@media all and (max-width: 500px){


    .charts_of_the_bestSuppliers_container .categorie_container{
        margin-bottom: 6px;
        font-size: .82rem;
    }

    .charts_of_the_bestSuppliers_container .name_of_the_bestSuppliers{
        padding: 4px 15px;
        min-width: 125px;
    }
    
    
    .charts_of_the_bestSuppliers_container .order_of_the_bestSuppliers {
        width: 33px;
        height: 33px;
        min-width: 33px;
        min-height: 33px;
        right: -11px;
    }

}


.all_services_identity_container{
    width: 100%;
    height: 100%;
    margin: auto;
    display: flex;
    justify-content: center;
}

.all_services_identity_infos_container{
    width: 95%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    margin: auto;
    padding: 15px 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.all_services_identity_infos{
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    margin: auto;
    display:flex;
    justify-content: space-between;
    align-items: flex-start;
    align-content: flex-start;
    flex-wrap: wrap;
}

.all_services_identity_infos_container .company_infos_header{
    width: 100%;
    margin-top: 19px;
    margin-bottom: 15px;
    padding: 0 8px;
    font-size: 1.5rem;
    color: #343a40;
    font-weight: 600;
    position: relative;
}

.all_services_identity_infos_container .company_infos_header::after{
    content: '';
    position: absolute;
    padding: 0 8px;
    bottom: -2px;
    left: 8px;
    height: 2px;
    display: inline-block;
    background: rgb(86, 121, 121);
    width: 60px;
}

.srv_identity_img{
    flex: 1 1 30ch;
    height: 220px;
    max-width: 455px;
    min-width: 455px;
    margin: 0 7px 45px 7px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
}

.srv_identity_img div:first-child{
    height: 100%;
    width: 220px;
    border-radius: 100%;
    border: solid 2px rgb(153, 153, 153);
    margin-right: 25px;
    position: relative;
    overflow: hidden;
    padding: 0;
}

.srv_identity_img div:last-child{
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.srv_identity_img img{
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    object-fit: cover;
    border: none;
}

.srv_identity_element{
    flex: 1 1 30ch;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    max-width: 455px;
    min-width: 455px;
    margin: 0 7px 25px 7px;
    display: flex;
    justify-content:space-between;
    align-content: center;
    align-items: center;
    align-self: flex-end;
}

.srv_identity_element span:first-child{
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    font-size: .98rem;
    font-weight: 600;
}

.srv_identity_element:last-child{
    flex: 1 1;
}

.srv_identity_element .form-control {
    border: 1px solid #adacac;
    border-radius: 6px;
    background-color: white;
    color: #2c2c2c;
    height: auto;
    font-size: .93rem;
    box-shadow: none;
    width: 268px;
    height: 41px;
    background-image: none !important;
}

.srv_identity_element .form-control:disabled, .form-control[readonly] {
    border: 1px solid #bababa !important;
    background-color: #f8f8f8 !important;
    opacity: .8 !important;
}

.all_services_identity_infos_config{
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    margin-top: 15px;
    display: flex;
    justify-content: flex-end;
}

.all_services_identity_infos_config div:first-child{
    width: 50%;
}


.all_services_identity_infos_config .submit_but{
    margin: 6px 5px;
}


.all_services_identity_container .edit_but{
    background:#ffa726;
    color: white;
}

.all_services_identity_container .edit_but:hover{
    background:#ffa726;
    opacity: 0.9;
}

.all_services_identity_container .valide_but{
    background:#18ac67;
    color: white;
}

.all_services_identity_container .valide_but:hover{
    background:#18ac67;
    opacity: 0.9;
}


@media all and (max-width: 1100px){

    .all_services_identity_infos_container{
        width: 97%;
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
        margin: auto;
        padding: 15px 8px;
    }

    .srv_identity_img{
        flex: 1 1 30ch !important;
        height: 195px !important;
        max-width: 415px !important;
        min-width: 415px !important;
        margin: 0 6px 30px 6px !important;
    }
    
    .srv_identity_element{
        flex: 1 1 30ch !important;
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
        max-width: 415px !important;
        min-width: 415px !important;
        margin: 0 8px 22px 4px !important;
    }


    .srv_identity_img div:first-child{
        height: 100%;
        width: 200px !important;
        border-radius: 100%;
        border: solid 2px rgb(153, 153, 153);
        margin-right: 20px !important;
    }

    .srv_identity_element span:first-child{
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
        font-size: .93rem !important;
        font-weight: 600;
    }

    .srv_identity_element .form-control {
        font-size: .9rem !important;
        width: 248px !important;
        height: 39px !important;
    }

    .all_services_identity_infos_container .company_infos_header{
        margin-top: 15px;
        margin-bottom: 19px;
        padding: 0 4px;
        font-size: 1.4rem;
    }
    
    .all_services_identity_infos_container .company_infos_header::after{
        padding: 0 8px;
        bottom: -2px;
        left: 6px;
        height: 2px;
        width: 60px;
    }
    
}

@media all and (max-width: 918px){

    .all_services_identity_infos_container{
        width: 100%;
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
        margin: auto;
        padding: 15px 1px;
    }
}


@media all and (max-width: 874px){

    .all_services_identity_infos_container{
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
        margin: auto;
    }

    .all_services_identity_infos{
        justify-content: center;
    }

    .all_services_identity_infos_container .company_infos_header{
        width: 98% !important;
        margin-bottom: 24px;
        padding: 0;
        font-size: 1.3rem;
    }
    
    .all_services_identity_infos_container .company_infos_header::after{
        padding: 0 8px;
        bottom: -2px;
        left: 2px;
        height: 2px;
        width: 60px;
    }


    .srv_identity_element{
        margin: 0 7px 17px 4px !important;
    }


}



@media all and (max-width: 450px){

    .all_services_identity_infos_container{
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
        margin: auto;
        padding: 12px 0;
    }

    .all_services_identity_infos{
        justify-content: center;
    }

    .srv_identity_element{
        max-width: 96% !important;
        min-width: 96% !important;
        margin: 0 0 14px 0 !important;
    }
    
    .srv_identity_img{
        height: 145px !important;
        max-width: 97% !important;
        min-width: 97% !important;
        margin: 0 0 20px 0 !important;
    }
    
    .srv_identity_img div:first-child{
        height: 100%;
        width: 150px !important;
        border-radius: 100%;
        border: solid 2px rgb(153, 153, 153);
        margin-right: 10px !important;
    }
    

    .all_services_identity_infos_container .company_infos_header{
        width: 97% !important;
        margin-bottom: 24px;
        padding: 0;
        font-size: 1.45rem;
    }


    .srv_identity_element span:first-child{
        font-size: .8rem !important;
        font-weight: 600;
    }

    .srv_identity_element .form-control {
        font-size: .8rem !important;
        width: 218px !important;
        height: 33px !important;
    }
    
    .all_services_identity_infos_container .company_infos_header::after{
        padding: 0 8px;
        bottom: -2px;
        left: 2px;
        height: 2px;
        width: 60px;
        font-size: 1.2rem !important;
    }

    .all_services_identity_infos_config{
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .all_services_identity_infos_config div:first-child{
        width: 97%;
    }
    
}



.purchase_bills_container{
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    margin: 0;
    margin-bottom: 25px !important;
}

.bills_src_header{
    width: 97% !important;
    margin: auto !important;
    height: -webkit-fit-content !important;
    height: -moz-fit-content !important;
    height: fit-content !important;
    padding-top: 5px;
}

.bills_table_container{
    width: 97% !important;
    margin: auto !important;
    height: -webkit-fit-content !important;
    height: -moz-fit-content !important;
    height: fit-content !important;
}

.bills_table{
    width: 100% !important;
    margin: auto !important;
    height: -webkit-fit-content !important;
    height: -moz-fit-content !important;
    height: fit-content !important;
}


.bills_container .MuiTableCell-head {
    font-weight: 700 !important;
}


.add_bill_container{
    display: flex !important;
    justify-content: space-between !important;
    margin-top: 7px;
}

/*
//////////////////////////////////////////////////////////////
*/

.purchase_bills_container .bill_container_titles{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    font-weight: 700;
    color: rgb(64, 64, 65);
    margin-bottom: 15px;
}


.purchase_bills_container .bill_container_titles .bill_infos_container{
    display: flex;
    width: 80%;
    padding-right: 13px;
}

.purchase_bills_container .bill_container_titles .bill_infos{
    width: 40%;
    min-width: 170px;
    display: flex;
    align-items: center;
    margin-right: 8px;
}

.purchase_bills_container .bill_container_titles .bill_state{
    width: 30%;
}

.purchase_bills_container .bill_container_titles .bill_treatedDate{
    width: 28%;
}

.purchase_bills_container .bill_container_titles .bill_amount{
    width: 28%;
}

.purchase_bills_container .bill_container_titles .bill_name{
    color: rgb(64, 64, 65);
    width: 100%;
}


.purchase_bills_container .bill_container .bill_name{
    color: rgb(64, 64, 65);
    width: 100%;
}

.purchase_bills_container .bill_infos .download_bill_button{
    width: 38px;
    margin-right: 7px;
}


.purchase_bills_container .bill_container_titles .bill_compDocs{
    width: 20%;
    height: 100%;
    margin-bottom: 9px;
    font-weight:700;
    margin: auto;
}


/*
//////////////////////////////////////////////////////////////
*/

.purchase_bills_container .bill_container{
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 2px;
}

.purchase_bills_container .bill_container label{
    margin-right: 8px !important;
    margin-bottom: 0 !important;
}



.purchase_bills_container .bill_infos_container{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 80%; 
    position: relative;
    padding-right: 13px;
}

.purchase_bills_container .bill_container .bill_infos{
    display: flex;
    align-items: center;
    font-weight:600;
    width: 40%;
    min-width: 170px;
    margin-right: 8px;
}

.purchase_bills_container .bill_container .bill_state_container{
    width: 30%;
}

.purchase_bills_container .bill_container .bill_state{
    width:-webkit-fit-content;
    width:-moz-fit-content;
    width:fit-content;
}


.purchase_bills_container .bill_container .bill_treatedDate{
    width: 28%;
    color: rgb(64, 64, 65);
    font-weight:600;
}

.purchase_bills_container .bill_container .bill_amount{
    width: 27%;
    padding-right: 2px;
    color: rgb(64, 64, 65);
    font-weight:600;
}

.purchase_bills_container .bill_container .bill_compDocs{
    width: 20%;
    margin-bottom: 8px;
    font-weight:600;
}

.purchase_bills_container .bill_container .bill_compDocs .bill_compDoc{
    margin-bottom: 6px;
    display: flex;
}

/*
//////////////////////////////////////////////////////////////
*/


.bill_container .bill_state_container .refuse_raison_button{
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin: auto;
    text-decoration: underline;
    margin-top: 2px;
    color: #38a1a5 !important;
    cursor: pointer !important;
}

.bill_container .bill_state_container .refuse_raison_button:hover{
    color: #3ebbc0 !important;
}


.bill_container .bill_compDoc .docs_name{
    width: 110px;
    color: rgb(64, 64, 65);
    display: flex;
    align-items: center;
}




.bills_table_container label + .MuiInput-formControl {
    color: rgb(90, 93, 90) !important;
}

.bills_table_container .MuiSelect-select.MuiSelect-select {
    color: rgb(90, 93, 90) !important;
    padding: 3px 0 !important;
    padding-top: 3.5px !important;
}

.bills_table_container .MuiInput-underline:before {
    border: none !important;
}

.bills_table_container .MuiSelect-icon {
    color: rgb(90, 93, 90) !important;
}

.bills_table_container .MuiInputBase-root {
    font-family: var(--body-font) !important;
    border: solid 1px rgb(90, 93, 90) !important;
    padding-left: 6px !important;
    border-radius: 4px !important;
    font-size: inherit !important;
}

.bills_table_container .MuiSelect-nativeInput {
    left: 0;
    width: 100%;
    bottom: 0;
    opacity: 0;
    position: absolute;
    pointer-events: none;
}

.bills_table_container .css-dk24d0-MuiButtonBase-root-MuiCheckbox-root {
    padding: 0 5px 0 0 !important;
}


.bills_table_container .MuiTableCell-body:nth-child(1) {
    min-width:125px !important;
    max-width:125px !important;
}

.bills_table_container .MuiTableCell-body:nth-child(3) {
    min-width:135px !important;
    max-width:135px !important;
}

.bills_table_container .MuiTableCell-body:nth-last-child(2){
    min-width:100px !important;
    max-width:100px !important;
}

.bills_table_container .MuiTableCell-body:last-child {
    min-width:770px !important;
    max-width:770px !important;
}

.bills_table_finance_container .MuiTableCell-body:last-child {
    min-width:900px !important;
    max-width:900px !important;
}


.purchase_bills_container .add_bill_container{
    display: flex !important;
    justify-content: space-between !important;
    margin-top: 7px;
}

.purchase_bills_container .add_bill_container .add_button{
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin: auto;
    text-decoration: underline;
    margin-top: 2px;
    margin-right: 7px;
    color: #38a1a5 !important;
    cursor: pointer !important;
}


.purchase_bills_container .add_bill_container .add_button:hover{
    color: #3ebbc0 !important;
}


@media all and (max-width: 645px){
    
    .bills_table_container .MuiTableCell-body:nth-child(1) {
        min-width:110px !important;
        max-width:110px !important;
    }

    .bills_table_container .MuiTableCell-body:nth-child(3) {
        min-width:125px !important;
        max-width:125px !important;
    }

    .bills_table_container .MuiTableCell-body:nth-last-child(2){
        min-width:90px !important;
        max-width:90px !important;
    }

    .bills_table_container .MuiTableCell-body:last-child {
        min-width:700px !important;
        max-width:700px !important;
    }

    .bills_table_finance_container .MuiTableCell-body:last-child {
        min-width:820px !important;
        max-width:820px !important;
    }

}

.view_state_details_container .bill_state_byService{
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
}

.view_state_details_container .bill_state_byService div:first-child{
    font-size: .96rem;
}

.view_state_details_container .MuiOutlinedInput-multiline {
    padding: 7.5px 14px !important;
}

.view_state_details_container .first_textField_container{
    width: 100%;
    margin-top: 8px;
}

.view_state_details_container .second_textFields_container{
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin: 22px auto;
    flex-wrap: wrap;
}

.view_state_details_container .second_textFields_container .container_11{
    width: 49%;
    margin-right: 6px;
}

.view_state_details_container .second_textFields_container .container_12{
    width: 49%;
    margin-left: 6px;
}

@media all and (max-width: 662px){

    .view_state_details_container .second_textFields_container{
        margin: 15px auto !important;
    }
    
    .view_state_details_container .bill_state_byService div:first-child{
        font-size: .87rem !important;
    }    

    .view_state_details_container .second_textFields_container .container_11{
        width: 100% !important;
        margin-right: 0 !important;
        margin-bottom: 8px;
    }
    
    .view_state_details_container .second_textFields_container .container_12{
        width: 100% !important;
        margin-left: 0 !important;
    }
}
.add_purchase_request_mainPage{
    width: 670px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    margin: auto;
    margin-top: 50px;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    flex-wrap: wrap;
}

.purchase_request_type_container{
    flex:1 1 225px;
    max-width: 305px !important;
    height: 95px !important;
    box-shadow: 0px 0px 3px 1px #e0e0e0 !important;
    margin: 25px 10px;
    margin-top: 0;
    border-radius: 5px;
    display:flex;
    background-color: white;
    cursor: pointer;
    transition: all 150ms ease-in-out 0s;
    -webkit-transform: scale(1);
            transform: scale(1);
}

.purchase_request_type_container:hover{
    transition: all 100ms ease-in-out 0s;
    background-color: rgb(253, 253, 253);
    -webkit-transform: scale(1.02);
            transform: scale(1.02);
}

.add_purchase_request_mainPage .request_type_icon{
    width: 25%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    padding-left: 5px;
}

.add_purchase_request_mainPage .request_type_icon .MuiSvgIcon-root {
    width: 38px !important;
    height: 38px !important;
    color: rgb(80, 79, 79) !important;
}

.add_purchase_request_mainPage .request_type{
    width: 78%;
    height: 100%;
    font-weight: 600;
    padding: 15px 4px;
    padding-right: 5px;
    font-size: 1.17rem;
    color: rgb(80, 79, 79) !important;
    display: flex;
    align-items: center;
    align-content: center;
}

.catalogue_modal{
    width: 100%;
    height: 100%;
    margin: 30px 0 ;
}


.products_gallery_modal_container{
    width: 100% !important;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: auto !important;
}


.products_gallery_modal_header_container{
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 18px 30px 15px 30px !important;
}


.products_gallery_modal_container .search_filter_container{
    width: 100%;
}


.products_gallery_modal_contents_container{
    flex: 1 1;
    width: 98%;
    margin: 15px auto;
    display:flex;
    flex-direction: column;
}

.products_contents_container{
    width: 100%;
    display:flex;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
}

.products_gallery_modal_container  .main_card_container{
    width: 42%;
    height: 342px;
    min-width: 263px;
    max-width: 263px !important;
    margin: 15px 17px !important;
    border-radius: 4px;
    box-shadow: 0px 1px 2px -1px rgb(0 0 0 / 20%), 0px 4px 8px 0px rgb(0 0 0 / 14%), 0px 1px 15px 0px rgb(0 0 0 / 12%);
    display: flex;
    flex-direction: column;
    padding: 0;
    position: relative;

}

.main_card_img_container{
    width: 100%;
    height: 48.5%;
    max-height:-webkit-min-content;
    max-height:min-content;
    position: relative;
}
.main_card_img_container img{
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    object-fit: cover !important;
    border: none;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
}

.products_gallery_modal_container .main_card_infos_container{
    flex: 1 1;
    width: 100%;
    background-color: rgb(252, 253, 253);
    border-top: solid 1px rgb(228, 228, 228);
    display: flex;
    flex-direction: column;
    padding: 16px 0;
    padding-top: 13px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
}

.products_gallery_modal_container .card_title{
    width: 98%;
    height: 1.55rem;
    padding: 0px 20px 0 10px;
    margin: auto;
    margin-bottom: 0;
    font-size: 1.15rem;
    font-weight: 700;
    display: flex;
    text-align: center;
    justify-content: center;
    text-transform: capitalize;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}

.main_card_infos_container .card_title:last-child{
    margin-top: 5px !important;
}   


.products_gallery_modal_container .card_description{
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    color: rgb(86, 86, 86);
    width: 98%;
    margin: auto;
    padding: 5px 10px;
    margin-bottom: 2px;
    height: 5em;
    display: flex;
    text-align: center;
    justify-content: center;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}

.products_gallery_modal_container .view_more_button_container{
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,.6);
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 0;
    opacity: 0;
    transition: all 190ms ease-in-out 0s;
    border-radius: 4px;
}

.products_gallery_modal_container .main_card_container:hover > .main_card_infos_container > .view_more_button_container{
    opacity: 1;
    transition: all 190ms ease-in-out 0s;
}



.products_gallery_modal_container .view_more_button_container button{
    all: unset;
    font-size: 1rem;
    padding: .4rem .6rem;
    border: 1px solid rgb(255, 255, 255);
    color: white;
    border-radius: 5px;
    cursor: pointer;
    position: relative;
}


.products_gallery_modal_container .view_more_button_container button:hover{
    border: 1px solid rgb(230, 226, 226);
    background-color: rgba(0, 0, 0, .7);
}


.catalogue_modal .MuiDialog-paperFullWidth {
    width: calc(100% - 64px) !important;
    height: calc(100% - 75px) !important;
    margin: 5px auto !important;
}

.catalogue_modal .MuiDialogContent-root {
    padding: 0px !important;
}

@media all and (max-width: 1160px){

    .products_gallery_modal_container  .main_card_container {
        height: 320px !important;
        min-width: 250px !important;
        max-width: 250px !important;
        margin: 14px 15px !important;
    }

    .products_gallery_modal_container .card_title{
        height: 1.55rem;
        padding: 0px 20px 0 10px;
        font-size: 1.1rem;
    }
    
    .products_gallery_modal_container  .card_description{
        padding: 5px 10px;
        font-size: .88rem !important;
    }

    .products_gallery_modal_container .main_card_infos_container {
        padding: 12px 0;
        padding-top: 10px;
    }
}

@media (max-width: 1000px){
    .products_gallery_modal_container  .main_card_container {
        height: 300px !important;
        min-width: 240px !important;
        max-width: 240px !important;
        margin: 12px 12px !important;
    }

    .main_card_img_container {
        max-height: 138px !important;
        min-height: 138px !important;
    }

    .products_gallery_modal_container  .card_title{
        padding: 0px 20px 0 10px;
        font-size: 1.05rem;
    }
    
    .products_gallery_modal_container  .card_description{
        font-size: .85rem !important;
    }
}


@media (max-width: 680px){
    .catalogue_modal .MuiDialog-paperFullWidth, .products_gallery_modal .MuiDialog-paperFullWidth {
        width: 97% !important;
        margin: 5px auto !important;
    }

    .products_gallery_modal_header_container {
        padding: 18px 15px 15px 15px !important;
    }
}


@media (max-width: 578px){
    .products_gallery_modal_container  .main_card_container {
        height: 240px !important;
        min-width: 200px !important;
        max-width: 200px !important;
        margin: 8px 5px !important;
    }

    .main_card_img_container {
        max-height: 110px !important;
        min-height: 110px !important;
    }

    .products_gallery_modal_container .main_card_infos_container {
        padding: 0px !important;
        padding-top: 0px;
    }

    .products_gallery_modal_container  .card_title {
        font-size: .9rem !important;
        margin-top: 12px !important;
    }

    .products_gallery_modal_container  .card_description {
        font-size: .7rem !important;
        margin-top: 2px !important;
    }

    .products_gallery_modal_container .main_card_infos_container .card_title:last-child {
        margin-top: 3px !important;
    }
}
.products_gallery_modal{
    width: 100%;
    height: 100%;
    margin: 30px 0 ;
}

.products_gallery_modal .modal_header_container{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: solid 1px #989898;
    padding: 5px 30px 8px 25px !important;
    background-color: #fbf9f9;
}


.products_gallery_modal .products_gallery_modal_container{
    width: 100% !important;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: auto !important;
}

.products_gallery_modal #products_number{
    top: 0px;
}



.products_gallery_modal .products_gallery_modal_header_container{
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 0px 5px 15px 30px;
}


.products_gallery_modal .products_gallery_modal_contents_container .view_more_button_container{
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,.6);
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 0;
    opacity: 0;
    transition: all 190ms ease-in-out 0s;
    border-radius: 4px;
}

.products_gallery_modal .main_card_container:hover > .main_card_infos_container > .view_more_button_container{
    opacity: 1;
    transition: all 190ms ease-in-out 0s;
}

.products_gallery_modal .products_gallery_modal_contents_container .view_more_button_container button{
    all: unset;
    font-size: 1rem;
    padding: .4rem .6rem;
    border: 1px solid rgb(255, 255, 255);
    color: white;
    border-radius: 5px;
    cursor: pointer;
    position: relative;
}


.products_gallery_modal .products_gallery_modal_contents_container .view_more_button_container button:hover{
    border: 1px solid rgb(230, 226, 226);
    background-color: rgba(0, 0, 0, .7);
}


.products_gallery_header_container{
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 20px 25px;
}



.products_gallery_modal .MuiDialog-paperFullWidth {
    width: calc(100% - 40px) !important;
    height: calc(100% - 70px);
    margin: 5px auto !important;
}

.products_gallery_modal .MuiDialogContent-root {
    padding: 0px !important;
}

@media all and (max-width: 550px){
    .products_gallery_modal .MuiDialog-paperFullWidth {
        width: 97%!important;
        height: calc(100% - 70px);
        margin: 5px auto !important;
    }
}
.products_gallery_container{
    width: 100% !important;
    height: 100%;
    display: flex;
    flex-direction: column;
}



.products_gallery_container .search_filter_container{
    display: flex;
    width: 100%;
    justify-content: flex-end;
}



.products_gallery_contents_container .view_more_button_container button{
    border: 1px solid rgb(255, 255, 255);
    background-color: rgba(0, 0, 0, .4);
    color: white;
    position: relative;
    text-transform: none !important;
}


.products_gallery_contents_container .view_more_button_container button:hover{
    border: 1px solid rgb(230, 226, 226);
    background-color: rgba(0, 0, 0, .7);
}


@media (max-width: 845px){
    .products_gallery_container .search_filter_container {
        display: flex;
        width: 100%;
        justify-content: center;
    }
}



.products_gallery_contents_container .card_title {
    margin-top: 7px;
}

.products_gallery_contents_container .card_description {
    margin-top: 10px;
}


.emptytable_msg_container{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    font-weight: 600;
}

.order_off_catalogue_form_page_container{
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    display: flex;
    justify-content: space-between;
    padding: 20px 15px;
    position: relative;
    align-items: flex-start !important;
    align-content: flex-start !important;
    flex-wrap: wrap;
}


.order_off_catalogue_form_page_container .off_catalogue_form_articles_container{
    width: 44%;
    display: flex;
    flex-direction: column;
}

.order_off_catalogue_form_page_container .off_catalogue_form_container{
    width: 55%;
    display: flex;
    flex-direction: column;
}



.order_off_catalogue_form_container{
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    margin: auto;
    border: solid 1px rgb(215, 212, 212);
    background-color: #fdfdfd;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    padding: 5px;
    padding-top: 13px;
}


.order_off_catalogue_form_infos_container{
    width: 100% !important;
    margin: 0 !important;
    padding: 0 !important;
}


.order_off_catalogue_form_infos_container .row {
    margin: 5px 0 10px 0;
}


.order_off_catalogue_form_infos_container .order_off_catalogue_form_infos_title{
    padding: 0 15px;
    font-size: 1.1rem;
    font-weight: 600;
    color: #526f87;
    margin: 18px 0 7px 0;
}

.order_off_catalogue_form_infos_container .row label {
    margin-bottom: 0.25rem !important;
}

.order_off_catalogue_form_infos_container .form-control {
    border: 1px solid #c4c3c3;
    border-radius: 4px;
    color: #2c2c2c;
    height: auto;
    font-size: .9rem;
    box-shadow: none;
    margin-bottom: 12px;
    height: 39px;
    background-image: none !important;
}

.order_off_catalogue_form_page_container .order_off_catalogue_form_infos_container textarea {
    height: auto !important;
    padding: 8px 11px !important;
    box-shadow: none !important;
    border: 1px solid #c4c3c3 !important;
    color: #2c2c2c !important;
}

.order_off_catalogue_form_page_buttons_container{
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    display: flex;
    justify-content: flex-end;
    margin: 30px auto;
}



.order_off_catalogue_form_page_container .articles_view_container{
    width: 100% !important;
    height: -webkit-fit-content !important;
    height: -moz-fit-content !important;
    height: fit-content !important;
    border: solid 1px rgb(215, 212, 212);
    background-color: #fdfdfd;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    padding: 12px 15px;
    margin-bottom: 10px;
}

.order_off_catalogue_form_page_container .articles_view_header_container{
    font-size: 1.15rem;
    font-weight: 700;
    color: rgb(228, 88, 32);
    display: flex;
    justify-content: space-between;
}

.articles_view_header_container .add_icon_container{
    border-radius: 100%;
    cursor: pointer;
    width: 25px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.articles_view_header_container .add_icon_container:hover{
    background-color: rgba(188, 188, 188, 0.2);
}

.order_off_catalogue_form_page_container .article_view_header_container{
    font-weight: 600;
    color: #526f87;
    display: flex;
    justify-content: space-between;
}

.article_view_header_container .add_icon_container{
    border-radius: 100%;
    cursor: pointer;
    width: 25px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.article_view_header_container .add_icon_container:hover{
    background-color: rgba(188, 188, 188, 0.2);
}

.order_off_catalogue_form_page_buttons_container .submit_but{
    background:rgb(228, 88, 32);
    color: white;
}

.order_off_catalogue_form_page_buttons_container .submit_but:hover{
    background: rgb(228, 88, 32);
    opacity: .9;
}


.order_off_catalogue_form_page_buttons_container .return_button{
    background:rgb(228, 32, 74);
}

.order_off_catalogue_form_page_buttons_container .return_button:hover{
    background:rgb(228, 32, 74);
    opacity: .9;
}


@media all and (max-width: 900px){

    .order_off_catalogue_form_page_container .off_catalogue_form_articles_container{
        width: 85%;
        margin: auto;
    }

    .order_off_catalogue_form_page_container .off_catalogue_form_container{
        width: 85%;
        margin: auto;
    }

    .order_off_catalogue_form_page_container .order_form_title::after{
        height: 2.5px;
        width: 38%;
    }

    .order_off_catalogue_form_page_container .articles_view_container{
        padding: 9px 15px;
    }
}


@media all and (max-width: 750px){
    .order_off_catalogue_form_page_container .order_form_title::after{
        height: 2px;
        width: 32%;
    }
}

@media all and (max-width: 580px){

    .order_off_catalogue_form_page_container{
        padding: 9px;
    }

    .order_off_catalogue_form_page_container .articles_view_container{
        padding: 9px 12px;
    }

    .order_off_catalogue_form_page_container .off_catalogue_form_articles_container{
        width: 95%;
        margin: auto;
    }

    .order_off_catalogue_form_page_container .off_catalogue_form_container{
        width: 95%;
        margin: auto;
    }

    .order_off_catalogue_form_page_container .articles_view_header_container{
        font-size: 1.05rem;
        font-weight: 700;
        color: rgb(228, 88, 32);
        display: flex;
        justify-content: space-between;
    }

    .articles_view_header_container .add_icon_container{
        width: 23px;
        height: 23px;
    }
}


@media all and (max-width: 379px){

    .order_off_catalogue_form_page_container{
        padding: 5px;
    }

    .order_off_catalogue_form_page_container .off_catalogue_form_articles_container{
        width: 99%;
        margin: auto;
    }

    .order_off_catalogue_form_page_container .off_catalogue_form_container{
        width: 99%;
        margin: auto;
    }

    .order_off_catalogue_form_page_container .order_form_title::after{
        left: 7px;
    }

    .order_off_catalogue_form_page_container .articles_view_container{
        padding: 9px 6px;
    }

    .articles_view_header_container .add_icon_container{
        width: 20px;
        height: 20px;
    }

}

.set_refuse_raison_container .MuiOutlinedInput-multiline {
    padding: 7.5px 14px !important;
}
/*
///////////////////////////////////////////////////////
/////////////////////////////////////////////////////*/



.product_details_modal_container{
    width: 100%;
    height: -webkit-max-content;
    height: max-content;
    position: relative;
    overflow: hidden !important;
    display: flex;
    margin-top: 2px;
    padding: 20px 15px;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
}

.product_details_carousel_container{
    width: 50% !important;
    min-height: 420px;
    height: calc(84vw / 3);
    position: relative;
}

.product_details_carousel_container .carousel-item {
    width: 100%;
    min-height: 380px;
    height:calc((84vw / 3) - 40px);
    position: relative !important;
    align-items: center;
    text-align: center;
    background: rgba(0,0,0,.02);
}

.product_details_carousel_container .carousel-item img{
    max-width: 100% !important;
    height: 100% !important;
    object-fit: cover !important;
}

.carousel-control-next-icon , .carousel-control-prev-icon{
    background-color: rgba(0,0,0,0.9);
    -webkit-filter: brightness(3);
            filter: brightness(3);
    width: 40px !important;
    height: 40px !important;
    background-size: 30% 30%;
    border-radius: 100%;
}

.product_details_carousel_container .carousel-indicators{
    bottom: -50px !important;
}

.product_details_carousel_container .carousel-indicators [data-bs-target] {
    width: 10px;
    height: 10px;
    background-color: rgb(11, 79, 82) !important;
    border: solid 0.5px rgb(255, 255, 255);
    border-radius: 9px;
}


.carousel .carousel-inner {
    box-shadow: 0px 5px 15px 0px rgb(0 0 0 / 30%) !important;
    border-radius: 0.1875rem;
}

.product_details_infos_container{
    flex:1 1;
    height: 280px;
    margin-left: 20px;
    border: solid 1px rgb(230, 230, 230);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 19px;
    background-color: rgb(253, 253, 253);
    border-radius: 5px ;
}


.product_details_infos_container .card_title_descr{
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    max-height: 6rem;
    font-size: 1.55rem;
    font-weight: 700;
    text-transform: capitalize;
    color: rgb(31, 107, 132);
    overflow: hidden;
    text-overflow: ellipsis;
    display: flex;
    justify-content: flex-start;
    padding: 0;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    line-height: 1.85rem;
}

.product_details_modal_container .card_price_descr{
    font-size: 1.05rem;
    font-weight: 700;
    color:rgb(228, 88, 32);
}

.product_details_modal_container .product_details_infos_container .card_categorie_descr{
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    font-size: 1rem;
    margin: 0;
    color: #343a40;
}

.product_details_modal_container .product_details_infos_container .card_categorie_descr:last-child{
    margin-bottom: 3px;
}

.product_details_modal_container .product_details_infos_container .card_categorie_descr span{
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin-right: 3px;
    font-weight: 700;
}


.product_details_modal_container .product_details_infos_container .card_description_details{
    width: 100%;
    font-size: 1rem;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    justify-content: flex-start;
    padding: 0;
    text-align: left;
}


.supplier_product_details_modal_container .product_details_infos_container .card_description_details {
    width: 100%;
    font-size: 1rem;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    justify-content: flex-start;
    padding: 0;
    text-align: left;
}

.product_details_modal_container .product_details_infos_container .view_more_buttons_cont{
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    display: flex;
    align-items: center;
    opacity: 1;
}


.product_details_infos_container .view_more_buttons_cont .amount_container {
    border-radius: 5px;
    width: 100px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    padding: 10px 9px;
    font-size: 1rem;
    margin-right: 20px;
}

.product_details_infos_container .view_more_buttons_cont .submit_but{
    background:rgb(228, 88, 32);
    color: white !important;
}

.product_details_infos_container .view_more_buttons_cont .submit_but:hover{
    background: rgb(228, 88, 32);
    opacity: .9;
}



.product_details_infos_container input[type=number]::-webkit-inner-spin-button, .product_details_infos_container
input[type=number]::-webkit-outer-spin-button {  
   opacity: 1;
}

.product_details_modal_container .details_container{
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    margin-top: 30px;
    border: solid 1px rgb(230, 230, 230);
    display: flex;
    flex-direction: column;
    padding: 20px;
    background-color: rgb(253, 253, 253);
    border-radius: 5px ;
}

.product_details_modal_container .details_container .container_title{
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    font-size: 1.5rem;
    font-weight: 700;
    text-transform: capitalize;
    color: rgb(228, 88, 32);
    position: relative;
}

.product_details_modal_container .details_container .container_title::after{
    content: '';
    height: 2px;
    width: 70%;
    background-color: rgb(228, 88, 32);
    position: absolute;
    bottom: -2px;
    left: 1px;
}

.product_details_modal_container .details_container .description{
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    font-size: 1.07rem;
    margin: 20px 0 10px 0;
}


@media all and (max-width: 1160px){
    .product_details_modal_container{
        padding: 20px 10px;
    }

    .product_details_carousel_container{
        width: 55% !important;
    }
 
    .product_details_infos_container{
        height: 275px;
        margin-left: 18px;
        padding: 16px;
    }

    .product_details_infos_container .card_title_descr{
        font-size: 1.35rem;
        line-height: 1.8rem;
    }


    .product_details_modal_container .card_price_descr{
        font-size: 1rem;
    }

    .product_details_modal_container .product_details_infos_container .card_categorie_descr {
        font-size: .95rem;
    }

    .product_details_modal_container .product_details_infos_container .card_description_details {
        font-size: .95rem;
    }

    .product_details_infos_container .view_more_buttons_cont .amount_container {
        padding: 8px 7px;
        font-size: .95rem;
        width: 85px;
        margin-right: 18px;
    }

    .product_details_modal_container .details_container {
        margin-top: 25px;
    }

    .product_details_modal_container .details_container .container_title {
        font-size: 1.4rem;
    }

    .product_details_modal_container .details_container .description {
        font-size: .95rem;
        margin: 20px 0 10px 0;
    }

}


@media all and (max-width: 1070px){


    .product_details_carousel_container{
        width: 52% !important;
        min-height: 380px;
    }
    
    .product_details_carousel_container .carousel-item {
        min-height: 340px;
    }
 
    .product_details_infos_container{
        height: 265px;
        margin-left: 18px;
        padding: 12px 14px;
    }

    .product_details_modal_container .details_container {
        margin-top: 25px;
    }

    .product_details_modal_container .details_container .container_title {
        font-size: 1.4rem;
    }

    .product_details_modal_container .details_container .description {
        font-size: .95rem;
        margin: 20px 0 10px 0;
    }


    .carousel-control-next-icon , .carousel-control-prev-icon{
        width: 38px !important;
        height: 38px !important;
        background-size: 30% 30%;
    }
    

}



@media all and (max-width: 1000px){
 
    .product_details_infos_container {
        height: 250px;
        margin-left: 18px;
        padding: 15px;
    }

    .product_details_infos_container .view_more_buttons_cont .amount_container {
        padding: 8px 7px;
        font-size: .9rem;
        width: 80px;
        margin-right: 18px;
    }

    .product_details_infos_container .card_title_descr{
        font-size: 1.2rem;
        line-height: 1.5rem;
    }

    .product_details_modal_container .card_price_descr{
        font-size: 1rem;
    }

    .product_details_modal_container .product_details_infos_container .card_categorie_descr {
        font-size: .9rem;
    }

    .product_details_modal_container .product_details_infos_container .card_description_details {
        font-size: .9rem;
    }


    .product_details_modal_container .details_container {
        margin-top: 25px;
    }

    .product_details_modal_container .details_container .container_title {
        font-size: 1.4rem;
    }

    .product_details_modal_container .details_container .description {
        font-size: .95rem;
        margin: 20px 0 10px 0;
    }

}



@media all and (max-width: 900px){
 
    .product_details_infos_container {
        height: 240px;
        margin-left: 18px;
        padding: 14px;
    }

    .product_details_modal_container .details_container {
        padding: 15px;
    }

    .product_details_infos_container .view_more_buttons_cont .amount_container {
        padding: 8px 7px;
        font-size: .9rem;
        width: 80px;
        margin-right: 18px;
    }

    .product_details_modal_container .details_container {
        margin-top: 25px;
    }

    .product_details_modal_container .details_container .container_title {
        font-size: 1.2rem;
    }

    .product_details_modal_container .details_container .description {
        font-size: .9rem;
        margin: 20px 0 0 0;
    }

    .carousel-control-next-icon , .carousel-control-prev-icon{
        width: 36px !important;
        height: 36px !important;
        background-size: 30% 30%;
    }
    

}


@media all and (max-width: 820px){
    .product_details_carousel_container{
        width: 85% !important;
        min-height: 400px;
        margin-bottom: 25px;
    }

    .product_details_carousel_container .carousel-item {
        min-height: 360px;
    }

    .product_details_infos_container {
        margin-left: 0;
    }

    .product_details_modal_container {
        padding: 20px 20px;
    }

    .carousel-control-next-icon , .carousel-control-prev-icon{
        width: 35px !important;
        height: 35px !important;
        background-size: 30% 30%;
    }
}



@media all and (max-width: 550px){

    .product_details_carousel_container{
        width: 92% !important;
        margin-bottom: 20px;
    }

    .product_details_modal_container {
        padding: 10px;
    }

    .product_details_carousel_container .carousel-indicators [data-bs-target] {
        width: 9px;
        height: 9px;
    }

    .product_details_carousel_container .carousel-indicators {
        bottom: -45px !important;
    }

    .product_details_infos_container .view_more_buttons_cont .amount_container {
        padding: 8px 7px;
        font-size: .85rem;
        width: 70px;
        margin-right: 14px;
    }

    .product_details_infos_container {
        height: 218px;
        padding: 12px 9px;
    }

    .product_details_infos_container .card_title_descr{
        font-size: 1.1rem;
        line-height: 1.23rem;
    }

    .product_details_modal_container .card_price_descr{
        font-size: .9rem;
    }

    .product_details_modal_container .product_details_infos_container .card_categorie_descr {
        font-size: .85rem;
    }

    .product_details_modal_container .product_details_infos_container .card_description_details {
        font-size: .85rem;
    }


    .product_details_modal_container .details_container {
        margin-top: 20px;
        padding: 12px 9px;
    }

    .product_details_modal_container .details_container .container_title {
        font-size: 1.1rem;
    }

    .product_details_modal_container .details_container .description {
        font-size: .9rem;
        margin: 15px 0 0 0;
    }
}


@media (max-width: 450px){

    .product_details_carousel_container{
        width: 96% !important;
        min-height: 350px;
        margin-bottom: 15px;
    }

    .product_details_carousel_container .carousel-item {
        min-height: 310px;
    }

    .product_details_modal_container {
        padding: 10px 5px;
    }

    .carousel-control-next-icon , .carousel-control-prev-icon{
        width: 33px !important;
        height: 33px !important;
        background-size: 30% 30%;
    }
}
.card_container_modal{
    width: 100%;
    flex:1 1;
    display: flex;
    flex-direction: column;
}

.card_container_modal .card_products_table_container{
    width: 94% !important;
    height: 100%;
    margin: 25px auto;
}

.card_container_modal .proceed_button_container{
    width: 94% !important;
    margin: auto;
    display: flex;
    justify-content: flex-end; 
}

.card_container_modal .proceed_button_container .submit_but{
    background:rgb(228, 88, 32);
    color: white;
}

.card_container_modal .proceed_button_container .submit_but:hover{
    background: rgb(228, 88, 32);
    opacity: .9;
}

.card_products_table_container .MuiTableCell-head {
    background: rgb(69 81 83) !important;
    color: white !important;
    padding: 9px 10px;
    font-size: 1rem;
}
  


.card_products_table_container .product_cell_img_container{
    width: 115px;
    height: 120px;
    display: flex;
    border: solid 1px rgb(194, 194, 194);
}

.card_products_table_container .product_cell_img_container img{
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    object-fit: cover !important;
    border: none;
}

.card_products_table_container .product_cell_title_container{
    width: 230px;
    margin-left: 20px;
    font-size: 1.1rem;
    font-weight: 700;
    color: rgb(57, 57, 57);
}

.card_products_table_container .product_cell_title_container #title{
    text-transform: capitalize;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}

.card_products_table_container .product_cell_title_container .product_cell_categorie_container{
    width: 100%;
    font-size: .85rem;
    font-weight: 500;
    text-transform: capitalize;
    color: rgb(59, 91, 91);
}

.card_products_table_container .product_cell_title_container .product_cell_categorie_container span{
    font-weight: 700;
}

.card_products_table_container .amount_container {
    border-radius: 5px;
    width: 100px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    padding: 6px 9px;
    font-size: inherit;
}


.card_products_table_container input[type=number]::-webkit-inner-spin-button, .product_details_infos_container
input[type=number]::-webkit-outer-spin-button {  
   opacity: 1;
}

.card_container_modal .card_products_table_container .MuiTableCell-body:nth-child(1) {
    min-width:130px !important;
    font-weight: 600;
}

.card_container_modal .card_products_table_container .MuiTableCell-body:nth-child(2) {
    min-width:400px !important;
}

.card_container_modal .card_products_table_container .MuiTableCell-body:nth-child(3) {
    min-width:100px !important;
}

.card_container_modal .card_products_table_container .MuiTableCell-body:nth-child(4) {
    min-width:70px !important;
}

.card_container_modal .card_products_table_container .MuiTableCell-body:nth-child(5) {
    min-width:100px !important;
}

.card_container_modal .card_products_table_container .MuiTableCell-body:nth-child(6) {
    min-width:55px !important;
}





@media all and (max-width: 1100px){
    .card_products_table_container .amount_container {
        width: 80px !important;
    }
    .card_products_table_container .product_cell_img_container {
        width: 92px;
        height: 96px;
    }
    .card_products_table_container .product_cell_title_container {
        flex: 1 1;
        margin-left: 17px;
        font-size: 1rem;
    }
    .card_products_table_container .product_cell_title_container .product_cell_categorie_container {
        font-size: .82rem;
    }

    .card_container_modal .card_products_table_container .MuiTableCell-body:nth-child(2) {
        min-width:385px !important;
    }
}


@media all and (max-width: 900px){
    .card_products_table_container .amount_container {
        width: 75px !important;
    }
    .card_products_table_container .product_cell_img_container {
        width: 91px;
        height: 95px;
    }
    .card_products_table_container .product_cell_title_container {
        flex: 1 1;
        margin-left: 14px;
    }

    .card_container_modal .card_products_table_container .MuiTableCell-body:nth-child(2) {
        min-width:370px !important;
    }

    .card_container_modal .card_products_table_container{
        width: 96% !important;
        margin: 25px auto;
    }

    .card_container_modal .proceed_button_container{
        width: 96% !important;
    }
}

@media all and (max-width: 700px){
    .card_products_table_container .product_cell_title_container {
        margin-left: 12px;
        font-size: .9rem;
    }
    .card_products_table_container .product_cell_title_container .product_cell_categorie_container {
        font-size: .75rem;
    }

    .card_container_modal .card_products_table_container .MuiTableCell-body:nth-child(2) {
        min-width:365px !important;
    }

    .card_container_modal .card_products_table_container{
        width: 98% !important;
        margin: 20px auto;
    }

    .card_container_modal .proceed_button_container{
        width: 98% !important;
    }
}

@media all and (max-width: 600px){

    .card_products_table_container .product_cell_img_container {
        width: 79px;
        height: 83px;
    }
    .card_products_table_container .amount_container {
        width: 60px !important;
        padding: 4px 8px !important;
    }
    .card_products_table_container .product_cell_title_container {
        margin-left: 9px;
        font-size: .82rem;
    }
    .card_products_table_container .product_cell_title_container .product_cell_categorie_container {
        font-size: .7rem;
    }

}



@media all and (max-width: 450px){

    .card_products_table_container .product_cell_img_container {
        width: 69px;
        height: 73px;
    }

    .card_products_table_container .amount_container {
        width: 50px !important;
        padding: 3.5px 4px !important;
    }
    .card_products_table_container .product_cell_title_container {
        margin-left: 6px;
        font-size: .8rem;
    }
    .card_products_table_container .product_cell_title_container .product_cell_categorie_container {
        font-size: .65rem;
    }
    .card_container_modal .card_products_table_container .MuiTableCell-body:nth-child(2) {
        min-width:330px !important;
    }

}


@media all and (max-width: 380px){

    .card_products_table_container .product_cell_img_container {
        width: 65px;
        height: 69px;
    }

    .card_products_table_container .product_cell_title_container {
        margin-left: 6px;
        font-size: .76rem;
    }
    .card_products_table_container .product_cell_title_container .product_cell_categorie_container {
        font-size: .6rem;
    }

    .card_container_modal .card_products_table_container .MuiTableCell-body:nth-child(2) {
        min-width:320px !important;
    }

}


.order_form_page_container{
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    display: flex;
    flex-direction: column;
    padding: 20px;
}

.order_form_container{
    width: 60%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    margin: auto;
    border: solid 1px rgb(215, 212, 212);
    background-color: #fdfdfd;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    padding: 5px;
    padding-top: 10px;
}

.order_form_title{
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    font-size: 1.4rem;
    font-weight: 700;
    color: rgb(228, 88, 32);
    position: relative;
    padding: 7px 15px;
    padding-bottom: 3px;
}

.order_form_title::after{
    content: '';
    height: 3px;
    width: 45%;
    background-color: rgb(228, 88, 32);
    position: absolute;
    bottom: -2px;
    left: 16px;
}

.order_form_infos_container{
    width: 100%;
    margin-top: 14px;
}

.order_form_infos_container .row {
    margin: 5px 0 10px 0;
}


.order_form_infos_container .order_form_infos_title{
    padding: 0 15px;
    font-size: 1.1rem;
    font-weight: 600;
    color: #526f87;
    margin: 18px 0 7px 0;
}

.order_form_infos_container .row label {
    margin-bottom: 0.25rem !important;
}

.order_form_infos_container .form-control {
    border: 1px solid #c4c3c3;
    border-radius: 4px;
    color: #2c2c2c;
    height: auto;
    font-size: .9rem;
    box-shadow: none;
    margin-bottom: 12px;
    height: 39px;
    background-image: none !important;
}



.order_form_page_container .order_form_infos_container textarea {
    height: auto !important;
    padding: 8px 11px !important;
    box-shadow: none !important;
    border: 1px solid #c4c3c3 !important;
    color: #2c2c2c !important;
}

.order_form_page_buttons_container{
    width: 60%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    display: flex;
    justify-content: space-between;
    margin: 15px auto;
}


.order_form_page_buttons_container .submit_but{
    all: unset;
    background:rgb(228, 88, 32);
    color: white;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    padding: .5rem 1.5rem;
    font-size: .9rem;
    border-radius: 5px;
    transition: -webkit-filter 300ms;
    transition: filter 300ms;
    transition: filter 300ms, -webkit-filter 300ms;
    cursor: pointer;
}

.order_form_page_buttons_container .submit_but:hover{
    background: rgb(228, 88, 32);
    opacity: .9;
}


.order_form_page_buttons_container .return_button{
    background:rgb(228, 32, 74);
}

.order_form_page_buttons_container .return_button:hover{
    background:rgb(228, 32, 74);
    opacity: .9;
}


@media all and (max-width: 1170px){
    .order_form_container{
        width: 70%;
    }

    .order_form_page_container .order_form_page_buttons_container{
        width: 70%;
    }

    .order_form_title{
        font-size: 1.32rem;
    }
    
}

@media all and (max-width: 900px){
    .order_form_container{
        width: 80%;
    }

    .order_form_page_container .order_form_page_buttons_container{
        width: 80%;
    }

    .order_form_page_container .order_form_title{
        font-size: 1.25rem;
    }

    .order_form_page_container .order_form_title::after{
        height: 2.5px;
        width: 38%;
    }

}

@media all and (max-width: 750px){
    .order_form_container{
        width: 90%;
    }

    .order_form_page_container .order_form_page_buttons_container{
        width: 90%;
    }

    .order_form_page_container .order_form_title{
        font-size: 1.2rem;
    }

    .order_form_page_container .order_form_title::after{
        height: 2px;
        width: 32%;
    }
}


@media all and (max-width: 550px){
    .order_form_container{
        width: 94%;
    }

    .order_form_page_container .order_form_page_buttons_container{
        width: 94%;
    }

    .order_form_page_container{
        padding: 10px;
    }

    .order_form_title{
        font-size: 1.15rem;
    }

}

@media all and (max-width: 379px){
    .order_form_container{
        width: 99%;
    }

    .order_form_page_container .order_form_page_buttons_container{
        width: 99%;
    }

    .order_form_page_container{
        padding: 5px;
    }

    .order_form_title{
        font-size: 1.05rem;
        padding: 5px 6px;
        padding-bottom: 1px;
    }
    
    .order_form_page_container .order_form_title::after{
        left: 7px;
    }

}
.applicant_request_view{
    width : 100% !important;
    margin: auto;
}



.applicant_request_view .applicant_request_view_header_container{
    font-size: 1.2rem;
    padding: 0 20px 15px 20px;
    width: 95%;
    margin: auto;
    margin-top: 30px;
    margin-bottom: 20px;
    border-bottom: solid 1px black;
    display: flex;
    justify-content: space-between;
}

.applicant_request_view .view_reason_button{
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin: auto;
    text-decoration: underline;
    margin-top: 2px;
    color: #38a1a5 !important;
    cursor: pointer !important;
}

.request_state_container{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.applicant_request_view .applicant_request_view_header_container .MuiGrid-container {
    width: -moz-fit-content !important;
    width: -webkit-fit-content !important;
    width: fit-content !important;
    align-content: center !important;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
}

.applicant_request_view .disable_button{
    opacity: 0.4;
    cursor: default !important;
}

.applicant_request_view .disable_button:hover{
    background-color: #e0e0e0 !important;
    opacity: 0.4;
    cursor: default !important;
}

.applicant_request_view label {
    margin-bottom: 0 !important;
    margin-right: 10px !important;
}

.applicant_request_view .submittions_table{
    width: 96% !important;
    margin: auto !important;
}

.applicant_request_view .MuiTableCell-body:nth-child(8) {
    min-width:235px !important;
}

.applicant_request_view .MuiTableCell-body:nth-child(8) .documentsDownload{
    width: 100%;
}


.request_details_container{
    width: 100vw;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    overflow: auto;
    padding: 20px 10vw;
 }

.order_accordion_header{
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding-right: 24px;
}

.request_order_toolbar_main_container{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center !important;
    align-content: center !important;
}

.request_order_toolbar_left{
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
}

.request_order_toolbar_right{
    flex: 1 1;
    display: flex;
    justify-content: flex-end;
}

.request_details_container .view_reason_button{
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin: auto;
    text-decoration: underline;
    margin-top: 2px;
    color: #38a1a5 !important;
    cursor: pointer !important;
    font-size: .9rem;
}

.request_details_container .request_state_container{
    display: flex;
    flex-direction: column;
    align-items: center !important;
    justify-content:center !important;
    align-content: center !important;
}


.notify_requester_button{
    background:rgb(228, 88, 32) !important;
    color:white !important;
    margin-left: 8px !important;
}

.notify_requester_button:hover{
    background: rgb(228, 88, 32) !important;
    opacity: .9;
}

 .request_details_container .accordion{
     margin-bottom: 15px;
 }

 .request_details_container .accordion-button{
    font-size: 1.4rem;
    font-weight: 600 !important;
    text-transform: capitalize !important;
    font-weight: 500;
    color: rgb(72, 72, 72) !important;
    padding: 9px 20px;
    min-height: 50px;
    height: -webkit-fit-content !important;
    height: -moz-fit-content !important;
    height: fit-content !important;
    border-radius: 5px !important;
    background-color: rgb(255, 255, 255);
    border: solid 1px rgb(106, 106, 106);
    font-family: inherit !important;
}

.request_details_container .accordion_header_content{
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    margin-right: 3px;
    line-height: 1rem;
}

.request_details_container .accordion_header_actions{
    flex: 1 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.request_details_container .accordion_header_content .supplier_infos {
    color: rgb(86, 121, 121);
    margin-left: 5px;
}

.request_details_container .accordion_header_content .supplier_infos span{
    position: relative;
    margin-bottom: 0 !important;
    bottom: 1px !important;
    margin-left: 8px;
}

.request_details_container .accordion_header_content .supplier_infos span:hover{
    opacity: .9;
}

.request_details_container .accordion_header_content .supplier_infos:first-child{
    color: rgb(86, 121, 121);
    font-size: inherit !important;
    margin-left: 6px;
    margin-bottom: 1px;
}


.request_details_container .order_state{
    position: relative;
    color: rgb(86, 121, 121);
    margin-right: 10px;
    display: flex;
    justify-content: center;
    align-items:flex-end;
}

.request_details_container .accordion_header_content span:first-child{
    position: relative;
}

.request_details_container .accordion_header_content span:first-child::before{
    content: '';
    position: absolute;
    bottom: -7px;
    left: 2px;
    height: 2px;
    display: inline-block;
    background: rgb(86, 121, 121);
    width: 60px;
}


.request_details_container .requester_details_container .accordion_header_content span:first-child::before{
    display: none;
}

.request_details_container .accordion_header_content .supplier_infos span::before{
    display: none;
}

.request_details_container div:first-child .accordion:first-child .accordion_header_content span:first-child::before{
    display: none;
}

.off_catalogue_requestDetails_container .supplier_accordion .accordion_header_content span:first-child::before{
    display: none;
}


.request_details_container .collapsed::after {
    background-image: url(https://cdn.iconscout.com/icon/free/png-256/top-arrow-25-1112318.png);
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform: rotate(-180deg);
            transform: rotate(-180deg);
}

.request_details_container .accordion-button:not(.collapsed)::after {
    background-image: url(https://cdn.iconscout.com/icon/free/png-256/top-arrow-25-1112318.png);
    -webkit-transform: scale(1);
            transform: scale(1);
}

.request_details_container .accordion-item{
    border: none !important;
}

.request_details_container .accordion-button:focus {
    box-shadow:none !important;
    color:black;
}


.request_details_container .accordion-body {
    padding: 0;
    margin: 0;
    color: rgb(64, 64, 65);
    background-color:#f9f9f9 !important;

}


.request_details_container div:nth-child(4) .accordion .accordion-button::before{
    background: rgb(245 128 115);
}

.request_details_container div:nth-child(2) .accordion .accordion-button::before{
    background: rgb(245 128 115);
}


.action_button_container {
    margin-right: 15px !important;
    height: -moz-fit-content;
    height: -webkit-fit-content;
    height: fit-content;
    display: flex;
}

.order_form_details_container{
    width: 80%;
    margin: 0 auto;
}

.order_form_details_container .order_form_infos_container{
    width: 100%;
    padding: 1em 2em;
    margin: 0;
}

.order_form_infos_container .row {
    margin: 5px 0 10px 0;
}


.order_form_infos_container .order_form_infos_title{
    padding: 0 15px;
    font-size: 1.1rem;
    font-weight: 600;
    color: #526f87;
    margin: 18px 0 7px 0;
}

.order_form_infos_container .row label {
    margin-bottom: 0.25rem !important;
}

.order_form_infos_container .form-control {
    border: 1px solid #c4c3c3;
    border-radius: 4px;
    color: #2c2c2c;
    height: auto;
    font-size: .9rem;
    box-shadow: none;
    margin-bottom: 12px;
    height: 39px;
    background-image: none !important;
}

.order_form_infos_container textarea {
    height: auto !important;
    padding: 8px 11px !important;
    box-shadow: none !important;
    border: 1px solid #c4c3c3 !important;
    color: #2c2c2c !important;
}

.order_form_page_buttons_container{
    width: 60%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    display: flex;
    justify-content: space-between;
    margin: 15px auto;
}

.rating_final_score_container{
    font-size: .9em !important;
    margin-left: 15px !important;
    text-decoration: none !important;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    cursor: pointer;
}

.rating_final_score_container span::before,.rating_final_score_container span::after{
    display: none !important;
}

.rating_final_score_container label{
    margin-bottom: 0 !important;
}

.rating_final_score{
    font-size: 0.8em !important;
}

.ratings_container_modal_content{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px 0;
}


.ratings_container_modal_content .rating_score_container{
    margin: 0 40px;
    display: flex;
    justify-content: space-between;
    justify-content: center;
}

.ratings_container_modal_content .rating_score_container label{
    margin-bottom: 0 !important;
}

.ratings_container_modal_content .rating_score_container div{
    margin: 0;
    margin-right: 10px;
    font-weight: 600;
    font-size: 1.2em;
    line-height: 1.1em;
}

.ratings_container_modal_content .rating_score_container .rating_score{
    margin-left: 3px;
    font-weight: 500;
    font-size: 1.1em;
}




@media all and (max-width: 1200px){

    .order_form_details_container {
        width: 86% !important;
    }
    .request_details_container{
        padding: 20px 7.5vw;
    }


}


@media all and (max-width: 1080px){
    

    .request_details_container{
        padding: 20px 7vw;
    }

    .request_details_container .accordion_header_content{
        font-size: 1.3rem;
    }

    .order_accordion_header{
        padding-right: 24px;
    }    

    .accordion-button::after {
        width: 1.1rem !important;
        height: 1.1rem !important;
        background-size: 1.1rem !important;
    }

    .order_form_details_container {
        width: 88% !important;
    }

    .order_form_infos_container .order_form_infos_title {
        font-size: 1.05rem !important;
        margin: 14px 0 7px 0 !important;
    }

    .order_form_infos_container .form-control {
        font-size: .9rem;
        margin-bottom: 12px;
        height: 37px !important;
    }

}




@media all and (max-width: 900px){

    .request_details_container{
        padding: 20px 4vw;
    }

    .request_details_container .accordion_header_content{
        font-size: 1.15rem;
    }

    .order_accordion_header{
        padding-right: 18px;
    }    

    .accordion-button::after {
        width: 1rem !important;
        height: 1rem !important;
        background-size: 1rem !important;
    }

    .order_form_details_container {
        width: 92% !important;
    }

    .order_form_infos_container .order_form_infos_title {
        font-size: 1rem !important;
        margin: 10px 0 7px 0 !important;
    }

    .request_details_container .accordion-button {
        padding: 9px 20px;
        padding-top: 10px;
    }

    .request_details_container .view_reason_button{
        font-size: .85rem;
    }

}



@media all and (max-width: 788px){
    .order_form_details_container {
        width: 95% !important;
    }

    .order_form_details_container .order_form_infos_container {
        padding: .8em 1.3em !important;
    }

    .order_form_infos_container .row label {
        font-size: .85rem !important;
    }

    .order_form_infos_container .form-control {
        font-size: .85rem !important;
        margin-bottom: 9px !important;
        height: 35px !important;
    }

    .request_details_container .accordion {
        margin-bottom: 10px;
    }
}




@media all and (max-width: 700px){

    .request_details_container{
        padding: 20px 2vw;
    }

    .request_details_container .accordion_header_content{
        font-size: 1.05rem;
    }

    .order_accordion_header{
        padding-right: 12px;
    }    

    .accordion-button::after {
        width: 1rem !important;
        height: 1rem !important;
        background-size: 1rem !important;
    }

    .order_form_details_container {
        width: 96% !important;
    }

    .order_form_infos_container .order_form_infos_title {
        font-size: 1rem !important;
        margin: 10px 0 7px 0 !important;
    }

    .request_details_container .accordion-button {
        padding: 9px 10px;
        padding-top: 8px;
    }

    .request_details_container .view_reason_button{
        font-size: .8rem;
    }

    .request_details_container .accordion_header_content .supplier_infos span {
        margin-left: 6px;
    }

    .order_form_details_container {
        width: 96% !important;
    }

    .order_form_infos_container .row label {
        margin-bottom: 0.2rem !important;
    }

    .order_form_infos_container .form-control {
        margin-bottom: 8px !important;
        height: 34px !important;
    }

    .order_form_infos_container .order_form_infos_title {
        font-size: 1rem !important;
        margin: 15px 0 7px 0 !important;
    }

    .request_details_container .accordion {
        margin-bottom: 8px;
    }
}


@media all and (max-width: 580px){
    .request_details_container{
        padding: 20px 1vw;
    }

    .request_order_toolbar_left .MuiIconButton-root {
        padding: 10px 8px 4px 8px;
    }

    .request_order_toolbar_left{
        align-items: flex-end !important;
    }

    .request_order_toolbar_right{
        flex: 1 1;
        display: flex;
        justify-content: flex-end;
        margin: 3px 0;
    }

    
    .request_details_container .accordion-button{
        padding: 8px 8px;
        min-height: 40px;
    }

    .request_details_container .accordion_header_content{
        font-size: .9rem;
    }

    .order_accordion_header{
        padding-right: 8px;
    }    

    .accordion-button::after {
        width: .9rem !important;
        height: .9rem !important;
        background-size: .9rem !important;
    }

    .order_form_details_container {
        width: 100% !important;
    }

    .order_form_infos_container .row label {
        font-size: .8rem !important;
        margin-bottom: 0.2rem !important;
    }

    .order_form_details_container .order_form_infos_container {
        padding: .5em 0em !important;
    }

    .order_form_infos_container .form-control {
        font-size: .8rem !important;
        margin-bottom: 9px !important;
        height: 32px !important;
    }

    .order_form_infos_container .order_form_infos_title {
        font-size: .95rem !important;
        margin: 15px 0 7px 0 !important;
    }

    .request_details_container .accordion {
        margin-bottom: 7px;
    }
}

@media all and (max-width: 497px){

    .request_details_container .accordion_header_content{
        font-size: .85rem;
    }

    .request_details_container .accordion_header_content span:first-child::before{
        display: none;
    }

    .request_details_container .accordion_header_content .supplier_infos {
        margin-left: 5px;
        margin-top: 1px;
    }

    .accordion-button::after {
        width: .8rem !important;
        height: .8rem !important;
        background-size: .8rem !important;
    }

    .request_details_container .view_reason_button{
        font-size: .7rem;
    }

    .order_form_infos_container .row label {
        font-size: .75rem !important;
        margin-bottom: 0.1rem !important;
    }

    .order_form_infos_container .form-control {
        font-size: .75rem !important;
        margin-bottom: 5px !important;
        height: 28px !important;
    }

    .order_form_infos_container .order_form_infos_title {
        font-size: .9rem !important;
        margin: 10px 0 7px 0 !important;
        padding: 0 0.3rem !important;
    }

    .order_form_infos_container .row>* {
        padding: 0 0.3rem !important;
    }

    .action_button_container {
        margin-right: 10px !important;
        height: -moz-fit-content;
        height: -webkit-fit-content;
        height: fit-content;
        display: flex;
    }
}


@media (max-width: 379px){
    .my_full_dialog .page_title {
        height: -webkit-fit-content !important;
        height: -moz-fit-content !important;
        height: fit-content !important;
        margin-top: 4px !important;
        margin-bottom: 0 !important;
    }
}
@media all and (max-width: 370px){

    .request_details_container .accordion_header_content span:first-child::before{
        display: none;
    }

    .request_details_container .accordion_header_content .supplier_infos {
        margin-left: 0px;
    }

    .order_form_details_container .order_form_infos_container {
        padding: .5em 1em !important;
    }

    .col-6{
        width: 100% !important;
    }

    .request_order_toolbar_left .MuiIconButton-root {
        padding: 8px 8px;
    }

    .request_order_toolbar_left{
        align-items: center !important;
    }
}
.order_plus_details_container{
    width: 100%;
    flex:1 1;
    display: flex;
    flex-direction: column;
}

.order_plus_details_table_container{
    width: 96% !important;
    height: 100%;
    margin: 12px auto 0 auto;
    background-color: rgb(254, 254, 254);
    border: solid 1px rgb(228, 227, 227);
}

.order_plus_details_container .proceed_button_container{
    width: 100% !important;
    margin: auto;
    display: flex;
    justify-content: flex-end; 
}

.order_plus_details_table_container .MuiTableCell-head {
    font-weight: 600 !important;
    font-size: .95rem;
}
  


.order_plus_details_table_container .product_cell_img_container{
    width: 94px;
    height: 99px;
    display: flex;
    border: solid 1px rgb(194, 194, 194);
}

.order_plus_details_table_container .product_cell_img_container img{
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    object-fit: cover !important;
    border: none;
}

.order_plus_details_table_container .product_cell_title_container{
    flex: 1 1;
    margin-left: 20px;
    font-size: 1.08rem;
    font-weight: 700;
    color: rgb(57, 57, 57);
}

.order_plus_details_table_container .product_cell_title_container #title{
    text-transform: capitalize;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}

.order_plus_details_table_container .product_cell_title_container .product_cell_categorie_container{
    width: 100%;
    font-size: .85rem;
    font-weight: 500;
    text-transform: capitalize;
    color: rgb(59, 91, 91);
}

.order_plus_details_table_container .product_cell_title_container .product_cell_categorie_container span{
    font-size: inherit;
    font-weight: 700;
}

.order_plus_details_table_container .amount_container {
    border-radius: 5px;
    width: 125px !important;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    padding: 6px 9px;
    font-size: inherit;
}

.order_plus_details_table_container input[type=number]::-webkit-inner-spin-button, .product_details_infos_container
input[type=number]::-webkit-outer-spin-button {  
   display: none !important;
}


.order_plus_details_table_container .addIcon{
    display:flex;
    background-color: rgb(253, 253, 253);
    color: #343a40;
    border-radius: 100%;
    cursor: pointer;
    text-align: center;
  }
  
  .order_plus_details_table_container .addIcon:hover{
    background-color: #e9fce9;
  }
  

.order_plus_details_table_container .MuiTableCell-body:nth-child(1) {
    min-width: 320px !important;
    width:48% !important;
    font-size: 1rem;
    font-weight: 600;
}

.order_plus_details_table_container .MuiTableCell-body:nth-child(2) {
    width:125px !important;
}

.order_plus_details_table_container .MuiTableCell-body:nth-child(3){
    width:105px !important;
}

.order_plus_details_table_container .MuiTableCell-body:nth-child(3) .amount_container {
    width: 60px !important;
    text-align: center;
}


.order_plus_details_table_container .MuiTableCell-body:nth-child(4) {
    width:105px !important;
}

.order_plus_details_table_container .MuiTableCell-body:nth-child(4) .amount_container {
    width: 60px !important;
    text-align: center;
}

.order_plus_details_table_container .MuiTableCell-body:nth-child(5) {
    width:135px !important;
}


.total_amount_container{
    width: 96% !important;
    margin: 0 auto 15px auto;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}


.total_amount_table_container{
    width: 450px !important;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    background-color: rgb(254, 254, 254);
    border-left: solid 1px rgb(228, 227, 227);
    border-right: solid 1px rgb(228, 227, 227);
    border-bottom: solid 1px rgb(228, 227, 227);
}

.total_amount_table_container .MuiTableCell-body{
    width:55% !important;
    font-size: .95rem;
    font-weight: 700;
    padding: 12px 18px !important;
}

.total_amount_table_container .MuiTableCell-body:nth-child(2) {
    width:50% !important;
    font-weight: 600;
    color: rgb(116, 116, 116);
}

.total_amount_table_container .amount_container {
    border-radius: 5px;
    width: 60px !important;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    align-self: flex-end;
    padding: 6px 9px;
    font-size: inherit;
    text-align: center;
    margin-left: calc( 100% - 59px );
}


.total_amount_table_container input[type=number]::-webkit-inner-spin-button, .total_amount_table_container
input[type=number]::-webkit-outer-spin-button {  
   display: none !important;
}

.order_plus_details_container .proceed_button_container{
    width: 96% !important;
    margin: 20px auto;
    display: flex;
    justify-content: flex-end;
}


.order_plus_details_container .proceed_button_container .submit_but{
    background:rgb(228, 88, 32);
    color: white;
    font-size: .9rem;
}


.order_plus_details_container .proceed_button_container .submit_but:hover{
    opacity: .9;
}

.order_plus_details_container .cancel_but{
    background:#e92a44 !important;
    margin-right: 6px;
}


.order_plus_details_container .valide_but{
    background:#18ac67 !important;
}

.order_plus_details_container .cancel_but:hover{
    background:#e92a44 !important;
    opacity: .9 !important;
}

.order_plus_details_container .valide_but:hover{
    background:#18ac67 !important;
    opacity: .9 !important;
}


@media all and (max-width: 1100px){
    .order_plus_details_table_container .amount_container {
        width: 107px !important;
        padding: 6px 9px !important;
    }
    .order_plus_details_table_container .MuiTableCell-body:nth-child(3) .amount_container, .total_amount_table_container .amount_container {
        width: 55px !important;
    }
    .order_plus_details_table_container .MuiTableCell-body:nth-child(4) .amount_container {
        width: 55px !important;
    }
    .order_plus_details_table_container .product_cell_img_container {
        width: 92px;
        height: 96px;
    }
    .order_plus_details_table_container .product_cell_title_container {
        flex: 1 1;
        margin-left: 17px;
        font-size: 1rem;
    }
    .order_plus_details_table_container .product_cell_title_container .product_cell_categorie_container {
        font-size: .82rem;
    }
}


@media all and (max-width: 900px){
    .order_plus_details_table_container, .total_amount_container {
        width: 98% !important;
    }

    .order_plus_details_container .proceed_button_container {
        width: 98% !important;
        margin: 15px auto !important;
    }

    .order_plus_details_table_container .amount_container {
        width: 95px !important;
        padding: 5px 7px !important;
    }

    .total_amount_table_container .amount_container {
        padding: 5px 7px !important;
    }

    .order_plus_details_table_container .MuiTableCell-body:nth-child(3) .amount_container, .total_amount_table_container .amount_container {
        width: 50px !important;
    }
    .order_plus_details_table_container .MuiTableCell-body:nth-child(4) .amount_container {
        width: 50px !important;
    }
    .order_plus_details_table_container .product_cell_img_container {
        width: 91px;
        height: 95px;
    }
    .order_plus_details_table_container .product_cell_title_container {
        flex: 1 1;
        margin-left: 14px;
    }
    .total_amount_table_container {
        width: 400px !important;
    }
    .total_amount_table_container .MuiTableCell-body{
        width:55% !important;
        font-size: .95rem;
        font-weight: 700;
        padding: 11px 17px !important;
    }
}

@media all and (max-width: 700px){

    .order_plus_details_table_container .product_cell_img_container {
        width: 89px;
        height: 93px;
    }

    .total_amount_table_container {
        width: 360px !important;
    }
    .order_plus_details_table_container .product_cell_title_container {
        margin-left: 12px;
        font-size: .9rem;
    }
    .order_plus_details_table_container .product_cell_title_container .product_cell_categorie_container {
        font-size: .75rem;
    }

    .total_amount_table_container .MuiTableCell-body{
        padding: 10px 16px !important;
        font-weight: 600;
    }

    .order_plus_details_container .proceed_button_container {
        margin: 10px auto !important;
        flex-wrap: wrap;
    }
}


@media all and (max-width: 600px){

    .order_plus_details_table_container .product_cell_img_container {
        width: 79px;
        height: 83px;
    }

    .order_plus_details_table_container .MuiTableCell-body:nth-child(1) {
        min-width: 300px !important;
    }

    .total_amount_table_container {
        width: 320px !important;
    }

    .order_plus_details_table_container .product_cell_title_container {
        margin-left: 9px;
        font-size: .82rem;
    }
    .order_plus_details_table_container .product_cell_title_container .product_cell_categorie_container {
        font-size: .7rem;
    }

    .total_amount_table_container .MuiTableCell-body{
        padding: 8px 14px !important;
    }


    .order_plus_details_table_container .amount_container {
        width: 88px !important;
        padding: 4px 8px !important;
    }
    .order_plus_details_table_container .MuiTableCell-body:nth-child(3) .amount_container, .total_amount_table_container .amount_container {
        width: 47px !important;
        padding: 4px 8px !important;
    }
    .order_plus_details_table_container .MuiTableCell-body:nth-child(4) .amount_container {
        width: 47px !important;
        padding: 4px 8px !important;
    }

    .total_amount_table_container .amount_container {
        width: 47px !important;
        padding: 4px 8px !important;
    }


}



@media all and (max-width: 450px){

    .order_plus_details_table_container .product_cell_img_container {
        width: 69px;
        height: 73px;
    }

    .order_plus_details_table_container .MuiTableCell-body:nth-child(1) {
        min-width: 280px !important;
    }

    .total_amount_table_container {
        width: 300px !important;
    }
    .order_plus_details_table_container .product_cell_title_container {
        margin-left: 6px;
        font-size: .8rem;
    }
    .order_plus_details_table_container .product_cell_title_container .product_cell_categorie_container {
        font-size: .65rem;
    }

    .total_amount_table_container .MuiTableCell-body{
        padding: 6px 10px !important;
    }

    .order_plus_details_table_container .amount_container {
        width: 67px !important;
        padding: 3.5px 4px !important;
    }
    .order_plus_details_table_container .MuiTableCell-body:nth-child(3) .amount_container, .total_amount_table_container .amount_container {
        width: 45px !important;
        padding: 3.5px 4px !important;
    }
    .order_plus_details_table_container .MuiTableCell-body:nth-child(4) .amount_container {
        width: 45px !important;
        padding: 3.5px 4px !important;
    }

    .total_amount_table_container .amount_container {
        width: 45px !important;
        padding: 3.5px 4px !important;
    }


    .order_plus_details_container .proceed_button_container {
        margin: 4px auto !important;
    }

}


@media all and (max-width: 380px){

    .order_plus_details_table_container .product_cell_img_container {
        width: 65px;
        height: 69px;
    }

    .order_plus_details_table_container .MuiTableCell-body:nth-child(1) {
        min-width: 280px !important;
    }

    .total_amount_table_container {
        width: 280px !important;
    }
    .order_plus_details_table_container .product_cell_title_container {
        margin-left: 6px;
        font-size: .76rem;
    }
    .order_plus_details_table_container .product_cell_title_container .product_cell_categorie_container {
        font-size: .6rem;
    }

    .total_amount_table_container .MuiTableCell-body{
        padding: 5px 6px !important;
    }
}



.set_refuse_raison_container .MuiOutlinedInput-multiline {
    padding: 7.5px 14px !important;
}


/*
///////////////////////////////////////////////////////
/////////////////////////////////////////////////////*/





.set_refuse_raison_container .MuiOutlinedInput-multiline {
    padding: 7.5px 14px !important;
}



/*
///////////////////////////////////////////////////////
/////////////////////////////////////////////////////*/





.set_refuse_raison_container .MuiOutlinedInput-multiline {
    padding: 7.5px 14px !important;
}




/*
///////////////////////////////////////////////////////
/////////////////////////////////////////////////////*/




.order_off_catalogue_form_page_container{
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    display: flex;
    justify-content: space-between;
    padding: 20px 15px;
    position: relative;
    align-items: flex-start !important;
    align-content: flex-start !important;
    flex-wrap: wrap;
}

.order_off_catalogue_form_modal_container{
    overflow: auto;
}

.order_off_catalogue_form_page_container .off_catalogue_form_articles_container{
    width: 44%;
    display: flex;
    flex-direction: column;
}

.order_off_catalogue_form_page_container .off_catalogue_form_container{
    width: 55%;
    display: flex;
    flex-direction: column;
}



.order_off_catalogue_form_container{
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    margin: auto;
    border: solid 1px rgb(215, 212, 212);
    background-color: #fdfdfd;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    padding: 5px;
    padding-top: 13px;
}


.order_off_catalogue_form_infos_container{
    width: 100% !important;
    margin: 0 !important;
    padding: 0 !important;
}


.order_off_catalogue_form_infos_container .row {
    margin: 5px 0 10px 0;
}


.order_off_catalogue_form_infos_container .order_off_catalogue_form_infos_title{
    padding: 0 15px;
    font-size: 1.1rem;
    font-weight: 600;
    color: #526f87;
    margin: 18px 0 7px 0;
}

.order_off_catalogue_form_infos_container .row label {
    margin-bottom: 0.25rem !important;
}

.order_off_catalogue_form_infos_container .form-control {
    border: 1px solid #c4c3c3;
    border-radius: 4px;
    color: #2c2c2c;
    height: auto;
    font-size: .9rem;
    box-shadow: none;
    margin-bottom: 12px;
    height: 39px;
    background-image: none !important;
}

.order_off_catalogue_form_page_container .order_off_catalogue_form_infos_container textarea {
    height: auto !important;
    padding: 8px 11px !important;
    box-shadow: none !important;
    border: 1px solid #c4c3c3 !important;
    color: #2c2c2c !important;
}

.order_off_catalogue_form_page_buttons_container{
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    display: flex;
    justify-content: flex-end;
    margin: 30px auto;
}



.order_off_catalogue_form_page_container .articles_view_container{
    width: 100%;
    height: -webkit-fit-content !important;
    height: -moz-fit-content !important;
    height: fit-content !important;
    border: solid 1px rgb(215, 212, 212);
    background-color: #fdfdfd;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    padding: 12px 15px;
    margin-bottom: 10px;
}

.order_off_catalogue_form_page_container .articles_view_header_container{
    font-size: 1.15rem;
    font-weight: 700;
    color: rgb(228, 88, 32);
    display: flex;
    justify-content: space-between;
}

.articles_view_header_container .add_icon_container{
    border-radius: 100%;
    cursor: pointer;
    width: 25px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.articles_view_header_container .add_icon_container:hover{
    background-color: rgba(188, 188, 188, 0.2);
}

.order_off_catalogue_form_page_container .article_view_header_container{
    font-weight: 600;
    color: #526f87;
    display: flex;
    justify-content: space-between;
}

.article_view_header_container .add_icon_container{
    border-radius: 100%;
    cursor: pointer;
    width: 25px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.article_view_header_container .add_icon_container:hover{
    background-color: rgba(188, 188, 188, 0.2);
}

.order_off_catalogue_form_page_buttons_container .submit_but{
    background:rgb(228, 88, 32);
    color: white;
}

.order_off_catalogue_form_page_buttons_container .submit_but:hover{
    background: rgb(228, 88, 32);
    opacity: .9;
}


.order_off_catalogue_form_page_buttons_container .return_button{
    background:rgb(228, 32, 74);
}

.order_off_catalogue_form_page_buttons_container .return_button:hover{
    background:rgb(228, 32, 74);
    opacity: .9;
}


@media all and (max-width: 900px){

    .order_off_catalogue_form_page_container .off_catalogue_form_articles_container{
        width: 85%;
        margin: auto;
    }

    .order_off_catalogue_form_page_container .off_catalogue_form_container{
        width: 85%;
        margin: auto;
    }

    .order_off_catalogue_form_page_container .order_form_title::after{
        height: 2.5px;
        width: 38%;
    }

    .order_off_catalogue_form_page_container .articles_view_container{
        padding: 9px 15px;
    }
}


@media all and (max-width: 750px){
    .order_off_catalogue_form_page_container .order_form_title::after{
        height: 2px;
        width: 32%;
    }
}

@media all and (max-width: 580px){

    .order_off_catalogue_form_page_container{
        padding: 9px;
    }

    .order_off_catalogue_form_page_container .articles_view_container{
        padding: 9px 12px;
    }

    .order_off_catalogue_form_page_container .off_catalogue_form_articles_container{
        width: 95%;
        margin: auto;
    }

    .order_off_catalogue_form_page_container .off_catalogue_form_container{
        width: 95%;
        margin: auto;
    }

    .order_off_catalogue_form_page_container .articles_view_header_container{
        font-size: 1.05rem;
        font-weight: 700;
        color: rgb(228, 88, 32);
        display: flex;
        justify-content: space-between;
    }

    .articles_view_header_container .add_icon_container{
        width: 23px;
        height: 23px;
    }
}


@media all and (max-width: 379px){

    .order_off_catalogue_form_page_container{
        padding: 5px;
    }

    .order_off_catalogue_form_page_container .off_catalogue_form_articles_container{
        width: 99%;
        margin: auto;
    }

    .order_off_catalogue_form_page_container .off_catalogue_form_container{
        width: 99%;
        margin: auto;
    }

    .order_off_catalogue_form_page_container .order_form_title::after{
        left: 7px;
    }

    .order_off_catalogue_form_page_container .articles_view_container{
        padding: 9px 6px;
    }

    .articles_view_header_container .add_icon_container{
        width: 20px;
        height: 20px;
    }

}

.set_refuse_raison_container .MuiOutlinedInput-multiline {
    padding: 7.5px 14px !important;
}
/*
///////////////////////////////////////////////////////
/////////////////////////////////////////////////////*/



.emails_sent_container{
    width: 90%;
    margin: 25px auto;
}

.emails_sent_container_header{
    font-size: 1.2rem;
    padding: 0 20px;
    padding-bottom: 10px;
    border-bottom: solid 1px black;
    width: 100%;
    margin: auto;
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
}

.emails_sent_container_header .page_title{
    flex:1 1;
    display: flex;
    flex-direction: row;
    align-items: end;
    justify-content: flex-start;
}

.emails_sent_container_header .page_title label{
    margin: 0 !important;
}

.emails_sent_container .emails_sent_table{
    width: 100% !important;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    margin: auto !important;
}

.emails_sent_table .MuiTableCell-head {
    font-weight: 700 !important;
}

.emails_sent_table .MuiTableCell-body:nth-child(1) {
    min-width:200px !important;
}

.emails_sent_table .MuiTableCell-body:nth-child(2) {
    min-width:250px !important;
}

.emails_sent_table .MuiTableCell-body:nth-child(3) {
    min-width:100px !important;
}

.subTypes_container{
    width:100%;
    height: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.subType_container{
    width:100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    display: flex;
    margin-bottom: 5px;
    align-items: center;
}

.subType{
    width:60%;
    height: 100%;
}

.subType_state{
    width:40%;
    height: 100%;
    display: contents;
}

.subType_sign_date_container{
    margin-bottom: 5px;
}

.subType_sign_date_container .MuiFab-circular{
    width: 0 !important;
}


.page_title .submit_but{
    all: unset;
    background: rgb(3, 113, 119) !important;
    color: white;
    margin-left: 16px;
}

.page_title .submit_but:hover{
    background: rgb(3, 113, 119) !important;
    opacity: .9;
}
.send_email_container .form{
    text-align: -webkit-right;
    padding-top: 0px !important;
}

.send_email_container .form-control {
    border: 1px solid #c4c3c3;
    border-bottom-width: 1px !important;
    border-radius: 6px;
    color: #2c2c2c;
    height: auto;
    font-size: .95rem;
    box-shadow: none;
    margin-top: 10px;
    height: 39px;
    background-image: none !important;
    background-color: white !important;
}

.send_email_container  .Mui-focused {
    border: 1px solid #c4c3c3 !important;
}

.send_email_container  .form-control:focus {
    border: 1px solid #c4c3c3 !important;
}


.send_email_container .multiselectContainer{
    font-family: var(--body-font) !important;
}

.send_email_container .searchBox{
    border: none !important;
    font-size: .95rem !important;
    color:#2c2c2c !important;
    font-family: var(--body-font) !important;
}

.send_email_container .inputField{
    color:#2c2c2c !important;
    font-family: var(--body-font) !important;
}


.send_email_container .searchWrapper {
    border: 1px solid #c4c3c3 !important;
    border-radius: 6px !important;
    padding: 0.3rem 0.65rem !important;
}

.send_email_container .chips{
    background: #338787 !important;
}

.send_email_container .chip {
    background: #338787 !important;
    border-radius: 15px !important;
    color: #ffffff !important;
    font-size: 0.85rem !important;
    margin-bottom: 0px !important;
    margin-right: 4px !important;
    font-family: var(--body-font) !important;
}

.send_email_container .optionListContainer {
    z-index: 1000 !important;
}

.send_email_container .option{
    color: #4c4c4e !important;
}

.send_email_container .option:hover{
    background: #e9ecee !important;
}

.send_email_container .highlightOption {
    background: #e9ecee !important;
}

.send_email_container .highlightOption:hover {
    background: #e9ecee !important;
}



.text_edit_cont{
    margin:10px 0px 5px 0px;
    width: 100%;
    height: 320px;
}
.send_email_container .sun-editor .se-btn-tray {
    display: flex !important;
}

.send_email_container .sun-editor .se-wrapper {
    text-align: start !important;
}

.sun-editor .se-container {
    display: flex !important;
    flex-direction: column !important;
}


.send_email_container .files_container{
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
}

.send_email_container .file_container{
    border: solid 1px rgb(185, 185, 185);
    color: rgb(100, 99, 99) !important;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    min-width: 150px;
    font-size: .85rem;
    display: flex;
    justify-content: space-between;
    padding: 9px 4px 9px 7px;
    border-radius: 3px;
    margin-right: 10px;
    margin-bottom: 8px;
}

.send_email_container .file_infos{
    margin-right: 8px;
}

.send_email_container .file_container .my_icon{
    color: rgb(117, 117, 117) !important;
    cursor: pointer !important;
}

.my_offers_container{
    width: 100% !important;
    overflow: hidden !important;
}


.my_offers_container_header{
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 30px 40px;
    align-items: center;
}

.addButton_container{
    width: -webkit-fit-content !important;
    width: -moz-fit-content !important;
    width: fit-content !important;
}


.addButton_container .submit_but{
    all: unset;
    background:#3b5a7c;
    color: white;
    transition: all 150ms ease-in-out 0s;
    padding: .39rem 1.2rem ;
    margin: 6px 5px;
    font-size: .93rem;
    cursor: pointer;
    align-items: center;
    text-align: center;
    box-shadow: none;
    margin-right: 20px;
    border-radius: 4px;
}


.my_offers_container .submit_but:hover{
    background:#4b698a !important;
}

.search_filter_container{
    display: flex;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.my_offers_container .gridOfService {
    width: 98% !important;
    margin: auto !important;

}

.my_offers_container .paginationBttns {
    width: 100% !important;
    height: -webkit-fit-content !important;
    height: -moz-fit-content !important;
    height: fit-content !important;
    display: flex !important;
    justify-content: center !important;
    padding: 0 !important;
}


.my_offers_container .offers_contents_pagination_container{
    width: 98%;
    height: 100%;
    margin: auto;
    justify-content: center;
}


.my_offers_container .offers_contents_container{
    width: 100%;
    height: 100%;
    margin-bottom: 30px;
    display:flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    flex-direction: row;
    flex-wrap: wrap;
}

.my_offers_container .main_card_container {
    float: left;
    width: 42%;
    height: 270px;
    min-width: 600px;
    max-width: 700px !important;
    margin: 12px 12px !important;
    background: none;
    border: solid;
    border:solid 1px rgb(87, 87, 87);
    background-color:rgb(253, 253, 253);
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    padding: 10px 5px;
}


.search_filter_container .search_filter_form{
    width:250px;
    margin:0px 30px 0px 0px;
}



.my_offers_container .main_card_container .main_card_infos_container{
    flex: 1 1;
    padding: 6px 10px;
    padding-bottom: 0;
    display: flex;
    flex-direction: column;
}

.my_offers_container .main_card_container .main_card_offer_object{
    font-size: 1.5rem;
    font-weight: 700;
    color: rgb(31, 107, 132);
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    margin-bottom: 10px;
    text-transform: capitalize;
}


.my_offers_container .main_card_container .main_card_infos_container .main_card_infos_content{
    flex: 1 1;
    font-size: 1.05rem;
    color: #343a40;
    height: 60%;
}

.my_offers_container .main_card_container .main_card_infos_container .main_card_infos_elements{
    width: 100%;
    margin-bottom: 7px;
    display: flex;
    display:flex;
    align-items: center;
    align-content: center;
    flex-direction: row;
    flex-wrap: wrap;
}

.my_offers_container .main_card_container .main_card_infos_container .main_card_infos_element{
    width: 100%;
    margin-bottom: 7px;
}

.my_offers_container .main_card_container .main_card_infos_container .main_card_infos_elements div{
    width: 50%;
}

.my_offers_container .main_card_container .main_card_infos_container span{
    font-weight: 700;
}

.my_offers_container .main_card_container .card_buttons_container{
    width: 100%;
    height: -webkit-min-content;
    height: min-content;
    display: flex;
    justify-content: flex-end;
    padding-right: 4px;
}

.main_card_container .submit_but{
    all: unset;
    background:#343a40;
    color: white;
    padding: .45rem 1.2rem;
    font-size: .9rem;
    border-radius: 4px;
    transition: -webkit-filter 300ms;
    transition: filter 300ms;
    transition: filter 300ms, -webkit-filter 300ms;
    cursor: pointer;
    align-items: center;
    text-align: center;
    box-shadow: none;
    position: relative;
}

  

.main_card_container .submit_but:first-child{
    margin-right: 5px;
}

.main_card_container .cancel_but{
    background:#e92a44;
}

.main_card_container .cancel_but:hover{
    background:#e92a44 !important;
    opacity: 0.9;
}

.main_card_container .valide_but{
    background:#18ac67;
}

.main_card_container .valide_but:hover{
    background:#18ac67 !important;
    opacity: 0.9;
}



@media all and (max-width: 1135px){

    .my_offers_container_header{
        padding: 30px 15px;
    }

}


@media all and (max-width: 1080px){
    
    .search_filter_container .search_filter_form{
        width:205px;
        margin:0px 25px 0px 0px;
    }

}


@media all and (max-width: 845px){
    
    .my_offers_container_header{
        flex-direction: column;
        align-items: flex-start;
        padding: 15px 15px;
    }

    .addButton_container{
        margin-bottom: 11px;
    }

    .search_filter_container{
        display: flex;
       width: 100%;
       justify-content: center;
    }

    .my_offers_container .main_card_container .main_card_offer_object {
        font-size: 1.45rem;
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
        margin-bottom: 20px;
    }

    .my_offers_container .main_card_container .main_card_infos_container .main_card_infos_content {
        font-size: .95rem;
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
    }
}


@media all and (max-width: 750px){
    
    .search_filter_container .search_filter_form{
        width:185px;
        margin:0px 15px 0px 0px;
    }
}


@media all and (max-width: 645px){

    .my_offers_container .offers_contents_pagination_container {
        width: 100%;
    }

    .search_filter_container{
       flex-wrap: wrap;
    }

    .search_filter_container .search_filter_form:nth-child(2){
        margin:0;
    }

    .search_filter_container .search_filter_form:nth-child(3){
        margin-right:10px;
    }

    .my_offers_container .main_card_container {
        width: 46%;
        min-width: 280px !important;
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
        margin: 6px 6px !important;
        padding: 8px 3px;
    }

    .my_offers_container .main_card_container .main_card_infos_container {
        padding: 6px 5px !important;
    }

    .my_offers_container .main_card_container .main_card_infos_container .main_card_infos_elements div {
        width: -webkit-fit-content !important;
        width: -moz-fit-content !important;
        width: fit-content !important;
        margin: 1px !important;
    }

    .my_offers_container .main_card_container .main_card_offer_object {
        font-size: 1.35rem;
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
        margin-bottom: 18px;
    }

    .my_offers_container .main_card_container .main_card_infos_container .main_card_infos_content {
        font-size: .9rem;
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
    }
}


@media all and (max-width: 580px){
    
    .search_filter_container .search_filter_form{
        width:180px;
        margin:0px 15px 0px 0px;
    }
}


@media (max-width: 590px){
    .my_offers_container .main_card_container {
        min-width: 270px !important;
        margin: 5px 5px !important;
    }

    .my_offers_container .search_bar{
        margin-top: 0 !important;
    }

    .my_offers_container .main_card_container .main_card_offer_object {
        font-size: 1.2rem;
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
        margin-bottom: 17px;
    }

    .my_offers_container .main_card_container .main_card_infos_container .main_card_infos_content {
        font-size: .82rem;
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
    }
}


@media all and (max-width: 445px){
    
    .search_filter_container .search_filter_form{
        width:150px;
        margin:0px 10px 0px 0px;
    }

    .my_offers_container .main_card_container .main_card_offer_object {
        font-size: 1.1rem;
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
        margin-bottom: 17px;
    }

    .my_offers_container .main_card_container .main_card_infos_container .main_card_infos_content {
        font-size: .78rem;
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
    }
}


@media all and (max-width: 397px){
    
    .search_filter_container .search_filter_form{
        width:150px;
        margin:0px;
    }

}

.dialogBottom div:nth-child(1){
    width: 65%;
    text-align: -webkit-right;
    padding-top: 3px;
}


.inputs_container{
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 10px;
    margin-bottom: 20px;
}


.inputs_container:last-child{
    margin-top: 40px;
    margin-bottom: 10px;
}

.input_form{
    width:250px;
    margin:0px 45px 10px 0px !important;
}

.inputs_container > .input_form:last-child{
    margin:0px 5px 10px 0px !important;
}

.inputs_container > label .input_form:last-child{
    margin:0px 5px 10px 0px !important;
    text-transform: none !important;
}


.dialogBottom .submit_but{
    background:#343a40;
    margin: 5px 6px;
    color: white;
}

.dialogBottom .submit_but:hover{
    background:#343a40;
    opacity: 0.9;
}

.dialogBottom .cancel_but{
    background:#e92a44;
}

.dialogBottom .cancel_but:hover{
    background:#e92a44;
    opacity: 0.9;
}

.dialogBottom .valide_but{
    background:#18ac67;
}

.dialogBottom .valide_but:hover{
    background:#18ac67;
    opacity: 0.9;
}




.update_porpose .documentsDownload{
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    display: flex;
    vertical-align: middle;
    align-items: center;
    margin-right: 10px;
    margin-top: 10px;
}

.update_porpose .documentsDownload:last-child{
    margin-right: 0px;
}


.update_porpose .documentsDownload .download_button{
    margin-left: 12px !important;
    color: black;
}


.update_porpose .documentsTitle{
    font-size: 1rem;
    margin-left: 9px;
  }


  
@media all and (max-width: 1080px){
    
    .inputs_container{
        margin-top: 6px;
        margin-bottom: 12px;
    }
    
    
    .inputs_container:last-child{
        margin-top: 25px;
        margin-bottom: 0px;
    }

    .input_form{
        width:250px;
        margin:0px 45px 10px 0px;
    }

}


  
@media all and (max-width: 941px){
    
    .inputs_container{
        margin-top: 6px;
        margin-bottom: 12px;
    }
    
    
    .inputs_container:last-child{
        margin-top: 25px;
        margin-bottom: 0px;
    }

    .input_form{
        width:235px !important;
        margin:0px 15px 10px 0px !important;
    }

    .update_porpose .documentsDownload .download_button{
        margin-left: 8px !important;
        color: black;
    }
    
    .update_porpose .documentsTitle{
        font-size: .95rem;
        margin-left: 8px;
    }

}


@media all and (max-width: 836px){
    
    .inputs_container{
        margin-top: 6px;
        margin-bottom: 12px;
    }
    
    
    .inputs_container:last-child{
        margin-top: 25px;
        margin-bottom: 0px;
    }

    .input_form{
        width:205px !important;
        margin:0px 10px 5px 0px !important;
    }

}



@media all and (max-width: 750px){
    
    .inputs_container{
        margin-top: 0px;
        margin-bottom: 0px;
    }
    
    
    .inputs_container:last-child{
        margin-top: 10px;
        margin-bottom: 0px;
    }

    .input_form{
        width:180px !important;
        margin:7px 25px 5px 0px !important;
        font-size: .87rem !important;
    }

    .inputs_container > .input_form:last-child{
        margin:7px 25px 5px 0px !important;
    }

    .inputs_container > label .input_form:last-child {
        font-size: .78rem !important;
    }

    .update_porpose .documentsTitle{
        font-size: .87rem;
        margin-left: 6px;
    }


}


@media all and (max-width: 620px){
    
    .input_form{
        width:175px !important;
        margin:7px 10px 5px 0px !important;
        font-size: .87rem !important;
    }

    .inputs_container > .input_form:last-child{
        margin:7px 10px 5px 0px !important;
    }

    .inputs_container > label .input_form:last-child {
        font-size: .78rem !important;
        width: 165px !important;
    }


    .update_porpose .documentsTitle{
        font-size: .82rem;
        margin-left: 6px;
    }

    .update_porpose .inputs_container:last-child{
        justify-content: flex-start !important;
    }

    .update_porpose .documentsDownload{
        margin-right: 30px;
    }

}



@media all and (max-width: 440px){
    
    .inputs_container{
        margin-top: 0px;
        margin-bottom: 0px;
    }
    
    
    .inputs_container:last-child{
        margin-top: 10px;
        margin-bottom: 0px;
    }

    .input_form{
        width:165px !important;
        margin:7px 5px 5px 0px !important;
        font-size: .8rem !important;
    }

    .inputs_container > .input_form:last-child{
        margin:7px 5px 5px 0px !important;
    }

    .inputs_container > .input_form:nth-child(2){
        margin:7px 0px 5px 0px !important;
    }

    .inputs_container > label .input_form:last-child {
        font-size: .75rem !important;
        width: 165px !important;
    }

    .update_porpose .documentsDownload{
        margin-right: 15px;
    }
}
.dialogSuppliersBottom .submit_but{
    all: unset;
    background:#343a40;
    color: white;
    padding: .45rem 1.2rem;
    padding-top: .48rem;
    margin: 4px 5px;
    font-size: 1rem;
    border-radius: 4px;
    transition: -webkit-filter 300ms;
    transition: filter 300ms;
    transition: filter 300ms, -webkit-filter 300ms;
    cursor: pointer;
    align-items: center;
    text-align: center;
    box-shadow: none;
}

.dialogSuppliersBottom .cancel_but{
    background:#e92a44;
}

.dialogSuppliersBottom .cancel_but:hover{
    background:#e92a44;
    opacity: 0.9;
}

.dialogSuppliersBottom .valide_but{
    background:#18ac67;
}

.dialogSuppliersBottom .valide_but:hover{
    background:#18ac67;
    opacity: 0.9;
}

.multi_form_select{
    width: 320px !important;
}


@media all and (max-width: 750px){
    .multi_form_select{
        width: 280px !important;
    }
}

@media all and (max-width: 549px){
    .multi_form_select{
        width: 220px !important;
    }
}
.docs_modal .documentsDownload{
    justify-content: flex-end !important;
}
.offresAppel_SubmitView{
    width : 100% !important;
    margin: auto;
}



.three_forms_header_container{
    font-size: 1.2rem;
    padding: 0 20px 10px 20px;
    width: 95%;
    margin: auto;
    margin-top: 20px;
    margin-bottom: 17px;
    border-bottom: solid 1px black;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}


.offresAppel_SubmitView label {
    margin-bottom: 0 !important;
    margin-right: 10px !important;
}

.offresAppel_SubmitView .submittions_table{
    width: 96% !important;
    margin: auto !important;
}

.offresAppel_SubmitView .MuiTableCell-body:nth-child(7) {
    min-width:235px !important;
}

.offresAppel_SubmitView .MuiTableCell-body:nth-child(7) .documentsDownload{
    width: 100%;
}
.my_full_dialog .MuiDialog-paper{
    margin: 0 !important;
}


.demandeAppel_container{
    width:100%;
    height: 100%;
}

.demandeAppel_container .documentsContainer {
    width: 320px;
    height: 100%;
    display: block;
}

.AccordCtrl_container{
    align-items: center !important;
    align-content: center !important;
    text-align: center !important;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height:100%;
}


.AccordCtrl_container .AccordCtrl_contract{
    display: flex;
    justify-content: center;
    height: 30%;
}

.AccordCtrl_container div:nth-child(2){
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin: auto;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
}

.AccordCtrl_container .checkIcon {
    width: 1.1em !important;
    height: 1.1em !important;
  }

  .AccordCtrl_container .closeSharpIcon {
    width: 1.1em !important;
    height: 1.1em !important;
  }


/* /////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////// ////////////////////////*/

.plus_details_dialog_container{
    padding: 18px;
    margin-bottom: 10px;
    display:flex;
    justify-content: space-between;
    align-items: flex-start;
    align-content: flex-start;
    flex-wrap: wrap;
}

.plus_details_dialog_container .header_form {
    width:205px;
    margin:0px 35px 15px 0px !important;
}


.demandeAppel_container .MuiTableCell-body:nth-child(5) {
    min-width:295px !important;
}


@media all and (max-width: 1080px){
    
    .plus_details_dialog_container .header_form {
        width:205px;
        margin:0px 35px 10px 0px !important;
    }
      
}


@media all and (max-width: 846px){

    .plus_details_dialog_container .header_form {
        width:190px;
        margin:0px 15px 10px 0px !important;
    }
}


@media all and (max-width: 750px){

    .plus_details_dialog_container .header_form {
        width:185px;
        margin:0px 15px 6px 0px !important;
    }
}





@media all and (max-width: 580px){
    
    .plus_details_dialog_container .header_form {
        width:180px;
    }
}    
    



@media all and (max-width: 445px){
    
    .plus_details_dialog_container .header_form {
        width:150px;
        margin:0px 10px 4px 0px !important;
    }

}




@media all and (max-width: 379px){

    .plus_details_dialog_container .header_form {
        width:160px;
        margin:0 !important;
    }
}



.contract_full_dialog .MuiAppBar-positionFixed {
    position: relative !important;
}

.contract_type_Container{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    margin: 10px auto 30px auto !important;
}

.contract_content_toolbar_left .search_filter_container {
   align-items: center !important;
   width: -webkit-fit-content !important;
   width: -moz-fit-content !important;
   width: fit-content !important;
}


.contract_type{
    width: 100%;
    display: flex;
    justify-content: center;
    vertical-align: middle;
    align-items: center;
}


.contract_content_toolbar{
    background-color: #343a40;
    display: flex;
    align-items: center;
    align-content: center;
}

@media (min-width: 600px){
    .MuiToolbar-gutters {
        padding-left: 14px !important;
        padding-right: 14px !important;
    }
}

.contract_content_toolbar_main_container{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.contract_content_toolbar_left{
    width: 45%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
    flex-wrap: wrap;
}

.contract_content_toolbar_left .page_title{
    flex-direction: row;
    align-items: center;
}

.contract_content_toolbar_left .header_form{
    margin-left: 20px !important;
    margin-right: 0px !important;
}

.contract_content_toolbar_right .icon_buttons_container{
    min-width: 122px;
}


.supplier_contract_content_toolbar .contract_content_toolbar_right .icon_buttons_container {
    min-width: -webkit-fit-content;
    min-width: -moz-fit-content;
    min-width: fit-content;
}

.contrat_type{
    font-size: 1.35rem !important;
    text-transform: uppercase;
}


.contract_content_toolbar_left .MuiInputLabel-shrink {
    -webkit-transform: translate(0, 5.5px) scale(0.75) !important;
            transform: translate(0, 5.5px) scale(0.75) !important;
    -webkit-transform-origin: top left;
            transform-origin: top left;
    color: #f9f3f3 !important;
}

.contract_content_toolbar_left label + .MuiInput-formControl {
    margin-top: 16px;
    color: #f9f3f3 !important;
}

.contract_content_toolbar_left .MuiSelect-select.MuiSelect-select {
    padding-right: 24px;
    color: #f9f3f3 !important;
    background-color: inherit !important;
}

.contract_content_toolbar_left .MuiInput-underline:before {
    border: none !important;
}

.contract_content_toolbar_left .MuiSelect-icon {
    color: #f9f3f3 !important;
}

.contract_content_toolbar_left .MuiInputBase-root {
    font-family: var(--body-font) !important;
    border: solid 1px #f9f3f3 !important;
    padding-left: 6px !important;
    border-radius: 4px !important;
    background-color: inherit !important;
}

.contract_content_toolbar_left .MuiSelect-nativeInput {
    left: 0;
    width: 100%;
    bottom: 0;
    opacity: 0;
    position: absolute;
    pointer-events: none;
}


.contract_content_toolbar_right{
    width: 55%;
    height: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    align-content: center;
    flex-wrap: wrap;
}


.contract_content_toolbar_right .border_button{
    font-size:.85rem !important;
    padding: .28rem .9rem !important;
}

.contract_content_toolbar_right .contract_being_edited{
    background-color:#f33640f3 !important;
    font-size:.85rem !important;
    padding: .28rem .9rem !important;
    font-weight: 600;
    color: white !important;
    margin-right:3px !important;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    align-self: center;
  }

.contract_content_toolbar_right .able_to_changeContract{
    background-color:#33b66e !important;
    font-weight: 600;
    color: white !important;
    margin-right:3px !important;
  }
  
.contract_content_toolbar_right .unable_to_changeContract{
    background-color:#ffa726 !important;
    font-weight: 600;
    color: white !important;
    margin-right:3px !important;
}
  


.text_editor_container{
    width: 100%;
    flex: 1 1;
    background: rgb(235, 235, 235);
    background-repeat: no-repeat;
    background-size: cover;
    padding: 15px;
    overflow: auto;
    margin-top: 0;
    overflow-x: hidden;
}

.sun-editor {
    height: 95% !important;
}

.sun-editor-editable p {
    margin: 0 0 3px !important;
}

.sun-editor .se-resizing-bar {
    display: none !important;
}

.sun-editor .se-wrapper .se-wrapper-inner {
    height: 100% !important;
}

.sun-editor .se-wrapper {
    position: relative!important;
    width: 100% !important;
    height: 100% !important;
    overflow: auto;
    z-index: 1;
}


@media (max-width: 1100px){

    .contract_content_toolbar_main_container{
        align-items: flex-end;
        padding: 5px 0;
    }

    .contract_content_toolbar_main_container .MuiIconButton-root {
        padding: 10px;
    }

    .contract_content_toolbar_left .header_form {
        margin-left: 0px !important;
        width: 185px !important;
    }

    .contract_content_toolbar_right .border_button {
        font-size: .75rem !important;
        padding: 0.22rem 0.8rem !important;
    }

    .contract_content_toolbar_right .unable_to_changeContract {
        font-size: .75rem !important;
        padding: 0.22rem 0.8rem !important;
    }

    .sun-editor .se-svg, .sun-editor button>svg {
        width: 15px !important;
        height: 15px !important;
    }

    .sun-editor .se-btn {
        width: 31px !important;
        height: 31px !important;
    }

    .sun-editor .se-btn-select.se-btn-tool-font {
        width: 92px !important;
    }

    .sun-editor .se-btn-select.se-btn-tool-size {
        width: 70px !important;
    }
}

@media (max-width: 850px){

    .contract_content_toolbar_left .header_form {
        width: 175px !important;
    }

    .contract_content_toolbar_main_container .MuiIconButton-root {
        padding: 8px;
    }

    .contract_content_toolbar_left {
        width: 240px;
    }

    .contract_content_toolbar_right {
        flex: 1 1;
    }

    .contract_content_toolbar_right .border_button {
        font-size: .7rem !important;
        padding: 0.2rem 0.7rem !important;
    }

    .contract_content_toolbar_right .unable_to_changeContract {
        font-size: .7rem !important;
        padding: 0.2rem 0.7rem !important;
    }

    .sun-editor .se-svg, .sun-editor button>svg {
        width: 14px !important;
        height: 14px !important;
    }

    .sun-editor .se-btn {
        width: 28px !important;
        height: 28px !important;
    }

    .sun-editor .se-btn-select.se-btn-tool-font {
        width: 100px !important;
    }

    .sun-editor .se-btn-select.se-btn-tool-font {
        width: 86px !important;
    }

    .sun-editor .se-btn-select.se-btn-tool-size {
        width: 67px !important;
    }

    .text_editor_container{
        padding: 12px;
    }
}


@media (max-width: 682px){
    .contract_content_toolbar_right .icon_buttons_container{
        margin-top: 5px;
    }

    .sun-editor .se-svg, .sun-editor button>svg {
        width: 12px !important;
        height: 12px !important;
    }

    .sun-editor .se-btn {
        width: 25px !important;
        height: 25px !important;
    }

    .sun-editor .se-btn-select.se-btn-tool-font {
        width: 80px !important;
    }

    .sun-editor .se-btn-select.se-btn-tool-size {
        width: 60px !important;
    }

    .text_editor_container{
        padding: 10px;
    }
}


@media (max-width: 550px){

    .text_editor_container{
        padding: 7px;
    }
}


.data_complements_container h4{
    margin-top: 5px !important;
}

.data_complements_container .bottom_buttons{
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    display: flex;
    margin: 60px 0 0 0 !important;
    justify-content: flex-end;
    align-items: flex-end;
    align-content: flex-end;
}

.data_complements_container .dialogBottom {
  margin: 0 !important;
  margin-top: 30px !important;
}

.data_complements_container .dialogBottom div {
  display: flex;
  justify-content: flex-end;
  align-items: center;
} 

.data_complements_container .discussion_but{
    background-color: rgb(52, 58, 64);
    color: white;
}

.data_complements_container .discussion_but:hover{
    background-color: rgb(52, 58, 64);
    opacity: .8;
}

.data_complements_container .send_but{
    background-color: rgb(52, 58, 64);
    color: white;
}

.data_complements_container .send_but:hover{
    background-color: rgb(52, 58, 64);
    opacity: .8;
}



  .divider_choices {
    text-align: center;
    font-size: 1.1rem;
    font-weight: 600;
    position: relative;
    margin: 0 23px;
    top: -2px;
  }
  
  .divider_choices:before {
    content: "";
    display: block;
    width: 13px;
    height: 2px;
    background-color: rgb(52, 58, 64);
    left: 29px;
    top: 50%;
    position: absolute;
  }
  
  .divider_choices:after {
    content: "";
    display: block;
    width: 13px;
    height: 2px;
    background-color: rgb(52, 58, 64);
    right: 29px;
    top: 50%;
    position: absolute;
  }


  
@media all and (max-width: 1080px){
    
  .divider_choices {
    font-size: .95rem;
  }

  .divider_choices:before {
    width: 11px;
    height: 1.5px;
  }
  
  .divider_choices:after {
    width: 11px;
    height: 1.5px;
  }
}

@media all and (max-width: 750px){

  .divider_choices {
    font-size: .9rem;
    margin: 0 19px;
  }
  

}





@media all and (max-width: 580px){
  
  .divider_choices {
    font-size: .85rem;
    margin: 0 16px;
  }

  .divider_choices:before {
    width: 8.5px;
    height: 1.5px;
    left: 23px;
  }
  
  .divider_choices:after {
    width: 8.5px;
    height: 1.5px;
    right: 23px;
  }
}    
  



@media all and (max-width: 445px){
  
  .divider_choices {
    font-size: .8rem;
    margin: 0 15px;
  }

  .divider_choices:before {
    width: 8px;
    height: 1px;
    left: 23px;
  }
  
  .divider_choices:after {
    width: 8px;
    height: 1px;
    right: 23px;
  }

}
.chatModal_container .MuiDialog-paperFullWidth {
  width: 60%;
  height: 100% !important;
  margin: 5px auto !important;
  padding: 0 !important;
}

.chatModal_container .MuiDialogContent-root{
  padding: 0 !important;
  overflow: hidden !important;
}



.chatModal_container #user{
  border-bottom: 1px solid; font-size: 16pt; display: flex; align-items: center;
}

.chatModal_container .modal-content .modal-body {
  padding: 0 !important;
}


.chatModal_container .chatView_container {
  height: 100%;
  width: 100%;
  background-color: rgb(249 249 249);
  border: solid 1px rgb(87, 87, 87);
  display: flex;
  flex-direction: column;
}


.chatModal_container .contact-profile {
  width: 100%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  font-weight: 600;
  padding: 15px !important;
  background: #f5f5f5;
  font-size: 1.3rem !important;
}

.chatView_container .messages {
  flex: 1 1;
  min-height: auto !important;
  overflow-y: scroll;
  overflow-x: hidden;
  width: 100%;
  padding: 0 !important;
  margin-bottom: 0;
}


.chatView_container ul li:nth-last-child(1) {
  margin-bottom: 12px !important;
}

.chatView_container .messages::-webkit-scrollbar {
  width: 8px;
  background: transparent;
  cursor: pointer;
}
.chatView_container .messages::-webkit-scrollbar-thumb {
  background-color: rgba(141, 141, 141);
  cursor: pointer;
}
.chatView_container ul li {
  display: inline-block;
  clear: both;
  width: calc(100% - 60px);
  font-size: .95em;
  margin: 9px 0;
}

.chatView_container .emetteur{
  position: relative;
  display: inline-block;
  justify-content: flex-start;
  margin: 9px 3.5rem !important;
  font-family: nunito;
}
.chatView_container .emetteur > span{
  min-width: 10ch;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  display: flex;
  align-items: center;
  max-width: 40ch;
  padding: .3rem .5rem;
  border-radius: .2rem;
  background: #2c3e50bd;
  position: relative;
  color: white !important;

}

.chatView_container .emetteur >  div:nth-child(1){
  position: relative;
  left: -2.5rem;
  font-size: 1rem;
  font-weight: 700;
}

.chatView_container .emetteur .temps{
  font-size: .8rem;
  top: 100%;
  left: 0%;
  width: -webkit-max-content;
  width: max-content;
}


.destinateur{
  position: relative;
  font-family: nunito;
  text-align: -webkit-right;
}

.destinateur > span{
  border: solid 1px gray;
  min-width: 10ch;
  text-align: left !important;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  display: flex;
  align-items: center;
  max-width: 40ch;
  padding: .3rem .5rem;
  border-radius: .2rem;
  background: whitesmoke;
  position: relative;
} 
.destinateur > div:nth-child(1){
  font-size: 1rem;
  font-weight: 700;
  position: relative;
  max-width: 8ch;
  overflow: hidden;
  text-overflow: ellipsis;
  left: 45px;
}


#messages .text > span{
  word-wrap: break-word;
  max-width: 38ch;
  display: block;
}

.input_container{
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  display: flex;
  border-top: solid 1px #777777;
  padding: 0px;
  margin: 0 !important;
  padding: 10px 14px;
}

.input_container textarea{
  max-width: 100% !important;
  max-height: none !important;
  padding: 9px 5px 7px 9px;
  resize: auto !important;
  font-size: 1rem !important;
  border: solid 0.1rem #777777 !important;
  color: #555353;
  border-radius: 7px;
  line-height: 1 !important;
  margin: 0 !important;
}

.textarea_container{
  min-width:94%;
  max-width:94%;
  height:100%;
  position: relative;
}

.sendBut_container{
  align-self: center;
  min-width: 15px;
  margin-left: 7px;
}

@media all and (max-width: 975px){
  .emetteur > span, .destinateur > span{
      max-width: 35ch !important;
  }
}

@media all and (max-width: 880px){
  .chatModal_container .MuiDialog-paperFullWidth {
      width: 75% !important;
  }
}



@media all and (max-width: 750px){
  .chatModal_container .MuiDialog-paperFullWidth {
      width: 80% !important;
  }

  .chatModal_container .contact-profile {
      padding: 12px !important;
      font-size: 1.2rem !important;
  }

  .emetteur > div:nth-child(1), .destinateur > div:nth-child(1) {
      font-size: .95rem !important;
  }

  .chatView_container .emetteur {
      margin: 7px 3rem !important;
  }

  .chatView_container .emetteur > div:nth-child(1) {
      left: -2.2rem;
  }

  .chatView_container .destinateur{
      margin: 7px 0 !important;
  }
  .chatView_container ul li {
      width: calc(100% - 55px) !important;
      font-size: .9em !important;
  }

  .chatView_container .emetteur .temps {
      font-size: .75rem;
  }

  .input_container textarea{
      padding: 7px 4px 6px 7px;
      font-size: .95rem !important;
  }

  .emetteur > span, .destinateur > span{
      max-width: 32ch !important;
  }
}



@media all and (max-width: 580px){
  .chatModal_container .MuiDialog-paperFullWidth {
      width: 89% !important;
  }

  .chatModal_container .contact-profile {
      padding: 9px !important;
      font-size: 1.1rem !important;
  }

  .emetteur > div:nth-child(1), .destinateur > div:nth-child(1) {
      font-size: .9rem !important;
  }

  .chatView_container .emetteur {
      margin: 5px 2.5rem !important;
  }

  .chatView_container .emetteur > div:nth-child(1) {
      left: -2rem !important;
  }

  .chatView_container .destinateur{
      margin: 5px 0 !important;
  }
  .chatView_container ul li {
      width: calc(100% - 55px) !important;
      font-size: .85em !important;
  }

  .chatView_container .emetteur .temps {
      font-size: .7rem;
  }

  .input_container textarea{
      padding: 7px 4px 5px 6px;
      font-size: .9rem !important;
  }

  .emetteur > span, .destinateur > span{
      max-width: 27ch !important;
  }
}


@media all and (max-width: 450px){
  .chatModal_container .MuiDialog-paperFullWidth {
      width: 97% !important;
  }

  .emetteur > div:nth-child(1), .destinateur > div:nth-child(1) {
      font-size: .85rem !important;
  }

  .input_container textarea{
      padding: 5px 5px 4px 5px;
      font-size: .85rem !important;
  }

  .emetteur > span, .destinateur > span{
      max-width: 23ch !important;
  }
}
@media all and (max-width: 380px){
    .setCommission_dialog .header_form{
        margin-right: 7px !important;
    }
}


.litigations_container{
    width: 100%;
    height: 100%;
    margin: 0;
}


.litigations_container_header{
    font-size: 1.2rem;
    padding: 10px 20px;
    border-bottom: solid 1px black;
    width: 95%;
    margin: auto;
    margin-bottom: 20px;
}

.litigations_container_header div{
    margin: 25px auto 0 auto;
}

.litigations_container .litigations_table{
    width: 95%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    margin: auto;
}

.references_container {
    width: 100%;
    height: -webkit-fit-content !important;
    height: -moz-fit-content !important;
    height: fit-content !important;
    overflow: hidden;
    margin: 0;
    margin-bottom: 30px !important;
}


.references_container .references_header_container{
    font-size: 1.2rem;
    padding: 0 20px 15px 20px;
    width: 95%;
    margin: auto;
    margin-top: 30px;
    margin-bottom: 20px;
    border-bottom: solid 1px black;
    display: flex;
    justify-content: space-between;
}


.references_container .select_container select{
    cursor: pointer;
}

.MuiTableCell-head {
    color: rgba(26, 24, 24, 0.87) !important;
    font-weight: 600 !important;
}


.references_container .reference_table{
    width: 97% !important;
    margin: auto !important;
}


.references_container .MuiTableCell-body:nth-child(4) {
    min-width:250px;
}

.documentsContainer{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.documentsDownload{
    width: 60%;
    display: flex;
    vertical-align: middle;
    align-items: center;
}

.documentsTitle{
    font-weight: 700;
    color: rgb(64, 64, 65);
    margin-left: 7px;
    align-self: center;
}

.documentsStatus_valide{
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    min-width: 70px;
    display: flex;
    justify-content: space-around;
}

.references_container .MuiTableCell-body:nth-child(5) {
    min-width:230px;
}


.references_container textarea{
    max-width: 100% !important;
    max-height: none !important;
    padding: 7px 5px 7px 5px;
    resize: auto !important;
    font-size: .9rem !important;
    border: solid 0.1rem #E3E3E3 !important;
    border-radius: 0;
    line-height: 1 !important;
}


.references_container .MuiTableCell-body:nth-last-child(1){
    min-width:70px !important;
}

.references_container .MuiTableCell-body:last-child {
    min-width:210px !important;
    padding-right: 20px !important;
}

.referenceStatusContainer{
    width: 100%;
    text-align: end;
    align-items: end;
    display: flex;
    justify-content: right;
}

.referenceStatusContainer2{
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    display: block;
    justify-content:center;
    text-align: center;
    align-items: center;
}


.refusedButton{
    pointer-events: none;
    all: unset;
    background:#d0336af3;
    color: white;
    padding: .3rem .9rem;
    padding-top: .35rem;
    font-size: .7rem !important;
    border-radius: 4px;
    text-align: center;    
}

.acceptedButton{
    pointer-events: none;
    all: unset;
    background:#33b66e;
    color: white;
    padding: .3rem .9rem;
    padding-top: .35rem;
    font-size: .7rem !important;
    border-radius: 4px;
    text-align: center;    
}




.referencecheckIcon{
    font-size: 2.9rem !important;
    background-color: rgb(253, 253, 253);
    border-radius: 100%;
    cursor: pointer;
    text-align: center;
    margin-right: 9px;
    padding: 5px;
    color:#00b200;
}

.referencecloseSharpIcon:hover{
    background-color: #fcf5f5;
}

.referencecloseSharpIcon{
    font-size: 2.9rem !important;
    background-color: rgb(253, 253, 253);
    border-radius: 100%;
    cursor: pointer;
    text-align: center;
    padding: 5px;
    color:#f33640f3;
}

.referencecheckIcon:hover{
    background-color: #e9fce9;
}



@media all and (max-width: 750px){
    

    .references_container .references_header_container {
        padding: 0 10px 12px 10px;
        width: 97%;
        margin: auto;
        margin-top: 20px;
        margin-bottom: 20px;
    }
}


@media all and (max-width: 580px){
    

    .references_container .references_header_container {
        display: flex;
        flex-direction: column;
    }

    .search_filter_container {
        width: 100%;
        display: flex;
        justify-content: center;
    }

    .referencecheckIcon {
        font-size: 2.5rem !important;
        margin-right: 6px;
        padding: 3px;
    }

    .referencecloseSharpIcon{
        font-size: 2.5rem !important;
        padding: 3px;
    }
}




@media all and (max-width: 445px){

    .references_container .references_header_container {
        padding: 0 5px 10px 5px !important;
    }

}

.set_refuse_raison_container .MuiOutlinedInput-multiline {
    padding: 7.5px 14px !important;
}

.set_refuse_raison_container  .dialogBottom div:nth-child(1) {
    width: 100% !important;
    text-align: -webkit-right;
    padding-top: 3px;
}


/*
///////////////////////////////////////////////////////
/////////////////////////////////////////////////////*/




.suppliers_container {
    width: 100%;
    height: -webkit-fit-content !important;
    height: -moz-fit-content !important;
    height: fit-content !important;
    overflow: hidden;
    margin: 0;
    margin-bottom: 30px !important;
}


.suppliers_container .suppliers_header_container{
    font-size: 1.2rem;
    padding: 0 20px 15px 20px;
    width: 95%;
    margin: auto;
    margin-top: 30px;
    margin-bottom: 20px;
    border-bottom: solid 1px black;
    display: flex;
    justify-content: space-between;
}


.suppliers_container .select_container select{
    cursor: pointer;
}

.MuiTableCell-head {
    color: rgba(26, 24, 24, 0.87) !important;
    font-weight: 600 !important;
}


.suppliers_container .reference_table{
    width: 90% !important;
    margin: auto !important;
}


.suppliers_container .MuiTableCell-body:nth-child(4) {
    min-width:150px;
}

.documentsContainer{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.documentsDownload{
    width: 60%;
    display: flex;
    vertical-align: middle;
    align-items: center;
}

.documentsTitle{
    font-weight: 700;
    color: rgb(64, 64, 65);
    margin-left: 7px;
    align-self: center;
}

.documentsStatus_valide{
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    min-width: 70px;
    display: flex;
    justify-content: space-around;
}

.suppliers_container .MuiTableCell-body:nth-child(3) {
    min-width:230px;
}


.suppliers_container textarea{
    max-width: 100% !important;
    max-height: none !important;
    padding: 7px 5px 7px 5px;
    resize: auto !important;
    font-size: .9rem !important;
    border: solid 0.1rem #E3E3E3 !important;
    border-radius: 0;
    line-height: 1 !important;
}


.suppliers_container .MuiTableCell-body:nth-last-child(1){
    min-width:70px !important;
}

.suppliers_container .MuiTableCell-body:last-child {
    min-width:210px !important;
    padding-right: 20px !important;
}

.referenceStatusContainer{
    width: 100%;
    text-align: end;
    align-items: end;
    display: flex;
    justify-content: right;
}

.referenceStatusContainer2{
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    display: block;
    justify-content:center;
    text-align: center;
    align-items: center;
}


.refusedButton{
    pointer-events: none;
    all: unset;
    background:#d0336af3;
    color: white;
    padding: .3rem .9rem;
    padding-top: .35rem;
    font-size: .7rem !important;
    border-radius: 4px;
    text-align: center;    
}

.acceptedButton{
    pointer-events: none;
    all: unset;
    background:#33b66e;
    color: white;
    padding: .3rem .9rem;
    padding-top: .35rem;
    font-size: .7rem !important;
    border-radius: 4px;
    text-align: center;    
}




.referencecheckIcon{
    font-size: 2.9rem !important;
    background-color: rgb(253, 253, 253);
    border-radius: 100%;
    cursor: pointer;
    text-align: center;
    margin-right: 9px;
    padding: 5px;
    color:#00b200;
}

.referencecloseSharpIcon:hover{
    background-color: #fcf5f5;
}

.referencecloseSharpIcon{
    font-size: 2.9rem !important;
    background-color: rgb(253, 253, 253);
    border-radius: 100%;
    cursor: pointer;
    text-align: center;
    padding: 5px;
    color:#f33640f3;
}

.referencecheckIcon:hover{
    background-color: #e9fce9;
}



@media all and (max-width: 750px){
    

    .suppliers_container .suppliers_header_container {
        padding: 0 10px 12px 10px;
        width: 97%;
        margin: auto;
        margin-top: 20px;
        margin-bottom: 20px;
    }
}


@media all and (max-width: 580px){
    

    .suppliers_container .suppliers_header_container {
        display: flex;
        flex-direction: column;
    }

    .search_filter_container {
        width: 100%;
        display: flex;
        justify-content: center;
    }

    .referencecheckIcon {
        font-size: 2.5rem !important;
        margin-right: 6px;
        padding: 3px;
    }

    .referencecloseSharpIcon{
        font-size: 2.5rem !important;
        padding: 3px;
    }
}




@media all and (max-width: 445px){

    .suppliers_container .suppliers_header_container {
        padding: 0 5px 10px 5px !important;
    }

}
.create-supplier_form{
    margin: 35px auto 10px auto;
    max-width: 550px;
    justify-content: center;
}


.create-supplier_form .form{
    text-align: -webkit-right;
    padding-top: 0px !important;
}

.create-supplier_form h2 {
    margin-bottom: 0px !important;
    font-size:2rem !important;    
    width: 100%;
    display: flex;
    justify-content: center;
}

.create-supplier_form label{
    width: 100%;
    text-align: left;
    margin: 0;
    font-size: 1rem;
    font-weight: 600;
    padding-left: 2px;
}

.error{
    width: 100%;
    font-size: 1.1em;
    color: #e13d68 !important;
    background: #dc35451f;
    padding: 10px 20px;
    border-radius: 5px;
    margin-bottom: 20px;
    text-align: start;
}


.create-supplier_form .row {
    margin: 20px 0;
}

.create-supplier_form .form-control {
    border: 1px solid #c4c3c3;
    border-bottom-width: 1px !important;
    border-radius: 6px;
    color: #2c2c2c;
    height: auto;
    font-size: .95rem;
    box-shadow: none;
    margin-bottom: 10px;
    height: 39px;
    background-image: none !important;
    background-color: white !important;
}

.create-supplier_form .form-select-control {
    border-style: solid !important;
    border-width: 1px  !important;
    border-color: #c4c3c3 !important;
}

.create-supplier_form .MuiInput-underline:after {
    border-bottom: none !important;
}


.create-supplier_form .MuiInput-underline:before {
    border-bottom: none !important;
}

.create-supplier_form .MuiSelect-select.MuiSelect-select{
    font-family: var(--body-font) !important;
    text-align: left !important;
  }

.create-supplier_form .form-select{
    cursor: pointer;
}

.create-supplier_form .form-select .MuiInputBase-input {
    background-color: white !important;
}

.create-supplier_form  .Mui-focused {
    border: black;
}

.create-supplier_form .MuiSelect-select {
   min-width: 100% !important;
}

input,select{
    box-shadow: none !important;
}
 input[type="checkbox"]{
     cursor: pointer;
}


.create-supplier_form .submit_but{
    all: unset;
    background:#343a40;
    color: white;
    font-size: 1.08rem;
    width: 160px;
    height: 28px;
    cursor:pointer !important;
}
.create-supplier_form .submit_but:hover{
    background: #343a40;
    opacity: .9;
}

.create-supplier_form .under_submit{
    display: flex;
    justify-content: space-between;
}

.create-supplier_form .under_submit a{
    text-decoration: none;
    font-size: 12pt;
}

.create-supplier_form .disabled_button{
    pointer-events: none;
    opacity: 0.4;
  }


  @media all and (max-width: 1080px){
    
    .create-supplier_form h2 {
        margin-bottom: 0px !important;
        font-size:1.8rem !important;    
        width: 100%;
        display: flex;
        justify-content: center;    
    }

    .create-supplier_form label{
        font-size: .95rem;
    }

    .create-supplier_form .form-control {
        font-size: .9rem;
        height: 39px;
    }

    .create-supplier_form .form-select{
        font-size: .9rem;
    }

    .create-supplier_form .submit_but{
        width: 150px;
        height: 26px;
        font-size: 1.05rem;
        margin-top: 25px;
    }

    .create-supplier_form .my_icon{
        width: 19px !important;
        height: 19px !important;
    }

}



@media all and (max-width: 645px){

    .create-supplier_form{
        margin: 40px auto;
        width: 97%;
    }
    .create-supplier_form .form{
        padding: 0 10px !important;

    }

  }


@media all and (max-width: 420px){
    
    .create-supplier_form{
        max-width: 600px;
    }

    
    .create-supplier_form h2 {
        margin-bottom: 0px !important;
        font-size:1.6rem !important;  
    }

    .create-supplier_form .row {
        margin: 10px 0;
    }

    .create-supplier_form label{
        font-size: .85rem;
    }

    .create-supplier_form .form-control {
        font-size: .85rem;
    }

    .create-supplier_form .form-select{
        font-size: .85rem;
    }

    .error{
        font-size: 1em;
        padding: 7px 20px;
        margin-bottom: 15px;
    }

    .create-supplier_form .submit_but {
        width: 100px;
        height: 23px;
        font-size: 1rem;
        margin-top: 15px !important;
    }
      
}

.service_commissions_container{
    height: 100%;
}

.service_commissions_container .commissions_container_header{
    font-size: 1.2rem;
    padding: 0 20px 15px 20px;
    width: 95%;
    margin: auto;
    margin-top: 30px;
    margin-bottom: 20px;
    border-bottom: solid 1px black;
    display: flex;
    justify-content: space-between;
}


.commissions_search_system .MuiGrid-container {
    width: -webkit-fit-content !important;
    width: -moz-fit-content !important;
    width: fit-content !important;
    align-content: center !important;
    position: relative !important;
}

.commissions_table_container{
    width: 99%;
    margin: auto;
    margin-bottom: 50px;
}


.service_commissions_container .MuiTableCell-body:nth-child(5) {
    min-width:120px;
}

.service_commissions_container .MuiTableCell-body:nth-child(6) {
    min-width:350px;
}

.service_commissions_container .MuiTableCell-body:nth-child(7) {
    min-width:100px;
}


.service_commissions_container.commissions_container_header .search_system{
    display: flex;
}


.service_commissions_container .commissions_container_header .MuiGrid-spacing-xs-1 {
    width: -webkit-fit-content !important;
    width: -moz-fit-content !important;
    width: fit-content !important;
}

  
.documentsTitle .MuiSelect-select.MuiSelect-select {
    padding-right: 2px !important;
    padding-bottom: 2px !important;
    padding-top: 0px !important;
    border: none;
    font-size: .9rem;
}
.dates_proposition_dialog textarea{
    max-width: 100% !important;
    max-height: none !important;
    padding: 5px 10px 7px 5px !important;
    resize: auto !important;
    font-size: 1rem;
    border: solid 0.1rem #b1b0b0 !important;
    border-radius: 0;
    line-height: 1 !important;
}

.dates_proposition_dialog .MuiDialog-paperFullWidth {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding: 10px !important;
}

.dates_proposition_dialog .plus_details_dialog_container{
    padding: 0 !important;
}


.dates_proposition_dialog .header_form:last-child{
    margin-right: 0 !important;
}

.membres_details_dialog_container{
    padding: 20px;
    margin-bottom: 10px;
}
.notify_auditor_again_dialog .dialogBottom {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    display: flex;
    justify-content: space-between;
    padding: 5px;
    margin: 0;
}
.notify_auditor_again_dialog .dialogBottom div:nth-child(1) {
    width: 100%;
    text-align: -webkit-right;
    padding-top: 3px;
}


.contracts_list_container{
    width: 95%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    margin: 0 auto;
}

.contracts_list_container_header{
    font-size: 1.2rem;
    padding: 0 20px;
    padding-bottom: 10px;
    border-bottom: solid 1px black;
    width: 100%;
    margin: auto;
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
}


.contracts_list_container .Contracts_list_table{
    width: 100% !important;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    margin: auto !important;
}

.contracts_list_container .MuiTableCell-body:nth-child(0) {
    min-width:80px !important;
}

.contracts_list_container .MuiTableCell-body:nth-child(1) {
    min-width:80px !important;
}

.contracts_list_container .MuiTableCell-body:nth-child(2) {
    min-width:150px !important;
}


.contracts_list_container .MuiTableCell-body:nth-child(3) {
    min-width:80px !important;
}


.contracts_list_container .MuiTableCell-body:nth-child(4) {
    min-width:235px !important;
}

.contracts_list_container .MuiTableCell-body:nth-child(5) {
    min-width:80px !important;
}

.contracts_list_container .MuiTableCell-body:nth-child(6) {
    min-width:80px !important;
}



.subTypes_container{
    width:100%;
    height: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.subType_container{
    width:100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    display: flex;
    margin-bottom: 5px;
    align-items: center;
}

.subType{
    width:60%;
    height: 100%;
}

.subType_state{
    width:40%;
    height: 100%;
    display: contents;
}

.subType_sign_date_container{
    margin-bottom: 5px;
}
.MuiPaper-rounded {
    padding: 0 !important;
    border-radius: 0 !important;
}

.suppliers_table{
    padding: 0 !important;
    border-radius: 0 !important;
    margin: 0 !important;
    font-size: .5rem !important;
    font-family: var(--body-font) !important;
    height: 100%;
    width: 100%;
}

.suppliers_table .MuiTableCell-body:nth-child(1) {
    min-width:105px;
}

.suppliers_table .MuiTableCell-body:nth-child(2) {
    min-width:95px;
}
.suppliers_table .MuiTableCell-body:nth-child(3) {
    min-width:55px;
}
.suppliers_table .MuiTableCell-body:nth-child(4) {
    min-width:65px;
}
.widget_count_elems_container{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    font-size: 3.8rem;
    cursor: pointer;
    padding: 25px 0;
}


.widget_count_elems_container .number_arrow_container{
    position: relative;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
}

.widget_count_elems_container .arrow_container{
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    position: absolute;
    top: -5px;
    right: -45px;
    display: flex;
    color: green;
}

.widget_count_elems_container .arrow_container .progress_icon{
    width: 2em;
    height: 2em;
}
.charts_of_the_bestOffers_container{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.charts_of_the_bestOffers_container .container{
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
}

.charts_of_the_bestOffers_container .categorie_container{
    width: 82%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    margin: auto;
    margin-bottom: 15px;
    padding-right: 15px;
    position: relative;
}


.charts_of_the_bestOffers_container .order_of_the_bestOffers {
    width: 50px;
    height: 50px;
    min-width: 50px;
    min-height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    border-radius: 100%;
    position:relative;
    right: -12px;
}

.charts_of_the_bestOffers_container .order_of_the_bestOffers span{
    color:white !important;
}


.charts_of_the_bestOffers_container .amount_of_the_bestOffers{
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    flex: 1 1;
    font-size: 1.2rem;
    padding: 5px 15px;
    text-align: center;
    color: rgb(52, 51, 51);
    font-weight: 700;
    background:linear-gradient(to right, #e0b663ad, #FCF6BA );
    border-radius: 3px;
    border-left: none;
    width: fit-content;
    min-width: 145px;
}


.charts_of_the_bestOffers_container .css-i4bv87-MuiSvgIcon-root {
    font-size: 2.2rem !important;
}


@media all and (max-width: 1200px){

    .charts_of_the_bestOffers_container .categorie_container{
        width: 86%;
        margin-bottom: 13px;
    }

    .charts_of_the_bestOffers_container .amount_of_the_bestOffers{
        font-size: 1.08rem;
        padding: 5px 15px;
        min-width: 135px;
    }
    
    
    .charts_of_the_bestOffers_container .order_of_the_bestOffers {
        width: 46px;
        height: 46px;
        min-width: 46px;
        min-height: 46px;
        right: -12px;
    }

}


@media all and (max-width: 1080px){

    .charts_of_the_bestOffers_container .categorie_container{
        margin-bottom: 12px;
    }

    .charts_of_the_bestOffers_container .amount_of_the_bestOffers{
        font-size: 1.02rem;
        padding: 5px 15px;
        min-width: 135px;
    }
    
    
    .charts_of_the_bestOffers_container .order_of_the_bestOffers {
        width: 43px;
        height: 43px;
        min-width: 43px;
        min-height: 43px;
        right: -11px;
    }

}



@media all and (max-width: 900px){


    .charts_of_the_bestOffers_container .categorie_container{
        margin-bottom: 9px;
    }

    .charts_of_the_bestOffers_container .amount_of_the_bestOffers{
        font-size: .92rem;
        padding: 4px 15px;
        min-width: 135px;
    }
    
    
    .charts_of_the_bestOffers_container .order_of_the_bestOffers {
        width: 39px;
        height: 39px;
        min-width: 39px;
        min-height: 39px;
        right: -11px;
    }

}


@media all and (max-width: 500px){


    .charts_of_the_bestOffers_container .categorie_container{
        margin-bottom: 7px;
    }

    .charts_of_the_bestOffers_container .amount_of_the_bestOffers{
        font-size: .82rem;
        padding: 4px 15px;
        min-width: 125px;
    }
    
    
    .charts_of_the_bestOffers_container .order_of_the_bestOffers {
        width: 33px;
        height: 33px;
        min-width: 33px;
        min-height: 33px;
        right: -11px;
    }

}

.MuiPaper-rounded {
    padding: 0 !important;
    border-radius: 0 !important;
}

.offers_table{
    padding: 0 !important;
    border-radius: 0 !important;
    margin: 0 !important;
    font-size: .5rem !important;
}

.offers_table .MuiTableCell-body:nth-child(1) {
    min-width:125px !important;
}

.offers_table .MuiTableCell-body:nth-child(2) {
    min-width:65px !important;
}
.offers_table .MuiTableCell-body:nth-child(3) {
    min-width:65px !important;
}
.offers_table .MuiTableCell-body:nth-child(4) {
    min-width:65px !important;
}

.offers_table .MuiTableCell-body:nth-child(5) {
    min-width:115px;
}

.offers_table .MuiTableCell-body:nth-child(6) {
    min-width:105px !important;
}
.radialCharts_offersState_container{
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: space-around;
    cursor: pointer;
    position: relative;
}

.radialCharts_offersState_container .radialCharts_container{
    width: 33%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    position: relative;
}


.radialCharts_offersState_container .apexcharts-text {
    font-family: var(--body-font) !important;
    font-size: 1.05rem !important;
}


@media all and (max-width: 1200px){
    .radialCharts_offersState_container .apexcharts-text {
        font-size: .97rem !important;
    }
}

@media all and (max-width: 879px){
    .radialCharts_offersState_container .apexcharts-text {
        font-size: .9rem !important;
    }
}

@media all and (max-width: 559px){
    .radialCharts_offersState_container .apexcharts-text {
        font-size: .85rem !important;
    }
}


@media all and (max-width: 450px){
    .radialCharts_offersState_container .apexcharts-text {
        font-size: .75rem !important;
    }
}

.MuiPaper-rounded {
    padding: 0 !important;
    border-radius: 0 !important;
}

.commissions_table{
    padding: 0 !important;
    border-radius: 0 !important;
    margin: 0 !important;
    font-size: .5rem !important;
}


.commissions_table .MuiTableCell-body:nth-child(1) {
    min-width:125px !important;
}

.commissions_table .MuiTableCell-body:nth-child(2) {
    min-width:65px !important;
}
.commissions_table .MuiTableCell-body:nth-child(3) {
    min-width:65px !important;
}
.commissions_table .MuiTableCell-body:nth-child(4) {
    min-width:65px !important;
}

.commissions_table .MuiTableCell-body:nth-child(5) {
    min-width:115px;
}

.commissions_table .MuiTableCell-body:nth-child(6) {
    min-width:105px !important;
}
.offers_number_byMonth_chart_container{
    width: 100%;
    height: 100%;
    margin: auto;
    padding: 0;
    padding-top: 10px;
    display: flex;
    flex-direction: column;
}


.apexcharts-canvas {
    position: relative;
    -webkit-user-select: none;
            user-select: none;
    margin: auto !important;
}

.offers_number_byMonth_chart_container .select_offers_categories_container{
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    display: flex;
    justify-content: flex-end;
    padding-right: 7px;
}

.offers_number_byMonth_chart_container .select_offers_categories_container .MuiSelect-select.MuiSelect-select {
    color: rgb(90, 93, 90) !important;
    font-size: .9rem;
    padding: 3px 0;
}

.offers_number_byMonth_chart_container .select_offers_categories_container .MuiInput-underline:before {
    border: none !important;
}

.offers_number_byMonth_chart_container .select_offers_categories_container .MuiSelect-icon {
    color: rgb(90, 93, 90) !important;
}

.offers_number_byMonth_chart_container .select_offers_categories_container .MuiInputBase-root {
    font-family: var(--body-font) !important;
    border: solid 1px rgb(90, 93, 90) !important;
    padding-left: 6px !important;
    border-radius: 4px !important;
}

.offers_number_byMonth_chart_container .select_offers_categories_container .MuiSelect-nativeInput {
    left: 0;
    width: 100%;
    bottom: 0;
    opacity: 0;
    position: absolute;
    pointer-events: none;
}

.MuiPaper-rounded {
    padding: 0 !important;
    border-radius: 0 !important;
}

.offers_totalAmount_widget{
    padding: 0 !important;
    border-radius: 0 !important;
    margin: 0 !important;
    font-size: .5rem !important;
}


.offers_totalAmount_widget .MuiTableCell-body:nth-child(1) {
    min-width:125px !important;
}

.offers_totalAmount_widget .MuiTableCell-body:nth-child(2) {
    min-width:65px !important;
}
.offers_totalAmount_widget .MuiTableCell-body:nth-child(3) {
    min-width:65px !important;
}
.offers_totalAmount_widget .MuiTableCell-body:nth-child(4) {
    min-width:65px !important;
}

.offers_totalAmount_widget .MuiTableCell-body:nth-child(5) {
    min-width:115px;
}

.offers_totalAmount_widget .MuiTableCell-body:nth-child(6) {
    min-width:105px !important;
}
.MuiPaper-rounded {
    padding: 0 !important;
    border-radius: 0 !important;
}

.count_awardedOffers_widget{
    padding: 0 !important;
    border-radius: 0 !important;
    margin: 0 !important;
    font-size: .5rem !important;
}

.count_awardedOffers_widget .MuiTableCell-body:nth-child(1) {
    min-width:125px !important;
}

.count_awardedOffers_widget .MuiTableCell-body:nth-child(2) {
    min-width:65px !important;
}
.count_awardedOffers_widget .MuiTableCell-body:nth-child(3) {
    min-width:65px !important;
}
.count_awardedOffers_widget .MuiTableCell-body:nth-child(4) {
    min-width:65px !important;
}

.count_awardedOffers_widget .MuiTableCell-body:nth-child(5) {
    min-width:115px;
}

.count_awardedOffers_widget .MuiTableCell-body:nth-child(6) {
    min-width:105px !important;
}
.MuiPaper-rounded {
    padding: 0 !important;
    border-radius: 0 !important;
}

.submissions_table{
    padding: 0 !important;
    border-radius: 0 !important;
    margin: 0 !important;
    font-size: .5rem !important;
}

.submissions_table .MuiTableCell-body:nth-child(1) {
    min-width:125px !important;
}

.submissions_table .MuiTableCell-body:nth-child(2) {
    min-width:65px !important;
}
.submissions_table .MuiTableCell-body:nth-child(3) {
    min-width:65px !important;
}
.submissions_table .MuiTableCell-body:nth-child(4) {
    min-width:65px !important;
}

.submissions_table .MuiTableCell-body:nth-child(5) {
    min-width:115px;
}

.submissions_table .MuiTableCell-body:nth-child(6) {
    min-width:105px !important;
}
.MuiPaper-rounded {
    padding: 0 !important;
    border-radius: 0 !important;
}

.delay_of_market_widget{
    padding: 0 !important;
    border-radius: 0 !important;
    margin: 0 !important;
    font-size: .5rem !important;
}


.delay_of_market_widget .MuiTableCell-body:nth-child(1) {
    min-width:125px !important;
}

.delay_of_market_widget .MuiTableCell-body:nth-child(2) {
    min-width:65px !important;
}
.delay_of_market_widget .MuiTableCell-body:nth-child(3) {
    min-width:65px !important;
}
.delay_of_market_widget .MuiTableCell-body:nth-child(4) {
    min-width:65px !important;
}

.delay_of_market_widget .MuiTableCell-body:nth-child(5) {
    min-width:115px;
}

.delay_of_market_widget .MuiTableCell-body:nth-child(6) {
    min-width:105px !important;
}

.offersChart_categorie_container{
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    padding: 10px;
    display: flex;
    flex-direction: column;
    cursor: default;
    font-size: .8rem;
}

.offersChart_byCategorie_header_container{
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    display: flex;
    margin-bottom: 15px;
    font-weight: 600;
}

.offersChart_categorie_container .offersChart_byCategorie_header_container .offersNumber_header{
    border: solid .5px rgba(141, 141, 141, 0.2);
    background-color: rgba(242, 242, 242, 0.45);
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding: 8px 10px;
    margin-right: 5px;
}

.offersChart_categorie_container .offersChart_byCategorie_header_container .offersCategorie_header{
    border: solid .5px rgba(141, 141, 141, 0.2);
    background-color: rgba(242, 242, 242, 0.45);
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding: 8px 15px;
    margin-right: 5px;
}

.offersChart_categorie_container .offersChart_byCategorie_header_container .offersCategoriePercent_header{
    border: solid .5px rgba(141, 141, 141, 0.2);
    background-color: rgba(242, 242, 242, 0.45);
    padding: 8px 15px;
    flex: 1 1;
}

.offersChart_categorie_container .offersChart_byCategorie_body_container{
    width: 100%;
    flex: 1 1;
}


.offersChart_categorie_container .categorie_container{
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    display: flex;
    margin-bottom: 8px;
}

.offersChart_categorie_container .offersNumber_body{
    border: solid .5px rgba(141, 141, 141, 0.2);
    border-right: none;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    width: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px 5px;
    position: relative;
}

.offersChart_categorie_container .offersNumber_body span{
    color:white !important;
}

.offersChart_categorie_container .offersNumber_body::after {
    content: "\27A4";
    position: absolute;
    right: -9px;
    bottom: auto;
    font-weight: 900;
    color:inherit;
}

.offersChart_categorie_container .offersCategorie_body{
    border: solid .5px rgba(141, 141, 141, 0.2);
    background-color: rgba(242, 242, 242, 0.45);
    border-left: none;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    display: flex;
    align-items: center;
    padding: 8px 15px;
    min-width: 145px;
    max-width: 145px;
    margin-right: 15px;
}


.offersChart_categorie_container .MuiTypography-root{
    font-size: inherit !important;
}

.offersChart_categorie_container .offersCategoriePercent_body{
    padding: 8px 5px;
    flex: 1 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.offersChart_categorie_container .offersCategoriePercent_body .MuiLinearProgress-determinate {
    height: 22px !important;
    background-color: rgb(239, 239, 239) !important;
}

.offersChart_categorie_container .offersCategoriePercent_body .MuiLinearProgress-determinate::before {
    background-color: white !important;
}

.offersChart_categorie_container .offersCategoriePercent_body .MuiTypography-root {
    font-family: var(--body-font) !important;
    font-weight: 600 !important;
    color: rgba(0,0,0,.81) !important;
}


@media all and (max-width: 1190px){
    .offersChart_categorie_container{
        font-size: .75rem;
    }

    .offersChart_categorie_container .offersChart_byCategorie_header_container .offersNumber_header{
        padding: 5px 9px;
    }
    
    .offersChart_categorie_container .offersChart_byCategorie_header_container .offersCategorie_header{
        padding: 5px 10px;
    }
    
    .offersChart_categorie_container .offersChart_byCategorie_header_container .offersCategoriePercent_header{
        padding: 5px 10px;
    }

    .offersChart_categorie_container .offersNumber_body{
        width: 40px;
        padding: 5px 5px;
    }
    
    .offersChart_categorie_container .offersCategorie_body{
        padding: 5px 10px;
        padding-left: 15px ;
        min-width: 120px;
        max-width: 120px;
        margin-right: 10px;
    }
    
    .offersChart_categorie_container .offersCategoriePercent_body {
        padding: 5px 5px;
    }
    
}



@media all and (max-width: 857px){
    .offersChart_categorie_container{
        font-size: .7rem;
    }

    .offersChart_categorie_container .offersChart_byCategorie_header_container .offersNumber_header{
        padding: 3px 5px;
    }
    
    .offersChart_categorie_container .offersChart_byCategorie_header_container .offersCategorie_header{
        padding: 3px 6px;
    }
    
    .offersChart_categorie_container .offersChart_byCategorie_header_container .offersCategoriePercent_header{
        padding: 3px 6px;
    }

    .offersChart_categorie_container .offersNumber_body{
        width: 30px;
        padding: 0;
    }
    
    .offersChart_categorie_container .offersCategorie_body{
        padding: 3px 6px;
        padding-left: 10px;
        min-width: 100px;
        max-width: 100px;
        margin-right: 7px;
    }
    
    .offersChart_categorie_container .offersCategoriePercent_body {
        padding: 3px 5px;
    }

    .offersChart_categorie_container .offersNumber_body::after {
        right: -7px;
        bottom: auto;
    }
}
.charts_of_the_bestSuppliers_container{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.charts_of_the_bestSuppliers_container .container{
    margin: auto;
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
}


.charts_of_the_bestSuppliers_container .select_supplierCategories{
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
    margin-top: 10px;
}

.charts_of_the_bestSuppliers_container .select_supplierCategories .MuiSelect-select.MuiSelect-select {
    color: rgb(74, 76, 74) !important;
    font-size: .9rem;
    padding: 3px 0;
}

.charts_of_the_bestSuppliers_container .select_supplierCategories .MuiInput-underline:before {
    border: none !important;
}

.charts_of_the_bestSuppliers_container .select_supplierCategories .MuiSelect-icon {
    color: rgb(74, 76, 74) !important;
}

.charts_of_the_bestSuppliers_container .select_supplierCategories .MuiInputBase-root {
    font-family: var(--body-font) !important;
    border: solid 1px rgb(74, 76, 74) !important;
    padding-left: 6px !important;
    border-radius: 4px !important;
}

.charts_of_the_bestSuppliers_container .select_supplierCategories .MuiSelect-nativeInput {
    left: 0;
    width: 100%;
    bottom: 0;
    opacity: 0;
    position: absolute;
    pointer-events: none;
}


.charts_of_the_bestSuppliers_container .categorie_container{
    width: 85%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    margin: auto;
    margin-bottom: 8px;
    padding-right: 15px;
    position: relative;
    font-size: 1.2rem;
    font-weight: 700;
}


.charts_of_the_bestSuppliers_container .order_of_the_bestSuppliers {
    width: 50px;
    height: 50px;
    min-width: 50px;
    min-height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    border-radius: 100%;
    position:relative;
    right: -12px;
    font-size: inherit;
}


.charts_of_the_bestSuppliers_container .name_of_the_bestSuppliers{
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    flex: 1 1;
    font-size: inherit;
    padding: 5px 15px;
    text-align: center;
    color: rgb(52, 51, 51);
    background:linear-gradient(to right, #e0b663ad, #FCF6BA );
    border-radius: 3px;
    border-left: none;
    width: fit-content;
    min-width: 145px;
}


.charts_of_the_bestSuppliers_container .css-i4bv87-MuiSvgIcon-root {
    font-size: 2.2rem !important;
}


@media all and (max-width: 1200px){

    .charts_of_the_bestSuppliers_container .categorie_container{
        width: 86%;
        margin-bottom: 7px;
        font-size: 1.08rem;
    }

    .charts_of_the_bestSuppliers_container .name_of_the_bestSuppliers{
        padding: 5px 15px;
        min-width: 135px;
    }
    
    
    .charts_of_the_bestSuppliers_container .order_of_the_bestSuppliers {
        width: 46px;
        height: 46px;
        min-width: 46px;
        min-height: 46px;
        right: -12px;
    }

}


@media all and (max-width: 1080px){

    .charts_of_the_bestSuppliers_container .categorie_container{
        margin-bottom: 6px;
        font-size: 1.02rem;
    }

    .charts_of_the_bestSuppliers_container .name_of_the_bestSuppliers{
        padding: 5px 15px;
        min-width: 135px;
    }
    
    
    .charts_of_the_bestSuppliers_container .order_of_the_bestSuppliers {
        width: 43px;
        height: 43px;
        min-width: 43px;
        min-height: 43px;
        right: -11px;
    }

}



@media all and (max-width: 900px){


    .charts_of_the_bestSuppliers_container .categorie_container{
        margin-bottom: 6px;
        font-size: .92rem;
    }

    .charts_of_the_bestSuppliers_container .name_of_the_bestSuppliers{
        padding: 4px 15px;
        min-width: 135px;
    }
    
    
    .charts_of_the_bestSuppliers_container .order_of_the_bestSuppliers {
        width: 39px;
        height: 39px;
        min-width: 39px;
        min-height: 39px;
        right: -11px;
    }

}


@media all and (max-width: 500px){


    .charts_of_the_bestSuppliers_container .categorie_container{
        margin-bottom: 6px;
        font-size: .82rem;
    }

    .charts_of_the_bestSuppliers_container .name_of_the_bestSuppliers{
        padding: 4px 15px;
        min-width: 125px;
    }
    
    
    .charts_of_the_bestSuppliers_container .order_of_the_bestSuppliers {
        width: 33px;
        height: 33px;
        min-width: 33px;
        min-height: 33px;
        right: -11px;
    }

}

.all_news_container{
    width:100%;
    height:100%;
    background-color: rgb(251, 251, 251);    
    padding-top: 6px;
}


.news_container{
    width: 98%;
    height: 120px;
    display: flex;
    margin: auto;
    cursor: pointer;
    position: relative;
    margin-bottom: 6px;
    background-color: white;
    border: solid 0.01rem #dadada;
}


.news_container:hover{
    background-color: rgb(250, 250, 250);
}


.img_news_container{
    width: 30%;
    height: 98%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 0%;
    margin: auto !important;
    margin-left: 2px;
}

.title_description_container{
    width: 70%;
    height: 100%;
    position: relative;
    padding-top:8px;
    padding-left: 8px ;
    padding-right: 3px;
}

.news_title{
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    font-size: .87rem;
    font-weight: 700;
    color: #2a2a2a;
}

.news_description{
    margin-top: 4px;
    font-size: .76rem;
    font-weight: 600;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    color: #2a2a2a;
}

.news_released_time{
    position: absolute;
    bottom: 1px;
    right: 9px;
    font-size: .68rem;
    font-weight: 600;
    color: #535353;
}
.MuiPaper-rounded {
    padding: 0 !important;
    border-radius: 0 !important;
}

.bills_widget_table{
    padding: 0 !important;
    border-radius: 0 !important;
    margin: 0 !important;
    font-size: .5rem !important;
}

.bills_widget_table .MuiTableCell-body:nth-child(1) {
    min-width:125px !important;
}

.bills_widget_table .MuiTableCell-body:nth-child(2) {
    min-width:65px !important;
}
.bills_widget_table .MuiTableCell-body:nth-child(3) {
    min-width:65px !important;
}
.bills_widget_table .MuiTableCell-body:nth-child(4) {
    min-width:65px !important;
}

.bills_widget_table .MuiTableCell-body:nth-child(5) {
    min-width:115px;
}

.bills_widget_table .MuiTableCell-body:nth-child(6) {
    min-width:105px !important;
}
.MuiPaper-rounded {
    padding: 0 !important;
    border-radius: 0 !important;
}

.contracts_widget_table{
    padding: 0 !important;
    border-radius: 0 !important;
    margin: 0 !important;
    font-size: .5rem !important;
}

.contracts_widget_table .MuiTableCell-body:nth-child(1) {
    min-width:125px !important;
}

.contracts_widget_table .MuiTableCell-body:nth-child(2) {
    min-width:65px !important;
}
.contracts_widget_table .MuiTableCell-body:nth-child(3) {
    min-width:65px !important;
}
.contracts_widget_table .MuiTableCell-body:nth-child(4) {
    min-width:65px !important;
}

.contracts_widget_table .MuiTableCell-body:nth-child(5) {
    min-width:115px;
}

.contracts_widget_table .MuiTableCell-body:nth-child(6) {
    min-width:105px !important;
}
.charts_of_the_bestSuppliers_container{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.charts_of_the_bestSuppliers_container .container{
    margin: auto;
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
}


.charts_of_the_bestSuppliers_container .select_supplierCategories{
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
    margin-top: 10px;
}

.charts_of_the_bestSuppliers_container .select_supplierCategories .MuiSelect-select.MuiSelect-select {
    color: rgb(74, 76, 74) !important;
    font-size: .9rem;
    padding: 3px 0;
}

.charts_of_the_bestSuppliers_container .select_supplierCategories .MuiInput-underline:before {
    border: none !important;
}

.charts_of_the_bestSuppliers_container .select_supplierCategories .MuiSelect-icon {
    color: rgb(74, 76, 74) !important;
}

.charts_of_the_bestSuppliers_container .select_supplierCategories .MuiInputBase-root {
    font-family: var(--body-font) !important;
    border: solid 1px rgb(74, 76, 74) !important;
    padding-left: 6px !important;
    border-radius: 4px !important;
}

.charts_of_the_bestSuppliers_container .select_supplierCategories .MuiSelect-nativeInput {
    left: 0;
    width: 100%;
    bottom: 0;
    opacity: 0;
    position: absolute;
    pointer-events: none;
}


.charts_of_the_bestSuppliers_container .categorie_container{
    width: 85%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    margin: auto;
    margin-bottom: 8px;
    padding-right: 15px;
    position: relative;
    font-size: 1.2rem;
    font-weight: 700;
}


.charts_of_the_bestSuppliers_container .order_of_the_bestSuppliers {
    width: 50px;
    height: 50px;
    min-width: 50px;
    min-height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    border-radius: 100%;
    position:relative;
    right: -12px;
    font-size: inherit;
}


.charts_of_the_bestSuppliers_container .name_of_the_bestSuppliers{
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    flex: 1 1;
    font-size: inherit;
    padding: 5px 15px;
    text-align: center;
    color: rgb(52, 51, 51);
    background:linear-gradient(to right, #e0b663ad, #FCF6BA );
    border-radius: 3px;
    border-left: none;
    width: fit-content;
    min-width: 145px;
}


.charts_of_the_bestSuppliers_container .css-i4bv87-MuiSvgIcon-root {
    font-size: 2.2rem !important;
}


@media all and (max-width: 1200px){

    .charts_of_the_bestSuppliers_container .categorie_container{
        width: 86%;
        margin-bottom: 7px;
        font-size: 1.08rem;
    }

    .charts_of_the_bestSuppliers_container .name_of_the_bestSuppliers{
        padding: 5px 15px;
        min-width: 135px;
    }
    
    
    .charts_of_the_bestSuppliers_container .order_of_the_bestSuppliers {
        width: 46px;
        height: 46px;
        min-width: 46px;
        min-height: 46px;
        right: -12px;
    }

}


@media all and (max-width: 1080px){

    .charts_of_the_bestSuppliers_container .categorie_container{
        margin-bottom: 6px;
        font-size: 1.02rem;
    }

    .charts_of_the_bestSuppliers_container .name_of_the_bestSuppliers{
        padding: 5px 15px;
        min-width: 135px;
    }
    
    
    .charts_of_the_bestSuppliers_container .order_of_the_bestSuppliers {
        width: 43px;
        height: 43px;
        min-width: 43px;
        min-height: 43px;
        right: -11px;
    }

}



@media all and (max-width: 900px){


    .charts_of_the_bestSuppliers_container .categorie_container{
        margin-bottom: 6px;
        font-size: .92rem;
    }

    .charts_of_the_bestSuppliers_container .name_of_the_bestSuppliers{
        padding: 4px 15px;
        min-width: 135px;
    }
    
    
    .charts_of_the_bestSuppliers_container .order_of_the_bestSuppliers {
        width: 39px;
        height: 39px;
        min-width: 39px;
        min-height: 39px;
        right: -11px;
    }

}


@media all and (max-width: 500px){


    .charts_of_the_bestSuppliers_container .categorie_container{
        margin-bottom: 6px;
        font-size: .82rem;
    }

    .charts_of_the_bestSuppliers_container .name_of_the_bestSuppliers{
        padding: 4px 15px;
        min-width: 125px;
    }
    
    
    .charts_of_the_bestSuppliers_container .order_of_the_bestSuppliers {
        width: 33px;
        height: 33px;
        min-width: 33px;
        min-height: 33px;
        right: -11px;
    }

}

.applicant_request_view{
    width : 100% !important;
    margin: auto;
}



.applicant_request_view .applicant_request_view_header_container{
    font-size: 1.2rem;
    padding: 0 20px 15px 20px;
    width: 95%;
    margin: auto;
    margin-top: 30px;
    margin-bottom: 20px;
    border-bottom: solid 1px black;
    display: flex;
    justify-content: space-between;
}

.applicant_request_view .applicant_request_view_header_container .MuiGrid-container {
    width: -moz-fit-content !important;
    width: -webkit-fit-content !important;
    width: fit-content !important;
    align-content: center !important;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
}

.applicant_request_view .disable_button{
    opacity: 0.4;
    cursor: default !important;
}

.applicant_request_view .disable_button:hover{
    background-color: #e0e0e0 !important;
    opacity: 0.4;
    cursor: default !important;
}

.applicant_request_view label {
    margin-bottom: 0 !important;
    margin-right: 10px !important;
}

.applicant_request_view .applicant_requests_table{
    width: 96% !important;
    margin: auto !important;
}

.applicant_request_view .MuiTableCell-body:nth-child(1) {
    min-width:100px !important;
}

.applicant_request_view .MuiTableCell-body:nth-child(2) {
    min-width:100px !important;
}


.applicant_request_view .MuiTableCell-body:nth-child(3) {
    min-width:100px !important;
}


.applicant_request_view .MuiTableCell-body:nth-child(4) {
    min-width:100px !important;
}

.applicant_request_view .MuiTableCell-body:nth-child(5) {
    min-width:100px !important;
}

.applicant_request_view .MuiTableCell-body:nth-last-child(2){
    min-width:160px !important;
}

.applicant_request_view .MuiTableCell-body:last-child{
    min-width:80px !important;
}

.applicant_request_view .MuiTableCell-body:nth-child(8) .documentsDownload{
    width: 100%;
}

.set_refuse_raison_container .MuiOutlinedInput-multiline {
    padding: 7.5px 14px !important;
}

/*
///////////////////////////////////////////////////////
/////////////////////////////////////////////////////*/




.set_refuse_raison_container .MuiOutlinedInput-multiline {
    padding: 7.5px 14px !important;
}



/*
///////////////////////////////////////////////////////
/////////////////////////////////////////////////////*/





.off_catalogue_requestDetails_container{
    width: 100vw;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    overflow: auto;
 }


.request_order_toolbar_main_container{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center !important;
    align-content: center !important;
    flex-wrap: wrap;
}


.off_catalogue_requestDetails_container .view_reason_button{
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin: auto;
    text-decoration: underline;
    margin-top: 2px;
    color: #38a1a5 !important;
    cursor: pointer !important;
    font-size: .95rem;
}

.off_catalogue_requestDetails_container .request_state_container{
    display: flex;
    flex-direction: column;
    align-items: center !important;
    justify-content:center !important;
    align-content: center !important;
}

 .off_catalogue_requestDetails_container .add_icon_container{
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    align-self: flex-end;
    color:#3b5a7c !important;
    cursor: pointer !important;
    margin-right: 8px;
    border-radius: 100%;
 }

 .off_catalogue_requestDetails_container .add_icon_container .MuiSvgIcon-root {
    width: 40px;
    height: 40px;
    min-width: 28px !important;
    min-height: 28px !important;
}

.off_catalogue_requestDetails_container .add_icon_container:hover{
    background:#efefef !important;
}

.off_catalogue_requestDetails_container .remove_icon_container{
    align-self: flex-end;
    color:#353535 !important;
    cursor: pointer !important;
    border-radius: 100%;
    display: flex;
    justify-content: center;
 }

 .off_catalogue_requestDetails_container .remove_icon_container:hover{
    background:#efefef !important;
 }

 .off_catalogue_requestDetails_container .remove_icon_container .MuiSvgIcon-root {
    width: 30px !important;
    height: 30px !important;
}

.off_catalogue_requestDetails_container .accordion-body {
    padding: 0;
    margin: 0;
    color: rgb(64, 64, 65);
    background-color:#f9f9f9 !important;
}


.off_catalogue_requestDetails_container div:nth-child(4) .accordion .accordion-button::before{
    background: rgb(245 128 115);
}

.off_catalogue_requestDetails_container div:nth-child(2) .accordion .accordion-button::before{
    background: rgb(245 128 115);
}


.off_catalogue_requestDetails_container .supplier_accordion .accordion-button::after {
    display: none;
}

.off_catalogue_requestDetails_container .supplier_accordion .accordion_header_content span:first-child::before{
    display: none;
}

.order_form_details_container{
    width: 80%;
    margin: 0 auto;
}

.order_form_details_container .order_form_infos_container{
    width: 100%;
    padding: 1em 2em;
    margin: 0;
}

.order_form_infos_container .row {
    margin: 5px 0 10px 0;
}


.order_form_infos_container .order_form_infos_title{
    padding: 0 15px;
    font-size: 1.1rem;
    font-weight: 600;
    color: #526f87;
    margin: 18px 0 7px 0;
}

.order_form_infos_container .row label {
    margin-bottom: 0.25rem !important;
}

.order_form_infos_container .form-control {
    border: 1px solid #c4c3c3;
    border-radius: 4px;
    color: #2c2c2c;
    height: auto;
    font-size: .9rem;
    box-shadow: none;
    margin-bottom: 12px;
    height: 39px;
    background-image: none !important;
}

.order_form_infos_container textarea {
    height: auto !important;
    padding: 8px 11px !important;
    box-shadow: none !important;
    border: 1px solid #c4c3c3 !important;
    color: #2c2c2c !important;
}

.order_form_page_buttons_container{
    width: 60%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    display: flex;
    justify-content: space-between;
    margin: 15px auto;
}


.off_catalogue_requestDetails_container .articles_container{
    width: 100%;
    margin-top: 15px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    font-size: .9rem;
}

.articles_container .articles_view_container{
    width: 49.5%;
    border: solid 1px rgb(215, 212, 212);
    background-color: #fdfdfd;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    padding: 13px 13px;
    margin-bottom: 10px;
}

.articles_container .article_view_header_container{
    font-size: 1.08rem;
    font-weight: 600;
    color: #526f87;
    display: flex;
    justify-content: space-between;
}


.request_order_toolbar_right .cancel_but{
    background:#e92a44 !important;
}


.request_order_toolbar_right .valide_but{
    background:#18ac67 !important;
}

.request_order_toolbar_right .cancel_but:hover{
    background:#e92a44 !important;
    opacity: .9 !important;
}

.request_order_toolbar_right .valide_but:hover{
    background:#18ac67 !important;
    opacity: .9 !important;
}


@media all and (max-width: 1080px){

    .articles_container .articles_view_container {
        padding: 11px 11px;
        margin-bottom: 8px;
        font-size: .87rem;
    }

    .articles_container .article_view_header_container{
        font-size: .98rem;
    }

}


@media all and (max-width: 620px){

    .articles_container .articles_view_container {
        padding: 11px 9px;
        margin-bottom: 8px;
        font-size: .78rem;
    }

    .articles_container .article_view_header_container{
        font-size: .9rem;
    }

}

@media all and (max-width: 490px){

    .off_catalogue_requestDetails_container .articles_container{
        justify-content: center;
    }

    .articles_container .articles_view_container {
        width: 90%;
        padding: 7px 5px;
        margin-bottom: 6px;
        font-size: .75rem;
    }

    .articles_container .article_view_header_container{
        font-size: .87rem;
    }
}
.dialogSuppliersBottom .submit_but{
    all: unset;
    background:#343a40;
    color: white;
    padding: .45rem 1.2rem;
    padding-top: .48rem;
    margin: 4px 5px;
    font-size: 1rem;
    border-radius: 4px;
    transition: -webkit-filter 300ms;
    transition: filter 300ms;
    transition: filter 300ms, -webkit-filter 300ms;
    cursor: pointer;
    align-items: center;
    text-align: center;
    box-shadow: none;
}

.dialogSuppliersBottom .cancel_but{
    background:#e92a44;
}

.dialogSuppliersBottom .cancel_but:hover{
    background:#e92a44;
    opacity: 0.9;
}

.dialogSuppliersBottom .valide_but{
    background:#18ac67;
}

.dialogSuppliersBottom .valide_but:hover{
    background:#18ac67;
    opacity: 0.9;
}




.set_refuse_raison_container .MuiOutlinedInput-multiline {
    padding: 7.5px 14px !important;
}



/*
///////////////////////////////////////////////////////
/////////////////////////////////////////////////////*/



.set_search_prd_container .MuiDialog-paperFullWidth {
    width: calc(100% - 64px) !important;
    height: 500px !important;
    margin: 5px auto !important;
}

.set_search_prd_container .form-control {
    border: 1px solid #c4c3c3;
    border-radius: 6px;
}

.set_search_prd_container label{
    width: 100%;
    text-align: left;
    margin: 0;
    font-size: .9rem;
    padding-left: 2px;
}

.set_search_prd_container .search_content_container{
    flex:1 1;
    width: 100%;
    margin: 15px auto;
    display:flex;
    justify-content: center;
    align-items: center;
}

.set_search_prd_container .emptytable_msg_container{
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    display: flex;
    justify-content: center;
    flex-direction: column;
    height: 100%;
    font-size: .95rem;
    padding-bottom: 70px;
}


.set_search_prd_container .submit_but{
    background:rgb(67, 113, 133);
    color: white;
}

.set_search_prd_container .submit_but:hover{
    background:rgb(67, 113, 133);
    opacity: .9;
}

.catalogue_modal{
    width: 100%;
    height: 100%;
    margin: 30px 0 ;
}


.products_gallery_modal_container{
    width: 100% !important;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: auto !important;
}


.set_search_prd_container{
    flex: 1 1;
    width: 98%;
    margin: 15px auto;
    display:flex;
    flex-direction: column;
}

.set_search_prd_container .products_contents_container{
    width: 100%;
    display:flex;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
}

.set_search_prd_container .main_card_container:hover > .main_card_infos_container > .view_more_button_container{
    opacity: 1;
    transition: all 190ms ease-in-out 0s;
}

.set_search_prd_container .view_more_button_container button{
    all: unset;
    font-size: 1rem;
    padding: .4rem .6rem;
    border: 1px solid rgb(255, 255, 255);
    color: white;
    border-radius: 5px;
    cursor: pointer;
    position: relative;
}


.set_search_prd_container .view_more_button_container button:hover{
    border: 1px solid rgb(230, 226, 226);
    background-color: rgba(0, 0, 0, .7);
}


@media (max-width: 680px){
    .set_search_prd_container .MuiDialog-paperFullWidth {
        width: 97% !important;
        margin: 5px auto !important;
    }
}
.add_product_byservice_container .cancel_but{
    background:#e92a44 !important;
    margin-right: 10px;
}


.add_product_byservice_container .valide_but{
    background:#18ac67 !important;
}

.add_product_byservice_container .cancel_but:hover{
    opacity: .9 !important;
}

.add_product_byservice_container .valide_but:hover{
    opacity: .9 !important;
}


.add_product_byservice_container textarea{
    max-width: 100% !important;
    max-height: none !important;
    padding: 5px 10px 7px 5px !important;
    resize: auto !important;
    font-size: 1rem;
    border: solid 0.1rem #b1b0b0 !important;
    border-radius: 0;
    line-height: 1 !important;
}

.add_product_byservice_container .MuiDialog-paperFullWidth {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding: 10px !important;
}

.add_product_byservice_container .plus_details_dialog_container{
    padding: 0 !important;
}


.add_product_byservice_container .header_form:last-child{
    margin-right: 0 !important;
}

.add_purchase_request_mainPage{
    width: 670px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    margin: auto;
    margin-top: 50px;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    flex-wrap: wrap;
}

.purchase_request_type_container{
    flex:1 1 225px;
    max-width: 305px !important;
    height: 95px !important;
    box-shadow: 0px 0px 3px 1px #e0e0e0 !important;
    margin: 25px 10px;
    margin-top: 0;
    border-radius: 5px;
    display:flex;
    background-color: white;
    cursor: pointer;
    transition: all 150ms ease-in-out 0s;
    -webkit-transform: scale(1);
            transform: scale(1);
}

.purchase_request_type_container:hover{
    transition: all 100ms ease-in-out 0s;
    background-color: rgb(253, 253, 253);
    -webkit-transform: scale(1.02);
            transform: scale(1.02);
}

.add_purchase_request_mainPage .request_type_icon{
    width: 25%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    padding-left: 5px;
}

.add_purchase_request_mainPage .request_type_icon .MuiSvgIcon-root {
    width: 38px !important;
    height: 38px !important;
    color: rgb(80, 79, 79) !important;
}

.add_purchase_request_mainPage .request_type{
    width: 78%;
    height: 100%;
    font-weight: 600;
    padding: 15px 4px;
    padding-right: 5px;
    font-size: 1.17rem;
    color: rgb(80, 79, 79) !important;
    display: flex;
    align-items: center;
    align-content: center;
}

.products_gallery_container{
    width: 100% !important;
    height: 100%;
    display: flex;
    flex-direction: column;
}



.products_gallery_container .search_filter_container{
    display: flex;
    width: 100%;
    justify-content: flex-end;
}



.products_gallery_contents_container .view_more_button_container button{
    border: 1px solid rgb(255, 255, 255);
    background-color: rgba(0, 0, 0, .4);
    color: white;
    position: relative;
    text-transform: none !important;
}


.products_gallery_contents_container .view_more_button_container button:hover{
    border: 1px solid rgb(230, 226, 226);
    background-color: rgba(0, 0, 0, .7);
}


@media (max-width: 845px){
    .products_gallery_container .search_filter_container {
        display: flex;
        width: 100%;
        justify-content: center;
    }
}



.products_gallery_contents_container .card_title {
    margin-top: 7px;
}

.products_gallery_contents_container .card_description {
    margin-top: 10px;
}


.emptytable_msg_container{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    font-weight: 600;
}

.order_off_catalogue_form_page_container{
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    display: flex;
    justify-content: space-between;
    padding: 20px 15px;
    position: relative;
    align-items: flex-start !important;
    align-content: flex-start !important;
    flex-wrap: wrap;
}


.order_off_catalogue_form_page_container .off_catalogue_form_articles_container{
    width: 44%;
    display: flex;
    flex-direction: column;
}

.order_off_catalogue_form_page_container .off_catalogue_form_container{
    width: 55%;
    display: flex;
    flex-direction: column;
}



.order_off_catalogue_form_container{
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    margin: auto;
    border: solid 1px rgb(215, 212, 212);
    background-color: #fdfdfd;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    padding: 5px;
    padding-top: 13px;
}


.order_off_catalogue_form_infos_container{
    width: 100% !important;
    margin: 0 !important;
    padding: 0 !important;
}


.order_off_catalogue_form_infos_container .row {
    margin: 5px 0 10px 0;
}


.order_off_catalogue_form_infos_container .order_off_catalogue_form_infos_title{
    padding: 0 15px;
    font-size: 1.1rem;
    font-weight: 600;
    color: #526f87;
    margin: 18px 0 7px 0;
}

.order_off_catalogue_form_infos_container .row label {
    margin-bottom: 0.25rem !important;
}

.order_off_catalogue_form_infos_container .form-control {
    border: 1px solid #c4c3c3;
    border-radius: 4px;
    color: #2c2c2c;
    height: auto;
    font-size: .9rem;
    box-shadow: none;
    margin-bottom: 12px;
    height: 39px;
    background-image: none !important;
}

.order_off_catalogue_form_page_container .order_off_catalogue_form_infos_container textarea {
    height: auto !important;
    padding: 8px 11px !important;
    box-shadow: none !important;
    border: 1px solid #c4c3c3 !important;
    color: #2c2c2c !important;
}

.order_off_catalogue_form_page_buttons_container{
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    display: flex;
    justify-content: flex-end;
    margin: 30px auto;
}



.order_off_catalogue_form_page_container .articles_view_container{
    width: 100% !important;
    height: -webkit-fit-content !important;
    height: -moz-fit-content !important;
    height: fit-content !important;
    border: solid 1px rgb(215, 212, 212);
    background-color: #fdfdfd;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    padding: 12px 15px;
    margin-bottom: 10px;
}

.order_off_catalogue_form_page_container .articles_view_header_container{
    font-size: 1.15rem;
    font-weight: 700;
    color: rgb(228, 88, 32);
    display: flex;
    justify-content: space-between;
}

.articles_view_header_container .add_icon_container{
    border-radius: 100%;
    cursor: pointer;
    width: 25px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.articles_view_header_container .add_icon_container:hover{
    background-color: rgba(188, 188, 188, 0.2);
}

.order_off_catalogue_form_page_container .article_view_header_container{
    font-weight: 600;
    color: #526f87;
    display: flex;
    justify-content: space-between;
}

.article_view_header_container .add_icon_container{
    border-radius: 100%;
    cursor: pointer;
    width: 25px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.article_view_header_container .add_icon_container:hover{
    background-color: rgba(188, 188, 188, 0.2);
}

.order_off_catalogue_form_page_buttons_container .submit_but{
    background:rgb(228, 88, 32);
    color: white;
}

.order_off_catalogue_form_page_buttons_container .submit_but:hover{
    background: rgb(228, 88, 32);
    opacity: .9;
}


.order_off_catalogue_form_page_buttons_container .return_button{
    background:rgb(228, 32, 74);
}

.order_off_catalogue_form_page_buttons_container .return_button:hover{
    background:rgb(228, 32, 74);
    opacity: .9;
}


@media all and (max-width: 900px){

    .order_off_catalogue_form_page_container .off_catalogue_form_articles_container{
        width: 85%;
        margin: auto;
    }

    .order_off_catalogue_form_page_container .off_catalogue_form_container{
        width: 85%;
        margin: auto;
    }

    .order_off_catalogue_form_page_container .order_form_title::after{
        height: 2.5px;
        width: 38%;
    }

    .order_off_catalogue_form_page_container .articles_view_container{
        padding: 9px 15px;
    }
}


@media all and (max-width: 750px){
    .order_off_catalogue_form_page_container .order_form_title::after{
        height: 2px;
        width: 32%;
    }
}

@media all and (max-width: 580px){

    .order_off_catalogue_form_page_container{
        padding: 9px;
    }

    .order_off_catalogue_form_page_container .articles_view_container{
        padding: 9px 12px;
    }

    .order_off_catalogue_form_page_container .off_catalogue_form_articles_container{
        width: 95%;
        margin: auto;
    }

    .order_off_catalogue_form_page_container .off_catalogue_form_container{
        width: 95%;
        margin: auto;
    }

    .order_off_catalogue_form_page_container .articles_view_header_container{
        font-size: 1.05rem;
        font-weight: 700;
        color: rgb(228, 88, 32);
        display: flex;
        justify-content: space-between;
    }

    .articles_view_header_container .add_icon_container{
        width: 23px;
        height: 23px;
    }
}


@media all and (max-width: 379px){

    .order_off_catalogue_form_page_container{
        padding: 5px;
    }

    .order_off_catalogue_form_page_container .off_catalogue_form_articles_container{
        width: 99%;
        margin: auto;
    }

    .order_off_catalogue_form_page_container .off_catalogue_form_container{
        width: 99%;
        margin: auto;
    }

    .order_off_catalogue_form_page_container .order_form_title::after{
        left: 7px;
    }

    .order_off_catalogue_form_page_container .articles_view_container{
        padding: 9px 6px;
    }

    .articles_view_header_container .add_icon_container{
        width: 20px;
        height: 20px;
    }

}

.set_refuse_raison_container .MuiOutlinedInput-multiline {
    padding: 7.5px 14px !important;
}
/*
///////////////////////////////////////////////////////
/////////////////////////////////////////////////////*/




.order_form_page_container{
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    display: flex;
    flex-direction: column;
    padding: 20px;
}

.order_form_container{
    width: 60%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    margin: auto;
    border: solid 1px rgb(215, 212, 212);
    background-color: #fdfdfd;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    padding: 5px;
    padding-top: 10px;
}

.order_form_title{
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    font-size: 1.4rem;
    font-weight: 700;
    color: rgb(228, 88, 32);
    position: relative;
    padding: 7px 15px;
    padding-bottom: 3px;
}

.order_form_title::after{
    content: '';
    height: 3px;
    width: 45%;
    background-color: rgb(228, 88, 32);
    position: absolute;
    bottom: -2px;
    left: 16px;
}

.order_form_infos_container{
    width: 100%;
    margin-top: 14px;
}

.order_form_infos_container .row {
    margin: 5px 0 10px 0;
}


.order_form_infos_container .order_form_infos_title{
    padding: 0 15px;
    font-size: 1.1rem;
    font-weight: 600;
    color: #526f87;
    margin: 18px 0 7px 0;
}

.order_form_infos_container .row label {
    margin-bottom: 0.25rem !important;
}

.order_form_infos_container .form-control {
    border: 1px solid #c4c3c3;
    border-radius: 4px;
    color: #2c2c2c;
    height: auto;
    font-size: .9rem;
    box-shadow: none;
    margin-bottom: 12px;
    height: 39px;
    background-image: none !important;
}



.order_form_page_container .order_form_infos_container textarea {
    height: auto !important;
    padding: 8px 11px !important;
    box-shadow: none !important;
    border: 1px solid #c4c3c3 !important;
    color: #2c2c2c !important;
}

.order_form_page_buttons_container{
    width: 60%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    display: flex;
    justify-content: space-between;
    margin: 15px auto;
}


.order_form_page_buttons_container .submit_but{
    all: unset;
    background:rgb(228, 88, 32);
    color: white;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    padding: .5rem 1.5rem;
    font-size: .9rem;
    border-radius: 5px;
    transition: -webkit-filter 300ms;
    transition: filter 300ms;
    transition: filter 300ms, -webkit-filter 300ms;
    cursor: pointer;
}

.order_form_page_buttons_container .submit_but:hover{
    background: rgb(228, 88, 32);
    opacity: .9;
}


.order_form_page_buttons_container .return_button{
    background:rgb(228, 32, 74);
}

.order_form_page_buttons_container .return_button:hover{
    background:rgb(228, 32, 74);
    opacity: .9;
}


@media all and (max-width: 1170px){
    .order_form_container{
        width: 70%;
    }

    .order_form_page_container .order_form_page_buttons_container{
        width: 70%;
    }

    .order_form_title{
        font-size: 1.32rem;
    }
    
}

@media all and (max-width: 900px){
    .order_form_container{
        width: 80%;
    }

    .order_form_page_container .order_form_page_buttons_container{
        width: 80%;
    }

    .order_form_page_container .order_form_title{
        font-size: 1.25rem;
    }

    .order_form_page_container .order_form_title::after{
        height: 2.5px;
        width: 38%;
    }

}

@media all and (max-width: 750px){
    .order_form_container{
        width: 90%;
    }

    .order_form_page_container .order_form_page_buttons_container{
        width: 90%;
    }

    .order_form_page_container .order_form_title{
        font-size: 1.2rem;
    }

    .order_form_page_container .order_form_title::after{
        height: 2px;
        width: 32%;
    }
}


@media all and (max-width: 550px){
    .order_form_container{
        width: 94%;
    }

    .order_form_page_container .order_form_page_buttons_container{
        width: 94%;
    }

    .order_form_page_container{
        padding: 10px;
    }

    .order_form_title{
        font-size: 1.15rem;
    }

}

@media all and (max-width: 379px){
    .order_form_container{
        width: 99%;
    }

    .order_form_page_container .order_form_page_buttons_container{
        width: 99%;
    }

    .order_form_page_container{
        padding: 5px;
    }

    .order_form_title{
        font-size: 1.05rem;
        padding: 5px 6px;
        padding-bottom: 1px;
    }
    
    .order_form_page_container .order_form_title::after{
        left: 7px;
    }

}
.MuiPaper-rounded {
    padding: 0 !important;
    border-radius: 0 !important;
}

.supplier_offers_tabel{
    padding: 0 !important;
    border-radius: 0 !important;
    margin: 0 !important;
    font-size: .5rem !important;
    height: -webkit-fit-content !important;
    height: -moz-fit-content !important;
    height: fit-content !important;
}


.supplier_offers_tabel .MuiTableCell-body:nth-child(1) {
    min-width:125px !important;
}

.supplier_offers_tabel .MuiTableCell-body:nth-child(2) {
    min-width:65px !important;
}
.supplier_offers_tabel .MuiTableCell-body:nth-child(3) {
    min-width:65px !important;
}
.supplier_offers_tabel .MuiTableCell-body:nth-child(4) {
    min-width:65px !important;
}

.supplier_offers_tabel .MuiTableCell-body:nth-child(5) {
    min-width:105px !important;
}

.supplier_offers_tabel .MuiTableCell-body:nth-child(6) {
    min-width:145px !important;
}
.widget_count_elems_container{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    font-size: 3.8rem;
    cursor: pointer;
    padding: 25px 0;
}


.widget_count_elems_container .number_arrow_container{
    position: relative;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
}

.widget_count_elems_container .arrow_container{
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    position: absolute;
    top: -5px;
    right: -45px;
    display: flex;
    color: green;
}

.widget_count_elems_container .arrow_container .progress_icon{
    width: 2em;
    height: 2em;
}
.MuiPaper-rounded {
    padding: 0 !important;
    border-radius: 0 !important;
}

.submissions_table{
    padding: 0 !important;
    border-radius: 0 !important;
    margin: 0 !important;
    font-size: .5rem !important;
    height: -webkit-fit-content !important;
    height: -moz-fit-content !important;
    height: fit-content !important;
}

.submissions_table .MuiTableCell-body:nth-child(1) {
    min-width:125px !important;
}

.submissions_table .MuiTableCell-body:nth-child(2) {
    min-width:65px !important;
}
.submissions_table .MuiTableCell-body:nth-child(3) {
    min-width:65px !important;
}
.submissions_table .MuiTableCell-body:nth-child(4) {
    min-width:65px !important;
}

.submissions_table .MuiTableCell-body:nth-child(5) {
    min-width:135px !important;
}

.submissions_table .MuiTableCell-body:nth-child(6) {
    min-width:105px !important;
}
.radialCharts_offersState_container{
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: space-around;
    cursor: pointer;
    position: relative;
}

.radialCharts_offersState_container .radialCharts_container{
    width: 25%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    position: relative;
}


.radialCharts_offersState_container .apexcharts-text {
    font-family: var(--body-font) !important;
    font-size: 1.05rem !important;
}


@media all and (max-width: 1200px){
    .radialCharts_offersState_container .apexcharts-text {
        font-size: .97rem !important;
    }
}

@media all and (max-width: 879px){
    .radialCharts_offersState_container .apexcharts-text {
        font-size: .9rem !important;
    }
}

@media all and (max-width: 559px){
    .radialCharts_offersState_container .apexcharts-text {
        font-size: .85rem !important;
    }
}


@media all and (max-width: 450px){
    .radialCharts_offersState_container .apexcharts-text {
        font-size: .75rem !important;
    }
}

.charts_ofBillsAmount_payed_unpayed{
    width: 96%;
    height: 100%;
    margin: auto;
    padding: 0;
    padding-top: 10px;
}

.charts_ofBillsAmount_payed_unpayed .pexcharts-legend-text{
    font-size: .9rem !important;
    font-weight: 500 !important;
    font-style: var(--body-font) !important;
}
.MuiPaper-rounded {
    padding: 0 !important;
    border-radius: 0 !important;
}


.charts_of_contract_container{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.charts_of_contract_container .contract_state{
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    display: flex;
    justify-content: flex-end;
    padding-right: 20px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.charts_of_contract_container .contract_state .MuiSelect-select.MuiSelect-select {
    color: rgb(74, 76, 74) !important;
    font-size: .9rem;
    padding: 3px 0;
}

.charts_of_contract_container .contract_state .MuiInput-underline:before {
    border: none !important;
}

.charts_of_contract_container .contract_state .MuiSelect-icon {
    color: rgb(74, 76, 74) !important;
}

.charts_of_contract_container .contract_state .MuiInputBase-root {
    font-family: var(--body-font) !important;
    border: solid 1px rgb(74, 76, 74) !important;
    padding-left: 6px !important;
    border-radius: 4px !important;
}

.charts_of_contract_container .contract_state .MuiSelect-nativeInput {
    left: 0;
    width: 100%;
    bottom: 0;
    opacity: 0;
    position: absolute;
    pointer-events: none;
}


.charts_of_contract_container .container{
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    flex:1 1;
    padding: 0 !important;
}


.contracts_table_container{
    padding: 0 !important;
    border-radius: 0 !important;
    margin: 0 !important;
    font-size: .5rem !important;
}

.contracts_table_container .MuiTableCell-body:nth-child(1) {
    min-width:125px !important;
}

.contracts_table_container .MuiTableCell-body:nth-child(2) {
    min-width:65px !important;
}
.contracts_table_container .MuiTableCell-body:nth-child(3) {
    min-width:65px !important;
}
.contracts_table_container .MuiTableCell-body:nth-child(4) {
    min-width:65px !important;
}

.contracts_table_container .MuiTableCell-body:nth-child(5) {
    min-width:105px !important;
}

.contracts_table_container .MuiTableCell-body:nth-child(6) {
    min-width:145px !important;
}
.radialCharts_offersState_container{
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: space-around;
    cursor: pointer;
    position: relative;
}

.radialCharts_offersState_container .radialCharts_container{
    width: 33%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    position: relative;
}


.radialCharts_offersState_container .apexcharts-text {
    font-family: var(--body-font) !important;
    font-size: 1.05rem !important;
}


@media all and (max-width: 1200px){
    .radialCharts_offersState_container .apexcharts-text {
        font-size: .97rem !important;
    }
}

@media all and (max-width: 879px){
    .radialCharts_offersState_container .apexcharts-text {
        font-size: .9rem !important;
    }
}

@media all and (max-width: 559px){
    .radialCharts_offersState_container .apexcharts-text {
        font-size: .85rem !important;
    }
}


@media all and (max-width: 450px){
    .radialCharts_offersState_container .apexcharts-text {
        font-size: .75rem !important;
    }
}

.MuiPaper-rounded {
    padding: 0 !important;
    border-radius: 0 !important;
}


.charts_of_bills_count_byState_container{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.charts_of_bills_count_byState_container .MuiTable-root {
    min-width: 750px !important;
}


.charts_of_bills_count_byState_container .bills_count_byState_state{
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    display: flex;
    justify-content: flex-end;
    padding-right: 15px;
    padding-left: 15px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.charts_of_bills_count_byState_container .bills_count_byState_state .MuiSelect-select.MuiSelect-select {
    color: rgb(74, 76, 74) !important;
    font-size: .9rem;
    padding: 3px 0;
}

.charts_of_bills_count_byState_container .bills_count_byState_state .MuiInput-underline:before {
    border: none !important;
}

.charts_of_bills_count_byState_container .bills_count_byState_state .MuiSelect-icon {
    color: rgb(74, 76, 74) !important;
}

.charts_of_bills_count_byState_container .bills_count_byState_state .MuiInputBase-root {
    font-family: var(--body-font) !important;
    border: solid 1px rgb(74, 76, 74) !important;
    padding-left: 6px !important;
    border-radius: 4px !important;
}

.charts_of_bills_count_byState_container .bills_count_byState_state .MuiSelect-nativeInput {
    left: 0;
    width: 100%;
    bottom: 0;
    opacity: 0;
    position: absolute;
    pointer-events: none;
}


.charts_of_bills_count_byState_container .container{
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    flex:1 1;
    padding: 0 !important;
}


.bills_widget_table_container{
    padding: 0 !important;
    border-radius: 0 !important;
    margin: 0 !important;
    font-size: .5rem !important;
}


.bills_widget_table_container .MuiTableCell-body:nth-child(1) {
    min-width:125px !important;
}

.bills_widget_table_container .MuiTableCell-body:nth-child(2) {
    min-width:65px !important;
}

.bills_widget_table_container .bill_lign{
    margin-bottom: 4px;
    width: 100%;
}

.offersChart_categorie_container{
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    padding: 10px;
    display: flex;
    flex-direction: column;
    cursor: default;
    font-size: .8rem;
}

.offersChart_byCategorie_header_container{
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    display: flex;
    margin-bottom: 15px;
    font-weight: 600;
}

.offersChart_categorie_container .offersChart_byCategorie_header_container .offersNumber_header{
    border: solid .5px rgba(141, 141, 141, 0.2);
    background-color: rgba(242, 242, 242, 0.45);
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding: 8px 10px;
    margin-right: 5px;
}

.offersChart_categorie_container .offersChart_byCategorie_header_container .offersCategorie_header{
    border: solid .5px rgba(141, 141, 141, 0.2);
    background-color: rgba(242, 242, 242, 0.45);
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding: 8px 15px;
    margin-right: 5px;
}

.offersChart_categorie_container .offersChart_byCategorie_header_container .offersCategoriePercent_header{
    border: solid .5px rgba(141, 141, 141, 0.2);
    background-color: rgba(242, 242, 242, 0.45);
    padding: 8px 15px;
    flex: 1 1;
}

.offersChart_categorie_container .offersChart_byCategorie_body_container{
    width: 100%;
    flex: 1 1;
}


.offersChart_categorie_container .categorie_container{
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    display: flex;
    margin-bottom: 8px;
}

.offersChart_categorie_container .offersNumber_body{
    border: solid .5px rgba(141, 141, 141, 0.2);
    border-right: none;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    width: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px 5px;
    position: relative;
}

.offersChart_categorie_container .offersNumber_body span{
    color:white !important;
}

.offersChart_categorie_container .offersNumber_body::after {
    content: "\27A4";
    position: absolute;
    right: -9px;
    bottom: auto;
    font-weight: 900;
    color:inherit;
}

.offersChart_categorie_container .offersCategorie_body{
    border: solid .5px rgba(141, 141, 141, 0.2);
    background-color: rgba(242, 242, 242, 0.45);
    border-left: none;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    display: flex;
    align-items: center;
    padding: 8px 15px;
    min-width: 145px;
    max-width: 145px;
    margin-right: 15px;
}


.offersChart_categorie_container .MuiTypography-root{
    font-size: inherit !important;
}

.offersChart_categorie_container .offersCategoriePercent_body{
    padding: 8px 5px;
    flex: 1 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.offersChart_categorie_container .offersCategoriePercent_body .MuiLinearProgress-determinate {
    height: 22px !important;
    background-color: rgb(239, 239, 239) !important;
}

.offersChart_categorie_container .offersCategoriePercent_body .MuiLinearProgress-determinate::before {
    background-color: white !important;
}

.offersChart_categorie_container .offersCategoriePercent_body .MuiTypography-root {
    font-family: var(--body-font) !important;
    font-weight: 600 !important;
    color: rgba(0,0,0,.81) !important;
}


@media all and (max-width: 1190px){
    .offersChart_categorie_container{
        font-size: .75rem;
    }

    .offersChart_categorie_container .offersChart_byCategorie_header_container .offersNumber_header{
        padding: 5px 9px;
    }
    
    .offersChart_categorie_container .offersChart_byCategorie_header_container .offersCategorie_header{
        padding: 5px 10px;
    }
    
    .offersChart_categorie_container .offersChart_byCategorie_header_container .offersCategoriePercent_header{
        padding: 5px 10px;
    }

    .offersChart_categorie_container .offersNumber_body{
        width: 40px;
        padding: 5px 5px;
    }
    
    .offersChart_categorie_container .offersCategorie_body{
        padding: 5px 10px;
        padding-left: 15px ;
        min-width: 120px;
        max-width: 120px;
        margin-right: 10px;
    }
    
    .offersChart_categorie_container .offersCategoriePercent_body {
        padding: 5px 5px;
    }
    
}



@media all and (max-width: 857px){
    .offersChart_categorie_container{
        font-size: .7rem;
    }

    .offersChart_categorie_container .offersChart_byCategorie_header_container .offersNumber_header{
        padding: 3px 5px;
    }
    
    .offersChart_categorie_container .offersChart_byCategorie_header_container .offersCategorie_header{
        padding: 3px 6px;
    }
    
    .offersChart_categorie_container .offersChart_byCategorie_header_container .offersCategoriePercent_header{
        padding: 3px 6px;
    }

    .offersChart_categorie_container .offersNumber_body{
        width: 30px;
        padding: 0;
    }
    
    .offersChart_categorie_container .offersCategorie_body{
        padding: 3px 6px;
        padding-left: 10px;
        min-width: 100px;
        max-width: 100px;
        margin-right: 7px;
    }
    
    .offersChart_categorie_container .offersCategoriePercent_body {
        padding: 3px 5px;
    }

    .offersChart_categorie_container .offersNumber_body::after {
        right: -7px;
        bottom: auto;
    }
}
.MuiPaper-rounded {
    padding: 0 !important;
    border-radius: 0 !important;
}

.offers_totalAmount_widget{
    padding: 0 !important;
    border-radius: 0 !important;
    margin: 0 !important;
    font-size: .5rem !important;
    height: -webkit-fit-content !important;
    height: -moz-fit-content !important;
    height: fit-content !important;
}


.offers_totalAmount_widget .MuiTableCell-body:nth-child(1) {
    min-width:125px !important;
}

.offers_totalAmount_widget .MuiTableCell-body:nth-child(2) {
    min-width:65px !important;
}
.offers_totalAmount_widget .MuiTableCell-body:nth-child(3) {
    min-width:65px !important;
}
.offers_totalAmount_widget .MuiTableCell-body:nth-child(4) {
    min-width:65px !important;
}

.offers_totalAmount_widget .MuiTableCell-body:nth-child(5) {
    min-width:115px;
}

.offers_totalAmount_widget .MuiTableCell-body:nth-child(6) {
    min-width:105px !important;
}
.MuiPaper-rounded {
    padding: 0 !important;
    border-radius: 0 !important;
}

.offers_totalAmount_widget{
    height: -webkit-fit-content !important;
    height: -moz-fit-content !important;
    height: fit-content !important;
    padding: 0 !important;
    border-radius: 0 !important;
    margin: 0 !important;
    font-size: .5rem !important;
}


.offers_totalAmount_widget .MuiTableCell-body:nth-child(1) {
    min-width:125px !important;
}

.offers_totalAmount_widget .MuiTableCell-body:nth-child(2) {
    min-width:65px !important;
}
.offers_totalAmount_widget .MuiTableCell-body:nth-child(3) {
    min-width:65px !important;
}
.offers_totalAmount_widget .MuiTableCell-body:nth-child(4) {
    min-width:65px !important;
}

.offers_totalAmount_widget .MuiTableCell-body:nth-child(5) {
    min-width:115px;
}

.offers_totalAmount_widget .MuiTableCell-body:nth-child(6) {
    min-width:105px !important;
}
.all_news_container{
    width:100%;
    height:-webkit-fit-content;
    height:-moz-fit-content;
    height:fit-content;
    background-color: rgb(251, 251, 251);    
    padding-top: 6px;
}


.news_container{
    width: 98%;
    height: 120px;
    display: flex;
    margin: auto;
    cursor: pointer;
    position: relative;
    margin-bottom: 6px;
    background-color: white;
    border: solid 0.01rem #dadada;
}


.news_container:hover{
    background-color: rgb(250, 250, 250);
}


.img_news_container{
    width: 30%;
    height: 98%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 0%;
    margin: auto !important;
    margin-left: 2px;
}

.title_description_container{
    width: 70%;
    height: 100%;
    position: relative;
    padding-top:8px;
    padding-left: 8px ;
    padding-right: 3px;
}

.news_title{
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    font-size: .87rem;
    font-weight: 700;
    color: #2a2a2a;
}

.news_description{
    margin-top: 4px;
    font-size: .76rem;
    font-weight: 600;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    color: #2a2a2a;
}

.news_released_time{
    position: absolute;
    bottom: 1px;
    right: 9px;
    font-size: .68rem;
    font-weight: 600;
    color: #535353;
}
.MuiPaper-rounded {
    padding: 0 !important;
    border-radius: 0 !important;
}

.bills_widget_table_container{
    height: -webkit-fit-content !important;
    height: -moz-fit-content !important;
    height: fit-content !important;
    padding: 0 !important;
    border-radius: 0 !important;
    margin: 0 !important;
    font-size: .5rem !important;
}

.bills_widget_table_container .MuiTable-root {
    min-width: 700px !important;
}


.bills_widget_table_container .MuiTableCell-body:nth-child(1) {
    min-width:125px !important;
}

.bills_widget_table_container .MuiTableCell-body:nth-child(2) {
    min-width:105px !important;
}

.bills_widget_table_container .MuiTableCell-body:nth-child(3) {
    position: relative;
}

.bills_widget_table_container .bill_container_titles{
    position: absolute;
    top: 0px;
    left: 0px;
    margin-bottom: 0 !important;
    padding: 0 10px;
    padding-top: 15px;
}

.bills_widget_table_container .bill_container{
    display: flex;
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
}

.bills_widget_table_container .bill_infos{
    width: 20% !important;
    min-width: 80px !important;
    margin-right: 4px;
}

.bills_widget_table_container .bill_amount{
    width: 20% !important;
    min-width: 80px !important;
    margin-right: 5px;
}

.bills_widget_table_container .bill_treatedDate{
    width: 20% !important;
    min-width: 80px !important;
    margin-right: 6px;
}


.charts_of_the_most_ordered_prds_container{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.charts_of_the_most_ordered_prds_container .container{
    margin: auto;
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
}


.charts_of_the_most_ordered_prds_container .select_supplierCategories{
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
    margin-top: 10px;
}

.charts_of_the_most_ordered_prds_container .select_supplierCategories .MuiSelect-select.MuiSelect-select {
    color: rgb(74, 76, 74) !important;
    font-size: .9rem;
    padding: 3px 0;
}

.charts_of_the_most_ordered_prds_container .select_supplierCategories .MuiInput-underline:before {
    border: none !important;
}

.charts_of_the_most_ordered_prds_container .select_supplierCategories .MuiSelect-icon {
    color: rgb(74, 76, 74) !important;
}

.charts_of_the_most_ordered_prds_container .select_supplierCategories .MuiInputBase-root {
    font-family: var(--body-font) !important;
    border: solid 1px rgb(74, 76, 74) !important;
    padding-left: 6px !important;
    border-radius: 4px !important;
}

.charts_of_the_most_ordered_prds_container .select_supplierCategories .MuiSelect-nativeInput {
    left: 0;
    width: 100%;
    bottom: 0;
    opacity: 0;
    position: absolute;
    pointer-events: none;
}


.charts_of_the_most_ordered_prds_container .categorie_container{
    width: 85%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    margin: auto;
    margin-bottom: 8px;
    padding-right: 15px;
    position: relative;
    font-size: 1.2rem;
    font-weight: 700;
}


.charts_of_the_most_ordered_prds_container .order_of_the_most_ordered_prds {
    width: 50px;
    height: 50px;
    min-width: 50px;
    min-height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    border-radius: 100%;
    position:relative;
    right: -12px;
    font-size: inherit;
}


.charts_of_the_most_ordered_prds_container .name_of_the_most_ordered_prds{
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    flex: 1 1;
    font-size: inherit;
    padding: 5px 15px;
    text-align: center;
    color: rgb(52, 51, 51);
    background:linear-gradient(to right, #e0b663ad, #FCF6BA );
    border-radius: 3px;
    border-left: none;
    width: fit-content;
    min-width: 145px;
}


.charts_of_the_most_ordered_prds_container .css-i4bv87-MuiSvgIcon-root {
    font-size: 2.2rem !important;
}


@media all and (max-width: 1200px){

    .charts_of_the_most_ordered_prds_container .categorie_container{
        width: 86%;
        margin-bottom: 7px;
        font-size: 1.08rem;
    }

    .charts_of_the_most_ordered_prds_container .name_of_the_most_ordered_prds{
        padding: 5px 15px;
        min-width: 135px;
    }
    
    
    .charts_of_the_most_ordered_prds_container .order_of_the_most_ordered_prds {
        width: 46px;
        height: 46px;
        min-width: 46px;
        min-height: 46px;
        right: -12px;
    }

}


@media all and (max-width: 1080px){

    .charts_of_the_most_ordered_prds_container .categorie_container{
        margin-bottom: 6px;
        font-size: 1.02rem;
    }

    .charts_of_the_most_ordered_prds_container .name_of_the_most_ordered_prds{
        padding: 5px 15px;
        min-width: 135px;
    }
    
    
    .charts_of_the_most_ordered_prds_container .order_of_the_most_ordered_prds {
        width: 43px;
        height: 43px;
        min-width: 43px;
        min-height: 43px;
        right: -11px;
    }

}



@media all and (max-width: 900px){


    .charts_of_the_most_ordered_prds_container .categorie_container{
        margin-bottom: 6px;
        font-size: .92rem;
    }

    .charts_of_the_most_ordered_prds_container .name_of_the_most_ordered_prds{
        padding: 4px 15px;
        min-width: 135px;
    }
    
    
    .charts_of_the_most_ordered_prds_container .order_of_the_most_ordered_prds {
        width: 39px;
        height: 39px;
        min-width: 39px;
        min-height: 39px;
        right: -11px;
    }

}


@media all and (max-width: 500px){


    .charts_of_the_most_ordered_prds_container .categorie_container{
        margin-bottom: 6px;
        font-size: .82rem;
    }

    .charts_of_the_most_ordered_prds_container .name_of_the_most_ordered_prds{
        padding: 4px 15px;
        min-width: 125px;
    }
    
    
    .charts_of_the_most_ordered_prds_container .order_of_the_most_ordered_prds {
        width: 33px;
        height: 33px;
        min-width: 33px;
        min-height: 33px;
        right: -11px;
    }

}

.supplier_requests_container{
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    position: relative;
}


.supplier_requests_container table{
    max-width: 95%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    margin: auto;
}

.supplier_requests_container .MuiTableCell-body:nth-child(5) {
    min-width:320px !important;
}

.supplier_requests_container .MuiTableCell-body:nth-child(6) {
    min-width:210px !important;
}

.supplier_requests_container .MuiTableCell-body:nth-child(6) .documentsDownload{
    width: 100%;
}

.supplier_requests_container .MuiTableCell-body:nth-child(7) {
    min-width:190px !important;
}

.supplier_requests_container .MuiTableCell-body:nth-child(7) .documentsDownload{
    width: 100%;
}

.supplier_requests_container .documentsDownload {
    width: 65%;
}

.supplier_requests_container .documentsDownload label {
    margin-bottom: 0 !important;
}





.supplier_offers .my_offers_container_header{
    display: flex;
    justify-content:flex-end;
    width: 100%;
    padding: 30px 40px;
}



.gridOfAppelFourn{
    width: 95% !important;
    margin: auto !important;
}

.main_card_container .but_color:hover{
    background:#343a40 !important;
    opacity: .9 !important;
}
  
.supplier_proposels_dialogBottom{
    width: 100%;
}

.supplier_proposels_dialogBottom .submit_but{
    background:#343a40;
    color: white;
}

.supplier_proposels_dialogBottom .cancel_but{
    background:#e92a44;
}

.supplier_proposels_dialogBottom .cancel_but:hover{
    background:#e92a44;
    opacity: 0.9;
}

.supplier_proposels_dialogBottom .valide_but{
    background:#18ac67;
}

.supplier_proposels_dialogBottom .valide_but:hover{
    background:#18ac67;
    opacity: 0.9;
}

.supplier_proposels_dialogBottom .return_but:hover{
    background:#343a40;
    opacity: 0.9;
}


.documentsContainer_proposelPage{
    display: flex;
    margin-bottom: 15px;
    padding-left: 15px;
    flex-wrap: wrap;
    margin: auto;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}


.documentsContainer_proposelPage .documentsContainer{
    width: 100%;
    height: 100%;
    display: flex;
    margin-top: 45px !important;
}


.documentsContainer_proposelPage .documentsDownload{
    width: -webkit-fit-content !important;
    width: -moz-fit-content !important;
    width: fit-content !important;
    display: flex;
    vertical-align: middle;
    align-items: center;
}

.documentsContainer_proposelPage .documentsDownload label {
    display: inline-block;
    margin-bottom: 0 !important;
}

.documentsContainer_proposelPage .documentsDownload .download_button{
    margin-left: 12px !important;
    color: black;
}


.supplier_proposels_dialogBottom .dialogBottom div:nth-child(2){
    height: 50px !important;
}

.nextStep .error{
    padding: 10px 12px !important;
}

.supplier_proposels_dialogBottom .error{
    width: 100%;
    font-size: 1.1em;
    color: #dd3461 !important;
    background: #dc35451f;
    border-radius: 5px;
    text-align: start;
    margin: 0 !important;
}

.supplier_proposels_dialogBottom .dialogBottom div:nth-child(2){
    margin-left: 20px;
    display: flex;
    justify-content: flex-end;
}

.view_state_details_container .bill_state_byService{
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
}

.view_state_details_container .bill_state_byService div:first-child{
    font-size: 1rem;
}

.view_state_details_container .MuiOutlinedInput-multiline {
    padding: 7.5px 14px !important;
}

.view_state_details_container .MuiInputLabel-outlined.MuiInputLabel-shrink {
    /* margin-right: 5px; */
    -webkit-transform: translate(8px, -10.5px) scale(0.8) !important;
            transform: translate(8px, -10.5px) scale(0.8) !important;
    z-index: 4 !important;
    background-color: white !important;
    padding: 0 5px !important;
}

.view_state_details_container .first_textField_container{
    width: 100%;
    margin-top: 8px;
}

.view_state_details_container .second_textFields_container{
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin: 25px auto;
}

.view_state_details_container .second_textFields_container .container_11{
    width: 49%;
    margin-right: 6px;
}

.view_state_details_container .second_textFields_container .container_12{
    width: 49%;
    margin-left: 6px;
}

.documentsContainer_BillsPage{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 0 9px;
}


.documentsContainer_BillsPage label{
    margin-bottom: 0 !important;
    margin-right: 8px !important;
}


.documentsContainer_BillsPage .comp_docs_container{
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin-top: 8px;
}

.documentsContainer_BillsPage .header_form{
    margin: 6px 0 !important;
    margin-right: 10px !important;
}


.documentsContainer_BillsPage .comp_docs_container .documentsDownload{
    margin-bottom: 8px;
    width: 100%;
}

.addproductbutt{
    background:#3b5a7c !important;
    color: white !important;
}

.addproductbutt:hover{
    background:#4b698a !important;
}

.supplier_page .view_more_button_container{
    flex-direction: column;
}

.supplier_page .view_more_button_container div:first-child{
    height: 55%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.supplier_page .update_buttons{
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: 45%;
    display: flex;
    justify-content: space-around;
    padding: 25px 10px;
    margin: auto;
}



.supplier_page .update_buttons .cancel_but{
    background:#e92a44 !important;
}


.supplier_page .update_buttons .valide_but{
    background:#18ac67 !important;
}

.supplier_page .update_buttons .cancel_but:hover{
    opacity: .9 !important;
}

.supplier_page .update_buttons .valide_but:hover{
    opacity: .9 !important;
}


.products_gallery_container{
    width: 100% !important;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.products_gallery_container .search_filter_container{
    display: flex;
    width: 100%;
    justify-content: flex-end;
}



.products_gallery_contents_container .view_more_button_container button{
    border: 1px solid rgb(255, 255, 255);
    background-color: rgba(0, 0, 0, .4);
    color: white;
    position: relative;
    text-transform: none !important;
}


.products_gallery_contents_container .view_more_button_container button:hover{
    border: 1px solid rgb(230, 226, 226);
    background-color: rgba(0, 0, 0, .7);
}


@media (max-width: 845px){
    .products_gallery_container .search_filter_container {
        display: flex;
        width: 100%;
        justify-content: center;
    }
}



.products_gallery_contents_container .card_title {
    margin-top: 7px;
}

.products_gallery_contents_container .card_description {
    margin-top: 10px;
}

.products_gallery_contents_container .cancel_object_fab
,.products_gallery_contents_container .success_object_fab {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    padding: 0 !important;
    margin: 0 5px !important;
    border: solid 1px rgb(163, 163, 163) !important;
}


.add_product_modal_infos .MuiOutlinedInput-multiline {
    padding: 7.5px 14px !important;
}


.add_product_modal_infos .MuiInputLabel-outlined.MuiInputLabel-shrink {
    /* margin-right: 5px; */
    -webkit-transform: translate(8px, -10.5px) scale(0.75) !important;
            transform: translate(8px, -10.5px) scale(0.75) !important;
    z-index: 4 !important;
    background-color: white !important;
    padding: 0 5px !important;
}



.add_product_modal_pics{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}


.files{
    display : block;
    width:160px;
    height:120px;
    text-align: -webkit-center;
    border: 2px dashed #57596c !important;
    margin: 0;
    padding: 0;
    cursor:pointer;
    font-size:.9rem;
    font-weight:500;
    color:black;
    padding-top:20px;
    margin-bottom:10px;
}

.invalid-fileInput{
    border: 2px dashed #dc3545!important;
}

#input_contenair_enf1{
    background-image: url("https://cdn-icons-png.flaticon.com/128/1092/1092004.png");
    background-size:30%;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    width: 100%;
    height: 60px;
    -webkit-transform:scale(0.85);
            transform:scale(0.85);
    cursor: pointer;
  }


  .blocm{
    position: absolute;
    top: 13px;
    right: 6px;
    background-color:rgba(0,0,0,0);
    border: none;
    cursor: pointer;
    cursor:pointer;
    outline: none;
    color:rgba(211,57,92);
  }

  .blocm>div:nth-child(1){
        background-color:rgba(211,57,92);
        border:solid 1.75px rgba(211,57,92);
        width:20px;
        border-radius:3px;
        transition:all .5s ease;
        -webkit-transform:rotateZ(45deg);
                transform:rotateZ(45deg);

    }
    .blocm>div:nth-child(2){
        background-color:rgba(211,57,92);
        border:solid 1.5px rgba(211,57,92);
        width:20px;
        border-radius:3px;
        transition:all .5s ease;
        -webkit-transform:translateY(-3.1px) rotateZ(-45deg);
                transform:translateY(-3.1px) rotateZ(-45deg);
    }
 
  .myClass{
    width:160px;
    height:120px;
    border: 2px dashed #57596c !important;
    display:flex;
    padding:0; 
    margin: 0;
    margin-right:15px;
    margin-bottom:15px;
    justify-content: center;
    align-items: center;
    background-color: rgba(235, 235, 235, 0.2);
    position:relative;
    z-index: 0;
    
  }
  
  .myClass img{
    width: 100%;
    height: 100%;
    z-index:-1;
    margin: auto;
    object-fit: contain;
  }

  



.supplier_reference_container {
    width: 70vw !important;
    height: -webkit-fit-content !important;
    height: -moz-fit-content !important;
    height: fit-content !important;
    margin: 20px auto !important;
    overflow: hidden;
}


.supplier_reference_container .references_header_container .Msg_button_container{
    display: flex;
    justify-content: center;
    align-items:flex-end;
    align-content: flex-end;
    padding: 0 !important;
}


.references_header_container .submit_but{
    background:#343a40;
    color: white;
}

.references_header_container .submit_but:hover{
    background:#343a40;
    opacity: .9;
}

#text_non_lue{
    position: absolute;
    right: -7%;
    top: -16%;
    width: 20px !important;
    height: 20px !important;
    border-radius: 50%;
    background: #4BEA82;
    padding: 0 !important;
    display: flex;
    justify-content: center;
    align-items: center;
    color: black;
    font-size: .7rem;
}


.supplier_reference_container .MuiTableCell-head {
    color: rgba(26, 24, 24, 0.87) !important;
    font-weight: 600 !important;
    line-height: 1.5rem !important;
}

.supplier_reference_container .MuiTableCell-body:nth-child(1) {
    min-width:30px !important;
    max-width:40px !important;
}

.supplier_reference_container .MuiTableCell-body:nth-child(4) {
    min-width:300px !important;
}

.supplier_reference_container .documentsContainer{
    width: 100%;
    height: 100%;
    display: block;
}


.supplier_reference_container .documentsDownload{
    width: 45%;
    display: flex;
    vertical-align: middle;
    align-items: center;
}

.supplier_reference_container .documentsDownload label {
    display: inline-block;
    margin-bottom: 0;
}

.supplier_reference_container .documentsDownload .download_button{
    margin-left: 9px !important;
    color: black;
}


.supplier_reference_container .documentsTitle{
    font-size: inherit !important;
    font-weight: 700;
    color: rgb(64, 64, 65);
    margin-left: 7px;
    align-self: center;
}

.supplier_reference_container .documentsStatus_valide{
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    min-width: 70px;
    display: flex;
    justify-content: space-around;
}

.supplier_reference_container .MuiTableCell-body:nth-child(5) {
    min-width:115px;
}



.referenceStatusContainer{
    width: 100%;
    text-align: end;
    align-items: end;
    display: flex;
    justify-content: right;
}

.referenceStatusContainer2{
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    display: block;
    justify-content:center;
    text-align: center;
    align-items: center;
}


.sendBut_conatainer{
    width: 100%;
    margin: 20px 0;
    text-align: right;
}

.sendBut_conatainer button{
    background-color: rgb(52, 58, 64);
    color: white;
}

.sendBut_conatainer button:hover{
    background-color: rgb(52, 58, 64);
    opacity: .9;
}

@media all and (max-width: 1000px){

    .supplier_reference_container {
        width: 80vw !important;
    }

}


@media all and (max-width: 820px){
    .supplier_reference_container {
        width: 86vw !important;
    }
}

@media (max-width: 580px){
    .supplier_reference_container .references_header_container {
        display: flex;
        flex-direction: row !important;
    }
}

@media all and (max-width: 500px){

    .supplier_reference_container {
        width: 90vw !important;
    }

    #text_non_lue{
        width: 17px !important;
        height: 17px !important;
        font-size: .6rem;
    }
    
}


@media all and (max-width: 400px){

    .supplier_reference_container {
        width: 98vw !important;
    }

    #text_non_lue{
        width: 17px !important;
        height: 17px !important;
        font-size: .6rem;
    }
    
}
.chatModal_container .MuiDialog-paperFullWidth {
    width: 60%;
    height: 100% !important;
    margin: 5px auto !important;
    padding: 0 !important;
}

.chatModal_container .MuiDialogContent-root{
    padding: 0 !important;
    overflow: hidden !important;
}



.chatModal_container #user{
    border-bottom: 1px solid; font-size: 16pt; display: flex; align-items: center;
}

.chatModal_container .modal-content .modal-body {
    padding: 0 !important;
}


.chatModal_container .chatView_container {
    height: 100%;
    flex:1 1;
    background-color: rgb(249 249 249);
    border: solid 1px rgb(87, 87, 87);
    display: flex;
    flex-direction: column;
}


.chatModal_container .contact-profile {
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    font-weight: 600;
    padding: 15px !important;
    background: #f5f5f5;
    font-size: 1.3rem !important;
  }

  .chatView_container .messages {
    flex: 1 1;
    min-height: auto !important;
    overflow-y: scroll;
    overflow-x: hidden;
    width: 100%;
    padding: 0 !important;
    margin-bottom: 0;
  }


  .chatView_container ul li:nth-last-child(1) {
    margin-bottom: 12px !important;
  }

  .chatView_container .messages::-webkit-scrollbar {
    width: 8px;
    background: transparent;
    cursor: pointer;
  }
  .chatView_container .messages::-webkit-scrollbar-thumb {
    background-color: rgba(141, 141, 141);
    cursor: pointer;
  }
  .chatView_container ul li {
    display: inline-block;
    clear: both;
    width: calc(100% - 60px);
    font-size: .95em;
    margin: 9px 0;
  }

  .chatView_container .emetteur{
    position: relative;
    display: inline-block;
    justify-content: flex-start;
    margin: 9px 3.5rem !important;
    font-family: nunito;
}
.chatView_container .emetteur > span{
    min-width: 10ch;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    display: flex;
    align-items: center;
    max-width: 40ch;
    padding: .3rem .5rem;
    border-radius: .2rem;
    background: #2c3e50bd;
    position: relative;
    color: white !important;

}

.chatView_container .emetteur >  div:nth-child(1){
    position: relative;
    left: -2.5rem;
    font-size: 1rem;
    font-weight: 700;
}

.chatView_container .emetteur .temps{
    font-size: .8rem;
    top: 100%;
    left: 0%;
    width: -webkit-max-content;
    width: max-content;
}


.destinateur{
    position: relative;
    font-family: nunito;
    text-align: -webkit-right;
}

.destinateur > span{
    border: solid 1px gray;
    min-width: 10ch;
    text-align: left !important;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    display: flex;
    align-items: center;
    max-width: 40ch;
    padding: .3rem .5rem;
    border-radius: .2rem;
    background: whitesmoke;
    position: relative;
} 
.destinateur > div:nth-child(1){
    font-size: 1rem;
    font-weight: 700;
    position: relative;
    max-width: 8ch;
    overflow: hidden;
    text-overflow: ellipsis;
    left: 45px;
}


#messages .text > span{
    word-wrap: break-word;
    max-width: 38ch;
    display: block;
}

.input_container{
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    display: flex;
    border-top: solid 1px #777777;
    padding: 0px;
    margin: 0 !important;
    padding: 10px 14px;
}

.input_container textarea{
    max-width: 100% !important;
    max-height: none !important;
    padding: 9px 5px 7px 9px;
    resize: auto !important;
    font-size: 1rem !important;
    border: solid 0.1rem #777777 !important;
    color: #555353;
    border-radius: 7px;
    line-height: 1 !important;
    margin: 0 !important;
}

.textarea_container{
    min-width:94%;
    max-width:94%;
    height:100%;
    position: relative;
}

.sendBut_container{
    align-self: center;
    min-width: 15px;
    margin-left: 7px;
}



.chatModal_container #frame {
    width: 100%;
    min-width: 360px;
    height: 100%;
    min-height: 300px;
    max-height: 720px;
    background: #E6EAEA;
    display: flex;
  }


.chatModal_container #frame #sidepanel {
    float: left;
    min-width: 240px;
    max-width: 270px;
    width: 40%;
    background: #405e7c;
    color: #f9f9f9;
    overflow: hidden;
    position: relative;
  } 

  .chatModal_container .contact:hover {
    background: #537295;
  }
  .chatModal_container .contact.active {
    background: #537295;
  }
  .chatModal_container .contact.active span.contact-status {
    border: 2px solid #32465a !important;
  }


.chatModal_container  #frame #sidepanel #search {
    border-top: 1px solid #32465a;
    border-bottom: 1px solid #32465a;
    background: #f5f5f5;
  }
  
  
  .chatModal_container  #frame #sidepanel #search label {
    position: absolute;
    margin: 6px 0 0 8px;
    color: #32465a;
    background-color: transparent;
  }
  .chatModal_container  #frame #sidepanel #search input {
    font-family: nunito;
    padding: 10px 0 10px 32px;
    width: calc(100% - 3px);
    border: none;
    color: #32465a;
    background: #f5f5f5;
    height: 35px;
  }
  .chatModal_container  #frame #sidepanel #search input:focus {
    outline: none;
  }
  
  .chatModal_container  #frame #sidepanel #contacts {
    height: calc(100% - 177px);
    overflow-y: auto;
    overflow-x: hidden;
    text-overflow: ellipsis;
  }
  
  .chatModal_container  #frame #sidepanel #contacts::-webkit-scrollbar {
  width: 8px;
  background: #2c3e50;
  }
  .chatModal_container  #frame #sidepanel #contacts::-webkit-scrollbar-thumb {
  background-color: #8d8d8d;
  }


  .chatModal_container  #frame #sidepanel #contacts ul{
	list-style: none;
        padding-left:0;
        margin:0;
     }

  
  
  @media all and (max-width: 900px) {
    .chatModal_container  #frame #sidepanel {
      width: 58px;
      min-width: 58px;
    }
  
    .chatModal_container  #frame #sidepanel #profile {
      width: 100%;
      margin: 0 auto;
      padding: 5px 0 0 0;
      background: #32465a;
    }
    .chatModal_container  #frame #sidepanel #profile .wrap {
        height: 55px;
    }
    .chatModal_container  #frame #sidepanel #profile .wrap img {
        margin-left: 0.2rem;
    }
    .chatModal_container  #frame #sidepanel #profile .wrap p {
        display: none;
    }
    .chatModal_container  #frame #sidepanel #search {
        display: none;
    }
    .chatModal_container  #frame #sidepanel #contacts {
        height: calc(100% - 149px);
        overflow-y: scroll;
        overflow-x: hidden;
    }

    .chatModal_container  #frame #sidepanel #contacts::-webkit-scrollbar {
      display: none;
    }

    .chatModal_container .contact .wrap .meta {
        display: none !important;
    }
}
  
@media all and (max-width: 975px){
    .emetteur > span, .destinateur > span{
        max-width: 35ch !important;
    }
}

@media all and (max-width: 880px){
    .chatModal_container .MuiDialog-paperFullWidth {
        width: 75% !important;
    }
}



@media all and (max-width: 750px){
    .chatModal_container .MuiDialog-paperFullWidth {
        width: 80% !important;
    }

    .chatModal_container .contact-profile {
        padding: 12px !important;
        font-size: 1.2rem !important;
    }

    .emetteur > div:nth-child(1), .destinateur > div:nth-child(1) {
        font-size: .95rem !important;
    }

    .chatView_container .emetteur {
        margin: 7px 3rem !important;
    }

    .chatView_container .emetteur > div:nth-child(1) {
        left: -2.2rem;
    }

    .chatView_container .destinateur{
        margin: 7px 0 !important;
    }
    .chatView_container ul li {
        width: calc(100% - 55px) !important;
        font-size: .9em !important;
    }

    .chatView_container .emetteur .temps {
        font-size: .75rem;
    }

    .input_container textarea{
        padding: 7px 4px 6px 7px;
        font-size: .95rem !important;
    }

    .emetteur > span, .destinateur > span{
        max-width: 32ch !important;
    }

    .chatView_container .messages::-webkit-scrollbar {
      display: none;
    }
}



@media all and (max-width: 580px){
    .chatModal_container .MuiDialog-paperFullWidth {
        width: 89% !important;
    }

    .chatModal_container .contact-profile {
        padding: 9px !important;
        font-size: 1.1rem !important;
    }

    .emetteur > div:nth-child(1), .destinateur > div:nth-child(1) {
        font-size: .9rem !important;
    }

    .chatView_container .emetteur {
        margin: 5px 2.5rem !important;
    }

    .chatView_container .emetteur > div:nth-child(1) {
        left: -2rem !important;
    }

    .chatView_container .destinateur{
        margin: 5px 0 !important;
    }
    .chatView_container ul li {
        width: calc(100% - 55px) !important;
        font-size: .85em !important;
    }

    .chatView_container .emetteur .temps {
        font-size: .7rem;
    }

    .input_container textarea{
        padding: 7px 4px 5px 6px;
        font-size: .9rem !important;
    }

    .emetteur > span, .destinateur > span{
        max-width: 27ch !important;
    }

    .chatModal_container #frame #sidepanel {
        width: 48px;
        min-width: 48px;
    }
    .chatModal_container .contact {
      padding: 5px 0 3px 0 !important;
    }

    .chatModal_container .contact .wrap .img_cont {
      margin: auto;
  }
      .chatModal_container .contact .wrap img {
        width: 38px;
        height: 38px;
    }
}


@media all and (max-width: 450px){
    .chatModal_container .MuiDialog-paperFullWidth {
        width: 97% !important;
    }

    .emetteur > div:nth-child(1), .destinateur > div:nth-child(1) {
        font-size: .85rem !important;
    }

    .input_container textarea{
        padding: 5px 5px 4px 5px;
        font-size: .85rem !important;
    }

    .emetteur > span, .destinateur > span{
        max-width: 23ch !important;
    }

    .chatModal_container #frame #sidepanel {
        width: 45px;
        min-width: 45px;
    }

    .chatModal_container .contact {
      padding: 5px 0 3px 0 !important;
    }

    .chatModal_container .contact .wrap .img_cont {
        margin: auto;
    }
        .chatModal_container .contact .wrap img {
          width: 35px;
          height: 35px;
      }
}






.chatModal_container .contact {
    position: relative;
    padding: 10px 0;
    font-size: 0.85em;
    cursor: pointer;
  }
  
 .chatModal_container p {
    margin:0;
  }
  
  
  .chatModal_container .contact .wrap {
    width: 92%;
    margin: 0 auto;
    position: relative;
    display: flex;
  }
  .chatModal_container .contact .wrap span {
    position: absolute;
    left: 0;
    margin: -2px 0 0 -2px;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    border: 2px solid #2c3e50;
    background: #e74c3c;
  }
  .chatModal_container .contact .wrap span.online {
    background: #2ecc71;
  }
  
  .chatModal_container .contact .wrap img {
    width: 40px;
    height: 40px;
    border-radius: 100%;
    float: left;
    margin-right: 8px;
    border: 1px solid #929292;
    object-fit: cover;
    background-color: rgb(253 253 253 / 48%);
  }
  .chatModal_container .contact .wrap .meta {
    padding:0;
    font-family: nunito;
    width: 70%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .chatModal_container .contact .wrap .meta .name {
    height: 1.35rem;
    font-size: .95rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 95%;
  }
  .chatModal_container .contact .wrap .meta .preview {
    margin: 0;
    padding: 0;
    font-weight: 300;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    transition: 1s all ease;
    height:19px;
    width: 83%;
    color: rgb(206, 206, 206);
    font-size: .77rem;
  }
  .chatModal_container .contact .wrap .meta .preview span {
    position: initial;
    border-radius: initial;
    background: none;
    border: none;
    padding: 0 2px 0 0;
    margin: 0 0 0 1px;
    opacity: .5;
  }
  
 .chatModal_container #text_non_lue{
    position: absolute;
    right: 1%;
    top: 27%;
    margin: 0 !important;
    width: 18px !important;
    height: 18px !important;
    border-radius: 100%;
    background: #4BEA82;
    padding: 0 !important;
    display: flex;
    justify-content: center;
    align-items: center;
    color: black;
  }
  
  @media all and (max-width: 900px) {
    .chatModal_container .contact {
      padding: 6px 0 8px 8px;
    }
    .chatModal_container .contact .wrap {
      width: 100%;
    }
    .chatModal_container .contact .wrap img {
      margin-right: 0px;
    }
    .chatModal_container .contact .wrap .meta .preview{
      display: none;
    }
  
    .chatModal_container .contact .wrap .meta .name{
      height: 15px;
      width: 80%;
      overflow: hidden;
      font-size: 0.85rem;
      text-overflow: ellipsis;
    }
  
    .chatModal_container #text_non_lue{
      position: absolute;
      top: 1.7rem;
      right: .25rem;
      font-size: .7rem;
      width: 15px !important;
      height: 15px !important;
      color: #060606;
    }
    
  }



.contracts_list_cadmin_container{
    width: 95%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    margin: 0 auto;
}

.contracts_list_cadmin_container_header{
    font-size: 1.2rem;
    padding: 0 20px;
    padding-bottom: 10px;
    border-bottom: solid 1px black;
    width: 100%;
    margin: auto;
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
}

.contracts_list_cadmin_container_header .title_container{
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    display: flex;
    align-items: flex-end;
    align-content: flex-end;
}

.contracts_list_cadmin_container_header .title_container label{
    margin: 0 !important;
}

.contracts_list_cadmin_container .Contracts_list_table{
    width: 100% !important;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    margin: auto !important;
}

.Contracts_list_table .MuiTableCell-head {
    font-weight: 700 !important;
}

.Contracts_list_table .MuiTableCell-body:nth-child(4) {
    min-width:265px !important;
}



.subTypes_container{
    width:100%;
    height: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.subType_container{
    width:100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    display: flex;
    margin-bottom: 5px;
    align-items: center;
}

.subType{
    width:60%;
    height: 100%;
}

.subType_state{
    width:40%;
    height: 100%;
    display: contents;
}

.subType_sign_date_container{
    margin-bottom: 5px;
}

.subType_sign_date_container .MuiFab-circular{
    width: 0 !important;
}
.applicant_request_view{
    width : 100% !important;
    margin: auto;
}



.applicant_request_view .applicant_request_view_header_container{
    font-size: 1.2rem;
    padding: 0 20px 15px 20px;
    width: 95%;
    margin: auto;
    margin-top: 30px;
    margin-bottom: 20px;
    border-bottom: solid 1px black;
    display: flex;
    justify-content: space-between;
}

.applicant_request_view .applicant_request_view_header_container .MuiGrid-container {
    width: -moz-fit-content !important;
    width: -webkit-fit-content !important;
    width: fit-content !important;
    align-content: center !important;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
}

.applicant_request_view .disable_button{
    opacity: 0.4;
    cursor: default !important;
}

.applicant_request_view .disable_button:hover{
    background-color: #e0e0e0 !important;
    opacity: 0.4;
    cursor: default !important;
}

.applicant_request_view label {
    margin-bottom: 0 !important;
    margin-right: 10px !important;
}

.applicant_request_view .submittions_table{
    width: 96% !important;
    margin: auto !important;
}

.applicant_request_view .MuiTableCell-body:nth-child(8) {
    min-width:235px !important;
}

.applicant_request_view .MuiTableCell-body:nth-child(8) .documentsDownload{
    width: 100%;
}


.set_refuse_raison_container .MuiOutlinedInput-multiline {
    padding: 7.5px 14px !important;
}


/*
///////////////////////////////////////////////////////
/////////////////////////////////////////////////////*/





.set_refuse_raison_container .MuiOutlinedInput-multiline {
    padding: 7.5px 14px !important;
}



/*
///////////////////////////////////////////////////////
/////////////////////////////////////////////////////*/




.set_refuse_raison_container .MuiOutlinedInput-multiline {
    padding: 7.5px 14px !important;
}


/*
///////////////////////////////////////////////////////
/////////////////////////////////////////////////////*/




.import_files_page_container{
    width: 100%;
    overflow: auto;
}


.drop_zone_container{
    width: 100%;
}


.all_imp_res_container{
    width: 100%;
    padding: 15px;
    display: flex;
    flex-wrap: wrap;
}

.imp_res_container{
    width: 32%;
    min-width: 310px;
    height: 245px;
    margin: 6px 5px;
    display: flex;
    flex-direction: column;
    color: rgb(84, 84, 84);
    border: solid 1px rgb(216, 216, 216);
    background-color: rgb(252, 252, 252);
    border-radius: 4px;
    cursor: pointer;
    transition: all 150ms ease-in-out 0s;
}

.imp_res_container:hover{
    border: solid 1px rgb(44, 190, 195);
    background-color: rgba(228, 248, 244, .4);
    transition: all 150ms ease-in-out 0s;
}

.imp_res_container_slected{
    border: solid 1px rgb(44, 190, 195) !important;
    background-color: rgba(228, 248, 244, .4) !important;
    transition: all 150ms ease-in-out 0s;
}


.imp_res_radio{
    height: 27%;
}

.imp_res_icon{
    height: 23%;
    text-align: center;
}


.imp_res_icon .MuiSvgIcon-root {
    width: 47px !important;
    height: 47px !important;
}

.imp_res_title{
    flex: 1 1;
    text-align: center;
    font-size: 1rem;
}

.files__container{
    width: 73%;
    margin: 15px auto;
    display: flex;
    flex-direction: column;
}

.import_files_page_container .submit_but{
    all: unset;
    background:rgb(231, 118, 74);
    color: white;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    padding: .5rem 1.5rem;
    font-size: .9rem;
    border-radius: 5px;
    transition: -webkit-filter 300ms;
    transition: filter 300ms;
    transition: filter 300ms, -webkit-filter 300ms;
    cursor: pointer;
    margin-left: 20px;
}

.import_files_page_container .submit_but:hover{
    background: rgb(228, 88, 32);
    opacity: .9;
}


.zone_title{
    color: rgb(84, 84, 84);
    font-weight: 600;
    margin: 4px 0;
    display: flex;
    flex-direction: column;
    justify-content:flex-start !important;
}

@media (max-width: 1100px){

    .files__container{
        width: 75%;
    }


    .imp_res_container{
        width: 32%;
        height: 215px;
        min-width: 280px !important;
        margin: 5px 5px;
    }
    
    .imp_res_icon .MuiSvgIcon-root {
        width: 43px !important;
        height: 43px !important;
    }
    
    .imp_res_title{
        font-size: .95rem;
    }

}

@media (max-width: 870px){

    .files__container{
        width: 85%;
    }

    .all_imp_res_container{
        padding: 10px;
        justify-content: center;
    }

    .imp_res_container{
        width: 48%;
        height: 220px;
        min-width: 290px !important;
        margin: 5px 5px;
    }
    
    .imp_res_icon .MuiSvgIcon-root {
        width: 35px !important;
        height: 35px !important;
    }
    
    .imp_res_title{
        font-size: .95rem;
    }

}

@media (max-width: 635px){

    .files__container{
        width: 98%;
    }

    .all_imp_res_container{
        padding: 5px;
        justify-content: center;
    }

    .imp_res_container{
        width: 48%;
        height: 160px;
        min-width: 230px !important;
        margin: 3px 3px;
    }

    .imp_res_icon{
        height: 23%;
        text-align: center;
    }
    
    
    .imp_res_icon .MuiSvgIcon-root {
        width: 33px !important;
        height: 33px !important;
    }
    
    .imp_res_title{
        font-size: .9rem;
    }
}


.dropzone{
    text-align: center;
    border: 2px rgb(151, 151, 151) dashed;
    background-color: rgb(252, 252, 252);
    width: 73%;
    border-radius: 8px;
    margin: 25px auto;
    padding: 5px;
    height: 220px;
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .dropzone_error{
    border: 2px rgb(216, 112, 112) dashed;
    color: rgb(192, 44, 44);
    background-color: rgb(254, 242, 242);
  }


.dropzone p{
    font-weight: 300;
    font-size: 1.2em;
}

.upload_button{
  background:#3b5a7c !important;
  color: white !important;
}


.upload_button:hover{
  background:#4b698a !important;
}


@media (max-width: 600px){

  .dropzone{
    border: 1.5px rgb(151, 151, 151) dashed;
    width: 88%;
    padding: 5px;
    margin: 20px auto;
    height: 140px;
}

  .dropzone p{
    font-weight: 300;
    font-size: .9rem !important;
}


}
.MuiPaper-rounded {
    padding: 0 !important;
    border-radius: 0 !important;
}

.files_table_container{
    padding: 0 !important;
    border-radius: 0 !important;
    margin: 0 !important;
    font-size: .5rem !important;
}


.files_table_container .MuiTableCell-head {
    background: rgba(96, 119, 135, 0.85) !important;
    color: white !important;
}
  

.files_table_container .MuiTable-root {
    min-width: 700px !important;
}


.files_table_container .MuiTableCell-body:nth-child(1) {
    min-width:55px !important;
}

.files_table_container .MuiTableCell-body:nth-child(2) {
    min-width:105px !important;
}

.files_table_container .MuiTableCell-body:nth-child(3) {
    position: relative;
}

.files_table_container .bill_container_titles{
    position: absolute;
    top: 0px;
    left: 0px;
    margin-bottom: 0 !important;
    padding: 0 10px;
    padding-top: 15px;
}

.files_table_container .bill_container{
    display: flex;
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
}

.files_table_container .bill_infos{
    width: 20% !important;
    min-width: 80px !important;
    margin-right: 4px;
}

.files_table_container .bill_amount{
    width: 20% !important;
    min-width: 80px !important;
    margin-right: 5px;
}

.files_table_container .bill_treatedDate{
    width: 20% !important;
    min-width: 80px !important;
    margin-right: 6px;
}


.charts_ofBillsAmount_payed_unpayed{
    width: 96%;
    height: 100%;
    margin: auto;
    padding: 0;
    padding-top: 10px;
}

.charts_ofBillsAmount_payed_unpayed .pexcharts-legend-text{
    font-size: .9rem !important;
    font-weight: 500 !important;
    font-style: var(--body-font) !important;
}
.MuiPaper-rounded {
    padding: 0 !important;
    border-radius: 0 !important;
}

.charts_of_bills_count_byState_container{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}


.charts_of_bills_count_byState_container .MuiTable-root {
    min-width: 750px !important;
}


.charts_of_bills_count_byState_container .bills_count_byState_state{
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    display: flex;
    justify-content: space-between;
    padding-right: 15px;
    padding-left: 15px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.charts_of_bills_count_byState_container .bills_count_byState_state .MuiSelect-select.MuiSelect-select {
    color: rgb(74, 76, 74) !important;
    font-size: .9rem;
    padding: 3px 0;
    padding-top: 3.5px;
}

.charts_of_bills_count_byState_container .bills_count_byState_state .MuiInput-underline:before {
    border: none !important;
}

.charts_of_bills_count_byState_container .bills_count_byState_state .MuiSelect-icon {
    color: rgb(74, 76, 74) !important;
}

.charts_of_bills_count_byState_container .bills_count_byState_state .MuiInputBase-root {
    font-family: var(--body-font) !important;
    border: solid 1px rgb(74, 76, 74) !important;
    padding-left: 6px !important;
    border-radius: 4px !important;
}

.charts_of_bills_count_byState_container .bills_count_byState_state .MuiSelect-nativeInput {
    left: 0;
    width: 100%;
    bottom: 0;
    opacity: 0;
    position: absolute;
    pointer-events: none;
}


.charts_of_bills_count_byState_container .container{
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    flex:1 1;
    padding: 0 !important;
}


.bills_widget_table_container{
    padding: 0 !important;
    border-radius: 0 !important;
    margin: 0 !important;
    font-size: .5rem !important;
}


.bills_widget_table_container .MuiTableCell-body:nth-child(1) {
    min-width:125px !important;
}

.bills_widget_table_container .MuiTableCell-body:nth-child(2) {
    min-width:65px !important;
}

.bills_widget_table_container .bill_lign{
    margin-bottom: 4px;
    width: 100%;
}
.all_news_container{
    width:100%;
    height:100%;
    background-color: rgb(251, 251, 251);    
    padding-top: 6px;
}


.news_container{
    width: 98%;
    height: 120px;
    display: flex;
    margin: auto;
    cursor: pointer;
    position: relative;
    margin-bottom: 6px;
    background-color: white;
    border: solid 0.01rem #dadada;
}


.news_container:hover{
    background-color: rgb(250, 250, 250);
}


.img_news_container{
    width: 30%;
    height: 98%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 0%;
    margin: auto !important;
    margin-left: 2px;
}

.title_description_container{
    width: 70%;
    height: 100%;
    position: relative;
    padding-top:8px;
    padding-left: 8px ;
    padding-right: 3px;
}

.news_title{
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    font-size: .87rem;
    font-weight: 700;
    color: #2a2a2a;
}

.news_description{
    margin-top: 4px;
    font-size: .76rem;
    font-weight: 600;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    color: #2a2a2a;
}

.news_released_time{
    position: absolute;
    bottom: 1px;
    right: 9px;
    font-size: .68rem;
    font-weight: 600;
    color: #535353;
}
.MuiPaper-rounded {
    padding: 0 !important;
    border-radius: 0 !important;
}

.bills_widget_table_container{
    padding: 0 !important;
    border-radius: 0 !important;
    margin: 0 !important;
    font-size: .5rem !important;
}

.bills_widget_table_container .MuiTable-root {
    min-width: 700px !important;
}


.bills_widget_table_container .MuiTableCell-body:nth-child(1) {
    min-width:125px !important;
}

.bills_widget_table_container .MuiTableCell-body:nth-child(2) {
    min-width:105px !important;
}

.bills_widget_table_container .MuiTableCell-body:nth-child(3) {
    position: relative;
}

.bills_widget_table_container .bill_container_titles{
    position: absolute;
    top: 0px;
    left: 0px;
    margin-bottom: 0 !important;
    padding: 0 10px;
    padding-top: 15px;
}

.bills_widget_table_container .bill_container{
    display: flex;
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
}

.bills_widget_table_container .bill_infos{
    width: 20% !important;
    min-width: 80px !important;
    margin-right: 4px;
}

.bills_widget_table_container .bill_amount{
    width: 20% !important;
    min-width: 80px !important;
    margin-right: 5px;
}

.bills_widget_table_container .bill_treatedDate{
    width: 20% !important;
    min-width: 80px !important;
    margin-right: 6px;
}


.widget_count_elements_container{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    font-size: 4rem;
    cursor: pointer;
}


.widget_count_elements_container .number_arrow_container{
    position: relative;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
}

.widget_count_elements_container .arrow_container{
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    position: absolute;
    top: -5px;
    right: -45px;
    display: flex;
    color: green;
}

.widget_count_elements_container .arrow_container .css-i4bv87-MuiSvgIcon-root {
    width: 2em !important;
    height: 2em !important;
}
.add_infos_toBill_container{
    width: 75%;
    margin: auto;
    display: flex;
    justify-content: space-between;
}


.set_refuse_raison_container .MuiOutlinedInput-multiline {
    padding: 7.5px 14px !important;
}

/*
///////////////////////////////////////////////////////
/////////////////////////////////////////////////////*/




.view_state_details_container .bill_state_byService{
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
}

.view_state_details_container .bill_state_byService div:first-child{
    font-size: 1rem;
}

.view_state_details_container .MuiOutlinedInput-multiline {
    padding: 7.5px 14px !important;
}

.view_state_details_container .MuiInputLabel-outlined.MuiInputLabel-shrink {
    /* margin-right: 5px; */
    -webkit-transform: translate(8px, -10.5px) scale(0.8) !important;
            transform: translate(8px, -10.5px) scale(0.8) !important;
    z-index: 4 !important;
    background-color: white !important;
    padding: 0 5px !important;
}

.view_state_details_container .first_textField_container{
    width: 100%;
    margin-top: 8px;
}

.view_state_details_container .second_textFields_container{
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin: 25px auto;
}

.view_state_details_container .second_textFields_container .container_11{
    width: 49%;
    margin-right: 6px;
}

.view_state_details_container .second_textFields_container .container_12{
    width: 49%;
    margin-left: 6px;
}

.commissions_container{
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    width: 100%;
}

.commissions_container_header{
    font-size: 1.2rem;
    padding: 0 20px 15px 20px;
    width: 95%;
    margin: auto;
    margin-top: 30px;
    margin-bottom: 20px;
    border-bottom: solid 1px black;
    display: flex;
    justify-content: space-between;
}


.commissions_container_header .MuiGrid-container {
    width: -webkit-fit-content !important;
    width: -moz-fit-content !important;
    width: fit-content !important;
    align-content: center !important;
    position: relative !important;
}


.commissions_container .MuiTableCell-body:nth-child(1) {
    min-width:100px !important;
    max-width:100px !important;
}

.commissions_container .MuiTableCell-body:nth-child(2) {
    min-width:85px !important;
    max-width:85px !important;
}

.commissions_container .MuiTableCell-body:nth-child(4),.commissions_container .MuiTableCell-body:nth-child(5) {
    min-width:110px !important;
    max-width:110px !important;
}

.commissions_container .MuiTableCell-body:nth-child(6) {
    min-width:220px;
}

.commissions_container .MuiTableCell-body:nth-child(7) {
    min-width:75px;
}

.commissions_container .MuiTableCell-body:nth-child(8) {
    min-width:85px;
}


.commissions_container_header .search_system{
    display: flex;
}

.commissions_table_container{
    width: 99%;
    margin: auto;
    margin-bottom: 50px;
}

.commissions_table_container .documentsContainer {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
  
.commissions_table_container .AccordCtrl_container{
    width: -moz-fit-content !important;
    width: -webkit-fit-content !important;
    width: fit-content !important;
    height: 100%;
    display: flex !important;
    flex-direction: column !important;
    justify-content: center !important;
    align-items: center !important;
}
.dates_proposition_dialog textarea{
    max-width: 100% !important;
    max-height: none !important;
    padding: 5px 10px 7px 5px !important;
    resize: auto !important;
    font-size: 1rem;
    border: solid 0.1rem #b1b0b0 !important;
    border-radius: 0;
    line-height: 1 !important;
}

.dates_proposition_dialog .MuiDialog-paperFullWidth {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding: 10px !important;
}

.dates_proposition_dialog .plus_details_dialog_container{
    padding: 0 !important;
}


.dates_proposition_dialog .header_form:last-child{
    margin-right: 0 !important;
}

.all_news_container{
    width:100%;
    height:100%;
    background-color: rgb(251, 251, 251);    
    padding-top: 6px;
}


.news_container{
    width: 98%;
    height: 120px;
    display: flex;
    margin: auto;
    cursor: pointer;
    position: relative;
    margin-bottom: 6px;
    background-color: white;
    border: solid 0.01rem #dadada;
}


.news_container:hover{
    background-color: rgb(250, 250, 250);
}


.img_news_container{
    width: 30%;
    height: 98%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 0%;
    margin: auto !important;
    margin-left: 2px;
}

.title_description_container{
    width: 70%;
    height: 100%;
    position: relative;
    padding-top:8px;
    padding-left: 8px ;
    padding-right: 3px;
}

.news_title{
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    font-size: .87rem;
    font-weight: 700;
    color: #2a2a2a;
}

.news_description{
    margin-top: 4px;
    font-size: .76rem;
    font-weight: 600;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    color: #2a2a2a;
}

.news_released_time{
    position: absolute;
    bottom: 1px;
    right: 9px;
    font-size: .68rem;
    font-weight: 600;
    color: #535353;
}
.add_company_form{
    margin: 20px auto;
    width: 60vw;
    justify-content: center;
}


.add_company_form .form{
    text-align: -webkit-right;
}

.add_company_form h2 {
    margin-bottom: 0px !important;
    padding: 0 20px;
}

.add_company_form label{
    width: 100%;
    text-align: left;
    margin: 0;
    font-size: 1rem;
    font-weight: 600;
    padding-left: 2px;
}

.error{
    width: 100%;
    font-size: 1.1em;
    color: #e13d68 !important;
    background: #dc35451f;
    padding: 10px 20px;
    border-radius: 5px;
    margin-bottom: 20px;
    text-align: start;
}



.add_company_form .row {
    margin: 14px 0;
}



.add_company_form .form-control {
    border: 1px solid #c4c3c3;
    border-radius: 6px;
    color: #2c2c2c;
    height: auto;
    font-size: .95rem;
    box-shadow: none;
    margin-bottom: 10px;
    height: 39px;
    background-image: none !important;
}

.add_company_form .submit_but{
    all: unset;
    background: rgb(3, 113, 119) !important;
    color: white;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    padding: .5rem 1.9rem;
    font-size: 1rem;
    border-radius: 5px;
    transition: -webkit-filter 300ms;
    transition: filter 300ms;
    transition: filter 300ms, -webkit-filter 300ms;
    cursor: pointer;
    margin-right: 12px;
}

.add_company_form .submit_but:hover{
    background: rgb(3, 113, 119) !important;
    opacity: .9;
}

/*
.input-group .form-control:last-child, .input-group-text:last-child{
    border: 1px solid #c4c3c3;
    border-left: 0 none;
    border-radius: 6px;
    height: 39px;
    padding: 0 16px !important;
}


.add_company_form .input-group>.form-control:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right: none;
}*/




/* .add_company_form .is-invalid{
    border-color: #dc3545 !important;
    background-image:none !important;
}

.add_company_form .form-control.is-invalid:focus, .was-validated .form-control:invalid:focus {
    border-color: #dc3545 !important;
    background-image:none !important;
}

.add_company_form .form-control.is-invalid, .was-validated .form-control:invalid {
    border-color: #dc3545 !important;
    background-image:none !important;
}

.add_company_form .form-control:focus{
    border: solid 0.5px #0f9ca1;
}
 */
.add_company_form .form-select{
    cursor: pointer;
}



input,select{
    box-shadow: none !important;
}
 input[type="checkbox"]{
     cursor: pointer;
}


.add_company_form .under_submit{
    display: flex;
    justify-content: space-between;
}

.add_company_form .under_submit a{
    text-decoration: none;
    font-size: 12pt;
}

.add_company_form .disabled_button{
    pointer-events: none;
    opacity: 0.4;
  }


  @media all and (max-width: 645px){

    .add_company_form{
        margin: 40px auto;
        width: 97%;
    }
    .add_company_form .form{
        padding: 0 10px !important;

    }

  }
.companies_list_container{
    width: 65%;
    margin: 25px auto;
}

.companies_list_container_header{
    font-size: 1.2rem;
    padding: 0 20px;
    padding-bottom: 10px;
    border-bottom: solid 1px black;
    width: 100%;
    margin: auto;
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
}

.companies_list_container_header .title_container{
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    display: flex;
    align-items: flex-end;
    align-content: flex-end;
}

.companies_list_container_header .title_container label{
    margin: 0 !important;
}

.companies_list_container .companies_list_table{
    width: 100% !important;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    margin: auto !important;
}

.companies_list_table .MuiTableCell-head {
    font-weight: 700 !important;
}

.companies_list_table .MuiTableCell-body:nth-child(4) {
    min-width:265px !important;
}



.subTypes_container{
    width:100%;
    height: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.subType_container{
    width:100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    display: flex;
    margin-bottom: 5px;
    align-items: center;
}

.subType{
    width:60%;
    height: 100%;
}

.subType_state{
    width:40%;
    height: 100%;
    display: contents;
}

.subType_sign_date_container{
    margin-bottom: 5px;
}

.subType_sign_date_container .MuiFab-circular{
    width: 0 !important;
}
.service_header{
    width: -webkit-max-content;
    width: max-content;
    padding:0;
    z-index:3;
    position:fixed;
    top: 0;
}


.menu_admin{
    width: 100%;
    display: flex;
    justify-content: flex-start;
}

.menu_admin #service_menu_holder{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: 12px;
    margin-right: .5rem;
    cursor: pointer;
    z-index: 1;
    position: absolute;
    top: 20px;
    -webkit-transform: translateX(5px);
            transform: translateX(5px);
    transition: all 400ms ease-in-out;
}

.menu_admin #service_menu_holder > div{
    width: 30px;
    height: 3.5px !important;
    border-radius: 4px;
    margin: 2.5px;
    background-color: rgb(252, 248, 248);
    transition: all 500ms ease-in-out;
    z-index: -1;
}


.menu_admin #service_menu_holder.open > div:nth-child(1){
    -webkit-transform: translateY(8px) rotateZ(45deg);
            transform: translateY(8px) rotateZ(45deg);
}
.menu_admin #service_menu_holder.open > div:nth-child(2){
    -webkit-transform: translateX(50px);
            transform: translateX(50px);
    opacity: 0;
}
.menu_admin #service_menu_holder.open > div:nth-child(3){
    -webkit-transform: translateY(-9.4px) rotateZ(-45deg);
            transform: translateY(-9.4px) rotateZ(-45deg);
}

.menu_admin .service_content {
    background: rgb(3, 113, 119) !important;
    height: 100vh;
    display: flex;
    flex-direction: column;
    transition: all 500ms;
    z-index: 3;
}


.menu_admin .service_content > div {
    display: flex;
    flex-direction: column;
}

.menu_admin .service_content .brand {
    cursor: pointer;
    display: block !important;
    width: -moz-fit-content !important;
    width: 59px !important;
    position: relative !important;
    left: -2px !important;
    margin-top: 3px;

}

.menu_admin .service_content.show {
    z-index: 3;
    width: 60px !important;
    position: fixed;
}


.menu_admin > div > div > a:hover{
    background-color: rgba(172, 171, 171, 0.1);
}


.menu_admin #drop1.show{
    height:65px !important;
}


.menu_admin .menu_items{
    margin-top: 5px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}

.menu_admin .menu_item{
    display: flex;
    color: white;
    text-transform: uppercase;
    width: 99%;
    justify-content: space-between;
    height: 2.4rem !important;
    align-items: center;
    align-content: center;
    padding: 0 5px 0 10px;
    cursor: pointer;
    transition: all 200ms;
}

.menu_admin .menu_item_selected{
    background-color:rgba(230, 218, 218, 0.292);
    transition: all 200ms;
}

.menu_admin .menu_item:hover{
    background-color:rgba(230, 218, 218, 0.292);
    transition: all 200ms;
}


.menu_admin .menu_item div:nth-child(2){
    width: 210px !important;
    padding-top: 2px !important;
}

.menu_admin .menu_item a{
    text-decoration:none;
	color: white;
	padding: 7px 10px;
	font-size: 10.5pt;
    font-weight: 400;
}

.menu_admin .menu_item .MuiSvgIcon-root{
    width: 2.3rem !important;
    height: 1.55rem !important;
}




.service_body{
    height: calc(100vh - 72px);
    width: calc(100vw - 60px);
    margin-top: 72px ;
    margin-left: 60px;
    overflow: auto;
}
.service_topnav .navbar{
    position: fixed;
    z-index: 2;
    display: flex;
    justify-content: flex-end;
    background-color: white !important;
    box-shadow: none;
    padding: 0;
}


.service_topnav .navbar a:not(.btn):not(.dropdown-item) {
    color: #040404;
}

.navbar_content_container{
    display: flex;
    width: calc(100% - 60px);
    background-color: #f7f7f7;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    border-bottom: solid .5px rgb(237, 237, 237);
}

.navbar_content_container .brand {
    cursor: pointer;
    display: block !important;
    width: -moz-fit-content !important;
    width: 340px;
    position: relative !important;
    height: 100%;
    margin-left: 10px;
    margin-bottom: 2px;
}


.navbar_content_container .selection {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
}
.add_auditor_form{
    margin: 20px auto;
    width: 60vw;
    justify-content: center;
}


.add_auditor_form .form{
    text-align: -webkit-right;
}

.add_auditor_form h2 {
    margin-bottom: 0px !important;
    padding: 0 20px;
}

.add_auditor_form label{
    width: 100%;
    text-align: left;
    margin: 0;
    font-size: 1rem;
    font-weight: 600;
    padding-left: 2px;
}

.error{
    width: 100%;
    font-size: 1.1em;
    color: #e13d68 !important;
    background: #dc35451f;
    padding: 10px 20px;
    border-radius: 5px;
    margin-bottom: 20px;
    text-align: start;
}



.add_auditor_form .row {
    margin: 14px 0;
}



.add_auditor_form .form-control {
    border: 1px solid #c4c3c3;
    border-radius: 6px;
    color: #2c2c2c;
    height: auto;
    font-size: .95rem;
    box-shadow: none;
    margin-bottom: 10px;
    height: 39px;
    background-image: none !important;
}

.add_auditor_form .submit_but{
    all: unset;
    background: rgb(3, 113, 119) !important;
    color: white;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    padding: .5rem 1.9rem;
    font-size: 1rem;
    border-radius: 5px;
    transition: -webkit-filter 300ms;
    transition: filter 300ms;
    transition: filter 300ms, -webkit-filter 300ms;
    cursor: pointer;
    margin-right: 12px;
}

.add_auditor_form .submit_but:hover{
    background: rgb(3, 113, 119) !important;
    opacity: .9;
}

/*
.input-group .form-control:last-child, .input-group-text:last-child{
    border: 1px solid #c4c3c3;
    border-left: 0 none;
    border-radius: 6px;
    height: 39px;
    padding: 0 16px !important;
}


.add_auditor_form .input-group>.form-control:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right: none;
}*/




/* .add_auditor_form .is-invalid{
    border-color: #dc3545 !important;
    background-image:none !important;
}

.add_auditor_form .form-control.is-invalid:focus, .was-validated .form-control:invalid:focus {
    border-color: #dc3545 !important;
    background-image:none !important;
}

.add_auditor_form .form-control.is-invalid, .was-validated .form-control:invalid {
    border-color: #dc3545 !important;
    background-image:none !important;
}

.add_auditor_form .form-control:focus{
    border: solid 0.5px #0f9ca1;
}
 */
.add_auditor_form .form-select{
    cursor: pointer;
}



input,select{
    box-shadow: none !important;
}
 input[type="checkbox"]{
     cursor: pointer;
}


.add_auditor_form .under_submit{
    display: flex;
    justify-content: space-between;
}

.add_auditor_form .under_submit a{
    text-decoration: none;
    font-size: 12pt;
}

.add_auditor_form .disabled_button{
    pointer-events: none;
    opacity: 0.4;
  }


  @media all and (max-width: 645px){

    .add_auditor_form{
        margin: 40px auto;
        width: 97%;
    }
    .add_auditor_form .form{
        padding: 0 10px !important;

    }

  }
.add_services_form{
    margin: 20px auto;
    width: 60vw;
    justify-content: center;
}


.add_services_form .form{
    text-align: -webkit-right;
}

.add_services_form h2 {
    margin-bottom: 0px !important;
    padding: 0 20px;
}

.add_services_form label{
    width: 100%;
    text-align: left;
    margin: 0;
    font-size: 1rem;
    font-weight: 600;
    padding-left: 2px;
}

.error{
    width: 100%;
    font-size: 1.1em;
    color: #e13d68 !important;
    background: #dc35451f;
    padding: 10px 20px;
    border-radius: 5px;
    margin-bottom: 20px;
    text-align: start;
}



.add_services_form .row {
    margin: 14px 0;
}



.add_services_form .form-control {
    border: 1px solid #c4c3c3;
    border-radius: 6px;
    color: #2c2c2c;
    height: auto;
    font-size: .95rem;
    box-shadow: none;
    margin-bottom: 10px;
    height: 39px;
    background-image: none !important;
}

.add_services_form .submit_but{
    all: unset;
    background: rgb(3, 113, 119) !important;
    color: white;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    padding: .5rem 1.9rem;
    font-size: 1rem;
    border-radius: 5px;
    transition: -webkit-filter 300ms;
    transition: filter 300ms;
    transition: filter 300ms, -webkit-filter 300ms;
    cursor: pointer;
    margin-right: 12px;
}

.add_services_form .submit_but:hover{
    background: rgb(3, 113, 119) !important;
    opacity: .9;
}

/*
.input-group .form-control:last-child, .input-group-text:last-child{
    border: 1px solid #c4c3c3;
    border-left: 0 none;
    border-radius: 6px;
    height: 39px;
    padding: 0 16px !important;
}


.add_services_form .input-group>.form-control:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right: none;
}*/




/* .add_services_form .is-invalid{
    border-color: #dc3545 !important;
    background-image:none !important;
}

.add_services_form .form-control.is-invalid:focus, .was-validated .form-control:invalid:focus {
    border-color: #dc3545 !important;
    background-image:none !important;
}

.add_services_form .form-control.is-invalid, .was-validated .form-control:invalid {
    border-color: #dc3545 !important;
    background-image:none !important;
}

.add_services_form .form-control:focus{
    border: solid 0.5px #0f9ca1;
}
 */
.add_services_form .form-select{
    cursor: pointer;
}



input,select{
    box-shadow: none !important;
}
 input[type="checkbox"]{
     cursor: pointer;
}


.add_services_form .under_submit{
    display: flex;
    justify-content: space-between;
}

.add_services_form .under_submit a{
    text-decoration: none;
    font-size: 12pt;
}

.add_services_form .disabled_button{
    pointer-events: none;
    opacity: 0.4;
  }


  @media all and (max-width: 645px){

    .add_services_form{
        margin: 40px auto;
        width: 97%;
    }
    .add_services_form .form{
        padding: 0 10px !important;

    }

  }
.categories_list_container{
    width: 80%;
    margin: 25px auto;
}

.categories_list_container_header{
    font-size: 1.2rem;
    padding: 0 20px;
    padding-bottom: 10px;
    border-bottom: solid 1px black;
    width: 100%;
    margin: auto;
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
}

.categories_list_container_header .title_container{
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    display: flex;
    align-items: flex-end;
    align-content: flex-end;
}

.categories_list_container_header .title_container label{
    margin: 0 !important;
}

.categories_list_container .categories_list_table{
    width: 700px !important;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    margin: auto !important;
}

.categories_list_table .MuiTableCell-head {
    font-weight: 700 !important;
}

.categories_list_table .MuiTableCell-body:nth-child(1) {
    min-width:300px !important;
}


.subTypes_container{
    width:100%;
    height: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.subType_container{
    width:100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    display: flex;
    margin-bottom: 5px;
    align-items: center;
}

.subType{
    width:60%;
    height: 100%;
}

.subType_state{
    width:40%;
    height: 100%;
    display: contents;
}

.subType_sign_date_container{
    margin-bottom: 5px;
}

.subType_sign_date_container .MuiFab-circular{
    width: 0 !important;
}


.title_container .submit_but{
    all: unset;
    background: rgb(3, 113, 119) !important;
    color: white;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    padding: .4rem 1rem;
    font-size: .9rem;
    border-radius: 5px;
    transition: -webkit-filter 300ms;
    transition: filter 300ms;
    transition: filter 300ms, -webkit-filter 300ms;
    cursor: pointer;
    margin-left: 16px;
}

.title_container .submit_but:hover{
    background: rgb(3, 113, 119) !important;
    opacity: .9;
}


.cp_admin_identity_container{
    width: 100%;
    height: 100%;
    padding-top: 30px;
}

.service_identity_infos_container{
    width: 95%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    margin: auto;
    padding: 15px 8px;
}

.service_identity_infos{
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    margin: auto;
    display:flex;
    justify-content: space-between;
    align-items: flex-start;
    align-content: flex-start;
    flex-wrap: wrap;
}

.service_identity_infos_container .company_infos_header{
    margin-top: 15px;
    margin-bottom: 24px;
    padding: 0 8px;
    font-size: 1.55rem;
    color: #343a40;
    font-weight: 600;
    position: relative;
}

.service_identity_infos_container .company_infos_header::after{
    content: '';
    position: absolute;
    padding: 0 8px;
    bottom: -2px;
    left: 8px;
    height: 2px;
    display: inline-block;
    background: rgb(86, 121, 121);
    width: 60px;
}

.cp_admin_identity_container .comapny_logo{
    flex: 1 1 30ch !important;
    height: 220px;
    max-width: 455px !important;
    min-width: 455px !important;
    margin: 0 7px 45px 7px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
}

.cp_admin_identity_container .comapny_logo .img_container{
    height: 100%;
    width: 220px;
    border-radius: 100%;
    border: solid 2px rgb(153, 153, 153);
    margin-right: 25px;
    position: relative;
    overflow: hidden;
    padding: 0;
}
.cp_admin_identity_container .comapny_logo div{
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.cp_admin_identity_container .comapny_logo img{
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    object-fit: cover;
    border: none;
}

.cp_admin_identity_container .change_color_container{
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    display: flex;
    align-items: center;
    border: solid 1px rgb(133, 133, 133);
    padding: .28rem;
    padding-right: .8rem;
    margin: 6px 5px;
    font-size: .93rem !important;
    border-radius: 4px;
    transition: -webkit-filter 300ms;
    transition: filter 300ms;
    transition: filter 300ms, -webkit-filter 300ms;
    cursor: pointer;
    align-items: center;
    text-align: center;
}   

.cp_admin_identity_container .change_color_container:hover{
    background-color: #f1f1f1;
    transition: -webkit-filter 300ms;
    transition: filter 300ms;
    transition: filter 300ms, -webkit-filter 300ms;
}   

.cp_admin_identity_container .color_container{
    display: inline-block;
    width: 21px;
    height: 21px;
    border: solid 1px rgb(133, 133, 133);
    border-radius: 4px;
    margin-right: 9px;
}



.identity_element{
    flex: 1 1 30ch !important;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    max-width: 455px !important;
    min-width: 455px !important;
    margin: 0 7px 45px 7px !important;
    display: flex;
    justify-content:space-between;
    align-content: center;
    align-items: center;
    align-self: flex-end;
}

.identity_element span:first-child{
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    font-size: .98rem !important;
    font-weight: 600;
}

.identity_element:last-child{
    flex: 1 1;
}

.identity_element .form-control {
    border: 1px solid #adacac;
    border-radius: 6px;
    background-color: white;
    color: #2c2c2c;
    height: auto;
    font-size: .93rem !important;
    box-shadow: none;
    width: 268px !important;
    height: 41px !important;
    background-image: none !important;
}

.identity_element .form-control:disabled, .form-control[readonly] {
    border: 1px solid #bababa !important;
    background-color: #f8f8f8 !important;
    opacity: .8 !important;
}

.service_identity_infos_config{
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    margin-top: 15px;
    display: flex;
    justify-content: flex-end;
}

.service_identity_infos_config div:first-child{
    width: 50%;
}



.cp_admin_identity_container .submit_but{
    all: unset;
    background:#343a40;
    color: white;
    padding: .39rem 1.2rem !important;
    padding-bottom: .42rem !important;
    margin: 6px 5px;
    font-size: .93rem !important;
    border-radius: 4px;
    transition: -webkit-filter 300ms;
    transition: filter 300ms;
    transition: filter 300ms, -webkit-filter 300ms;
    cursor: pointer;
    align-items: center;
    text-align: center;
    box-shadow: none;
    position: relative;
}

.cp_admin_identity_container .edit_but{
    background:#ffa726;
}

.cp_admin_identity_container .edit_but:hover{
    background:#ffa726;
    opacity: 0.9;
}

.cp_admin_identity_container .valide_but{
    background:#18ac67;
}

.cp_admin_identity_container .valide_but:hover{
    background:#18ac67;
    opacity: 0.9;
}

.color_palette_modal .dialogBottom{
    justify-content: flex-end;
}

.color_palette_modal .rcp{
    margin: 10px !important;
    display: inline-block;
}

.color_palette_container{
    width: 100%;
    display: flex;
    justify-content: space-between;
}
.service_header{
    width: -webkit-max-content;
    width: max-content;
    padding:0;
    z-index:3;
    position:fixed;
    top: 0;
}


.menu_company_admin{
    width: 100%;
    display: flex;
    justify-content: flex-start;
}

.menu_company_admin #service_menu_holder{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: 12px;
    margin-right: .5rem;
    cursor: pointer;
    z-index: 1;
    position: absolute;
    top: 20px;
    -webkit-transform: translateX(5px);
            transform: translateX(5px);
    transition: all 400ms ease-in-out;
}

.menu_company_admin #service_menu_holder > div{
    width: 30px;
    height: 3.5px !important;
    border-radius: 4px;
    margin: 2.5px;
    background-color: rgb(252, 248, 248);
    transition: all 500ms ease-in-out;
    z-index: -1;
}


.menu_company_admin #service_menu_holder.open > div:nth-child(1){
    -webkit-transform: translateY(8px) rotateZ(45deg);
            transform: translateY(8px) rotateZ(45deg);
}
.menu_company_admin #service_menu_holder.open > div:nth-child(2){
    -webkit-transform: translateX(50px);
            transform: translateX(50px);
    opacity: 0;
}
.menu_company_admin #service_menu_holder.open > div:nth-child(3){
    -webkit-transform: translateY(-9.4px) rotateZ(-45deg);
            transform: translateY(-9.4px) rotateZ(-45deg);
}

.menu_company_admin .service_content {
    height: 100vh;
    display: flex;
    flex-direction: column;
    transition: all 500ms;
    z-index: 3;
}


.menu_company_admin .service_content > div {
    display: flex;
    flex-direction: column;
}

.menu_company_admin .service_content .company_nav_logo_container {
    cursor: pointer;
    width: 100% !important;
    height: 60px !important;
    position: relative !important;
    left: -2px !important;
    margin-top: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
}


.menu_company_admin .service_content .company_nav_logo_container img {
    width: 52px;
    height: 100%;
    margin: 10% auto 0 auto;
    object-fit: scale-down;
}

.menu_company_admin .service_content .company_nav_logo_container .edit_container{
    position: absolute;
    right: 2px;
    top: -1px;
    width: 21px;
    height: 21px;
    border-radius: 100%;
    color: white !important;
    background: rgba(59, 59, 59, 0.75);
    display: flex;
    justify-content: center;
    cursor: pointer;
}

.menu_company_admin .service_content .company_nav_logo_container .edit_but{
    cursor: pointer;
    width: 0.67em !important;
    height: 0.67em !important;
}

.menu_company_admin .service_content .company_nav_logo_container .edit_container:hover{
    background: rgba(59, 59, 59, 0.96);
}

.menu_company_admin .service_content.show {
    z-index: 3;
    width: 60px !important;
    position: fixed;
}


.menu_company_admin > div > div > a:hover{
    background-color: rgba(172, 171, 171, 0.1);
}


.menu_company_admin #drop1.show{
    height:65px !important;
}


.menu_company_admin .menu_items{
    margin-top: 5px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}

.menu_company_admin .menu_item{
    display: flex;
    color: white;
    text-transform: uppercase;
    width: 99%;
    justify-content: space-between;
    height: 2.4rem !important;
    align-items: center;
    align-content: center;
    padding: 0 5px 0 10px;
    cursor: pointer;
    transition: all 200ms;
}

.menu_company_admin .menu_item_selected{
    background-color:rgba(230, 218, 218, 0.292);
    transition: all 200ms;
}

.menu_company_admin .menu_item:hover{
    background-color:rgba(230, 218, 218, 0.292);
    transition: all 200ms;
}


.menu_company_admin .menu_item div:nth-child(2){
    width: 210px !important;
    padding-top: 2px !important;
}

.menu_company_admin .menu_item a{
    text-decoration:none;
	color: white;
	padding: 7px 10px;
	font-size: 10.5pt;
    font-weight: 400;
}

.menu_company_admin .menu_item .MuiSvgIcon-root{
    width: 2.3rem !important;
    height: 1.55rem !important;
}




.service_body{
    height: calc(100vh - 72px);
    width: calc(100vw - 60px);
    margin-top: 72px ;
    margin-left: 60px;
    overflow: auto;
}
.service_topnav .navbar{
    position: fixed;
    z-index: 2;
    display: flex;
    justify-content: flex-end;
    background-color: white !important;
    box-shadow: none;
    padding: 0;
}


.service_topnav .navbar a:not(.btn):not(.dropdown-item) {
    color: #040404;
}

.navbar_content_container{
    display: flex;
    width: calc(100% - 60px);
    justify-content: space-between;
    align-items: center;
    align-content: center;
    border-bottom: solid .5px rgb(237, 237, 237);
}

.navbar_content_container .company_logo_container {
    cursor: pointer;
    height: 69px;
    width: -moz-fit-content !important;
    position: relative !important;
    margin-left: 10px;
    margin-bottom: 2px;
    padding: 2px 0;
    display: flex;
    align-items: center;
}

.navbar_content_container .company_logo_container img{
    height: 65px;
    object-fit: scale-down;
}

.navbar_content_container .company_logo_container .edit_container{
    position: absolute;
    right: -10px;
    top: 8px;
    width: 21px;
    height: 21px;
    border-radius: 100%;
    color: white !important;
    background: rgba(59, 59, 59, 0.75);
    padding: 0;
    display: flex;
    justify-content: center;
    cursor: pointer;
}


.navbar_content_container .company_logo_container .edit_but{
    cursor: pointer;
    width: 0.67em !important;
    height: 0.67em !important;
}

.navbar_content_container .company_logo_container .edit_container:hover{
    background: rgba(59, 59, 59, 0.96);
}


.navbar_content_container .selection {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
}
.services_list_container{
    width: 90%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    margin: 25px auto;
}

.services_list_container_header{
    font-size: 1.2rem;
    padding: 0 20px;
    padding-bottom: 10px;
    border-bottom: solid 1px black;
    width: 100%;
    margin: auto;
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
}

.services_list_container_header .title_container{
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    display: flex;
    align-items: flex-end;
    align-content: flex-end;
}

.services_list_container_header .title_container label{
    margin: 0 !important;
}

.services_list_container .services_list_table{
    width: 100% !important;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    margin: auto !important;
}

.services_list_table .MuiTableCell-head {
    font-weight: 700 !important;
}


.subTypes_container{
    width:100%;
    height: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.subType_container{
    width:100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    display: flex;
    margin-bottom: 5px;
    align-items: center;
}

.subType{
    width:60%;
    height: 100%;
}

.subType_state{
    width:40%;
    height: 100%;
    display: contents;
}

.subType_sign_date_container{
    margin-bottom: 5px;
}

.subType_sign_date_container .MuiFab-circular{
    width: 0 !important;
}

.auditors_list_container{
    width: 90%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    margin: 25px auto;
}

.auditors_list_container_header{
    font-size: 1.2rem;
    padding: 0 20px;
    padding-bottom: 10px;
    border-bottom: solid 1px black;
    width: 100%;
    margin: auto;
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
}

.auditors_list_container_header .title_container{
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    display: flex;
    align-items: flex-end;
    align-content: flex-end;
}

.auditors_list_container_header .title_container label{
    margin: 0 !important;
}

.auditors_list_container .auditors_list_table{
    width: 100% !important;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    margin: auto !important;
}

.auditors_list_table .MuiTableCell-head {
    font-weight: 700 !important;
}


.subTypes_container{
    width:100%;
    height: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.subType_container{
    width:100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    display: flex;
    margin-bottom: 5px;
    align-items: center;
}

.subType{
    width:60%;
    height: 100%;
}

.subType_state{
    width:40%;
    height: 100%;
    display: contents;
}

.subType_sign_date_container{
    margin-bottom: 5px;
}

.subType_sign_date_container .MuiFab-circular{
    width: 0 !important;
}
.contracts_list_container .contract_subtypes_cont{
    width: 100%;
    display: flex;
    justify-content: space-between;
}


.contracts_list_container .contract_subtypes_cont .contract_subtypes{
    display: flex;
    align-items: center;
}

.contracts_list_container{
    width: 80%;
    margin: 25px auto;
}

.contracts_list_container_header{
    font-size: 1.2rem;
    padding: 0 20px;
    padding-bottom: 10px;
    border-bottom: solid 1px black;
    width: 100%;
    margin: auto;
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
}

.contracts_list_container_header .title_container{
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    display: flex;
    align-items: flex-end;
    align-content: flex-end;
}

.contracts_list_container_header .title_container label{
    margin: 0 !important;
}

.contracts_list_container .contracts_list_table{
    width: 700px !important;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    margin: auto !important;
}

.contracts_list_table .MuiTableCell-head {
    font-weight: 700 !important;
}

.contracts_list_table .MuiTableCell-body:nth-child(1) {
    min-width:200px !important;
}


.contracts_list_table .documentsStatus_valide{
    width:115px;
    justify-content: flex-start;
}

.subTypes_container{
    width:100%;
    height: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.subType_container{
    width:100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    display: flex;
    margin-bottom: 5px;
    align-items: center;
}

.subType{
    width:60%;
    height: 100%;
}

.subType_state{
    width:40%;
    height: 100%;
    display: contents;
}

.subType_sign_date_container{
    margin-bottom: 5px;
}

.subType_sign_date_container .MuiFab-circular{
    width: 0 !important;
}


.title_container .submit_but{
    all: unset;
    background: rgb(3, 113, 119) !important;
    color: white;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    padding: .4rem 1rem;
    font-size: .9rem;
    border-radius: 5px;
    transition: -webkit-filter 300ms;
    transition: filter 300ms;
    transition: filter 300ms, -webkit-filter 300ms;
    cursor: pointer;
    margin-left: 16px;
}

.title_container .submit_but:hover{
    background: rgb(3, 113, 119) !important;
    opacity: .9;
}
.add_contract_modal_infos{
    width: 80%;
    margin: auto;
    display: flex;
    justify-content: space-between;
}


.add_contract_modal_infos .subType_input{
    width: 100%;
    display: flex;
}
.dndflow {
    flex-direction: column !important;
    display: flex;
    flex-grow: 1;
    height: 100%;
  }
  
  .dndflow aside {
    border-bottom: 1px solid rgb(109, 109, 109);
    font-size: 12px;
    background: #4a4a4a;
    width: 100%;
    position: relative !important;
  }

  
  .dndflow .reactflow-wrapper {
    flex-grow: 1;
    height: 100%;
    background: #fbfbfb;
  }
  
  .dndflow .selectall {
    margin-top: 10px;
  }
  
  @media screen and (min-width: 768px) {
    .dndflow {
      flex-direction: row;
    }
  
  }
  

.new_node_modal label{
    color:#888888;
    margin: 0;
    padding: 0 2px 2px 0 ;
}

.new_node_modal .inputform {
    border: 1px solid #c4c3c3;
    border-bottom-width: 1px !important;
    border-radius: 6px;
    color: #2c2c2c;
    height: auto;
    font-size: .95rem;
    box-shadow: none;
    height: 36px;
    background-image: none !important;
    background-color: white !important;
}

.new_node_modal textarea{
    max-width: 100% !important;
    max-height: none !important;
    padding: 7px 10px 7px 7px !important;
    resize: auto !important;
    font-size: .95rem;
    border: 1px solid #c4c3c3 !important;
    line-height: 1 !important;
    border-radius: 6px;
}

.dndflow .MuiInputLabel-shrink {
    -webkit-transform: translate(0, 5.5px) scale(0.75) !important;
            transform: translate(0, 5.5px) scale(0.75) !important;
    -webkit-transform-origin: top left;
            transform-origin: top left;
    color: #f9f3f3 !important;
}

.dndflow label + .MuiInput-formControl {
    margin-top: 16px;
    color: #f9f3f3 !important;
}

.dndflow .MuiSelect-select.MuiSelect-select {
    padding-right: 24px;
    color: #f9f3f3 !important;
    background-color: inherit !important;
}

.dndflow .MuiInput-underline:before {
    border: none !important;
}

.dndflow .MuiSelect-icon {
    color: #f9f3f3 !important;
}

.dndflow .MuiInputBase-root {
    font-family: var(--body-font) !important;
    border: solid 1px #f9f3f3 !important;
    padding-left: 6px !important;
    border-radius: 4px !important;
    background-color: inherit !important;
}

.dndflow .MuiSelect-nativeInput {
    left: 0;
    width: 100%;
    bottom: 0;
    opacity: 0;
    position: absolute;
    pointer-events: none;
}


.dndflow  .nodes_cont{
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    position: absolute;
    background: #4a4a4a;
    border-top: solid 1px rgb(208, 208, 208);
    top: 100%;
    padding: 3px 6px;
    z-index: 1500 !important;
}

.dndflow aside .description {
    margin-bottom: 10px;
    padding: 15px 10px;
}

.dndflow .dndnode {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    padding: 4px;
    border: 1px solid white;
    border-radius: 2px;
    margin: 6px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: -webkit-grab;
    cursor: grab;
    padding: 3px 6px;
    color:white;
    width: 45px;
    font-size: .7rem;
    text-align: center;
    cursor: pointer;
}

.dndflow .dndnode:hover {
    background-color: #656565;
}


.react-flow__node-default, .react-flow__node-group, .react-flow__node-input, .react-flow__node-output {
    padding: 6px 10px !important;
    width: -webkit-fit-content !important;
    width: -moz-fit-content !important;
    width: fit-content !important;
    min-width: 75px !important;
    border: 1px solid rgb(112, 112, 112) !important;
    border-radius: 4px !important;
    font-size: .7rem !important;
    background-color: #fefefe !important;
}

/* .dndflow .dndnode.input {
    border-color: #0041d0;
}

.dndflow .dndnode.output {
    border-color: #ff0072;
} */
.customNodeBody {
    min-width: 75px !important;
    padding: 6px 10px !important;
    border: 1px solid rgb(112, 112, 112) !important;
    background-color: #fefefe !important;
    position: relative;
    overflow: hidden;
    border-radius: 4px;
    font-size: .7rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .customNode_label {
    z-index: 1000;
    line-height: 1;
    border-radius: 4px;
    font-size: .7rem;
  }
  
  div.sourceHandle {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 0;
    -webkit-transform: none;
            transform: none;
    border: none;
    opacity: 0;
  }
  
  div.targetHandle {
    width: 100%;
    height: 100%;
    background: blue;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 0;
    -webkit-transform: none;
            transform: none;
    border: none;
    opacity: 0;
  }
  


.params_node_modal .MuiDialogTitle-root {
    padding: 14px 15px 7px 17px !important;
}

.params_node_modal label{
    color:#888888;
    margin: 0;
    padding: 0 2px 2px 0 ;
}

.params_node_modal .inputform {
    border: 1px solid #c4c3c3;
    border-bottom-width: 1px !important;
    border-radius: 6px;
    color: #2c2c2c;
    height: auto;
    font-size: .95rem;
    box-shadow: none;
    height: 36px;
    background-image: none !important;
    background-color: white !important;
}

.params_node_modal textarea{
    max-width: 100% !important;
    max-height: none !important;
    padding: 7px 10px 7px 7px !important;
    resize: auto !important;
    font-size: .95rem;
    border: 1px solid #c4c3c3 !important;
    line-height: 1 !important;
    border-radius: 6px;
}



.params_node_modal .accordion{
    margin-bottom: 15px;
}

.params_node_modal .accordion-button{
   font-size: 1rem;
   text-transform: capitalize !important;
   color: rgb(113, 113, 113) !important;
   padding: 3px 5px;
   min-height: 37px;
   height: -webkit-fit-content !important;
   height: -moz-fit-content !important;
   height: fit-content !important;
   background-color: rgb(255, 255, 255);
   border: none;
   border-bottom: solid 1px rgb(165, 165, 165);
   border-radius: 0 !important;
   font-family: inherit !important;
}

.params_node_modal .accordion_header_content{
   position: relative;
   display: flex;
   justify-content: space-between;
   align-items: center;
   flex-wrap: wrap;
   margin-right: 3px;
   line-height: 1rem;
}

.params_node_modal .accordion_header_actions{
   flex: 1 1;
   display: flex;
   justify-content: flex-end;
   align-items: center;
}



.params_node_modal .collapsed::after {
    background-image: url(https://cdn.iconscout.com/icon/free/png-256/top-arrow-25-1112318.png);
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform: rotate(-180deg);
            transform: rotate(-180deg);
}

.params_node_modal .accordion-button:not(.collapsed)::after {
    background-image: url(https://cdn.iconscout.com/icon/free/png-256/top-arrow-25-1112318.png);
    -webkit-transform: scale(1);
            transform: scale(1);
}

.params_node_modal .accordion-item{
    border: none !important;
}

.params_node_modal .accordion-button:focus {
    box-shadow:none !important;
    color:black;
}


.params_node_modal .accordion-body {
    padding: 5px 5px;
    margin: 0;
    color: rgb(135, 134, 134) !important;
    background-color:white !important;
}






.prm_permissions .form-control {
    border: 1px solid #c4c3c3 !important;
    border-radius: 6px;
    color: #2c2c2c;
    height: auto;
    font-size: .95rem;
    box-shadow: none;
    margin-bottom: 0 !important;
    height: 39px;
    background-image: none !important;
    background-color: white !important;
}

.prm_permissions .form-select-control {
    border-style: solid !important;
    border-width: 1px  !important;
    border-color: #c4c3c3 !important;
}

.prm_permissions .MuiInput-underline:after {
    border-bottom: none !important;
}


.prm_permissions .MuiInput-underline:before {
    border-bottom: none !important;
}

.prm_permissions .MuiSelect-select.MuiSelect-select{
    font-family: var(--body-font) !important;
    text-align: left !important;
  }

.prm_permissions .form-select{
    cursor: pointer;
}

.prm_permissions .form-select .MuiInputBase-input {
    background-color: white !important;
}

.prm_permissions  .Mui-focused {
    border: black;
}

.prm_permissions .MuiSelect-select {
   min-width: 100% !important;
}

input,select{
    box-shadow: none !important;
}
.property_list_table .inputform {
    border: solid 1px #d9d3d3;
    border-bottom-width: 1px !important;
    border-radius: 6px;
    color: rgb(0 0 0 / 75%) !important;
    height: auto;
    font-size: inherit;
    box-shadow: none;
    height: 30px;
    background-image: none !important;
    background-color: white !important;
}

.property_list_table .MuiTableCell-head {
    color: rgb(0 0 0 / 75%) !important;
    font-weight: 500 !important;
}

.property_list_table .MuiSelect-select.MuiSelect-select {
    padding-right: 24px;
    color: inherit !important;
    background-color: inherit !important;
}

.property_list_table .MuiInput-underline:before {
    border: none !important;
}

.property_list_table .MuiSelect-icon {
    color: inherit !important;
}

.property_list_table .MuiInputBase-root {
    font-family: var(--body-font) !important;
    width: 160px !important;
    border: none !important;
    padding-left: 0 !important;
    border-radius: 4px !important;
    background-color: inherit !important;
    color: rgb(0 0 0 / 75%) !important;
}

.property_list_table .MuiSelect-nativeInput {
    left: 0;
    width: 100%;
    bottom: 0;
    opacity: 0;
    position: absolute;
    pointer-events: none;
}
.docs_list_container .doc_subtypes_cont{
    width: 100%;
    display: flex;
    justify-content: space-between;
}


.docs_list_container .doc_subtypes_cont .doc_subtypes{
    display: flex;
    align-items: center;
}

.docs_list_container{
    width: 85%;
    margin: 25px auto;
}

.docs_list_container_header{
    font-size: 1.2rem;
    padding: 0 20px;
    padding-bottom: 10px;
    border-bottom: solid 1px black;
    width: 100%;
    margin: auto;
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
}

.docs_list_container_header .title_container{
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    display: flex;
    align-items: flex-end;
    align-content: flex-end;
}

.docs_list_container_header .title_container label{
    margin: 0 !important;
}

.docs_list_container .docs_list_table{
    width: 100% !important;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    margin: auto !important;
}

.docs_list_table .MuiTableCell-head {
    font-weight: 700 !important;
}

.docs_list_table .MuiTableCell-body:nth-child(1) {
    min-width:200px !important;
}


.docs_list_table .documentsStatus_valide{
    width:115px;
    justify-content: flex-start;
}

.subTypes_container{
    width:100%;
    height: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.subType_container{
    width:100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    display: flex;
    margin-bottom: 5px;
    align-items: center;
}

.subType{
    height: 100%;
}

.subType_state{
    height: 100%;
    display: contents;
}

.subType_sign_date_container{
    margin-bottom: 5px;
}

.subType_sign_date_container .MuiFab-circular{
    width: 0 !important;
}


.title_container .submit_but{
    all: unset;
    background: rgb(3, 113, 119) !important;
    color: white;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    padding: .4rem 1rem;
    font-size: .9rem;
    border-radius: 5px;
    transition: -webkit-filter 300ms;
    transition: filter 300ms;
    transition: filter 300ms, -webkit-filter 300ms;
    cursor: pointer;
    margin-left: 16px;
}

.title_container .submit_but:hover{
    background: rgb(3, 113, 119) !important;
    opacity: .9;
}


.params_node_modal .MuiDialogTitle-root {
    padding: 14px 15px 7px 17px !important;
}

.params_node_modal label{
    color:#888888;
    margin: 0;
    padding: 0 2px 2px 0 ;
}

.params_node_modal .inputform {
    border: 1px solid #c4c3c3;
    border-bottom-width: 1px !important;
    border-radius: 6px;
    color: #2c2c2c;
    height: auto;
    font-size: .95rem;
    box-shadow: none;
    height: 36px;
    background-image: none !important;
    background-color: white !important;
}

.params_node_modal textarea{
    max-width: 100% !important;
    max-height: none !important;
    padding: 7px 10px 7px 7px !important;
    resize: auto !important;
    font-size: .95rem;
    border: 1px solid #c4c3c3 !important;
    line-height: 1 !important;
    border-radius: 6px;
}



.params_node_modal .accordion{
    margin-bottom: 15px;
}

.params_node_modal .accordion-button{
   font-size: 1rem;
   text-transform: capitalize !important;
   color: rgb(113, 113, 113) !important;
   padding: 3px 5px;
   min-height: 37px;
   height: -webkit-fit-content !important;
   height: -moz-fit-content !important;
   height: fit-content !important;
   background-color: rgb(255, 255, 255);
   border: none;
   border-bottom: solid 1px rgb(165, 165, 165);
   border-radius: 0 !important;
   font-family: inherit !important;
}

.params_node_modal .accordion_header_content{
   position: relative;
   display: flex;
   justify-content: space-between;
   align-items: center;
   flex-wrap: wrap;
   margin-right: 3px;
   line-height: 1rem;
}

.params_node_modal .accordion_header_actions{
   flex: 1 1;
   display: flex;
   justify-content: flex-end;
   align-items: center;
}



.params_node_modal .collapsed::after {
    background-image: url(https://cdn.iconscout.com/icon/free/png-256/top-arrow-25-1112318.png);
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform: rotate(-180deg);
            transform: rotate(-180deg);
}

.params_node_modal .accordion-button:not(.collapsed)::after {
    background-image: url(https://cdn.iconscout.com/icon/free/png-256/top-arrow-25-1112318.png);
    -webkit-transform: scale(1);
            transform: scale(1);
}

.params_node_modal .accordion-item{
    border: none !important;
}

.params_node_modal .accordion-button:focus {
    box-shadow:none !important;
    color:black;
}


.params_node_modal .accordion-body {
    padding: 5px 5px;
    margin: 0;
    color: rgb(135, 134, 134) !important;
    background-color:white !important;
}




.docs_perms .form-control {
    border: 1px solid #c4c3c3 !important;
    border-radius: 6px;
    color: #2c2c2c;
    height: auto;
    font-size: .95rem;
    box-shadow: none;
    margin-bottom: 0 !important;
    height: 39px;
    background-image: none !important;
    background-color: white !important;
}

.docs_perms .form-select-control {
    border-style: solid !important;
    border-width: 1px  !important;
    border-color: #c4c3c3 !important;
}

.docs_perms .MuiInput-underline:after {
    border-bottom: none !important;
}


.docs_perms .MuiInput-underline:before {
    border-bottom: none !important;
}

.docs_perms .MuiSelect-select.MuiSelect-select{
    font-family: var(--body-font) !important;
    text-align: left !important;
  }

.docs_perms .form-select{
    cursor: pointer;
}

.docs_perms .form-select .MuiInputBase-input {
    background-color: white !important;
}

.docs_perms  .Mui-focused {
    border: black;
}

.docs_perms .MuiSelect-select {
   min-width: 100% !important;
}

input,select{
    box-shadow: none !important;
}

.docs_perms .multiselectContainer{
    font-family: var(--body-font) !important;
}

.docs_perms .searchBox{
    border: none !important;
    font-size: .95rem !important;
    color:#2c2c2c !important;
    font-family: var(--body-font) !important;
}

.docs_perms .inputField{
    color:#2c2c2c !important;
    font-family: var(--body-font) !important;
}


.docs_perms .searchWrapper {
    border: 1px solid #c4c3c3 !important;
    border-radius: 6px !important;
    padding: 0.3rem 0.65rem !important;
}

.docs_perms .chips{
    background: #338787 !important;
}

.docs_perms .chip {
    background: #338787 !important;
    border-radius: 15px !important;
    color: #ffffff !important;
    font-size: 0.85rem !important;
    margin-bottom: 0px !important;
    margin-right: 4px !important;
    font-family: var(--body-font) !important;
}

.docs_perms .optionListContainer {
    z-index: 1000 !important;
}

.docs_perms .option{
    color: #4c4c4e !important;
}

.docs_perms .option:hover{
    background: #e9ecee !important;
}

.docs_perms .highlightOption {
    background: #e9ecee !important;
}

.docs_perms .highlightOption:hover {
    background: #e9ecee !important;
}


.docs_perms .optionListContainer {
    top: auto !important;
    bottom: 100% !important;
}

