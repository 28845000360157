.add_company_form{
    margin: 20px auto;
    width: 60vw;
    justify-content: center;
}


.add_company_form .form{
    text-align: -webkit-right;
}

.add_company_form h2 {
    margin-bottom: 0px !important;
    padding: 0 20px;
}

.add_company_form label{
    width: 100%;
    text-align: left;
    margin: 0;
    font-size: 1rem;
    font-weight: 600;
    padding-left: 2px;
}

.error{
    width: 100%;
    font-size: 1.1em;
    color: #e13d68 !important;
    background: #dc35451f;
    padding: 10px 20px;
    border-radius: 5px;
    margin-bottom: 20px;
    text-align: start;
}



.add_company_form .row {
    margin: 14px 0;
}



.add_company_form .form-control {
    border: 1px solid #c4c3c3;
    border-radius: 6px;
    color: #2c2c2c;
    height: auto;
    font-size: .95rem;
    box-shadow: none;
    margin-bottom: 10px;
    height: 39px;
    background-image: none !important;
}

.add_company_form .submit_but{
    all: unset;
    background: rgb(3, 113, 119) !important;
    color: white;
    width: fit-content;
    height: fit-content;
    padding: .5rem 1.9rem;
    font-size: 1rem;
    border-radius: 5px;
    transition: filter 300ms;
    cursor: pointer;
    margin-right: 12px;
}

.add_company_form .submit_but:hover{
    background: rgb(3, 113, 119) !important;
    opacity: .9;
}

/*
.input-group .form-control:last-child, .input-group-text:last-child{
    border: 1px solid #c4c3c3;
    border-left: 0 none;
    border-radius: 6px;
    height: 39px;
    padding: 0 16px !important;
}


.add_company_form .input-group>.form-control:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right: none;
}*/




/* .add_company_form .is-invalid{
    border-color: #dc3545 !important;
    background-image:none !important;
}

.add_company_form .form-control.is-invalid:focus, .was-validated .form-control:invalid:focus {
    border-color: #dc3545 !important;
    background-image:none !important;
}

.add_company_form .form-control.is-invalid, .was-validated .form-control:invalid {
    border-color: #dc3545 !important;
    background-image:none !important;
}

.add_company_form .form-control:focus{
    border: solid 0.5px #0f9ca1;
}
 */
.add_company_form .form-select{
    cursor: pointer;
}



input,select{
    box-shadow: none !important;
}
 input[type="checkbox"]{
     cursor: pointer;
}


.add_company_form .under_submit{
    display: flex;
    justify-content: space-between;
}

.add_company_form .under_submit a{
    text-decoration: none;
    font-size: 12pt;
}

.add_company_form .disabled_button{
    pointer-events: none;
    opacity: 0.4;
  }


  @media all and (max-width: 645px){

    .add_company_form{
        margin: 40px auto;
        width: 97%;
    }
    .add_company_form .form{
        padding: 0 10px !important;

    }

  }