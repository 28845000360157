.customNodeBody {
    min-width: 75px !important;
    padding: 6px 10px !important;
    border: 1px solid rgb(112, 112, 112) !important;
    background-color: #fefefe !important;
    position: relative;
    overflow: hidden;
    border-radius: 4px;
    font-size: .7rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .customNode_label {
    z-index: 1000;
    line-height: 1;
    border-radius: 4px;
    font-size: .7rem;
  }
  
  div.sourceHandle {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 0;
    transform: none;
    border: none;
    opacity: 0;
  }
  
  div.targetHandle {
    width: 100%;
    height: 100%;
    background: blue;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 0;
    transform: none;
    border: none;
    opacity: 0;
  }
  