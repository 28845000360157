.config_widget_container{
    min-width: 70vw !important;
}


.config_widget_container .dialog_title_container{
    width: 100%;
    display: flex !important;
    justify-content: space-between !important;
    padding: 5px 10px !important;
    font-size: inherit !important;
}

.config_widget_container .dialog_title_container .dialog_title{
    width: fit-content !important;
    align-content: center;
    align-items: center;
    height: fit-content;
    color: rgb(80, 79, 79) !important;
    text-transform: uppercase;
    font-weight: 600;
    font-size: inherit !important;
}

.config_widget_container .dialog_title_container .dialog_icon{
    width: fit-content;
    align-content: center;
    align-items: center;
    height: fit-content;
    color: rgb(80, 79, 79) !important;
}

.remains_widgets_container{
    width: 100%;
    display:flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 7px;
    justify-content: center;
}

.remains_widgets_container .widget_container{
    flex: 1 1 35ch;
    max-width: 290px;
    max-height: none !important;
    box-shadow: 0px 0px 3px 1px #e0e0e0 !important;
    margin: 20px 10px;
    margin-top: 0;
    border-radius: 5px;
    display: flex;
    background-color: white;
    cursor: pointer;
    transition: all 150ms ease-in-out 0s;
    transform: scale(1);
}

.remains_widgets_container .widget_container:hover{
    transition: all 150ms ease-in-out 0s;
    background-color: rgb(248, 248, 248);
    transform: scale(1.05);
}

.remains_widgets_container .widget_icon{
    width: fit-content;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    padding: 10px 10px 10px 15px;
}

.remains_widgets_container .widget_icon .MuiSvgIcon-root {
    width: 38px !important;
    height: 38px !important;
    color: rgb(80, 79, 79) !important;
}

.remains_widgets_container .widget_title{
    flex: 1;
    height: 100%;
    font-weight: 600;
    padding: 15px 4px;
    padding-right: 10px;
    font-size: 1.08rem;
    color: rgb(80, 79, 79) !important;
    display: flex;
    align-items: center;
    align-content: center;
}


@media all and (max-width: 1100px){

    .remains_widgets_container .widget_container{
        flex: 1 1 28ch;
        margin: 13px 9px;
    }

    .remains_widgets_container .widget_icon{
        padding: 10px 10px 10px 15px;
    }
    
    .remains_widgets_container .widget_icon .MuiSvgIcon-root {
        width: 35px !important;
        height: 35px !important;
    }
    
    .remains_widgets_container .widget_title{
        padding: 15px 4px;
        padding-right: 10px;
        font-size: 1rem;
    }

}


@media all and (max-width: 800px){

    .remains_widgets_container .widget_container{
        flex: 1 1 22ch;
        margin: 10px 7px;
    }

    .remains_widgets_container .widget_icon{
        padding: 7px;
    }
    
    .remains_widgets_container .widget_icon .MuiSvgIcon-root {
        width: 32px !important;
        height: 32px !important;
    }
    
    .remains_widgets_container .widget_title{
        padding: 10px 3px;
        padding-right: 6px;
        font-size: .95rem;
    }

}


@media all and (max-width: 600px){

    .remains_widgets_container .widget_container{
        flex: 1 1 20ch;
        margin: 7px 5px;
        max-width: 235px !important;
    }

    .remains_widgets_container .widget_icon{
        padding: 7px;
    }
    
    .remains_widgets_container .widget_icon .MuiSvgIcon-root {
        width: 27px !important;
        height: 27px !important;
    }
    
    .remains_widgets_container .widget_title{
        padding: 7px 3px;
        padding-right: 6px;
        font-size: .9rem;
    }

}



@media all and (max-width: 430px){

    .remains_widgets_container .widget_container{
        flex: 1 1 18ch;
        margin: 5px 3px;
    }

    .remains_widgets_container .widget_icon{
        padding: 5px;
    }
    
    .remains_widgets_container .widget_icon .MuiSvgIcon-root {
        width: 22px !important;
        height: 22px !important;
    }
    
    .remains_widgets_container .widget_title{
        padding: 7px 3px;
        padding-right: 6px;
        font-size: .8rem;
    }

}