
.widgets_main_container{
    width: 100%;
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
    margin: 0; 
}


.parent {
    display: flex;
    flex-direction: column;
}

.childThatGrows{
    flex-grow: 1;        
    min-height: 0;       
    overflow: scroll;
 }


 .widgets_view_title{
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 12%;
    max-height: 100px;
    align-content: center;
    align-items: center;
}

.main_title{
    width: 50%;
    font-size: 2rem;
    font-weight: 600;
    color: #535353;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    padding: 20px;
    padding-left: 30px;
}

.widgets_configButton_container{
    height: fit-content;
    width: fit-content;
    text-align: end;
    padding-right: 15px;
    display: flex;
    align-items: center;
    align-content: center;
}


.month_year_switchercontainer{
    height: 50px;
    margin-right: 25px;
    border: solid 1px #484f57;
    border-radius: 40px;
    height: fit-content;
    pointer-events: none !important;
    position: relative !important;
}



.month_year_switchercontainer:hover{
    background-color: #484f5714 !important;
    color: white !important;
    transition: all 150ms ease-in-out 0s;
}

.month_year_switchercontainer .MuiInput-underline:before {
    border: none !important;
}

.month_year_switchercontainer .MuiSelect-icon {
    color: inherit !important;
    right: 4px !important;
}

.month_year_switchercontainer .active_select{
    background-color: #484f57 !important;
    color: white !important;
    pointer-events: fill !important;
}

.month_year_switchercontainer .inactive_select{
    pointer-events: fill !important;
}

.month_year_switchercontainer .MuiSelect-nativeInput {
    left: 0;
    width: 100%;
    bottom: 0;
    opacity: 0;
    position: absolute;
    pointer-events: none;
}

.month_year_switchercontainer .year_slct_form{
    width: 95px;
}

@media all and (min-width: 990px){


    .month_year_switchercontainer .MuiInputBase-root {
        font-family: var(--body-font) !important;
        border: none !important;
        padding: .35rem .5rem !important;
        border-radius: 40px !important;
        height: 35px !important;
    }


    .month_year_switchercontainer .MuiSelect-select.MuiSelect-select {
        color: inherit !important;
        font-size: .92rem !important;
        padding-top: 0.37rem !important;
        padding-bottom: 0.35rem !important;
        background-color: inherit !important;
    }
    
        
    .month_year_switchercontainer .selected_button{
        all: unset !important;
        background-color: #484f57 !important;
        color: white !important;
        border-radius: 40px !important;
        font-size: .95rem !important;
        padding: .38rem 1.25rem !important;
        transition: all 150ms ease-in-out 0s;
        box-shadow: none !important;
        position: relative !important;
    }
    
    
    .month_year_switchercontainer .unselected_button{
        all: unset !important;
        color: #484f57 !important;
        font-size: .95rem !important;
        border-radius: 40px !important;
        padding: .38rem 1.25rem !important;
        cursor: pointer !important;
        transition: all 150ms ease-in-out 0s;
        pointer-events: fill !important;
        box-shadow: none !important;
        position: relative !important;
    }


    .widgets_configButton{
        border: solid 1px #484f57;
        background:#484f57 !important;
        color: white !important;
        padding: 0 !important;
        transition: all 150ms ease-in-out 0s;
        cursor: pointer !important;
        align-items: center !important;
        text-align: center !important;
        margin-right: 10px !important;
    }


    .widgets_configButton:hover{
        background:#484f57ee !important;
    }

    .widgets_saveButton{
        border: solid 1px #3c7782;
        background:#3b5a7c !important;
        color: white !important;
        padding: 0 !important;
        transition: all 150ms ease-in-out 0s;
        cursor: pointer !important;
        align-items: center !important;
        text-align: center !important;
        margin-right: 10px !important;
    }


    .widgets_saveButton:hover{
        background:#3b5a7cee !important;
    }


    .widgets_configButton_container .disabled_button{
        pointer-events: none !important;
        opacity: 0.6 !important;
    }


}



.config_widget_container .MuiDialog-paperWidthLg {
    max-width: 85vw;
}

.MuiDialog-paperFullWidth {
    width: calc(100% - 64px);
    border-radius: 5px !important;
    padding-top: 5px !important;
}


.react-grid-item.cssTransforms {
    transition-property: -webkit-transform;
    transition-property: transform;
    transition-property: none !important;
    /* transition-property: transform, -webkit-transform; */
}

.widgets_contents_container{
    width: 100%;
    height:90%;
    transition: .2s linear;
    display:flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    flex-direction: row;
    flex-wrap: wrap;
    background-color: transparent;
    box-shadow: none !important;
    display: flex !important;
    flex-direction: column !important;
    background-color: rgb(250, 250, 250);
    overflow: auto;
    border-top: solid 3px rgb(237, 237, 237);
    border-bottom: solid .5px rgb(243, 243, 243);
 }


  .widget_container:after {
    content: "";
    display: block;
    position: absolute;
    bottom: -2rem;
    width: 100%;
    height: 3px;
    background-color: none;
  }

.react-grid-item.react-grid-placeholder {
    background:rgba(0, 0, 0, 0.3) !important;
  }


.widgets_contents_container .widget_container{
    margin: 0;
    height: fit-content;
    background: rgb(253, 253, 253) !important;
    box-shadow: none !important;
    box-shadow: 0px 0px 6px .8px #d5d5d5 !important;
    border-radius: 5px !important;
    display: flex !important;
    flex-direction: column !important;
    color: black !important;
}


.widgets_contents_container .item_title{
    padding: 9px 15px 8px 20px;
    font-size: 1rem;
    font-weight: 800;
    text-align: left;
    width: 100%;
    font-family: var(--body-font) !important;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: rgba(26, 26, 26, .8);
    border-bottom: solid 1px rgba(196, 196, 196,.5);
}

.widgets_contents_container .item_title div:nth-child(2){
    transition: all 150ms ease-in-out 0s;
    border-radius: 24px;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    transition: all 150ms ease-in-out 0s;
}

.widgets_contents_container .item_title div:nth-child(2):hover {
    background: rgba(0,0,0,0.04);
    transition: all 150ms ease-in-out 0s;
}

/* 
.react-grid-item > .react-resizable-handle.react-resizable-handle-se {
    opacity: 0 !important;
}

.react-grid-item > .react-resizable-handle.react-resizable-handle-sw {
    opacity: 0 !important;
} */

.widget_component_container{
    height:100% !important;
    overflow: auto;
    cursor: default !important;
}

.react-grid-item > .react-resizable-handle{
    background-image: none !important;
}
.react-grid-item > .react-resizable-handle::after {
    right: 8px !important;
    bottom: 8px !important;
    width: 9px !important;
    height: 9px !important;
    border-right: 3px solid rgb(83, 97, 110) !important;
    border-bottom: 3px solid rgb(83, 97, 110) !important;
}

/* .MuiPaper-root::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    background: transparent;
}
.MuiPaper-root::-webkit-scrollbar-thumb {
    background-color: rgb(123, 122, 122);
    border-radius: 3px;
}

.MuiPaper-root::-webkit-scrollbar-thumb:hover {
    background-color: rgba(141, 141, 141);
    cursor: pointer !important;
} */


.widgets_contents_container::-webkit-scrollbar {
    width: 9px;
    height: 9px;
    background: white;
    cursor: pointer !important;
}
.widgets_contents_container::-webkit-scrollbar-thumb {
    background-color: rgb(106, 102, 102);
    border-radius: 9px;
    cursor: pointer !important;
}

.widgets_contents_container::-webkit-scrollbar-thumb:hover {
    background-color: rgb(163, 159, 159);
    cursor: pointer !important;
}

.widget_component_container::-webkit-scrollbar {
    width: 7px;
    height: 7px;
    background: white;
    cursor: pointer !important;
}
.widget_component_container::-webkit-scrollbar-thumb {
    background-color: rgb(106, 102, 102);
    border-radius: 7px;
    cursor: pointer !important;
}

.widget_component_container::-webkit-scrollbar-thumb:hover {
    background-color: rgb(163, 159, 159);
    cursor: pointer !important;
}


.widgets_main_container .MuiTable-root {
    background: rgb(252, 252, 252) !important;
    color: white;
}

.widgets_main_container .MuiTableContainer-root {
    width: 100%;
    height: 100% !important;
    overflow-x: visible !important;
    border-radius: 0 0 6px 6px !important;
}

.widgets_main_container .MuiTableBody-root {
    display: table-row-group;
    background: rgb(252, 252, 252) !important;
    color: white !important;
}


.widgets_main_container .MuiTableCell-head {
    background: rgba(96, 119, 135, 0.85) !important;
    color: white !important;
    font-weight: 600 !important;
    line-height: .95rem !important;
    font-size: .8rem;
    background-color: rgb(248, 248, 248);
    padding: 12px 8px;
}
  
.widgets_main_container .MuiTableCell-body{
    background: rgb(252, 252, 252) !important;
    color: black !important;
    padding: 12px 8px;
    font-size: .8rem;
}


.widgets_main_container .header_form {
    width:160px;
    margin:0px !important;
}


.widgets_configButtons .MuiFab-root{
    width: 39px !important;
    height: 39px !important;
  }