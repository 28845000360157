

.request_details_container{
    width: 100vw;
    height: fit-content;
    overflow: auto;
    padding: 20px 10vw;
 }

.order_accordion_header{
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding-right: 24px;
}

.request_order_toolbar_main_container{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center !important;
    align-content: center !important;
}

.request_order_toolbar_left{
    width: fit-content;
    height: fit-content;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
}

.request_order_toolbar_right{
    flex: 1;
    display: flex;
    justify-content: flex-end;
}

.request_details_container .view_reason_button{
    width: fit-content;
    margin: auto;
    text-decoration: underline;
    margin-top: 2px;
    color: #38a1a5 !important;
    cursor: pointer !important;
    font-size: .9rem;
}

.request_details_container .request_state_container{
    display: flex;
    flex-direction: column;
    align-items: center !important;
    justify-content:center !important;
    align-content: center !important;
}


.notify_requester_button{
    background:rgb(228, 88, 32) !important;
    color:white !important;
    margin-left: 8px !important;
}

.notify_requester_button:hover{
    background: rgb(228, 88, 32) !important;
    opacity: .9;
}

 .request_details_container .accordion{
     margin-bottom: 15px;
 }

 .request_details_container .accordion-button{
    font-size: 1.4rem;
    font-weight: 600 !important;
    text-transform: capitalize !important;
    font-weight: 500;
    color: rgb(72, 72, 72) !important;
    padding: 9px 20px;
    min-height: 50px;
    height: fit-content !important;
    border-radius: 5px !important;
    background-color: rgb(255, 255, 255);
    border: solid 1px rgb(106, 106, 106);
    font-family: inherit !important;
}

.request_details_container .accordion_header_content{
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    margin-right: 3px;
    line-height: 1rem;
}

.request_details_container .accordion_header_actions{
    flex: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.request_details_container .accordion_header_content .supplier_infos {
    color: rgb(86, 121, 121);
    margin-left: 5px;
}

.request_details_container .accordion_header_content .supplier_infos span{
    position: relative;
    margin-bottom: 0 !important;
    bottom: 1px !important;
    margin-left: 8px;
}

.request_details_container .accordion_header_content .supplier_infos span:hover{
    opacity: .9;
}

.request_details_container .accordion_header_content .supplier_infos:first-child{
    color: rgb(86, 121, 121);
    font-size: inherit !important;
    margin-left: 6px;
    margin-bottom: 1px;
}


.request_details_container .order_state{
    position: relative;
    color: rgb(86, 121, 121);
    margin-right: 10px;
    display: flex;
    justify-content: center;
    align-items:flex-end;
}

.request_details_container .accordion_header_content span:first-child{
    position: relative;
}

.request_details_container .accordion_header_content span:first-child::before{
    content: '';
    position: absolute;
    bottom: -7px;
    left: 2px;
    height: 2px;
    display: inline-block;
    background: rgb(86, 121, 121);
    width: 60px;
}


.request_details_container .requester_details_container .accordion_header_content span:first-child::before{
    display: none;
}

.request_details_container .accordion_header_content .supplier_infos span::before{
    display: none;
}

.request_details_container div:first-child .accordion:first-child .accordion_header_content span:first-child::before{
    display: none;
}

.off_catalogue_requestDetails_container .supplier_accordion .accordion_header_content span:first-child::before{
    display: none;
}


.request_details_container .collapsed::after {
    background-image: url(https://cdn.iconscout.com/icon/free/png-256/top-arrow-25-1112318.png);
    transform: scale(1);
    transform: rotate(-180deg);
}

.request_details_container .accordion-button:not(.collapsed)::after {
    background-image: url(https://cdn.iconscout.com/icon/free/png-256/top-arrow-25-1112318.png);
    transform: scale(1);
}

.request_details_container .accordion-item{
    border: none !important;
}

.request_details_container .accordion-button:focus {
    box-shadow:none !important;
    color:black;
}


.request_details_container .accordion-body {
    padding: 0;
    margin: 0;
    color: rgb(64, 64, 65);
    background-color:#f9f9f9 !important;

}


.request_details_container div:nth-child(4) .accordion .accordion-button::before{
    background: rgb(245 128 115);
}

.request_details_container div:nth-child(2) .accordion .accordion-button::before{
    background: rgb(245 128 115);
}


.action_button_container {
    margin-right: 15px !important;
    height: -moz-fit-content;
    height: fit-content;
    display: flex;
}

.order_form_details_container{
    width: 80%;
    margin: 0 auto;
}

.order_form_details_container .order_form_infos_container{
    width: 100%;
    padding: 1em 2em;
    margin: 0;
}

.order_form_infos_container .row {
    margin: 5px 0 10px 0;
}


.order_form_infos_container .order_form_infos_title{
    padding: 0 15px;
    font-size: 1.1rem;
    font-weight: 600;
    color: #526f87;
    margin: 18px 0 7px 0;
}

.order_form_infos_container .row label {
    margin-bottom: 0.25rem !important;
}

.order_form_infos_container .form-control {
    border: 1px solid #c4c3c3;
    border-radius: 4px;
    color: #2c2c2c;
    height: auto;
    font-size: .9rem;
    box-shadow: none;
    margin-bottom: 12px;
    height: 39px;
    background-image: none !important;
}

.order_form_infos_container textarea {
    height: auto !important;
    padding: 8px 11px !important;
    box-shadow: none !important;
    border: 1px solid #c4c3c3 !important;
    color: #2c2c2c !important;
}

.order_form_page_buttons_container{
    width: 60%;
    height: fit-content;
    display: flex;
    justify-content: space-between;
    margin: 15px auto;
}

.rating_final_score_container{
    font-size: .9em !important;
    margin-left: 15px !important;
    text-decoration: none !important;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    cursor: pointer;
}

.rating_final_score_container span::before,.rating_final_score_container span::after{
    display: none !important;
}

.rating_final_score_container label{
    margin-bottom: 0 !important;
}

.rating_final_score{
    font-size: 0.8em !important;
}

.ratings_container_modal_content{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px 0;
}


.ratings_container_modal_content .rating_score_container{
    margin: 0 40px;
    display: flex;
    justify-content: space-between;
    justify-content: center;
}

.ratings_container_modal_content .rating_score_container label{
    margin-bottom: 0 !important;
}

.ratings_container_modal_content .rating_score_container div{
    margin: 0;
    margin-right: 10px;
    font-weight: 600;
    font-size: 1.2em;
    line-height: 1.1em;
}

.ratings_container_modal_content .rating_score_container .rating_score{
    margin-left: 3px;
    font-weight: 500;
    font-size: 1.1em;
}




@media all and (max-width: 1200px){

    .order_form_details_container {
        width: 86% !important;
    }
    .request_details_container{
        padding: 20px 7.5vw;
    }


}


@media all and (max-width: 1080px){
    

    .request_details_container{
        padding: 20px 7vw;
    }

    .request_details_container .accordion_header_content{
        font-size: 1.3rem;
    }

    .order_accordion_header{
        padding-right: 24px;
    }    

    .accordion-button::after {
        width: 1.1rem !important;
        height: 1.1rem !important;
        background-size: 1.1rem !important;
    }

    .order_form_details_container {
        width: 88% !important;
    }

    .order_form_infos_container .order_form_infos_title {
        font-size: 1.05rem !important;
        margin: 14px 0 7px 0 !important;
    }

    .order_form_infos_container .form-control {
        font-size: .9rem;
        margin-bottom: 12px;
        height: 37px !important;
    }

}




@media all and (max-width: 900px){

    .request_details_container{
        padding: 20px 4vw;
    }

    .request_details_container .accordion_header_content{
        font-size: 1.15rem;
    }

    .order_accordion_header{
        padding-right: 18px;
    }    

    .accordion-button::after {
        width: 1rem !important;
        height: 1rem !important;
        background-size: 1rem !important;
    }

    .order_form_details_container {
        width: 92% !important;
    }

    .order_form_infos_container .order_form_infos_title {
        font-size: 1rem !important;
        margin: 10px 0 7px 0 !important;
    }

    .request_details_container .accordion-button {
        padding: 9px 20px;
        padding-top: 10px;
    }

    .request_details_container .view_reason_button{
        font-size: .85rem;
    }

}



@media all and (max-width: 788px){
    .order_form_details_container {
        width: 95% !important;
    }

    .order_form_details_container .order_form_infos_container {
        padding: .8em 1.3em !important;
    }

    .order_form_infos_container .row label {
        font-size: .85rem !important;
    }

    .order_form_infos_container .form-control {
        font-size: .85rem !important;
        margin-bottom: 9px !important;
        height: 35px !important;
    }

    .request_details_container .accordion {
        margin-bottom: 10px;
    }
}




@media all and (max-width: 700px){

    .request_details_container{
        padding: 20px 2vw;
    }

    .request_details_container .accordion_header_content{
        font-size: 1.05rem;
    }

    .order_accordion_header{
        padding-right: 12px;
    }    

    .accordion-button::after {
        width: 1rem !important;
        height: 1rem !important;
        background-size: 1rem !important;
    }

    .order_form_details_container {
        width: 96% !important;
    }

    .order_form_infos_container .order_form_infos_title {
        font-size: 1rem !important;
        margin: 10px 0 7px 0 !important;
    }

    .request_details_container .accordion-button {
        padding: 9px 10px;
        padding-top: 8px;
    }

    .request_details_container .view_reason_button{
        font-size: .8rem;
    }

    .request_details_container .accordion_header_content .supplier_infos span {
        margin-left: 6px;
    }

    .order_form_details_container {
        width: 96% !important;
    }

    .order_form_infos_container .row label {
        margin-bottom: 0.2rem !important;
    }

    .order_form_infos_container .form-control {
        margin-bottom: 8px !important;
        height: 34px !important;
    }

    .order_form_infos_container .order_form_infos_title {
        font-size: 1rem !important;
        margin: 15px 0 7px 0 !important;
    }

    .request_details_container .accordion {
        margin-bottom: 8px;
    }
}


@media all and (max-width: 580px){
    .request_details_container{
        padding: 20px 1vw;
    }

    .request_order_toolbar_left .MuiIconButton-root {
        padding: 10px 8px 4px 8px;
    }

    .request_order_toolbar_left{
        align-items: flex-end !important;
    }

    .request_order_toolbar_right{
        flex: 1;
        display: flex;
        justify-content: flex-end;
        margin: 3px 0;
    }

    
    .request_details_container .accordion-button{
        padding: 8px 8px;
        min-height: 40px;
    }

    .request_details_container .accordion_header_content{
        font-size: .9rem;
    }

    .order_accordion_header{
        padding-right: 8px;
    }    

    .accordion-button::after {
        width: .9rem !important;
        height: .9rem !important;
        background-size: .9rem !important;
    }

    .order_form_details_container {
        width: 100% !important;
    }

    .order_form_infos_container .row label {
        font-size: .8rem !important;
        margin-bottom: 0.2rem !important;
    }

    .order_form_details_container .order_form_infos_container {
        padding: .5em 0em !important;
    }

    .order_form_infos_container .form-control {
        font-size: .8rem !important;
        margin-bottom: 9px !important;
        height: 32px !important;
    }

    .order_form_infos_container .order_form_infos_title {
        font-size: .95rem !important;
        margin: 15px 0 7px 0 !important;
    }

    .request_details_container .accordion {
        margin-bottom: 7px;
    }
}

@media all and (max-width: 497px){

    .request_details_container .accordion_header_content{
        font-size: .85rem;
    }

    .request_details_container .accordion_header_content span:first-child::before{
        display: none;
    }

    .request_details_container .accordion_header_content .supplier_infos {
        margin-left: 5px;
        margin-top: 1px;
    }

    .accordion-button::after {
        width: .8rem !important;
        height: .8rem !important;
        background-size: .8rem !important;
    }

    .request_details_container .view_reason_button{
        font-size: .7rem;
    }

    .order_form_infos_container .row label {
        font-size: .75rem !important;
        margin-bottom: 0.1rem !important;
    }

    .order_form_infos_container .form-control {
        font-size: .75rem !important;
        margin-bottom: 5px !important;
        height: 28px !important;
    }

    .order_form_infos_container .order_form_infos_title {
        font-size: .9rem !important;
        margin: 10px 0 7px 0 !important;
        padding: 0 0.3rem !important;
    }

    .order_form_infos_container .row>* {
        padding: 0 0.3rem !important;
    }

    .action_button_container {
        margin-right: 10px !important;
        height: -moz-fit-content;
        height: fit-content;
        display: flex;
    }
}


@media (max-width: 379px){
    .my_full_dialog .page_title {
        height: fit-content !important;
        margin-top: 4px !important;
        margin-bottom: 0 !important;
    }
}
@media all and (max-width: 370px){

    .request_details_container .accordion_header_content span:first-child::before{
        display: none;
    }

    .request_details_container .accordion_header_content .supplier_infos {
        margin-left: 0px;
    }

    .order_form_details_container .order_form_infos_container {
        padding: .5em 1em !important;
    }

    .col-6{
        width: 100% !important;
    }

    .request_order_toolbar_left .MuiIconButton-root {
        padding: 8px 8px;
    }

    .request_order_toolbar_left{
        align-items: center !important;
    }
}