.card_container_modal{
    width: 100%;
    flex:1;
    display: flex;
    flex-direction: column;
}

.card_container_modal .card_products_table_container{
    width: 94% !important;
    height: 100%;
    margin: 25px auto;
}

.card_container_modal .proceed_button_container{
    width: 94% !important;
    margin: auto;
    display: flex;
    justify-content: flex-end; 
}

.card_container_modal .proceed_button_container .submit_but{
    background:rgb(228, 88, 32);
    color: white;
}

.card_container_modal .proceed_button_container .submit_but:hover{
    background: rgb(228, 88, 32);
    opacity: .9;
}

.card_products_table_container .MuiTableCell-head {
    background: rgb(69 81 83) !important;
    color: white !important;
    padding: 9px 10px;
    font-size: 1rem;
}
  


.card_products_table_container .product_cell_img_container{
    width: 115px;
    height: 120px;
    display: flex;
    border: solid 1px rgb(194, 194, 194);
}

.card_products_table_container .product_cell_img_container img{
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    object-fit: cover !important;
    border: none;
}

.card_products_table_container .product_cell_title_container{
    width: 230px;
    margin-left: 20px;
    font-size: 1.1rem;
    font-weight: 700;
    color: rgb(57, 57, 57);
}

.card_products_table_container .product_cell_title_container #title{
    text-transform: capitalize;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}

.card_products_table_container .product_cell_title_container .product_cell_categorie_container{
    width: 100%;
    font-size: .85rem;
    font-weight: 500;
    text-transform: capitalize;
    color: rgb(59, 91, 91);
}

.card_products_table_container .product_cell_title_container .product_cell_categorie_container span{
    font-weight: 700;
}

.card_products_table_container .amount_container {
    border-radius: 5px;
    width: 100px;
    height: fit-content;
    padding: 6px 9px;
    font-size: inherit;
}


.card_products_table_container input[type=number]::-webkit-inner-spin-button, .product_details_infos_container
input[type=number]::-webkit-outer-spin-button {  
   opacity: 1;
}

.card_container_modal .card_products_table_container .MuiTableCell-body:nth-child(1) {
    min-width:130px !important;
    font-weight: 600;
}

.card_container_modal .card_products_table_container .MuiTableCell-body:nth-child(2) {
    min-width:400px !important;
}

.card_container_modal .card_products_table_container .MuiTableCell-body:nth-child(3) {
    min-width:100px !important;
}

.card_container_modal .card_products_table_container .MuiTableCell-body:nth-child(4) {
    min-width:70px !important;
}

.card_container_modal .card_products_table_container .MuiTableCell-body:nth-child(5) {
    min-width:100px !important;
}

.card_container_modal .card_products_table_container .MuiTableCell-body:nth-child(6) {
    min-width:55px !important;
}





@media all and (max-width: 1100px){
    .card_products_table_container .amount_container {
        width: 80px !important;
    }
    .card_products_table_container .product_cell_img_container {
        width: 92px;
        height: 96px;
    }
    .card_products_table_container .product_cell_title_container {
        flex: 1;
        margin-left: 17px;
        font-size: 1rem;
    }
    .card_products_table_container .product_cell_title_container .product_cell_categorie_container {
        font-size: .82rem;
    }

    .card_container_modal .card_products_table_container .MuiTableCell-body:nth-child(2) {
        min-width:385px !important;
    }
}


@media all and (max-width: 900px){
    .card_products_table_container .amount_container {
        width: 75px !important;
    }
    .card_products_table_container .product_cell_img_container {
        width: 91px;
        height: 95px;
    }
    .card_products_table_container .product_cell_title_container {
        flex: 1;
        margin-left: 14px;
    }

    .card_container_modal .card_products_table_container .MuiTableCell-body:nth-child(2) {
        min-width:370px !important;
    }

    .card_container_modal .card_products_table_container{
        width: 96% !important;
        margin: 25px auto;
    }

    .card_container_modal .proceed_button_container{
        width: 96% !important;
    }
}

@media all and (max-width: 700px){
    .card_products_table_container .product_cell_title_container {
        margin-left: 12px;
        font-size: .9rem;
    }
    .card_products_table_container .product_cell_title_container .product_cell_categorie_container {
        font-size: .75rem;
    }

    .card_container_modal .card_products_table_container .MuiTableCell-body:nth-child(2) {
        min-width:365px !important;
    }

    .card_container_modal .card_products_table_container{
        width: 98% !important;
        margin: 20px auto;
    }

    .card_container_modal .proceed_button_container{
        width: 98% !important;
    }
}

@media all and (max-width: 600px){

    .card_products_table_container .product_cell_img_container {
        width: 79px;
        height: 83px;
    }
    .card_products_table_container .amount_container {
        width: 60px !important;
        padding: 4px 8px !important;
    }
    .card_products_table_container .product_cell_title_container {
        margin-left: 9px;
        font-size: .82rem;
    }
    .card_products_table_container .product_cell_title_container .product_cell_categorie_container {
        font-size: .7rem;
    }

}



@media all and (max-width: 450px){

    .card_products_table_container .product_cell_img_container {
        width: 69px;
        height: 73px;
    }

    .card_products_table_container .amount_container {
        width: 50px !important;
        padding: 3.5px 4px !important;
    }
    .card_products_table_container .product_cell_title_container {
        margin-left: 6px;
        font-size: .8rem;
    }
    .card_products_table_container .product_cell_title_container .product_cell_categorie_container {
        font-size: .65rem;
    }
    .card_container_modal .card_products_table_container .MuiTableCell-body:nth-child(2) {
        min-width:330px !important;
    }

}


@media all and (max-width: 380px){

    .card_products_table_container .product_cell_img_container {
        width: 65px;
        height: 69px;
    }

    .card_products_table_container .product_cell_title_container {
        margin-left: 6px;
        font-size: .76rem;
    }
    .card_products_table_container .product_cell_title_container .product_cell_categorie_container {
        font-size: .6rem;
    }

    .card_container_modal .card_products_table_container .MuiTableCell-body:nth-child(2) {
        min-width:320px !important;
    }

}
