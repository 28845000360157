
.order_form_page_container{
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    padding: 20px;
}

.order_form_container{
    width: 60%;
    height: fit-content;
    margin: auto;
    border: solid 1px rgb(215, 212, 212);
    background-color: #fdfdfd;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    padding: 5px;
    padding-top: 10px;
}

.order_form_title{
    width: fit-content;
    height: fit-content;
    font-size: 1.4rem;
    font-weight: 700;
    color: rgb(228, 88, 32);
    position: relative;
    padding: 7px 15px;
    padding-bottom: 3px;
}

.order_form_title::after{
    content: '';
    height: 3px;
    width: 45%;
    background-color: rgb(228, 88, 32);
    position: absolute;
    bottom: -2px;
    left: 16px;
}

.order_form_infos_container{
    width: 100%;
    margin-top: 14px;
}

.order_form_infos_container .row {
    margin: 5px 0 10px 0;
}


.order_form_infos_container .order_form_infos_title{
    padding: 0 15px;
    font-size: 1.1rem;
    font-weight: 600;
    color: #526f87;
    margin: 18px 0 7px 0;
}

.order_form_infos_container .row label {
    margin-bottom: 0.25rem !important;
}

.order_form_infos_container .form-control {
    border: 1px solid #c4c3c3;
    border-radius: 4px;
    color: #2c2c2c;
    height: auto;
    font-size: .9rem;
    box-shadow: none;
    margin-bottom: 12px;
    height: 39px;
    background-image: none !important;
}



.order_form_page_container .order_form_infos_container textarea {
    height: auto !important;
    padding: 8px 11px !important;
    box-shadow: none !important;
    border: 1px solid #c4c3c3 !important;
    color: #2c2c2c !important;
}

.order_form_page_buttons_container{
    width: 60%;
    height: fit-content;
    display: flex;
    justify-content: space-between;
    margin: 15px auto;
}


.order_form_page_buttons_container .submit_but{
    all: unset;
    background:rgb(228, 88, 32);
    color: white;
    width: fit-content;
    height: fit-content;
    padding: .5rem 1.5rem;
    font-size: .9rem;
    border-radius: 5px;
    transition: filter 300ms;
    cursor: pointer;
}

.order_form_page_buttons_container .submit_but:hover{
    background: rgb(228, 88, 32);
    opacity: .9;
}


.order_form_page_buttons_container .return_button{
    background:rgb(228, 32, 74);
}

.order_form_page_buttons_container .return_button:hover{
    background:rgb(228, 32, 74);
    opacity: .9;
}


@media all and (max-width: 1170px){
    .order_form_container{
        width: 70%;
    }

    .order_form_page_container .order_form_page_buttons_container{
        width: 70%;
    }

    .order_form_title{
        font-size: 1.32rem;
    }
    
}

@media all and (max-width: 900px){
    .order_form_container{
        width: 80%;
    }

    .order_form_page_container .order_form_page_buttons_container{
        width: 80%;
    }

    .order_form_page_container .order_form_title{
        font-size: 1.25rem;
    }

    .order_form_page_container .order_form_title::after{
        height: 2.5px;
        width: 38%;
    }

}

@media all and (max-width: 750px){
    .order_form_container{
        width: 90%;
    }

    .order_form_page_container .order_form_page_buttons_container{
        width: 90%;
    }

    .order_form_page_container .order_form_title{
        font-size: 1.2rem;
    }

    .order_form_page_container .order_form_title::after{
        height: 2px;
        width: 32%;
    }
}


@media all and (max-width: 550px){
    .order_form_container{
        width: 94%;
    }

    .order_form_page_container .order_form_page_buttons_container{
        width: 94%;
    }

    .order_form_page_container{
        padding: 10px;
    }

    .order_form_title{
        font-size: 1.15rem;
    }

}

@media all and (max-width: 379px){
    .order_form_container{
        width: 99%;
    }

    .order_form_page_container .order_form_page_buttons_container{
        width: 99%;
    }

    .order_form_page_container{
        padding: 5px;
    }

    .order_form_title{
        font-size: 1.05rem;
        padding: 5px 6px;
        padding-bottom: 1px;
    }
    
    .order_form_page_container .order_form_title::after{
        left: 7px;
    }

}