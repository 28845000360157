



.supplier_offers .my_offers_container_header{
    display: flex;
    justify-content:flex-end;
    width: 100%;
    padding: 30px 40px;
}



.gridOfAppelFourn{
    width: 95% !important;
    margin: auto !important;
}

.main_card_container .but_color:hover{
    background:#343a40 !important;
    opacity: .9 !important;
}
  