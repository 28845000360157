.notify_auditor_again_dialog .dialogBottom {
    width: fit-content;
    display: flex;
    justify-content: space-between;
    padding: 5px;
    margin: 0;
}
.notify_auditor_again_dialog .dialogBottom div:nth-child(1) {
    width: 100%;
    text-align: -webkit-right;
    padding-top: 3px;
}