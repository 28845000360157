.dropzone{
    text-align: center;
    border: 2px rgb(151, 151, 151) dashed;
    background-color: rgb(252, 252, 252);
    width: 73%;
    border-radius: 8px;
    margin: 25px auto;
    padding: 5px;
    height: 220px;
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .dropzone_error{
    border: 2px rgb(216, 112, 112) dashed;
    color: rgb(192, 44, 44);
    background-color: rgb(254, 242, 242);
  }


.dropzone p{
    font-weight: 300;
    font-size: 1.2em;
}

.upload_button{
  background:#3b5a7c !important;
  color: white !important;
}


.upload_button:hover{
  background:#4b698a !important;
}


@media (max-width: 600px){

  .dropzone{
    border: 1.5px rgb(151, 151, 151) dashed;
    width: 88%;
    padding: 5px;
    margin: 20px auto;
    height: 140px;
}

  .dropzone p{
    font-weight: 300;
    font-size: .9rem !important;
}


}