
.cp_admin_identity_container{
    width: 100%;
    height: 100%;
    padding-top: 30px;
}

.service_identity_infos_container{
    width: 95%;
    height: fit-content;
    margin: auto;
    padding: 15px 8px;
}

.service_identity_infos{
    width: fit-content;
    height: fit-content;
    margin: auto;
    display:flex;
    justify-content: space-between;
    align-items: flex-start;
    align-content: flex-start;
    flex-wrap: wrap;
}

.service_identity_infos_container .company_infos_header{
    margin-top: 15px;
    margin-bottom: 24px;
    padding: 0 8px;
    font-size: 1.55rem;
    color: #343a40;
    font-weight: 600;
    position: relative;
}

.service_identity_infos_container .company_infos_header::after{
    content: '';
    position: absolute;
    padding: 0 8px;
    bottom: -2px;
    left: 8px;
    height: 2px;
    display: inline-block;
    background: rgb(86, 121, 121);
    width: 60px;
}

.cp_admin_identity_container .comapny_logo{
    flex: 1 1 30ch !important;
    height: 220px;
    max-width: 455px !important;
    min-width: 455px !important;
    margin: 0 7px 45px 7px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
}

.cp_admin_identity_container .comapny_logo .img_container{
    height: 100%;
    width: 220px;
    border-radius: 100%;
    border: solid 2px rgb(153, 153, 153);
    margin-right: 25px;
    position: relative;
    overflow: hidden;
    padding: 0;
}
.cp_admin_identity_container .comapny_logo div{
    height: fit-content;
    width: fit-content;
}

.cp_admin_identity_container .comapny_logo img{
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    object-fit: cover;
    border: none;
}

.cp_admin_identity_container .change_color_container{
    width: fit-content;
    height: fit-content;
    display: flex;
    align-items: center;
    border: solid 1px rgb(133, 133, 133);
    padding: .28rem;
    padding-right: .8rem;
    margin: 6px 5px;
    font-size: .93rem !important;
    border-radius: 4px;
    transition: filter 300ms;
    cursor: pointer;
    align-items: center;
    text-align: center;
}   

.cp_admin_identity_container .change_color_container:hover{
    background-color: #f1f1f1;
    transition: filter 300ms;
}   

.cp_admin_identity_container .color_container{
    display: inline-block;
    width: 21px;
    height: 21px;
    border: solid 1px rgb(133, 133, 133);
    border-radius: 4px;
    margin-right: 9px;
}



.identity_element{
    flex: 1 1 30ch !important;
    height: fit-content;
    max-width: 455px !important;
    min-width: 455px !important;
    margin: 0 7px 45px 7px !important;
    display: flex;
    justify-content:space-between;
    align-content: center;
    align-items: center;
    align-self: flex-end;
}

.identity_element span:first-child{
    height: fit-content;
    font-size: .98rem !important;
    font-weight: 600;
}

.identity_element:last-child{
    flex: 1;
}

.identity_element .form-control {
    border: 1px solid #adacac;
    border-radius: 6px;
    background-color: white;
    color: #2c2c2c;
    height: auto;
    font-size: .93rem !important;
    box-shadow: none;
    width: 268px !important;
    height: 41px !important;
    background-image: none !important;
}

.identity_element .form-control:disabled, .form-control[readonly] {
    border: 1px solid #bababa !important;
    background-color: #f8f8f8 !important;
    opacity: .8 !important;
}

.service_identity_infos_config{
    width: 100%;
    height: fit-content;
    margin-top: 15px;
    display: flex;
    justify-content: flex-end;
}

.service_identity_infos_config div:first-child{
    width: 50%;
}



.cp_admin_identity_container .submit_but{
    all: unset;
    background:#343a40;
    color: white;
    padding: .39rem 1.2rem !important;
    padding-bottom: .42rem !important;
    margin: 6px 5px;
    font-size: .93rem !important;
    border-radius: 4px;
    transition: filter 300ms;
    cursor: pointer;
    align-items: center;
    text-align: center;
    box-shadow: none;
    position: relative;
}

.cp_admin_identity_container .edit_but{
    background:#ffa726;
}

.cp_admin_identity_container .edit_but:hover{
    background:#ffa726;
    opacity: 0.9;
}

.cp_admin_identity_container .valide_but{
    background:#18ac67;
}

.cp_admin_identity_container .valide_but:hover{
    background:#18ac67;
    opacity: 0.9;
}
