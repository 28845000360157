.contracts_list_container .contract_subtypes_cont{
    width: 100%;
    display: flex;
    justify-content: space-between;
}


.contracts_list_container .contract_subtypes_cont .contract_subtypes{
    display: flex;
    align-items: center;
}

.contracts_list_container{
    width: 80%;
    margin: 25px auto;
}

.contracts_list_container_header{
    font-size: 1.2rem;
    padding: 0 20px;
    padding-bottom: 10px;
    border-bottom: solid 1px black;
    width: 100%;
    margin: auto;
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
}

.contracts_list_container_header .title_container{
    width: fit-content;
    display: flex;
    align-items: flex-end;
    align-content: flex-end;
}

.contracts_list_container_header .title_container label{
    margin: 0 !important;
}

.contracts_list_container .contracts_list_table{
    width: 700px !important;
    height: fit-content;
    margin: auto !important;
}

.contracts_list_table .MuiTableCell-head {
    font-weight: 700 !important;
}

.contracts_list_table .MuiTableCell-body:nth-child(1) {
    min-width:200px !important;
}


.contracts_list_table .documentsStatus_valide{
    width:115px;
    justify-content: flex-start;
}

.subTypes_container{
    width:100%;
    height: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.subType_container{
    width:100%;
    height: fit-content;
    display: flex;
    margin-bottom: 5px;
    align-items: center;
}

.subType{
    width:60%;
    height: 100%;
}

.subType_state{
    width:40%;
    height: 100%;
    display: contents;
}

.subType_sign_date_container{
    margin-bottom: 5px;
}

.subType_sign_date_container .MuiFab-circular{
    width: 0 !important;
}


.title_container .submit_but{
    all: unset;
    background: rgb(3, 113, 119) !important;
    color: white;
    width: fit-content;
    height: fit-content;
    padding: .4rem 1rem;
    font-size: .9rem;
    border-radius: 5px;
    transition: filter 300ms;
    cursor: pointer;
    margin-left: 16px;
}

.title_container .submit_but:hover{
    background: rgb(3, 113, 119) !important;
    opacity: .9;
}