.dialogSuppliersBottom .submit_but{
    all: unset;
    background:#343a40;
    color: white;
    padding: .45rem 1.2rem;
    padding-top: .48rem;
    margin: 4px 5px;
    font-size: 1rem;
    border-radius: 4px;
    transition: filter 300ms;
    cursor: pointer;
    align-items: center;
    text-align: center;
    box-shadow: none;
}

.dialogSuppliersBottom .cancel_but{
    background:#e92a44;
}

.dialogSuppliersBottom .cancel_but:hover{
    background:#e92a44;
    opacity: 0.9;
}

.dialogSuppliersBottom .valide_but{
    background:#18ac67;
}

.dialogSuppliersBottom .valide_but:hover{
    background:#18ac67;
    opacity: 0.9;
}

.multi_form_select{
    width: 320px !important;
}


@media all and (max-width: 750px){
    .multi_form_select{
        width: 280px !important;
    }
}

@media all and (max-width: 549px){
    .multi_form_select{
        width: 220px !important;
    }
}