.services_list_container{
    width: 90%;
    height: fit-content;
    margin: 25px auto;
}

.services_list_container_header{
    font-size: 1.2rem;
    padding: 0 20px;
    padding-bottom: 10px;
    border-bottom: solid 1px black;
    width: 100%;
    margin: auto;
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
}

.services_list_container_header .title_container{
    width: fit-content;
    display: flex;
    align-items: flex-end;
    align-content: flex-end;
}

.services_list_container_header .title_container label{
    margin: 0 !important;
}

.services_list_container .services_list_table{
    width: 100% !important;
    height: fit-content;
    margin: auto !important;
}

.services_list_table .MuiTableCell-head {
    font-weight: 700 !important;
}


.subTypes_container{
    width:100%;
    height: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.subType_container{
    width:100%;
    height: fit-content;
    display: flex;
    margin-bottom: 5px;
    align-items: center;
}

.subType{
    width:60%;
    height: 100%;
}

.subType_state{
    width:40%;
    height: 100%;
    display: contents;
}

.subType_sign_date_container{
    margin-bottom: 5px;
}

.subType_sign_date_container .MuiFab-circular{
    width: 0 !important;
}