.MuiPaper-rounded {
    padding: 0 !important;
    border-radius: 0 !important;
}

.suppliers_table{
    padding: 0 !important;
    border-radius: 0 !important;
    margin: 0 !important;
    font-size: .5rem !important;
    font-family: var(--body-font) !important;
    height: 100%;
    width: 100%;
}

.suppliers_table .MuiTableCell-body:nth-child(1) {
    min-width:105px;
}

.suppliers_table .MuiTableCell-body:nth-child(2) {
    min-width:95px;
}
.suppliers_table .MuiTableCell-body:nth-child(3) {
    min-width:55px;
}
.suppliers_table .MuiTableCell-body:nth-child(4) {
    min-width:65px;
}