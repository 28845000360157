.set_search_prd_container .MuiDialog-paperFullWidth {
    width: calc(100% - 64px) !important;
    height: 500px !important;
    margin: 5px auto !important;
}

.set_search_prd_container .form-control {
    border: 1px solid #c4c3c3;
    border-radius: 6px;
}

.set_search_prd_container label{
    width: 100%;
    text-align: left;
    margin: 0;
    font-size: .9rem;
    padding-left: 2px;
}

.set_search_prd_container .search_content_container{
    flex:1;
    width: 100%;
    margin: 15px auto;
    display:flex;
    justify-content: center;
    align-items: center;
}

.set_search_prd_container .emptytable_msg_container{
    height: fit-content;
    display: flex;
    justify-content: center;
    flex-direction: column;
    height: 100%;
    font-size: .95rem;
    padding-bottom: 70px;
}


.set_search_prd_container .submit_but{
    background:rgb(67, 113, 133);
    color: white;
}

.set_search_prd_container .submit_but:hover{
    background:rgb(67, 113, 133);
    opacity: .9;
}

.catalogue_modal{
    width: 100%;
    height: 100%;
    margin: 30px 0 ;
}


.products_gallery_modal_container{
    width: 100% !important;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: auto !important;
}


.set_search_prd_container{
    flex: 1;
    width: 98%;
    margin: 15px auto;
    display:flex;
    flex-direction: column;
}

.set_search_prd_container .products_contents_container{
    width: 100%;
    display:flex;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
}

.set_search_prd_container .main_card_container:hover > .main_card_infos_container > .view_more_button_container{
    opacity: 1;
    transition: all 190ms ease-in-out 0s;
}

.set_search_prd_container .view_more_button_container button{
    all: unset;
    font-size: 1rem;
    padding: .4rem .6rem;
    border: 1px solid rgb(255, 255, 255);
    color: white;
    border-radius: 5px;
    cursor: pointer;
    position: relative;
}


.set_search_prd_container .view_more_button_container button:hover{
    border: 1px solid rgb(230, 226, 226);
    background-color: rgba(0, 0, 0, .7);
}


@media (max-width: 680px){
    .set_search_prd_container .MuiDialog-paperFullWidth {
        width: 97% !important;
        margin: 5px auto !important;
    }
}