.charts_ofBillsAmount_payed_unpayed{
    width: 96%;
    height: 100%;
    margin: auto;
    padding: 0;
    padding-top: 10px;
}

.charts_ofBillsAmount_payed_unpayed .pexcharts-legend-text{
    font-size: .9rem !important;
    font-weight: 500 !important;
    font-style: var(--body-font) !important;
}