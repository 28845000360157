

.purchase_bills_container{
    width: 100%;
    height: fit-content;
    margin: 0;
    margin-bottom: 25px !important;
}

.bills_src_header{
    width: 97% !important;
    margin: auto !important;
    height: fit-content !important;
    padding-top: 5px;
}

.bills_table_container{
    width: 97% !important;
    margin: auto !important;
    height: fit-content !important;
}

.bills_table{
    width: 100% !important;
    margin: auto !important;
    height: fit-content !important;
}


.bills_container .MuiTableCell-head {
    font-weight: 700 !important;
}


.add_bill_container{
    display: flex !important;
    justify-content: space-between !important;
    margin-top: 7px;
}

/*
//////////////////////////////////////////////////////////////
*/

.purchase_bills_container .bill_container_titles{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    font-weight: 700;
    color: rgb(64, 64, 65);
    margin-bottom: 15px;
}


.purchase_bills_container .bill_container_titles .bill_infos_container{
    display: flex;
    width: 80%;
    padding-right: 13px;
}

.purchase_bills_container .bill_container_titles .bill_infos{
    width: 40%;
    min-width: 170px;
    display: flex;
    align-items: center;
    margin-right: 8px;
}

.purchase_bills_container .bill_container_titles .bill_state{
    width: 30%;
}

.purchase_bills_container .bill_container_titles .bill_treatedDate{
    width: 28%;
}

.purchase_bills_container .bill_container_titles .bill_amount{
    width: 28%;
}

.purchase_bills_container .bill_container_titles .bill_name{
    color: rgb(64, 64, 65);
    width: 100%;
}


.purchase_bills_container .bill_container .bill_name{
    color: rgb(64, 64, 65);
    width: 100%;
}

.purchase_bills_container .bill_infos .download_bill_button{
    width: 38px;
    margin-right: 7px;
}


.purchase_bills_container .bill_container_titles .bill_compDocs{
    width: 20%;
    height: 100%;
    margin-bottom: 9px;
    font-weight:700;
    margin: auto;
}


/*
//////////////////////////////////////////////////////////////
*/

.purchase_bills_container .bill_container{
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 2px;
}

.purchase_bills_container .bill_container label{
    margin-right: 8px !important;
    margin-bottom: 0 !important;
}



.purchase_bills_container .bill_infos_container{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 80%; 
    position: relative;
    padding-right: 13px;
}

.purchase_bills_container .bill_container .bill_infos{
    display: flex;
    align-items: center;
    font-weight:600;
    width: 40%;
    min-width: 170px;
    margin-right: 8px;
}

.purchase_bills_container .bill_container .bill_state_container{
    width: 30%;
}

.purchase_bills_container .bill_container .bill_state{
    width:fit-content;
}


.purchase_bills_container .bill_container .bill_treatedDate{
    width: 28%;
    color: rgb(64, 64, 65);
    font-weight:600;
}

.purchase_bills_container .bill_container .bill_amount{
    width: 27%;
    padding-right: 2px;
    color: rgb(64, 64, 65);
    font-weight:600;
}

.purchase_bills_container .bill_container .bill_compDocs{
    width: 20%;
    margin-bottom: 8px;
    font-weight:600;
}

.purchase_bills_container .bill_container .bill_compDocs .bill_compDoc{
    margin-bottom: 6px;
    display: flex;
}

/*
//////////////////////////////////////////////////////////////
*/


.bill_container .bill_state_container .refuse_raison_button{
    width: fit-content;
    margin: auto;
    text-decoration: underline;
    margin-top: 2px;
    color: #38a1a5 !important;
    cursor: pointer !important;
}

.bill_container .bill_state_container .refuse_raison_button:hover{
    color: #3ebbc0 !important;
}


.bill_container .bill_compDoc .docs_name{
    width: 110px;
    color: rgb(64, 64, 65);
    display: flex;
    align-items: center;
}




.bills_table_container label + .MuiInput-formControl {
    color: rgb(90, 93, 90) !important;
}

.bills_table_container .MuiSelect-select.MuiSelect-select {
    color: rgb(90, 93, 90) !important;
    padding: 3px 0 !important;
    padding-top: 3.5px !important;
}

.bills_table_container .MuiInput-underline:before {
    border: none !important;
}

.bills_table_container .MuiSelect-icon {
    color: rgb(90, 93, 90) !important;
}

.bills_table_container .MuiInputBase-root {
    font-family: var(--body-font) !important;
    border: solid 1px rgb(90, 93, 90) !important;
    padding-left: 6px !important;
    border-radius: 4px !important;
    font-size: inherit !important;
}

.bills_table_container .MuiSelect-nativeInput {
    left: 0;
    width: 100%;
    bottom: 0;
    opacity: 0;
    position: absolute;
    pointer-events: none;
}

.bills_table_container .css-dk24d0-MuiButtonBase-root-MuiCheckbox-root {
    padding: 0 5px 0 0 !important;
}


.bills_table_container .MuiTableCell-body:nth-child(1) {
    min-width:125px !important;
    max-width:125px !important;
}

.bills_table_container .MuiTableCell-body:nth-child(3) {
    min-width:135px !important;
    max-width:135px !important;
}

.bills_table_container .MuiTableCell-body:nth-last-child(2){
    min-width:100px !important;
    max-width:100px !important;
}

.bills_table_container .MuiTableCell-body:last-child {
    min-width:770px !important;
    max-width:770px !important;
}

.bills_table_finance_container .MuiTableCell-body:last-child {
    min-width:900px !important;
    max-width:900px !important;
}


.purchase_bills_container .add_bill_container{
    display: flex !important;
    justify-content: space-between !important;
    margin-top: 7px;
}

.purchase_bills_container .add_bill_container .add_button{
    width: fit-content;
    margin: auto;
    text-decoration: underline;
    margin-top: 2px;
    margin-right: 7px;
    color: #38a1a5 !important;
    cursor: pointer !important;
}


.purchase_bills_container .add_bill_container .add_button:hover{
    color: #3ebbc0 !important;
}


@media all and (max-width: 645px){
    
    .bills_table_container .MuiTableCell-body:nth-child(1) {
        min-width:110px !important;
        max-width:110px !important;
    }

    .bills_table_container .MuiTableCell-body:nth-child(3) {
        min-width:125px !important;
        max-width:125px !important;
    }

    .bills_table_container .MuiTableCell-body:nth-last-child(2){
        min-width:90px !important;
        max-width:90px !important;
    }

    .bills_table_container .MuiTableCell-body:last-child {
        min-width:700px !important;
        max-width:700px !important;
    }

    .bills_table_finance_container .MuiTableCell-body:last-child {
        min-width:820px !important;
        max-width:820px !important;
    }

}