.documentsContainer_BillsPage{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 0 9px;
}


.documentsContainer_BillsPage label{
    margin-bottom: 0 !important;
    margin-right: 8px !important;
}


.documentsContainer_BillsPage .comp_docs_container{
    width: fit-content;
    margin-top: 8px;
}

.documentsContainer_BillsPage .header_form{
    margin: 6px 0 !important;
    margin-right: 10px !important;
}


.documentsContainer_BillsPage .comp_docs_container .documentsDownload{
    margin-bottom: 8px;
    width: 100%;
}