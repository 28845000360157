.add_contract_modal_infos{
    width: 80%;
    margin: auto;
    display: flex;
    justify-content: space-between;
}


.add_contract_modal_infos .subType_input{
    width: 100%;
    display: flex;
}