.property_list_table .inputform {
    border: solid 1px #d9d3d3;
    border-bottom-width: 1px !important;
    border-radius: 6px;
    color: rgb(0 0 0 / 75%) !important;
    height: auto;
    font-size: inherit;
    box-shadow: none;
    height: 30px;
    background-image: none !important;
    background-color: white !important;
}

.property_list_table .MuiTableCell-head {
    color: rgb(0 0 0 / 75%) !important;
    font-weight: 500 !important;
}

.property_list_table .MuiSelect-select.MuiSelect-select {
    padding-right: 24px;
    color: inherit !important;
    background-color: inherit !important;
}

.property_list_table .MuiInput-underline:before {
    border: none !important;
}

.property_list_table .MuiSelect-icon {
    color: inherit !important;
}

.property_list_table .MuiInputBase-root {
    font-family: var(--body-font) !important;
    width: 160px !important;
    border: none !important;
    padding-left: 0 !important;
    border-radius: 4px !important;
    background-color: inherit !important;
    color: rgb(0 0 0 / 75%) !important;
}

.property_list_table .MuiSelect-nativeInput {
    left: 0;
    width: 100%;
    bottom: 0;
    opacity: 0;
    position: absolute;
    pointer-events: none;
}