
.docs_perms .multiselectContainer{
    font-family: var(--body-font) !important;
}

.docs_perms .searchBox{
    border: none !important;
    font-size: .95rem !important;
    color:#2c2c2c !important;
    font-family: var(--body-font) !important;
}

.docs_perms .inputField{
    color:#2c2c2c !important;
    font-family: var(--body-font) !important;
}


.docs_perms .searchWrapper {
    border: 1px solid #c4c3c3 !important;
    border-radius: 6px !important;
    padding: 0.3rem 0.65rem !important;
}

.docs_perms .chips{
    background: #338787 !important;
}

.docs_perms .chip {
    background: #338787 !important;
    border-radius: 15px !important;
    color: #ffffff !important;
    font-size: 0.85rem !important;
    margin-bottom: 0px !important;
    margin-right: 4px !important;
    font-family: var(--body-font) !important;
}

.docs_perms .optionListContainer {
    z-index: 1000 !important;
}

.docs_perms .option{
    color: #4c4c4e !important;
}

.docs_perms .option:hover{
    background: #e9ecee !important;
}

.docs_perms .highlightOption {
    background: #e9ecee !important;
}

.docs_perms .highlightOption:hover {
    background: #e9ecee !important;
}


.docs_perms .optionListContainer {
    top: auto !important;
    bottom: 100% !important;
}
