@media all and (max-width: 1200px){
    .widgets_contents_container .item_title {
        padding: 8px 15px 7px 15px !important;
        font-size: .95rem !important;
    }

    .widget_count_elems_container {
        font-size: 3.2rem !important;
    }


    .widget_count_elems_container .arrow_container {
        top: -5px !important;
        right: -30px !important;
    }

    .widget_count_elems_container .arrow_container .progress_icon {
        width: 1.5em !important;
        height: 1.5em !important;
    }

    .widgets_contents_container .MuiInputBase-input.MuiInputBase-input,.widgets_main_container .MuiInputBase-formControl {
        font-size: .87rem !important;
    }
}


@media all and (max-width: 1080px){
    .widgets_contents_container .item_title {
        padding: 6px 10px 5px 10px !important;
        font-size: .9rem !important;
    }

    .widgets_main_container  .MuiTableCell-root {
        padding: 10px 8px !important;
        font-size: 0.75rem !important;
        
    }

    .widgets_contents_container .header_form {
        width:155px !important;
    }
    
    .widgets_contents_container .MuiInputBase-input.MuiInputBase-input,.widgets_main_container .MuiInputBase-formControl {
        font-size: .82rem !important;
    }

}


@media all and (max-width: 990px) and (min-width: 765px){

    .main_title{
        width: fit-content !important;
        font-size: 1.85rem !important;
        padding-left: 26px !important;
    }
    
    .widgets_configButton_container{
        flex: 1 !important;
        padding-right: 13px !important;
        justify-content: flex-end;
    }
    
    
    .month_year_switchercontainer{
        height: 33px !important;
        margin-right: 20px !important;
    }


    .month_year_switchercontainer .selected_button{
        all: unset !important;
        background-color: #484f57 !important;
        color: white !important;
        border-radius: 40px !important;
        font-size: .85rem !important;
        padding: .36rem 1.05rem !important;
        transition: all 150ms ease-in-out 0s;
        box-shadow: none !important;
        position: relative !important;
    }
    
    
    .month_year_switchercontainer .unselected_button{
        all: unset !important;
        color: #484f57 !important;
        border-radius: 40px !important;
        font-size: .85rem !important;
        padding: .36rem 1.05rem !important;
        cursor: pointer !important;
        transition: all 150ms ease-in-out 0s;
        pointer-events: fill !important;
        box-shadow: none !important;
        position: relative !important;
    }
    
    .month_year_switchercontainer .year_slct_form{
        width: 85px !important;
        height: 32px !important;
    }


    .month_year_switchercontainer .MuiSelect-select.MuiSelect-select {
        color: inherit !important;
        font-size: .85rem !important;
        padding-top: 0.3rem !important;
        padding-bottom: 0.31rem !important;
        background-color: inherit !important;
    }
    
    .month_year_switchercontainer .MuiInputBase-root {
        font-family: var(--body-font) !important;
        border: none !important;
        padding: .35rem .5rem !important;
        border-radius: 40px !important;
        height: 31px !important;
    }

    .widgets_configButton{
        border: solid 1px #484f57;
        background:#484f57 !important;
        color: white !important;
        transition: all 150ms ease-in-out 0s;
        cursor: pointer !important;
        align-items: center !important;
        text-align: center !important;
        box-shadow: none !important;
    }


    .widgets_configButton:hover{
        background:#5b636b !important;
    }

    .widgets_saveButton{
        border: solid 1px #3b5a7c;
        background:#3b5a7c !important;
        color: white !important;
        transition: all 150ms ease-in-out 0s;
        cursor: pointer !important;
        align-items: center !important;
        text-align: center !important;
        box-shadow: none !important;
        margin-right: 9px !important;
    }


    .widgets_saveButton:hover{
        background:#4b698a !important;
    }


    .widgets_configButton_container .disabled_button{
        pointer-events: none !important;
        opacity: 0.6 !important;
    }

    .widgets_contents_container::-webkit-scrollbar {
        width: 8px !important;
        height: 8px !important;
        background: white;
    }

    .widgets_contents_container::-webkit-scrollbar-thumb {
        background-color: rgb(106, 102, 102);
        border-radius: 8px !important;
    }
    
    .widget_component_container::-webkit-scrollbar {
        width: 6px !important;
        height: 6px !important;
        background: white;
    }

    .widget_component_container::-webkit-scrollbar-thumb {
        border-radius: 6px !important;
    }

    .widget_count_elems_container {
        font-size: 2.75rem !important;
    }

    .widgets_contents_container .header_form {
        width:150px !important;
    }


    .widgets_contents_container .MuiInputBase-input.MuiInputBase-input,.widgets_main_container .MuiInputBase-formControl {
        font-size: .78rem !important;
    }

    .apexcharts-text {
        font-size: .75rem !important;
    }
}




@media all and (max-width: 765px) and (min-width: 660px){

    .main_title{
        width: fit-content !important;
        font-size: 1.6rem !important;
        padding-left: 15px !important;
    }
    
    .widgets_configButton_container{
        padding-right: 7px !important;
    }
    
    
    .month_year_switchercontainer{
        height: 28px !important;
        margin-right: 15px !important;
    }


    .month_year_switchercontainer .selected_button{
        all: unset !important;
        background-color: #484f57 !important;
        color: white !important;
        border-radius: 40px !important;
        font-size: .76rem !important;
        padding: .25rem .8rem !important;
        transition: all 150ms ease-in-out 0s;
        box-shadow: none !important;
        position: relative !important;
    }
    
    
    .month_year_switchercontainer .unselected_button{
        all: unset !important;
        color: #484f57 !important;
        border-radius: 40px !important;
        font-size: .76rem !important;
        padding: .25rem .8rem !important;
        cursor: pointer !important;
        transition: all 150ms ease-in-out 0s;
        pointer-events: fill !important;
        box-shadow: none !important;
        position: relative !important;
    }
    
    .MuiSelect-select.MuiSelect-select {
        padding-right: 20px !important;
    }

    .MuiMenuItem-root {
        font-size: .76rem !important;
    }

    .month_year_switchercontainer .year_slct_form{
        width: 75px !important;
    }


    .month_year_switchercontainer .MuiSelect-select.MuiSelect-select {
        color: inherit !important;
        font-size: .76rem !important;
        padding-top: 0.22rem !important;
        padding-bottom: 0.23rem !important;
        background-color: inherit !important;
    }

    .month_year_switchercontainer .MuiSelect-icon {
        right: 0px !important;
        width: 20px !important;
        height: 20px !important;
    }
    
    .month_year_switchercontainer .MuiInputBase-root {
        font-family: var(--body-font) !important;
        border: none !important;
        padding: .3rem .5rem !important;
        border-radius: 40px !important;
        height: 26px !important;
    }

    .widgets_configButton{
        border: solid 1px #484f57;
        background:#484f57 !important;
        color: white !important;
        transition: all 150ms ease-in-out 0s;
        cursor: pointer !important;
        align-items: center !important;
        text-align: center !important;
        box-shadow: none !important;
    }


    .widgets_configButton:hover{
        background:#5b636b !important;
    }

    .widgets_saveButton{
        border: solid 1px #3b5a7c;
        background:#3b5a7c !important;
        color: white !important;
        transition: all 150ms ease-in-out 0s;
        cursor: pointer !important;
        align-items: center !important;
        text-align: center !important;
        box-shadow: none !important;
        margin-right: 6px !important;
    }


    .widgets_saveButton:hover{
        background:#4b698a !important;
    }


    .widgets_configButton_container .disabled_button{
        pointer-events: none !important;
        opacity: 0.6 !important;
    }


    .widgets_contents_container .item_title {
        padding: 6px 10px 5px 10px !important;
        font-size: .85rem !important;
    }

    .widget_count_elems_container {
        font-size: 2.5rem !important;
    }


    .widget_count_elems_container .arrow_container {
        top: -5px !important;
        right: -26px !important;
    }

    .widget_count_elems_container .arrow_container .progress_icon {
        width: 1.4em !important;
        height: 1.4em !important;
    }

    .widgets_main_container  .MuiTableCell-root {
        padding: 9px 7px !important;
        font-size: 0.72rem !important;
        
    }

    .widgets_contents_container::-webkit-scrollbar {
        width: 8px !important;
        height: 8px !important;
        background: white;
    }

    .widgets_contents_container::-webkit-scrollbar-thumb {
        background-color: rgb(106, 102, 102);
        border-radius: 8px !important;
    }
    
    .widget_component_container::-webkit-scrollbar {
        width: 6px !important;
        height: 6px !important;
        background: white;
    }

    .widget_component_container::-webkit-scrollbar-thumb {
        border-radius: 6px !important;
    }

    .widgets_contents_container .header_form {
        width:150px !important;
    }

    .widgets_contents_container .MuiInputBase-input.MuiInputBase-input,.widgets_main_container .MuiInputBase-formControl {
        font-size: .78rem !important;
    }

    .apexcharts-text {
        font-size: .7rem !important;
    }
}



@media all and (max-width: 660px) and (min-width: 549px){

    .main_title{
        width: fit-content !important;
        font-size: 1.35rem !important;
        padding-left: 15px !important;
        padding-right: 5px !important;
    }
    
    .widgets_configButton_container{
        padding-right: 7px !important;
    }
    
    
    .month_year_switchercontainer{
        height: 25px !important;
        margin-right: 15px !important;
    }


    .month_year_switchercontainer .selected_button{
        all: unset !important;
        background-color: #484f57 !important;
        color: white !important;
        border-radius: 40px !important;
        font-size: .7rem !important;
        padding: .2rem .75rem !important;
        transition: all 150ms ease-in-out 0s;
        box-shadow: none !important;
        position: relative !important;
    }
    
    
    .month_year_switchercontainer .unselected_button{
        all: unset !important;
        color: #484f57 !important;
        border-radius: 40px !important;
        font-size: .7rem !important;
        padding: .2rem .75rem !important;
        cursor: pointer !important;
        transition: all 150ms ease-in-out 0s;
        pointer-events: fill !important;
        box-shadow: none !important;
        position: relative !important;
    }
    
    .MuiSelect-select.MuiSelect-select {
        padding-right: 14px !important;
    }

    .MuiMenuItem-root {
        font-size: .7rem !important;
    }

    .month_year_switchercontainer .year_slct_form{
        width: 65px !important;
    }


    .month_year_switchercontainer .MuiSelect-select.MuiSelect-select {
        color: inherit !important;
        font-size: .7rem !important;
        padding-top: 0.11rem !important;
        padding-bottom: 0.12rem !important;
        background-color: inherit !important;
    }

    .month_year_switchercontainer .MuiSelect-icon {
        top: 4px !important;
        right: 0px !important;
        width: 15px !important;
        height: 15px !important;
    }
    
    
    .month_year_switchercontainer .MuiInputBase-root {
        font-family: var(--body-font) !important;
        border: none !important;
        padding: .25rem .5rem !important;
        border-radius: 40px !important;
        height: 23px !important;
    }


    .widgets_configButtons .my_icon{
        width: 14.5px !important;
        height: 14.5px !important;
    }


    .widgets_configButton{
        background:#484f57 !important;
        color: white !important;
        padding: .35rem .75rem !important;
        font-size: .7rem !important;
        transition: all 150ms ease-in-out 0s;
        cursor: pointer !important;
        align-items: center !important;
        text-align: center !important;
        box-shadow: none !important;
    }

    .widgets_configButton:hover{
        background:#5b636b !important;
    }

    .widgets_saveButton{
        border: solid 1px #3b5a7c;
        background:#3b5a7c !important;
        color: white !important;
        transition: all 150ms ease-in-out 0s;
        cursor: pointer !important;
        align-items: center !important;
        text-align: center !important;
        box-shadow: none !important;
        margin-right: 5px !important;
    }

    .widgets_saveButton:hover{
        background:#4b698a !important;
    }

    .widgets_configButton_container .disabled_button{
        pointer-events: none !important;
        opacity: 0.6 !important;
    }


    .widgets_contents_container::-webkit-scrollbar {
        width: 8px !important;
        height: 8px !important;
        background: white;
    }

    .widgets_contents_container::-webkit-scrollbar-thumb {
        background-color: rgb(106, 102, 102);
        border-radius: 8px !important;
    }
    
    .widget_component_container::-webkit-scrollbar {
        width: 6px !important;
        height: 6px !important;
        background: white;
    }

    .widget_component_container::-webkit-scrollbar-thumb {
        border-radius: 6px !important;
    }
    
    .widgets_contents_container .item_title {
        padding: 6px 10px 5px 10px !important;
        font-size: .82rem !important;
    }

    .widget_count_elems_container {
        font-size: 2.6rem !important;
    }


    .widget_count_elems_container .arrow_container {
        top: -5px !important;
        right: -26px !important;
    }

    .widget_count_elems_container .arrow_container .progress_icon {
        width: 1.4em !important;
        height: 1.4em !important;
    }

    .widgets_main_container  .MuiTableCell-root {
        padding: 9px 7px !important;
        font-size: 0.72rem !important;
        
    }

    .widgets_contents_container .header_form {
        width:150px !important;
    }

    .widgets_contents_container .MuiInputBase-input.MuiInputBase-input,.widgets_main_container .MuiInputBase-formControl {
        font-size: .77rem !important;
    }

    .apexcharts-text {
        font-size: .7rem !important;
    }
}





@media all and (max-width: 549px){

    .main_title{
        width: fit-content !important;
        font-size: 1.1rem !important;
        padding-left: 6px !important;
        padding-right: 4px !important;
    }
    
    .widgets_configButton_container{
        padding-right: 5px !important;
    }
    
    .widgets_configButtons{
        width: fit-content !important;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    
    .month_year_switchercontainer{
        height: 25px !important;
        margin-right: 10px !important;
    }


    .month_year_switchercontainer .selected_button{
        all: unset !important;
        background-color: #484f57 !important;
        color: white !important;
        display: flex;
        border-radius: 40px !important;
        font-size: .75rem !important;
        padding: 0 0.65rem !important;
        height: 100% !important;
        transition: all 150ms ease-in-out 0s;
        box-shadow: none !important;
        position: relative !important;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    
    
    .month_year_switchercontainer .unselected_button{
        all: unset !important;
        color: #484f57 !important;
        border-radius: 40px !important;
        font-size: .72rem !important;
        padding: .2rem .65rem !important;
        cursor: pointer !important;
        transition: all 150ms ease-in-out 0s;
        pointer-events: fill !important;
        box-shadow: none !important;
        position: relative !important;
    }
    
    .MuiSelect-select.MuiSelect-select {
        padding-right: 12px !important;
    }

    .MuiMenuItem-root {
        font-size: .7rem !important;
    }

    .month_year_switchercontainer .year_slct_form{
        width: 60px !important;
    }


    .month_year_switchercontainer .MuiSelect-select.MuiSelect-select {
        color: inherit !important;
        font-size: .7rem !important;
        padding-top: 0.11rem !important;
        padding-bottom: 0.12rem !important;
        background-color: inherit !important;
    }

    .month_year_switchercontainer .MuiSelect-icon {
        top: 4px !important;
        right: 0px !important;
        width: 15px !important;
        height: 15px !important;
    }
    
    
    .month_year_switchercontainer .MuiInputBase-root {
        font-family: var(--body-font) !important;
        border: none !important;
        padding: .25rem .4rem !important;
        border-radius: 40px !important;
        height: 23px !important;
    }


    .widgets_configButtons .my_icon{
        width: 14.5px !important;
        height: 14.5px !important;
    }


    .widgets_configButton{
        background:#484f57 !important;
        color: white !important;
        transition: all 150ms ease-in-out 0s;
        cursor: pointer !important;
        align-items: center !important;
        text-align: center !important;
        box-shadow: none !important;
        margin-bottom: 5px !important;
    }

    .widgets_configButton:hover{
        background:#5b636b !important;
    }

    .widgets_saveButton{
        border: solid 1px #3b5a7c;
        background:#3b5a7c !important;
        color: white !important;
        transition: all 150ms ease-in-out 0s;
        cursor: pointer !important;
        align-items: center !important;
        text-align: center !important;
        box-shadow: none !important;
        margin-bottom: 5px !important;
    }

    .widgets_saveButton:hover{
        background:#4b698a !important;
    }

    .widgets_configButton_container .disabled_button{
        pointer-events: none !important;
        opacity: 0.6 !important;
    }


    .widgets_contents_container::-webkit-scrollbar {
        width: 7px !important;
        height: 7px !important;
        background: white;
    }

    .widgets_contents_container::-webkit-scrollbar-thumb {
        background-color: rgb(106, 102, 102);
        border-radius: 7px !important;
    }
    
    .widget_component_container::-webkit-scrollbar {
        width: 5px !important;
        height: 5px !important;
        background: white;
    }

    .widget_component_container::-webkit-scrollbar-thumb {
        border-radius: 5px !important;
    }

    .widgets_contents_container .item_title {
        padding: 6px 9px 5px 9px !important;
        font-size: .77rem !important;
    }

    .widget_count_elems_container {
        font-size: 2.2rem !important;
    }


    .widget_count_elems_container .arrow_container {
        top: -5px !important;
        right: -24px !important;
    }

    .widget_count_elems_container .arrow_container .progress_icon {
        width: 1.1em !important;
        height: 1.1em !important;
    }

    .widgets_main_container  .MuiTableCell-root {
        padding: 8px 7px !important;
        font-size: 0.72rem !important;
        
    }
    .widgets_main_container .MuiTableCell-head {
        line-height: .9rem !important;
    }

    .widgets_contents_container .header_form {
        width:145px !important;
    }

    .widgets_contents_container .MuiInputBase-input.MuiInputBase-input,.widgets_main_container .MuiInputBase-formControl {
        font-size: .75rem !important;
    }

    .apexcharts-text {
        font-size: .68rem !important;
    }

    .config_widget_container .MuiDialog-paperWidthLg {
        max-width: 100vw !important;
    }
    
}

@media all and (max-width: 379px){

    .widgets_main_container  .MuiTableCell-root {
        padding: 8px 7px !important;
        font-size: 0.7rem !important;
        
    }

    .widgets_contents_container .item_title {
        padding: 6px 9px 5px 9px !important;
        font-size: .75rem !important;
    }

    .widgets_contents_container .MuiInputBase-input.MuiInputBase-input,.widgets_main_container .MuiInputBase-formControl {
        font-size: .72rem !important;
    }

    .apexcharts-text {
        font-size: .65rem !important;
    }
}