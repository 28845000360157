.color_palette_modal .dialogBottom{
    justify-content: flex-end;
}

.color_palette_modal .rcp{
    margin: 10px !important;
    display: inline-block;
}

.color_palette_container{
    width: 100%;
    display: flex;
    justify-content: space-between;
}