.myAgenda_container{
    width: 100%;
    height: 100%;
    margin-top: 12px;
}

.rbc-toolbar .rbc-btn-group:nth-child(1) button:nth-child(1){
    display: none !important;
}


.rbc-btn-group:first-child{
    margin-left: 15px;
}

.rbc-btn-group:first-child > button:last-child:not(:first-child) {
    border-radius: 100% !important;
    width: 24px;
    height: 24px;
    padding: 0 !important;
    border-color:rgb(137, 137, 137) !important ;
    color: rgb(137, 137, 137) !important;
    font-weight: 600;
    margin-left: 5px;
}

.rbc-btn-group:first-child > button:not(:first-child):not(:last-child) {
    border-radius: 100% !important;
    width: 24px;
    height: 24px;
    padding: 0 !important;
    border-color:rgb(137, 137, 137) !important ;
    color: rgb(137, 137, 137) !important;
    font-weight: 600;
}


.rbc-label {
    padding: 0 6.5px !important;
    color: #535353 !important;
    font-size: .8rem !important;
}

.rbc-toolbar .rbc-toolbar-label {
    flex-grow: 1;
    padding: 0 0 0 15px !important;
    text-align: left !important;
    font-size: .9rem !important;
}


.rbc-btn-group:last-child{
    margin-right: 15px;
    font-family: var(--body-font) !important;
    font-size: .8rem !important;
}


.rbc-btn-group:last-child > button:last-child:not(:first-child) {
    border-radius: 30px !important;
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
    border-color:rgb(137, 137, 137) !important ;
    padding: 5px 15px !important;
    padding-bottom: 5.5px !important;
    transition: all 100ms ease-in-out 0s;
}

.rbc-btn-group:last-child > button:first-child:not(:last-child) {
    border-radius: 30px !important;
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    border-color:rgb(137, 137, 137) !important ;
    padding: 5px 15px !important;
    padding-bottom: 5.5px !important;
    transition: all 100ms ease-in-out 0s;
}

.rbc-toolbar button:active, .rbc-toolbar button.rbc-active {
    background-image: none;
    box-shadow: none !important;
    background-color: #3b5a7c !important;
    color: white !important;
    border-color:rgb(137, 137, 137) !important ;
    transition: all 100ms ease-in-out 0s;
}


.rbc-header {
    padding: 5px 3px !important;
    font-size: .8rem !important;
    color: #535353 !important;
    border-bottom: none !important;
}

.rbc-header button {
    font-weight: 600 !important;
}


.rbc-allday-cell {
    display: none !important;
}


.rbc-day-slot .rbc-events-container {
    margin: 0 !important;
}


.rbc-row-content {
    background: white !important;
    font-size: .9rem !important;
}


.rbc-day-slot .rbc-event-label {
    margin-top: 3px !important;
}

.rbc-day-slot .rbc-event-content {
    font-size: .8rem !important;
    margin-top: 3px !important;
}


.rbc-time-column .rbc-timeslot-group {
    background: white !important;
}

.rbc-day-slot {
    max-height: 550px !important;
}

.rbc-events-container {
    max-height: 550px !important;
}

.rbc-timeslot-group {
    flex: 1 1;
    max-height: 50px !important;
}

.rbc-time-slot {
    border-top: 1px solid #f7f7f7;
    max-height: 25px !important;
}

.rbc-event::after{
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(0,0,0,.2);
    z-index: -1;
}

.rbc-time-content{
    overflow: hidden !important;
}

@media all and (max-width: 800px){

    .rbc-btn-group:first-child > button:last-child:not(:first-child) {
        width: 22px;
        height: 22px;
        font-size: .9rem;
        margin-left: 3px;
    }


    .rbc-btn-group:first-child > button:not(:first-child):not(:last-child) {
        width: 22px;
        height: 22px;
        font-size: .9rem;
    }

    .rbc-btn-group:first-child {
        margin-left: 12px;
    }

    .rbc-btn-group:last-child {
        margin-right: 12px;
        font-size: .77rem !important;
    }

    .rbc-btn-group:last-child > button:last-child:not(:first-child) {
        padding: 4.5px 12px !important;
        padding-bottom: 5.5px !important;
    }

    .rbc-btn-group:last-child > button:first-child:not(:last-child) {
        padding: 4.5px 12px !important;
        padding-bottom: 5.5px !important;
    }

    .rbc-toolbar .rbc-toolbar-label {
        padding: 0 0 0 12px !important;
        font-size: .85rem !important;
    }

    .rbc-header {
        padding: 4px 3px !important;
        font-size: .75rem !important;
    }
    .rbc-label {
        padding: 0 5.5px !important;
        font-size: .75rem !important;
    }
}




@media all and (max-width: 450px){

    .rbc-btn-group:first-child > button:last-child:not(:first-child) {
        width: 20px;
        height: 20px;
        font-size: .78rem;
        margin-left: 3px;
    }

    .rbc-btn-group:first-child > button:not(:first-child):not(:last-child) {
        width: 20px;
        height: 20px;
        font-size: .78rem;
    }

    .rbc-btn-group:first-child {
        margin-left: 10px;
    }

    .rbc-btn-group:last-child {
        margin-right: 10px;
        font-size: .72rem !important;
    }

    .rbc-btn-group:last-child > button:last-child:not(:first-child) {
        padding: 4px 10px !important;
        padding-bottom: 4.5px !important;
    }

    .rbc-btn-group:last-child > button:first-child:not(:last-child) {
        padding: 4px 12px !important;
        padding-bottom: 4.5px !important;
    }

    .rbc-toolbar .rbc-toolbar-label {
        padding: 0 0 0 10px !important;
        font-size: .76rem !important;
    }

    .rbc-header {
        padding: 4px 3px !important;
        font-size: .7rem !important;
    }
    .rbc-label {
        padding: 0 5.5px !important;
        font-size: .7rem !important;
    }
}