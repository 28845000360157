.MuiPaper-rounded {
    padding: 0 !important;
    border-radius: 0 !important;
}

.contracts_widget_table{
    padding: 0 !important;
    border-radius: 0 !important;
    margin: 0 !important;
    font-size: .5rem !important;
}

.contracts_widget_table .MuiTableCell-body:nth-child(1) {
    min-width:125px !important;
}

.contracts_widget_table .MuiTableCell-body:nth-child(2) {
    min-width:65px !important;
}
.contracts_widget_table .MuiTableCell-body:nth-child(3) {
    min-width:65px !important;
}
.contracts_widget_table .MuiTableCell-body:nth-child(4) {
    min-width:65px !important;
}

.contracts_widget_table .MuiTableCell-body:nth-child(5) {
    min-width:115px;
}

.contracts_widget_table .MuiTableCell-body:nth-child(6) {
    min-width:105px !important;
}