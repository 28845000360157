.products_gallery_modal{
    width: 100%;
    height: 100%;
    margin: 30px 0 ;
}

.products_gallery_modal .modal_header_container{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: solid 1px #989898;
    padding: 5px 30px 8px 25px !important;
    background-color: #fbf9f9;
}


.products_gallery_modal .products_gallery_modal_container{
    width: 100% !important;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: auto !important;
}

.products_gallery_modal #products_number{
    top: 0px;
}



.products_gallery_modal .products_gallery_modal_header_container{
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 0px 5px 15px 30px;
}


.products_gallery_modal .products_gallery_modal_contents_container .view_more_button_container{
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,.6);
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 0;
    opacity: 0;
    transition: all 190ms ease-in-out 0s;
    border-radius: 4px;
}

.products_gallery_modal .main_card_container:hover > .main_card_infos_container > .view_more_button_container{
    opacity: 1;
    transition: all 190ms ease-in-out 0s;
}

.products_gallery_modal .products_gallery_modal_contents_container .view_more_button_container button{
    all: unset;
    font-size: 1rem;
    padding: .4rem .6rem;
    border: 1px solid rgb(255, 255, 255);
    color: white;
    border-radius: 5px;
    cursor: pointer;
    position: relative;
}


.products_gallery_modal .products_gallery_modal_contents_container .view_more_button_container button:hover{
    border: 1px solid rgb(230, 226, 226);
    background-color: rgba(0, 0, 0, .7);
}


.products_gallery_header_container{
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 20px 25px;
}



.products_gallery_modal .MuiDialog-paperFullWidth {
    width: calc(100% - 40px) !important;
    height: calc(100% - 70px);
    margin: 5px auto !important;
}

.products_gallery_modal .MuiDialogContent-root {
    padding: 0px !important;
}

@media all and (max-width: 550px){
    .products_gallery_modal .MuiDialog-paperFullWidth {
        width: 97%!important;
        height: calc(100% - 70px);
        margin: 5px auto !important;
    }
}