.charts_of_the_bestOffers_container{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.charts_of_the_bestOffers_container .container{
    width: 100%;
    height: fit-content;
}

.charts_of_the_bestOffers_container .categorie_container{
    width: 82%;
    height: fit-content;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    margin: auto;
    margin-bottom: 15px;
    padding-right: 15px;
    position: relative;
}


.charts_of_the_bestOffers_container .order_of_the_bestOffers {
    width: 50px;
    height: 50px;
    min-width: 50px;
    min-height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    border-radius: 100%;
    position:relative;
    right: -12px;
}

.charts_of_the_bestOffers_container .order_of_the_bestOffers span{
    color:white !important;
}


.charts_of_the_bestOffers_container .amount_of_the_bestOffers{
    height: fit-content;
    width: fit-content;
    flex: 1;
    font-size: 1.2rem;
    padding: 5px 15px;
    text-align: center;
    color: rgb(52, 51, 51);
    font-weight: 700;
    background:linear-gradient(to right, #e0b663ad, #FCF6BA );
    border-radius: 3px;
    border-left: none;
    width: fit-content;
    min-width: 145px;
}


.charts_of_the_bestOffers_container .css-i4bv87-MuiSvgIcon-root {
    font-size: 2.2rem !important;
}


@media all and (max-width: 1200px){

    .charts_of_the_bestOffers_container .categorie_container{
        width: 86%;
        margin-bottom: 13px;
    }

    .charts_of_the_bestOffers_container .amount_of_the_bestOffers{
        font-size: 1.08rem;
        padding: 5px 15px;
        min-width: 135px;
    }
    
    
    .charts_of_the_bestOffers_container .order_of_the_bestOffers {
        width: 46px;
        height: 46px;
        min-width: 46px;
        min-height: 46px;
        right: -12px;
    }

}


@media all and (max-width: 1080px){

    .charts_of_the_bestOffers_container .categorie_container{
        margin-bottom: 12px;
    }

    .charts_of_the_bestOffers_container .amount_of_the_bestOffers{
        font-size: 1.02rem;
        padding: 5px 15px;
        min-width: 135px;
    }
    
    
    .charts_of_the_bestOffers_container .order_of_the_bestOffers {
        width: 43px;
        height: 43px;
        min-width: 43px;
        min-height: 43px;
        right: -11px;
    }

}



@media all and (max-width: 900px){


    .charts_of_the_bestOffers_container .categorie_container{
        margin-bottom: 9px;
    }

    .charts_of_the_bestOffers_container .amount_of_the_bestOffers{
        font-size: .92rem;
        padding: 4px 15px;
        min-width: 135px;
    }
    
    
    .charts_of_the_bestOffers_container .order_of_the_bestOffers {
        width: 39px;
        height: 39px;
        min-width: 39px;
        min-height: 39px;
        right: -11px;
    }

}


@media all and (max-width: 500px){


    .charts_of_the_bestOffers_container .categorie_container{
        margin-bottom: 7px;
    }

    .charts_of_the_bestOffers_container .amount_of_the_bestOffers{
        font-size: .82rem;
        padding: 4px 15px;
        min-width: 125px;
    }
    
    
    .charts_of_the_bestOffers_container .order_of_the_bestOffers {
        width: 33px;
        height: 33px;
        min-width: 33px;
        min-height: 33px;
        right: -11px;
    }

}
