.offers_number_byMonth_chart_container{
    width: 100%;
    height: 100%;
    margin: auto;
    padding: 0;
    padding-top: 10px;
    display: flex;
    flex-direction: column;
}


.apexcharts-canvas {
    position: relative;
    user-select: none;
    margin: auto !important;
}

.offers_number_byMonth_chart_container .select_offers_categories_container{
    width: 100%;
    height: fit-content;
    display: flex;
    justify-content: flex-end;
    padding-right: 7px;
}

.offers_number_byMonth_chart_container .select_offers_categories_container .MuiSelect-select.MuiSelect-select {
    color: rgb(90, 93, 90) !important;
    font-size: .9rem;
    padding: 3px 0;
}

.offers_number_byMonth_chart_container .select_offers_categories_container .MuiInput-underline:before {
    border: none !important;
}

.offers_number_byMonth_chart_container .select_offers_categories_container .MuiSelect-icon {
    color: rgb(90, 93, 90) !important;
}

.offers_number_byMonth_chart_container .select_offers_categories_container .MuiInputBase-root {
    font-family: var(--body-font) !important;
    border: solid 1px rgb(90, 93, 90) !important;
    padding-left: 6px !important;
    border-radius: 4px !important;
}

.offers_number_byMonth_chart_container .select_offers_categories_container .MuiSelect-nativeInput {
    left: 0;
    width: 100%;
    bottom: 0;
    opacity: 0;
    position: absolute;
    pointer-events: none;
}
