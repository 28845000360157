.chatModal_container .MuiDialog-paperFullWidth {
  width: 60%;
  height: 100% !important;
  margin: 5px auto !important;
  padding: 0 !important;
}

.chatModal_container .MuiDialogContent-root{
  padding: 0 !important;
  overflow: hidden !important;
}



.chatModal_container #user{
  border-bottom: 1px solid; font-size: 16pt; display: flex; align-items: center;
}

.chatModal_container .modal-content .modal-body {
  padding: 0 !important;
}


.chatModal_container .chatView_container {
  height: 100%;
  width: 100%;
  background-color: rgb(249 249 249);
  border: solid 1px rgb(87, 87, 87);
  display: flex;
  flex-direction: column;
}


.chatModal_container .contact-profile {
  width: 100%;
  height: fit-content;
  font-weight: 600;
  padding: 15px !important;
  background: #f5f5f5;
  font-size: 1.3rem !important;
}

.chatView_container .messages {
  flex: 1;
  min-height: auto !important;
  overflow-y: scroll;
  overflow-x: hidden;
  width: 100%;
  padding: 0 !important;
  margin-bottom: 0;
}


.chatView_container ul li:nth-last-child(1) {
  margin-bottom: 12px !important;
}

.chatView_container .messages::-webkit-scrollbar {
  width: 8px;
  background: transparent;
  cursor: pointer;
}
.chatView_container .messages::-webkit-scrollbar-thumb {
  background-color: rgba(141, 141, 141);
  cursor: pointer;
}
.chatView_container ul li {
  display: inline-block;
  clear: both;
  width: calc(100% - 60px);
  font-size: .95em;
  margin: 9px 0;
}

.chatView_container .emetteur{
  position: relative;
  display: inline-block;
  justify-content: flex-start;
  margin: 9px 3.5rem !important;
  font-family: nunito;
}
.chatView_container .emetteur > span{
  min-width: 10ch;
  width: fit-content;
  display: flex;
  align-items: center;
  max-width: 40ch;
  padding: .3rem .5rem;
  border-radius: .2rem;
  background: #2c3e50bd;
  position: relative;
  color: white !important;

}

.chatView_container .emetteur >  div:nth-child(1){
  position: relative;
  left: -2.5rem;
  font-size: 1rem;
  font-weight: 700;
}

.chatView_container .emetteur .temps{
  font-size: .8rem;
  top: 100%;
  left: 0%;
  width: max-content;
}


.destinateur{
  position: relative;
  font-family: nunito;
  text-align: -webkit-right;
}

.destinateur > span{
  border: solid 1px gray;
  min-width: 10ch;
  text-align: left !important;
  width: fit-content;
  display: flex;
  align-items: center;
  max-width: 40ch;
  padding: .3rem .5rem;
  border-radius: .2rem;
  background: whitesmoke;
  position: relative;
} 
.destinateur > div:nth-child(1){
  font-size: 1rem;
  font-weight: 700;
  position: relative;
  max-width: 8ch;
  overflow: hidden;
  text-overflow: ellipsis;
  left: 45px;
}


#messages .text > span{
  word-wrap: break-word;
  max-width: 38ch;
  display: block;
}

.input_container{
  height: fit-content;
  display: flex;
  border-top: solid 1px #777777;
  padding: 0px;
  margin: 0 !important;
  padding: 10px 14px;
}

.input_container textarea{
  max-width: 100% !important;
  max-height: none !important;
  padding: 9px 5px 7px 9px;
  resize: auto !important;
  font-size: 1rem !important;
  border: solid 0.1rem #777777 !important;
  color: #555353;
  border-radius: 7px;
  line-height: 1 !important;
  margin: 0 !important;
}

.textarea_container{
  min-width:94%;
  max-width:94%;
  height:100%;
  position: relative;
}

.sendBut_container{
  align-self: center;
  min-width: 15px;
  margin-left: 7px;
}

@media all and (max-width: 975px){
  .emetteur > span, .destinateur > span{
      max-width: 35ch !important;
  }
}

@media all and (max-width: 880px){
  .chatModal_container .MuiDialog-paperFullWidth {
      width: 75% !important;
  }
}



@media all and (max-width: 750px){
  .chatModal_container .MuiDialog-paperFullWidth {
      width: 80% !important;
  }

  .chatModal_container .contact-profile {
      padding: 12px !important;
      font-size: 1.2rem !important;
  }

  .emetteur > div:nth-child(1), .destinateur > div:nth-child(1) {
      font-size: .95rem !important;
  }

  .chatView_container .emetteur {
      margin: 7px 3rem !important;
  }

  .chatView_container .emetteur > div:nth-child(1) {
      left: -2.2rem;
  }

  .chatView_container .destinateur{
      margin: 7px 0 !important;
  }
  .chatView_container ul li {
      width: calc(100% - 55px) !important;
      font-size: .9em !important;
  }

  .chatView_container .emetteur .temps {
      font-size: .75rem;
  }

  .input_container textarea{
      padding: 7px 4px 6px 7px;
      font-size: .95rem !important;
  }

  .emetteur > span, .destinateur > span{
      max-width: 32ch !important;
  }
}



@media all and (max-width: 580px){
  .chatModal_container .MuiDialog-paperFullWidth {
      width: 89% !important;
  }

  .chatModal_container .contact-profile {
      padding: 9px !important;
      font-size: 1.1rem !important;
  }

  .emetteur > div:nth-child(1), .destinateur > div:nth-child(1) {
      font-size: .9rem !important;
  }

  .chatView_container .emetteur {
      margin: 5px 2.5rem !important;
  }

  .chatView_container .emetteur > div:nth-child(1) {
      left: -2rem !important;
  }

  .chatView_container .destinateur{
      margin: 5px 0 !important;
  }
  .chatView_container ul li {
      width: calc(100% - 55px) !important;
      font-size: .85em !important;
  }

  .chatView_container .emetteur .temps {
      font-size: .7rem;
  }

  .input_container textarea{
      padding: 7px 4px 5px 6px;
      font-size: .9rem !important;
  }

  .emetteur > span, .destinateur > span{
      max-width: 27ch !important;
  }
}


@media all and (max-width: 450px){
  .chatModal_container .MuiDialog-paperFullWidth {
      width: 97% !important;
  }

  .emetteur > div:nth-child(1), .destinateur > div:nth-child(1) {
      font-size: .85rem !important;
  }

  .input_container textarea{
      padding: 5px 5px 4px 5px;
      font-size: .85rem !important;
  }

  .emetteur > span, .destinateur > span{
      max-width: 23ch !important;
  }
}