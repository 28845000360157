.service_commissions_container{
    height: 100%;
}

.service_commissions_container .commissions_container_header{
    font-size: 1.2rem;
    padding: 0 20px 15px 20px;
    width: 95%;
    margin: auto;
    margin-top: 30px;
    margin-bottom: 20px;
    border-bottom: solid 1px black;
    display: flex;
    justify-content: space-between;
}


.commissions_search_system .MuiGrid-container {
    width: fit-content !important;
    align-content: center !important;
    position: relative !important;
}

.commissions_table_container{
    width: 99%;
    margin: auto;
    margin-bottom: 50px;
}


.service_commissions_container .MuiTableCell-body:nth-child(5) {
    min-width:120px;
}

.service_commissions_container .MuiTableCell-body:nth-child(6) {
    min-width:350px;
}

.service_commissions_container .MuiTableCell-body:nth-child(7) {
    min-width:100px;
}


.service_commissions_container.commissions_container_header .search_system{
    display: flex;
}


.service_commissions_container .commissions_container_header .MuiGrid-spacing-xs-1 {
    width: fit-content !important;
}

  
.documentsTitle .MuiSelect-select.MuiSelect-select {
    padding-right: 2px !important;
    padding-bottom: 2px !important;
    padding-top: 0px !important;
    border: none;
    font-size: .9rem;
}