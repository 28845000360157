.MuiPaper-rounded {
    padding: 0 !important;
    border-radius: 0 !important;
}

.bills_widget_table_container{
    height: fit-content !important;
    padding: 0 !important;
    border-radius: 0 !important;
    margin: 0 !important;
    font-size: .5rem !important;
}

.bills_widget_table_container .MuiTable-root {
    min-width: 700px !important;
}


.bills_widget_table_container .MuiTableCell-body:nth-child(1) {
    min-width:125px !important;
}

.bills_widget_table_container .MuiTableCell-body:nth-child(2) {
    min-width:105px !important;
}

.bills_widget_table_container .MuiTableCell-body:nth-child(3) {
    position: relative;
}

.bills_widget_table_container .bill_container_titles{
    position: absolute;
    top: 0px;
    left: 0px;
    margin-bottom: 0 !important;
    padding: 0 10px;
    padding-top: 15px;
}

.bills_widget_table_container .bill_container{
    display: flex;
    width: 100%;
    height: fit-content;
}

.bills_widget_table_container .bill_infos{
    width: 20% !important;
    min-width: 80px !important;
    margin-right: 4px;
}

.bills_widget_table_container .bill_amount{
    width: 20% !important;
    min-width: 80px !important;
    margin-right: 5px;
}

.bills_widget_table_container .bill_treatedDate{
    width: 20% !important;
    min-width: 80px !important;
    margin-right: 6px;
}

