

.params_node_modal .MuiDialogTitle-root {
    padding: 14px 15px 7px 17px !important;
}

.params_node_modal label{
    color:#888888;
    margin: 0;
    padding: 0 2px 2px 0 ;
}

.params_node_modal .inputform {
    border: 1px solid #c4c3c3;
    border-bottom-width: 1px !important;
    border-radius: 6px;
    color: #2c2c2c;
    height: auto;
    font-size: .95rem;
    box-shadow: none;
    height: 36px;
    background-image: none !important;
    background-color: white !important;
}

.params_node_modal textarea{
    max-width: 100% !important;
    max-height: none !important;
    padding: 7px 10px 7px 7px !important;
    resize: auto !important;
    font-size: .95rem;
    border: 1px solid #c4c3c3 !important;
    line-height: 1 !important;
    border-radius: 6px;
}



.params_node_modal .accordion{
    margin-bottom: 15px;
}

.params_node_modal .accordion-button{
   font-size: 1rem;
   text-transform: capitalize !important;
   color: rgb(113, 113, 113) !important;
   padding: 3px 5px;
   min-height: 37px;
   height: fit-content !important;
   background-color: rgb(255, 255, 255);
   border: none;
   border-bottom: solid 1px rgb(165, 165, 165);
   border-radius: 0 !important;
   font-family: inherit !important;
}

.params_node_modal .accordion_header_content{
   position: relative;
   display: flex;
   justify-content: space-between;
   align-items: center;
   flex-wrap: wrap;
   margin-right: 3px;
   line-height: 1rem;
}

.params_node_modal .accordion_header_actions{
   flex: 1;
   display: flex;
   justify-content: flex-end;
   align-items: center;
}



.params_node_modal .collapsed::after {
    background-image: url(https://cdn.iconscout.com/icon/free/png-256/top-arrow-25-1112318.png);
    transform: scale(1);
    transform: rotate(-180deg);
}

.params_node_modal .accordion-button:not(.collapsed)::after {
    background-image: url(https://cdn.iconscout.com/icon/free/png-256/top-arrow-25-1112318.png);
    transform: scale(1);
}

.params_node_modal .accordion-item{
    border: none !important;
}

.params_node_modal .accordion-button:focus {
    box-shadow:none !important;
    color:black;
}


.params_node_modal .accordion-body {
    padding: 5px 5px;
    margin: 0;
    color: rgb(135, 134, 134) !important;
    background-color:white !important;
}

