

.litigations_container{
    width: 100%;
    height: 100%;
    margin: 0;
}


.litigations_container_header{
    font-size: 1.2rem;
    padding: 10px 20px;
    border-bottom: solid 1px black;
    width: 95%;
    margin: auto;
    margin-bottom: 20px;
}

.litigations_container_header div{
    margin: 25px auto 0 auto;
}

.litigations_container .litigations_table{
    width: 95%;
    height: fit-content;
    margin: auto;
}