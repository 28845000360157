.supplier_requests_container{
    width: 100%;
    height: fit-content;
    position: relative;
}


.supplier_requests_container table{
    max-width: 95%;
    height: fit-content;
    margin: auto;
}

.supplier_requests_container .MuiTableCell-body:nth-child(5) {
    min-width:320px !important;
}

.supplier_requests_container .MuiTableCell-body:nth-child(6) {
    min-width:210px !important;
}

.supplier_requests_container .MuiTableCell-body:nth-child(6) .documentsDownload{
    width: 100%;
}

.supplier_requests_container .MuiTableCell-body:nth-child(7) {
    min-width:190px !important;
}

.supplier_requests_container .MuiTableCell-body:nth-child(7) .documentsDownload{
    width: 100%;
}

.supplier_requests_container .documentsDownload {
    width: 65%;
}

.supplier_requests_container .documentsDownload label {
    margin-bottom: 0 !important;
}
